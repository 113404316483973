import {
  _salesforceValidateUnit,
  _salesforceRegisterUnit,
} from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const salesforceService = {
  salesforceAuth,
  salesforceValidateUnit,
  salesforceRegisterUnit,
};

async function salesforceAuth() {
  // try {
  //     const awsHeaders = {
  //         'Authorization': `Bearer ${cookies.get('access_token')}`,
  //         'x-user-uuid': `${cookies.get('user_session')}`
  //     }
  //     const res = await _salesforceAuth(awsHeaders);
  //     return res.data.data.response;
  // } catch (err) {
  //     return Promise.reject(err);
  // }
}

async function salesforceValidateUnit(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _salesforceValidateUnit(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function salesforceRegisterUnit(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _salesforceRegisterUnit(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
