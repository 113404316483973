export const saveddestinationsConstants = {
  GET_AWS_DEST_REQ_FAILURE: 'GET_AWS_DEST_REQ_FAILURE',
  GET_AWS_DEST: 'GET_AWS_DEST',
  CREATE_AWS_DEST: 'CREATE_AWS_DEST',
  CREATE_AWS_DEST_FAILURE: 'CREATE_AWS_DEST_FAILURE',
  GET_LUC_DEST: 'GET_LUC_DEST',
  GET_LUC_DEST_FAILURE: 'GET_LUC_DEST_FAILURE',
  CREATE_LUC_DEST: 'CREATE_LUC_DEST',
  CREATE_LUC_DEST_FAILURE: 'CREATE_LUC_DEST_FAILURE',
  SET_LUC_DEST: 'SET_LUC_DEST',
  SET_LUC_DEST_FAILURE: 'SET_LUC_DEST_FAILURE',
  DEL_AWS_DEST_FAILURE: 'DEL_AWS_DEST_FAILURE',
  DEL_AWS_DEST: 'DEL_AWS_DEST',
  DEL_LUC_DEST: 'DEL_LUC_DEST',
  DEL_LUC_DEST_FAILURE: 'DEL_LUC_DEST_FAILURE',
  GET_TWITTER_AUTH_REQ: 'GET_TWITTER_AUTH_REQ',
};
