import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  commonAction,
  // FBAction,
  liveStreamActions,
  unitActions,
  YTAction,
} from '../../actions';
import { Source } from '../Source';
import { Lrt } from '../Lrt';
import './UnitDetailsContainer.css';
import plusAdd from '../../assets/images/plus-add.png';
import { getUnitWithStatus } from '../../selectors/unit_selectors';
import {
  getLrtServices,
  getSelectedChannel,
} from '../../selectors/unit_selectors';
import { history } from '../../history';
import { getselectedDestination } from '../../selectors/destinations_selectors';
import { FacebookVideo } from '../Providers/Facebook/FacebookVideo';
import { YouTubeVideo } from '../Providers/YouTube/YouTubeVideo';
import { FacebookAnalytics } from '../Providers/Facebook/FacebookAnalytics';
import { YouTubeAnalytics } from '../Providers/YouTube/YouTubeAnalytics';
import { destinationService, unitService } from '../../services';
import { errorBlock } from '../../api/errorBlock';
import { ErrorBoundary } from '../ErrorBoundary';
import Cookies from 'universal-cookie';
import { YouTubeStreamToolVideo } from '../Providers/YouTube/YouTubeStreamToolVideo';
import { YouTubeStreamToolAnalytics } from '../Providers/YouTube/YouTubeStreamToolAnalytics';
import StreamToolsDashBoardCard from '../StreamToolsDashBoardCard';
import { Skeleton } from 'antd';
import HandleDestinations from './HandleDestinations';
import streamtoolZones from '../../data/streamToolZones.json';
import streamtoolZonesStage from '../../data/streamToolZonesStage.json';
import streamToolsQueue from '../../helpers/queue';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
// import { StreamToolDestinations } from '../StreamToolDestinations';
// import { Destinations } from '../Destinations';
// import _, { orderBy } from 'lodash';
// import profiles from '../../data/Profile.json';

const cookies = new Cookies();

let debounceTimeOut;
class UnitDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAnalytics: false,
      showYTAnalytics: false,
      fbLoggedIn: false,
      ytLoggedIn: false,
      streamToolDestPreview: [],
      streamtoolToogle: null,
      isDestRemoveLoading: false,
    };
  }

  async componentDidMount() {
    const {
      // streamTools: {isStreamToolActive} ,
      streamToolsUnitsList,
      showStreamtoolsLoader,
      unit,
    } = this.props;

    this.setState({ streamToolsData: streamToolsUnitsList?.[unit?.BOSSID] });
    this.providerState();
    this.previewState();
    showStreamtoolsLoader(unit?.BOSSID);
    this.getStreamToolInfo();
    if (streamToolsUnitsList?.[unit?.BOSSID]?.isStreamToolActive) {
      this.providerPostStatus();
    }
  }

  getStreamToolInfo = async () => {
    const {
      match,
      // isStreamtoolsStreamingfn,
      retriveStreamToolsInfo,
      streamToolsQueueWithDispatch,
    } = this.props;
    const data = await retriveStreamToolsInfo(match.params.bossId, false);
    this.setState({
      streamtoolToogle: data?.toggle_state,
    });
    const { streamToolsUnitsList, unit } = this.props;
    if (streamToolsUnitsList?.[unit?.BOSSID]?.isStreamToolActive) {
      streamToolsQueueWithDispatch(liveStreamActions.isStreamtoolsStreaming, [
        unit?.BOSSID,
      ]);
      // isStreamtoolsStreamingfn(unit?.BOSSID);
    }
  };

  debounceProps = (cb, loaderfn, bossId, timeout = 500) => {
    loaderfn(bossId);
    if (debounceTimeOut) {
      clearTimeout(debounceTimeOut);
    }
    debounceTimeOut = setTimeout(async () => {
      cb();
    }, timeout);
  };
  previewState = async () => {
    const {
      // streamTools: { selectedDestinations },
      getYTStreamToolEventDetails,
      match,
      streamToolsUnitsList,
    } = this.props;

    const selectedDestinations =
      streamToolsUnitsList?.[match.params.bossId]?.selectedDestinations;
    const isStreamToolActive =
      streamToolsUnitsList?.[match.params.bossId]?.isStreamToolActive;

    let preview = [];
    if (
      isStreamToolActive &&
      selectedDestinations &&
      selectedDestinations.length > 0
    ) {
      let data = await Promise.all(
        selectedDestinations
          .filter(
            (e) => e.streaming_destination?.streaming_provider === 'youtube',
          )
          .map(async (e) => {
            if (!!cookies.get('YT_access_token')) {
              return await getYTStreamToolEventDetails(
                e.streaming_destination.external_id,
                match,
              );
            }
          }),
      );

      data = data.filter(Boolean);
      preview = selectedDestinations.map((obj) => {
        const index = data.findIndex((el) => el['id'] === obj['id']);
        const temp = index !== -1 ? data[index] : {};
        return {
          ...obj,
          ...temp,
        };
      });
    }
    this.setState({
      streamToolDestPreview: [...preview],
    });
  };

  providerState = () => {
    !!getLocalStorageItem('FB_access_token')
      ? this.setState({ fbLoggedIn: true })
      : this.setState({ fbLoggedIn: false });
    !!cookies.get('YT_access_token')
      ? this.setState({ ytLoggedIn: true })
      : this.setState({ ytLoggedIn: false });
  };

  providerPostStatus = async () => {
    const { match } = this.props;
    try {
      const v1 = await unitService.getChannelServicesAndDestination(
        match.params.bossId,
      );

      if (v1.selected_destination) {
        const t1 = await destinationService.getDestinationDetails(
          v1.selected_destination,
        );

        // eslint-disable-next-line no-unused-vars
        // const v2 =
        //   !!cookies.get('FB_access_token') &&
        //   t1.streaming_destination.streaming_provider === 'Facebook'
        //     ? await this.getFbPostStatus(t1)
        //     : null;

        // eslint-disable-next-line no-unused-vars
        const yt =
          !!cookies.get('YT_access_token') &&
          t1.streaming_destination.streaming_provider.toLowerCase() ===
            'youtube'
            ? await this.getYtPostStatus(t1)
            : null;
      }
    } catch (e) {
      errorBlock(e);
    }
  };

  componentWillUnmount() {
    const { stopAllIntervals } = this.props;
    stopAllIntervals(); //to stop all running interval.This is done as some the interval don't have a reference when we perform clearInterval
  }

  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    const {
      match,
      fbData,
      selectedDes,
      fbLoginData,
      broadcast,
      // streamTools: { selectedDestinations, isStreamToolActive },
      showStreamtoolsLoader,
      streamToolsUnitsList,
    } = this.props;

    const { ytLoggedIn } = this.state;
    if (prevProps.match.params.bossId !== this.props.match.params.bossId) {
      this.debounceProps(
        this.getStreamToolInfo,
        showStreamtoolsLoader,
        match.params.bossId,
      );
      this.setState({
        showAnalytics: false,
        showYTAnalytics: false,
      });
      this.previewState();
    }

    if (JSON.stringify(prevProps.selectedDes) !== JSON.stringify(selectedDes)) {
      if (!!selectedDes && !!selectedDes.streaming_destination) {
        const provider =
          selectedDes.streaming_destination.streaming_provider.toLowerCase();
        if (provider === 'facebook' || provider === 'youtube') {
          this.providerPostStatus();
        }
      }
    }
    if (JSON.stringify(prevProps.fbLoginData) !== JSON.stringify(fbLoginData)) {
      if (
        fbData.fb_Session_Expired &&
        !!!getLocalStorageItem('FB_access_token')
      ) {
        this.setState({ showAnalytics: false });
      }
    }
    if (JSON.stringify(prevProps.broadcast) !== JSON.stringify(broadcast)) {
      if (!broadcast.id && !!!cookies.get('YT_access_token')) {
        this.setState({ showYTAnalytics: false });
      }
    }
    if (
      JSON.stringify(prevProps.fbData.liveVideo.status) !==
      JSON.stringify(fbData.liveVideo.status)
    ) {
      this.providerState();
      if (!!fbData.liveVideo.status.status) {
        fbData.liveVideo.status.status.toLowerCase() === 'unpublished' ||
        fbData.liveVideo.status.status.toLowerCase() === 'live' ||
        fbData.liveVideo.status.status.toLowerCase() === 'scheduled_unpublished'
          ? this.setState({ showAnalytics: true })
          : this.setState({ showAnalytics: false });
      }
      if (
        typeof fbData.liveVideo.status === 'string' &&
        fbData.liveVideo.status.toLowerCase().includes('error')
      ) {
        this.setState({ showAnalytics: false });
      }
    }
    if (
      JSON.stringify(prevProps.broadcast.status) !==
      JSON.stringify(broadcast.status)
    ) {
      this.providerState();
      if (!!broadcast.status && !!broadcast.status.lifeCycleStatus) {
        !broadcast.snippet.isDefaultBroadcast &&
        (broadcast.status.lifeCycleStatus.toLowerCase() !== 'live' ||
          broadcast.status.lifeCycleStatus.toLowerCase() !== 'deleted' ||
          broadcast.status.lifeCycleStatus.toLowerCase() !== 'complete')
          ? this.setState({ showYTAnalytics: true })
          : this.setState({ showYTAnalytics: false });
      }
    }

    if (
      JSON.stringify(
        prevProps.streamToolsUnitsList?.[match.params.bossId]
          ?.selectedDestinations,
      ) !==
      JSON.stringify(
        streamToolsUnitsList?.[match.params.bossId]?.selectedDestinations,
      )
    ) {
      this.previewState();
    }

    if (prevState.ytLoggedIn !== ytLoggedIn) {
      if (ytLoggedIn) {
        this.previewState();
      }
    }

    if (
      prevProps.streamToolsUnitsList?.[match.params.bossId]
        ?.isStreamToolActive !==
      streamToolsUnitsList?.[match.params.bossId]?.isStreamToolActive
    ) {
      // this.handleStremToolDestinations();
      this.previewState();
    }
  }

  // getFbPostStatus = (dest) => {
  //   const { fbGetLiveVideoStatus, match } = this.props;
  //   // eslint-disable-next-line no-unused-vars
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const data = await fbGetLiveVideoStatus(
  //         dest.streaming_destination.external_id,
  //         match.params.bossId,
  //       );
  //       // eslint-disable-next-line no-unused-vars
  //       const t1 =
  //         data.status.toLowerCase() === 'unpublished' ||
  //         data.status.toLowerCase() === 'live' ||
  //         data.status.toLowerCase() === 'scheduled_unpublished'
  //           ? this.setState({ showAnalytics: true })
  //           : this.setState({ showAnalytics: false });
  //       resolve(true);
  //     } catch (e) {
  //       errorBlock(e); //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
  //       reject(e);
  //     }
  //   });
  // };

  getYtPostStatus = (dest) => {
    const { getYTEventDetails, match } = this.props;
    // eslint-disable-next-line no-unused-vars
    return new Promise(async (resolve, reject) => {
      try {
        const data = await getYTEventDetails(
          dest.streaming_destination.external_id,
          match,
        );

        // eslint-disable-next-line no-unused-vars
        const yt =
          !data.snippet.isDefaultBroadcast &&
          (data.status.lifeCycleStatus.toLowerCase() !== 'live' ||
            data.status.lifeCycleStatus.toLowerCase() !== 'deleted' ||
            data.status.lifeCycleStatus.toLowerCase() !== 'complete')
            ? this.setState({ showYTAnalytics: true })
            : this.setState({ showYTAnalytics: false });
        resolve(true);
      } catch (e) {
        //errorBlock(e);
        //reject(e)
      }
    });
  };

  handleRemoveDestinationLoading = (value) => {
    this.setState({
      isDestRemoveLoading: value,
    });
  };

  // handleStremToolDestinations = () => {
  //   const { selectedDes, match, unit, t, streamToolsUnitsList } = this.props;

  //   const selectedDestinations =
  //     streamToolsUnitsList?.[match.params.bossId]?.selectedDestinations;
  //   const isStreamToolActive =
  //     streamToolsUnitsList?.[match.params.bossId]?.isStreamToolActive;
  //   if (
  //     isStreamToolActive &&
  //     selectedDestinations &&
  //     selectedDestinations.length > 0 &&
  //     unit?.status !== 'offline'
  //   ) {
  //     return (
  //       <div
  //         className={`${
  //           selectedDestinations?.length === 3 ? 'col-md-6' : 'col-md-4'
  //         } unitBlk_cont d-flex flex-column`}
  //         style={{
  //           marginRight: selectedDestinations?.length === 2 ? '20px' : '',
  //         }}
  //       >
  //         <div className='sub-title'>
  //           <h4>{t('DESTINATION').toUpperCase()}</h4>
  //         </div>
  //         <div className='block-wrap unitDestination'>
  //           {selectedDestinations.map((e, index) => (
  //             <StreamToolDestinations
  //               currentDestinationCount={index + 1}
  //               selectedDes={e}
  //               match={match}
  //               key={e?.id}
  //               handleRemoveDestinationLoading={
  //                 this.handleRemoveDestinationLoading
  //               }
  //             />
  //           ))}
  //         </div>
  //       </div>
  //     );
  //   }

  //   if (
  //     !isStreamToolActive &&
  //     selectedDes &&
  //     selectedDes.streaming_destination !== 'undefined'
  //   ) {
  //     return (
  //       <Destinations
  //         selectedDes={selectedDes}
  //         match={match}
  //         handleRemoveDestinationLoading={this.handleRemoveDestinationLoading}
  //       />
  //     );
  //   }

  //   return null;
  // };

  handleYTPreview = () => {
    const {
      selectedDes,
      broadcast,
      t,
      match,
      // streamTools: { isStreamToolActive },
      unit,
      streamToolsUnitsList,
    } = this.props;

    const { ytLoggedIn, showYTAnalytics, streamToolDestPreview } = this.state;
    const isStreamToolActive =
      streamToolsUnitsList?.[match.params.bossId]?.isStreamToolActive;

    if (
      isStreamToolActive &&
      streamToolDestPreview &&
      streamToolDestPreview
        .filter(
          (e) =>
            e.streaming_destination.streaming_provider.toLowerCase() ===
            'youtube',
        )
        .filter(
          (data) =>
            data?.status?.lifeCycleStatus?.toLowerCase() !== 'live' ||
            data?.status?.lifeCycleStatus?.toLowerCase() !== 'deleted' ||
            data?.status?.lifeCycleStatus?.toLowerCase() !== 'complete',
        ).length > 0 &&
      ytLoggedIn &&
      unit?.status !== 'offline'
    ) {
      return (
        <div
          className={`${
            streamToolDestPreview?.length === 3 && isStreamToolActive
              ? 'col-md'
              : 'col-md-4'
          } unitBlk_cont d-flex flex-column`}
        >
          <div className='sub-title'>
            <h4>{t('PREVIEW').toUpperCase()}</h4>
          </div>
          <div className='block-wrap unitDestination'>
            {streamToolDestPreview
              .filter(
                (e) =>
                  e.streaming_destination.streaming_provider.toLowerCase() ===
                  'youtube',
              )
              .filter(
                (data) =>
                  data?.status?.lifeCycleStatus?.toLowerCase() !== 'live' ||
                  data?.status?.lifeCycleStatus?.toLowerCase() !== 'deleted' ||
                  data?.status?.lifeCycleStatus?.toLowerCase() !== 'complete',
              )
              .map((e, index, arr) => (
                <div
                  className={`${
                    arr.length === 3
                      ? 'unitDestination-block unitDestination-block-xs'
                      : arr.length === 2
                      ? 'unitDestination-block unitDestination-block-sm'
                      : 'unitDestination-block'
                  } d-flex flex-column justify-content-between boxpos${
                    index + 1
                  }`}
                  key={e?.id}
                >
                  <YouTubeStreamToolVideo
                    selectedDestination={e}
                    match={match}
                    broadcast={e}
                  />
                  <YouTubeStreamToolAnalytics
                    selectedDestination={e}
                    match={match}
                    broadcast={e}
                  />
                </div>
              ))}
          </div>
        </div>
      );
    }

    if (
      !isStreamToolActive &&
      selectedDes &&
      selectedDes?.streaming_destination?.streaming_provider.toLowerCase() ===
        'youtube' &&
      ytLoggedIn &&
      showYTAnalytics &&
      broadcast.id
    ) {
      return (
        <div className='col-md-4 unitBlk_cont flex-column svunit-blocks'>
          <div className='sub-title'>
            <h4>{t('PREVIEW').toUpperCase()}</h4>
          </div>
          <div className='block-wrap d-flex flex-column justify-content-between '>
            <div className='d-flex flex-column'>
              <YouTubeVideo />
            </div>
            {showYTAnalytics && <YouTubeAnalytics match={match} />}
          </div>
        </div>
      );
    }

    return null;
  };

  handleFBPreview = () => {
    const {
      selectedDes,
      t,
      match,
      // streamTools: { isStreamToolActive },
      unit,
      streamToolsUnitsList,
    } = this.props;

    const { fbLoggedIn, showAnalytics } = this.state;
    const isStreamToolActive =
      streamToolsUnitsList?.[match.params.bossId]?.isStreamToolActive;

    if (
      !isStreamToolActive &&
      selectedDes &&
      selectedDes?.streaming_destination?.streaming_provider === 'Facebook' &&
      fbLoggedIn &&
      showAnalytics
    ) {
      return (
        <div className='col-md-4 unitBlk_cont flex-column svunit-blocks'>
          <div className='sub-title'>
            <h4>{t('PREVIEW').toUpperCase()}</h4>
          </div>
          <div className='block-wrap d-flex flex-column justify-content-between '>
            <div className='d-flex flex-column'>
              {unit.status === 'streaming' && <FacebookVideo />}
            </div>
            {showAnalytics && <FacebookAnalytics match={match} />}
          </div>
        </div>
      );
    }

    return null;
  };

  addDestinationBtnDesign = () => {
    const { match, t, streamToolsUnitsList, unit } = this.props;

    const selectedDestinations =
      streamToolsUnitsList?.[unit?.BOSSID]?.selectedDestinations;
    const isStreamToolActive =
      streamToolsUnitsList?.[unit?.BOSSID]?.isStreamToolActive;
    return (
      <div
        className={`${
          selectedDestinations?.length >= 2 &&
          isStreamToolActive &&
          selectedDestinations.some(
            (e) =>
              e.streaming_destination.streaming_provider.toLowerCase() ===
              'youtube',
          )
            ? 'col-md-3'
            : 'col-md-4'
        } unitBlk_cont d-flex flex-column add_block`}
      >
        <div className='sub-title'>
          <h4>&nbsp;</h4>
        </div>
        <div className='block-wrap addUnitBtn_blk'>
          <button
            className='addUnit-btn'
            title=''
            onClick={() => {
              history.push('/dashboard/edit/' + match.params.bossId);
            }}
          >
            <img src={plusAdd} alt='plusAdd' />
            <p>{t('SELECTNEWDESTINATION')}</p>
          </button>
        </div>
      </div>
    );
  };

  handleAddDestinationsBtn = () => {
    // const {
    //   unit,
    //   match,
    //   // streamTools: { isStreamToolActive, selectedDestinations },
    //   // isStreamtoolsStreaming,
    //   streamToolsUnitsList,
    // } = this.props;
    // const isStreamToolActive =
    //   streamToolsUnitsList?.[match.params.bossId]?.isStreamToolActive;

    // if (isStreamToolActive && unit?.status === 'offline') {
    //   return this.addDestinationBtnDesign();
    // }

    // if (
    //   isStreamToolActive &&
    //   (unit.status === 'streaming' || isStreamtoolsStreaming)
    // ) {
    //   return null;
    // }

    return this.addDestinationBtnDesign();
  };

  handleStreamToolsToggleChange = () => {
    const { streamtoolToogle } = this.state;
    const { changeStreamtoolMode, match, updateStreamToolsData } = this.props;
    this.setState(
      {
        streamtoolToogle: !streamtoolToogle,
      },
      async () => {
        const { streamtoolToogle } = this.state;
        this.handleRemoveDestinationLoading(true);
        await changeStreamtoolMode(match.params.bossId, streamtoolToogle);
        await updateStreamToolsData({
          bossId: match.params.bossId,
          toggleState: streamtoolToogle,
        });
        this.handleRemoveDestinationLoading(false);
      },
    );
  };

  // profileOptions = () => {
  //   const { unit, providers } = this.props;
  //   // console.log("unit", unit);
  //   if (providers[0]) {
  //     let options = providers.filter(
  //       (prov) => prov.name.toLowerCase() === 'generic',
  //     );
  //     if (options.length !== 0) {
  //       let oldOptions = options;
  //       try {
  //         //to sort profiles in ascending order
  //         options[0].streaming_profiles = orderBy(
  //           options[0].streaming_profiles,
  //           [
  //             (val) => {
  //               const l1 = val.name.toLowerCase().indexOf('x');
  //               const newVal = val.name.slice(0, l1);
  //               return Number(newVal);
  //             },
  //           ],
  //           ['asc'],
  //         );
  //       } catch (e) {
  //         options = oldOptions;
  //       }
  //       let profileResult = null;
  //       let result = null;
  //       try {
  //         profileResult = profiles.filter((obj1) =>
  //           options[0].streaming_profiles.some(
  //             (obj2) => obj1.value === obj2.name,
  //           ),
  //         );

  //         // To find profile which is in api(options[0].streaming_profiles) but not in profile.json(profiles)
  //         result = options[0].streaming_profiles.filter(
  //           (profiles1) =>
  //             !profileResult.some(
  //               (profiles2) => profiles1.name === profiles2.value,
  //             ),
  //         );

  //         if (unit.product !== 'LU_SOLO_PRO') {
  //           result = result.filter((res) => {
  //             return (
  //               res.name !== 'SRT-Out solo h265 2160p50/60' &&
  //               res.name !== 'SRT-Out solo h265 2160p25/30' &&
  //               res.name !== 'SRT-Out solo h265 1440p50/6030' &&
  //               res.name !== 'SRT-Out solo h265 1440p25/30' &&
  //               res.name !== 'SRT-Out solo 2160p50/60' &&
  //               res.name !== 'SRT-Out solo 2160p25/30' &&
  //               res.name !== 'SRT-Out solo 1440p50/60' &&
  //               res.name !== 'SRT-Out solo 1440p25/30' &&
  //               res.name !== '2160p25/30' &&
  //               res.name !== '2160p50/60' &&
  //               res.name !== '1440p50/60' &&
  //               res.name !== '1440p25/30'
  //             );
  //           });
  //         }
  //         result.forEach((item) => {
  //           if (item.name === '2160p25/30' || item.name === '2160p50/60') {
  //             item.label = 'Standard Profiles';
  //           } else {
  //             item.label = 'Others';
  //           }
  //           item.value = item.name;
  //           profileResult.push(item);
  //         });

  //         let updatedResult = profileResult.map((res) => {
  //           return {
  //             type: res.label,
  //             label: res.value,
  //             value: res.value,
  //             name: res.value,
  //           };
  //         });

  //         const grouped = _.groupBy(updatedResult, (res) => res.type);
  //         const data = Object.keys(grouped).map((label) => ({
  //           label,
  //           options: grouped[label],
  //         }));
  //         return data;
  //       } catch (e) {
  //         options = oldOptions;
  //       }
  //     }
  //   }
  // };

  onZoneUpdate = (_, { value }) => {
    const { unit, setStreamToolsZone } = this.props;
    setStreamToolsZone(unit?.BOSSID, value);
  };

  render() {
    const {
      match,
      unit,
      service,
      channelServiceDestination,
      t,
      streamToolsUnitsList,
      unitLoaderState,
      selectedDes,
    } = this.props;
    const { isDestRemoveLoading } = this.state;

    const streamtoolObj = streamToolsUnitsList?.[unit?.BOSSID];

    const isStreamToolActive = streamtoolObj?.isStreamToolActive;
    const dnd = streamtoolObj?.dnd;
    const streamtoolSubscribed = streamtoolObj?.streamtoolSubscribed;
    const isLoadingData = streamtoolObj?.isLoadingData;
    const isStreamtoolsStreaming = streamtoolObj?.isStreamtoolsStreaming;
    const selectedZone = streamtoolObj?.selectedZone;
    const isUpdatingZone = streamtoolObj?.isUpdatingZone;

    return (
      <div className='source-details clearfix'>
        <div className='solounit-details'>
          <div className='row'>
            <Source match={match} />
            {unit && channelServiceDestination && (
              <Lrt
                service={service}
                unit={unit}
                channelServiceDestination={channelServiceDestination}
              />
            )}
            <StreamToolsDashBoardCard
              {...{
                isStreamToolActive,
                dnd,
                streamtoolSubscribed,
                isLoadingData,
                isStreamtoolsStreaming,
                unit,
                unitLoaderState,
                handleStreamToolsToggleChange:
                  this.handleStreamToolsToggleChange,
                t,
                selectedZone,
                zonesOptions:
                  process.env.REACT_APP_environment === 'production'
                    ? streamtoolZones
                    : streamtoolZonesStage,
                onZoneUpdate: this.onZoneUpdate,
                isUpdatingZone,
              }}
            />
          </div>
        </div>

        <div className='destination-details mb-3'>
          {streamToolsUnitsList?.[match.params.bossId]?.isLoadingData ||
          isDestRemoveLoading ? (
            <div className='row'>
              <div className='col-md-4 unitBlk_cont'>
                <Skeleton
                  active
                  className='imageSkeleton skeleton_card'
                  paragraph={false}
                />
              </div>
              <div className='col-md-4 unitBlk_cont'>
                <Skeleton
                  active
                  className='imageSkeleton skeleton_card'
                  paragraph={false}
                />
              </div>
              <div className='col-md-4 unitBlk_cont'>
                <Skeleton
                  active
                  className='imageSkeleton skeleton_card'
                  paragraph={false}
                />
              </div>
            </div>
          ) : (
            <div className='row'>
              <ErrorBoundary>
                {/* {this.handleStremToolDestinations()} */}
                <HandleDestinations
                  {...{
                    selectedDes,
                    match,
                    unit,
                    t,
                    streamToolsUnitsList,
                    handleRemoveDestinationLoading:
                      this.handleRemoveDestinationLoading,
                  }}
                />
                {this.handleAddDestinationsBtn()}
              </ErrorBoundary>

              <ErrorBoundary>
                {this.handleFBPreview()}
                {this.handleYTPreview()}
              </ErrorBoundary>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedDestination, providers } = state.destination;
  const fbLoginData = state.fb.fb_data.login;
  const fbData = state.fb;
  const { broadcast } = state.yt.YT_data;
  const { unitLoaderState } = state.unit;

  const streamToolsUnitsList = state.streamTools;
  return {
    unit: getUnitWithStatus(state),
    service: getLrtServices(state),
    channelServiceDestination: getSelectedChannel(state),
    selectedDes: getselectedDestination(state),
    selectedDestination,
    fbLoginData,
    fbData,
    broadcast,
    streamToolsUnitsList,
    unitLoaderState,
    providers,
  };
};

const mapDispatchToProps = {
  getVideoDetails: unitActions.getVideoDetails,
  changeStreamtoolMode: unitActions.changeStreamtoolMode,
  // fbGetLiveVideoStatus: FBAction.fbLiveVideoStatus,
  getYTEventDetails: YTAction.getYTEventDetails,
  getYTStreamToolEventDetails: YTAction.getYTStreamToolEventDetails,
  stopAllIntervals: commonAction.stopAllIntervals,
  retriveStreamToolsInfo: liveStreamActions.retriveStreamToolsInfo,
  // isStreamtoolsStreamingfn: liveStreamActions.isStreamtoolsStreaming,
  showStreamtoolsLoader: liveStreamActions.showStreamtoolsLoader,
  setStreamToolsZone: liveStreamActions.setStreamToolsZone,
  updateStreamToolsData: liveStreamActions.updateStreamToolsData,
  streamToolsQueueWithDispatch: streamToolsQueue,
};

const ConnectedUnitDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UnitDetailsContainer));
export { ConnectedUnitDetailsContainer as UnitDetailsContainer };
