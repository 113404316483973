import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { VideoPlayer } from '../../../VideoPlayer/VideoPlayer';
import { YTAction } from '../../../../actions';
import { errorBlock } from '../../../../api/errorBlock';
import { getUnitWithStatus } from '../../../../selectors/unit_selectors';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Tooltip } from 'antd';
import i18n from 'i18next';

import '../YouTube.css'

import { css } from '@emotion/core';
const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  padding-top: 35%;
`;

class YouTubeVideo extends Component {
  state = {
    playerLoading: true,
  };
  componentDidMount() {
    this.playerLoadingTimeout();
  }

  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    const { broadcast } = this.props;
    if (
      prevProps.broadcast.status.lifeCycleStatus !==
      broadcast.status.lifeCycleStatus
    ) {
      if (
        !broadcast.snippet.isDefaultBroadcast &&
        (broadcast.status.lifeCycleStatus.toLowerCase() !== 'live' ||
          broadcast.status.lifeCycleStatus.toLowerCase() !== 'deleted' ||
          broadcast.status.lifeCycleStatus.toLowerCase() !== 'completed')
      ) {
        this.getTranscoderState();
      } else {
        clearInterval(this.intervalT2);
      }
    }
  }
  getPostStatus = async () => {
    const { match, getYTEventDetails, selectedDestination } = this.props;
    try {
      await getYTEventDetails(
        selectedDestination.streaming_destination.external_id,
        match,
      );
    } catch (e) {
      clearInterval(this.intervalT2);
      errorBlock(e);
    }
  };
  playerLoadingTimeout = () => {
    setTimeout(() => {
      this.setState({
        playerLoading: false,
      });
    }, 5000);
  };
  getTranscoderState = () => {
    const { match, selectedDestination } = this.props;
    try {
      if (
        selectedDestination.streaming_destination.streaming_provider ===
        'youtube'
      ) {
        this.intervalT2 = setInterval(() => {
          this.getPostStatus();
        }, 5000);
      }
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  };
  componentWillUnmount() {
    clearInterval(this.intervalT2);
  }

  render() {
    const { broadcast, unit } = this.props;
    const { playerLoading } = this.state;
    return (
      (broadcast.status.lifeCycleStatus !== 'live' ||
        broadcast.status.lifeCycleStatus !== 'completed' ||
        broadcast.status.lifeCycleStatus !== 'deleted' ||
        unit.status === 'streaming') && (
        <div className='d-flex flex-column'>
          {playerLoading ? (
            <PropagateLoader
              css={override}
              size={10}
              color={'#000000'}
              loading={playerLoading}
            />
          ) : (
            <React.Fragment>
              <div style={{ position: 'relative' }}>
                <Tooltip
                  placement='top'
                  title={
                    (
                      broadcast.status.lifeCycleStatus === 'live' ||
                      broadcast.status.lifeCycleStatus === 'complete' ||
                      broadcast.status.lifeCycleStatus === 'testing' 
                    ) ?
                      null :
                      i18n.t('PREVIEW_NOT_VISIBLE') 
                  }
                >
                  <div className='preview-overlay'>
                    <div
                      style={{
                        pointerEvents:
                          broadcast.status.lifeCycleStatus === 'live' ||
                            broadcast.status.lifeCycleStatus === 'complete' ||
                            broadcast.status.lifeCycleStatus === 'testing' 
                            ? 'all'
                            : 'none',
                      }}
                    >
                      <VideoPlayer
                        videoUrl={
                          broadcast.contentDetails.monitorStream.embedHtml
                        }
                      />
                    </div>
                  </div>
                </Tooltip>
              </div>
            </React.Fragment>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { broadcast } = state.yt.YT_data;
  const { selectedDestination } = state.destination;
  return {
    broadcast,
    selectedDestination,
    unit: getUnitWithStatus(state),
  };
};
const mapDispatchToProps = {
  getYTEventDetails: YTAction.getYTEventDetails,
};
const ConnectedYouTubeVideo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(YouTubeVideo));
export { ConnectedYouTubeVideo as YouTubeVideo };
