import { commonConstants, twitchConstants } from '../constants';
import { twitchService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { commonAction } from './common.actions';

export const twitchAction = {
  getChannel,
  getGames,
  updateTwitchChannel,
};
function getChannel(bossId, getIngestValue = true) {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const channel = await twitchService.getTwitchUser();
      if (getIngestValue) {
        const response = await twitchService.getTwitchStreamKey(channel.id);
        channel.stream_key = response.stream_key;
      }
      dispatch(success(channel));
      getIngestValue && dispatch(getIngest(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };
  function loading() {
    return { type: twitchConstants.TWITCH_LOADING };
  }
  function success(channel) {
    return { type: twitchConstants.CHANNEL_DETAILS, channel };
  }
  function failure(error) {
    return { type: twitchConstants.CHANNEL_DETAILS_FAIL, error };
  }
}

function getIngest(bossId) {
  return async (dispatch) => {
    try {
      const allIngests = await twitchService.getTwitchIngests();
      let availableIngest = [];
      // eslint-disable-next-line array-callback-return
      allIngests.ingests.filter((allValue) => {
        if (allValue.availability > 0) {
          availableIngest.push({
            label: allValue.name,
            value: allValue.url_template.replace(/\s?\/{[^}]+\}/g, ''),
          });
        }
      });
      dispatch(success(allIngests, availableIngest));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };
  function success(allIngests, availableIngest) {
    return {
      type: twitchConstants.INGEST_DETAILS,
      ingest: { allIngests, availableIngest },
    };
  }
  function failure(error) {
    return { type: twitchConstants.INGEST_DETAILS_FAIL, error };
  }
}

function getGames(bossId, value) {
  return async () => {
    try {
      const games = await twitchService.getTwitchGames(value);
      const values = games.filter((value) => {
        value.key = value.id;
        value.label = value.name;
        return value;
      });
      return values;
    } catch (e) {
      errorBlock(e, bossId);
    }
  };
}

function updateTwitchChannel(values, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      var payload = {
        game_id: values.game ? values.game.key : '',
        title: values.destination,
        // channel: {
        //   status: values.destination,
        //   game: values.game ? values.game.label : '',
        // },
      };
      await twitchService.updateTwitchChannel(payload, values.channelId);
      dispatch(makePayload(values, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function makePayload(values, bossId) {
  return async (dispatch) => {
    try {
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile.name,
        primary_url: values.ingestUrl,
        secondary_url: '',
        stream_name: values.streamKey,
        username: '',
        password: '',
        id: guid(),
        streaming_provider: 'Twitch',
        overrideResolution: values.resolutionBox
          ? values.overrideResolution.value
          : '',
        overrideFramerate: values.frameRateBox
          ? values.overrideFrame.value
          : '',
        overrideBitrate: values.bitRateBox ? values.overrideBitrate : '',
        audio_bitrate_override: values.audioBitRateBox ? values.audioBitRate?.value : null
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
