import { switchboardConstants } from '../constants';

const initialState = {
  SBLoading: true,
  SB: {
    auth: {},
    profile: {},
    workflows: [],
    ingests: [],
    streamKey: '',
    noworkflows: false,
  },
  authIsLoading: true,
};
const {
  SB_DATA_REQ,
  SB_DATA_FAIL,
  SB_PROFILE,
  SB_WORKFLOWS,
  SB_NO_WORKFLOWS,
  SB_INGESTS,
  SB_STREAM_NAME,
  SB_TOKEN_FAIL,
  SB_TOKEN,
  SB_TOKEN_REQ,
} = switchboardConstants;

export function switchboard(state = initialState, payload) {
  switch (payload.type) {
    case SB_TOKEN_REQ:
      return { ...state, authIsLoading: true };
    case SB_TOKEN_FAIL:
      return { ...state, authIsLoading: true };
    case SB_TOKEN:
      return { ...state, SB: { ...state.SB, auth: payload.Data } };
    case SB_DATA_REQ:
      return { ...state, SBLoading: true };
    case SB_DATA_FAIL:
      return {
        ...state,
        SBLoading: true,
        SB: {
          auth: {},
          profile: {},
          workflows: [],
          ingests: [],
          streamKey: '',
          noworkflows: false,
        },
      };
    case SB_PROFILE:
      return { ...state, SB: { ...state.SB, profile: payload.Data } };
    case SB_WORKFLOWS:
      return { ...state, SB: { ...state.SB, workflows: payload.Data } };
    case SB_NO_WORKFLOWS:
      return {
        ...state,
        SB: { ...state.SB, noworkflows: true },
        SBLoading: false,
      };
    case SB_STREAM_NAME:
      return { ...state, SB: { ...state.SB, streamKey: payload.Data } };
    case SB_INGESTS:
      return {
        ...state,
        SB: { ...state.SB, ingests: payload.Data },
        SBLoading: false,
      };
    default:
      return state;
  }
}
