import {
  unitConstants,
  destinationConstant,
  linkedinConstants,
  commonConstants,
  paymentConstants,
  streamToolConstants,
} from '../constants';
import {
  unitService,
  periscopeTwitterService,
  salesforceService,
  paymentService,
  linkedinService,
} from '../services';
import { history } from '../history';
import {
  destinationActions,
  vimeoAction,
  FBAction,
  userActions,
  linkedinAction,
  liveStreamActions,
  commonAction,
} from '../actions';
import { store } from '../store';
import { getTranscoder, wowzaStart, wowzaStop } from '../api/wowza.api';
import { notify } from '../components/CommonNotification/CommonNotification';
import { errorBlock } from '../api/errorBlock';
import { filter, includes } from 'lodash';
import i18n from 'i18next';
import { YTAction } from './editunit/yt.actions';
import utf8 from 'utf8';
import Cookies from 'universal-cookie';
import streamToolsQueue from '../helpers/queue';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';

const cookies = new Cookies();

const {
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  SELECTED_UNIT,
  EDIT_SELECTED_UNIT,
  CANCEL_EDIT_SELECTED_UNIT,
  UPDATE_UNIT,
  VIDEO_DETAILS_REQUEST,
  GET_VIDEO_DETAILS,
  GET_STATUS_SUCCESS,
  GET_INTERFACE_SUCCESS,
  GET_INTERFACE_REQUEST,
  GET_INTERFACE_FAILURE,
  GET_INTERFACE_DETAILS_SUCCESS,
  GET_INTERFACE_DETAILS_REQUEST,
  GET_INTERFACE_DETAILS_FAILURE,
  GET_UNIT_SETTINGS_SUCCESS,
  REBOOT_UNIT,
  SET_UNIT_LOADER_STATE,
  UPDATE_UNIT_FROM_UNITS,
  SET_SELECTOR_VALUE,
} = unitConstants;

const {
  ADD_UNIT_LOADING_STOP,
  // GET_CUSTOMER_REQUEST,
  // GET_CUSTOMER_SUCCESS,
  // GET_CUSTOMER_FAILURE,
  LRT_LOADING,
} = paymentConstants;

const {
  GET_SELECTED_DES_REQ,
  GET_CHANNEL_SERVICE_DES_REQUEST,
  GET_CHANNEL_SERVICE_DES_SUCCESS,
  GET_CHANNEL_SERVICE_DES_ERROR,
} = destinationConstant;
const {
  GET_DSESTINATIONS_SUCCESS,
  GET_DSESTINATIONS_FAIL,
  CHANGE_STREAM_TOOL_MODE,
  SET_ST_INITIAL_STATE,
} = streamToolConstants;
export const unitActions = {
  //getCustomerId,
  updateUnitBatteryStatus,
  getUnitsInfo,
  updateUnits,
  selectedUnit,
  editSelectedUnit,
  cancelEditSelectedUnit,
  renameUnit,
  getVideoDetails,
  changedelayMode,
  startStream,
  startUnit,
  stopUnit,
  stopStream,
  registerLucUnit,
  getUnitInterfaces,
  getUnitInterfaceDetails,
  editUnitInterfaceDetails,
  editUnitWifiDetails,
  getUnitsNameList,
  getUnitServices,
  unitSettings,
  restoreUnitSettings,
  cancelUnitSettings,
  rebootUnit,
  removeUnit,
  switchToDestination,
  getStreamToolDestination,
  startUnitForStreamTool,
  changeStreamtoolMode,
  setUnitLoaderState,
  storeSelectorValue,
  sendUnitLogs,
};

// const { stopStreamTools } = liveStreamActions
// function getCustomerId() {
//     return async dispatch => {

//         dispatch(request());
//         try {
//             const customer = await paymentService.getCustomer();
//             if (customer.length && customer[0].customer_id !== undefined) {
//                 dispatch(success(customer[0]));
//                 dispatch(stoploading());
//             } else {
//                 dispatch(success({}));
//                 dispatch(stoploading());
//             }
//         } catch (err) {
//             dispatch(failure());
//             errorBlock(err)
//         }
//     };

//     function stoploading() { return { type: ADD_UNIT_LOADING_STOP } }
//     function success(customer) { return { type: GET_CUSTOMER_SUCCESS, customer } }
//     function request() { return { type: GET_CUSTOMER_REQUEST } }
//     function failure() { return { type: GET_CUSTOMER_FAILURE } }
// }

function changeStreamtoolMode(bossId, data) {
  const dest = store.getState().streamTools?.[bossId];
  const lucDest = store.getState().destination?.selectedDestination;

  function success(isActive) {
    return { type: CHANGE_STREAM_TOOL_MODE, payload: { bossId, isActive } };
  }

  return async (dispatch) => {
    dispatch(success(data));
    if (
      !data &&
      dest?.selectedDestinations.length > 0 &&
      dest?.selectedDestinations[0]?.streaming_destination
        ?.streaming_destination_outputs[0]?.stream_name !==
        lucDest?.streaming_destination?.streaming_destination_outputs[0]
          ?.stream_name
    ) {
      const lucDest = dest?.selectedDestinations[0];

      const payload = {
        id: lucDest.id,
        streaming_provider: lucDest?.streaming_destination?.streaming_provider,
        destinationName: lucDest.name,
        type: 'stream',
        username: lucDest?.streaming_destination?.streaming_ingest?.username,
        password: lucDest?.streaming_destination?.streaming_ingest?.password,
        primary_url:
          lucDest?.streaming_destination?.streaming_ingest?.primary_url,
        secondary_url:
          lucDest?.streaming_destination?.streaming_ingest?.secondary_url,
        selectedProfile:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.streaming_profile,
        stream_name:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.stream_name,
        max_overrideResolution:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.max_res_override,
        min_overrideResolution:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.min_res_override,
        max_overrideFramerate:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.max_fps_override,
        min_overrideFramerate:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.min_fps_override,
        max_overrideBitrate:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.max_bitrate_override,
        min_overrideBitrate:
          lucDest.streaming_destination?.streaming_destination_outputs[0]
            ?.min_bitrate_override,
        startAsPreview: null,
        overrideResolution: '',
        overrideFramerate: '',
        overrideBitrate: '',
        audio_bitrate_override: null,
      };

      await dispatch(
        commonAction.createAwsDestination(payload, bossId, null, true),
      );
    }
  };
}

function getStreamToolDestination() {
  const { BOSSID } = store.getState().unit?.unit;

  function success(dest) {
    return {
      type: GET_DSESTINATIONS_SUCCESS,
      payload: { bossId: BOSSID, dest },
    };
  }

  function failure() {
    return { type: GET_DSESTINATIONS_FAIL };
  }

  return async (dispatch) => {
    try {
      const res = await unitService.streamToolDestinations(BOSSID);
      const data =
        res && res.length > 0
          ? res.map((e) => {
              return {
                id: e?.d_id,
                name: e?.title,
                inventories: [e?.inventory_id],
                type: e?.type,
                fb_page_id: e?.fb_page_id,
                streaming_destination: {
                  external_id: e?.d_id,
                  streaming_provider: e?.streaming_provider,
                  streaming_destination_outputs: [
                    {
                      streaming_profile: e?.streaming_profile,
                      stream_name: e?.stream_name,
                      min_res_override: e?.min_res_override || '',
                      max_res_override: e?.max_res_override || '',
                      min_fps_override: e?.min_fps_override || '',
                      max_fps_override: e?.max_fps_override || '',
                      max_bitrate_override: e?.max_bitrate_override || '',
                      min_bitrate_override: e?.min_bitrate_override || '',
                      audio_bitrate_override: e?.audio_bitrate_override || '',
                    },
                  ],
                  streaming_ingest: {
                    primary_url: e?.primary_url || '',
                    secondary_url: e?.secondary_url || '',
                    username: e?.username || '',
                    password: e?.password || '',
                  },
                },
              };
            })
          : [];
      dispatch(success(data));
    } catch (err) {
      dispatch(failure());
    }
  };
}
function updateUnitBatteryStatus() {
  return async (dispatch) => {
    try {
      const { status_promises, details_promise } = await unitService.getUnits();
      const status = await Promise.all(status_promises);
      const details = await Promise.all(details_promise);
      const result = status.filter((s) => {
        // eslint-disable-next-line array-callback-return
        return details.map((d) => {
          if (s.data.deviceId === d.id) {
            s.data.selected_destination = d.selected_destination;
            return s.data;
          }
        });
      });
      dispatch(success_status(result.map((s) => s.data)));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  function success_status(status) {
    return { type: GET_STATUS_SUCCESS, status };
  }
}

function getUnitsInfo() {
  return async (dispatch) => {
    dispatch(request());
    dispatch(requestdestination());
    try {
      const { _units, status_promises, details_promise } =
        await unitService.getUnits();

      let unit_id = history.location.pathname.split('/');
      if (unit_id.length > 3) {
        let selectedUnitId = filter(unit_id, function (s) {
          return s.indexOf('Boss') !== -1;
        });
        let selected_unit = _units.filter(
          (u) => u.BOSSID === selectedUnitId[0],
        );
        dispatch(request2(selected_unit[0]));
        //history.push(`/dashboard/units/${selectedUnitId[0]}`);
        dispatch(
          destinationActions.getChannelServicesAndDestination(
            selectedUnitId[0],
          ),
        );
      } else {
        if (_units.length) {
          dispatch(request2(_units[0]));
          history.push(`/dashboard/units/${_units[0].BOSSID}`);
          dispatch(
            destinationActions.getChannelServicesAndDestination(
              _units[0].BOSSID,
            ),
          );
        } else {
          history.push(`/dashboard/units`);
        }
      }
      // const streamToolsConfigList = store.getState().streamTools;
      // if (true) {
      //   const {unit} = store.getState()
      //   const selectedUnitId = unit?.unit?.BOSSID
      //   if (selectedUnitId) {
      //     const dnd = streamToolsConfigList?.[selectedUnitId]?.dnd
      //     if (!dnd) {
      //       dispatch(setStreamtoolsReduxState(_units));
      //     }
      //   }
      // }
      dispatch(success(_units));
      dispatch(getAwsUnitSettings());
      const status = await Promise.all(status_promises);
      const details = await Promise.all(details_promise);
      const result = status.filter((s) => {
        // eslint-disable-next-line array-callback-return
        return details.map((d) => {
          if (s.data.deviceId === d.id) {
            s.data.selected_destination = d.selected_destination;
            return s.data;
          }
        });
      });
      dispatch(success_status(result.map((s) => s.data)));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  function request2(unit) {
    return { type: SELECTED_UNIT, unit };
  }
  function success(units) {
    return { type: GET_UNITS_SUCCESS, units };
  }
  // function setStreamtoolsReduxState(units) {
  //   return { type: SET_ST_INITIAL_STATE, units };
  // }
  function success_status(status) {
    return { type: GET_STATUS_SUCCESS, status };
  }
  function request() {
    return { type: GET_UNITS_REQUEST };
  }
  function requestdestination() {
    return { type: GET_SELECTED_DES_REQ };
  }
}

function rebootUnit(bossId) {
  return async (dispatch) => {
    // dispatch(request());
    try {
      const rebootStatus = await unitService.rebootLUCUnit(bossId);
      dispatch(success(rebootStatus));
    } catch (error) {
      notify('success', i18n.t('REBOOTFAILED'));
      // eslint-disable-next-line no-console
    }
    function success(rebootStatus) {
      return { type: REBOOT_UNIT, rebootStatus };
    }
    // function request() {
    //   return { type: REBOOT_UNIT };
    // }
  };
}

function updateUnits() {
  /*const initLoaderValue = store.getState().common.unitStreamLoader[cookies.get('currentSelectedUnit')]
    const checkValue = !!initLoaderValue*/
  return async (dispatch) => {
    // dispatch(request());
    try {
      const units = await unitService.updateUnits();
      dispatch(success(units));
      dispatch(updateUnit(units));
    } catch (err) {
      // dispatch(showUnitLoader({ [cookies.get('currentSelectedUnit')]: !checkValue }))
    }
  };
  function success(units) {
    return { type: GET_UNITS_SUCCESS, units };
  }
  function updateUnit(units) {
    return { type: UPDATE_UNIT_FROM_UNITS, units };
  }
  // function request() {
  //   return { type: GET_UNITS_REQUEST };
  // }
}

function selectedUnit(unit) {
  return (dispatch) => {
    //dispatch(lrtloading())
    dispatch(sourceloading());
    dispatch(request(unit));
    dispatch(getVideoDetails(unit.BOSSID, 'video'));
  };

  // function lrtloading() {
  //   return { type: LRT_LOADING };
  // }
  function sourceloading() {
    return { type: VIDEO_DETAILS_REQUEST };
  }
  function request(unit) {
    return { type: SELECTED_UNIT, unit };
  }
}

function editSelectedUnit(unit) {
  return (dispatch) => {
    dispatch(request(unit));
  };

  function request(unit) {
    return { type: EDIT_SELECTED_UNIT, unit };
  }
}

function cancelEditSelectedUnit(unit) {
  return (dispatch) => {
    dispatch(request(unit));
  };

  function request(unit) {
    return { type: CANCEL_EDIT_SELECTED_UNIT, unit };
  }
}

//rename unit
function renameUnit(id, payload, unit) {
  return (dispatch) => {
    unitService.updateUnitName(id, payload).then(() => {
      dispatch(request2(unit));
    });
  };

  // function request(units) {
  //     return { type: GET_UNITS_SUCCESS, units }
  // }

  function request2(unit) {
    return { type: UPDATE_UNIT, unit };
  }
}

//set unit delay
function changedelayMode(unit) {
  return async (dispatch) => {
    try {
      const unitpayload = {
        unit: {},
      };

      let success_msg = '';
      if (unit.delay === 1000) {
        unitpayload.unit.delay = 5000;
        success_msg = i18n.t('UNITLOWDELAYOFFMSG');
      } else {
        unitpayload.unit.delay = 1000;
        success_msg = i18n.t('UNITLOWDELAYONMSG');
      }
      await unitService.LiveUSoloAPIUnitDelay(unit.BOSSID, unitpayload);
      notify('success', success_msg);
      dispatch(success({ ...unit, ...unitpayload.unit }));
    } catch (err) {
      errorBlock(err);
    }
  };
  function success(unit) {
    return { type: UPDATE_UNIT, unit };
  }
}

//get video details
function getVideoDetails(id, path) {
  return async (dispatch) => {
    //dispatch(request());
    try {
      const res = await unitService.getVideoDetails(id, path);
      path === 'interfaces'
        ? dispatch(interfaceData(res))
        : dispatch(success(res));
    } catch (err) {
      errorBlock(err);
    }
  };
  function success(res) {
    return { type: GET_VIDEO_DETAILS, res };
  }
  function interfaceData(Data) {
    return { type: unitConstants.GET_UNIT_INTERFACE, Data };
  }
}

// start stream
function startStream(unit) {
  return async (dispatch) => {
    try {
      const { ovpCredentials } = store.getState().authentication.user;
      if (unit.status === 'connected') {
        const { selectedDestination } = store.getState().destination;
        const { isStreamToolActive } =
          store.getState().streamTools?.[unit.BOSSID];
        return await dispatch(
          switchToDestination(
            selectedDestination,
            ovpCredentials,
            unit,
            isStreamToolActive,
          ),
        );
      }
    } catch (err) {}
  };
}

function switchToDestination(
  selectedDestination,
  ovpCredentials,
  unit,
  isStreamToolActive,
) {
  return async (dispatch) => {
    try {
      if (selectedDestination) {
        switch (
          selectedDestination.streaming_destination.streaming_provider.toLowerCase()
        ) {
          case 'wowza':
            if (!ovpCredentials) {
              history.push(`/dashboard/wowza/${unit.BOSSID}`);
              return;
            } else if (!JSON.parse(ovpCredentials)[unit.BOSSID + '_wowza']) {
              history.push(`/dashboard/wowza/${unit.BOSSID}`);
              return;
            }
            dispatch(startUnit(unit.BOSSID));
            dispatch(
              wowzastart(JSON.parse(ovpCredentials)[unit.BOSSID + '_wowza']),
            );
            break;
          case 'periscope and twitter':
            dispatch(periscopestart(selectedDestination, unit.BOSSID));
            break;
          case 'facebook':
            !!getLocalStorageItem('FB_access_token')
              ? dispatch(checkFbPostStatus(selectedDestination, unit.BOSSID))
              : notify('error', i18n.t('FBSESSIONEXPIRED'));
            break;
          case 'youtube':
            !!cookies.get('YT_access_token')
              ? dispatch(checkYtPostStatus(selectedDestination, unit.BOSSID))
              : notify('error', i18n.t('YTSESSIONEXPIRED'));
            break;
          case 'linkedin':
            !!cookies.get('LI_access_token')
              ? dispatch(linkedInPostProcess(selectedDestination, unit))
              : notify('error', i18n.t('LINKEDINSESSIONEXPIRED'));
            break;
          case 'vimeo':
            !!cookies.get('vimeo_access_token')
              ? dispatch(vimeoActivateEvent(selectedDestination, unit))
              : notify('error', i18n.t('VIMEOSESSIONEXPIRED'));
            break;

          default:
            if (!isStreamToolActive) {
              dispatch(startUnit(unit.BOSSID));
              break;
            }
        }
      }
    } catch (err) {
      err && notify('warning', err);
    }
  };
}

//activate vimeo event
function vimeoActivateEvent(selectDest, unit) {
  return async (dispatch) => {
    try {
      if (
        !!cookies.get(`isVimeoLiveEvent_${unit.BOSSID}`) &&
        cookies.get(`isVimeoLiveEvent_${unit.BOSSID}`)
      ) {
        // await dispatch(
        //   vimeoAction.updateStatus(
        //     selectDest.streaming_destination.external_id,
        //     'activate',
        //   ),
        // );
        dispatch(startUnit(unit.BOSSID));
      } else {
        dispatch(startUnit(unit.BOSSID));
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}
//start unit
function startUnit(id) {
  const initLoaderValue =
    store.getState().common.unitStreamLoader[
      cookies.get('currentSelectedUnit')
    ];
  const checkValue = !!initLoaderValue;
  return async (dispatch) => {
    try {
      const { isStreamToolActive } = store.getState().streamTools?.[id];
      if (isStreamToolActive) {
        return;
      }
      dispatch(
        showUnitLoader({ [cookies.get('currentSelectedUnit')]: !checkValue }),
      );
      const payload = {
        unit_id: id,
      };
      await unitService.startUnit(id, payload);
      dispatch(updateUnits());
      notify('success', i18n.t('STREAMINGSTARTSUCCESS'));
    } catch (err) {
      dispatch(
        showUnitLoader({ [cookies.get('currentSelectedUnit')]: !checkValue }),
      );
    }
  };
  function showUnitLoader(Data) {
    return { type: commonConstants.UNIT_STREAM_LOADING, Data };
  }
}

function startUnitForStreamTool(id) {
  return () => {
    return new Promise(async (resolve) => {
      try {
        const payload = {
          unit_id: id,
        };
        await unitService.startUnit(id, payload);
        resolve();
        // notify('success', i18n.t('STREAMINGSTARTSUCCESS'));
      } catch (err) {}
    });
  };
}
//start wowza stream
function wowzastart(token) {
  return async () => {
    try {
      const res = await getTranscoder(token);
      if (res.data.transcoder.state === 'stopped') {
        await wowzaStart(token, token);
      } else {
      }
    } catch (error) {
      errorBlock(error);
    }
  };
}

function stopLinkedinStream(selectedDestination, bossId) {
  const match = {
    params: {
      bossId: bossId,
    },
  };
  return async (dispatch) => {
    try {
      const payload = {
        id: selectedDestination.streaming_destination.external_id,
        action: 'endLiveEvent',
      };
      const v1 = await dispatch(linkedinAction.LIPostStatus(payload, match));
      dispatch(status(v1));
      await unitService.stopUnit(bossId);
    } catch (e) {
      dispatch(
        status({
          id: selectedDestination.streaming_destination.external_id,
          status: 'AVAILABLE',
        }),
      );
      await unitService.stopUnit(bossId);
      errorBlock(e, bossId);
    }
  };
  function status(Data) {
    return { type: linkedinConstants.LI_POST_STATUS, Data };
  }
}

function linkedInPostProcess(destData, unitData) {
  let data;
  let payload;
  let LIInterval;
  let counter = 0;
  const match = {
    params: {
      bossId: unitData.BOSSID,
    },
  };
  const assetId = cookies.get(`LI_post_data_${unitData.BOSSID}`).lAssetId;
  return async (dispatch) => {
    try {
      dispatch(startUnit(unitData.BOSSID));
      LIInterval = setInterval(async () => {
        try {
          data = await dispatch(
            linkedinAction.LIPostStatus(
              destData.streaming_destination.external_id,
              match,
            ),
          );
          counter++;
          if ((!!data && data.status === 'INCOMPLETE') || counter > 10) {
            clearInterval(LIInterval);
            counter = 0;
            notify('error', i18n.t('OBJECTDOESNOTEXIT'));
            let { isStreamToolActive } =
              store.getState().streamTools?.[unitData.BOSSID];
            if (!isStreamToolActive) {
              await unitService.stopUnit(unitData.BOSSID);
            }
          }
          if (!!data && data.status === 'AVAILABLE') {
            const temp = cookies.get(`LI_post_data_${match.params.bossId}`);
            payload = {
              id: JSON.parse(cookies.get('LI_post_profile'))
                ? `urn:li:person:${temp.linkedinId}`
                : `urn:li:organization:${temp.linkedinId}`,
              assetId: assetId,
              title: destData.name,
              privacy: temp.postPrivacy,
            };
            dispatch(linkedinMakeLive(payload, match));
            clearInterval(LIInterval);
          }
        } catch (e) {
          clearInterval(LIInterval);
          errorBlock(e, unitData.BOSSID);
        }
      }, 7000);
    } catch (e) {
      clearInterval(LIInterval);
      errorBlock(e, unitData.BOSSID);
    }
  };
}

function linkedinMakeLive(payload, match) {
  return async (dispatch) => {
    try {
      const v1 = await linkedinService.LICreatePost(payload);
      const oldValue = !!cookies.get('linkedin_post_url')
        ? cookies.get('linkedin_post_url')
        : {};
      const v2 = {
        [match.params.bossId]: v1.id,
      };
      cookies.set(
        'linkedin_post_url',
        !!cookies.get('linkedin_post_url')
          ? JSON.stringify({ ...oldValue, ...v2 })
          : JSON.stringify(v2),
        { path: '/', maxAge: 60 * 60 * 24 * 365, secure: true },
      );
      dispatch(create(v2));
    } catch (e) {
      let { isStreamToolActive } =
        store.getState().streamTools?.[match.params.bossId];
      if (!isStreamToolActive) {
        await unitService.stopUnit(match.params.bossId);
      }
      errorBlock(e, match.params.bossId);
      dispatch(status({ id: '', status: 'DELETED' }));
    }
  };
  function create(Data) {
    return { type: linkedinConstants.LI_CREATE_POST, Data };
  }
  function status(Data) {
    return { type: linkedinConstants.LI_POST_STATUS, Data };
  }
}

function checkFbPostStatus(selectedDestination, bossId) {
  return async (dispatch) => {
    try {
      const data = await dispatch(
        FBAction.fbLiveVideoStatus(
          selectedDestination.streaming_destination.external_id,
          bossId,
          selectedDestination?.fb_page_id,
        ),
      );
      if (data.status !== 'VOD' && data.status !== 'DELETED') {
        dispatch(startUnit(bossId));
      } else {
        notify('error', i18n.t('STREAMUNPUBLISHLIVEVIDEOS'));
      }
    } catch (e) {
      if (!!e.response && e.response.status === 100) {
        notify('error', i18n.t('OBJECTDOESNOTEXIT'));
      }
      errorBlock(e, bossId);
    }
  };
}

function checkYtPostStatus(selectedDestination, bossId) {
  return async (dispatch) => {
    try {
      const data = await dispatch(
        YTAction.getYTEventDetails(
          selectedDestination.streaming_destination.external_id,
          bossId,
        ),
      );
      if (data.id) {
        dispatch(startUnit(bossId));
        const ytStatus =
          data.contentDetails.monitorStream.embedHtml === undefined
            ? 'live'
            : 'testing';
        if (
          data.snippet.isDefaultBroadcast === false &&
          data.status.lifeCycleStatus === 'ready'
        ) {
          const ytinterval = setInterval(async () => {
            const res = await unitService.getChannelServicesAndDestination(
              bossId,
            );
            if (
              res.status === 'streaming' &&
              data.status.lifeCycleStatus === 'ready' &&
              !data.contentDetails.enableAutoStart
            ) {
              setTimeout(async () => {
                const ytdata = await dispatch(
                  YTAction.ytBroadcastTransition(
                    selectedDestination.streaming_destination.external_id,
                    ytStatus,
                    bossId,
                  ),
                );
                if (ytdata.id) {
                  notify('success', i18n.t('MODESUCCESSFULLYCHANGED'));
                  clearInterval(ytinterval);
                }
              }, 5000);
            }
          }, 10000);
        }
      }
    } catch (e) {
      if (!!e.response && e.response.status === 100) {
        notify('error', i18n.t('OBJECTDOESNOTEXIT'));
      }
      errorBlock(e, bossId);
    }
  };
}

function stopFbLiveStream(selectedDestination, bossId) {
  return async (dispatch) => {
    try {
      const data = await dispatch(
        FBAction.fbLiveVideoStatus(
          selectedDestination.streaming_destination.external_id,
          bossId,
          selectedDestination?.fb_page_id,
        ),
      );
      if (data.status === 'LIVE') {
        const payload = { end_live_video: true };
        await dispatch(
          FBAction.fbUpdateLiveVideoStatus(
            selectedDestination.streaming_destination.external_id,
            payload,
            bossId,
            selectedDestination?.fb_page_id,
          ),
        );
        await unitService.stopUnit(bossId);
      }
      await unitService.stopUnit(bossId);
    } catch (e) {
      await unitService.stopUnit(bossId);
      errorBlock(e, bossId);
    }
  };
}

//publish periscope broadcast
function periscopestart(selectedDestination, unitId) {
  return async (dispatch) => {
    try {
      // const res = await periscopeTwitterService.getPeriscopeStatus(
      //   selectedDestination.streaming_destination.external_id,
      // );
      // if (res.state !== 'running') {
      //   if (res.state !== 'ended') {
      //     dispatch(startUnit(unitId));
      //     const payload = {
      //       broadcast_id: selectedDestination.streaming_destination.external_id,
      //       title: selectedDestination.name,
      //     };
      //     await periscopeTwitterService.publishPeriscopeBroadcast(payload);
      //   } else {
      //     notify('error', i18n.t('ALREADYSTREAMED'));
      //   }
      // } else {
      //   notify('error', i18n.t('ALREADYSTREAMED'));
      // }
      dispatch(startUnit(unitId));
      const payload = {
        broadcast_id: selectedDestination.streaming_destination.external_id,
        title: selectedDestination.name,
        should_not_tweet:
          cookies.get('should_not_tweet') === 'true' ? true : false,
        locale: 'en_US',
        state: 'PUBLISH',
      };
      const broadcastResult =
        await periscopeTwitterService.publishTwitterBroadcast(payload);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('twitter_stream_state', broadcastResult.broadcast.state, {
        path: '/',
        expires: d,
        secure: true,
      });
    } catch (error) {
      errorBlock(error);
    }
  };
}

//stop stream
function stopStream(unit) {
  const initLoaderValue =
    store.getState().common.unitStreamLoader[
      cookies.get('currentSelectedUnit')
    ];
  const checkValue = !!initLoaderValue;
  return async (dispatch) => {
    try {
      dispatch(
        showUnitLoader({ [cookies.get('currentSelectedUnit')]: !checkValue }),
      );
      await dispatch(stopUnit(unit.BOSSID));
    } catch (err) {
      dispatch(
        showUnitLoader({ [cookies.get('currentSelectedUnit')]: !checkValue }),
      );
    }
  };
  function showUnitLoader(Data) {
    return { type: commonConstants.UNIT_STREAM_LOADING, Data };
  }
}

function switchToDestinationForTermination(
  selectedDestination,
  ovpCredentials,
  id,
) {
  return async (dispatch) => {
    if (selectedDestination) {
      switch (
        selectedDestination.streaming_destination.streaming_provider.toLowerCase()
      ) {
        case 'wowza':
          if (!ovpCredentials && !cookies.get('wowza_long_token')) {
            history.push(`/dashboard/wowzartmp/${id}`);
            return;
          } else if (!JSON.parse(ovpCredentials)[id + '_wowza']) {
            history.push(`/dashboard/wowza/${id}`);
            return;
          }
          await unitService.stopUnit(id);
          dispatch(wowzastop(JSON.parse(ovpCredentials)[id + '_wowza']));
          break;
        case 'periscope and twitter':
          await unitService.stopUnit(id);
          dispatch(periscopestop(selectedDestination, id));
          break;
        case 'vimeo':
          await unitService.stopUnit(id);
          const streamId =
            selectedDestination.streaming_destination.external_id;
          if (
            !!cookies.get(`isVimeoLiveEvent_${id}`) &&
            cookies.get(`isVimeoLiveEvent_${id}`)
          ) {
            await dispatch(vimeoAction.updateStatus(streamId, 'end'));
          }
          break;
        case 'facebook':
          dispatch(stopFbLiveStream(selectedDestination, id));
          break;
        case 'youtube':
          dispatch(stopYTLiveStream(selectedDestination, id));
          break;
        case 'linkedin':
          dispatch(stopLinkedinStream(selectedDestination, id));
          break;
        default:
          await unitService.stopUnit(id);
          break;
      }
    } else {
      await unitService.stopUnit(id);
    }
  };
}

//stop unit
function stopUnit(id) {
  const initLoaderValue =
    store.getState().common.unitStreamLoader[
      cookies.get('currentSelectedUnit')
    ];
  const checkValue = !!initLoaderValue;
  const { isStreamToolActive, selectedDestinations } =
    store.getState().streamTools?.[id];
  const { selectedDestination: lucDest } = store.getState().destination;
  const { stopStreamTools } = liveStreamActions;
  return async (dispatch) => {
    try {
      const { ovpCredentials } = store.getState().authentication.user;
      if (isStreamToolActive) {
        dispatch(showLoaderForStreamTools(id, 1));

        const destinations = selectedDestinations;
        destinations.forEach((selectedDestination) => {
          dispatch(
            switchToDestinationForTermination(
              selectedDestination,
              ovpCredentials,
              id,
            ),
          );
        });
        await dispatch(streamToolsQueue(stopStreamTools, [id]));
        // await dispatch(liveStreamActions.stopStreamTools(id));
        dispatch(showLoaderForStreamTools(id, 2));
      } else {
        const { selectedDestination } = store.getState().destination;
        await dispatch(
          switchToDestinationForTermination(
            selectedDestination,
            ovpCredentials,
            id,
          ),
        );
      }

      dispatch(showLoaderForStreamTools(id, 7));
      /**
       * Delete destination from store (streamTools & selectedDestinations)
       */
      if (isStreamToolActive && lucDest) {
        await dispatch(
          destinationActions.deleteSingleDestination(lucDest?.id, id),
        );

        await dispatch(
          destinationActions.getDestinationDetails(null, id, true),
        );
      }
      await unitService.stopUnit(id);
      await dispatch(updateUnits());
      dispatch(showLoaderForStreamTools(id, 0));
      notify('success', i18n.t('STREAMINGSTOPSUCCESS'));
    } catch (err) {
      await unitService.stopUnit(id);
      dispatch(
        showUnitLoader({ [cookies.get('currentSelectedUnit')]: !checkValue }),
      );
    }
  };

  function showUnitLoader(Data) {
    return { type: commonConstants.UNIT_STREAM_LOADING, Data };
  }

  function showLoaderForStreamTools(bossId, progress) {
    return {
      type: streamToolConstants.SET_GOLIVE_STAGE,
      payload: { bossId, progress },
    };
  }
}

//stop wowza stream
function wowzastop(token) {
  return async () => {
    try {
      const res = await getTranscoder(token);
      if (res.data.transcoder.state === 'started') {
        await wowzaStop(token, token);
      } else {
      }
    } catch (error) {
      errorBlock(error);
    }
  };
}

//stop periscope broadcast
function periscopestop(selectedDestination, bossId) {
  return async () => {
    try {
      // const res = await periscopeTwitterService.getPeriscopeStatus(
      //   selectedDestination.streaming_destination.external_id,
      // );
      // if (res.state === 'running') {
      //   const payload = {
      //     broadcast_id: selectedDestination.streaming_destination.external_id,
      //   };
      //   await periscopeTwitterService.stopPeriscopeBroadcast(payload);
      // }

      const payload = {
        broadcast_id: selectedDestination.streaming_destination.external_id,
        title: selectedDestination.name,
        should_not_tweet:
          cookies.get('should_not_tweet') === 'true' ? true : false,
        locale: 'en_US',
        state: 'END',
      };
      await periscopeTwitterService.publishTwitterBroadcast(payload);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('twitter_stream_state', 'END', {
        path: '/',
        expires: d,
        secure: true,
      });
      cookies.remove('should_not_tweet', { path: '/' });
      cookies.remove('twitter_stream_state', { path: '/' });
      // delete cookies.get('should_not_tweet');
      // delete cookies.get('twitter_stream_state');
    } catch (error) {
      errorBlock(error, bossId);
    }
  };
}

//stop youtube broadcast
function stopYTLiveStream(selectedDestination, bossId) {
  return async (dispatch) => {
    try {
      const data = await dispatch(
        YTAction.getYTEventDetails(
          selectedDestination.streaming_destination.external_id,
          bossId,
        ),
      );
      if (
        !!data.status &&
        !!data.status.lifeCycleStatus &&
        data.status.lifeCycleStatus === 'live'
      ) {
        await dispatch(
          YTAction.ytBroadcastTransition(
            selectedDestination.streaming_destination.external_id,
            'complete',
            bossId,
          ),
        );
        await unitService.stopUnit(bossId);
      }
      await unitService.stopUnit(bossId);
    } catch (e) {
      await unitService.stopUnit(bossId);
      errorBlock(e, bossId);
    }
  };
}

function registerLucUnit(value) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      dispatch(request());
      const { customer, customerDetails } = store.getState().payment;
      if (customer) {
        if (customerDetails.address) {
          const { address } = customerDetails;
          value.city = address.city && address.city;
          value.country.value = address.country && address.country;
          value.address1 = address.line1 && address.line1;
          value.address2 = address.line2 && address.line2;
          value.state = address.state && address.state;
          value.zipcode = address.postal_code && address.postal_code;
        }
      }
      const result = await unitService.registerUnit(value.reg_code);
      if (result.data) {
        let payload = {
          action: 'Activation',
          address_city: value.city,
          address_country: value.country.value,
          address_line1: value.address1,
          address_line2: value.address2,
          address_state: value.state,
          address_zip: value.zipcode,
          amount: 0,
          tax: 0,
          currency_code: '',
          deal_number: Math.floor(Date.now() / 1000),
          email: cookies.get('username'),
          extra_hours: 0,
          hours: 0,
          interval: 12,
          number: value.reg_code,
          paid: true,
          part_number: 'LU-Activation',
          registrationkey: '',
          service: 'SLA',
          unit_id: '',
          referral_code: value.referral_code ? value.referral_code : null,
          reg_code: value?.reg_code,
        };
        const { _units } = await unitService.getUnits();
        dispatch(setStreamtoolsReduxState(_units));
        const sfresult = await salesforceService.salesforceRegisterUnit(
          payload,
        );
        if (sfresult.result !== 'success') {
          const rollbarObj = !!window.rollbarObj ? window.rollbarObj : null;
          !!rollbarObj &&
            rollbarObj.warning(sfresult.result, {
              description: sfresult.result,
              user: cookies.get('username'),
              unitId: value.reg_code,
            });
        }
        // customer going to create or update billing address after salesforce entry so that saleforce entry and billing address should be same
        if (customer) {
          if (!customerDetails.address || !customerDetails.address.country) {
            const payload = {
              business_name: '',
              line1: value.address1,
              line2: value.address2,
              city: value.city,
              state: value.state,
              country: value.country.value,
              postal_code: value.zipcode,
            };
            await paymentService.updateCustomer(payload);
          }
        } else {
          const payload = {
            reg_code: value.reg_code,
            city: value.city,
            country: value.country.value,
            add_line1: value.address1,
            add_line2: value.address2,
            zipcode: value.zipcode,
            state: value.state,
            referral_code: value.referral_code ? value.referral_code : null,
          };
          await paymentService.createCustomer(payload);
        }
        const unitresult = await unitService.getUnitsName();
        var unit = filter(unitresult.data.data.units, function (o) {
          return o.reg_code === value.reg_code;
        });
        dispatch(hideLoader());
        dispatch(success());
        if (unit.length > 0) {
          history.push({
            pathname: '/dashboard/unitservices/' + unit[0].BOSSID,
            state: { unitNum: value.reg_code },
          });
        } else {
          history.push('/dashboard/units');
        }
      }
    } catch (e) {
      dispatch(hideLoader());
      errorBlock(e);
    }
  };
  function success(user) {
    return { type: 'REGISTER_UNIT_SUCCESS', user };
  }
  function request(user) {
    return { type: 'REGISTER_UNIT_REQUEST', user };
  }
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
  function setStreamtoolsReduxState(units) {
    return { type: SET_ST_INITIAL_STATE, units };
  }
}

function getUnitInterfaces(id) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const interfaces = await unitService.getInterfaces(id);
      if (interfaces.data) {
        dispatch(success(interfaces.data, interfaces.data[0]?.port));
        dispatch(getUnitInterfaceDetails(id, interfaces.data[0]?.port, 1));
      } else {
        dispatch(success([]));
      }
    } catch (err) {
      dispatch(failure());
      errorBlock(err);
    }
  };
  function success(interfaces) {
    return { type: GET_INTERFACE_SUCCESS, interfaces };
  }
  function request() {
    return { type: GET_INTERFACE_REQUEST };
  }
  function failure() {
    return { type: GET_INTERFACE_FAILURE };
  }
}

function getUnitInterfaceDetails(id, selectedinterfaceport, count, type) {
  return async (dispatch) => {
    dispatch(request());
    try {
      let path;
      let subpath;
      if (selectedinterfaceport?.indexOf('eth') !== -1) {
        path = 'eth';
        subpath = 'config';
      } else if (selectedinterfaceport.indexOf('wlan') !== -1) {
        path = 'wlan';
        subpath = 'wifi';
      } else {
        path = 'modem';
        subpath = 'config';
      }
      const details = await unitService.getInterfaceDetails(
        id,
        path,
        selectedinterfaceport,
        subpath,
      );
      if (details.data && details.data.length) {
        // eslint-disable-next-line array-callback-return
        details.data.filter((value) => {
          if (includes(value.ssid, '\x00') || includes(value.ssid, '\\x00')) {
            try {
              let decodedSSID = utf8.decode(value.ssid);
              if (includes(value.ssid, '\u0000')) {
                // eslint-disable-next-line no-control-regex
                decodedSSID = decodedSSID.replace(/\u0000/g, '');
              }
              if (includes(value.ssid, '\\x00')) {
                decodedSSID = decodedSSID.replace(/\\x00/g, '');
              }

              value.ssid = decodedSSID;
            } catch (e) {
              return value.ssid;
            }
          }
        });
      }

      if (details.data && selectedinterfaceport.indexOf('wlan') === -1) {
        dispatch(success(details.data));
      } else if (
        details.data.length &&
        selectedinterfaceport.indexOf('wlan') !== -1
      ) {
        var connectedWifi = filter(details.data, function (o) {
          return o.status === 'Connected';
        });
        if (!connectedWifi.length) {
          var associatingWifi = filter(details.data, function (o) {
            return o.status === 'Associating';
          });
          if (associatingWifi.length) {
            setTimeout(() => {
              dispatch(
                getUnitInterfaceDetails(
                  id,
                  selectedinterfaceport,
                  count + 1,
                  type,
                ),
              );
            }, 2000);
          } else {
            if (type === 'edit') notify('error', i18n.t('CONNECTIONFAILED'));
          }
          dispatch(success(details.data));
        } else {
          if (type === 'edit')
            notify('success', i18n.t('CONNECTEDSUCCESSFULLY'));
        }
        dispatch(success(details.data));
      } else {
        if (selectedinterfaceport.indexOf('wlan') !== -1 && count <= 8) {
          setTimeout(() => {
            dispatch(
              getUnitInterfaceDetails(
                id,
                selectedinterfaceport,
                count + 1,
                type,
              ),
            );
          }, 2000);
        } else {
          dispatch(success({}));
        }
      }
    } catch (err) {
      dispatch(failure());
      errorBlock(err);
    }
  };

  function success(details) {
    return { type: GET_INTERFACE_DETAILS_SUCCESS, details };
  }
  function request() {
    return { type: GET_INTERFACE_DETAILS_REQUEST };
  }
  function failure() {
    return { type: GET_INTERFACE_DETAILS_FAILURE };
  }
}

function editUnitInterfaceDetails(id, payload) {
  return async (dispatch) => {
    try {
      let path;
      let { port } = payload;
      let subpath;
      if (payload.port.indexOf('eth') !== -1) {
        path = 'eth';
        subpath = 'config';
        await unitService.editInterfaceDetails(
          payload,
          id,
          path,
          port,
          subpath,
        );
        notify('success', i18n.t('SETTINGSAAPPLIEDMESSAGES'));
        dispatch(getUnitInterfaceDetails(id, port, 1));
      } else {
        path = 'modem';
        subpath = 'config';
        await unitService.editInterfaceDetails(
          payload,
          id,
          path,
          (port - 1).toString(),
          subpath,
        );
        notify('success', i18n.t('SETTINGSAAPPLIEDMESSAGES'));
        dispatch(getUnitInterfaceDetails(id, (port - 1).toString(), 1));
      }
    } catch (err) {
      //dispatch(failure());
      errorBlock(err);
    }
  };
}

function editUnitWifiDetails(id, payload, port, ssid) {
  return async (dispatch) => {
    try {
      let path = 'wlan';
      let subpath = 'wifi';
      await unitService.editWifiDetails(payload, id, path, port, subpath, ssid);
      notify('info', i18n.t('TRYINGTOCONNECTTONETWORK'));
      setTimeout(async () => {
        const details = await unitService.getInterfaceDetails(
          id,
          path,
          port,
          subpath,
        );
        if (details.data.length) {
          var connectedWifi = filter(details.data, function (o) {
            return o.status === 'Connected';
          });
          if (!connectedWifi.length) {
            var associatingWifi = filter(details.data, function (o) {
              return o.status === 'Associating';
            });
            if (associatingWifi.length) {
              setTimeout(() => {
                dispatch(getUnitInterfaceDetails(id, port, 2, 'edit'));
              }, 2000);
            } else {
              notify('error', i18n.t('CONNECTIONFAILED'));
            }
          } else {
            notify('success', i18n.t('CONNECTEDSUCCESSFULLY'));
          }
          dispatch(success(details.data));
        } else {
          setTimeout(() => {
            dispatch(getUnitInterfaceDetails(id, port, 2));
          }, 2000);
        }
      }, 5000);
    } catch (err) {
      errorBlock(err);
    }
  };
  function success(details) {
    return { type: GET_INTERFACE_DETAILS_SUCCESS, details };
  }
}

function getUnitsNameList() {
  return async (dispatch) => {
    dispatch(request());
    try {
      const unitsList = await unitService.getUnitsName();
      dispatch(success(unitsList.data.data.units));
    } catch (err) {}
  };

  function success(units) {
    return { type: GET_UNITS_SUCCESS, units };
  }
  function request() {
    return { type: GET_UNITS_REQUEST };
  }
}

function getUnitServices(id) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const res = await unitService.getChannelServicesAndDestination(id);
      dispatch(success(res));
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };

  function request() {
    return { type: GET_CHANNEL_SERVICE_DES_REQUEST };
  }
  function success(res) {
    return { type: GET_CHANNEL_SERVICE_DES_SUCCESS, res };
  }
  function failure(error) {
    return { type: GET_CHANNEL_SERVICE_DES_ERROR, error };
  }
}

function unitSettings() {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const res = await unitService.getUnitsName();
      const { units } = res.data.data;
      let array = [];
      var online_units = filter(units, function (o) {
        return o.status !== 'offline' && o.reg_code.substr(0, 2) !== 'sa';
      });
      if (online_units.length === 0) {
        dispatch(userActions.logout());
        dispatch(hideLoader());
      } else {
        online_units.map(async (unit, index) => {
          const unitdetails =
            await unitService.getChannelServicesAndDestination(unit.BOSSID);
          array.push({
            unit_id: unit.BOSSID,
            destination_id: unitdetails.selected_destination,
            lowdelay: unitdetails.delay ? unitdetails.delay.toString() : '',
            lrtchannel: unitdetails.selected_channel,
            sw_version: unitdetails.sw_version,
            inventory_id: cookies.get('inventory_id'),
          });
          if (index + 1 === online_units.length || array.length === 20) {
            var payload = { units: array };
            try {
              await unitService.addUnitSettings(payload);
              await unitService.clearAccessToken();
              if (index + 1 === online_units.length) {
                dispatch(userActions.logout());
                dispatch(hideLoader());
              } else {
                array = [];
              }
            } catch (e) {
              dispatch(hideLoader());
              dispatch(userActions.logout());
              errorBlock(e);
            }
          }
        });
      }
      const streamToolsConfigList = store.getState().streamTools;
      dispatch(streamToolsQueue(() => () => {}, [], true));
      for (const bossid in streamToolsConfigList) {
        const config = streamToolsConfigList[bossid];
        if (config?.socket?.readyState === 1) {
          config.socket.close();
        }
      }
    } catch (e) {
      dispatch(hideLoader());
      dispatch(userActions.logout());
      errorBlock(e);
    }
  };

  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function getAwsUnitSettings() {
  return async (dispatch) => {
    try {
      const res = await unitService.getUnitSettings();
      dispatch(success(res));
    } catch (err) {
      errorBlock(err);
    }
  };

  function success(res) {
    return { type: GET_UNIT_SETTINGS_SUCCESS, res };
  }
}

function restoreUnitSettings(awsunits, lucunits) {
  return async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let array = [];
        lucunits.map(async (luc, index) => {
          awsunits.map(async (aws) => {
            if (luc.id === aws.unit_id && luc.sw_version !== aws.sw_version) {
              var payload = {
                unit: {
                  selected_destination: aws.destination_id,
                  selected_channel: aws.lrtchannel,
                  delay: aws.lowdelay,
                },
              };
              await unitService.updateUnitName(luc.id, payload);
              array.push({
                unit_id: luc.id,
                destination_id: aws.destination_id,
                lowdelay: aws.lowdelay ? aws.lowdelay.toString() : '',
                lrtchannel: aws.lrtchannel,
                sw_version: luc.sw_version,
                inventory_id: cookies.get('inventory_id'),
              });
              if (index + 1 === lucunits.length || array.length === 20) {
                payload = { units: array };
                const settings = await unitService.addUnitSettings(payload);
                array = [];
                if (index + 1 === lucunits.length) {
                  resolve(settings);
                }
              }
            }
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  };
}

function cancelUnitSettings(awsunits, lucunits) {
  return async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let array = [];
        let value = 0;
        lucunits.map(async (luc, index) => {
          const dest = await unitService.getChannelServicesAndDestination(
            luc.id,
          );
          value++;
          awsunits.map(async (aws) => {
            if (luc.id === aws.unit_id && luc.sw_version !== aws.sw_version) {
              array.push({
                unit_id: luc.id,
                destination_id: dest.selected_destination,
                lowdelay: luc.delay ? luc.delay.toString() : '',
                lrtchannel: luc.selected_channel,
                sw_version: luc.sw_version,
                inventory_id: cookies.get('inventory_id'),
              });
              if (value === lucunits.length || array.length === 20) {
                var payload = { units: array };
                const settings = await unitService.addUnitSettings(payload);
                array = [];
                if (index + 1 === lucunits.length) {
                  resolve(settings);
                }
              }
            }
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  };
}
function removeUnit(id) {
  return async (dispatch) => {
    dispatch(lrtloading());
    try {
      const res = await unitService.removeUnit(id);
      if (res) {
        dispatch(stoploading());
      } else {
      }
    } catch (error) {
      dispatch(stoploading());
      errorBlock(error);
    }
  };
  function lrtloading() {
    return { type: LRT_LOADING };
  }
  function stoploading() {
    return { type: ADD_UNIT_LOADING_STOP };
  }
}

function setUnitLoaderState(bossId, loaderState) {
  return (dispatch) => {
    dispatch({
      type: SET_UNIT_LOADER_STATE,
      payload: {
        bossId,
        loaderState,
      },
    });
  };
}

function storeSelectorValue(value) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTOR_VALUE,
      value,
    });
  };
}

function sendUnitLogs(id, payload) {
  return async () => {
    try {
      const res = await unitService.sendUnitLogs(id, payload);
      if (res) {
        notify('success', i18n.t('SENDUNITLOGSUCCESS'));
      }
      return res;
    } catch (err) {
      const errMessage = err.response.data.description;
      !!errMessage && notify('error', errMessage);
      return false;
    }
  };
}
