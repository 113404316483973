//import { CHANGE_LANGUAGE } from '../constants';
const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
const initialState = { language: 'en' };

export const userLngPreferences = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return { ...state, language: action.payload };
    }
    default:
      return state;
  }
};
