import { paymentConstants } from '../constants';
const { GET_CURRENCY_REQUEST, GET_CURRENCY_SUCCESS, GET_CURRENCY_ERROR } =
  paymentConstants;

const initialState = {
  currency: { amount: 1, currency: 'USD' },
  isLoading: true,
};

export function currency(state = initialState, payload) {
  switch (payload.type) {
    case GET_CURRENCY_REQUEST:
      return {
        ...state,
      };
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        currency: payload.res,
        isLoading: false,
      };
    case GET_CURRENCY_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
