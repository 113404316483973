import React from 'react';
import { AppVersion } from '../AppVersion/AppVersion';
export const Footer = ({ t }) => {
  return (
    <footer className='d-flex px-2'>
      <div className='ml-auto'>
        <AppVersion t={t} />
      </div>
    </footer>
  );
};
