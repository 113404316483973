import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { ProfileSettings } from '../../ProfileSettings';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import * as antDesign from 'antd';
import moment from 'moment';
import fbImg from '../../../assets/images/facebook-stream.png';
import includeImg from '../../../assets/images/location-y.png';
import excludeImg from '../../../assets/images/location-x.png';
import 'antd/dist/antd.css';
import find from 'lodash/find';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { notify } from '../../CommonNotification/CommonNotification';
import orderBy from 'lodash/orderBy';
import _ from 'lodash';
import aduioBitRateOptions from '../../../data/aduioBitRateOptions.json';
import { Link } from 'react-router-dom';
import { liveStreamActions } from '../../../actions';
import StreamToolsProfile from '../../StreamtoolsProfile/StreamToolsProfile';

const {
  DatePicker,
  Select: AntSelect,
  Spin,
  Table: AntTable,
  Avatar,
} = antDesign;
const { Option } = AntSelect;
let crossPostTableData = [];

let privacyOptions = [
  { label: 'Only Me', value: 'SELF' },
  { label: 'Public', value: 'EVERYONE' },
  { label: 'Friends', value: 'ALL_FRIENDS' },
];
privacyOptions = orderBy(privacyOptions, ['label'], ['asc']);
let publishModeOptions = [
  { label: 'Live', value: 'LIVE_NOW' },
  { label: 'Preview', value: 'UNPUBLISHED' },
];
publishModeOptions = orderBy(publishModeOptions, ['label'], ['asc']);
const audienceAgeOptions = [
  { label: 'Everyone', value: 0 },
  { label: '13+', value: 13 },
  { label: '15+', value: 15 },
  { label: '18+', value: 18 },
  { label: '21+', value: 21 },
  { label: '25+', value: 25 },
];
let includedPlaces = [];
let excludedPlaces = [];
let includedCountries = [];
let includedRegions = [];
let includedCities = [];
let excludedCountries = [];
let excludedCities = [];
let excludedRegions = [];

export const FacebookView = ({
  t,
  match,
  history,
  profileOptions,
  submitHandler,
  bitrateDisabled,
  streamLocationOptions,
  friendsSearch,
  friendsData,
  adTags,
  adData,
  defaultOption,
  crossPostingData,
  checkCrossPosting,
  currentUnitData,
  adGeoLocationData,
  adGeoLocationSearch,
  fbLogout,
  crossPostError,
  isEdit,
  editData,
  userDetails,
  groupsByPage,
  getgroupsByPage,
  fbImageUpload,
  isStreamToolActive,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const aduioBitRateOptions = aduioBitRateOption;

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  function back() {
    history.push('/dashboard/edit/' + match.params.bossId);
  }
  const isEditDefaultTime = () => {
    return moment(editData.postStatus.planned_start_time);
  };
  const defaultTime = () => {
    //return  moment(moment().year() +'-'+ (moment().month()+1) +'-'+moment().date() +' '+ moment().hours() + ':' + (moment().add(10, 'minutes').minutes()) + ':' + moment().seconds(), 'YYYY-MM-DD hh:mm a')
    return moment(
      moment().year() +
        '-' +
        (moment().month() + 1) +
        '-' +
        moment().date() +
        ' ' +
        moment().hours() +
        ':' +
        moment().minutes(),
      'YYYY-MM-DD hh:mm a',
    ).add(12, 'minutes');
  };
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current) {
    const startday = moment().startOf('day');
    const endday = moment().add(7, 'days');
    return !(startday.isBefore(current) && endday.isAfter(current));
  }

  function defaultFriends() {
    let v1 = [];
    if (!!editData.videoStatus.tags) {
      if (!!editData.videoStatus.tags.data) {
        // eslint-disable-next-line array-callback-return
        editData.videoStatus.tags.data.filter((v) => {
          v1.push({
            label: v.name,
            key: v.id,
          });
        });
      }
    }
    return v1;
  }

  function disabledDateTime(current) {
    const date = moment();
    const disableHour = current.date() === date.date() ? date.hours() : 0;
    const disableMin =
      current.date() === date.date()
        ? current.hour() === date.hour()
          ? date.minutes() + 12
          : 0
        : 0;
    return {
      disabledHours: () => range(0, 24).splice(0, disableHour),
      disabledMinutes: () => range(0, disableMin),
      //disabledSeconds: () => [0, 0],
    };
  }

  function ovpProfile(value) {
    const result = _.chain(profileOptions)
      .map((i) => i.options || [])
      .flatten()
      .uniqBy('name')
      .filter((i) => i.label === value)
      .first()
      .value();
    return result;
  }

  function defaultPublishMode(value) {
    const v1 = publishModeOptions.filter((v) => {
      return v.value === value;
    });
    return v1[0];
  }

  function defaultPrivacy(option) {
    const t1 = privacyOptions.filter((value) => value.value === option);
    return t1[0];
  }

  function defaultStreamOption(option) {
    let t1 = [];
    // eslint-disable-next-line array-callback-return
    streamLocationOptions.filter((value) => {
      // eslint-disable-next-line array-callback-return
      value.options.filter((inner) => {
        if (inner.id === option) {
          t1.push(inner);
        }
      });
    });
    if (t1.length) {
      return t1[0];
    } else {
      return {
        label: 'Personal profile',
        type: streamLocationOptions[0].options[0]
          ? streamLocationOptions[0].options[0].type
          : '',
        value: streamLocationOptions[0].options[0].value
          ? streamLocationOptions[0].options[0].value
          : '',
        access_token: streamLocationOptions[0].options[0].access_token
          ? streamLocationOptions[0].options[0].access_token
          : '',
      };
    }
  }

  const [activeTab, setActiveTab] = useState('basics');
  // let groupColumns = [{
  //   title: t('NAME'),
  //   dataIndex: 'GroupName',
  // }]

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    includedPlaces = [];
    excludedPlaces = [];
    includedCountries = [];
    includedRegions = [];
    includedCities = [];
    excludedCountries = [];
    excludedCities = [];
    excludedRegions = [];
  }, []);
  let crossPostColumns = [];

  (function crossPostData() {
    crossPostTableData = [];
    // eslint-disable-next-line array-callback-return
    crossPostingData.filter((v, index) => {
      crossPostTableData.push({
        key: index,
        pageName: v.name,
        crossPost: '',
        crossPostAndCreate: '',
        pageId: v.id,
      });
    });
  })();
  return (
    <div className='facebookstream pagewrap'>
      <div className='container'>
        <div className='pageWelcomeTxt'>{t('SETUPFB')}</div>
        <div className='page-title'>
          <h4>{t('FBLIVEDESTINATION')}</h4>

          {/* 
                        <button type="button" className="btn btn-primary pull-right subbtn" type="submit">{t('CREATEEVENT')}</button>
                     */}

          <button
            type='button'
            className='btn btn-primary pull-right backbtn'
            onClick={() => {
              back();
            }}
          >
            {t('BACK')}
          </button>
          <div className='pull-right profileui d-flex flex-row align-items-center mt-2'>
            <div className='profileImg'>
              <img
                src={userDetails.picture && userDetails.picture.data.url}
                alt='userDetail'
              />
            </div>
            <span className='profileName'>{userDetails.name}</span>
          </div>
        </div>
        <div className='page-wrap'>
          <div className='card-body'>
            {
              <Formik
                initialValues={{
                  destination: isEdit
                    ? !!editData.dest && !!editData.dest.title
                      ? editData.dest.title
                      : ''
                    : '',
                  profile: isStreamToolActive
                    ? liveStreamActions.getProfileValue('facebook')
                    : isEdit
                    ? ovpProfile(
                        !!editData.dest && !!editData.dest.streaming_profile
                          ? editData.dest.streaming_profile
                          : '1280 x 720 Widescreen (16:9)',
                      )
                    : !!currentUnitData.selected_profile
                    ? ovpProfile(currentUnitData.selected_profile)
                    : {
                        label: '1280 x 720 Widescreen (16:9)',
                        name: '1280 x 720 Widescreen (16:9)',
                        value: '1280 x 720 Widescreen (16:9)',
                      },
                  streamLocation:
                    isEdit || !!match.params.fbId
                      ? defaultStreamOption(match.params.fbId)
                      : defaultOption.type !== 'me'
                      ? defaultOption
                      : {
                          label: 'Personal profile',
                          type:
                            streamLocationOptions[0].options.length &&
                            streamLocationOptions[0].options[0].type,
                          value:
                            streamLocationOptions[0].options.length &&
                            streamLocationOptions[0].options[0].value,
                          access_token:
                            streamLocationOptions[0].options.length &&
                            streamLocationOptions[0].options[0].access_token,
                        },
                  privacy: isEdit
                    ? !!editData.videoStatus && !!editData.videoStatus.privacy
                      ? defaultPrivacy(editData.videoStatus.privacy.value)
                      : {
                          label: 'Only Me',
                          value: 'SELF',
                        }
                    : { label: 'Only Me', value: 'SELF' },
                  description: isEdit
                    ? !!editData.videoStatus &&
                      !!editData.videoStatus.description
                      ? editData.videoStatus.description
                      : ''
                    : '',
                  scheduleLive: isEdit
                    ? !!editData.postStatus && !!editData.postStatus.status
                      ? editData.postStatus.status === 'SCHEDULED_UNPUBLISHED'
                      : false
                    : false,
                  scheduledTime: isEdit
                    ? moment(
                        moment(
                          !!editData.postStatus &&
                            !!editData.postStatus.planned_start_time
                            ? editData.postStatus.planned_start_time
                            : defaultTime(),
                          'YYYY-MM-DDTHH:mm:ssZZ',
                        ),
                        'YYYY-MM-DD hh:mm a',
                      ).valueOf()
                    : moment(new Date())
                        .add(12, 'minutes', 'YYYY-MM-DD hh:mm a')
                        .valueOf(),
                  scheduleImage: null,
                  // publishMode: !!currentUnitData.selected_mode
                  //   ? defaultPublishMode(currentUnitData.selected_mode)
                  //   : {
                  //       label: 'Live',
                  //       value: 'LIVE_NOW',
                  //     },
                  publishMode: isEdit
                    ? defaultPublishMode(editData?.postStatus?.status)
                    : {
                        label: 'Live',
                        value: 'LIVE_NOW',
                      },
                  geoLocation: [],
                  friends: isEdit ? defaultFriends() : [],
                  adInterest: isEdit ? editData.videoStatus.content_tags : [],
                  prohibitEmbedding: isEdit
                    ? !editData.videoStatus.embeddable
                    : false,
                  enableCrossPost: [],
                  enableCrossPostAndCreate: [],
                  crossPostPages: [],
                  audienceAge: { label: 'Everyone', value: 0 },
                  includeOrExclude: {
                    label: (
                      <div>
                        <span>
                          <img src={includeImg} alt='' />
                        </span>{' '}
                        {t('INCLUDE')}{' '}
                      </div>
                    ),
                    value: 'include',
                  },
                  includeLocation: [],
                  excludeLocation: [],
                  postSponsor: [],
                  includedGeoLocation: {
                    countries: [],
                    cities: [],
                    regions: [],
                  },
                  excludedGeoLocation: {
                    countries: [],
                    cities: [],
                    regions: [],
                  },
                  overrideFrame: !!currentUnitData.selected_framerate
                    ? {
                        label: currentUnitData.selected_framerate,
                        value: currentUnitData.selected_framerate,
                      }
                    : {
                        label: '12.48',
                        value: '12.48',
                      },
                  overrideResolution: !!currentUnitData.selected_overrideprofile
                    ? {
                        label: currentUnitData.selected_overrideprofile,
                        value: currentUnitData.selected_overrideprofile,
                      }
                    : {
                        label: '360x240',
                        value: '360x240',
                      },
                  overrideBitrate: !!currentUnitData.selected_bitrate
                    ? Number(currentUnitData.selected_bitrate)
                    : 400,
                  resolutionBox: !!currentUnitData.selected_overrideprofile,
                  bitRateBox: !!currentUnitData.selected_bitrate,
                  frameRateBox: !!currentUnitData.selected_framerate,
                  overrideErrors: !!currentUnitData.selected_bitrate
                    ? Number(currentUnitData.selected_bitrate) < 250
                      ? { overrideBitrate: 'invalid bitrate' }
                      : ''
                    : '',
                  audioBitRate: editData.dest.audio_bitrate_override
                    ? aduioBitRateOptions.options.filter((singleValue) => {
                        return (
                          singleValue.value ===
                          editData.dest.audio_bitrate_override
                        );
                      })[0]
                    : { label: '128kbps', value: '128000' },
                  audioBitRateBox: !!editData.dest.audio_bitrate_override,
                }}
                validationSchema={Yup.object({
                  destination: Yup.string()
                    .max(255, t('ELSTRERROR0'))
                    .trim()
                    .required('Video Title is required'),
                  profile: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  description: Yup.string()
                    .trim()
                    .required('Video Description is required'),
                  streamLocation: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    /*.test('test-name', t('CROSSPOSTERROR'),
                                            function () {
                                                return !crossPostError;

                                            }
                                        )*/
                    .required('Stream Location is required'),
                  privacy: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable()
                    .required(t('SELECTPRIVACY')),
                  scheduleLive: Yup.bool(),
                  scheduledTime: Yup.mixed()
                    .nullable()
                    .when('scheduleLive', {
                      is: true,
                      then: Yup.mixed().required(t('PLANNEDSTARTTIMEMESSAGE')),
                      otherwise: Yup.mixed().notRequired(),
                    }),
                  scheduleImage: Yup.mixed().test(
                    'test-name',
                    'Only JPEG,JPG and PNG images are allowed',
                    function (value) {
                      if (!!value) {
                        if (
                          value.type === 'image/jpeg' ||
                          value.type === 'image/jpg' ||
                          value.type === 'image/png'
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return true;
                      }
                    },
                  ),
                  publishMode: Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  }),
                  friends: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      }),
                    )
                    .nullable(),
                  adInterest: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      }),
                    )
                    .nullable(),
                  geoLocation: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  prohibitEmbedding: Yup.bool(),
                  enableCrossPostAndCreate: Yup.array().nullable(),
                  enableCrossPost: Yup.array().nullable(),
                  crossPostPages: Yup.array().nullable(),
                  audienceAge: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  postSponsor: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  includeOrExclude: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  includeLocation: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      }),
                    )
                    .nullable(),
                  excludeLocation: Yup.array()
                    .of(
                      Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string(),
                      }),
                    )
                    .nullable(),
                  includedGeoLocation: Yup.object().nullable(),
                  excludedGeoLocation: Yup.object().nullable(),
                  overrideFrame: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  overrideResolution: Yup.object()
                    .shape({
                      label: Yup.string(),
                      value: Yup.string(),
                    })
                    .nullable(),
                  overrideBitrate: Yup.number(),
                  resolutionBox: Yup.bool(),
                  bitRateBox: Yup.bool(),
                  frameRateBox: Yup.bool(),
                  overrideErrors: Yup.string(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  values.scheduledTime = values.scheduleLive
                    ? values.scheduledTime
                    : null;
                  values.description = values.description.trim();
                  values.destination = values.destination.trim();
                  submitHandler(values);
                }}
              >
                {(formik) => {
                  function inSelectedPlaces(obj) {
                    let results = [];
                    if (excludedPlaces.length) {
                      switch (obj.type) {
                        case 'city':
                          results = find(excludedPlaces, function (o) {
                            return o.key === obj.key;
                          });
                          if (results) {
                            notify(
                              'warning',
                              `${t('REMOVELOCATION')}${t('CONFLICT')}`,
                            );
                            return;
                          }
                          break;
                        case 'region':
                          results = find(excludedPlaces, function (o) {
                            return o.key === obj.key;
                          });
                          if (results) {
                            notify(
                              'warning',
                              `${t('REMOVELOCATION')}${t('CONFLICT')}`,
                            );
                            return;
                          }
                          break;
                        case 'country':
                          results = find(excludedPlaces, function (o) {
                            return o.key === obj.key;
                          });
                          if (results) {
                            notify(
                              'warning',
                              `${t('REMOVELOCATION')}${t('CONFLICT')}`,
                            );
                            return;
                          }
                          break;
                        default:
                          break;
                      }
                    }
                    if (includedPlaces.length) {
                      switch (obj.type) {
                        case 'city':
                          remove(includedPlaces, function (n) {
                            if (n.key === obj.key) {
                              return n.key === obj.key;
                            } else if (n.type === 'region') {
                              return n.key === obj.region_id;
                            } else if (n.type === 'country') {
                              return n.key === obj.country_code;
                            }
                          });
                          remove(includedPlaces, function (n) {
                            if (n.type === 'region') {
                              return n.key === obj.region_id;
                            } else if (n.type === 'country') {
                              return n.key === obj.country_code;
                            }
                          });
                          includedPlaces.push({
                            name: obj.name,
                            key: obj.key,
                            country_code: obj.country_code,
                            type: obj.type,
                            region_id: obj.region_id,
                          });
                          break;
                        case 'region':
                          remove(includedPlaces, function (n) {
                            if (n.key === obj.key) {
                              return n.key === obj.key;
                            } else if (n.type === 'city') {
                              return n.region_id === obj.key;
                            } else if (n.type === 'country') {
                              return n.key === obj.country_code;
                            }
                          });
                          remove(excludedPlaces, function (n) {
                            if (n.type === 'country') {
                              return n.key === obj.country_code;
                            }
                          });
                          includedPlaces.push({
                            name: obj.name,
                            key: obj.key,
                            country_code: obj.country_code,
                            type: obj.type,
                            region_id: obj.region_id,
                          });

                          break;
                        case 'country':
                          remove(includedPlaces, function (n) {
                            if (n.key === obj.key) {
                              return n.key === obj.key;
                            } else if (n.type === 'city') {
                              return n.country_code === obj.key;
                            } else if (n.type === 'region') {
                              return n.country_code === obj.key;
                            }
                          });
                          includedPlaces.push({
                            name: obj.name,
                            key: obj.key,
                            country_code: obj.country_code,
                            type: obj.type,
                            region_id: obj.region_id,
                          });
                          break;
                        default:
                          break;
                      }
                    } else {
                      includedPlaces.push({
                        name: obj.name,
                        key: obj.key,
                        country_code: obj.country_code,
                        type: obj.type,
                        region_id: obj.region_id,
                      });
                    }
                    includedCountries = map(
                      filter(includedPlaces, function (o) {
                        return o.type === 'country';
                      }),
                      'key',
                    );
                    includedRegions = map(
                      filter(includedPlaces, function (o) {
                        return o.type === 'region';
                      }),
                      function (y) {
                        return { key: y.key };
                      },
                    );
                    includedCities = map(
                      filter(includedPlaces, function (o) {
                        return o.type === 'city';
                      }),
                      function (y) {
                        return { key: y.key };
                      },
                    );
                  }

                  function exSelectedPlaces(obj) {
                    let results = [];
                    if (includedPlaces.length) {
                      switch (obj.type) {
                        case 'city':
                          results = find(includedPlaces, function (o) {
                            return o.key === obj.key;
                          });
                          if (results) {
                            notify(
                              'warning',
                              `${t('AUDIENCELOCATION')} ${results.name},  ${t(
                                'LOCATIONEXCLUDED',
                              )}`,
                            );

                            return;
                          }
                          break;
                        case 'region':
                          results = find(includedPlaces, function (o) {
                            return (
                              o.key === obj.key ||
                              o.region_id === parseInt(obj.key)
                            );
                          });
                          if (results) {
                            notify(
                              'warning',
                              `${t('AUDIENCELOCATION')} ${results.name},  ${t(
                                'LOCATIONEXCLUDED',
                              )}`,
                            );
                            return;
                          }
                          break;
                        case 'country':
                          results = find(includedPlaces, function (o) {
                            return o.country_code === obj.key;
                          });
                          if (results) {
                            notify(
                              'warning',
                              `${t('AUDIENCELOCATION')} ${results.name},  ${t(
                                'LOCATIONEXCLUDED',
                              )}`,
                            );
                            return;
                          }
                          break;
                        default:
                          break;
                      }
                    } else {
                      notify('info', `${t('INCLUDEONELOCATION')}`);
                      return;
                    }
                    if (excludedPlaces.length) {
                      switch (obj.type) {
                        case 'city':
                          remove(excludedPlaces, function (n) {
                            if (n.key === obj.key) {
                              return n.key === obj.key;
                            } else if (n.type === 'region') {
                              return n.key === obj.region_id;
                            } else if (n.type === 'country') {
                              return n.key === obj.country_code;
                            }
                          });
                          excludedPlaces.push({
                            name: obj.name,
                            key: obj.key,
                            country_code: obj.country_code,
                            type: obj.type,
                            region_id: obj.region_id,
                          });
                          break;
                        case 'region':
                          remove(excludedPlaces, function (n) {
                            if (n.key === obj.key) {
                              return n.key === obj.key;
                            } else if (n.type === 'city') {
                              return n.region_id === obj.key;
                            } else if (n.type === 'country') {
                              return n.key === obj.country_code;
                            }
                          });
                          excludedPlaces.push({
                            name: obj.name,
                            key: obj.key,
                            country_code: obj.country_code,
                            type: obj.type,
                            region_id: obj.region_id,
                          });
                          break;
                        case 'country':
                          remove(excludedPlaces, function (n) {
                            if (n.key === obj.key) {
                              return n.key === obj.key;
                            } else if (n.type === 'city') {
                              return n.country_code === obj.key;
                            } else if (n.type === 'region') {
                              return n.country_code === obj.key;
                            }
                          });
                          excludedPlaces.push({
                            name: obj.name,
                            key: obj.key,
                            country_code: obj.country_code,
                            type: obj.type,
                            region_id: obj.region_id,
                          });
                          break;
                        default:
                      }
                    } else {
                      excludedPlaces.push({
                        name: obj.name,
                        key: obj.key,
                        country_code: obj.country_code,
                        type: obj.type,
                        region_id: obj.region_id,
                      });
                    }
                    excludedCountries = map(
                      filter(excludedPlaces, function (o) {
                        return o.type === 'country';
                      }),
                      'key',
                    );
                    excludedRegions = map(
                      filter(excludedPlaces, function (o) {
                        return o.type === 'region';
                      }),
                      'key',
                    );
                    excludedCities = map(
                      filter(excludedPlaces, function (o) {
                        return o.type === 'city';
                      }),
                      'key',
                    );
                  }
                  const CrossPost = (value, pageValue) => {
                    const t1 = crossPostingData.filter(
                      (v) => v.id === pageValue.pageId,
                    );

                    return (
                      <div className='customCheckBox'>
                        <Field
                          name={`enableCrossPost[${pageValue.key}]`}
                          className=''
                          component='input'
                          type='checkbox'
                          default={formik.values.enableCrossPost}
                          onClick={() => {
                            if (formik.values.enableCrossPost.length) {
                              formik.setFieldValue(
                                `enableCrossPostAndCreate[${pageValue.key}]`,
                                false,
                              );
                              formik.setFieldValue(
                                `enableCrossPost[${pageValue.key}]`,
                                false,
                              );
                              const newID = formik.values.crossPostPages.filter(
                                (v) => v.id === pageValue.pageId,
                              );
                              if (newID.length) {
                                remove(formik.values.crossPostPages, (v) => {
                                  return v.id === pageValue.pageId;
                                });
                                if (
                                  newID[0].type ===
                                  'enable_crossposting_and_create_post'
                                ) {
                                  formik.values.crossPostPages.push({
                                    ...t1[0],
                                    type: 'enable_crossposting',
                                  });
                                }
                              } else {
                                formik.values.crossPostPages.push({
                                  ...t1[0],
                                  type: 'enable_crossposting',
                                });
                              }
                            } else {
                              formik.setFieldValue(
                                `enableCrossPost[${pageValue.key}]`,
                                true,
                              );
                              formik.setFieldValue(
                                `enableCrossPostAndCreate[${pageValue.key}]`,
                                false,
                              );
                              formik.values.crossPostPages.push({
                                ...t1[0],
                                type: 'enable_crossposting',
                              });
                            }
                          }}
                          onBlur={() => {
                            formik.setFieldTouched('enableCrossPost', true);
                          }}
                        />
                        <span></span>
                      </div>
                    );
                  };
                  const CrossPostAndCreate = (value, pageValue) => {
                    const t1 = crossPostingData.filter(
                      (v) => v.id === pageValue.pageId,
                    );
                    return (
                      <div className='customCheckBox'>
                        <Field
                          name={`enableCrossPostAndCreate[${pageValue.key}]`}
                          className=''
                          component='input'
                          type='checkbox'
                          default={formik.values.enableCrossPostAndCreate}
                          onClick={() => {
                            if (formik.values.enableCrossPostAndCreate.length) {
                              formik.setFieldValue(
                                `enableCrossPostAndCreate[${pageValue.key}]`,
                                false,
                              );
                              formik.setFieldValue(
                                `enableCrossPost[${pageValue.key}]`,
                                false,
                              );
                              const newID = formik.values.crossPostPages.filter(
                                (v) => v.id === pageValue.pageId,
                              );
                              if (newID.length) {
                                remove(formik.values.crossPostPages, (v) => {
                                  return v.id === pageValue.pageId;
                                });
                                if (newID[0].type === 'enable_crossposting') {
                                  formik.values.crossPostPages.push({
                                    ...t1[0],
                                    type: 'enable_crossposting_and_create_post',
                                  });
                                }
                              } else {
                                formik.values.crossPostPages.push({
                                  ...t1[0],
                                  type: 'enable_crossposting_and_create_post',
                                });
                              }
                            } else {
                              formik.setFieldValue(
                                `enableCrossPost[${pageValue.key}]`,
                                false,
                              );
                              formik.setFieldValue(
                                `enableCrossPostAndCreate[${pageValue.key}]`,
                                true,
                              );
                              formik.values.crossPostPages.push({
                                ...t1[0],
                                type: 'enable_crossposting_and_create_post',
                              });
                            }
                          }}
                          onBlur={() => {
                            formik.setFieldTouched(
                              'enableCrossPostAndCreate',
                              true,
                            );
                          }}
                        />
                        <span></span>
                      </div>
                    );
                  };
                  crossPostColumns = [
                    {
                      title: t('PAGENAME'),
                      dataIndex: 'pageName',
                    },
                    {
                      title: t('ENABLECROSSPOST'),
                      dataIndex: 'crossPost',
                      render: (text, value) => CrossPost(text, value),
                    },
                    {
                      title: t('ENABLECROSSPOSTANDCREATE'),
                      dataIndex: 'crossPostAndCreate',
                      render: (text, value) => CrossPostAndCreate(text, value),
                    },
                  ];

                  return (
                    <form
                      id='facebookstream'
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      <fieldset className='row flex-column p-0'>
                        <div className='nav-wrap w-100'>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === 'basics',
                                })}
                                onClick={() => {
                                  toggle('basics');
                                }}
                              >
                                <b>{t('BASICS').toUpperCase()}</b>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === 'tagsandpeople',
                                })}
                                onClick={() => {
                                  toggle('tagsandpeople');
                                }}
                              >
                                <b>{t('TAGSANDPEOPLE').toUpperCase()}</b>
                              </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                                            <NavLink
                                                                className={'disabled'}//classnames({active: activeTab === 'location'})
                                                                onClick={() => {
                                                                    toggle('location');
                                                                }}
                                                            >
                                                                <b>{t('LOCATION').toUpperCase()}</b>
                                                            </NavLink>
                                                        </NavItem> */}
                            <NavItem>
                              <NavLink
                                className={
                                  isEdit
                                    ? 'disabled'
                                    : classnames({
                                        active: activeTab === 'postcontrol',
                                      })
                                }
                                onClick={() => {
                                  toggle('postcontrol');
                                  if (
                                    formik.values.streamLocation.type === 'page'
                                  )
                                    getgroupsByPage(
                                      formik.values.streamLocation.value,
                                    );
                                }}
                              >
                                <b>{t('POSTCONTROL').toUpperCase()}</b>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={
                                  formik.values.streamLocation.type === 'page'
                                    ? classnames({
                                        active:
                                          activeTab === 'audiencerestriction',
                                      })
                                    : 'disabled'
                                }
                                onClick={() => {
                                  toggle('audiencerestriction');
                                }}
                              >
                                <b>{t('AUDIENCERESTRICTION').toUpperCase()}</b>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId='basics' className='w-100'>
                            <div className='row'>
                              <div className='col-lg-8 col-left fb-basics'>
                                <div className='form-group row'>
                                  <div className='col-lg-3 col-sm-3'>
                                    <div className='lbltxt'>
                                      {t('VIDEOTITLE')}{' '}
                                    </div>
                                  </div>
                                  <div className='col-lg-9 col-sm-9'>
                                    <input
                                      className={
                                        !!formik.values.destination
                                          ? 'form-control success'
                                          : 'form-control'
                                      }
                                      name='destination'
                                      placeholder={t('ADDTITLE')}
                                      autoComplete='off'
                                      disabled={isEdit}
                                      type='text'
                                      {...formik.getFieldProps('destination')}
                                    />
                                    <ErrorMessage
                                      component='span'
                                      name='destination'
                                      className='col-sm-8 col-sm-push-4 error'
                                    />
                                  </div>
                                </div>
                                <div className='form-group row'>
                                  <div className='col-lg-3 col-sm-3'>
                                    <div className='lbltxt'>
                                      {t('VIDEODESRIPTION')}{' '}
                                    </div>
                                  </div>
                                  <div className='col-lg-9 col-sm-9'>
                                    <Field
                                      className={
                                        !!formik.values.description
                                          ? 'form-control success'
                                          : 'form-control'
                                      }
                                      name='description'
                                      component='textarea'
                                      placeholder={t('WHATSONYOURMIND')}
                                      autoComplete='off'
                                      {...formik.getFieldProps('description')}
                                    />
                                  </div>
                                </div>
                                {isStreamToolActive ? (
                                  <StreamToolsProfile
                                    destination={editData?.dest}
                                    label='col-lg-3 col-sm-3'
                                    filed='col-lg-9 col-sm-9'
                                  />
                                ) : (
                                  <div className='form-group row'>
                                    <span className='col-lg-3 col-sm-3'>
                                      {t('PROFILE')}
                                    </span>
                                    <div className='col-lg-9 col-sm-9'>
                                      <Select
                                        name='profile'
                                        value={formik.values.profile}
                                        defaultValue={
                                          profileOptions &&
                                          !!profileOptions.length
                                            ? profileOptions[0].options[0]
                                            : []
                                        }
                                        onChange={(value) => {
                                          formik.setFieldValue(
                                            'profile',
                                            value,
                                          );
                                          formik.values.profile = value;
                                        }}
                                        options={profileOptions}
                                        onBlur={() => {
                                          formik.setFieldTouched(
                                            'profile',
                                            true,
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className='form-group row'>
                                  <div className='col-lg-3 col-sm-3'>
                                    <div className='lbltxt'>
                                      {t('WHERETOSTREAM')}{' '}
                                    </div>
                                  </div>
                                  <div className='col-lg-9 col-sm-9'>
                                    <Select
                                      name='streamLocation'
                                      value={formik.values.streamLocation}
                                      isDisabled={isEdit}
                                      onChange={(value) => {
                                        formik.setFieldValue(
                                          'streamLocation',
                                          value,
                                        );
                                        formik.values.streamLocation = value;
                                        if (value.type === 'page') {
                                          formik.setFieldValue('friends', []);
                                          checkCrossPosting(value.id);
                                        }
                                      }}
                                      options={streamLocationOptions}
                                      onBlur={() => {
                                        formik.setFieldTouched(
                                          'streamLocation',
                                          true,
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      component='span'
                                      name='streamLocation'
                                      className='col-sm-8 col-sm-push-4 error'
                                    />
                                  </div>
                                </div>
                                {formik.values.streamLocation.label ===
                                  'Personal profile' && (
                                  <div className='form-group row'>
                                    <div className='col-lg-3 col-sm-3'>
                                      <div className='lbltxt'>
                                        {t('PRIVACY')}{' '}
                                      </div>
                                    </div>
                                    <div className='col-lg-4 col-sm-4'>
                                      <Select
                                        name='privacy'
                                        value={formik.values.privacy}
                                        onChange={(value) => {
                                          formik.setFieldValue(
                                            'privacy',
                                            value,
                                          );
                                          formik.values.privacy = value;
                                        }}
                                        options={privacyOptions}
                                        onBlur={() => {
                                          formik.setFieldTouched(
                                            'privacy',
                                            true,
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}

                                <div className='form-group row'>
                                  <div className='col-lg-3 col-sm-3 col-6'>
                                    <div className='lbltxt'>
                                      {t('SCHEDULELIVE')}{' '}
                                    </div>
                                  </div>
                                  <div className='col-lg-9 col-sm-9 col-6'>
                                    <p className='customCheckBox'>
                                      <Field
                                        name='scheduleLive'
                                        component='input'
                                        type='checkbox'
                                        disabled={isEdit}
                                        default={formik.values.scheduleLive}
                                        // eslint-disable-next-line no-unused-vars
                                        onClick={(value) => {
                                          !formik.values.scheduleLive
                                            ? formik.setFieldValue(
                                                'scheduleLive',
                                                true,
                                              )
                                            : formik.setFieldValue(
                                                'scheduleLive',
                                                false,
                                              );
                                          formik.setFieldTouched(
                                            'scheduleLive',
                                            true,
                                          );
                                        }}
                                        onBlur={() => {
                                          formik.setFieldTouched(
                                            'scheduleLive',
                                            true,
                                          );
                                        }}
                                      />
                                      <span></span>
                                    </p>
                                  </div>
                                </div>
                                {formik.values.scheduleLive && (
                                  <div className='row'>
                                    <div className='offset-sm-3 col-sm-9'>
                                      <div className='scheduleLiveTime'>
                                        {!isEdit && (
                                          <div className='row mb-3'>
                                            <div className='col-lg-4 col-sm-6'>
                                              <div className='lbltxt'>
                                                {t('CUSTOMIMAGE')}
                                                <span
                                                  className='fa fa-info-circle fa-lg text-info fb-tooltip ml-2'
                                                  data-toggle='tooltip'
                                                >
                                                  <span className='tooltiptext'>
                                                    {t('ADDCUSTOMIMAGEMESSAGE')}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className='col-lg-8 col-sm-6'>
                                              <div className='custom'>
                                                {t('UPLOADIMAGE')}
                                                <input
                                                  name='scheduleImage'
                                                  type='file'
                                                  className={'custom_image'}
                                                  onChange={({
                                                    target,
                                                    currentTarget,
                                                  }) => {
                                                    const file =
                                                      currentTarget.files[0];
                                                    target.value = '';

                                                    if (!!file) {
                                                      const selectedFilesSize =
                                                        file.size;

                                                      // Check the file size
                                                      if (
                                                        selectedFilesSize >
                                                        2097152
                                                      ) {
                                                        notify(
                                                          'error',
                                                          t('MAX2MBFILE'),
                                                        );
                                                        return;
                                                      }

                                                      getBase64(file).then(
                                                        async (res) => {
                                                          setIsLoading(true);
                                                          const path =
                                                            res.replace(
                                                              /^data:image\/[a-z]+;base64,/,
                                                              '',
                                                            );

                                                          let payload = {
                                                            type: 'upload',
                                                            base64String: path,
                                                            tag: 'removeFbImage',
                                                          };

                                                          const data =
                                                            await fbImageUpload(
                                                              payload,
                                                            );
                                                          if (
                                                            data?.data?.response
                                                          ) {
                                                            formik.setFieldValue(
                                                              'scheduleImagePath',
                                                              data?.data
                                                                ?.response,
                                                            );
                                                            setIsLoading(false);
                                                          }
                                                        },
                                                      );

                                                      if (
                                                        file.type ===
                                                          'image/jpeg' ||
                                                        file.type ===
                                                          'image/jpg' ||
                                                        file.type ===
                                                          'image/png'
                                                      ) {
                                                        formik.setFieldValue(
                                                          'scheduleImage',
                                                          file,
                                                        );
                                                      } else {
                                                        formik.setFieldValue(
                                                          'scheduleImage',
                                                          file,
                                                        );
                                                      }
                                                    }
                                                  }}
                                                  accept='image/jpeg, image/jpg, image/png'
                                                  onBlur={() => {
                                                    formik.setFieldTouched(
                                                      'scheduleImage',
                                                      true,
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <ErrorMessage
                                                component='span'
                                                name='scheduleImage'
                                                className='col-sm-8 col-sm-push-4 error'
                                              />
                                              {!!formik.values.scheduleImage &&
                                                !!formik.values
                                                  ?.scheduleImagePath && (
                                                  <span className='image_name d-block w-100'>
                                                    {
                                                      formik.values
                                                        .scheduleImage.name
                                                    }
                                                    <Link
                                                      to='#'
                                                      className='fa fa-times-circle fa-lg text-info ml-2'
                                                      title={t('REMOVE')}
                                                      onClick={async () => {
                                                        setIsLoading(true);
                                                        formik.setFieldValue(
                                                          'scheduleImage',
                                                          null,
                                                        );
                                                        formik.setFieldValue(
                                                          'scheduleImagePath',
                                                          null,
                                                        );
                                                        setIsLoading(false);
                                                      }}
                                                    ></Link>
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                        <div className='row'>
                                          <div className='col-lg-12 col-sm-12'>
                                            <div className='lbltxt w-100'>
                                              {t('SCHEDULEDSTARTTIME')}{' '}
                                            </div>
                                            <DatePicker
                                              name={'scheduledTime'}
                                              // showTime={{ defaultValue:  moment(moment().year() +'-'+ (moment().month()+1) +'-'+moment().date() +' '+ moment().hours() + ':' + (moment().add(12, 'minutes').minutes()) + ':' + moment().seconds(), 'YYYY-MM-DD hh:mm a') }}
                                              showTime={{
                                                format: 'HH:mm',
                                                defaultValue: moment(
                                                  moment().hours() +
                                                    ':' +
                                                    moment().minutes(),
                                                  'YYYY-MM-DD hh:mm a',
                                                ).add(12, 'minutes'),
                                              }}
                                              defaultValue={
                                                isEdit
                                                  ? isEditDefaultTime()
                                                  : defaultTime()
                                              }
                                              showToday={false}
                                              format='YYYY-MM-DD hh:mm a'
                                              disabledDate={disabledDate}
                                              disabledTime={disabledDateTime}
                                              onChange={(value) => {
                                                var timediff = moment().diff(
                                                  value,
                                                  'minutes',
                                                );
                                                const v1 =
                                                  timediff >= 0
                                                    ? timediff === 0
                                                      ? moment(
                                                          value
                                                            .add(12, 'minutes')
                                                            .minutes(),
                                                          'YYYY-MM-DD hh:mm a',
                                                        ).valueOf()
                                                      : moment(
                                                          value.add(
                                                            timediff + 12,
                                                            'minutes',
                                                          ),
                                                          'YYYY-MM-DD hh:mm a',
                                                        ).valueOf()
                                                    : moment(
                                                        value,
                                                        'YYYY-MM-DD hh:mm a',
                                                      ).valueOf();
                                                formik.setFieldValue(
                                                  'scheduledTime',
                                                  v1,
                                                );
                                              }}
                                              onOk={(value) => {
                                                const v1 = moment(
                                                  value,
                                                  'YYYY-MM-DD hh:mm a',
                                                ).valueOf();
                                                formik.setFieldValue(
                                                  'scheduledTime',
                                                  v1,
                                                );
                                              }}
                                              onBlur={() => {
                                                formik.setFieldTouched(
                                                  'scheduledTime',
                                                  true,
                                                );
                                              }}
                                              placeholder={'Select a date...'}
                                              className='w-100'
                                            />
                                            <ErrorMessage
                                              component='span'
                                              name='scheduledTime'
                                              className='w-100 error'
                                            />
                                          </div>
                                          <div className='col-lg-12 col-sm-12 date-input'>
                                            <span className='notesTxt'>
                                              ({t('SCHEDULELIVEVIDEOMESSAGE')})
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!formik.values.scheduleLive && (
                                  <div className='form-group row'>
                                    <div className='col-lg-3 col-sm-3'>
                                      <div className='lbltxt'>
                                        {t('PUBLISHMODE')}{' '}
                                      </div>
                                    </div>
                                    <div className='col-lg-4 col-sm-4'>
                                      <Select
                                        name='publishMode'
                                        value={formik.values.publishMode}
                                        onChange={(value) => {
                                          formik.setFieldValue(
                                            'publishMode',
                                            value,
                                          );
                                          formik.values.publishMode = value;
                                        }}
                                        options={publishModeOptions}
                                        onBlur={() => {
                                          formik.setFieldTouched(
                                            'publishMode',
                                            true,
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className='col-lg-9 col-sm-9'>
                                      <span className='notesTxt'>
                                        {t('SELECTLIVEVIDEOGETPOST')}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className='col-lg-4 text-center hidden-xs'>
                                <img src={fbImg} alt='' />
                              </div>
                            </div>
                          </TabPane>
                          {/* <TabPane tabId={'location'}>
                                                        <div className="form-group row">
                                                            <div className="col-lg-3 col-sm-3">
                                                                <div className="lbltxt">{t('WHEREAREYOU')}</div>
                                                            </div>
                                                            <div className="col-lg-9 col-sm-9">

                                                                <AntSelect
                                                                    showSearch={true}
                                                                    allowClear={true}
                                                                    labelInValue
                                                                    name="geoLocation"
                                                                    placeholder="Enter a Location"
                                                                    notFoundContent={<Spin size="small" />}
                                                                    filterOption={false}
                                                                    onSearch={(value) => {
                                                                        if (!!value) {
                                                                            onLocationChange(value)
                                                                        }
                                                                    }}
                                                                    onSelect={(value) => {
                                                                        formik.setFieldValue('geoLocation', value);
                                                                    }}
                                                                    onDeselect={(value) => {
                                                                        remove(formik.values.geoLocation, (v) => {
                                                                            return v.key === value.key
                                                                        });
                                                                    }}
                                                                    onBlur={() => {
                                                                        formik.setFieldTouched('geoLocation', true)
                                                                    }}
                                                                    style={{ width: '70%' }}>
                                                                    {locationData.map(d => (
                                                                        <Option key={d.key}>{d.label}</Option>
                                                                    ))}
                                                                </AntSelect>
                                                            </div>
                                                        </div>

                                                    </TabPane> */}
                          <TabPane tabId={'tagsandpeople'}>
                            {formik.values.streamLocation.type !== 'page' && (
                              <div className='form-group row'>
                                <div className='col-lg-3 col-md-3'>
                                  <div className='lbltxt'>
                                    {t('WHOAREYOUWITH')}
                                  </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                  <AntSelect
                                    showSearch={true}
                                    allowClear={true}
                                    mode={'multiple'}
                                    labelInValue={true}
                                    name='friends'
                                    placeholder={t('PEOPLE')}
                                    notFoundContent={<Spin size='small' />}
                                    defaultValue={
                                      isEdit ? defaultFriends() : []
                                    }
                                    filterOption={(inputValue, option) => {
                                      let v1;
                                      // eslint-disable-next-line array-callback-return
                                      option.props.children.filter((oValue) => {
                                        if (typeof oValue === 'string') {
                                          v1 = oValue
                                            .toLowerCase()
                                            .includes(inputValue);
                                        }
                                      });
                                      return v1;
                                    }}
                                    onSearch={(value) => {
                                      if (!!value) {
                                        friendsSearch();
                                      }
                                    }}
                                    onSelect={(value) => {
                                      let v1 = friendsData.filter(
                                        (v) => v.key === value.key,
                                      );
                                      formik.values.friends.push({ ...v1[0] });
                                    }}
                                    onDeselect={(value) => {
                                      remove(formik.values.friends, (v) => {
                                        return v.key === value.key;
                                      });
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched('friends', true);
                                    }}
                                  >
                                    {friendsData.map((d) => (
                                      <Option key={d.key}>
                                        <Avatar
                                          shape='square'
                                          size='small'
                                          src={d.image.data.url}
                                        />
                                        <span
                                          className={'friendImg'}
                                          role='img'
                                        >
                                          <img
                                            className={'friendImg'}
                                            src={d.image.data.url}
                                            alt=''
                                          />
                                        </span>
                                        {d.name}
                                      </Option>
                                    ))}
                                  </AntSelect>
                                </div>
                              </div>
                            )}
                            <div className='form-group row'>
                              <div className='col-lg-3 col-md-3'>
                                <div className='lbltxt'>{t('VIDEOTAGS')}</div>
                              </div>
                              <div className='col-lg-6 col-md-6'>
                                <AntSelect
                                  showSearch={true}
                                  allowClear={true}
                                  mode={'multiple'}
                                  labelInValue={true}
                                  name='adInterest'
                                  defaultValue={
                                    isEdit
                                      ? editData.videoStatus.content_tags
                                      : []
                                  }
                                  placeholder={t('VIDEOTAGS')}
                                  notFoundContent={<Spin size='small' />}
                                  filterOption={false}
                                  onSearch={(value) => {
                                    if (!!value) {
                                      adTags(value);
                                    }
                                  }}
                                  onSelect={(value) => {
                                    formik.values.adInterest.push({
                                      key: value.key,
                                      label: value.label,
                                    });
                                  }}
                                  onDeselect={(value) => {
                                    remove(formik.values.adInterest, (v) => {
                                      return v.key === value.key;
                                    });
                                    formik.setFieldValue(
                                      'adInterest',
                                      formik.values.adInterest,
                                    );
                                  }}
                                  onBlur={() => {
                                    formik.setFieldTouched('adInterest', true);
                                  }}
                                >
                                  {adData.map((d) => (
                                    <Option key={d.key}>{d.label}</Option>
                                  ))}
                                </AntSelect>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tabId={'postcontrol'}>
                            <div className='form-group row'>
                              <div className='col-lg-2 col-md-3 col-6'>
                                <div className='lbltxt'>
                                  {t('PROHIBITEMBEDDING')}{' '}
                                </div>
                              </div>

                              <div className='col-lg-4 col-md-4 col-6 '>
                                <p className='customCheckBox'>
                                  <Field
                                    name='prohibitEmbedding'
                                    component='input'
                                    type='checkbox'
                                    default={formik.values.prohibitEmbedding}
                                    // eslint-disable-next-line no-unused-vars
                                    onClick={(value) => {
                                      !formik.values.prohibitEmbedding
                                        ? formik.setFieldValue(
                                            'prohibitEmbedding',
                                            true,
                                          )
                                        : formik.setFieldValue(
                                            'prohibitEmbedding',
                                            false,
                                          );
                                    }}
                                    onBlur={() => {
                                      formik.setFieldTouched(
                                        'prohibitEmbedding',
                                        true,
                                      );
                                    }}
                                  />
                                  <span></span>
                                </p>
                              </div>
                            </div>
                            {formik.values.streamLocation.type === 'page' && (
                              <div className='form-group crosspost row'>
                                <div className='col-lg-2 col-md-3'>
                                  {t('CROSSPOSTING')}
                                  <a
                                    href='https://www.facebook.com/help/publisher/1385580858214929'
                                    className='ml-2'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <span
                                      className='fa fa-info-circle fa-lg text-info fb-tooltip'
                                      data-toggle='tooltip'
                                    >
                                      <span className='tooltiptext'>
                                        {t('CROSSPOSTINFO')}
                                      </span>
                                    </span>
                                  </a>
                                </div>
                                <div className='col-lg-10 col-md-9'>
                                  {crossPostingData.length > 0 ? (
                                    <div className='table-responsive crosspostingTable'>
                                      <AntTable
                                        columns={crossPostColumns}
                                        dataSource={crossPostTableData}
                                      />
                                    </div>
                                  ) : (
                                    <span className='error'>
                                      {t('NOCROSSPOSTMESSAGE')}
                                    </span>
                                  )}
                                  {crossPostError && (
                                    <span className='error'>
                                      {t('CROSSPOSTERROR')}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            {groupsByPage.length > 0 &&
                              formik.values.streamLocation.type === 'page' && (
                                <div className='form-group row'>
                                  <div className='col-lg-2 col-md-3 col-6'>
                                    <div className='lbltxt'>{t('GROUPS')} </div>
                                  </div>
                                  <div className='col-lg-10 col-md-9'>
                                    <div className='row'>
                                      {groupsByPage.map((value) => (
                                        <div className='col-md-3'>
                                          <div className='customCheckBox groupsByPageCheck'>
                                            <Field
                                              type='checkbox'
                                              name='groups'
                                              value={value.id}
                                            />
                                            <span>
                                              <p>{value.name}</p>
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </TabPane>
                          <TabPane tabId={'audiencerestriction'}>
                            <div className='form-group row'>
                              <div className='col-lg-2 col-sm-3'>
                                <div className='lbltxt'>
                                  {t('AGE')}{' '}
                                  <span
                                    className='fa fa-info-circle fa-lg text-info user-tooltip'
                                    data-toggle='tooltip'
                                  >
                                    <span className='tooltiptext'>
                                      {t('AGEINFO')}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className='col-lg-4 col-sm-5 ageValue'>
                                <Select
                                  name='audienceAge'
                                  value={formik.values.audienceAge}
                                  onChange={(value) => {
                                    formik.setFieldValue('audienceAge', value);
                                    formik.values.audienceAge = value;
                                  }}
                                  options={audienceAgeOptions}
                                  onBlur={() => {
                                    formik.setFieldTouched('audienceAge', true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className='form-group row'>
                              <span className='col-lg-2 col-sm-3'>
                                {t('LOCATION')}{' '}
                              </span>
                              <div className='col-lg-10 col-sm-9 mb-2'>
                                <div className='row'>
                                  <div className='col-lg-2 col-sm-3 col-auto'>
                                    <span>
                                      <img src={includeImg} alt='' />{' '}
                                      {t('INCLUDE')}
                                    </span>
                                  </div>
                                  <div className='col-lg-8 col-sm-7 col'>
                                    <AntSelect
                                      showSearch={true}
                                      allowClear={true}
                                      labelInValue={true}
                                      mode={'multiple'}
                                      name='includeLocation'
                                      placeholder='Enter a location to include'
                                      notFoundContent={<Spin size='small' />}
                                      value={formik.values.includeLocation}
                                      filterOption={false}
                                      onSearch={(value) => {
                                        if (!!value) {
                                          adGeoLocationSearch(value);
                                        }
                                      }}
                                      onSelect={(value) => {
                                        let v1 = adGeoLocationData.filter(
                                          (v) => v.key === value.key,
                                        );
                                        // eslint-disable-next-line no-unused-vars
                                        let result = inSelectedPlaces({
                                          ...v1[0],
                                        });
                                        let test = includedPlaces.map((loc) => {
                                          return {
                                            key: loc.key,
                                            label: (
                                              <span>
                                                <Avatar src={includeImg} />
                                                {loc.name}
                                              </span>
                                            ),
                                          };
                                        });
                                        formik.setFieldValue(
                                          'includeLocation',
                                          [...test],
                                        );

                                        formik.values.includedGeoLocation.countries =
                                          includedCountries;
                                        formik.values.includedGeoLocation.cities =
                                          includedCities;
                                        formik.values.includedGeoLocation.regions =
                                          includedRegions;
                                      }}
                                      onDeselect={(value) => {
                                        remove(
                                          formik.values.includeLocation,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.includedGeoLocation
                                            .countries,
                                          (v) => {
                                            return v === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.includedGeoLocation
                                            .cities,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.includedGeoLocation
                                            .regions,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(includedPlaces, (v) => {
                                          return v.key === value.key;
                                        });
                                        formik.setFieldValue(
                                          'includeLocation',
                                          formik.values.includeLocation,
                                        );
                                        formik.values.includedGeoLocation.countries =
                                          includedCountries;
                                        formik.values.includedGeoLocation.cities =
                                          includedCities;
                                        formik.values.includedGeoLocation.regions =
                                          includedRegions;
                                      }}
                                      onBlur={() => {
                                        formik.setFieldTouched(
                                          'includeLocation',
                                          true,
                                        );
                                      }}
                                      style={{ width: '70%' }}
                                    >
                                      {adGeoLocationData.map((d) => (
                                        <Option key={d.key} label={d.label}>
                                          <div>
                                            <Avatar
                                              style={{
                                                width: '15px',
                                                height: '18px',
                                              }}
                                              src={includeImg}
                                            />{' '}
                                            {d.type === 'city' ||
                                            d.type === 'subcity'
                                              ? `${d.name}-${d.region}`
                                              : d.name}
                                          </div>
                                        </Option>
                                      ))}
                                    </AntSelect>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-10 offset-lg-2 offset-sm-3 col-sm-9 '>
                                <div className='row'>
                                  <div className='col-lg-2 col-sm-3 col-auto'>
                                    <span>
                                      <img src={excludeImg} alt='' />{' '}
                                      {t('EXCLUDE')}
                                    </span>
                                  </div>
                                  <div className='col-lg-8 col-sm-7 col'>
                                    <AntSelect
                                      showSearch={true}
                                      allowClear={true}
                                      labelInValue={true}
                                      mode={'multiple'}
                                      name='excludeLocation'
                                      placeholder='Enter a location to exclude'
                                      notFoundContent={<Spin size='small' />}
                                      value={formik.values.excludeLocation}
                                      filterOption={false}
                                      onSearch={(value) => {
                                        if (!!value) {
                                          adGeoLocationSearch(value);
                                        }
                                      }}
                                      onSelect={(value) => {
                                        let v1 = adGeoLocationData.filter(
                                          (v) => v.key === value.key,
                                        );
                                        // eslint-disable-next-line no-unused-vars
                                        let result = exSelectedPlaces({
                                          ...v1[0],
                                        });
                                        let test1 = excludedPlaces.map(
                                          (loc) => {
                                            return {
                                              key: loc.key,
                                              label: (
                                                <span>
                                                  <Avatar src={excludeImg} />{' '}
                                                  {loc.name}
                                                </span>
                                              ),
                                            };
                                          },
                                        );
                                        formik.setFieldValue(
                                          'excludeLocation',
                                          [...test1],
                                        );
                                        formik.values.excludedGeoLocation.countries =
                                          excludedCountries;
                                        formik.values.excludedGeoLocation.cities =
                                          excludedCities;
                                        formik.values.excludedGeoLocation.regions =
                                          excludedRegions;
                                      }}
                                      onDeselect={(value) => {
                                        remove(
                                          formik.values.excludeLocation,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.excludedGeoLocation
                                            .countries,
                                          (v) => {
                                            return v === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.excludedGeoLocation
                                            .cities,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.excludedGeoLocation
                                            .regions,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(
                                          formik.values.excludedGeoLocation,
                                          (v) => {
                                            return v.key === value.key;
                                          },
                                        );
                                        remove(excludedPlaces, (v) => {
                                          return v.key === value.key;
                                        });
                                        formik.setFieldValue(
                                          'excludeLocation',
                                          formik.values.excludeLocation,
                                        );
                                        formik.values.excludedGeoLocation.countries =
                                          excludedCountries;
                                        formik.values.excludedGeoLocation.cities =
                                          excludedCities;
                                        formik.values.excludedGeoLocation.regions =
                                          excludedRegions;
                                      }}
                                      onBlur={() => {
                                        formik.setFieldTouched(
                                          'excludeLocation',
                                          true,
                                        );
                                      }}
                                      style={{ width: '70%' }}
                                    >
                                      {adGeoLocationData.map((d) => (
                                        <Option key={d.key} label={d.label}>
                                          <div>
                                            <Avatar
                                              style={{
                                                width: '15px',
                                                height: '18px',
                                              }}
                                              src={excludeImg}
                                            />{' '}
                                            {d.type === 'city' ||
                                            d.type === 'subcity'
                                              ? `${d.name}-${d.region}`
                                              : d.name}
                                          </div>
                                        </Option>
                                      ))}
                                    </AntSelect>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </fieldset>
                      <Field
                        component={ProfileSettings}
                        t={t}
                        formik={formik}
                        bitrateDisabled={bitrateDisabled}
                        isBitRateHigher={() => {}}
                        invalidBitRate={() => {}}
                        destination={editData?.dest}
                      />
                      <div className='btn-row'>
                        <button
                          type='submit'
                          disabled={
                            !formik.isValid ||
                            formik.values.overrideErrors.length ||
                            isLoading
                          }
                          className='btn btn-primary btn-md'
                        >
                          {t('SUBMIT')}
                        </button>
                      </div>
                      <div className='form-group text-center'>
                        <button
                          type='button'
                          className='btag'
                          onClick={() => {
                            fbLogout(match);
                          }}
                        >
                          {t('SIGNINWITHDIFFERENTACC')}
                        </button>
                      </div>
                      <div className='form-group text-center'>
                        {t('FBPOLICYINFO1')}
                        <button
                          type='button'
                          className='atag'
                          onClick={() => {
                            window.open(
                              'https://www.facebook.com/policies/brandedcontent/',
                              '_blank',
                            );
                          }}
                        >
                          {t('FBPOLICYINFO2')}
                        </button>
                        {t('FBPOLICYINFO3')}
                      </div>
                    </form>
                  );
                }}
              </Formik>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
