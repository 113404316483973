import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { unitActions } from '../../actions';
import { SourceView } from './SourceView';
import { getBitrate, getUnitWithStatus } from '../../selectors/unit_selectors';
import './SourceView.css';
import { ErrorBoundary } from '../ErrorBoundary';
// import { debounce } from 'lodash';

class Source extends Component {
  static intervalId = 0;
  static intervalT6 = 0;
  state = {
    unitLogsModal: false,
  };
  componentWillUnmount() {
    clearInterval(Source.intervalId);
    clearInterval(Source.intervalT6);
  }

  componentDidMount() {
    const {
      match: {
        params: { bossId },
      },
      // eslint-disable-next-line no-unused-vars
      unitstatus,
    } = this.props;
    this.props.getVideoDetails(bossId, 'interfaces');
    this.fetchVideoDetailsAtInterval(bossId);
    this.props.getVideoDetails(bossId, 'video');
    // unitstatus.status === 'streaming' &&
    this.fetchConnectionsAtInterval(bossId);
  }

  debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  fetchVideoDetailsAtInterval = this.debounce((bossId) => {
    clearInterval(Source.intervalId);
    Source.intervalId = setInterval(() => {
      this.props.getVideoDetails(bossId, 'video');
    }, 5000);
  });

  fetchConnectionsAtInterval = this.debounce((bossId) => {
    clearInterval(Source.intervalT6);
    Source.intervalT6 = setInterval(() => {
      this.props.getVideoDetails(bossId, 'interfaces');
    }, 5000);
  });

  async componentDidUpdate(prevProps) {
    if (this.props.unit.BOSSID !== prevProps.unit.BOSSID) {
      await this.props.getVideoDetails(this.props.unit.BOSSID, 'interfaces');
      await this.props.getVideoDetails(this.props.unit.BOSSID, 'video');
      this.fetchVideoDetailsAtInterval(this.props.unit.BOSSID);
      this.fetchConnectionsAtInterval(this.props.unit.BOSSID);
    }
    if (
      (this.props.unitstatus && this.props.unitstatus.status) !==
      (prevProps.unitstatus && prevProps.unitstatus.status)
    ) {
      // clearInterval(Source.intervalT6);
      // if (this.props.unitstatus.status === 'streaming') {
      this.props.getVideoDetails(this.props.unit.BOSSID, 'interfaces');
      this.fetchConnectionsAtInterval(this.props.unit.BOSSID);
      // }
    }
  }

  handleSendUnitLogs = (description) => {
    const { unit, sendUnitLogs } = this.props;
    const { BOSSID } = unit;
    const payload = {
      emails: ['solo.help@liveu.tv'],
      description,
      application: 'solo',
    };
    sendUnitLogs(BOSSID, payload);
  };

  openUnitLogsModal = () => {
    this.setState({ unitLogsModal: true });
  };

  closeUnitLogsModal = () => {
    this.setState({ unitLogsModal: false });
  };

  render() {
    const { t, videoDetails, b, sourceLoading, unitstatus, unitInterface } =
      this.props;
    const { unitLogsModal } = this.state;

    return (
      <ErrorBoundary>
        <SourceView
          t={t}
          videoDetails={videoDetails}
          bitrateArr={b}
          sourceLoading={sourceLoading}
          unitStatus={unitstatus}
          unitInterface={unitInterface}
          handleSendUnitLogs={this.handleSendUnitLogs}
          unitLogsModal={unitLogsModal}
          closeUnitLogsModal={this.closeUnitLogsModal}
          openUnitLogsModal={this.openUnitLogsModal}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  const { videoDetails, sourceLoading, unit, unitInterface } = state.unit;
  return {
    unit,
    videoDetails,
    unitstatus: getUnitWithStatus(state),
    b: getBitrate(state),
    unitInterface,
    sourceLoading,
  };
};

const mapDispatchToProps = {
  getVideoDetails: unitActions.getVideoDetails,
  sendUnitLogs: unitActions.sendUnitLogs,
};

const ConnectedSource = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Source));
export { ConnectedSource as Source };
