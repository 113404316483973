export const paymentConstants = {
  PAYMENT_LOADING_START: 'PAYMENT_LOADING_START',
  PAYMENT_LOADING_STOP: 'PAYMENT_LOADING_STOP',
  GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAILURE: 'GET_CUSTOMER_FAILURE',
  GET_PLANS_REQUEST: 'GET_PLANS_REQUEST',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  GET_PLANS_ERROR: 'GET_PLANS_ERROR',
  GET_CURRENCY_REQUEST: 'GET_CURRENCY_REQUEST',
  GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS',
  GET_CURRENCY_ERROR: 'GET_CURRENCY_ERROR',
  GET_CUSTOMER_DETAILS_REQUEST: 'GET_CUSTOMER_DETAILS_REQUEST',
  GET_CUSTOMER_DETAILS_SUCCESS: 'GET_CUSTOMER_DETAILS_SUCCESS',
  GET_SUBSCRIPTION_REQUEST: 'GET_SUBSCRIPTION_REQUEST',
  GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
  GET_SUBSCRIPTION_ERROR: 'GET_SUBSCRIPTION_ERROR',
  PAYMENT_METHOD_LOADING_START: 'PAYMENT_METHOD_LOADING_START',
  PAYMENT_METHOD_LOADING_STOP: 'PAYMENT_METHOD_LOADING_STOP',
  PAYMENT_HISTORY_LOADING_START: 'PAYMENT_HISTORY_LOADING_START',
  PAYMENT_HISTORY_LOADING_STOP: 'PAYMENT_HISTORY_LOADING_STOP',
  INVOICE_LOADING_START: 'INVOICE_LOADING_START',
  INVOICE_LOADING_STOP: 'INVOICE_LOADING_STOP',
  PAYMENT_INFO_LOADING_START: 'PAYMENT_INFO_LOADING_START',
  PAYMENT_INFO_LOADING_STOP: 'PAYMENT_INFO_LOADING_STOP',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_CHARGES_SUCCESS: 'GET_CHARGES_SUCCESS',
  GET_INVOICE_DETAILS_SUCCESS: 'GET_INVOICE_DETAILS_SUCCESS',
  GET_CHARGE_DETAILS_SUCCESS: 'GET_CHARGE_DETAILS_SUCCESS',
  GET_COUPON_SUCCESS: 'GET_COUPON_SUCCESS',
  GET_TAX_SUCCESS: 'GET_TAX_SUCCESS',
  GET_UPCOMING_INVOICES_REQUEST: 'GET_UPCOMING_INVOICES_REQUEST',
  GET_UPCOMING_INVOICES_SUCCESS: 'GET_UPCOMING_INVOICES_SUCCESS',
  GET_UPCOMING_INVOICES_ERROR: 'GET_UPCOMING_INVOICES_ERROR',
  ADD_UNIT_LOADING_STOP: 'ADD_UNIT_LOADING_STOP',
  LRT_LOADING: 'LRT_LOADING',
  ADD_UNIT_LOADING: 'ADD_UNIT_LOADING',
  GET_CREDIT_BALANCE_SUCCESS: 'GET_CREDIT_BALANCE_SUCCESS',
  GET_CREDIT_BALANCE_REQUEST: 'GET_CREDIT_BALANCE_REQUEST',
  GET_LOYALTY_CREDIT_SUCCESS: 'GET_LOYALTY_CREDIT_SUCCESS',
  GET_LOYALTY_CREDIT_ERROR: 'GET_LOYALTY_CREDIT_ERROR',
  GET_CREDIT_BALANCE_FAILURE: 'GET_CREDIT_BALANCE_FAILURE',
  GET_USERS_LOYALTY_CREDIT_SUCCESS: 'GET_USERS_LOYALTY_CREDIT_SUCCESS',
  GET_USERS_LOYALTY_CREDIT_ERROR: 'GET_USERS_LOYALTY_CREDIT_ERROR',
  REMOVE_INVOICE: 'REMOVE_INVOICE',
};
