import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Unit } from '../Unit';
import './Units.css';
import { Link } from 'react-router-dom';

class Units extends Component {
  render() {
    const { t, onSelectedUnit, onUnitSettings, units, modalHandler } =
      this.props;

    return (
      <div className='side-bg'>
        <div className={'d-flex flex-column col px-0 unit-lists'}>
          <div className='unit-lists-wrap d-flex flex-column'>
            <div className='unit-wrap'>
              {units.length > 0
                ? units.map((unit, index) => {
                    return (
                      <Unit
                        index={index}
                        unit={unit}
                        key={unit.BOSSID}
                        onSelectedUnit={onSelectedUnit}
                        onUnitSettings={onUnitSettings}
                        modalHandler={modalHandler}
                      />
                    );
                  })
                : null}
            </div>
            <div className='addSolo'>
              <Link
                to='/dashboard/add'
                className='d-flex flex-column text-center justify-content-center py-4 px-2'
              >
                {/* <img src={PlusAdd} />  */}
                <svg
                  height='30px'
                  className='svg_plus'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 23.47 23.47'
                >
                  <path d='M4.6,9.22H9c.24,0,.24,0,.24-.24V.23c0-.23,0-.23.22-.23H14c.22,0,.22,0,.22.22V8.87c0,.35,0,.35.35.35h8.64c.22,0,.22,0,.22.22V14c0,.23,0,.23-.23.23H14.63c-.37,0-.37,0-.37.38v8.59c0,.24,0,.24-.24.24H9.45c-.23,0-.23,0-.23-.24V14.56c0-.29,0-.3-.3-.3H.19c-.18,0-.19,0-.19-.19V9.42c0-.2,0-.2.2-.2Z' />
                </svg>
                <span className='my-2'>{t('ADDSOLO')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ConnectedUnits = connect(null, null)(withTranslation()(Units));
export { ConnectedUnits as Units };
