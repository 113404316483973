import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { VideoPlayer } from '../../../VideoPlayer/VideoPlayer';
import { FBAction } from '../../../../actions';
import { errorBlock } from '../../../../api/errorBlock';
import { getUnitWithStatus } from '../../../../selectors/unit_selectors';
import PropagateLoader from 'react-spinners/PropagateLoader';

import { css } from '@emotion/core';
const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  padding-top: 35%;
`;

class FacebookVideo extends Component {
  state = {
    playerLoading: true,
  };
  componentDidMount() {
    this.playerLoadingTimeout();
  }

  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    const { liveVideo } = this.props;
    if (prevProps.liveVideo.status.status !== liveVideo.status.status) {
      if (liveVideo.status.status === 'LIVE') {
        this.getTranscoderState();
      }
      if (liveVideo.status.status !== 'LIVE') {
        clearInterval(this.intervalT3);
      }
    }
  }
  getPostStatus = async () => {
    const { match, fbGetLiveVideoStatus, selectedDestination } = this.props;
    try {
      if (
        selectedDestination.streaming_destination.streaming_provider ===
        'Facebook'
      ) {
        await fbGetLiveVideoStatus(
          selectedDestination.streaming_destination.external_id,
          match,
          selectedDestination.fb_page_id,
        );
      }
    } catch (e) {
      clearInterval(this.intervalT3);
      //errorBlock(e) //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
    }
  };
  playerLoadingTimeout = () => {
    setTimeout(() => {
      this.setState({
        playerLoading: false,
      });
    }, 5000);
  };
  getTranscoderState = () => {
    const { match, selectedDestination } = this.props;
    try {
      if (
        selectedDestination.streaming_destination.streaming_provider ===
        'Facebook'
      ) {
        this.intervalT3 = setInterval(() => {
          this.getPostStatus();
        }, 5000);
      }
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  };
  componentWillUnmount() {
    clearInterval(this.intervalT3);
  }

  render() {
    const { liveVideo, unit } = this.props;
    const { playerLoading } = this.state;
    return (
      (liveVideo.status.status === 'UNPUBLISHED' ||
        liveVideo.status.status === 'LIVE' ||
        unit.status === 'streaming') && (
        <div className='d-flex flex-column'>
          {playerLoading ? (
            <PropagateLoader
              css={override}
              size={10}
              color={'#000000'}
              loading={playerLoading}
            />
          ) : (
            <React.Fragment>
              <VideoPlayer videoUrl={liveVideo.status.dash_preview_url} />
            </React.Fragment>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { liveVideo } = state.fb;
  const { selectedDestination } = state.destination;
  return {
    liveVideo,
    selectedDestination,
    unit: getUnitWithStatus(state),
  };
};
const mapDispatchToProps = {
  fbGetLiveVideoStatus: FBAction.fbLiveVideoStatus,
};
const ConnectedFacebookVideo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FacebookVideo));
export { ConnectedFacebookVideo as FacebookVideo };
