import { getAWSDestinationList } from '../../api/aws.api';
import { getLUCStreamingProviders } from '../../api/luc.api';
import Cookies from 'universal-cookie';
import { store } from '../../store';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
// import {providers} from '../../utils';

const _ = require('lodash');
const cookies = new Cookies();

export const editUnitsService = {
  getStreamingProviders,
  getAWSDestInven,
  getStreamingProvidersFromApi,
};
async function getStreamingProviders() {
  try {
    const { providers } = store.getState().destination;
    return providers;
  } catch (err) {
    return Promise.reject(err);
  }
}
async function getAWSDestInven() {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await getAWSDestinationList(awsHeaders);
    if (res.data.data.response.length) {
      return { dest: res.data.data.response };
    } else {
      return { dest: [] };
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getStreamingProvidersFromApi() {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': 'SlZ3SHqiqtYJRkF0zO',
  };
  try {
    const res = await getLUCStreamingProviders(lucHeaders);
    const { providers } = store.getState().destination;
    if (!_.isEqual(providers, res?.data?.data?.streaming_providers)) {
      return { status: false, data: res?.data?.data?.streaming_providers };
    } else {
      return { status: true, data: providers };
    }
  } catch (err) {
    return Promise.reject(err);
  }
}
