import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import PropagateLoader from 'react-spinners/PropagateLoader';
import './OtherSpinner.css';
const override = css`
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
`;
class OtherSpinner extends Component {
  render() {
    const { showSpinner } = this.props;
    return (
      <div className={showSpinner ? 'overlay' : null}>
        <PropagateLoader
          css={override}
          size={20}
          color={'#fafbff'}
          loading={showSpinner}
        />
      </div>
    );
  }
}
const ConnectedOtherSpinner = connect(
  null,
  null,
)(withTranslation()(OtherSpinner));
export { ConnectedOtherSpinner as OtherSpinner };
