export const boxCastConstants = {
  GET_TOKEN_REQ: 'GET_TOKEN_REQ',
  GET_TOKEN: 'GET_TOKEN',
  GET_TOKEN_FAIL: 'GET_TOKEN_FAIL',
  GET_USER: 'GET_USER',
  GET_USER_FAIL: 'GET_USER_FAIL',
  GET_USER_REQ: 'GET_USER_REQ',
  GET_RTMP: 'GET_RTMP',
  GET_RTMP_FAIL: 'GET_RTMP_FAIL',
  CREATE_STREAM: 'CREATE_STREAM',
  CREATE_STREAM_FAIL: 'CREATE_STREAM_FAIL',
  STOP_STREAM: 'STOP_STREAM',
  STOP_STREAM_FAIL: 'STOP_STREAM_FAIL',
};
