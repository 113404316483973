import React from 'react';
import { Row, Col, Button, Upload, Icon, Skeleton, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import './streamtools.css';

const StreamToolsFallBackSlateView = ({
  t,
  isLoading,
  isUploading,
  isClearing,
  dimenstions,
  url,
  handleFallbackSlateUpload,
  handleRemoveFallbackSlate,
  dummyRequest,
  fileList,
  fileName,
}) => {
  return (
    <>
      <Row gutter={20}>
        <Col span={24} lg={16} className='mb-lg-0 mb-3'>
          <div className='sub-title'>
            <h4>{t('FALLBACKSLATE')}</h4>
          </div>
          <em>{t('FALLBACKDES')}</em>
        </Col>
        <Col lg={8} span={24}>
          <div className='d-flex '>
            <Upload
              onChange={handleFallbackSlateUpload}
              customRequest={dummyRequest}
              fileList={fileList}
              accept='image/jpeg,image/jpg,image/png,image/gif,image/webp,image/avif,video/mp4,.jpg,.png,.jpeg,.gif,.webp,.avif,.mp4'
              showUploadList={false}
              onRemove={handleRemoveFallbackSlate}
            >
              <Button
                icon={isUploading ? <CheckOutlined /> : null}
                loading={isUploading}
                type='primary'
                className='btn-primary'
              >
                {!isUploading && (
                  <span>
                    <Icon type='upload' />
                    <b className='p-1'>{t('UPLOADNEWSLASTE')}</b>
                  </span>
                )}
                {isUploading && <b> {t('UPLOADING')} </b>}
              </Button>
            </Upload>
            <Tooltip
              title={'You can upload a *.png, *.jpg, *.gif, or *.apng file.'}
            >
              <i
                type='info-circle'
                className={`d-flex justify-content-center align-items-center profile-help-btn fa fa-question-circle fa-lg ml-2`}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
          {isLoading ? (
            <Skeleton />
          ) : (
            <div className='fallback-slate-info-container'>
              {fileName && (
                <div className='information-row'>
                  <span className='information'>{t('CURRENTFALLBACK')}</span>
                  <div className='box topborder'>
                    <span>{fileName}</span>
                  </div>
                </div>
              )}
              {dimenstions.length > 0 && (
                <div className='information-row'>
                  <span className='information'> {t('FILEINFO')} </span>
                  <div className='box topborder'>
                    <span id='width'>{dimenstions[0]}</span>
                  </div>
                  <div className='box-saparator topborder'>
                    <span> x </span>
                  </div>
                  <div className='box topborder'>
                    <span id='height'>{dimenstions[1]}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          <hr></hr>
          <Row gutter={[25, 15]}>
            <Col md={24} lg={8}>
              <Button
                onClick={handleRemoveFallbackSlate}
                disabled={!url}
                icon={isClearing ? <CheckOutlined /> : null}
                loading={isClearing}
              >
                {isClearing ? `${t('CLEARING')}` : `${t('CLEARSLATE')}`}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default StreamToolsFallBackSlateView;
