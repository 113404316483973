import { Badge } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import BitrateChart from '../BitrateChart/BitrateChart';
// import mobUnitImg from '../assets/images/solo-plusdevice.png';

export const SourceView = ({
  t,
  videoDetails,
  bitrateArr,
  sourceLoading,
  unitStatus,
  unitInterface,
  unitLogsModal,
  closeUnitLogsModal,
  openUnitLogsModal,
  handleSendUnitLogs,
}) => {
  const { resolution, bitrate } = videoDetails;
  function connections() {
    let count = 0;
    if (unitStatus && unitStatus.product === 'LU-Solo') {
      if (unitInterface.length === 6) {
        unitInterface.splice(2, 2);
      }
      const t1 =
        unitInterface &&
        unitInterface.map((val) => {
          return (
            <div key={Math.random() * 100} className='col-6 px-0'>
              <div className='row mx-0'>
                <div
                  key={Math.random() * 100}
                  className='col-2 py-1 px-0 border-bottom font-weight-bold'
                >
                  {val.port === 'eth0'
                    ? 'Eth'
                    : val.port === 'wlan0'
                    ? 'WiFi'
                    : ++count}
                </div>
                <div
                  key={Math.random() * 100}
                  className='col-7 py-1 pl-2 pr-0 text-left border-bottom '
                >
                  {val.connected === true ? (
                    <Badge
                      color={'green'}
                      text={
                        val.name === 'No Name' || val.name === ''
                          ? 'USB Device'
                          : val.name
                      }
                    />
                  ) : val.name === 'No Name' ||
                    val.name === '' ||
                    val.name === 'No Device' ? (
                    <Badge
                      color={'gray'}
                      text={
                        val.name === 'No Name' || val.name === ''
                          ? 'USB Device'
                          : val.name
                      }
                    />
                  ) : (
                    <Badge
                      color={'yellow'}
                      text={
                        val.name === 'No Name' || val.name === ''
                          ? 'USB Device'
                          : val.name
                      }
                    />
                  )}
                  {/* {val.name === 'No Name' || val.name === ''
                  ? 'USB Device'
                  : val.name} */}
                </div>
                <div
                  key={Math.random() * 100}
                  className='col-3 py-1 px-0 border-bottom '
                >
                  {val.kbps}{' '}
                </div>
              </div>
            </div>
          );
        });
      return t1;
    } else {
      const sortedUnitInterface =
        unitInterface &&
        unitInterface.sort((a, b) => {
          if (a.port === 'eth0' || a.port === 'eth') return -1;
          if (b.port === 'eth0' || b.port === 'eth') return 1;
          if (
            (a.port.includes('wlan0') || a.port.includes('wlan')) &&
            !(b.port.includes('wlan0') || b.port.includes('wlan'))
          )
            return -1;
          if (
            !(a.port.includes('wlan0') || a.port.includes('wlan')) &&
            (b.port.includes('wlan0') || b.port.includes('wlan'))
          )
            return 1;
          return 0;
        });
      const t1 =
        sortedUnitInterface &&
        sortedUnitInterface.map((val) => {
          return (
            <div key={Math.random() * 100} className='col-6 px-0'>
              <div className='row mx-0'>
                <div
                  key={Math.random() * 100}
                  className='col-2 py-1 px-0 border-bottom font-weight-bold'
                >
                  {val.port?.includes('eth')
                    ? 'Eth'
                    : val.port?.includes('wlan')
                    ? 'WiFi'
                    : parseInt(val.port, 10) + 1}
                </div>
                <div
                  key={Math.random() * 100}
                  className='col-7 py-1 pl-2 pr-0 text-left border-bottom'
                >
                  {val.connected === true ? (
                    <Badge
                      color={'green'}
                      text={
                        val.name === 'No Name' || val.name === ''
                          ? 'USB Device'
                          : val.name
                      }
                      className='badge-ellipsis'
                    />
                  ) : val.name === 'No Name' ||
                    val.name === '' ||
                    val.name === 'No Device' ? (
                    <Badge
                      color={'gray'}
                      text={
                        val.name === 'No Name' || val.name === ''
                          ? 'USB Device'
                          : val.name
                      }
                    />
                  ) : (
                    <Badge
                      color={'yellow'}
                      text={
                        val.name === 'No Name' || val.name === ''
                          ? 'USB Device'
                          : val.name
                      }
                    />
                  )}
                  {/* {val.name === 'No Name' || val.name === ''
                  ? 'USB Device'
                  : val.name} */}
                </div>
                <div
                  key={Math.random() * 100}
                  className='col-3 py-1 px-0 border-bottom '
                >
                  {val.kbps}{' '}
                </div>
              </div>
            </div>
          );
        });
      return t1;
    }
  }

  function currentConnection() {
    if (unitInterface && unitInterface.length) {
      return unitInterface.filter(function (element) {
        return element.connected === true;
      }).length;
    }
    return 0;
  }

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .label('Description')
      .required()
      .max(250, 'Maximum 250 characters are allowed'),
  });

  const initialValues = {
    description: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    handleSendUnitLogs(values?.description ?? '');
    setSubmitting(false);
    closeUnitLogsModal();
  };

  return (
    <div className='col-md-4 unitBlk_cont flex-column svunit-blocks'>
      <div className='sub-title'>
        <h4>{t('SOURCE')}</h4>
      </div>
      <div className='block-wrap position-relative'>
        {sourceLoading ? (
          <div className='timeline-wrapper'>
            <div className='timeline-item'>
              <div className='animated-background facebook'>
                <div className='background-masker header-top'></div>
                <div className='background-masker header-left'></div>
                <div className='background-masker header-right'></div>
                <div className='background-masker header-bottom'></div>
                <div className='background-masker subheader-left'></div>
                <div className='background-masker subheader-right'></div>
                <div className='background-masker subheader-bottom'></div>
                <div className='background-masker content-top'></div>
                <div className='background-masker content-first-end'></div>
                <div className='background-masker content-second-line'></div>
                <div className='background-masker content-second-end'></div>
                <div className='background-masker content-third-line'></div>
                <div className='background-masker content-third-end'></div>
              </div>
            </div>
          </div>
        ) : (
          <div className='container-min' style={{ paddingBottom: '15px' }}>
            <div className='row'>
              <div className='col-sm-12 mb5'>
                <div className='row'>
                  <div className='col-sm-12'>
                    {/*Resolution: N/A*/}
                    <span className='lbltxt'>{t('INPUT')}:</span>{' '}
                    <span className='valuetxt'>
                      {resolution === undefined ? 'N/A' : resolution}{' '}
                    </span>
                    <span className='lbltxt current-connection-lbl'>
                      {t('CURRENTCONNECTIONS')}:
                    </span>
                    <span className='current-connection'>
                      {currentConnection()}
                    </span>
                  </div>
                  <div className='col-lg-12 col-md-12 currBandwidth mt-1'>
                    <span className='lbltxt'>{t('CURRENTBANDWIDTH')}:</span>{' '}
                    <span className='valuetxt'>
                      {bitrate === undefined ? 'N/A' : bitrate}
                      {/*{ currentBitrate | number | ifEmpty:'N/A'}*/}
                      {bitrate !== undefined && <span>kbps</span>}
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-12'>
                <div id='bitrate'>
                  <div className='currentBitrate'>
                    {bitrate && <BitrateChart bitrateArr={bitrateArr} />}
                  </div>
                </div>
              </div>
            </div>
            {/* {unitStatus.status === 'streaming' && ( */}
            <div className='row mx-0 px-2 text-center bandwidth'>
              {connections()}
            </div>
            <div
              className=''
              style={{ position: 'absolute', right: '10px', bottom: '5px' }}
            >
              <span>
                <button
                  onClick={openUnitLogsModal}
                  disabled={unitStatus.status === 'streaming'}
                  style={{
                    color:
                      unitStatus.status === 'streaming' ? '#999' : '#1E76AC',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    font: 'inherit',
                    cursor:
                      unitStatus.status === 'streaming'
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                >
                  {t('SENDUNITLOGS')}
                </button>
              </span>
              <span className='ml-2' title={t('SENDUNITLOGINFO')}>
                <Link
                  to='#'
                  className='help-btn fa fa-question-circle fa-lg'
                ></Link>
              </span>
            </div>
            {/* )} */}
            {unitLogsModal && (
              <Modal isOpen={unitLogsModal} toggle={closeUnitLogsModal}>
                <i className='fa fa-times' onClick={closeUnitLogsModal}></i>
                <div className='modal-footer border-bottom'>
                  <h3 className='w-100 text-left'>
                    {t('REPORTTECHNICALPROBLEM')}
                  </h3>
                </div>

                <ModalBody>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form
                        style={{
                          padding: '0px',
                        }}
                      >
                        <div className='form-group'>
                          <label htmlFor='description'>
                            {t('DESCRIPTION')}
                          </label>
                          <Field
                            name='description'
                            component='textarea'
                            placeholder='Write your description here...'
                            className='form-control expanded-textarea'
                          />
                          <ErrorMessage
                            name='description'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                        <Button
                          type='submit'
                          className='btn btn-primary btn-sm float-right'
                          // loading={isSubmitting}
                          disabled={isSubmitting}
                        >
                          {t('SUBMIT')}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </Modal>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
