// Function to get the appropriate value based on the environment
export const getValueForEnvironment = (valueForStage, valueForProd) => {
  const environment = process.env.REACT_APP_environment; // Get the current environment

  switch (environment) {
    case 'development':
      return valueForStage;
    case 'staging':
      return valueForStage;
    case 'production':
      return valueForProd;
    default:
      return valueForProd; // Default to production value
  }
};
