import { castrService } from '../services';
import { commonAction } from './common.actions';
import { history } from '../history';
import { commonConstants } from '../constants';
import { notify } from '../components/CommonNotification/CommonNotification';

export const castrActions = {
  // startStreaming,
  getRtmp,
  getCastrStream,
  setActionDestination,
};

/**
 * @name guid
 * @params none
 * @descriptiton creates 16 char unique string
 **/
let guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};
function getCastrStream(match) {
  return async () => {
    // dispatch(showLoader());
    try {
      const getCastrStreams = await castrService.getCastrStreams();

      // dispatch(hideSpinner());
      return getCastrStreams;
    } catch (err) {
      // dispatch(hideSpinner());
      notify('error', err);
      history.push({
        pathname: `/dashboard/CastrForm/${match.params.bossId}`,
      });
    }
  };
}
function getRtmp(id, matchurl) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const rtmpConfig = await castrService.getStreamRtmpConfig(id);
      if (rtmpConfig.error) {
        dispatch(hideSpinner());
        notify('error', rtmpConfig.error.message);
        history.push({
          pathname: `/dashboard/CastrForm/${matchurl.params.bossId}`,
        });
      } else {
        dispatch(hideSpinner());
        return rtmpConfig.data.data.response;
      }
    } catch (e) {
      notify('error', e);
      history.push({
        pathname: `/dashboard/CastrForm/${matchurl.params.bossId}`,
      });
    }
  };
}

function setActionDestination(payload, matchurl) {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const destinationPayload = {
        destinationName: payload.streamName,
        selectedProfile: payload.profile,
        primary_url: payload.rtmpLink,
        secondary_url: '',
        stream_name: payload.streamKey,
        username: '',
        password: '',
        id: guid(),
        streaming_provider: 'Castr',
        overrideResolution: payload.resolutionBox
          ? payload.overrideResolution.value
          : '',
        overrideFramerate: payload.frameRateBox
          ? payload.overrideFrame.value
          : '',
        overrideBitrate: payload.bitRateBox ? payload.overrideBitrate : '',
      };
      let bId = matchurl.params.bossId;
      dispatch(commonAction.createAwsDestination(destinationPayload, bId));
    } catch (e) {
      dispatch(hideSpinner());
      notify('error', e);
      history.push({
        pathname: `/dashboard/CastrForm/${matchurl.params.bossId}`,
      });
    }
  };
}

function showLoader() {
  return { type: commonConstants.OTHER_LOADING };
}
function hideSpinner() {
  return { type: commonConstants.OTHER_LOADING_FALSE };
}
