export const switchboardConstants = {
  SB_DATA_REQ: 'SB_DATA_REQ',
  SB_PROFILE: 'SB_PROFILE',
  SB_DATA_FAIL: 'SB_DATA_FAIL',
  SB_WORKFLOWS: 'SB_WORKFLOWS',
  SB_NO_WORKFLOWS: 'SB_NO_WORKFLOWS',
  SB_INGESTS: 'SB_INGESTS',
  SB_STREAM_NAME: 'SB_STREAM_NAME',
  SB_TOKEN_REQ: 'SB_TOKEN_REQ',
  SB_TOKEN: 'SB_TOKEN',
  SB_TOKEN_FAIL: 'SB_TOKEN_FAIL',
};
