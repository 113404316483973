const initialState = {
  authIsLoading: false,
  twitterIsLoading: false,
  getAccessTokenLoading: true,
  status: '',
  auth: {},
  profile: {},
  ptbroadcast: {},
  sources: [],
  region: {},
  deleteLoader: false,
};

export function periscope(state = initialState, payload) {
  switch (payload.type) {
    case 'GET_TWITTER_REGION':
      return {
        ...state,
        region: payload.twitterRegion,
        twitterIsLoading: false,
      };
    case 'DELETE_TWITTER_SOURCE_REQ':
      return {
        ...state,
        deleteLoader: true,
      };
    case 'DELETE_TWITTER_SOURCE':
      let sourcesIds = payload.sourceId.split(',');
      sourcesIds.forEach((sourceId) => {
        state.sources = state.sources.filter(
          (source) => source.id !== sourceId,
        );
      });
      return {
        ...state,
        deleteLoader: false,
        isDeleted: true,
      };
    case 'DELETE_TWITTER_SOURCE_FAIL':
      return {
        ...state,
        deleteLoader: false,
      };
    case 'TWITTER_GET_ACCESS_TOKEN_REQ':
      return {
        ...state,
        getAccessTokenLoading: true,
      };
    case 'SET_TWITTER_STATE':
      return {
        ...state,
        status: payload.state,
      };
    case 'GET_TWITTER_SOURCES':
      return {
        ...state,
        sources: payload.twitterSources,
      };
    case 'CREATE_TWITTER_SOURCE':
      return {
        ...state,
        twitterIsLoading: false,
      };
    case 'TWITTER_REQ':
      return {
        ...state,
        twitterIsLoading: true,
      };
    case 'PT_GET_TOKEN_REQ':
      return {
        ...state,
        authIsLoading: true,
      };
    case 'PT_GET_TOKEN_SUCCESS':
      return {
        ...state,
        authIsLoading: false,
        auth: payload.ptAuthData,
      };
    case 'PT_GET_TOKEN_FAIL':
      return {
        ...state,
        authIsLoading: false,
      };
    case 'PT_GET_PROFILE_REQ':
      return {
        ...state,
        ptIsLoading: true,
      };
    case 'PT_GET_PROFILE_SUCCESS':
      return {
        ...state,
        ptIsLoading: false,
        profile: payload.ptProfData,
      };
    case 'PT_GET_PROFILE_FAIL':
      return {
        ...state,
        ptIsLoading: false,
      };
    case 'PT_BROADCAST':
      return {
        ...state,
        ptbroadcast: payload.ptBroadcast,
      };
    case 'PT_INITIAL_STATE':
      return { ...state, ...initialState };
    default:
      return state;
  }
}
