import {
  callStoreStreamToolsData,
  callGetStreamToolsData,
} from '../api/aws.api';
import streamtoolZones from '../data/streamToolZones.json';
import streamtoolZonesStage from '../data/streamToolZonesStage.json';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';

const cookies = new Cookies();

export const liveStreamServices = {
  storeStreamToolsData,
  getStreamToolsData,
  // getStreamToolsDataRawResponse,
};
async function storeStreamToolsData(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  let res = await callStoreStreamToolsData(payload, awsHeaders);
  return res.data.data;
}

async function getStreamToolsData(bossID) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };

  const selectedChannel = cookies.get(`selected_channel_${bossID}`);
  let STZone =
    process.env.REACT_APP_environment === 'production'
      ? streamtoolZones.find(
          (zone) => zone?.['LUC Group Name'] === selectedChannel,
        )?.value ?? 'eu-irl-4'
      : streamtoolZonesStage.find(
          (zone) => zone?.['LUC Group Name'] === selectedChannel,
        )?.value ?? 'eu-irl-1';

  let res = await callGetStreamToolsData(
    { bossId: bossID, STZone },
    awsHeaders,
  );
  return res.data.data?.response?.data;
}

// async function getStreamToolsDataRawResponse(bossID) {
//   const awsHeaders = {
//     Authorization: 'Bearer ' + cookies.get('access_token'),
//     'x-user-uuid': `${cookies.get('user_session')}`,
//   };
//   let res = await callGetStreamToolsData(bossID, awsHeaders);
//   return res;
// }
