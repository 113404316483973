import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FBAction } from '../../../../actions';
import { errorBlock } from '../../../../api/errorBlock';
import FacebookEmoji from 'react-facebook-emoji';
import { fbAnalyticsCount } from '../../../../selectors/fb-selectors';
import './FacebookAnalytics.css';

import { FbAnalyticsListView } from './FbAnalyticsListView';
import { fbService } from '../../../../services';
import fbCareXS from '../../../../assets/images/carexs.png';
import { getLocalStorageItem } from '../../../../utils/cookieAndLocalStorageUtils';

class FacebookAnalytics extends Component {
  state = {
    fbAnalyticsLoading: true,
    modalIsOpen: false,
    alreadySub: false,
  };

  async componentDidMount() {
    const { liveVideo, selectedDestination } = this.props;
    if (
      (liveVideo.status.status === 'LIVE' ||
        liveVideo.status.status === 'SCHEDULED_UNPUBLISHED') &&
      selectedDestination.streaming_destination.external_id ===
        liveVideo.status.id &&
      !this.state.alreadySub
    ) {
      const ovpD = await this.ovpData();
      // eslint-disable-next-line no-unused-vars
      const t1 = !!ovpD ? await this.getPostAnalytics(ovpD) : null;
      // eslint-disable-next-line no-unused-vars
      const t2 = !!ovpD ? this.getTranscoderState(ovpD) : null;
    }
  }
  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    const { liveVideo, selectedDestination } = this.props;
    if (
      JSON.stringify(prevProps.liveVideo.status) !==
        JSON.stringify(liveVideo.status) &&
      !this.state.alreadySub
    ) {
      if (
        (liveVideo.status.status === 'LIVE' ||
          liveVideo.status.status === 'SCHEDULED_UNPUBLISHED') &&
        selectedDestination.streaming_destination.external_id ===
          liveVideo.status.id
      ) {
        const ovpD = await this.ovpData();
        // eslint-disable-next-line no-unused-vars
        const t1 = !!ovpD ? await this.getPostAnalytics(ovpD) : null;
        // eslint-disable-next-line no-unused-vars
        const t2 = !!ovpD ? this.getTranscoderState(ovpD) : null;
      }
    }
    /*if ((liveVideo.status.status === 'LIVE' || liveVideo.status.status === 'SCHEDULED_UNPUBLISHED') && selectedDestination.streaming_destination.external_id === liveVideo.status.id) {
            const ovpD = await this.ovpData();
            const t1 = !!ovpD ? await this.getPostAnalytics(ovpD) : null;
            const t2 = !! ovpD ? this.getTranscoderState(ovpD) : null;
        }*/
  }
  ovpData = async () => {
    const { match, selectedDestination } = this.props;
    let getToken;
    try {
      const userInfo = await fbService.userInfo();
      if (!!userInfo.ovpCredentials) {
        const ovpData = JSON.parse(userInfo.ovpCredentials);
        if (userInfo.ovpCredentials && ovpData[match.params.bossId]) {
          const ovpDat = ovpData[match.params.bossId];
          if (
            selectedDestination.streaming_destination.streaming_provider ===
              'Facebook' &&
            !!ovpDat
          ) {
            if (
              ovpDat.selected_wheretostream === 'group' ||
              ovpDat.selected_wheretostream === 'page'
            ) {
              getToken = await fbService.fbgetToken(ovpDat.selected_location);
              return getToken.access_token;
            } else {
              return getLocalStorageItem('FB_access_token');
            }
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      errorBlock(e);
    }
  };

  getTranscoderState = (token) => {
    const { match } = this.props;
    this.setState({ alreadySub: true });
    try {
      this.intervalT7 = setInterval(() => {
        this.getPostAnalytics(token);
      }, 10000);
    } catch (e) {
      this.setState({ alreadySub: false });
      errorBlock(e, match.params.bossId);
    }
  };
  getPostAnalytics = async (token) => {
    const { match, fbGetPostAnalytics, selectedDestination } = this.props;
    try {
      if (
        selectedDestination.streaming_destination.streaming_provider ===
        'Facebook'
      ) {
        await fbGetPostAnalytics(
          selectedDestination.streaming_destination.external_id,
          match.params.bossId,
          token,
        );
      }
    } catch (e) {
      clearInterval(this.intervalT7);
      //errorBlock(e) //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalT7);
  }

  toggle = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { fbReactions, analytics, t } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <React.Fragment>
        {!!fbReactions.like ||
        !!fbReactions.love ||
        !!fbReactions.haha ||
        !!fbReactions.wow ||
        !!fbReactions.care ||
        !!fbReactions.sad ||
        !!fbReactions.angry ||
        !!fbReactions.views ||
        !!fbReactions.comments ? (
          <div
            className='emojis_blk p-2 border d-flex flex-column '
            onClick={() => {
              this.setState({ modalIsOpen: true });
            }}
          >
            <div className='d-flex flex-row align-items-center justify-content-center'>
              {!!fbReactions.comments && (
                <span className='emoji eicon'>
                  <i className='fa fa-comment-o' title={'comments'}></i>
                  <span>{fbReactions.comments}</span>
                </span>
              )}
              {!!fbReactions.views && (
                <span className='emoji eicon'>
                  <i className='fa fa-eye' title={'views'}></i>
                  <span>{fbReactions.views}</span>
                </span>
              )}
              {!!fbReactions.care && (
                <span className='emoji eicon'>
                  <img src={fbCareXS} alt={'care'} title={'care'} />
                  <span>{fbReactions.care}</span>
                </span>
              )}
              {!!fbReactions.like && (
                <span className='emoji' title={'like'}>
                  <FacebookEmoji type='like' size='xs' />
                  <span>{fbReactions.like}</span>
                </span>
              )}
              {!!fbReactions.love && (
                <span className='emoji' title={'love'}>
                  <FacebookEmoji type='love' size='xs' />
                  <span>{fbReactions.love}</span>
                </span>
              )}
              {!!fbReactions.haha && (
                <span className='emoji' title={'haha'}>
                  <FacebookEmoji type='haha' size='xs' />
                  <span>{fbReactions.haha}</span>
                </span>
              )}
              {!!fbReactions.wow && (
                <span className='emoji' title={'wow'}>
                  <FacebookEmoji type='wow' size='xs' />
                  <span>{fbReactions.wow}</span>
                </span>
              )}
              {!!fbReactions.sad && (
                <span className='emoji' title={'sad'}>
                  <FacebookEmoji type='sad' size='xs' />
                  <span>{fbReactions.sad}</span>
                </span>
              )}
              {!!fbReactions.angry && (
                <span className='emoji' title={'angry'}>
                  <FacebookEmoji type='angry' size='xs' />
                  <span>{fbReactions.angry}</span>
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className='emojis_blk p-2 border d-flex flex-column '>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <span className='analyticsNoData'>{t('NOPOSTAVAILABLE')}</span>
            </div>
          </div>
        )}

        {modalIsOpen && (
          <FbAnalyticsListView
            modalIsOpen={modalIsOpen}
            modalToggle={this.toggle}
            fbAnalyticsData={analytics}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { liveVideo, analytics } = state.fb;
  const { selectedDestination } = state.destination;
  return {
    liveVideo,
    selectedDestination,
    analytics,
    fbReactions: fbAnalyticsCount(state),
  };
};
const mapDispatchToProps = {
  fbGetPostAnalytics: FBAction.fbGetPostAnalytics,
};
const ConnectedFacebookAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FacebookAnalytics));
export { ConnectedFacebookAnalytics as FacebookAnalytics };
