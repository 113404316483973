import React, { Component } from 'react';
import F2 from '@antv/f2';
const Tooltip = require('@antv/f2/lib/plugin/tooltip');

class BitrateChart extends Component {
  componentDidMount() {
    this.updateChart();
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.bitrateArr) !==
      JSON.stringify(this.props.bitrateArr)
    ) {
      this.updateChart();
    }
  }
  updateChart() {
    const data = this.props.bitrateArr;
    const chart = new F2.Chart({
      id: 'mountNode',
      height: 150,
      width: 300,
      pixelRatio: window.devicePixelRatio,
      plugins: Tooltip,
    });
    chart.source(data, {
      x: {
        tickCount: 5,
        min: 0,
      },
      y: {
        tickCount: 5,
        min: 0,
      },
    });
    chart
      .area()
      .position('x*y')
      .color('l(90) 0:#1890FF 1:#f7f7f7')
      .shape('smooth');
    chart
      .line()
      .position('x*y')
      .color('l(90) 0:#1890FF 1:#f7f7f7')
      .shape('smooth');
    chart
      .point()
      .position('x*y')
      .size(4)
      .shape('circle')
      .style({ stroke: '#fff', lineWidth: 1 });
    chart.render();
  }
  render() {
    return (
      <div>
        <canvas id='mountNode' />
      </div>
    );
  }
}

export default BitrateChart;
