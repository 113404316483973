import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ModalpopupView = ({ modalDetails }) => {
  return (
    <div>
      <Modal isOpen={modalDetails.isOpen} toggle={modalDetails.toggle}>
        <i className='fa fa-times' onClick={modalDetails.toggle}></i>
        <ModalHeader>{modalDetails.modalHeader}</ModalHeader>
        <ModalBody>{modalDetails.modalBody}</ModalBody>
        <ModalFooter >
          {modalDetails.primaryBtnText && (
            <Button color='primary' disabled={modalDetails.primaryBtnDisable || false } onClick={modalDetails.primaryBtnClick}>
              {modalDetails.primaryBtnText}
            </Button>
          )}
          {modalDetails.secondaryBtnText && (
            <Button color='secondary' onClick={modalDetails.secondaryBtnClick}>
              {modalDetails.secondaryBtnText}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};
