import { userConstants } from '../constants';
// import { REHYDRATE } from 'redux-persist';

const initialState = { user: {}, submitted: false };

const {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_LOGOUT,
  USER_INFO,
  INITIAL_STATE,
} = userConstants;

export function authentication(state = {...initialState}, payload) {
  switch (payload.type) {
    case INITIAL_STATE:
      return {
        user: {},
        submitted: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        submitted: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case 'UPDATE_OVP_CRED':
      return {
        ...state,
        user: { ...state.user, ovpCredentials: payload.ovp_credentials },
        isLoading: false,
        submitted: false,
      };
    case USER_INFO:
      return {
        ...state,
        user: payload.user,
      };
    case LOGIN_FAILURE:
      return { ...state, submitted: false };
    case USER_LOGOUT:
      return { ...state, USER_LOGOUT: true };
    // case REHYDRATE:
    //   return {
    //     ...state,
    //     submitted: false,
    //   };
    default:
      return state;
  }
}
