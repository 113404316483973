import {
  destinationService,
  unitService,
  savedDestinationsService,
} from '../services';
import {
  destinationConstant,
  commonConstants,
  streamToolConstants,
  editunitConstants,
} from '../constants';
// import { Promise } from 'bluebird';
import { errorBlock } from '../api/errorBlock';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();
const {
  GET_CHANNEL_SERVICE_DES_REQUEST,
  GET_CHANNEL_SERVICE_DES_SUCCESS,
  GET_CHANNEL_SERVICE_DES_ERROR,
  AWS_DESTINATIONS_REQ,
  AWS_DESTINATIONS_SUCCESS,
  AWS_DESTINATIONS_ERROR,
  GET_SELECTED_DES_SUCCESS,
  AWS_DESTINATION_REQ,
  AWS_DESTINATION_SUCCESS,
  AWS_DESTINATION_ERROR,
  STREAMING_PROVIDERS_SUCCESS,
  STREAMING_PROVIDERS_FAILURE,
  LUC_DESTINATIONS_SUCCESS,
  LUC_DESTINATIONS_ERROR,
  GET_UNIT_DETAILS_REQUEST,
  GET_UNIT_DETAILS_SUCCESS,
  GET_UNIT_DETAILS_ERROR,
  GET_SELECTED_DES_REQ,
  INITIAL_MANAGE_DESTINATION,
  SET_FB_PAGE_ID_SUCCESS,
} = destinationConstant;
export const destinationActions = {
  awsDestinations,
  lucDestinations,
  getChannelServicesAndDestination,
  deleteDestination,
  awsDestinationsById,
  awsDestinationsByIdEmpty,
  getStreamingProviders,
  getUnitDetails,
  getDestinationDetails,
  initialStateDestination,
  autoDeleteDestination,
  deleteSingleDestination,
  removeStreamToolDestination,
  setFBPageId,
};
/**
 * @name awsDestinations
 * @params none
 * @descriptiton gets aws destinations
 **/
function awsDestinations() {
  return async (dispatch) => {
    try {
      dispatch(request());
      destinationService.getAWSDestination().then((awsDestinations) => {
        dispatch(success(awsDestinations));
        dispatch(lucDestinations());
      });
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };

  function request() {
    return { type: AWS_DESTINATIONS_REQ };
  }
  function success(awsDestinations) {
    return { type: AWS_DESTINATIONS_SUCCESS, awsDestinations };
  }
  function failure(error) {
    return { type: AWS_DESTINATIONS_ERROR, error };
  }
}
/**
 * @name lucDestinations
 * @params none
 * @descriptiton get LUC destinations
 **/
function lucDestinations() {
  return async (dispatch) => {
    try {
      destinationService
        .getLUCDestination()
        .then((lucDestinations) => dispatch(success(lucDestinations)));
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };

  function success(lucDestinations) {
    return { type: LUC_DESTINATIONS_SUCCESS, lucDestinations };
  }
  function failure(error) {
    return { type: LUC_DESTINATIONS_ERROR, error };
  }
}
/**
 * @name getChannelServicesAndDestination
 * @params id
 * @descriptiton gets channels,services,destination of a unit
 **/
function getChannelServicesAndDestination(id) {
  return async (dispatch) => {
    dispatch(request());
    dispatch(destinationloading());
    try {
      const res = await unitService.getChannelServicesAndDestination(id);
      dispatch(success(res));
      dispatch(getDestinationDetails(res.selected_destination));
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };
  function destinationloading() {
    return { type: GET_SELECTED_DES_REQ };
  }
  function request() {
    return { type: GET_CHANNEL_SERVICE_DES_REQUEST };
  }
  function success(res) {
    return { type: GET_CHANNEL_SERVICE_DES_SUCCESS, res };
  }
  function failure(error) {
    return { type: GET_CHANNEL_SERVICE_DES_ERROR, error };
  }
}
/**
 * @name getDestinationDetails
 * @params destId
 * @descriptiton fetch a  destination details
 **/
function getDestinationDetails(
  selected_destination,
  bossId = null,
  isStreamToolActive = null,
) {
  return async (dispatch) => {
    try {
      if (selected_destination) {
        const destination = await destinationService.getDestinationDetails(
          selected_destination,
        );

        if (destination !== undefined) {
          dispatch(success(destination));
        }
      } else {
        dispatch(success(undefined));
        if (isStreamToolActive) {
          dispatch(delHelperDestination());
        }
      }
    } catch (err) {
      dispatch(success(undefined));
      //dispatch(failure(e.toString()));
      errorBlock(err);
    }
  };
  function delHelperDestination() {
    return {
      type: streamToolConstants.UPDATE_ST_HELPER_DESTINATION,
      payload: { bossId, helperDestination: {} },
    };
  }
  function success(res) {
    return { type: GET_SELECTED_DES_SUCCESS, res };
  }
}

function setFBPageId(id) {
  function success(res) {
    return { type: SET_FB_PAGE_ID_SUCCESS, res };
  }

  return async (dispatch) => {
    try {
      dispatch(success({ fb_page_id: id }));
    } catch (err) {
      dispatch(success({ fb_page_id: null }));
      errorBlock(err);
    }
  };
}

/**
 * @name deleteDestination
 * @params array of destination
 * @descriptiton deletes destinations
 **/
function deleteDestination(destArray) {
  return async (dispatch) => {
    dispatch(showSpinner());
    const awsdest_promises = [];
    const lucdest_promises = [];
    try {
      let dataArray = [];
      // eslint-disable-next-line array-callback-return
      destArray.map((dest) => {
        dataArray.push(dest.d_id);
      });
      awsdest_promises.push(destinationService.delAWSDestination(dataArray));
      destArray.map(async (dest) => {
        if (dest.id) {
          lucdest_promises.push(destinationService.delLUCDestination(dest.id));
        }
      });
      await Promise.all(awsdest_promises);
      await Promise.all(lucdest_promises);
      notify('success', i18n.t('DESTINATIONDELETED'));
      dispatch(awsDestinations());
      dispatch(hideSpinner());
    } catch (err) {
      errorBlock(err);
      dispatch(hideSpinner());
    }
    function showSpinner() {
      return { type: commonConstants.OTHER_LOADING };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}

/**
 * @name awsDestinationsById
 * @params id
 * @descriptiton fetch aws destination
 **/
function awsDestinationsById(id) {
  return async (dispatch) => {
    try {
      dispatch(request());
      savedDestinationsService.getAwsDest(id).then((selectedAwsDestination) => {
        dispatch(success(selectedAwsDestination[0]));
      });
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };
  function request() {
    return { type: AWS_DESTINATION_REQ };
  }
  function success(selectedAwsDestination) {
    return { type: AWS_DESTINATION_SUCCESS, selectedAwsDestination };
  }
  function failure(error) {
    return { type: AWS_DESTINATION_ERROR, error };
  }
}
/**
 * @name awsDestinationsByIdEmpty
 * @params none
 * @descriptiton used in custom rtmp
 **/

function awsDestinationsByIdEmpty() {
  return async (dispatch) => {
    try {
      dispatch(success({}));
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };
  function success(selectedAwsDestination) {
    return { type: AWS_DESTINATION_SUCCESS, selectedAwsDestination };
  }
  function failure(error) {
    return { type: AWS_DESTINATION_ERROR, error };
  }
}
/**
 * @name getStreamingProviders
 * @params none
 * @descriptiton get LUC providers
 **/
function getStreamingProviders() {
  return async (dispatch) => {
    try {
      const data = await destinationService.getStreamingProviders();
      dispatch(success(data));
      dispatch(streamProviderdata(data));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
  function success(providers) {
    return { type: STREAMING_PROVIDERS_SUCCESS, providers };
  }
  function failure(error) {
    return { type: STREAMING_PROVIDERS_FAILURE, error };
  }
  function streamProviderdata(streamProvidersData) {
    return {
      type: editunitConstants.GET_STREAMING_PROVIDERS,
      streamProvidersData,
    };
  }
}
/**
 * @name getUnitDetails
 * @params unitId
 * @descriptiton get details of a unit
 **/
function getUnitDetails(id) {
  return async (dispatch) => {
    dispatch(request());
    try {
      const res = await destinationService.getUnitDetails(id);
      dispatch(success(res));
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };
  function request() {
    return { type: GET_UNIT_DETAILS_REQUEST };
  }
  function success(unitDetails) {
    return { type: GET_UNIT_DETAILS_SUCCESS, unitDetails };
  }
  function failure(error) {
    return { type: GET_UNIT_DETAILS_ERROR, error };
  }
}
/**
 * @name initialStateDestination
 * @params none
 * @descriptiton reset a state
 **/
function initialStateDestination() {
  return async (dispatch) => {
    try {
      dispatch(success({}));
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };
  function success() {
    return { type: INITIAL_MANAGE_DESTINATION };
  }
  function failure(error) {
    return { type: AWS_DESTINATION_ERROR, error };
  }
}
/**
 * @name autoDeleteDestination
 * @params unit array
 * @descriptiton auto delete destination which are not in use
 **/
function autoDeleteDestination(units) {
  return async (dispatch) => {
    try {
      const awsdest = await destinationService.getAWSDestination();
      const lucdest = await destinationService.getLUCDestination();
      //if aws destination list exist call delete destination
      //if aws destinations list doesn't exist create aws dest before deleting luc destinations
      if (awsdest?.length) {
        dispatch(delete_destination(units, awsdest, lucdest));
      } else if (lucdest?.length) {
        let array = [];
        let count = 0;

        // eslint-disable-next-line array-callback-return
        lucdest.map((item) => {
          count++;
          array.push({
            d_id: item.streaming_destination.external_id,
            //'inventory_id': cookies.get('inventory_id'),
            streaming_provider: item.streaming_destination.streaming_provider,
            title: item.name,
            type: item.type,
            username: item.streaming_destination.streaming_ingest.username,
            password: item.streaming_destination.streaming_ingest.password,
            primary_url:
              item.streaming_destination.streaming_ingest.primary_url,
            secondary_url:
              item.streaming_destination.streaming_ingest.secondary_url,
            streaming_profile:
              item.streaming_destination.streaming_destination_outputs[0]
                .streaming_profile,
            stream_name:
              item.streaming_destination.streaming_destination_outputs[0]
                .stream_name,
            min_res_override:
              item.streaming_destination.streaming_destination_outputs[0]
                .min_res_override,
            max_res_override:
              item.streaming_destination.streaming_destination_outputs[0]
                .max_res_override,
            min_fps_override:
              item.streaming_destination.streaming_destination_outputs[0]
                .min_fps_override,
            max_fps_override:
              item.streaming_destination.streaming_destination_outputs[0]
                .max_fps_override,
            min_bitrate_override:
              item.streaming_destination.streaming_destination_outputs[0]
                .min_bitrate_override,
            max_bitrate_override:
              item.streaming_destination.streaming_destination_outputs[0]
                .max_bitrate_override,
          });

          if (lucdest?.length === count) {
            let payload = {
              destinations: array,
            };
            dispatch(createAWSDestinations(payload));
            dispatch(delete_destination(units, awsdest, lucdest));
          }
        });
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}
/**
 * @name createAWSDestinations
 * @params aws payload
 * @descriptiton create aws destination
 **/
function createAWSDestinations(payload) {
  return async () => {
    try {
      await destinationService.createAWSDestinations(payload);
    } catch (err) {
      errorBlock(err);
    }
  };
}

/**
 * @name deleteSingleDestination
 * @params destId,bossId
 * @descriptiton delete a luc dest
 **/
function deleteSingleDestination(destID, bossId) {
  return async (dispatch) => {
    try {
      await destinationService.delLUCDestination(destID);
      var payload1 = {
        unit: {
          selected_destination: '',
        },
      };
      await savedDestinationsService.setLucDest(payload1, bossId);
      await new Promise((r) => setTimeout(r, 1000)); //adding delay to wait for change from LUC
      dispatch(getChannelServicesAndDestination(bossId));
      //const v1 = await dispatch(getDestinationDetails(postId));
      dispatch(hideSpinner());
    } catch (err) {
      if (err && err.response && err.response.status === 404) {
        //if destination already doesn't existed. remove from store
        dispatch(getChannelServicesAndDestination(bossId));
      }
      errorBlock(err);
      dispatch(hideSpinner());
    }
    // eslint-disable-next-line no-unused-vars
    function showSpinner() {
      return { type: commonConstants.OTHER_LOADING };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name delete_destination
 * @params unit arrat,aws dest,luc dest
 * @descriptiton function to delete aws and luc dest
 **/
function delete_destination(units, awsdest, lucdest) {
  return async () => {
    try {
      const lucdest_promises = [];
      // eslint-disable-next-line array-callback-return
      lucdest.map((x) => {
        // if unit is offline check if destination is attached to unit or not in aws
        // eslint-disable-next-line array-callback-return
        let aws_unit = awsdest.filter((u) => {
          if (u.unit_id && u.d_id === x.streaming_destination.external_id) {
            return u;
          }
        });
        // check if destination is attached to unit or not in LUC
        // eslint-disable-next-line array-callback-return
        let unit = units.filter((u) => {
          if (
            (u.selected_destination && u.selected_destination === x.id) ||
            (u.status !== 'offline' && !u.deviceId)
          ) {
            return u;
          }
        });
        if (unit.length === 0 && aws_unit.length === 0) {
          lucdest_promises.push(destinationService.delLUCDestination(x.id));
        }
      });
      await Promise.all(lucdest_promises);
    } catch {}
  };
}

function removeStreamToolDestination(id) {
  return async () => {
    try {
      await destinationService.deleteStreamToolDestination(id);
    } catch (err) {
      errorBlock(err);
    }
  };
}
