import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  unitActions,
  liveStreamActions,
  commonAction,
  destinationActions,
} from '../../actions';
import { UnitHeaderView } from './UnitHeaderView';
import './UnitHeader.css';
import { getUnitWithStatus } from '../../selectors/unit_selectors';
import { getselectedDestination } from '../../selectors/destinations_selectors';
import Cookies from 'universal-cookie';
import i18n from 'i18next';
import { Modalpopup } from '../Modalpopup';
import { withRouter } from 'react-router';
import { notify } from '../CommonNotification/CommonNotification';

const cookies = new Cookies();

class UnitHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: {},
      progressInfo: '',
      showLowDelay: false,
      goLiveDisabled: true,
      goLiveDisableForStreamtools: true,
      showModel: false,
      modalDetails: {},
    };
  }

  async componentDidMount() {
    const {
      unit,
      match,
      checkDestinationsHealthToGoLive,
      streamToolsUnitsList,
    } = this.props;
    const { isStreamToolActive, dnd: streamtoolsDnd } =
      streamToolsUnitsList?.[unit.BOSSID];
    if (isStreamToolActive) {
      if (!streamtoolsDnd) {
        this.setState({
          goLiveDisableForStreamtools: await checkDestinationsHealthToGoLive(
            match,
          ),
        });
      }
    }
  }

  modelHandeler = () => {
    this.setState({
      showModel: true,
      modalDetails: {
        isOpen: true,

        classModal: '',
        modalHeader: i18n.t('REMOVEUNIT'),
        modalBody: i18n.t('AREYOUSURE'),
        toggle: this.cancelreset,
        secondaryBtnText: i18n.t('NO'),
        primaryBtnText: i18n.t('YES'),
        secondaryBtnClick: this.cancelreset,
        primaryBtnClick: this.removeUnit,
      },
    });
  };
  cancelreset = () => {
    this.setState({
      showModal: false,
      modalDetails: {
        ...this.state.modalDetails,
        isOpen: false,
      },
    });
  };
  unitsettingsHandler = (unit) => {
    const { history } = this.props;
    history.push(`/dashboard/${unit.BOSSID}/settings`);
  };
  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    this.setState({ goLiveDisabled: false });
  }
  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState) {
    const {
      unit,
      linkedin,
      unitStatus,
      match,
      checkDestinationsHealthToGoLive,
      fbLoginData,
      ytLoginData,
      linkedLoginData,
      periscopeLoginData,
      streamToolsUnitsList,
      liveVideo,
      selectedDestination,
      broadcast,
      setUnitLoaderState,
    } = this.props;

    if (streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive) {
      if (
        prevProps.unit.BOSSID !== unit.BOSSID ||
        JSON.stringify(
          streamToolsUnitsList?.[unit.BOSSID]?.selectedDestinations,
        ) !==
          JSON.stringify(
            prevProps.streamToolsUnitsList?.[unit.BOSSID]?.selectedDestinations,
          ) ||
        (unit.status === 'connected' &&
          unit.BOSSID === prevProps.unit.BOSSID &&
          prevProps.unit.status !== unit.status) ||
        JSON.stringify(prevProps.periscopeLoginData) !==
          JSON.stringify(periscopeLoginData) ||
        JSON.stringify(prevProps.ytLoginData) !== JSON.stringify(ytLoginData) ||
        JSON.stringify(prevProps.linkedLoginData) !==
          JSON.stringify(linkedLoginData) ||
        linkedin.postStatus === 'processing' ||
        JSON.stringify(prevProps.fbLoginData) !== JSON.stringify(fbLoginData) ||
        prevProps.streamToolsUnitsList?.[unit.BOSSID]
          ?.isStreamtoolsStreaming !==
          streamToolsUnitsList?.[unit.BOSSID]?.isStreamtoolsStreaming ||
        prevProps.streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive !==
          streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive ||
        prevProps?.streamToolsUnitsList?.[unit.BOSSID]?.isUpdatingZone !==
          streamToolsUnitsList?.[unit.BOSSID]?.isUpdatingZone
      ) {
        this.setState({
          goLiveDisableForStreamtools: await checkDestinationsHealthToGoLive(
            match,
          ),
        });
      }
    }

    if (
      prevProps.unitStreamLoader[cookies.get('currentSelectedUnit')] !==
      this.props.unitStreamLoader[cookies.get('currentSelectedUnit')]
    ) {
      const { status } = unit;
      // eslint-disable-next-line no-unused-vars
      (status === 'connected' || status === 'streaming') &&
        this.setState({ showLoader: { [unit.BOSSID]: true } });
      setUnitLoaderState(unit.BOSSID, true);
      // this.forceStopLoader(unit.BOSSID);
    }
    if (
      prevProps.unitStatus.selected_channel !==
      this.props.unitStatus.selected_channel
    ) {
      if (
        unitStatus.status !== 'offline' &&
        unitStatus.services.indexOf('lrt') !== -1 &&
        unitStatus.services.indexOf('cloud') !== -1 &&
        unitStatus.selected_channel &&
        unitStatus.selected_channel !== 'null'
      ) {
        this.setState({ showLowDelay: true });
      } else {
        this.setState({ showLowDelay: false });
      }
    }
    if (prevProps.unit.status !== this.props.unit.status) {
      const { status } = unit;
      if (status === 'connected' || status === 'streaming') {
        this.setState({ showLoader: { [unit.BOSSID]: false } });
        setUnitLoaderState(unit.BOSSID, false);
      }
    }
    if (
      JSON.stringify(prevProps.selectedDestination) !==
        JSON.stringify(this.props.selectedDestination) ||
      prevProps.streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive !==
        streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive
    ) {
      if (!!unitStatus) {
        if (
          unitStatus?.status !== 'offline' &&
          unitStatus?.services?.indexOf('lrt') !== -1 &&
          unitStatus?.services?.indexOf('cloud') !== -1 &&
          unitStatus?.selected_channel &&
          unitStatus?.selected_channel !== 'null'
        ) {
          this.setState({ showLowDelay: true });
        } else {
          this.setState({ showLowDelay: false });
        }
      }
      if (
        !!selectedDestination &&
        !!selectedDestination?.streaming_destination
      ) {
        const provider =
          this.props.selectedDestination?.streaming_destination?.streaming_provider.toLowerCase();
        const status = linkedin.postStatus.status.toLowerCase();
        if (
          provider !== 'facebook' &&
          provider !== 'youtube' &&
          provider !== 'linkedin'
        ) {
          this.setState({ goLiveDisabled: false });
        }
        if (provider === 'linkedin') {
          if (
            status === '' ||
            status === 'incomplete' ||
            status === 'deleted'
          ) {
            this.setState({ goLiveDisabled: true });
          } else {
            this.setState({ goLiveDisabled: false });
          }
        }
        if (provider === 'youtube') {
          if (!!cookies.get('YT_access_token')) {
            this.setState({ goLiveDisabled: false });
          } else {
            this.setState({ goLiveDisabled: true });
          }
        }
        if (provider === 'periscope and twitter') {
          // if (!!cookies.get('periscope_access_token')) {
          //   this.setState({ goLiveDisabled: false });
          // } else {
          //   this.setState({ goLiveDisabled: true });
          // }
        }
      } else {
        this.setState({ goLiveDisabled: true });
      }
    }
    if (prevProps.liveVideo.status !== liveVideo.status) {
      if (
        !!selectedDestination &&
        !!selectedDestination.streaming_destination &&
        selectedDestination.streaming_destination.streaming_provider ===
          'Facebook'
      ) {
        if (
          liveVideo.status === '' ||
          (typeof liveVideo.status.status === 'string' &&
            liveVideo.status.status === '') ||
          (typeof liveVideo.status === 'object' &&
            liveVideo.status.status === 'DELETED') ||
          (typeof liveVideo.status === 'string' &&
            liveVideo.status.toLowerCase().includes('error'))
        ) {
          this.setState({ goLiveDisabled: true });
        } else {
          this.setState({ goLiveDisabled: false });
        }
      }
    }
    if (
      JSON.stringify(prevProps.linkedin.postStatus) !==
        JSON.stringify(linkedin.postStatus) ||
      linkedin.postStatus === 'processing'
    ) {
      if (
        !!selectedDestination &&
        !!selectedDestination.streaming_destination &&
        selectedDestination.streaming_destination.streaming_provider ===
          'LinkedIn'
      ) {
        const status = linkedin.postStatus.status.toLowerCase();
        if (status === '' || status === 'incomplete' || status === 'deleted') {
          this.setState({ goLiveDisabled: true });
        } else {
          this.setState({ goLiveDisabled: false });
        }
      }
    }
    if (JSON.stringify(prevProps.broadcast) !== JSON.stringify(broadcast)) {
      if (
        !!selectedDestination &&
        !!selectedDestination.streaming_destination &&
        selectedDestination.streaming_destination.streaming_provider.toLowerCase() ===
          'youtube'
      ) {
        if (!broadcast.id || !!!cookies.get('YT_access_token')) {
          this.setState({ goLiveDisabled: true });
        } else {
          this.setState({ goLiveDisabled: false });
        }
      }
    }
    if (
      JSON.stringify(prevProps.ptbroadcast) !==
      JSON.stringify(this.props.ptbroadcast)
    ) {
      if (
        !!selectedDestination &&
        !!selectedDestination.streaming_destination &&
        selectedDestination.streaming_destination.streaming_provider.toLowerCase() ===
          'periscope and twitter'
      ) {
        if (
          !this.props.ptbroadcast.id ||
          !!!cookies.get('periscope_access_token')
        ) {
          this.setState({ goLiveDisabled: true });
        } else {
          this.setState({ goLiveDisabled: false });
        }
      }
    }
  }

  removeUnit = async () => {
    const { removeUnit, unit, history } = this.props;
    await removeUnit(unit.BOSSID).then(() => {
      this.cancelreset();
      history.push(`/dashboard`);
    });
  };

  handleSRTMode = async () => {
    const { unitStatus, unit } = this.props;
    if (!unitStatus?.selected_channel) {
      notify('error', i18n.t('LRTSERVICEOFF'));
      this.setState({ showLoader: { [unit.BOSSID]: false } });
      return true;
    }
    this.setState({ showLoader: { [unit.BOSSID]: false } });
    return false;
  };

  handleSRTCondition = async () => {
    const { unit, streamToolsUnitsList, lucSelectedDestination } = this.props;

    if (unit?.video?.videoStandard === 'Camera not connected') {
      notify('error', i18n.t('NOVIDEOSOURCE'));
      return true;
    }

    const isStreamToolActive =
      streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive;
    const isStreamSRTStreamInST = streamToolsUnitsList?.[
      unit.BOSSID
    ]?.selectedDestinations?.find((data) =>
      data?.streaming_destination?.streaming_destination_outputs[0]?.streaming_profile
        ?.toLowerCase()
        ?.includes('h265'),
    );
    const isSTreamSRT =
      lucSelectedDestination?.streaming_destination?.streaming_destination_outputs[0]?.streaming_profile
        ?.toLowerCase()
        ?.includes('h265');

    let isExit;
    if (
      (isStreamToolActive && isStreamSRTStreamInST) ||
      (!isStreamToolActive && isSTreamSRT)
    ) {
      this.setState({ showLoader: { [unit.BOSSID]: true } });

      const timeoutPromise = new Promise((resolve) => {
        setTimeout(async () => {
          isExit = await this.handleSRTMode();
          resolve(isExit);
        }, 2500);
      });
      return await timeoutPromise;
    }
  };

  handleStartStream = async () => {
    const { startStream, goLive, unit, streamToolsUnitsList } = this.props;

    if (streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive) {
      await goLive(unit);
    } else {
      let data = await this.handleSRTCondition();
      if (data) {
        return;
      }

      await startStream(unit);
    }
    // if (streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive) {
    //   await goLive(unit);
    // } else {
    //   if (unit?.video?.videoStandard === 'Camera not connected') {
    //     notify('error', i18n.t('NOVIDEOSOURCE'));
    //   } else {
    //     await startStream(unit);
    //   }
    // }
  };

  render() {
    const {
      t,
      unit,
      isEdit,
      renameUnit,
      cancelRenameUnit,
      changedelayMode,
      startStream,
      unitstart,
      rebootUnit,
      selectedDestination,
      match,
      history,
      createEasyLiveProject,
      stopStream,
      streamToolsUnitsList,
      goLive,
      checkDestinationsHealthToGoLive,
      stopUnit,
    } = this.props;
    // const isStreamToolActive = streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive
    return (
      <>
        <UnitHeaderView
          t={t}
          unit={unit}
          isEdit={isEdit}
          renameUnit={renameUnit}
          cancelRenameUnit={cancelRenameUnit}
          changedelayMode={changedelayMode}
          startStream={startStream}
          unitstart={unitstart}
          stopStream={stopStream}
          selectedDestination={selectedDestination}
          unitsettingsHandler={this.unitsettingsHandler}
          showLoader={this.state.showLoader}
          goLiveDisabled={
            this.state.goLiveDisabled ||
            streamToolsUnitsList?.[unit.BOSSID]?.isLoadingData
          }
          showLowDelay={this.state.showLowDelay}
          rebootUnit={rebootUnit}
          removeUnit={this.modelHandeler}
          createEasyLiveProject={createEasyLiveProject}
          handleStartStream={this.handleStartStream}
          progress={streamToolsUnitsList?.[unit.BOSSID]?.progress}
          isStreamToolActive={
            streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive
          }
          progressInfo={this.state.progressInfo}
          isStreamtoolsStreaming={
            streamToolsUnitsList?.[unit.BOSSID]?.isStreamtoolsStreaming
          }
          streamToolsUnitsList={streamToolsUnitsList}
          goLive={goLive}
          checkDestinationsHealthToGoLive={checkDestinationsHealthToGoLive}
          goLiveDisableForStreamtools={this.state.goLiveDisableForStreamtools}
          stopUnit={stopUnit}
          streamToolData={streamToolsUnitsList?.[unit?.BOSSID]}
          handleSRTCondition={this.handleSRTCondition}
        />
        {this.state.showModel && this.state.modalDetails ? (
          <Modalpopup
            t={t}
            match={match}
            history={history}
            modalDetails={this.state.modalDetails}
          />
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isEdit } = state.unit;
  const { unitStreamLoader } = state.common;
  const {
    liveVideo,
    fb_data: { login: fbLoginData },
  } = state.fb;
  const {
    linkedin,
    linkedin: { auth: linkedLoginData },
  } = state.linkedin;
  const { data } = state.channelServiceDestination;
  const { broadcast, login: ytLoginData } = state.yt.YT_data;
  const { ptbroadcast, auth: periscopeLoginData } = state.periscope;
  const { selectedDestination: lucSelectedDestination, unitDetails } =
    state.destination;
  const streamToolsUnitsList = state.streamTools;

  return {
    unit: getUnitWithStatus(state),
    selectedDestination: getselectedDestination(state),
    isEdit,
    liveVideo,
    linkedin,
    unitStatus: data,
    unitStreamLoader,
    broadcast,
    ptbroadcast,
    fbLoginData,
    ytLoginData,
    periscopeLoginData,
    linkedLoginData,
    lucSelectedDestination,
    streamToolsUnitsList,
    unitDetails,
  };
}

const mapDispatchToProps = {
  renameUnit: unitActions.renameUnit,
  cancelRenameUnit: unitActions.cancelEditSelectedUnit,
  changedelayMode: unitActions.changedelayMode,
  startStream: unitActions.startStream,
  startUnit: unitActions.startUnit,
  stopStream: unitActions.stopStream,
  rebootUnit: unitActions.rebootUnit,
  removeUnit: unitActions.removeUnit,
  showProgressBar: commonAction.showProgressBar,
  hideProgressBar: commonAction.hideProgressBar,
  setProgressBarInfo: commonAction.setProgressBarInfo,
  goLive: liveStreamActions.goLive,
  setLiveStreamProgressState: liveStreamActions.setLiveStreamProgressState,
  checkDestinationsHealthToGoLive:
    liveStreamActions.checkDestinationsHealthToGoLive,
  deleteDestination: destinationActions.deleteSingleDestination,
  // isStreamtoolsStreaming: liveStreamActions.isStreamtoolsStreaming,
  setUnitLoaderState: unitActions.setUnitLoaderState,
  stopUnit: unitActions.stopUnit,
};

const ConnectedUnitHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(UnitHeader)));
export { ConnectedUnitHeader as UnitHeader };
