/* eslint-disable */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const StreamtoolProtected = ({ component: Component, ...rest }) => {
  const streamToolsUnitsList = useSelector((state) => state.streamTools);
  const BOSSID = useSelector((state) => state.unit?.unit?.BOSSID);
  return (
    <Route
      {...rest}
      render={(props) =>
        streamToolsUnitsList?.[BOSSID]?.isStreamToolActive &&
        streamToolsUnitsList?.[BOSSID]?.streamtoolSubscribed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/dashboard/units/${cookies.get(
                'currentSelectedUnit',
              )}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
