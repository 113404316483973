export const fbConstants = {
  FB_PAGES_REQ: 'FB_PAGES_REQ',
  FB_PAGES_FAILURE: 'FB_PAGES_FAILURE',
  FB_PAGES: 'FB_PAGES',
  FB_GROUPS: 'FB_GROUPS',
  FB_GROUPS_FAILURE: 'FB_GROUPS_FAILURE',
  FB_EVENTS: 'FB_EVENTS',
  FB_EVENTS_FAILURE: 'FB_EVENTS_FAILURE',
  FB_LIVE_VIDEOS: 'FB_LIVE_VIDEOS',
  FB_LIVE_VIDEOS_FAILURE: 'FB_LIVE_VIDEOS_FAILURE',
  FB_LOGIN_FAILURE: 'FB_LOGIN_FAILURE',
  FB_LOGIN: 'FB_LOGIN',
  FB_PERSONAL: 'FB_PERSONAL',
  FB_LIVE_VIDEOS_EXISTS: 'FB_LIVE_VIDEOS_EXISTS',
  FB_LIVE_VIDEOS_EXISTS_FAILURE: 'FB_LIVE_VIDEOS_EXISTS_FAILURE',
  RECENT_DEST_EXISTS: 'RECENT_DEST_EXISTS',
  RECENT_DEST_EXISTS_FAILURE: 'RECENT_DEST_EXISTS_FAILURE',
  RECENT_DEST_FAILURE: 'RECENT_DEST_FAILURE',
  RECENT_DEST: 'RECENT_DEST',
  SHOW_FB_SIGN_IN: 'SHOW_FB_SIGN_IN',
  SHOW_FB_SIGN_IN_FALSE: 'SHOW_FB_SIGN_IN_FALSE',
  FB_SESSION_EXPIRED_TRUE: 'FB_SESSION_EXPIRED_TRUE',
  FB_SESSION_EXPIRED_FALSE: 'FB_SESSION_EXPIRED_FALSE',
  FB_DELETE_DEST: 'FB_DELETE_DEST',
  FB_DELETE_DEST_FAIL: 'FB_DELETE_DEST_FAIL',
  FB_LOADING_TRUE: 'FB_LOADING_TRUE',
  FB_LOADING_FALSE: 'FB_LOADING_FALSE',
  SHOW_FB_LOADING_TRUE: 'SHOW_FB_LOADING_TRUE',
  SHOW_FB_LOADING_FALSE: 'SHOW_FB_LOADING_FALSE',
  FB_CROSSPOST_PAGE: 'FB_CROSSPOST_PAGE',
  FB_CROSSPOST_PAGE_ERR: 'FB_CROSSPOST_PAGE_ERR',

  STREAMLOCATION_OPTIONS: 'STREAMLOCATION_OPTIONS',

  FB_OVP_DATA: 'FB_OVP_DATA',
  FB_CREATE_LIVE_VIDEO: 'FB_CREATE_LIVE_VIDEO',
  FB_CREATE_LIVE_VIDEO_FAIL: 'FB_CREATE_LIVE_VIDEO_FAIL',
  FB_UPDATE_LIVE_VIDEO: 'FB_UPDATE_LIVE_VIDEO',
  FB_UPDATE_LIVE_VIDEO_FAIL: 'FB_UPDATE_LIVE_VIDEO_FAIL',
  FB_INITIAL_STATE: 'FB_INITIAL_STATE',
  FB_LIVE_VIDEO_STATUS: 'FB_LIVE_VIDEO_STATUS',
  FB_LIVE_VIDEO_STATUS_ERROR: 'FB_LIVE_VIDEO_STATUS_ERROR',
  FB_UPDATE_LIVE_VIDEO_STATUS: 'FB_UPDATE_LIVE_VIDEO_STATUS',
  FB_UPDATE_LIVE_VIDEO_STATUS_ERR: 'FB_UPDATE_LIVE_VIDEO_STATUS_ERR',
  FB_POST_ANALYTICS: 'FB_POST_ANALYTICS',
  FB_POST_ANALYTICS_FAIL: 'FB_POST_ANALYTICS_FAIL',

  GET_FB_VIDEO_STATUS: 'GET_FB_VIDEO_STATUS',

  GET_FB_VIDEO_STATUS_FAIL: 'GET_FB_VIDEO_STATUS_FAIL',
  GET_FB_AWS_DEST: 'GET_FB_AWS_DEST',
  GET_FB_AWS_DEST_FAIL: 'GET_FB_AWS_DEST_FAIL',

  GET_CROSS_POST_SHARED: 'GET_CROSS_POST_SHARED',

  GET_CROSS_POST_SHARED_FAIL: 'GET_CROSS_POST_SHARED_FAIL',

  GET_FB_USER_DETAILS: 'GET_FB_USER_DETAILS',
  GET_FB_USER_DETAILS_FAIL: 'GET_FB_USER_DETAILS_FAIL',
  FB_POST_SUCCESS: 'FB_POST_SUCCESS',
  FB_POST_FAILURE: 'FB_POST_FAILURE',
};
