import { twitchConstants } from '../constants';

const initialState = {
  twitchLoading: true,
  auth: {},
  channel: {},
  ingests: {},
  authIsLoading: true,
};
const {
  TWITCH_LOADING,
  CHANNEL_DETAILS,
  CHANNEL_DETAILS_FAIL,
  INGEST_DETAILS_FAIL,
  INGEST_DETAILS,
} = twitchConstants;

export function twitch(state = initialState, payload) {
  switch (payload.type) {
    case TWITCH_LOADING:
      return {
        ...state,
        twitchLoading: true,
      };
    case CHANNEL_DETAILS:
      return {
        ...state,
        channel: payload.channel,
      };
    case CHANNEL_DETAILS_FAIL:
      return {
        ...state,
        channel: payload.error,
        twitchLoading: false,
      };
    case INGEST_DETAILS:
      return {
        ...state,
        ingests: payload.ingest,
        twitchLoading: false,
      };
    case INGEST_DETAILS_FAIL:
      return {
        ...state,
        ingests: payload.error,
        twitchLoading: false,
      };
    default:
      return state;
  }
}
