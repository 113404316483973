import { streamToolConstants } from '../constants';
import { getValueForEnvironment } from '../utils/setZoneValues';

const {
  GET_DSESTINATIONS_SUCCESS,
  GET_DSESTINATIONS_FAIL,
  SET_GOLIVE_STAGE,
  CHANGE_STREAM_TOOL_MODE,
  SET_GOLIVE_INFO,
  SET_DND,
  SET_ST_INITIAL_STATE,
  EL_STREAM_TOKEN,

  EL_STREAM_LIVE_MANAGER_ID,
  EL_STREAM_INPUT_ID,
  EL_WATERMARK_URL,
  EL_WATERMARK_X,
  EL_WATERMARK_Y,
  EL_STREAM_SOCKET,
  EL_FALLBACK_SLATE_URL,
  EL_FALLBACK_SLATE_NAME,
  EL_LOADING_DATA,
  EL_STREAMTOOLS_SUBSCRIBED,

  EL_IS_STREAMTOOLS_STREAMING,
  UPDATE_ST_HELPER_DESTINATION,
  SET_INITIAL_SET,
  SET_FPS,
  SET_AUDIO_BITRATE,
  SET_VIDEO_BITRATE,
  SET_PROFILE,
  SET_STEAMTOOL_ZONE,
  SET_STREAMTOOL_ZONE_LOADER,
  SET_LOG_PROGRESS,
  SET_LOG_INTERVAL,
  EL_WATERMARK_ZOOM,
  INITIAL_DATA_LOADED,
  LOGO_HEIGHT,
  LOGO_WIDTH,
  SLATE_HEIGHT,
  SLATE_WIDTH,
} = streamToolConstants;

const intialState = {
  isStreamToolActive: false,
  selectedDestinations: [],
  progress: 0,
  progressInfo: '',
  dnd: false,
  helperDestination: {},
  streamtoolSubscribed: false,
  fps: null,
  videoBitrate: null,
  audioBitrate: null,
  profile: '1920 x 1080 Widescreen (16:9) 60 fps',
  selectedZone: getValueForEnvironment('eu-irl-1', 'eu-irl-4'),
  isUpdatingZone: false,
  initialDataLoaded: false,
};
export function streamTools(state = null, action) {
  switch (action.type) {
    case SET_ST_INITIAL_STATE: {
      let tempState = {};
      const streamToolUnitsList = { ...state };
      action.units.forEach((unit) => {
        tempState[unit.BOSSID] =
          streamToolUnitsList && !!streamToolUnitsList[unit.BOSSID]
            ? {
                ...streamToolUnitsList[unit.BOSSID],
                dnd: false,
                isUpdatingZone: false,
                progress: 0,
                progressInfo: '',
              }
            : {
                ...intialState,
              };
      });
      return action?.units ? tempState : null;
    }
    case GET_DSESTINATIONS_SUCCESS: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            selectedDestinations: action.payload?.dest,
          },
        };
      }
      return state;
    }
    case GET_DSESTINATIONS_FAIL: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            selectedDestinations: [],
          },
        };
      }
      return state;
    }
    case SET_GOLIVE_STAGE: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            progress: action.payload?.progress,
          },
        };
      }
      return state;
    }
    case CHANGE_STREAM_TOOL_MODE: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            isStreamToolActive: action.payload?.isActive,
          },
        };
      }
      return state;
    }

    case SET_GOLIVE_INFO: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            progressInfo: action.payload?.progressInfo,
          },
        };
      }
      return state;
    }

    case SET_DND: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            dnd: action.payload?.dnd,
          },
        };
      }
      return state;
    }

    case EL_STREAM_TOKEN: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            streamToken: action.payload?.streamToken,
          },
        };
      }
      return state;
    }

    case EL_STREAM_LIVE_MANAGER_ID: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            streamLiveManageId: action.payload?.streamLiveManageId,
          },
        };
      }
      return state;
    }

    case EL_STREAM_INPUT_ID: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            streamInputId: action.payload?.streamInputId,
          },
        };
      }
      return state;
    }

    case EL_WATERMARK_URL: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            watermarkUrl: action.payload?.watermarkUrl,
          },
        };
      }
      return state;
    }

    case EL_WATERMARK_X: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            x: action.payload?.x,
          },
        };
      }
      return state;
    }

    case EL_WATERMARK_Y: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            y: action.payload?.y,
          },
        };
      }
      return state;
    }

    case EL_STREAM_SOCKET: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            socket: action.payload?.socket,
          },
        };
      }
      return state;
    }
    case EL_FALLBACK_SLATE_URL: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            fallBackSlateUrl: action.payload?.fallBackSlateUrl,
          },
        };
      }
      return state;
    }
    case EL_FALLBACK_SLATE_NAME: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            fallBackSlateName: action.payload?.fallBackSlateName,
          },
        };
      }
      return state;
    }
    case EL_LOADING_DATA: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action?.payload?.bossId],
            isLoadingData: action.payload?.isLoadingData,
          },
        };
      }
      return state;
    }
    case EL_STREAMTOOLS_SUBSCRIBED: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            streamtoolSubscribed: action.payload?.streamtoolSubscribed,
          },
        };
      }
      return state;
    }

    case EL_IS_STREAMTOOLS_STREAMING: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            isStreamtoolsStreaming: action.payload?.isStreamtoolsStreaming,
          },
        };
      }
      return state;
    }

    case UPDATE_ST_HELPER_DESTINATION: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            helperDestination: action.payload?.helperDestination,
          },
        };
      }
      return state;
    }

    case SET_INITIAL_SET:
      return {
        ...state,
        [action.bossId]: {
          ...intialState,
        },
      };
    case SET_FPS:
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            fps: action.payload?.fps,
          },
        };
      }
      return state;

    case SET_AUDIO_BITRATE:
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            audioBitrate: action.payload?.audioBitrate,
          },
        };
      }
      return state;

    case SET_VIDEO_BITRATE:
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            videoBitrate: action.payload?.videoBitrate,
          },
        };
      }
      return state;
    case SET_PROFILE:
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            profile: action.payload?.profile,
          },
        };
      }
      return state;

    case SET_STREAMTOOL_ZONE_LOADER: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            isUpdatingZone: action.payload?.value,
          },
        };
      }
      return state;
    }

    case SET_STEAMTOOL_ZONE: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            selectedZone: action.payload?.selectedZone,
          },
        };
      }
      return state;
    }
    case SET_LOG_PROGRESS: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            logProgress: action.payload?.logProgress,
          },
        };
      }
      return state;
    }
    case SET_LOG_INTERVAL: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            logInterval: action.payload?.logInterval,
          },
        };
      }
      return state;
    }
    case EL_WATERMARK_ZOOM: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            logoZoom: action.payload?.logoZoom,
          },
        };
      }
      return state;
    }

    case INITIAL_DATA_LOADED: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            initialDataLoaded: action.payload?.initialDataLoaded,
          },
        };
      }
      return state;
    }

    // case REHYDRATE:
    //   {
    //     let streamToolsUnitList = action?.payload?.streamTools
    //     if (streamToolsUnitList) {
    //       Object.keys(streamToolsUnitList).forEach(key => {
    //         streamToolsUnitList[key].dnd = false
    //       })
    //       return streamToolsUnitList;
    //     }
    //     return state
    //   }
    case LOGO_HEIGHT: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            logoHeight: action.payload?.logoHeight,
          },
        };
      }
      return state;
    }
    case LOGO_WIDTH: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            logoWidth: action.payload?.logoWidth,
          },
        };
      }
      return state;
    }
    case SLATE_HEIGHT: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            slateHeight: action.payload?.slateHeight,
          },
        };
      }
      return state;
    }
    case SLATE_WIDTH: {
      if (action.payload.bossId) {
        return {
          ...state,
          [action.payload.bossId]: {
            ...state[action.payload.bossId],
            slateWidth: action.payload?.slateWidth,
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}
