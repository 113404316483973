import { ytConstants } from '../constants';
const {
  YT_LOGIN,
  YT_LOGIN_FAILURE,
  SHOW_YT_SIGN_IN,
  SHOW_YT_SIGN_IN_FALSE,
  GET_BROADCASTS,
  GET_BROADCASTS_FAIL,
  GET_LIVE_STREAMS,
  GET_LIVE_STREAMS_FAIL,
  YT_LOADING_FALSE,
  YT_LOADING_TRUE,
  YT_SESSION_EXPIRED_FALSE,
  YT_SESSION_EXPIRED_TRUE,
  YT_BROADCAST_EXIST,
  YT_LIVE_STREAM_EXIST,
  YT_LIVE_STREAM_LIST,
  YT_BROADCAST_LIST,
  YT_DELETE_DASHBOARD_FAIL,
  YT_DELETE_DASHBOARD,
  YT_DELETE_UPCOMING,
  YT_DELETE_UPCOMING_FAIL,
  YT_INITIAL_STATE,
  GET_DEFAULT_BROADCAST,
  GET_DEFAULT_BROADCAST_FAIL,
  GET_DEFAULT_LIVE_STREAM,
  GET_DEFAULT_LIVE_STREAM_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_FAIL,
  YT_LOADING,
  YT_LOADING_STOP,
  GET_BROADCAST_BY_ID,
  GET_BROADCAST_BY_ID_FAIL,
  GET_LIVE_STREAM_BY_ID,
  GET_LIVE_STREAM_BY_ID_FAIL,
  YT_BROADCAST_ANALYTICS,
  YT_ANALYTICS_LOADING,
  YT_ANALYTICS_STOP,
  YT_VIDEO_ANALYTICS,
} = ytConstants;

const initialState = {
  showYTSignIn: true,
  showYTLoading: true,
  loadingYT: true,
  YT_data: {
    login: [],
    broadcasts: [],
    liveStreams: [],
    unpublishedEvent: [],
    dashboardEvents: [],
    defaultBroadcast: {},
    defaultLiveStream: {},
    broadcast: {},
    liveStream: {},
  },
  deleteEvent: {
    dashboardEvent: {},
    upcomingEvent: {},
  },
  analytics: [],
  videoanalytics: [],
  userDetails: {},
  broadcastExists: false,
  liveStreamExists: false,
  ytSessionExpired: true,
  loadingYTAnalytics: true,
};
export function yt(state = initialState, payload) {
  switch (payload.type) {
    case YT_LOGIN:
      return {
        ...state,
        YT_data: {
          login: payload.ytLoginData,
          broadcasts: [],
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case YT_LOGIN_FAILURE:
      return {
        ...state,
        YT_data: {
          login: payload.error,
          broadcasts: [],
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case SHOW_YT_SIGN_IN:
      return {
        ...state,
        showYTSignIn: true,
      };
    case SHOW_YT_SIGN_IN_FALSE:
      return {
        ...state,
        showYTSignIn: false,
      };
    case YT_LOADING:
      return {
        ...state,
        loadingYT: true,
      };
    case YT_LOADING_STOP:
      return {
        ...state,
        loadingYT: false,
      };
    case GET_BROADCASTS:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: payload.ytBroadcastData,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: state.YT_data.defaultBroadcast,
          defaultLiveStream: state.YT_data.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case GET_BROADCASTS_FAIL:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: payload.error,
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case GET_LIVE_STREAMS:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: payload.ytLiveStreamData,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: state.YT_data.defaultBroadcast,
          defaultLiveStream: state.YT_data.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case GET_LIVE_STREAMS_FAIL:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: payload.ytLiveStreamData,
          liveStreams: payload.error,
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case YT_LOADING_TRUE:
      return {
        ...state,
        showYTLoading: true,
      };
    case YT_LOADING_FALSE:
      return {
        ...state,
        showYTLoading: false,
      };
    case YT_SESSION_EXPIRED_TRUE:
      return {
        ...state,
        ytSessionExpired: true,
      };
    case YT_SESSION_EXPIRED_FALSE:
      return {
        ...state,
        ytSessionExpired: false,
      };
    case YT_LIVE_STREAM_EXIST:
      return {
        ...state,
        liveStreamExists: payload.liveStreamExists,
      };
    case YT_BROADCAST_EXIST:
      return {
        ...state,
        broadcastExists: payload.broadcastExists,
      };
    case YT_BROADCAST_LIST:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: payload.broadcastData,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: state.YT_data.defaultBroadcast,
          defaultLiveStream: state.YT_data.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case YT_LIVE_STREAM_LIST:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: payload.liveStreamData,
          defaultBroadcast: state.YT_data.defaultBroadcast,
          defaultLiveStream: state.YT_data.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case YT_DELETE_DASHBOARD:
      return {
        ...state,
        deleteEvent: {
          dashboardEvent: payload.sucData,
          upcomingEvent: state.deleteEvent.upcomingEvent,
        },
      };
    case YT_DELETE_DASHBOARD_FAIL:
      return {
        ...state,
        deleteEvent: {
          dashboardEvent: payload.error,
          upcomingEvent: state.deleteEvent.upcomingEvent,
        },
      };
    case YT_DELETE_UPCOMING:
      return {
        ...state,
        deleteEvent: {
          dashboardEvent: state.deleteEvent.dashboardEvent,
          upcomingEvent: payload.sucData,
        },
      };
    case YT_DELETE_UPCOMING_FAIL:
      return {
        ...state,
        deleteEvent: {
          dashboardEvent: state.deleteEvent.dashboardEvent,
          upcomingEvent: payload.error,
        },
      };
    case YT_INITIAL_STATE:
      return { ...state, ...initialState };
    case GET_DEFAULT_BROADCAST:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: payload.defaultBroadcast,
          defaultLiveStream: state.YT_data.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case GET_DEFAULT_BROADCAST_FAIL:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: [],
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case GET_DEFAULT_LIVE_STREAM:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: state.YT_data.defaultBroadcast,
          defaultLiveStream: payload.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case GET_DEFAULT_LIVE_STREAM_FAIL:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: [],
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload.userDetails,
      };
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        userDetails: {},
      };
    case GET_BROADCAST_BY_ID:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: payload.defaultBroadcast,
          defaultLiveStream: state.YT_data.defaultLiveStream,
          broadcast: payload.broadcast,
          liveStream: state.YT_data.liveStream,
        },
      };
    case GET_BROADCAST_BY_ID_FAIL:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: [],
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case GET_LIVE_STREAM_BY_ID:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: state.YT_data.broadcasts,
          liveStreams: state.YT_data.liveStreams,
          unpublishedEvent: state.YT_data.unpublishedEvent,
          dashboardEvents: state.YT_data.dashboardEvents,
          defaultBroadcast: state.YT_data.defaultBroadcast,
          defaultLiveStream: payload.defaultLiveStream,
          broadcast: state.YT_data.broadcast,
          liveStream: payload.liveStream,
        },
      };
    case GET_LIVE_STREAM_BY_ID_FAIL:
      return {
        ...state,
        YT_data: {
          login: state.YT_data.login,
          broadcasts: [],
          liveStreams: [],
          unpublishedEvent: [],
          dashboardEvents: [],
          defaultBroadcast: {},
          defaultLiveStream: {},
          broadcast: {},
          liveStream: {},
        },
      };
    case YT_BROADCAST_ANALYTICS:
      return {
        ...state,
        analytics: payload.analytics,
      };
    case YT_ANALYTICS_LOADING:
      return {
        ...state,
        loadingYTAnalytics: true,
      };
    case YT_ANALYTICS_STOP:
      return {
        ...state,
        loadingYTAnalytics: false,
      };
    case YT_VIDEO_ANALYTICS:
      return {
        ...state,
        videoanalytics: payload.videoanalytics,
      };
    default:
      return state;
  }
}
