export const unitConstants = {
  GET_UNITS_REQUEST: 'GET_UNITS_REQUEST',
  GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
  GET_SFLRT_DETAILS_SUCCESS: 'GET_SFLRT_DETAILS_SUCCESS',
  GET_SFSLA_DETAILS_SUCCESS: 'GET_SFSLA_DETAILS_SUCCESS',
  SELECTED_UNIT: 'SELECTED_UNIT',
  EDIT_SELECTED_UNIT: 'EDIT_SELECTED_UNIT',
  CANCEL_EDIT_SELECTED_UNIT: 'CANCEL_EDIT_SELECTED_UNIT',
  UPDATE_UNIT: 'UPDATE_UNIT',
  GET_VIDEO_DETAILS: 'GET_VIDEO_DETAILS',
  VIDEO_DETAILS_REQUEST: 'VIDEO_DETAILS_REQUEST',
  GET_STATUS_SUCCESS: 'GET_STATUS_SUCCESS',
  GET_INTERFACE_SUCCESS: 'GET_INTERFACE_SUCCESS',
  GET_INTERFACE_REQUEST: 'GET_INTERFACE_REQUEST',
  GET_INTERFACE_FAILURE: 'GET_INTERFACE_FAILURE',
  GET_INTERFACE_DETAILS_SUCCESS: 'GET_INTERFACE_DETAILS_SUCCESS',
  GET_INTERFACE_DETAILS_REQUEST: 'GET_INTERFACE_DETAILS_REQUEST',
  GET_INTERFACE_DETAILS_FAILURE: 'GET_INTERFACE_DETAILS_FAILURE',
  SF_LOADING_START: 'SF_LOADING_START',
  SF_LOADING_STOP: 'SF_LOADING_STOP',
  GET_UNIT_SETTINGS_SUCCESS: 'GET_UNIT_SETTINGS_SUCCESS',
  GET_UNIT_INTERFACE: 'GET_UNIT_INTERFACE',
  REBOOT_UNIT: 'REBOOT_UNIT',
  SET_UNIT_LOADER_STATE: 'SET_UNIT_LOADER_STATE',
  UPDATE_UNIT_FROM_UNITS: 'UPDATE_UNIT_FROM_UNITS',
  SET_SELECTOR_VALUE: 'SET_SELECTOR_VALUE',
};
