import {
  deleteAWSDestination,
  createAWSDestination,
  getAWSDestinationById,
  nSetStreamtoolDest,
} from '../../api/aws.api';
import {
  getLUCDestinations,
  deleteLUCdestination,
  // createLUCDest,
  setLucDes,
  _createDestination,
} from '../../api/luc.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();
const { REACT_APP_ApplicationId } = process.env;

export const savedDestinationsService = {
  getLucDest,
  delLucDest,
  delAWSDest,
  getAwsDest,
  createAwsDest,
  createLucDest,
  setLucDest,
  setStreamtoolDest,
};
async function getLucDest() {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await getLUCDestinations(lucHeaders);

    return res.data.lenght > 0
      ? res.data.map((destination) => ({
          id: destination?.id,
          name: destination?.name,
          inventories: destination?.inventories,
          type: destination?.type,
          streaming_destination: {
            external_id: destination?.externalId,
            streaming_provider: destination?.streamingProvider,
            streaming_destination_outputs: [
              {
                streaming_profile:
                  destination?.streamingDestinationOverrides[0]
                    ?.streamingProfile,
                stream_name:
                  destination?.streamingDestinationOverrides[0]?.streamId,
                min_res_override:
                  destination?.streamingDestinationOverrides[0]
                    ?.minResolutionOverride,
                max_res_override:
                  destination?.streamingDestinationOverrides[0]
                    ?.maxResolutionOverride,
              },
            ],
            streaming_ingest: {
              username: destination?.streamingIngest?.primaryUsername,
              password: destination?.streamingIngest?.primaryPassword,
              primary_url: destination?.streamingIngest?.primaryUrl,
              secondary_url: destination?.streamingIngest?.secondaryUrl,
            },
          },
        }))
      : [];
  } catch (err) {
    return Promise.reject(err);
  }
}
async function getAwsDest(id) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  if (!id) {
    return;
  }
  try {
    const res = await getAWSDestinationById(awsHeaders, id);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
async function createAwsDest(data) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  if (!data) {
    return;
  }
  try {
    await createAWSDestination(awsHeaders, data);
    return { aws_Dest_Created: true };
  } catch (err) {
    return Promise.reject(err);
  }
}
async function delLucDest(destId) {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  if (!destId) {
    return;
  }
  try {
    const res = await deleteLUCdestination(lucHeaders, destId);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function delAWSDest(destId) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  if (!destId) {
    return;
  }
  try {
    const res = await deleteAWSDestination(awsHeaders, destId);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function createLucDest(data) {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  if (!data) {
    return;
  }
  try {
    // const res = await createLUCDest(data, lucHeaders);
    const res = await _createDestination(lucHeaders, data);
    return res.data.data.destination;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function setLucDest(payload, unitId) {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  if (!payload && !unitId) {
    return;
  }
  try {
    const res = await setLucDes(payload, unitId, lucHeaders);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function setStreamtoolDest(payload) {
  const awsHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'x-user-uuid': `${cookies.get('user_session')}`,
  };

  try {
    const res = await nSetStreamtoolDest(payload, awsHeaders);
    return res?.data?.data?.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
