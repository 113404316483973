import { boxCastConstants, commonConstants } from '../constants';
import { boxCastService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { history } from '../history';
import { commonAction } from './common.actions';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const boxCastAction = {
  getDetails,
  makePayload,
  boxCastToken,
};
/**
 * @name boxCastToken
 * @params code,redirect,granttype,bossID
 * @descriptiton gets a switchboard token
 **/
function boxCastToken(code, redirect, grantType, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getBoxTok = await boxCastService.getBoxCastToken(
        code,
        redirect,
        grantType,
      );
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);

      cookies.set('boxcast_access_token', getBoxTok.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(success(getBoxTok));
      history.push('/dashboard/boxcast/' + bossId);
      dispatch(getDetails(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
      history.push('/dashboard/boxcastlogin/' + bossId);
    }
  };
  function request() {
    return { type: boxCastConstants.GET_TOKEN_REQ };
  }

  function success(authData) {
    return { type: boxCastConstants.GET_TOKEN, authData };
  }

  function failure(error) {
    return { type: boxCastConstants.GET_TOKEN_FAIL, error };
  }
}
/**
 * @name getDetails
 * @params bossID
 * @descriptiton get Boxcast profile
 **/
function getDetails(bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const det = await boxCastService.userDetails();
      dispatch(success(det));
      dispatch(getRtmp(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
      history.push('/dashboard/boxcastlogin/' + bossId);
    }
  };
  function request() {
    return { type: boxCastConstants.GET_USER_REQ };
  }

  function success(userData) {
    return { type: boxCastConstants.GET_USER, userData };
  }

  function failure(error) {
    return { type: boxCastConstants.GET_USER_FAIL, error };
  }
}
/**
 * @name getRtmp
 * @params bossID
 * @descriptiton get RTMP Ingest points
 **/
function getRtmp(bossId) {
  return async (dispatch) => {
    try {
      const rtmp = await boxCastService.getRtmpUrl();
      if (rtmp.length) {
        dispatch(success(rtmp));
      } else {
        dispatch(failure());
      }
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
      history.push('/dashboard/boxcastlogin/' + bossId);
    }
  };

  function success(ingData) {
    return { type: boxCastConstants.GET_RTMP, ingData };
  }

  function failure() {
    return { type: boxCastConstants.GET_RTMP_FAIL };
  }
}
/**
 * @name makePayload
 * @params values,bossID
 * @descriptiton make  aws destination
 **/
function makePayload(values, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile.name,
        primary_url: values.source.server_url,
        secondary_url: '',
        stream_name: values.source.stream_key,
        username: '',
        password: '',
        id: guid(),
        streaming_provider: 'Boxcast',
        overrideResolution: values.resolutionBox
          ? values.overrideResolution.value
          : '',
        overrideFramerate: values.frameRateBox
          ? values.overrideFrame.value
          : '',
        overrideBitrate: values.bitRateBox ? values.overrideBitrate : '',
        audio_bitrate_override: values.audioBitRateBox ? values.audioBitRate?.value : null
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
}
/**
 * @name guid
 * @params none
 * @descriptiton creates 16 char unique string
 **/
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
