import React from 'react';
import { Button } from 'antd';
import usePreventReload from '../../hooks/usePreventReload';
import { useDispatch } from 'react-redux';
import streamToolsQueue from '../../helpers/queue';

const StreamToolsGoLiveButton = ({
  streamToolsUnitsList,
  unit,
  goLiveDisableForStreamtools,
  stopUnit,
  goLive,
  t,
  handleSRTCondition,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setShowExitPrompt] = usePreventReload(false);
  const dispatch = useDispatch();
  const StreamtoolsGoLive = async (unit) => {
    setShowExitPrompt(true);
    await dispatch(streamToolsQueue(goLive, [unit, handleSRTCondition]));
    setShowExitPrompt(false);
  };
  return (
    <Button
      loading={streamToolsUnitsList?.[unit.BOSSID]?.progress > 0}
      className={
        streamToolsUnitsList?.[unit.BOSSID]?.isStreamtoolsStreaming
          ? 'btn btn-danger'
          : 'btn btn-primary'
      }
      disabled={
        streamToolsUnitsList?.[unit.BOSSID]?.isStreamtoolsStreaming
          ? false
          : goLiveDisableForStreamtools
      } //no disability if streaming started
      type='button'
      size='large'
      style={{
        color: '#fff',
        backgroundColor: streamToolsUnitsList?.[unit.BOSSID]
          ?.isStreamtoolsStreaming
          ? '#dc3545'
          : '',
      }}
      onClick={() => {
        streamToolsUnitsList?.[unit.BOSSID]?.isStreamtoolsStreaming
          ? stopUnit(unit.BOSSID)
          : StreamtoolsGoLive(unit);
      }}
    >
      {
        // streamToolsUnitsList?.[unit.BOSSID]?.dnd
        // ? streamToolsUnitsList?.[unit.BOSSID]?.progressInfo
        // :
        streamToolsUnitsList?.[unit.BOSSID]?.isStreamtoolsStreaming
          ? t('STOP')
          : t('GOLIVE')
      }
    </Button>
  );
};

export default StreamToolsGoLiveButton;
