import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { VideoPlayer } from '../../../VideoPlayer/VideoPlayer';
import { YTAction } from '../../../../actions';
import { errorBlock } from '../../../../api/errorBlock';
import { getUnitWithStatus } from '../../../../selectors/unit_selectors';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Tooltip } from 'antd';
import i18n from 'i18next';

import { css } from '@emotion/core';
const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
  padding-top: 35%;
  display: flex;
  justify-content: center;
`;

class YouTubeStreamToolVideo extends Component {
  state = {
    playerLoading: true,
    videoEvent: [],
  };

  async componentDidMount() {
    const { broadcast } = this.props;
    this.playerLoadingTimeout();
    this.setState({ videoEvent: broadcast });
    this.intervalT2 = setInterval(() => {
      this.getPostStatus();
    }, 5000);
  }

  // eslint-disable-next-line no-unused-vars
  // async componentDidUpdate(prevProps, prevState) {
  //   const { broadcast } = this.props;
  //   if (
  //     prevProps.broadcast?.status?.lifeCycleStatus !==
  //     broadcast?.status?.lifeCycleStatus
  //   ) {
  //     if (
  //       !broadcast?.snippet?.isDefaultBroadcast &&
  //       (broadcast?.status?.lifeCycleStatus.toLowerCase() !== 'live' ||
  //         broadcast?.status?.lifeCycleStatus.toLowerCase() !== 'deleted' ||
  //         broadcast?.status?.lifeCycleStatus.toLowerCase() !== 'completed')
  //     ) {
  //       this.getTranscoderState();
  //     } else {
  //       clearInterval(this.intervalT2);
  //     }
  //   }
  // }
  getPostStatus = async () => {
    const { match, getYTEventDetails, selectedDestination } = this.props;
    try {
      const event = await getYTEventDetails(
        selectedDestination.streaming_destination.external_id,
        match,
      );
      this.setState({
        videoEvent: event,
      });
    } catch (e) {
      clearInterval(this.intervalT2);
      errorBlock(e);
    }
  };
  playerLoadingTimeout = () => {
    setTimeout(() => {
      this.setState({
        playerLoading: false,
      });
    }, 5000);
  };
  // getTranscoderState = () => {
  //   const { match, selectedDestination } = this.props;
  //   try {
  //     if (
  //       selectedDestination?.streaming_destination?.streaming_provider ===
  //       'youtube'
  //     ) {
  //       this.intervalT2 = setInterval(() => {
  //         this.getPostStatus();
  //       }, 5000);
  //     }
  //   } catch (e) {
  //     errorBlock(e, match.params.bossId);
  //   }
  // };
  componentWillUnmount() {
    clearInterval(this.intervalT2);
  }

  render() {
    const { playerLoading, videoEvent } = this.state;
    return (
      <div className='videobox'>
        {playerLoading ? (
          <PropagateLoader
            css={override}
            size={10}
            color={'#000000'}
            loading={playerLoading}
          />
        ) : (
          <div style={{ position: 'relative' }}>
            <Tooltip
              placement='top'
              title={
                videoEvent?.status?.lifeCycleStatus === 'live' ||
                videoEvent?.status?.lifeCycleStatus === 'testing' ||
                videoEvent?.status?.lifeCycleStatus === 'complete'
                  ? null
                  : i18n.t('PREVIEW_NOT_VISIBLE')
              }
            >
              <div className='preview-overlay'>
                <div
                  style={{
                    pointerEvents:
                      videoEvent?.status?.lifeCycleStatus === 'live' ||
                      videoEvent?.status?.lifeCycleStatus === 'testing' ||
                      videoEvent?.status?.lifeCycleStatus === 'complete'
                        ? 'all'
                        : 'none',
                  }}
                >
                  <VideoPlayer
                    videoUrl={
                      videoEvent?.contentDetails?.monitorStream?.embedHtml
                    }
                  />
                </div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unit: getUnitWithStatus(state),
  };
};
const mapDispatchToProps = {
  getYTEventDetails: YTAction.getYTStreamToolEventDetails,
};
const ConnectedYouTubeVideo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(YouTubeStreamToolVideo));
export { ConnectedYouTubeVideo as YouTubeStreamToolVideo };
