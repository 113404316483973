import i18n from '../i18n';
import { paymentActions } from './payment.actions';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const lngActions = {
  lngChange,
};

function lngChange(lng, currency, currency_symbol) {
  return (dispatch, getState) => {
    dispatch(request(lng));
    i18n.changeLanguage(getState().userLngPreferences.language);
    dispatch(paymentActions.getCurrency(currency));
    let { language } = getState().userLngPreferences;

    cookies.set('language', language, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      secure: true,
    });
    cookies.set('currency', currency, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      secure: true,
    });
    cookies.set('currency_symbol', currency_symbol, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      secure: true,
    });
  };
  function request(lng) {
    return { type: 'CHANGE_LANGUAGE', payload: lng };
  }
}
