import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { lrtActions } from '../../actions';
import { Form } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Cookies from 'universal-cookie';
import { notify } from '../../components/CommonNotification/CommonNotification';
const cookies = new Cookies();

class Dropdown extends Component {
  state = {
    selectedChannel: this.props.channelServiceDestination.selected_channel,
  };

  onChangeHandler = async (e, { value }) => {
    const { unit, changeZone, t } = this.props;
    const { streamtoolSubscribed, onStreamToolsZoneUpdate } = this.props;
    let selectedZone;
    cookies.set(`selected_channel_${unit.BOSSID}`, value, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      secure: true,
    });
    switch (value) {
      case 'Cloud_Service_EU':
        selectedZone = 'EU_Ireland';
        break;
      case 'Cloud_Service_APAC':
        selectedZone = 'AP_Singapore';
        break;
      case 'Cloud_Service_Austra':
        selectedZone = 'AU_Sydney';
        break;
      case 'Cloud_Service_EMEA':
        selectedZone = 'EU_Ireland';
        break;
      case 'Cloud_Service_US_SJ':
        selectedZone = 'US_West_SanJose';
        break;
      case 'Cloud-Service':
        selectedZone = 'US_East_NorthVirginia';
        break;
      case 'Galmo_Instance2':
        selectedZone = 'Galmo_Instance2';
        break;
      case 'Solo_MMH2_Instance6':
        selectedZone = 'Solo_MMH2_Instance6';
        break;
      case 'Auto_mahendra@intellimedianetworks.net':
        selectedZone = 'Auto_mahendra@intellimedianetworks.net';
        break;
      default:
        selectedZone = value;
    }

    if (value) {
      await changeZone(unit.BOSSID, value);
      if (streamtoolSubscribed) {
        await onStreamToolsZoneUpdate(e, { value });
      }
      notify('success', `${selectedZone} ${t('ZONEUPDATED')}`);
    }
  };

  componentDidMount() {
    const { channelServiceDestination, unit } = this.props;
    if (channelServiceDestination.selected_channel) {
      cookies.set(
        `selected_channel_${unit.BOSSID}`,
        channelServiceDestination.selected_channel,
        { path: '/', maxAge: 60 * 60 * 24 * 365, secure: true },
      );
    }
  }

  render() {
    const { unit, channels, isDisabled } = this.props;
    const { isStreamToolActive, dnd, isStreamtoolsStreaming, isUpdatingZone } =
      this.props;
    return (
      <div>
        <Form.Dropdown
          placeholder='None Selected'
          fluid
          selection
          onChange={this.onChangeHandler}
          options={channels.map((option) => ({
            key: option.value,
            text: <CustomOption option={option.text} />,
            value: option.value,
          }))}
          // options={channels}
          value={cookies.get(`selected_channel_${this.props.unit.BOSSID}`)}
          loading={
            // isStreamToolActive &&
            isUpdatingZone
          }
          disabled={
            isDisabled ||
            unit?.status === 'streaming' ||
            unit?.status === 'offline' ||
            isUpdatingZone ||
            dnd === true ||
            (isStreamToolActive && isStreamtoolsStreaming)
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  changeZone: lrtActions.changeZone,
};

const ConnectedDropdown = connect(
  null,
  mapDispatchToProps,
)(withTranslation()(Dropdown));
export { ConnectedDropdown as Dropdown };

const CustomOption = ({ option }) => {
  return <div title={option}>{option}</div>;
};
