import { createSelector } from 'reselect';
const fbAnalytics = (state) => state.fb.analytics;

export const fbAnalyticsCount = createSelector(fbAnalytics, (analytics) => {
  let reactions = {
    like: 0,
    haha: 0,
    love: 0,
    sad: 0,
    angry: 0,
    wow: 0,
    care: 0,
    views: 0,
    comments: 0,
  };
  if (analytics.reactions.length || analytics.views || analytics.comments) {
    // eslint-disable-next-line array-callback-return
    analytics.reactions.filter((value) => {
      if (value.type === 'LIKE') {
        reactions.like++;
      } else if (value.type === 'HAHA') {
        reactions.haha++;
      } else if (value.type === 'LOVE') {
        reactions.love++;
      } else if (value.type === 'SAD') {
        reactions.sad++;
      } else if (value.type === 'ANGRY') {
        reactions.angry++;
      } else if (value.type === 'WOW') {
        reactions.wow++;
      } else if (value.type === 'CARE') {
        reactions.care++;
      }
    });
    !!analytics.comments.length
      ? (reactions.comments = analytics.comments.length)
      : (reactions.comments = 0);
    !!analytics.views
      ? (reactions.views = analytics.views)
      : (reactions.views = 0);
    return { ...reactions };
  } else {
    return { ...reactions };
  }
});
