import React from 'react';
import { EditUnitName } from './EditUnitName';
import { Skeleton, Progress } from 'antd';
import StreamToolsGoLiveButton from './StreamToolsGoLiveButton';
import NormalFlowGoLiveButton from './NormalFlowGoLiveButton';
import ChargingIcon from '../../assets/svg/bolt.svg';

export const UnitHeaderView = ({
  t,
  unit,
  isEdit,
  renameUnit,
  cancelRenameUnit,
  changedelayMode,
  stopStream,
  showLoader,
  unitsettingsHandler,
  showLowDelay,
  removeUnit,
  handleStartStream,
  goLiveDisabled,
  goLive,
  streamToolsUnitsList,
  goLiveDisableForStreamtools,
  stopUnit,
  streamToolData,
  handleSRTCondition,
}) => {
  return (
    <>
      {/* {streamToolData?.isStreamToolActive && streamToolData?.progress > 0 && (
        <div style={{ backgroundColor: '#f3f3f3', position: 'relative' }}>
          <Progress
            percent={streamToolData?.progress * 16.66 + 1}
            showInfo={false}
            style={{
              position: 'absolute',
              top: '-10px',
              left: 0,
              padding: '0 21px',
            }}
            strokeWidth={3}
          />
        </div>
      )} */}
      <div className='titlebar px-4 d-flex flex-xl-row flex-column justify-content-sm-between'>
        <div className='titlebarLeft d-flex flex-column justify-content-md-center '>
          <div className='unitname flex-row d-flex align-items-center'>
            {isEdit ? (
              <EditUnitName
                unit={unit}
                renameUnit={renameUnit}
                cancelRenameUnit={cancelRenameUnit}
                removeUnit={removeUnit}
              />
            ) : (
              <span className='editUnitnName' title={unit.name}>
                {unit.name}
              </span>
            )}
            <span className='statusTxt'>
              {unit.status === 'streaming' ? (
                <span className='onlineTxt'>{t('STREAMINGSTATUS')}</span>
              ) : unit.status === 'connected' ? (
                <span className='onlineTxt'>{t('ONLINESTATUS')}</span>
              ) : (
                <span className='offlineTxt'>{t('OFFLINESTATUS')}</span>
              )}
            </span>
            <span className='apnSettings'>
              <span
                className='btn btn-primary btn-sm'
                onClick={() => unitsettingsHandler(unit)}
              >
                {t('APNSETTINGS')}
              </span>
            </span>
          </div>

          <div className='smalltxt mb-1'>
            {unit.SN} - {unit.sw_version}
          </div>
          <div className='statusAll'>
            <div className={unit.signalQuality} title={unit.modemname}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.48 21.63">
                                <path d="M30.42,6.32l0-.05A21.41,21.41,0,0,0,.1,6.27a.35.35,0,0,0-.1.24.37.37,0,0,0,.1.24L2.31,9a.35.35,0,0,0,.24.1A.33.33,0,0,0,2.78,9,17.59,17.59,0,0,1,27.69,9a.34.34,0,0,0,.48,0l2.21-2.22a.33.33,0,0,0,.1-.23A.33.33,0,0,0,30.42,6.32Z" />
                                <path d="M15.24,5.86a15.48,15.48,0,0,0-11,4.56.33.33,0,0,0,0,.47L6.45,13.1a.35.35,0,0,0,.24.1.33.33,0,0,0,.23-.1,11.76,11.76,0,0,1,16.64,0,.33.33,0,0,0,.23.1.35.35,0,0,0,.24-.1l2.21-2.21a.34.34,0,0,0,.09-.24.33.33,0,0,0-.09-.23A15.48,15.48,0,0,0,15.24,5.86Z" />
                                <path d="M15.24,11.71a9.63,9.63,0,0,0-6.86,2.85.33.33,0,0,0-.1.23.35.35,0,0,0,.1.24l2.21,2.21a.34.34,0,0,0,.24.09.33.33,0,0,0,.23-.09,5.89,5.89,0,0,1,8.36,0,.33.33,0,0,0,.23.09.34.34,0,0,0,.24-.09L22.1,15a.33.33,0,0,0,0-.47A9.63,9.63,0,0,0,15.24,11.71Z" />
                                <path d="M15.24,17.92a3.36,3.36,0,0,0-2.39,1,.33.33,0,0,0,0,.47L15,21.54a.37.37,0,0,0,.48,0l2.15-2.16a.33.33,0,0,0,0-.47A3.36,3.36,0,0,0,15.24,17.92Z" />
                            </svg> */}
            </div>
            <div className='battery-status'>
              <span className='statuslabel'>
                <b className='mLabel hide'>{t('BATTERY')}: </b>
                {unit.showbattery ? (
                  <div className={`progress ${unit.batterystatuscharging}`}>
                    <div className='prog-wrap'>
                      <div
                        className='progress-bar progress-bar-v'
                        role='progressbar'
                        // eslint-disable-next-line jsx-a11y/aria-proptypes
                        aria-valuenow="{{selectedunit.batterystatusvalue | ifEmpty:'N/A'}}"
                        aria-valuemin='0'
                        aria-valuemax='100'
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr auto',
                          alignItems: 'center',
                          width: unit.batterystatuswidth,
                          backgroundColor: unit.batterystatuscolor,
                          color: '#000',
                        }}
                      />
                      <div>
                        {!unit.battery.discharging && (
                          <img
                            src={ChargingIcon}
                            alt='Charging'
                            style={{
                              position: 'absolute',
                              right: '0px',
                              height: '8px',
                              width: '10px',
                              top: '2px',
                            }}
                          />
                        )}
                        <span
                          style={{
                            position: 'absolute',
                            right: !unit.battery.discharging ? '5px' : '0px',
                            margin: '0 auto',
                            textAlign: 'center',
                            left: '0',
                            fontSize: '10px',
                            fontWeight: 700,
                            top: '6px',
                            color: '#000',
                          }}
                        >
                          {unit.batterystatusvalue}
                        </span>
                      </div>
                    </div>
                    <span className='sr-only'>{unit.batterystatus}</span>
                  </div>
                ) : (
                  <span className='natxt'></span>
                )}
              </span>
            </div>

            {/*<button*/}
            {/*  className='btn'*/}
            {/*  onClick={() => rebootUnit(unit.BOSSID)}*/}
            {/*  disabled={unit.status === 'offline'}*/}
            {/*>*/}
            {/*  Reboot*/}
            {/*</button>*/}
          </div>
          <div></div>
        </div>
        <div className='titlebarRight d-flex flex-column align-items-end justify-content-md-center'>
          <div className='status d-flex flex-row align-items-md-center'>
            {unit.delay && unit.status !== 'offline' && showLowDelay && (
              <div className='lowdelaymode d-flex flex-row mr-3 align-items-center'>
                <div className='lbltxt lrttxt' title='Low Delay Mode'>
                  {t('LOWDELAY')}:
                </div>
                <div className='valuetxt ml-2'>
                  <input
                    type='checkbox'
                    className='toggle'
                    id='lowDelaytoggle'
                    onChange={() => changedelayMode(unit)}
                    checked={unit.delay !== 5000}
                    disabled={unit.status === 'streaming'}
                  />
                  <label
                    htmlFor='lowDelaytoggle'
                    className='mb-0'
                    disabled={unit.status === 'streaming'}
                  >
                    {/* <span className={'{unit.delay}' == 100 ? 'on' : 'off'}>{'{unit.delay}' == 100 ? 'On' : 'Off'}</span> */}
                    <span className='on'>On</span>
                    <span className='off'>Off</span>
                  </label>
                </div>
              </div>
            )}

            <div>
              <div
                className='statusdiv'
                // style={{
                //   marginTop:
                //     streamToolData?.isStreamToolActive &&
                //     streamToolData?.progress > 0
                //       ? '11px'
                //       : '0',
                // }}
              >
                {streamToolsUnitsList?.[unit.BOSSID]?.isLoadingData ? (
                  <Skeleton
                    className='btn-skeleton'
                    avatar={{
                      shape: 'square',
                      size: 'large',
                      style: { color: '#fff' },
                    }}
                    title={false}
                    paragraph={false}
                    active
                  />
                ) : streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive ? (
                  <StreamToolsGoLiveButton
                    {...{
                      streamToolsUnitsList,
                      unit,
                      goLiveDisableForStreamtools,
                      stopUnit,
                      goLive,
                      t,
                      handleSRTCondition,
                    }}
                  />
                ) : (
                  <NormalFlowGoLiveButton
                    {...{
                      showLoader,
                      unit,
                      t,
                      stopStream,
                      goLiveDisabled,
                      handleStartStream,
                    }}
                  />
                )}
              </div>
              {streamToolData?.isStreamToolActive &&
                streamToolData?.progress > 0 && (
                  <div className='mt-1'>
                    <Progress
                      percent={
                        streamToolData.progress < 7
                          ? streamToolData.logProgress
                          : 100
                      }
                      showInfo={true}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
