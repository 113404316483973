import React from 'react';
import './Appversion.css';
import { commonConstants } from '../../constants';
export const AppVersion = ({ t }) => {
  return (
    <div className='ml-auto appVersion'>
      <span>
        {t('VERSION')}: {commonConstants.SOLO_VERSION}
      </span>
      <button
        type='button'
        className='shopLink'
        onClick={() => {
          window.open('https://get.gosolo.tv/privacy-policy', '_blank');
        }}
      >
        {t('PRIVACYPOLICY')}
      </button>
    </div>
  );
};
