import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './Facebook.css';
import { commonAction } from '../../../actions/common.actions';
import { Liveuloading } from '../../Liveuloading';
import { versionCheck } from '../../../selectors/destinations_selectors';
import { FacebookView } from './FacebookView';
import { destinationActions, FBAction } from '../../../actions';
import { OtherSpinner } from '../../Otherspinner';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import map from 'lodash/map';
import transform from 'lodash/transform';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { notify } from '../../CommonNotification/CommonNotification';
import i18n from 'i18next';
import { history } from '../../../history';
import { fbService } from '../../../services';
import profiles from '../../../data/Profile.json';
import staticProviders from '../../../data/providers';
import _ from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Facebook extends Component {
  state = {
    bitrateDisabled: false,
    locationData: [],
    friendsData: [],
    adTagsData: [],
    adGeoLocationData: [],
    postSponsorsData: [],
    isEdit: false,
    crossPosting: false,
    groupsByPage: [],
  };

  constructor() {
    super();
    this.onLocationChange = debounce(this.onLocationChange, 1000);
    this.friendsSearch = debounce(this.friendsSearch, 1000);
    this.AdGeoLocationSearch = debounce(this.AdGeoLocationSearch, 1000);
    this.postSponsors = debounce(this.postSponsors, 1000);
    this.adInterest = debounce(this.adInterest, 1000);
  }

  async componentDidMount() {
    const {
      getUnitDetails,
      streamingProviders,
      match,
      fbGetPages,
      fbInitialState,
      fbUserDetails,
    } = this.props;
    try {
      if (!!!match.params.bossId) {
        notify('error', i18n.t('SELECTUNIT'));
        history.push('/dashboard/units');
      } else {
        // eslint-disable-next-line no-unused-vars
        const v1 =
          !!match.params.fbId && !!match.params.postId
            ? this.setState({ isEdit: true })
            : this.setState({ isEdit: false });
        fbInitialState();
        await getUnitDetails(match.params.bossId);
        await fbGetPages(match);
        await streamingProviders();
        await fbUserDetails();
      }
    } catch (e) {
      //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
    }
  }

  onLocationChange = async (value) => {
    try {
      const { getLocation, match } = this.props;
      const data = await getLocation(value, match);
      if (data.length) {
        this.setState({
          locationData: data,
        });
      }
    } catch (e) {
      this.setState({
        locationData: [],
      });
      notify('error', i18n.t('PLTRYAGAIN'));
    }
  };
  postSponsors = async (value) => {
    try {
      const { postSponsor, match } = this.props;
      const data = await postSponsor(value, match);
      if (data.length) {
        this.setState({
          postSponsorsData: data,
        });
      }
    } catch (e) {
      this.setState({
        postSponsorsData: [],
      });
      notify('error', i18n.t('PLTRYAGAIN'));
    }
  };
  friendsSearch = async () => {
    try {
      const { getFriends, match } = this.props;
      const data = await getFriends(match);
      if (data.length) {
        this.setState({
          friendsData: data,
        });
      }
    } catch (e) {
      this.setState({
        friendsData: [],
      });
      notify('error', i18n.t('PLTRYAGAIN'));
    }
  };
  checkCrossPosting = async (value) => {
    const { checkCrossPostPage, match } = this.props;
    try {
      await checkCrossPostPage(value, match);
      await this.getgroupsByPage(value);
    } catch (e) {
      // errorBlock(e,match); //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
    }
  };
  getgroupsByPage = async (value) => {
    const { fbGetGroupByPage, match } = this.props;
    try {
      this.setState({
        groupsByPage: await fbGetGroupByPage(value, match),
      });
    } catch (e) {
      // errorBlock(e,match); //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
    }
  };
  crosspostWhitelistPages = async (pageId, pageToken) => {
    const listOfPage = await fbService.fbGetCrossPage(pageId, pageToken);

    return listOfPage;
  };

  AdGeoLocationSearch = async (value) => {
    try {
      const { getAdGeoLocation, match } = this.props;
      const data = await getAdGeoLocation(value, match);
      if (data.length) {
        this.setState({
          adGeoLocationData: data,
        });
      }
    } catch (e) {
      this.setState({
        adGeoLocationData: [],
      });
      notify('error', i18n.t('PLTRYAGAIN'));
    }
  };
  adInterest = async (value) => {
    try {
      const { getAdInterest, match } = this.props;
      const data = await getAdInterest(value, match);
      if (data.length) {
        this.setState({
          adTagsData: data,
        });
      }
    } catch (e) {
      this.setState({
        adTagsData: [],
      });
      notify('error', i18n.t('PLTRYAGAIN'));
    }
  };

  profileOptions = () => {
    const { providers } = this.props;
    const lucProviders = providers.length > 0 ? providers : staticProviders;
    if (lucProviders) {
      let squareProfiles = false;
      const swVersion = this.props.unitDetails.sw_version;
      let options = lucProviders.filter(
        (prov) => prov.name.toLowerCase() === 'facebook',
      );
      if (!!swVersion) {
        if (
          (swVersion.substr(0, 1) === 'v' &&
            parseFloat(swVersion.substr(1, 3)) >= 7.0) ||
          parseFloat(swVersion.substr(0, 3)) >= 7.0
        ) {
          squareProfiles = true;
        }
      }
      if (options.length) {
        if (!squareProfiles) {
          remove(options[0].streaming_profiles, function (v) {
            return (
              v.name === '720x720 1:1 aspect ratio' ||
              v.name === '1080x1080 1:1 aspect ratio'
            );
          });
        }
        let oldOptions = options;
        let profileResult = null;
        let result = null;
        try {
          profileResult = profiles.filter((obj1) =>
            options[0].streaming_profiles.some(
              (obj2) => obj1.value === obj2.name,
            ),
          );

          // To find profile which is in api(options[0].streaming_profiles) but not in profile.json(profiles)
          result = options[0].streaming_profiles.filter(
            (profiles1) =>
              !profileResult.some(
                (profiles2) => profiles1.name === profiles2.value,
              ),
          );
          result.forEach((item) => {
            item.label = 'Others';
            item.value = item.name;
            profileResult.push(item);
          });
          let updatedResult = profileResult.map((res) => {
            return {
              type: res.label,
              label: res.value,
              value: res.value,
              name: res.value,
            };
          });
          const grouped = _.groupBy(updatedResult, (res) => res.type);
          const data = Object.keys(grouped).map((label) => ({
            label,
            options: grouped[label],
          }));
          return data;
        } catch (e) {
          options = oldOptions;
        }
      }
    }
  };
  crossPostingEnable = (value) => {
    this.setState({
      crossPosting: value,
    });
  };
  submitHandler = (values) => {
    const { match, updateLiveVideoPost, createLiveVideo } = this.props;
    const facebookForm = new FormData();
    facebookForm.append(
      'privacy',
      JSON.stringify({
        value:
          values.streamLocation.type === 'me'
            ? values.privacy.value
            : 'EVERYONE',
      }),
    );
    facebookForm.append('description', values.description);
    facebookForm.append('title', values.destination);
    facebookForm.append(
      'status',
      values.scheduleLive ? '' : values.publishMode.value,
    );

    !!values.scheduledTime &&
      values?.scheduleImagePath &&
      facebookForm.append(
        'event_params',
        JSON.stringify({
          start_time: values.scheduledTime
            ? Number(values.scheduledTime.toString().slice(0, 10))
            : '',
          cover: values?.scheduleImagePath,
        }),
      );

    !!values.scheduledTime &&
      !values?.scheduleImagePath &&
      facebookForm.append(
        'event_params',
        JSON.stringify({
          start_time: values.scheduledTime
            ? Number(values.scheduledTime.toString().slice(0, 10))
            : '',
        }),
      );

    // if (!!values.scheduleImage) {
    //   facebookForm.append(
    //     'schedule_custom_profile_image',
    //     values.scheduleImage,
    //   );
    // }
    const adInterestTags = map(values.adInterest, 'key');
    facebookForm.append('content_tags', adInterestTags);

    const people = map(values.friends, 'id');
    let update = {};
    let tempObj = {};
    let fbCrossPost = [];
    if (values.streamLocation.type === 'page') {
      // eslint-disable-next-line array-callback-return
      values.crossPostPages.filter((v) => {
        if (v.type === 'enable_crossposting') {
          fbCrossPost.push({
            page_id: v.id,
            action: 'enable_crossposting',
          });
        } else {
          fbCrossPost.push({
            page_id: v.id,
            action: 'enable_crossposting_and_create_post',
          });
        }
      });
      tempObj = {
        crossposting_actions: fbCrossPost.length ? fbCrossPost : null,
        embeddable: !values.prohibitEmbedding,
        content_tags: adInterestTags,
        tags: people,
        sponsor_id: !!values.postSponsor.value ? values.postSponsor.value : {},
        targeting: {
          age_min: !!values.audienceAge.value ? values.audienceAge.value : {},
          excluded_countries: values.excludedGeoLocation.countries,
          excluded_regions: values.excludedGeoLocation.regions,
          excluded_cities: values.excludedGeoLocation.cities,
          geo_locations: {
            countries: values.includedGeoLocation.countries,
            regions: values.includedGeoLocation.regions,
            cities: values.includedGeoLocation.cities,
          },
        },
      };
      let groupString = '';
      if (values.groups) {
        // eslint-disable-next-line array-callback-return
        values.groups.map((g, i) => {
          groupString =
            groupString + g + (i + 1 === values.groups.length ? '' : ',');
        });
        tempObj.cross_share_to_group_ids = groupString;
      }
    } else {
      tempObj = {
        crossposting_actions: [],
        embeddable: !values.prohibitEmbedding,
        content_tags: adInterestTags,
        sponsor_id: !!values.postSponsor.value ? values.postSponsor.value : {},
        tags: people,
      };
    }
    function cleanObj(el) {
      function internalClean(el) {
        return transform(el, function (result, value, key) {
          let isCollection = isObject(value);
          let cleaned = isCollection ? internalClean(value) : value;

          if (isCollection && isEmpty(cleaned)) {
            return;
          }

          isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
        });
      }

      return isObject(el) ? internalClean(el) : el;
    }
    update = cleanObj(tempObj);
    let updateObj;
    if (this.state.isEdit) {
      let tempUpdateObj = {
        title: values.destination,
        description: values.description,
        privacy: JSON.stringify({
          value:
            values.streamLocation.type === 'me'
              ? values.privacy.value
              : 'EVERYONE',
        }),
        status: values.scheduleLive ? '' : values.publishMode.value,
        embeddable: !values.prohibitEmbedding,
        content_tags: adInterestTags,
        sponsor_id: !!values.postSponsor.value ? values.postSponsor.value : {},
        tags: people,
        planned_start_time: values.scheduledTime
          ? Number((values.scheduledTime / 1000).toFixed())
          : '',
        crossposting_actions:
          values.streamLocation.type === 'page'
            ? fbCrossPost.length
              ? fbCrossPost
              : []
            : [],
        audio_bitrate_override: values.audioBitRateBox
          ? values.audioBitRateBox
            ? values.audioBitRate.value
            : ''
          : null,
      };
      updateObj = cleanObj(tempUpdateObj);
    }
    let d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    cookies.set(`FB_${match.params.bossId}`, values.streamLocation, {
      path: '/',
      expires: d,
      secure: true,
    });
    // eslint-disable-next-line no-unused-vars
    const t1 = this.state.isEdit
      ? updateLiveVideoPost(match.params.postId, updateObj, values, match)
      : createLiveVideo(update, values, facebookForm, match);
  };

  render() {
    const {
      t,
      match,
      history,
      showLoader,
      getLocation,
      fbLoading,
      otherData,
      unitLoading,
      providersLoading,
      fbLogout,
      edit,
      user_details,
      fbImageUpload,
      unit,
      streamToolsUnitsList
    } = this.props;
    const {
      bitrateDisabled,
      locationData,
      friendsData,
      adTagsData,
      adGeoLocationData,
      postSponsorsData,
      isEdit,
      groupsByPage,
    } = this.state;

    const isStreamToolActive = streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive

    return (
      <React.Fragment>
        {!providersLoading && !fbLoading && !unitLoading ? (
          <FacebookView
            t={t}
            match={match}
            history={history}
            profileOptions={this.profileOptions()}
            submitHandler={this.submitHandler}
            defaultOption={otherData.ovpData.defaultOption}
            currentUnitData={otherData.ovpData.currentUnit}
            streamLocationOptions={otherData.whereToStream}
            crossPostingData={otherData.crossPostPages}
            checkCrossPosting={this.checkCrossPosting}
            getLocation={getLocation}
            bitrateDisabled={bitrateDisabled}
            onLocationChange={this.onLocationChange}
            adGeoLocationData={adGeoLocationData}
            adGeoLocationSearch={this.AdGeoLocationSearch}
            postSponsorData={postSponsorsData}
            postSponsors={this.postSponsors}
            friendsSearch={this.friendsSearch}
            friendsData={friendsData}
            locationData={locationData}
            adTags={this.adInterest}
            adData={adTagsData}
            fbLogout={fbLogout}
            crossPostError={otherData.crossPostError}
            isEdit={isEdit}
            editData={edit}
            crossPostingEnable={this.crossPostingEnable}
            userDetails={user_details}
            groupsByPage={groupsByPage}
            getgroupsByPage={this.getgroupsByPage}
            fbImageUpload={fbImageUpload}
            isStreamToolActive={isStreamToolActive}
          />
        ) : (
          <Liveuloading />
        )}
        {showLoader && (
          <React.Fragment>
            <OtherSpinner showSpinner={showLoader} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { providers } = state.editUnit.editunit;
  const { fbLoading, fb_data, otherData, edit, user_details } = state.fb;
  const { showLoader, providersLoading } = state.common;
  const { unitDetails, isLoading: unitLoading } = state.destination;
  const {unit} = state.unit
  const streamToolsUnitsList= state.streamTools

  return {
    bitrateDisabled: versionCheck(state),
    fbLoading,
    providers,
    showLoader,
    fb_data,
    otherData,
    unitDetails,
    unitLoading,
    providersLoading,
    edit,
    user_details,
    unit,
    streamToolsUnitsList,
  };
};

const mapDispatchToProps = {
  streamingProviders: commonAction.getStreamingProviders,
  getUnitDetails: destinationActions.getUnitDetails,
  getLocation: FBAction.getLocation,
  getFriends: FBAction.getFriends,
  getAdInterest: FBAction.getAdTags,
  fbGetPages: FBAction.fbGetPages,
  checkCrossPostPage: FBAction.checkCrossPostPage,
  getAdGeoLocation: FBAction.getAdLocation,
  postSponsor: FBAction.getPostSponsors,
  createLiveVideo: FBAction.createLiveVideo,
  fbLogout: FBAction.facebookLogout,
  updateLiveVideoPost: FBAction.fbUpdateLiveVideo,
  fbInitialState: FBAction.fbInitialState,
  fbUserDetails: FBAction.fbGetUserDetails,
  fbGetGroupByPage: FBAction.fbGetGroupByPage,
  fbImageUpload: FBAction.fbImageUpload,
};
const ConnectedFacebook = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Facebook));
export { ConnectedFacebook as Facebook };
