import { linkedinConstants } from '../constants';

const initialState = {
  linkedInLoading: true,
  linkedin: {
    auth: {},
    user: {},
    assets: {},
    create: {},
    postStatus: { status: '', id: '' },
    pages: [],
    error: false,
  },
  authIsLoading: true,
};
const {
  LI_PROFILE,
  LI_DATA_REQ,
  LI_ASSETS,
  LI_DATA_FAIL,
  LI_POST_STATUS,
  LI_TOKEN_FAIL,
  LI_TOKEN,
  LI_TOKEN_REQ,
  LI_CREATE_POST,
  LI_PAGE_DATA,
  LI_PAGE_FAIL,
} = linkedinConstants;

export function linkedin(state = initialState, payload) {
  switch (payload.type) {
    case LI_TOKEN_REQ:
      return { ...state, authIsLoading: true };
    case LI_TOKEN:
      return { ...state, linkedin: { ...state.linkedin, auth: payload.Data } };
    case LI_TOKEN_FAIL:
      return { ...state, authIsLoading: false };
    case LI_DATA_REQ:
      return { ...state, linkedInLoading: true };
    case LI_PROFILE:
      return { ...state, linkedin: { ...state.linkedin, user: payload.Data } };
    case LI_DATA_FAIL:
      return { ...state, ...initialState };
    case LI_ASSETS:
      return {
        ...state,
        linkedin: { ...state.linkedin, assets: payload.Data },
        linkedInLoading: false,
      };
    case LI_POST_STATUS:
      return {
        ...state,
        linkedin: { ...state.linkedin, postStatus: payload.Data },
      };
    case LI_CREATE_POST:
      return {
        ...state,
        linkedin: { ...state.linkedin, create: payload.Data },
      };
    case LI_PAGE_DATA:
      return { ...state, linkedin: { ...state.linkedin, pages: payload.Data } };
    case LI_PAGE_FAIL:
      return {
        ...state,
        linkedin: { ...state.linkedin, error: true },
        linkedInLoading: false,
      };

    default:
      return state;
  }
}
