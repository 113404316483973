import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SoloDevice from '../../assets/images/solo-device.png';
import SoloPlusdevice from '../../assets/images/solo-plusdevice.png';
import SoloProdevice from '../../assets/images/solo-pro-device.png';
import Mobile from '../../assets/images/mobile.png';
import './Unit.css';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unitActions } from '../../actions';
import { Popover } from 'antd';
import { FilterFilled, SortAscendingOutlined } from '@ant-design/icons';
export const Unit = ({
  index,
  unit,
  onSelectedUnit,
  onUnitSettings,
  modalHandler,
}) => {
  function modalDetails(id) {
    const destId = id;
    const modalBody = i18n.t('AREYOUSUREREBOOTUNIT');
    const modalHeader = i18n.t('REBOOTUNIT');
    const primaryBtnText = i18n.t('YES');
    const secondaryBtnText = i18n.t('NO');
    const modalDetails = {
      modalHeader,
      modalBody,
      primaryBtnText,
      secondaryBtnText,
      destId,
    };
    modalHandler(modalDetails);
  }

  const CustIconRadioBtnActive = ({ className = '', title = '' }) => {
    return (
      <i
        type='cool-10094'
        className={`cIcon cIcon-Dot05Xl ${className}`}
        title={title}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='.8em'
          height='.8em'
          viewBox='0 0 29.107 29.107'
        >
          <g>
            <path
              d='M14.554 0C6.561 0 0 6.562 0 14.552c0 7.996 6.561 14.555 14.554 14.555 7.996 0 14.553-6.559 14.553-14.555C29.106 6.562 22.55 0 14.554 0z'
              fill='currentcolor'
              opacity='1'
              data-original='#000000'
              class=''
            />
          </g>
        </svg>
      </i>
    );
  };

  const dispatch = useDispatch();
  const { unitOrder } = useSelector((state) => state.unit);
  const unitSelectorDropdown = [
    {
      value: 1,
      text: i18n.t('ACTIVE'),
      icon: <CustIconRadioBtnActive />,
    },
    {
      value: 2,
      text: i18n.t('ALIAS'),
      icon: <SortAscendingOutlined />,
    },
  ];

  const [popoverVisible, setPopoverVisible] = useState(false);
  const handleOpenChange = (visible) => {
    setPopoverVisible(visible);
  };

  const handleDropdownChange = (value) => {
    dispatch(unitActions.storeSelectorValue(value));
    setPopoverVisible(false);
  };

  const renderSwitch = (status, product) => {
    switch (`${status}-${product}`) {
      case 'offline-LU-Solo':
      case 'offline-LU-300':
      case 'offline-LU-800':
      case 'offline-LU-600':
      case 'offline-LU-Smart':
        return (
          <img
            className={`d-flex ${status === `offline` ? `offline` : ``}`}
            src={SoloDevice}
            alt='description'
          />
        );
      case 'online-LU-Solo':
        return <img className='d-flex' src={SoloDevice} alt='description' />;
      case 'idle-LU-Solo':
        return <img className='d-flex' src={SoloDevice} alt='description' />;
      case 'streaming-LU-Solo':
        return <img className='d-flex' src={SoloDevice} alt='description' />;
      case 'connected-LU-Solo':
        return <img className='d-flex' src={SoloDevice} alt='description' />;
      case 'starting transcoder-LU-Solo':
        return <img className='d-flex' src={SoloDevice} alt='description' />;
      case 'transcoding-LU-Solo':
        return <img className='d-flex' src={SoloDevice} alt='description' />;
      case 'offline-LU_SOLO_PRO':
        return (
          <img
            className={`d-flex ${status === `offline` ? `offline` : ``}`}
            src={SoloProdevice}
            alt='description'
          />
        );
      case 'idle-LU_SOLO_PRO':
        return <img className='d-flex' src={SoloProdevice} alt='description' />;
      case 'streaming-LU_SOLO_PRO':
        return <img className='d-flex' src={SoloProdevice} alt='description' />;
      case 'connected-LU_SOLO_PRO':
        return <img className='d-flex' src={SoloProdevice} alt='description' />;
      case 'starting transcoder-LU-Solo-Pro':
        return <img className='d-flex' src={SoloProdevice} alt='description' />;
      case 'transcoding-LU_SOLO_PRO':
        return <img className='d-flex' src={SoloProdevice} alt='description' />;
      case 'offline-LU-Solo-Plus':
        return (
          <img
            className={`d-flex ${status === `offline` ? `offline` : ``}`}
            src={SoloPlusdevice}
            alt='description'
          />
        );
      case 'idle-LU-Solo-Plus':
        return (
          <img className='d-flex' src={SoloPlusdevice} alt='description' />
        );
      case 'streaming-LU-Solo-Plus':
        return (
          <img className='d-flex' src={SoloPlusdevice} alt='description' />
        );
      case 'connected-LU-Solo-Plus':
        return (
          <img className='d-flex' src={SoloPlusdevice} alt='description' />
        );
      case 'starting transcoder-LU-Solo-Plus':
        return (
          <img className='d-flex' src={SoloPlusdevice} alt='description' />
        );
      case 'transcoding-Liveu-Solo':
        return (
          <img className='d-flex' src={SoloPlusdevice} alt='description' />
        );
      case 'mobile':
        return <img className='d-flex' src={Mobile} alt='description' />;
      default:
        return <img className='d-flex' src={SoloDevice} alt='description' />;
    }
  };

  return (
    <div
      className={`unit-item ${unit.isActive} pl-sm-1 pr-sm-2 py-sm-4 py-3 px-2  d-flex flex-sm-column flex-row align-items-center justify-content-sm-center`}
    >
      <Link
        to={`/dashboard/units/${unit.BOSSID}`}
        className='unitSettings'
        aria-expanded='true'
      >
        <svg
          onClick={(e) => onUnitSettings(e, unit)}
          height='15px'
          className='svg_setting'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 9.66 9.66'
        >
          <path d='M7.72,8.7a2.8,2.8,0,0,1-.25-.14c-.21-.14-.39-.39-.61-.42s-.45.19-.68.23c-.42.07-.5.32-.52.68,0,.61-.07.61-.68.61H4.39a.28.28,0,0,1-.33-.28C4,9.18,4,9,4,8.79s-.09-.31-.3-.36a1.74,1.74,0,0,1-.56-.23.36.36,0,0,0-.5.05c-.14.12-.3.22-.45.34a.29.29,0,0,1-.42,0l-.66-.66a.28.28,0,0,1,0-.4c.14-.19.27-.39.42-.57a.23.23,0,0,0,0-.29,7.12,7.12,0,0,1-.31-.76c0-.12-.09-.18-.22-.19l-.7-.1A.28.28,0,0,1,0,5.29V4.37c0-.21.1-.3.3-.32S.77,4,1,3.94a.28.28,0,0,0,.18-.16c.12-.26.23-.53.33-.8a.29.29,0,0,0,0-.24c-.13-.2-.27-.38-.42-.57a.3.3,0,0,1,0-.44c.22-.21.43-.43.65-.65a.28.28,0,0,1,.42,0c.21.17.42.43.66.46s.49-.2.75-.26A.47.47,0,0,0,4,.75c0-.15,0-.29.06-.44A.29.29,0,0,1,4.39,0h.9a.3.3,0,0,1,.33.31,5.06,5.06,0,0,0,.1.68.31.31,0,0,0,.16.18c.26.12.53.23.8.33a.31.31,0,0,0,.25,0,5.45,5.45,0,0,0,.56-.42.3.3,0,0,1,.44,0c.22.21.44.43.65.65a.28.28,0,0,1,0,.42c-.17.21-.42.42-.46.66s.21.47.25.73S8.66,4,9,4l.3,0c.37,0,.4.09.4.46v.75a.3.3,0,0,1-.31.36,1.43,1.43,0,0,0-.73.13c-.17.12-.19.44-.32.63s-.14.57.09.86c.4.48.44.45-.06.94l-.39.38A1.21,1.21,0,0,1,7.72,8.7ZM4.83,2.53a2.3,2.3,0,1,0,2.3,2.29A2.28,2.28,0,0,0,4.83,2.53Z' />
        </svg>
        {unit.status !== 'offline' && (
          <span className='pl-2 ' onClick={() => modalDetails(unit.BOSSID)}>
            {/*<img*/}
            {/*  src='https://img.icons8.com/external-those-icons-fill-those-icons/24/000000/external-power-settings-those-icons-fill-those-icons.png'*/}
            {/*  alt={''}*/}
            {/*/>*/}

            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='15px'
              className='svg_restart'
              viewBox='0 0 16 17.983'
            >
              <path
                d='M13,4.009a1,1,0,1,0-2,0l0,8a1,1,0,1,0,2,0Z'
                transform='translate(-4 -3.009)'
              />
              <path
                d='M4,12.992A7.975,7.975,0,0,1,6.343,7.335L7.757,8.749a6,6,0,1,0,8.485,0l1.414-1.414A8,8,0,1,1,4,12.992Z'
                transform='translate(-4 -3.009)'
              />
            </svg>
          </span>
        )}
      </Link>
      <Link
        to={`/dashboard/units/${unit.BOSSID}`}
        className='unitImgitem_link'
        onClick={(e) => {
          onSelectedUnit(e, unit, unit.name);
        }}
      />
      {index === 0 && (
        <div className='popoverSetting' onClick={(e) => e.stopPropagation()}>
          <Popover
            placement='bottomRight'
            content={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {unitSelectorDropdown.map((option) => (
                  <span
                    className='popoverOptionName'
                    key={option.value}
                    defaultValue={1}
                    style={{
                      cursor: 'pointer',
                      color: unitOrder === option.value ? '#E44920' : 'inherit',
                    }}
                    onClick={() => handleDropdownChange(option.value)}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          marginRight: '5px',
                          width: '20px',
                          display: 'inline-block',
                          height: '33px',
                        }}
                      >
                        {option.icon}
                      </span>
                      {option.text}
                    </span>
                  </span>
                ))}
              </div>
            }
            trigger='click'
            visible={popoverVisible}
            onVisibleChange={handleOpenChange}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <FilterFilled style={{ color: '#e44920' }} />
          </Popover>
        </div>
      )}
      <div className='unitImgitem d-flex flex-column'>
        <div className=' switch-icon mr-2 mr-sm-0'>
          {renderSwitch(unit.status, unit.product)}
        </div>
      </div>

      {unit.status === 'mobile' && (
        <div className='item-desc d-flex flex-column mt-sm-3'>
          <p>
            <b>Your phone</b>
          </p>
        </div>
      )}
      {unit.status !== 'mobile' && (
        <div className='item-desc  flex-column mt-sm-3'>
          <span title={unit.name}>{unit.name}</span>
        </div>
      )}

      {unit.status === 'streaming' && (
        <div className='nowlivebox'>
          <span className='nowLiveTxt'>Now LIVE</span>
        </div>
      )}
    </div>
  );
};
