import React, { useState, useEffect } from 'react';
import StreamToolsPreviewView from './StreamToolsPreviewView';
import './StreamToolsPreview.css';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { liveStreamActions } from '../../actions';
import { history } from '../../history';
import streamToolsQueue from '../../helpers/queue';

let socket;
let timer;

const StreamToolsPreview = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [fallbackUrl, setFallbackUrl] = useState(null);
  const [showFallback, setShowFallback] = useState(false);
  const [audioVolume, setAudioVolume] = useState(69);
  const [isParamsFetched, setIsParamsFetched] = useState(false);
  const [streamToolData, setStreamToolData] = useState(null);
  const [muteLiveFeed, setMute] = useState(false);
  // const [audioContext, setAudioContext] = useState(null)
  const streamToolsUnitsList = useSelector((state) => state.streamTools);
  const { updateAudio } = liveStreamActions;

  useEffect(() => {
    (async () => {
      await dispatch(
        liveStreamActions.retriveStreamToolsInfo(match.params.bossId, false),
      );
      setIsParamsFetched(true);
      setStreamToolData(streamToolsUnitsList[match.params.bossId]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.bossId]);

  useEffect(() => {
    (async () => {
      if (streamToolData) {
        const { fallBackSlateUrl } = streamToolData;
        if (fallBackSlateUrl) {
          const res = await fetch(fallBackSlateUrl);
          if (res.ok) {
            setFallbackUrl(fallBackSlateUrl);
          } else {
            setFallbackUrl(null);
          }
        } else {
          setFallbackUrl(null);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamToolData]);
  useEffect(() => {
    dispatch(
      streamToolsQueue(() => {
        return () => {
          if (muteLiveFeed) {
            dispatch(updateAudio(match.params.bossId, audioVolume * -1));
          } else {
            dispatch(updateAudio(match.params.bossId, audioVolume));
          }
        };
      }, []),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muteLiveFeed]);
  const updateAudioHandler = async (volume) => {
    setAudioVolume(volume);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      dispatch(
        streamToolsQueue(() => {
          return () => {
            dispatch(updateAudio(match.params.bossId, volume));
          };
        }, []),
      );
    }, 500);
  };

  const handleShowfallback = () => {
    setShowFallback((pre) => !pre);
  };
  const back = () => {
    history.goBack();
  };
  const toggleMute = () => {
    setMute((pre) => !pre);
  };
  return (
    <>
      {
        <StreamToolsPreviewView
          {...{
            t,
            match,
            socket,
            isParamsFetched,
            liveId: streamToolData?.streamLiveManageId,
            fallbackUrl,
            showFallback,
            handleShowfallback,
            audioVolume,
            updateAudioHandler,
            back,
            muteLiveFeed,
            toggleMute,
          }}
        />
      }
    </>
  );
};

export default StreamToolsPreview;
