import {
  getLUCDestinations,
  _createDestination,
  deleteLUCdestination,
  _getUnitDetails,
  _getLUCDestination,
  getLUCStreamingProviders,
} from '../api/luc.api';
import {
  getAWSDestinationList,
  getAWSDestinationById,
  deleteAWSDestination,
  _createAWSDestinationList,
  nDeleteStreamToolDestination,
} from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
// import {providers} from '../utils';

const cookies = new Cookies();
const { REACT_APP_ApplicationId } = process.env;

export const destinationService = {
  getAWSDestination,
  getLUCDestination,
  getDestinationDetails,
  AWSLucAPIDestination,
  createDestination,
  delLUCDestination,
  delAWSDestination,
  getStreamingProviders,
  getUnitDetails,
  createAWSDestinations,
  deleteStreamToolDestination,
};

async function getAWSDestination() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const awsdestinations_res = await getAWSDestinationList(awsHeaders);
    // if (typeof awsdestinations_res.data.data.response !== 'undefined') {
    //   return awsdestinations_res.data.data.response;
    // }
    if (
      awsdestinations_res.data &&
      awsdestinations_res.data.data &&
      awsdestinations_res.data.data.response
    ) {
      return awsdestinations_res.data.data.response;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getLUCDestination() {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const lucdestinations_res = await getLUCDestinations(lucHeaders);

    const transformedResponse = {
      destinations: lucdestinations_res.data.map((destination) => ({
        id: destination?.id,
        name: destination?.name,
        inventories: destination?.inventories,
        type: destination?.type,
        streaming_destination: {
          external_id: destination?.externalId,
          streaming_provider: destination?.streamingProvider,
          streaming_destination_outputs: [
            {
              streaming_profile:
                destination?.streamingDestinationOverrides[0]?.streamingProfile,
              stream_name:
                destination?.streamingDestinationOverrides[0]?.streamId,
              min_res_override:
                destination?.streamingDestinationOverrides[0]
                  ?.minResolutionOverride,
              max_res_override:
                destination?.streamingDestinationOverrides[0]
                  ?.maxResolutionOverride,
            },
          ],
          streaming_ingest: {
            username: destination?.streamingIngest?.primaryUsername,
            password: destination?.streamingIngest?.primaryPassword,
            primary_url: destination?.streamingIngest?.primaryUrl,
            secondary_url: destination?.streamingIngest?.secondaryUrl,
          },
        },
      })),
    };
    return Promise.resolve(transformedResponse.destinations);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getDestinationDetails(selected_destination) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const lucdestination_res = await _getLUCDestination(
      lucHeaders,
      selected_destination,
    );
    const transformedResponse = {
      destination: {
        id: lucdestination_res?.data?.id,
        name: lucdestination_res?.data?.name,
        inventories: lucdestination_res?.data?.inventories,
        type: lucdestination_res?.data?.type,
        streaming_destination: {
          external_id: lucdestination_res?.data?.externalId,
          streaming_provider: lucdestination_res?.data?.streamingProvider,
          streaming_destination_outputs: [
            {
              streaming_profile:
                lucdestination_res?.data?.streamingDestinationOverrides[0]
                  ?.streamingProfile,
              stream_name:
                lucdestination_res?.data?.streamingDestinationOverrides[0]
                  ?.streamId,
            },
          ],
          streaming_ingest: {
            primary_url: lucdestination_res?.data?.streamingIngest?.primaryUrl,
          },
        },
      },
    };
    return Promise.resolve(transformedResponse.destination);
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get Primary Url from AWS
async function AWSLucAPIDestination(external_id) {
  const headers = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await getAWSDestinationById(headers, external_id);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
// Create LUC Destination
async function createDestination(payload) {
  const headers = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await _createDestination(headers, payload);
    return res.data.data.destination;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function delLUCDestination(destId) {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await deleteLUCdestination(lucHeaders, destId);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function delAWSDestination(destId) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await deleteAWSDestination(awsHeaders, destId);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getStreamingProviders() {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await getLUCStreamingProviders(lucHeaders);
    return res.data.data.streaming_providers;
    // return providers();
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getUnitDetails(id) {
  const headers = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await _getUnitDetails(headers, id);
    return res.data.data.unit;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function createAWSDestinations(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const awsdestinations_res = await _createAWSDestinationList(
      awsHeaders,
      payload,
    );
    return awsdestinations_res.data.data.response;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function deleteStreamToolDestination(id) {
  try {
    const awsHeaders = {
      Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const awsdestinations_res = await nDeleteStreamToolDestination(
      awsHeaders,
      id,
    );
    return awsdestinations_res.data.data.response;
  } catch (error) {
    return Promise.reject(error);
  }
}
