import { periscopeTwitterService } from '../services';
import { errorBlock } from '../api/errorBlock';
import i18n from 'i18next';
import { notify } from '../components/CommonNotification/CommonNotification';
import { history } from '../history';
import { commonConstants } from '../constants';
import { commonAction } from './common.actions';
import _ from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const periscopetwitterActions = {
  getTwitterRegion,
  getTwitterSources,
  twitterAccessToken,
  twitterCreateBroadcast,
  twitterPublishBroadcast,
  twitterCreateSource,
  twitterDeleteSource,
};

function twitterCreateSource(payload, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      let broadcastResult, res, source;
      if (!_.isEmpty(payload.source)) {
        // eslint-disable-next-line prefer-destructuring
        source = payload.source;
      } else {
        const sourcePayload = {
          source_name: payload.sourceName,
          region: payload.region,
        };
        res = await periscopeTwitterService.twitterCreateSource(sourcePayload);
        // eslint-disable-next-line prefer-destructuring
        source = res.source;
      }
      broadcastResult = await twitterCreateBroadcast(source, payload);
      const destinationPayload = {
        destinationName: payload.destinationName,
        selectedProfile: payload.profile.value,
        primary_url: source.rtmp_url,
        secondary_url: '',
        stream_name: source.rtmp_stream_key,
        username: '',
        password: '',
        id: broadcastResult.broadcast.id,
        streaming_provider: 'periscope and twitter',
        overrideResolution: payload.resolutionBox
          ? payload.overrideResolution.value
          : '',
        overrideFramerate: payload.frameRateBox
          ? payload.overrideFrame.value
          : '',
        overrideBitrate: payload.bitRateBox ? payload.overrideBitrate : '',
        audio_bitrate_override: payload.audioBitRateBox ? payload.audioBitRate?.value : null
      };
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('twitter_stream_state', broadcastResult.broadcast.state, {
        path: '/',
        expires: d,
        secure: true,
      });
      // cookies.set('twitter_stream_state', broadcastResult.broadcast.state);
      dispatch(setTwitterStatus(broadcastResult.broadcast));
      dispatch(commonAction.createAwsDestination(destinationPayload, bossId));
      // dispatch(success());
    } catch (e) {
      // history.push('/dashboard/periscopelogin/' + unitId);
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
  // function request() {
  //   return { type: 'TWITTER_REQ' };
  // }
  function setTwitterStatus(payload) {
    return { type: 'SET_TWITTER_STATE', payload };
  }
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  // function success() {
  //   return { type: 'CREATE_TWITTER_SOURCE' };
  // }
  function failure(error) {
    return { type: 'PT_GET_TOKEN_FAIL', error };
  }
}
async function twitterCreateBroadcast(source, payload) {
  try {
    const broadcastPayload = {
      is_low_latency: 'false',
      region: payload.region,
      source_id: source.id,
    };
    const res = await periscopeTwitterService.createTwitterBroadcast(
      broadcastPayload,
    );
    return res;
  } catch (e) {
    // history.push('/dashboard/periscopelogin/' + unitId);
    //dispatch(failure(e.toString()));
    errorBlock(e);
  }
}

function twitterPublishBroadcast(payload) {
  return async (dispatch) => {
    try {
      const broadcastResult =
        await periscopeTwitterService.publishTwitterBroadcast(payload);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set(
        'twitter_stream_state',
        payload.state === 'END' ? 'END' : broadcastResult.broadcast.state,
        {
          path: '/',
          expires: d,
          secure: true,
        },
      );
      dispatch(setTwitterStatus(broadcastResult.broadcast));
      // dispatch(success(getChann));
      // dispatch(getStreamingProviders());
    } catch (e) {
      // history.push('/dashboard/periscopelogin/' + unitId);
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
  // function request() {
  //   return { type: 'PT_GET_TOKEN_REQ' };
  // }
  // function success(twitterRegion) {
  //   return { type: 'GET_TWITTER_REGION', twitterRegion };
  // }
  function setTwitterStatus(payload) {
    return { type: 'SET_TWITTER_STATE', payload };
  }
  function failure(error) {
    return { type: 'PT_GET_TOKEN_FAIL', error };
  }
}
function twitterDeleteSource(payload) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await periscopeTwitterService.deleteTwitterSource(payload.ids);
      notify('success', i18n.t('SOURCEDELETED'));
      dispatch(success(payload.ids));
    } catch (err) {
      notify('error', i18n.t('SOURCENOTDELETED'));
      dispatch(failure(err));
    }
  };
  function request() {
    return { type: 'DELETE_TWITTER_SOURCE_REQ' };
  }
  function success(sourceId) {
    return { type: 'DELETE_TWITTER_SOURCE', sourceId };
  }
  function failure(error) {
    return { type: 'DELETE_TWITTER_SOURCE_FAIL', error };
  }
}

function twitterAccessToken(payload) {
  return async (dispatch) => {
    try {
      request();
      const accessToken = await periscopeTwitterService.twitterAccessToken(
        payload,
      );
      if (accessToken.StatusCode === 200) {
        let d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        cookies.set(`${cookies.get('username')}_twitterLogin`, true, {
          path: '/',
          expires: d,
          secure: true,
        });
        const unitId = cookies.get('currentSelectedUnit');
        history.push('/dashboard/twitter/' + unitId);
      }
      // dispatch(success(getChann));
      // dispatch(getStreamingProviders());
    } catch (e) {
      // history.push('/dashboard/periscopelogin/' + unitId);
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
  function request() {
    return { type: 'TWITTER_GET_ACCESS_TOKEN_REQ' };
  }
  // function success(twitterRegion) {
  //   return { type: 'GET_TWITTER_REGION', twitterRegion };
  // }
  function failure(error) {
    return { type: 'PT_GET_TOKEN_FAIL', error };
  }
}

function getTwitterRegion() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const twitterRegion = await periscopeTwitterService.getTwitterRegion();
      dispatch(success(twitterRegion));
    } catch (e) {
      // history.push('/dashboard/periscopelogin/' + unitId);
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
  function request() {
    return { type: 'TWITTER_REQ' };
  }
  function success(twitterRegion) {
    return { type: 'GET_TWITTER_REGION', twitterRegion };
  }
  function failure(error) {
    return { type: 'PT_GET_TOKEN_FAIL', error };
  }
}

function getTwitterSources() {
  return async (dispatch) => {
    try {
      // dispatch(request());
      const twitterSources = await periscopeTwitterService.getTwitterSources();
      dispatch(success(twitterSources.sources));
    } catch (e) {
      // history.push('/dashboard/periscopelogin/' + unitId);
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
  // function request() {
  //   return { type: 'TWITTER_REQ' };
  // }
  function success(twitterSources) {
    return { type: 'GET_TWITTER_SOURCES', twitterSources };
  }
  function failure(error) {
    return { type: 'PT_GET_TOKEN_FAIL', error };
  }
}

// function twitterLogout(match) {
//   return async (dispatch) => {
//     try {
//       delete cookies.get('YT_access_token');
//       // dispatch(ytInitialState(match));
//       history.push('/dashboard/configureyoutube/' + match.params.bossId);
//     } catch (e) {
//       errorBlock(e, match);
//     }
//   };
// }
