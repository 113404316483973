import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const vimeoBaseUrl = 'https://api.vimeo.com';
export const createLiveEvent = (payload) =>
  axios.post(
    `${vimeoBaseUrl}/users/${cookies.get('vimeo_user_id')}/live_events`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
        accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    },
  );
export const getVimeoFollowers = () =>
  axios.get(`${vimeoBaseUrl}/me/followers`, {
    headers: {
      Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
      accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
  });

export const getUserDetails = () =>
  axios.get(`${vimeoBaseUrl}/me`, {
    headers: {
      Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
      accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
  });
export const getAllEvents = (userId) =>
  axios.get(
    `${vimeoBaseUrl}/users/${userId}/live_events?type=all&sort=date&per_page=100`,
    {
      headers: {
        Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
        accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    },
  );
export const updateVimeoStream = (id, uri) =>
  axios.post(`${vimeoBaseUrl}${uri}`, null, {
    headers: {
      Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
      accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
  });

export const addFollowertoStream = (id, payload) =>
  axios.put(
    `${vimeoBaseUrl}/videos/${id}/privacy/users`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
        'content-type': 'application/json',
        accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    },
  );

export const addDomaintoStream = (id, payload) =>
  axios.put(
    `${vimeoBaseUrl}/live_events/${id}/privacy/domains`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
        'content-type': 'application/json',
        accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    },
  );
export const deleteEvents = (userId, destId) =>
  axios.delete(`${vimeoBaseUrl}/users/${userId}/live_events/${destId}`, {
    headers: {
      Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
      accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
  });

export const getSingleEvents = (userId, destId) =>
  axios.get(`${vimeoBaseUrl}/users/${userId}/live_events/${destId}`, {
    headers: {
      Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
      accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
  });

export const updateSingleEvents = (userId, destId, event) =>
  axios.patch(`${vimeoBaseUrl}/users/${userId}/live_events/${destId}`, event, {
    headers: {
      Authorization: `Bearer ${cookies.get('vimeo_access_token')}`,
      accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
  });
