import React from 'react';
import { ProvidersView } from '../Providers/ProvidersView';
import { history } from '../../history';
import Broadcast from '../../assets/images/broadcast.png';
import LiveControlRoom from '../../assets/images/sound-controller.png';
import { Link } from 'react-router-dom';
// import ReactGA from 'react-ga';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

export const DestinationsView = ({
  t,
  match,
  unit,
  selectedDes,
  transcoderState,
  loginFlags,
  modalHandler,
  fbMakePostLive,
  fbLogin,
  ytMakePostLive,
  ytLogin,
  utf,
  channel,
  twitterStreamState,
  selectProvider,
  selectedAwsDestination,
}) => {
  const { name, streaming_destination } = selectedDes;
  function deleteDestModal(dest) {
    const destId = dest;
    const modalBody = t('DELETEDESTINATIONCONFIRMATION');
    const modalHeader = t('DELETEDESTINATION');
    const primaryBtnText = t('YES');
    const secondaryBtnText = t('NO');
    const modalDetails = {
      modalHeader,
      modalBody,
      primaryBtnText,
      secondaryBtnText,
      destId,
    };
    modalHandler(modalDetails);
  }

  const twitterSignIn = () => {
    selectProvider('periscope and twitter', match);
  };
  const decodedTitle =
    streaming_destination?.streaming_provider?.toLowerCase() === 'youtube' ||
    streaming_destination?.streaming_provider === 'Facebook'
      ? utf(name)
      : name;
  return (
    <div className='col-md-4 unitBlk_cont d-flex flex-column'>
      <div className='sub-title'>
        <h4>{t('DESTINATION').toUpperCase()}</h4>
      </div>
      <div className='block-wrap d-flex flex-column p-0 justify-content-between unitDestination blocknoStream'>
        <Link
          to='#'
          className='close-link dclose'
          onClick={() => {
            deleteDestModal(selectedDes);
          }}
          title={t('DELETE')}
        >
          <i className='fa fa-close'></i>
        </Link>

        <div className=' container-min d-flex flex-column px-2 pt-4 pb-2 justify-content-between'>
          <div className='dDetails'>
            <div className='form-group mb-2 destTitleNdis'>
              <span className='valuetxt' title={decodedTitle}>
                {decodedTitle}
              </span>
              {streaming_destination.streaming_provider === 'Twitch' &&
                loginFlags.twitchLoggedIn &&
                channel.display_name && (
                  <div className='form-group channel-name'>
                    <span title={t('STREAMINGCHANNELNAME')}>
                      {t('STREAMINGCHANNELNAME')}:{' '}
                    </span>
                    <span className='channel-name-value'>
                      {channel.display_name}
                    </span>
                  </div>
                )}
              {((streaming_destination.streaming_provider === 'Facebook' &&
                loginFlags.fbLoggedIn) ||
                (streaming_destination.streaming_provider.toLowerCase() ===
                  'youtube' &&
                  loginFlags.ytLoggedIn)) &&
                !!transcoderState.postDescription && (
                  <div className='form-group mb-2 desctxt'>
                    <span className='lbltxt' title={t('POSTDESCRIPTION')}>
                      {t('POSTDESCRIPTION')}:{' '}
                    </span>
                    <span className='valuetxt two-line-ellipsis'>
                      {transcoderState.postDescription}
                    </span>
                  </div>
                )}
            </div>
            {streaming_destination.streaming_provider === 'wowza' &&
              !!transcoderState.postState && (
                <div className='form-group'>
                  <span
                    className='lbltxt transcoderdes'
                    title={t('TRANSCODER')}
                  >
                    {t('TRANSCODER')}:{' '}
                  </span>
                  <span className='valuetxt'>
                    {transcoderState.postState.toUpperCase()}
                  </span>
                </div>
              )}
            {streaming_destination.streaming_provider ===
              'Periscope and Twitter' &&
              loginFlags.periscopeLoggedIn && (
                <div className='form-group'>
                  {/* <span
                    className='lbltxt transcoderdes'
                    title={t('TRANSCODER')}
                  >
                    {t('TRANSCODER')}:{' '}
                  </span> */}
                  <span className='lbltxt' title={t('POSTSTATE')}>
                    {t('POSTSTATE')}:{' '}
                  </span>
                  <span className='valuetxt'>
                    {/* {twitterStreamStatus.toUpperCase()} */}
                    {twitterStreamState}
                  </span>
                </div>
              )}
            {streaming_destination.streaming_provider === 'LinkedIn' &&
              !!transcoderState.postState &&
              loginFlags.linkedinLoggedIn && (
                <div className='form-group'>
                  <span className='lbltxt' title={t('POSTSTATE')}>
                    {t('POSTSTATE')}:{' '}
                  </span>
                  <span className='valuetxt'>
                    {transcoderState.postState.toUpperCase()}
                  </span>
                </div>
              )}
            {streaming_destination.streaming_provider === 'Facebook' &&
              !!transcoderState.postState &&
              loginFlags.fbLoggedIn && (
                <div className='form-group'>
                  <span className='lbltxt' title={t('POSTSTATE')}>
                    {t('POSTSTATE')}:{' '}
                  </span>
                  <span className='valuetxt'>
                    {transcoderState.postState.toUpperCase()}
                  </span>
                  {transcoderState.postState === 'preview' && (
                    <div className='btn-col  postlive'>
                      <button
                        type='button'
                        className='btag'
                        onClick={() => {
                          fbMakePostLive();
                        }}
                      >
                        {t('MAKEPOSTLIVE')}
                      </button>
                    </div>
                  )}
                </div>
              )}
            {streaming_destination.streaming_provider.toLowerCase() ===
              'youtube' &&
              !!transcoderState.postState &&
              loginFlags.ytLoggedIn && (
                <div className='form-group d-flex mb-2'>
                  <span className='lbltxt' title={t('POSTSTATE')}>
                    {t('POSTSTATE')}:{' '}
                  </span>
                  <span className='valuetxt px-2'>
                    {transcoderState.postState.toUpperCase()}
                  </span>
                  {transcoderState.postState === 'preview' &&
                    selectedAwsDestination[0]?.startAsPreview === true && (
                      <div className='btn-col postlive'>
                        <button
                          type='button'
                          className='btag'
                          onClick={() => {
                            ytMakePostLive();
                          }}
                        >
                          {t('MAKEPOSTLIVE')}
                        </button>
                      </div>
                    )}
                </div>
              )}
            {(transcoderState.postState.toLowerCase() === 'live' ||
              transcoderState.postState.toLowerCase() === 'schedule_live') &&
              loginFlags.fbLoggedIn &&
              streaming_destination.streaming_provider === 'Facebook' && (
                <div className='row  control-link'>
                  <div className='col-lg-12'>
                    <img
                      src={Broadcast}
                      alt='Broadcast'
                      title={t('LIVEBROADCAST')}
                      className='mr-1'
                    />
                    <button
                      type='button'
                      className='atag'
                      onClick={() => {
                        window.open(
                          `https://www.facebook.com/${transcoderState.postId}`,
                          '_blank',
                        );
                      }}
                    >
                      {t('LIVEBROADCAST')}
                    </button>
                  </div>
                </div>
              )}
            {loginFlags.vimeoLoggedIn &&
              streaming_destination.streaming_provider === 'Vimeo' &&
              transcoderState.postState === 'Live' &&
              transcoderState.postId.length > 0 &&
              unit.status === 'streaming' && (
                <div className='row  control-link'>
                  <div className='col-lg-12'>
                    <img
                      src={Broadcast}
                      alt='Broadcast'
                      title={t('LIVEBROADCAST')}
                    />
                    <button
                      type='button'
                      className='atag'
                      onClick={() => {
                        window.open(
                          `https://vimeo.com/event/${selectedDes.streaming_destination.external_id}`,
                          '_blank',
                        );
                      }}
                    >
                      {t('LIVEBROADCAST')}
                    </button>
                  </div>
                </div>
              )}
            {transcoderState.postState.toLowerCase() === 'live' &&
              loginFlags.linkedinLoggedIn &&
              streaming_destination.streaming_provider === 'LinkedIn' &&
              !!transcoderState.postId && (
                <div className='row  control-link'>
                  <div className='col-lg-12'>
                    <img
                      src={Broadcast}
                      alt='Broadcast'
                      title={t('LIVEBROADCAST')}
                    />
                    <button
                      type='button'
                      className='atag'
                      onClick={() => {
                        window.open(
                          `https://www.linkedin.com/video/live/${transcoderState.postId}`,
                          '_blank',
                        );
                      }}
                    >
                      {t('LIVEBROADCAST')}
                    </button>
                  </div>
                </div>
              )}
            {streaming_destination.streaming_provider.toLowerCase() ===
              'youtube' &&
              !!transcoderState.postState &&
              transcoderState.postState !== 'deleted' &&
              transcoderState.postState !== 'complete' &&
              loginFlags.ytLoggedIn && (
                <div className='row control-link'>
                  <div className='col-lg-6 pr-0'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={Broadcast}
                        title={t('LIVEBROADCAST')}
                        className='mr-1'
                        alt=''
                      />
                      <span
                        className='atag control-link link'
                        onClick={() => {
                          window.open(
                            `https://www.youtube.com/watch?v=${transcoderState.postId}`,
                            '_blank',
                          );
                        }}
                      >
                        {t('LIVEBROADCAST')}
                      </span>
                    </div>
                  </div>
                  <div className='col-lg-6 pr-0'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={LiveControlRoom}
                        title={t('LIVECONTROLROOM')}
                        className='mr-1'
                        alt=''
                      />
                      <span
                        className='atag control-link link'
                        onClick={() => {
                          window.open(
                            `https://www.youtube.com/live_event_analytics?v=${transcoderState.postId}`,
                            '_blank',
                          );
                        }}
                      >
                        {t('LIVECONTROLROOM')}
                      </span>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          {streaming_destination.streaming_provider === 'wowza' && (
            <div className='d-flex flex-row align-items-center justify-content-center pb-4'>
              <button
                type='button'
                className='btn btn-primary btn-wowzapost btn-sm fbpost'
                onClick={() => {
                  history.push('/dashboard/wowza/' + match.params.bossId);
                }}
                title={t('NEWWOWZASTREAM')}
              >
                {t('NEWWOWZASTREAM')}
              </button>
            </div>
          )}
          {streaming_destination.streaming_provider === 'Twitch' && (
            <div className='solounit-preview'>
              {loginFlags.twitchLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary btn-twitchpost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Twitch stream button on Dashboard',
                    //   label: 'Twitch',
                    // });

                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Twitch stream button on Dashboard',
                      'Twitch',
                    );
                    history.push('/dashboard/twitch/' + match.params.bossId);
                  }}
                  title={t('NEWTWITCHSTREAM')}
                >
                  {t('NEWTWITCHSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary btn-twitchpost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/twitchlogin/' + match.params.bossId,
                    );
                  }}
                  title={t('TWITCHSIGNIN')}
                >
                  {t('TWITCHSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider === 'Boxcast' && (
            <div className='solounit-preview '>
              {loginFlags.bcLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary btn-boxcastpost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Boxcast stream button on Dashboard',
                    //   label: 'Boxcast',
                    // });

                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Boxcast stream button on Dashboard',
                      'Boxcast',
                    );
                    history.push('/dashboard/boxcast/' + match.params.bossId);
                  }}
                  title={t('NEWBOXCASTSTREAM')}
                >
                  {t('NEWBOXCASTSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary btn-boxcastpost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/boxcastlogin/' + match.params.bossId,
                    );
                  }}
                  title={t('BOXCASTSIGNIN')}
                >
                  {t('BOXCASTSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider === 'Switchboard Live' && (
            <div className='solounit-preview '>
              {loginFlags.sbLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary btn-switchboardpost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Switchboard Live stream button on Dashboard',
                    //   label: 'Switchboard Live',
                    // });

                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Switchboard Live stream button on Dashboard',
                      'Switchboard Live',
                    );
                    history.push(
                      '/dashboard/switchboard/' + match.params.bossId,
                    );
                  }}
                  title={t('SBNEWSTREAM')}
                >
                  {t('SBNEWSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary btn-switchboardpost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/switchboardlogin/' + match.params.bossId,
                    );
                  }}
                  title={t('SBSIGNIN')}
                >
                  {t('SBSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider ===
            'Periscope and Twitter' && (
            <div className='solounit-preview '>
              {loginFlags.periscopeLoggedIn ? (
                // !!transcoderState.postState && (
                <button
                  type='button'
                  className='btn btn-primary btn-pspost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Twitter stream button on Dashboard',
                    //   label: 'Twitter',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Twitter stream button on Dashboard',
                      'Twitter',
                    );
                    history.push('/dashboard/twitter/' + match.params.bossId);
                  }}
                  title={t('NEWPTSTREAM')}
                >
                  {t('NEWPTSTREAM')}
                </button>
              ) : (
                // )
                <button
                  type='button'
                  className='btn btn-primary btn-pspostlogout btn-sm fbpost'
                  onClick={() => twitterSignIn()}
                  title={t('PERISCOPESIGNIN')}
                >
                  {t('PERISCOPESIGNIN')}
                </button>
              )}
            </div>
          )}

          {streaming_destination.streaming_provider === 'LinkedIn' && (
            <div className='solounit-preview '>
              {loginFlags.linkedinLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary  btn-linkedinpost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new LinkedIn stream button on Dashboard',
                    //   label: 'LinkedIn',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new LinkedIn stream button on Dashboard',
                      'LinkedIn',
                    );
                    history.push('/dashboard/linkedin/' + match.params.bossId);
                  }}
                  title={t('NEWLINKEDINSTREAM')}
                >
                  {t('NEWLINKEDINSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary  btn-linkedinpost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/linkedinlogin/' + match.params.bossId,
                    );
                  }}
                  title={t('LINKEDINSIGNIN')}
                >
                  {t('LINKEDINSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider === 'Vimeo' && (
            <div className='solounit-preview '>
              {loginFlags.vimeoLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary  btn-vimeopost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Vimeo stream button on Dashboard',
                    //   label: 'Vimeo',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Vimeo stream button on Dashboard',
                      'Vimeo',
                    );
                    history.push('/dashboard/vimeo/' + match.params.bossId);
                  }}
                  title={t('NEWVIMEOSTREAM')}
                >
                  {t('NEWVIMEOSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary  btn-vimeopost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/vimeologin/' + match.params.bossId,
                    );
                  }}
                  title={t('VIMEOSIGNIN')}
                >
                  {t('VIMEOSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider === 'Easy Live' && (
            <div className='solounit-preview '>
              {loginFlags.elLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary  btn-easylivepost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Easy Live stream button on Dashboard',
                    //   label: 'Easy Live',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Easy Live stream button on Dashboard',
                      'Easy Live',
                    );
                    history.push('/dashboard/easylive/' + match.params.bossId);
                  }}
                  title={t('EASYLIVENEWSTREAM')}
                >
                  {t('EASYLIVENEWSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary  btn-easylivepost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/easylivelogin/' + match.params.bossId,
                    );
                  }}
                  title={t('ELSIGNIN')}
                >
                  {t('ELSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider === 'Restream.io' && (
            <div className='solounit-preview '>
              {loginFlags.reLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary  btn-restreampost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new Restream.io stream button on Dashboard',
                    //   label: 'Restream.io',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new Restream.io stream button on Dashboard',
                      'Restream.io',
                    );
                    history.push('/dashboard/restream/' + match.params.bossId);
                  }}
                  title={t('NEWRESTREAMSTREAM')}
                >
                  {t('NEWRESTREAMSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary  btn-restreampost btn-sm fbpost'
                  onClick={() => {
                    history.push(
                      '/dashboard/restreamlogin/' + match.params.bossId,
                    );
                  }}
                  title={t('RESTREAMSIGNIN')}
                >
                  {t('RESTREAMSIGNIN')}
                </button>
              )}
            </div>
          )}
        </div>
        <div className='d-flex flex-row align-items-center justify-content-between px-2 pb-2'>
          <div className='destinationimage'>
            <div className=' imgThumb'>
              <ProvidersView
                provider={streaming_destination.streaming_provider}
                isSmall={true}
              />
            </div>
          </div>

          {streaming_destination.streaming_provider === 'Facebook' && (
            <div className='solounit-preview d-flex '>
              {loginFlags.fbLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary  btn-fbpost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new FB stream button on Dashboard',
                    //   label: 'Facebook',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new FB stream button on Dashboard',
                      'Facebook',
                    );
                    history.push('/dashboard/facebook/' + match.params.bossId);
                  }}
                  title={t('NEWFBSTREAM')}
                >
                  {t('NEWFBSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary  btn-fbpost btn-sm fbpost'
                  onClick={() => {
                    fbLogin(match);
                  }}
                  title={t('FBSIGNIN')}
                >
                  {t('FBSIGNIN')}
                </button>
              )}
            </div>
          )}
          {streaming_destination.streaming_provider.toLowerCase() ===
            'youtube' && (
            <div className='solounit-preview d-flex'>
              {loginFlags.ytLoggedIn ? (
                <button
                  type='button'
                  className='btn btn-primary btn-ytpost btn-sm fbpost'
                  onClick={() => {
                    // ReactGA.event({
                    //   category: 'One-touch Providers',
                    //   action:
                    //     'User pressed the create new YT stream button on Dashboard',
                    //   label: 'Youtube',
                    // });
                    CustomEvent.trackEvent(
                      'One-touch Providers',
                      'User pressed the create new YT stream button on Dashboard',
                      'Youtube',
                    );
                    history.push('/dashboard/youtube/' + match.params.bossId);
                  }}
                  title={t('NEWYTSTREAM')}
                >
                  {t('NEWYTSTREAM')}
                </button>
              ) : (
                <button
                  type='button'
                  className='btn btn-primary btn-ytpost btn-sm fbpost'
                  onClick={(event) => {
                    ytLogin(event, match);
                  }}
                  title={t('YTSIGNIN')}
                >
                  {t('YTSIGNIN')}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
