import { destinationConstant } from '../constants';

const initialState = {
  data: {},
};

const {
  GET_CHANNEL_SERVICE_DES_REQUEST,
  GET_CHANNEL_SERVICE_DES_SUCCESS,
  GET_CHANNEL_SERVICE_DES_ERROR,
} = destinationConstant;

export function channelServiceDestination(state = initialState, payload) {
  switch (payload.type) {
    case GET_CHANNEL_SERVICE_DES_REQUEST:
      return {
        ...state,
        channelsLoading: true,
      };
    case GET_CHANNEL_SERVICE_DES_SUCCESS:
      return {
        ...state,
        data: payload.res,
        channelsLoading: false,
      };
    case GET_CHANNEL_SERVICE_DES_ERROR:
      return {
        ...state,
        channelsLoading: false,
      };
    default:
      return state;
  }
}
