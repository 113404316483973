import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal } from 'reactstrap';
import FacebookEmoji from 'react-facebook-emoji';
import classnames from 'classnames';
import moment from 'moment';
import { Empty } from 'antd';
import fbCare from '../../../../assets/images/care.png';

export const FbAnalyticsListView = ({
  modalIsOpen,
  modalToggle,
  fbAnalyticsData,
}) => {
  let defaultTab = '';
  // eslint-disable-next-line array-callback-return
  fbAnalyticsData.reactions.filter((value) => {
    if (value.type === 'LIKE') {
      defaultTab = value.type.toLowerCase();
    } else if (value.type === 'HAHA') {
      defaultTab = value.type.toLowerCase();
    } else if (value.type === 'LOVE') {
      defaultTab = value.type.toLowerCase();
    } else if (value.type === 'SAD') {
      defaultTab = value.type.toLowerCase();
    } else if (value.type === 'ANGRY') {
      defaultTab = value.type.toLowerCase();
    } else if (value.type === 'WOW') {
      defaultTab = value.type.toLowerCase();
    } else if (value.type === 'CARE') {
      defaultTab = value.type.toLowerCase();
    }
  });
  if (!!!defaultTab) {
    !!fbAnalyticsData.view ? (defaultTab = 'views') : (defaultTab = 'comments');
  }
  if (!!!defaultTab) {
    fbAnalyticsData.comments.length
      ? (defaultTab = 'comments')
      : (defaultTab = 'comments');
  }
  const [activeTab, setActiveTab] = useState(defaultTab);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const fbListComment = () => {
    if (fbAnalyticsData.comments.length) {
      return fbAnalyticsData.comments.map((value, index) => {
        return (
          <li key={index}>
            <div>
              <div>
                <em>{value.from.name}</em>:{value.message}
              </div>
              <div>
                <small>
                  {moment(value.created_time, 'YYYY-MM-DD HH:mm Z').fromNow()}
                </small>
              </div>
            </div>
          </li>
        );
      });
    } else {
      return (
        <li key={1}>
          <Empty />
        </li>
      );
    }
  };
  const fbListReactions = (reaction) => {
    const v1 = (data) => {
      return (
        <li key={1}>
          <div>
            <div>{data.name}</div>
          </div>
        </li>
      );
    };
    if (fbAnalyticsData.reactions.length) {
      const t1 = fbAnalyticsData.reactions.filter(
        (value) => value.type.toLowerCase() === reaction,
      );
      if (t1.length) {
        return v1(t1[0]);
      } else {
        return (
          <li key={1}>
            <Empty />
          </li>
        );
      }
    } else {
      return (
        <li key={1}>
          <Empty />
        </li>
      );
    }
  };
  return (
    <Modal isOpen={modalIsOpen} toggle={modalToggle} className='viewCommentFb'>
      <i className='fa fa-times' onClick={modalToggle}></i>
      <div className='emojiPop'>
        <Nav tabs className='px-2'>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'comments' })}
              onClick={() => {
                toggle('comments');
              }}
            >
              <i className='fa fa-comment-o mb-2' title={'comments'}></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'views' })}
              onClick={() => {
                toggle('views');
              }}
            >
              <i className='fa fa-eye mb-2' title={'views'}></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'care' })}
              onClick={() => {
                toggle('care');
              }}
            >
              <img src={fbCare} alt={'care'} title={'care'} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'like' })}
              onClick={() => {
                toggle('like');
              }}
            >
              <span title={'like'}>
                <FacebookEmoji type='like' size='sm' />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'love' })}
              onClick={() => {
                toggle('love');
              }}
            >
              <span title={'love'}>
                {' '}
                <FacebookEmoji type='love' size='sm' />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'haha' })}
              onClick={() => {
                toggle('haha');
              }}
            >
              <span title={'haha'}>
                <FacebookEmoji type='haha' size='sm' />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'wow' })}
              onClick={() => {
                toggle('wow');
              }}
            >
              <span title={'wow'}>
                <FacebookEmoji type='wow' size='sm' />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'sad' })}
              onClick={() => {
                toggle('sad');
              }}
            >
              <span title={'sad'}>
                <FacebookEmoji type='sad' size='sm' />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'angry' })}
              onClick={() => {
                toggle('angry');
              }}
            >
              <span title={'angry'}>
                <FacebookEmoji type='angry' size='sm' />
              </span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId='angry'>
            <ul className='angry'>{fbListReactions('angry')}</ul>
          </TabPane>
          <TabPane tabId='comments'>
            <ul className='comments'>{fbListComment()}</ul>
          </TabPane>
          <TabPane tabId='like'>
            <ul>{fbListReactions('like')}</ul>
          </TabPane>
          <TabPane tabId='wow'>
            <ul>{fbListReactions('wow')}</ul>
          </TabPane>
          <TabPane tabId='care'>
            <ul>{fbListReactions('care')}</ul>
          </TabPane>
          <TabPane tabId='haha'>
            <ul>{fbListReactions('haha')}</ul>
          </TabPane>
          <TabPane tabId='sad'>
            <ul>{fbListReactions('sad')}</ul>
          </TabPane>
          <TabPane tabId='love'>
            <ul>{fbListReactions('love')}</ul>
          </TabPane>
          <TabPane tabId='views'>
            <ul>
              {!!fbAnalyticsData.views ? (
                <li key={'1'}>
                  <div>
                    <div>Your post has {fbAnalyticsData.views} views.</div>
                  </div>
                </li>
              ) : (
                <li key={1}>
                  <Empty />
                </li>
              )}
            </ul>
          </TabPane>
        </TabContent>
      </div>
    </Modal>
  );
};
