import _ from 'lodash';
import { paymentConstants } from '../constants';
const {
  PAYMENT_LOADING_START,
  PAYMENT_LOADING_STOP,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  //GET_SUBSCRIPTION_ERROR,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_PLANS_ERROR,
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  PAYMENT_METHOD_LOADING_START,
  PAYMENT_METHOD_LOADING_STOP,
  PAYMENT_HISTORY_LOADING_START,
  PAYMENT_HISTORY_LOADING_STOP,
  INVOICE_LOADING_START,
  INVOICE_LOADING_STOP,
  PAYMENT_INFO_LOADING_START,
  PAYMENT_INFO_LOADING_STOP,
  GET_INVOICES_SUCCESS,
  GET_CHARGES_SUCCESS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_CHARGE_DETAILS_SUCCESS,
  GET_COUPON_SUCCESS,
  GET_TAX_SUCCESS,
  // GET_UPCOMING_INVOICES_REQUEST,
  GET_UPCOMING_INVOICES_SUCCESS,
  GET_UPCOMING_INVOICES_ERROR,
  ADD_UNIT_LOADING_STOP,
  LRT_LOADING,
  ADD_UNIT_LOADING,
  GET_CREDIT_BALANCE_SUCCESS,
  GET_CREDIT_BALANCE_REQUEST,
  GET_LOYALTY_CREDIT_SUCCESS,
  GET_LOYALTY_CREDIT_ERROR,
  GET_CREDIT_BALANCE_FAILURE,
  GET_USERS_LOYALTY_CREDIT_SUCCESS,
  GET_USERS_LOYALTY_CREDIT_ERROR,
  REMOVE_INVOICE,
} = paymentConstants;

const initialState = {
  customer: false,
  subscriptions: [],
  creditBalance: '',
  isLoading: true,
  lrtLoading: false,
  isLoadingPayment: true,
  isLoadingMethod: true,
  isLoadingHistory: true,
  isLoadingInvoice: true,
  isLoadingInfo: true,
  isLoadingCreditBalance: true,
  plans: [],
  customerDetails: {},
  coupon: { amount_off: null, percent_off: null },
  tax: { combined_rate: 0, isZipValid: true, taxLoading: false },
  customerInvoices: [],
  customerCharges: [],
  invoice: {},
  charge: {},
  upcomingInvoices: '',
  addUnitLoading: false,
  isIndianCustomer: false,
  loyaltyCredit: null,
  userLoyaltyCredit: [],
};

export function payment(state = initialState, payload) {
  switch (payload.type) {
    case PAYMENT_LOADING_START:
      return {
        ...state,
        isLoadingPayment: true,
      };
    case ADD_UNIT_LOADING:
      return {
        ...state,
        addUnitLoading: true,
      };
    case ADD_UNIT_LOADING_STOP:
      return {
        ...state,
        addUnitLoading: false,
      };
    case PAYMENT_LOADING_STOP:
      return {
        ...state,
        isLoadingPayment: false,
      };
    case PAYMENT_METHOD_LOADING_START:
      return {
        ...state,
        isLoadingMethod: true,
      };
    case PAYMENT_METHOD_LOADING_STOP:
      return {
        ...state,
        isLoadingMethod: false,
      };
    case PAYMENT_HISTORY_LOADING_START:
      return {
        ...state,
        isLoadingHistory: true,
      };
    case PAYMENT_HISTORY_LOADING_STOP:
      return {
        ...state,
        isLoadingHistory: false,
      };
    case INVOICE_LOADING_START:
      return {
        ...state,
        isLoadingInvoice: true,
      };
    case INVOICE_LOADING_STOP:
      return {
        ...state,
        isLoadingInvoice: false,
      };
    case PAYMENT_INFO_LOADING_START:
      return {
        ...state,
        subscriptions: [],
        isLoadingInfo: true,
      };
    case PAYMENT_INFO_LOADING_STOP:
      return {
        ...state,
        isLoadingInfo: false,
      };
    case GET_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    // case ADD_UNIT_LOADING_STOP:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: payload.customer,
        isLoading: true,
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        customer: { error: 'no customer' },
        isLoading: false,
      };
    case GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
      };
    case LRT_LOADING:
      return {
        ...state,
        lrtLoading: true,
      };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: payload.res,
        lrtLoading: false,
      };
    case GET_PLANS_REQUEST:
      return {
        ...state,
      };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: payload.res,
      };
    case GET_PLANS_ERROR:
      return {
        ...state,
      };
    case GET_CUSTOMER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerDetails: payload.res,
        customer: _.isEmpty(payload.res)
          ? !_.isEmpty(payload.res)
          : !(payload.res && payload.res.deleted),
        isIndianCustomer:
          !_.isEmpty(payload.res) &&
          payload.res.address &&
          payload.res.address.country === 'IN',
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        customerInvoices: payload.resInvoices,
      };
    case GET_UPCOMING_INVOICES_SUCCESS:
      return {
        ...state,
        upcomingInvoices: payload.resUpInvoices,
      };
    case GET_UPCOMING_INVOICES_ERROR:
      return {
        ...state,
        upcomingInvoices: '',
      };
    case GET_CHARGES_SUCCESS:
      return {
        ...state,
        customerCharges: payload.resCharges,
      };
    case GET_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        invoice: payload.resInvoice,
      };
    case GET_CHARGE_DETAILS_SUCCESS:
      return {
        ...state,
        charge: payload.resCharge,
      };
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        coupon: payload.coupon,
      };
    case GET_TAX_SUCCESS:
      return {
        ...state,
        tax: payload.tax,
      };
    case GET_CREDIT_BALANCE_REQUEST:
      return {
        ...state,
      };
    case GET_CREDIT_BALANCE_SUCCESS:
      return {
        ...state,
        creditBalance: payload.res,
        isLoadingCreditBalance: false,
      };
    case GET_LOYALTY_CREDIT_SUCCESS:
      return {
        ...state,
        loyaltyCredit: payload?.data,
      };
    case GET_LOYALTY_CREDIT_ERROR:
      return {
        ...state,
        loyaltyCredit: null,
      };
    case GET_USERS_LOYALTY_CREDIT_SUCCESS:
      return {
        ...state,
        userLoyaltyCredit: payload?.data,
      };
    case GET_USERS_LOYALTY_CREDIT_ERROR:
      return {
        ...state,
        userLoyaltyCredit: [],
      };
    case GET_CREDIT_BALANCE_FAILURE:
      return {
        ...state,
        isLoadingCreditBalance: false,
      };
    case REMOVE_INVOICE:
      return {
        ...state,
        invoice: null,
      };
    default:
      return state;
  }
}
