import {
  commonConstants,
  streamToolConstants,
  userConstants,
} from '../constants';
import { unitService, userService } from '../services';
//import { alertActions } from './';
import { notify } from '../components/CommonNotification/CommonNotification';
import { history } from '../history';
import i18n from 'i18next';
import { errorBlock } from '../api/errorBlock';
import Cookies from 'universal-cookie';
import { removeLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';

const cookies = new Cookies();

const { SET_ST_INITIAL_STATE } = streamToolConstants;
const { OTHER_LOADING, OTHER_LOADING_FALSE } = commonConstants;

export const userActions = {
  login,
  logout,
  register,
  getUserInfo,
  forgetpassword,
  verifyUser,
  updatePassword,
  adminMessage,
  changePassword,
  changeEmail,
  setCommunicationPreference,
  getCommunicationPreference,
  verifyEmail,
  updateEmail,
  initialState,
  isLoadingFalse,
  setStreamToolsConfig,
};

function initialState() {
  return {
    type: userConstants.INITIAL_STATE,
  };
}
function login(username, password) {
  return async (dispatch) => {
    try {
      dispatch(request({ username }));
      const t1 = await userService.login(username, password);
      dispatch(success(t1));
      cookies.set('admin_message_visible', true, {
        path: '/',
        secure: true,
      });
      // Rollbar object created
      // const rollbarObj = !!window.rollbarObj ? window.rollbarObj : null;
      // !!rollbarObj && rollbarObj.info(`User ${username} signed in`);

      const t2 = await dispatch(getUserInfo(username));
      if (!!t2.type && t2.type !== 'luc_application') {
        notify('error', i18n.t('CONTACTSUPPORT'));
        removeLocalStorageItem('access_token');
        dispatch(failure());
        return;
      }
      if (t1 === undefined) {
        return history.push('/dashboard/units');
      }
      window.location = t1;
    } catch (e) {
      notify('error', i18n.t('USERNAMEPASSWORDERRORMESSAGE'));
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

// eslint-disable-next-line no-unused-vars
function getUserInfo(user) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const t1 = await userService.userInfo();
        dispatch(request(t1));
        resolve(t1);
      } catch (e) {
        errorBlock(e);
        reject(e);
      }
    });
  };

  function request(user) {
    return { type: userConstants.USER_INFO, user };
  }
}

function logout() {
  return (dispatch) => {
    try {
      dispatch({ type: userConstants.USER_LOGOUT });
      removeLocalStorageItem('access_token');
      // cookies.remove('FB_access_token', { path: '/' });
      localStorage.clear();
      return history.push('/login');
    } catch (e) {
      errorBlock(e);
    }
  };
}

function isLoadingFalse() {
  return (dispatch) => {
    try {
      dispatch({ type: commonConstants.OTHER_LOADING_FALSE });
    } catch (e) {
      errorBlock(e);
    }
  };
}

//function to register LUC user
function register(user, email) {
  return async (dispatch) => {
    try {
      dispatch(request(user));
      const res = await userService.registerLUCUser(user);
      if (res) {
        var payload = {
          email: email.toLowerCase(),
        };
        const termres = await userService.addLUCTerms(payload);
        if (termres) {
          notify('success', i18n.t('ACCOUNTCREATED'));
          dispatch(success());
          return true;
        } else {
          notify('error', i18n.t('ERROR'));
        }
      }
    } catch (err) {
      dispatch(failure(err.toString()));
      errorBlock(err);
    }
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

//function to send email for resetting password
function forgetpassword(email) {
  return async () => {
    try {
      const res = await userService.resetLUCPassword(email);
      if (res) {
        notify('success', i18n.t('RESETLINKSENT'));
        history.push('/login');
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}

//function to send email for resetting password
function verifyUser(user) {
  return async () => {
    try {
      const res = await userService.verifyLUCUser(user);
      if (res) {
        notify('success', i18n.t('SUCCESSFULLYVALIDATED'));
        setTimeout(() => {
          history.push('/login');
        }, 2000);
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}

//function for updating the user password
function updatePassword(payload) {
  return async () => {
    try {
      const res = await userService.updateLUCPassword(payload);
      if (res) {
        notify('success', i18n.t('SUCCESSFULLYVALIDATED'));
        setTimeout(() => {
          history.push('/login');
        }, 2000);
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}
function changePassword(payload) {
  return async () => {
    try {
      const res = await userService.changeLUCPassword(payload);
      if (res) {
        notify('success', i18n.t('PASSWORDSUCCESS'));
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}
function changeEmail(payload) {
  return async () => {
    try {
      const res = await userService.changeLUCEmail(payload);
      if (res) {
        notify('success', i18n.t('EMAILREQUESTINFO'));
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}

function verifyEmail(payload) {
  return async () => {
    try {
      const res = await userService.emailChangeVerification(payload);
      if (res) {
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}

function updateEmail(value) {
  return async () => {
    try {
      const payload = {
        email: value,
      };
      const res = await userService.updateEmail(payload);
      return res?.data?.data?.response;
    } catch (err) {
      errorBlock(err);
    }
  };
}

function adminMessage() {
  return async () => {
    try {
      const res = await userService.getAdminMessage();
      if (res.data.Item.message) {
        if (
          new Date(res.data.Item.expire_at).getTime() > new Date().getTime()
        ) {
          let d = new Date(res.data.Item.expire_at);
          cookies.set('admin_message', res.data.Item.message, {
            path: '/',
            expires: d,
            secure: true,
          });
        }
      } else {
        cookies.remove('admin_message', { path: '/' });
        cookies.remove('admin_message_visible', { path: '/' });
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}

function getCommunicationPreference() {
  return async () => {
    try {
      const res = await userService.getCommunicationPreference();
      if (res) {
        return res.data;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}
function setCommunicationPreference(payload) {
  return async () => {
    try {
      const res = await userService.setCommunicationPreference(payload);
      if (res) {
        return res;
      }
    } catch (err) {
      errorBlock(err);
    }
  };
}

function setStreamToolsConfig() {
  return async (dispatch) => {
    dispatch(showSpinner());
    // await dispatch(unitActions.getUnitsInfo());
    // const { units } = store.getState().unit;
    const { _units: units } = await unitService.getUserUnits();
    await unitService.getUnits();
    dispatch({ type: SET_ST_INITIAL_STATE, units: units ?? [] });
    dispatch(hideSpinner());
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}
