import React, { Component } from 'react';
import Select from 'react-select';
import filter from 'lodash/filter';
import usStateList from '../../data/UsState.json';
import canadaStateList from '../../data/CanadaState.json';
import { Fragment } from 'react';

export class UsStateDropdown extends Component {
  state = {
    usStates: [],
    canadaStates: [],
  };
  componentDidMount() {
    this.setState({
      usStates: usStateList,
      canadaStates: canadaStateList,
    });
  }
  render() {
    const { usStates, canadaStates } = this.state;
    const { handleStateChange, onblur, defaultSelectedValue, value, name } =
      this.props;
    function getState(item) {
      const result = filter(usStates, function (o) {
        return item.label === o.label;
      });
      return result[0];
    }
    function getCanadaState(item) {
      const result = filter(canadaStates, function (o) {
        return item.label === o.label;
      });
      return result[0];
    }
    return (
      <Fragment>
        {name === 'canadastate' ? (
          <Select
            className={
              value.label
                ? 'select-form-control success'
                : 'select-form-control'
            }
            options={canadaStates}
            onChange={handleStateChange}
            onBlur={onblur}
            value={
              defaultSelectedValue
                ? getCanadaState(defaultSelectedValue)
                : value
            }
          />
        ) : name === 'usstate' ? (
          <Select
            className={
              value.label
                ? 'select-form-control success'
                : 'select-form-control'
            }
            options={usStates}
            onChange={handleStateChange}
            onBlur={onblur}
            value={
              defaultSelectedValue ? getState(defaultSelectedValue) : value
            }
          />
        ) : null}
      </Fragment>
    );
  }
}
