/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { YTAction } from '../../../../actions';
import { errorBlock } from '../../../../api/errorBlock';
import './YouTubeAnalytics.css';

class YouTubeAnalytics extends Component {
  state = {
    ytChatOpen: false,
    chatUrl: '',
  };

  async componentDidMount() {
    await this.getPostAnalytics();
    await this.getTranscoderState();
  }
  getTranscoderState = async () => {
    const { match } = this.props;
    try {
      this.intervalT5 = setInterval(() => {
        this.getPostAnalytics();
      }, 10000);
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  };
  getPostAnalytics = async () => {
    const { match, ytBroadcastAnalytics, selectedDestination } = this.props;
    try {
      await ytBroadcastAnalytics(
        selectedDestination.streaming_destination.external_id,
        match,
      );
    } catch (e) {
      clearInterval(this.intervalT5);
      errorBlock(e);
    }
  };

  youtubeChat = () => {
    const { selectedDestination } = this.props;
    const youtubeChaturl = `https://www.youtube.com/live_chat?v=${selectedDestination.streaming_destination.external_id}&amp;embed_domain=${window.location.host}`;
    window.open(youtubeChaturl, "_blank");
    // this.setState({ ytChatOpen: true, chatUrl: youtubeChaturl });
  };

  closeytchat = () => {
    this.setState({ ytChatOpen: false, chatUrl: '' });
  };

  componentWillUnmount() {
    clearInterval(this.intervalT5);
  }

  render() {
    const { t, analytics, broadcast } = this.props;
    // function getIframe(url) {
    //     return <React.Fragment>
    //         <iframe allowFullScreen="" frameBorder="0" height="80%" src={url} width="60%"></iframe>
    //         <a className="close" href="" ng-click="closeytchat();"><i className="fa fa-times fa-3x" aria-hidden="true"></i></a>
    //     </React.Fragment>
    // }
    return (
      <React.Fragment>
        {!!analytics &&
        analytics.statistics &&
        (parseInt(analytics.statistics.viewCount) > 0 ||
          parseInt(analytics.statistics.likeCount) > 0 ||
          parseInt(analytics.statistics.dislikeCount) > 0) ? (
          <React.Fragment>
            <div className='emojis_blk p-2 border d-flex flex-column '>
              <div className='d-flex flex-row align-items-center justify-content-center'>
                {parseInt(analytics.statistics.viewCount) > 0 && (
                  <span className='emoji eicon'>
                    <i
                      className='fa fa-eye'
                      title={
                        broadcast.status.lifeCycleStatus === 'live'
                          ? t('WATCHINGNOW')
                          : t('VIEWS')
                      }
                    ></i>
                    <span>{analytics.statistics.viewCount}</span>
                  </span>
                )}
                {parseInt(analytics.statistics.likeCount) > 0 && (
                  <span className='emoji eicon'>
                    <i className='fa fa-thumbs-up' title={'like'}></i>
                    <span>{analytics.statistics.likeCount}</span>
                  </span>
                )}
                {parseInt(analytics.statistics.dislikeCount) > 0 && (
                  <span className='emoji eicon'>
                    <i className='fa fa-thumbs-down' title={'dislike'}></i>
                    <span>{analytics.statistics.dislikeCount}</span>
                  </span>
                )}

                {broadcast.status.lifeCycleStatus === 'live' &&
                  (broadcast.status.privacyStatus === 'public' ||
                    broadcast.status.privacyStatus === 'unlisted') && (
                    <div className='loadytchat'>
                      <span className='provider-label text-center'>
                        <button
                          className='btn btn-primary btn-ytpost btn-sm fbpost'
                          onClick={this.youtubeChat}
                        >
                          YouTube Live chat
                        </button>
                      </span>
                    </div>
                  )}
              </div>
            </div>
            {this.state.ytChatOpen && (
              <div className='youtubelivechat'>
                <div className='youtubechatframe'>
                  <iframe
                    allowFullScreen=''
                    frameBorder='0'
                    height='80%'
                    src={this.state.chatUrl}
                    width='60%'
                  ></iframe>
                  <button className='close' onClick={this.closeytchat}>
                    <i className='fa fa-times' aria-hidden='true'></i>
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className='emojis_blk p-2 border d-flex flex-column '>
            <div className='d-flex flex-row align-items-center justify-content-center'>
              <span className='analyticsNoData'>{t('NOVIEWAVAILABLE')}</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { broadcast } = state.yt.YT_data;
  const { analytics } = state.yt;
  const { selectedDestination } = state.destination;
  return {
    broadcast,
    analytics,
    selectedDestination,
  };
};
const mapDispatchToProps = {
  ytBroadcastAnalytics: YTAction.ytBroadcastAnalytics,
};
const ConnectedYouTubeAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(YouTubeAnalytics));
export { ConnectedYouTubeAnalytics as YouTubeAnalytics };
