import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { commonAction } from '../../actions';

class ProgressBar extends Component {
    render() {
        const { showProgressBar, progressBarInfo } = this.props;
        return (
            <>
                <div className={showProgressBar ? 'overlay' : null} >
                    <div style={{width:'100%'}}>
                    <Steps
                        current={progressBarInfo.current}
                        status={progressBarInfo?.status === 'error' ? 'error' : 'process'}
                        labelPlacement='vertical'
                        type='line'
                        style={{ backgroundColor: 'white', padding: '20px', margin:'auto'}}
                    >
                        {progressBarInfo.items.map((item, index) => {
                            return <Steps.Step key={index} title={item.title} icon={index === progressBarInfo.current ? !progressBarInfo.status && <LoadingOutlined/> : '' } />
                        })}
                    </Steps>
                        <div style={{ margin: '20px auto', width:'200px' }}>
                            <Button onClick={this.props.hideProgressBar} style={{width:'150px'}}> Close</Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {

    const { showProgressBar,progressBarInfo } = state.common;
    return {
        showProgressBar,
        progressBarInfo
    };
}
const mapDispatchToProps = {
    hideProgressBar : commonAction.hideProgressBar
}
const ConnectedProgressBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(ProgressBar));
export  { ConnectedProgressBar as ProgressBar };
