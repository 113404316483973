import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './ProfileSettings.css';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
// import { is4kProfile } from '../../utils/is4kprofile';
import aduioBitRateOptions from '../../data/aduioBitRateOptions.json';

class ProfileSettings extends Component {
  state = {
    menu: false,
    currentURL: '',
    customResolution: [
      { label: '360x240', value: '360x240' },
      { label: '384x288', value: '384x288' },
      { label: '640x480', value: '640x480' },
      { label: '720x480', value: '720x480' },
      { label: '720x576', value: '720x576' },
      { label: '768x432', value: '768x432' },
      { label: '768x576', value: '768x576' },
      { label: '1024x576', value: '1024x576' },
      { label: '1280x720', value: '1280x720' },
      { label: '1920x1080', value: '1920x1080' },
    ],
    customFrameRate: [
      { label: '12.48', value: '12.48' },
      { label: '12.5', value: '12.5' },
      { label: '14.98', value: '14.98' },
      { label: '15', value: '15' },
      { label: '23.97', value: '23.97' },
      { label: '24', value: '24' },
      { label: '24.97', value: '24.97' },
      { label: '25', value: '25' },
      { label: '29.97', value: '29.97' },
      { label: '30', value: '30' },
      { label: '50', value: '50' },
      { label: '59.94', value: '59.94' },
      { label: '60', value: '60' },
    ],
    aduioBitRateOptions: aduioBitRateOptions,
    selectedProfile: {},
  };

  componentDidMount() {
    const { unit, selectedProfile } = this.props;
    let pathURL = window.location.pathname.split('/')[3];
    pathURL = encodeURIComponent(
      decodeURIComponent(pathURL).replace(/\s+/g, ''),
    );
    this.setState({
      currentURL: pathURL,
      selectedProfile: selectedProfile,
    });
    if (
      (pathURL === 'SRT-OUT-Caller-Solo' ||
        pathURL === 'Generic' ||
        pathURL === 'GenericwithAuth') &&
      unit.product === 'LU_SOLO_PRO'
    ) {
      this.setState({
        customResolution: [
          ...this.state.customResolution,
          // { label: '1440p25/30', value: '1440p25/30' },
          // { label: '1440p50/60', value: '1440p50/60' },
          // { label: '2160p25/30', value: '2160p25/30' },
          // { label: '2160p50/60', value: '2160p50/60' },
          { label: '2560x1440', value: '2560x1440' },
          { label: '3840x2160', value: '3840x2160' },
        ],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selectedProfile) !==
      JSON.stringify(this.props.selectedProfile)
    ) {
      this.setState({
        selectedProfile: this.props.selectedProfile,
      });
    }
  }

  toggle = () => {
    this.setState({
      menu: !this.state.menu,
    });
  };

  // const codecOptions = {
  //   options: [
  //     { label: 'H264', value: 'h264' },
  //     { label: 'H265', value: 'h265' },
  //   ],
  // };

  render() {
    const {
      t,
      bitrateDisabled,
      formik,
      unit,
      isBitRateHigher,
      streamToolsUnitsList,
    } = this.props;
    const { menu, customResolution, customFrameRate, aduioBitRateOptions } =
      this.state;
    function checkForErrors(errors) {
      const arr = Object.keys(errors).map(function (key) {
        return [errors[key]];
      });
      if (arr.length) {
        formik.setFieldValue('overrideErrors', arr);
      }
    }
    function isFormValid() {
      formik.setFieldValue('overrideErrors', []);
    }
    return (
      <>
        {streamToolsUnitsList?.[unit.BOSSID]?.isStreamToolActive ? null : (
          <div className='row d-flex flex-column '>
            <Formik
              initialValues={{
                resolutionBox: formik.values.resolutionBox
                  ? formik.values.resolutionBox
                  : false,
                bitRateBox: formik.values.bitRateBox,
                frameRateBox: formik.values.frameRateBox
                  ? formik.values.frameRateBox
                  : false,
                codecBox: formik.values?.codec ? formik.values.codec : false,
                audioBitRateBox: formik.values?.audioBitRateBox,
                overrideResolution: this.props.formik.values.overrideResolution,
                // : {
                //     label: '360x240',
                //     value: '360x240',
                // },
                overrideBitrate: !!formik.values.overrideBitrate
                  ? formik.values.overrideBitrate
                  : 0,
                overrideFrame: formik.values.overrideFrame.value
                  ? formik.values.overrideFrame
                  : {
                      label: '12.48',
                      value: '12.48',
                    },
                codec: formik?.values?.codec?.value
                  ? formik?.values?.codec
                  : { label: 'H264', value: 'h264' },
                audioBitRate: formik.values.audioBitRate
                  ? formik.values.audioBitRate
                  : { label: '128kbps', value: '128000' },
              }}
              // (is4kProfile(formik.values.profile.value) || is4kProfile(this.props.formik.values.overrideResolution.value))
              validationSchema={Yup.object({
                resolutionBox: Yup.bool(),
                bitRateBox: Yup.bool(),
                frameRateBox: Yup.bool(),
                overrideResolution: Yup.object()
                  .shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                  .nullable(),
                overrideBitrate:
                  unit.product === 'LU_SOLO_PRO'
                    ? Yup.number().test(
                        'test-name',
                        t('PROVIDEVALUESOLOPRO'),
                        function (values) {
                          values > 6000
                            ? isBitRateHigher(true)
                            : isBitRateHigher(false);
                          if (values > 20000 || values < 250 || !!!values) {
                            checkForErrors({
                              overrideBitrate: 'invalid bitrate',
                            });
                            return false;
                          } else {
                            formik.setFieldValue('overrideBitrate', values);
                            isFormValid();
                            return true;
                          }
                        },
                      )
                    : Yup.number().test(
                        'test-name',
                        t('PROVIDEVALUE'),
                        function (values) {
                          if (values > 6000 || values < 250 || !!!values) {
                            checkForErrors({
                              overrideBitrate: 'invalid bitrate',
                            });
                            return false;
                          } else {
                            formik.setFieldValue('overrideBitrate', values);
                            isFormValid();
                            return true;
                          }
                        },
                      ),
                overrideFrame: Yup.object()
                  .shape({
                    label: Yup.string(),
                    value: Yup.string(),
                  })
                  .nullable(),
              })}
            >
              {(subformik) => {
                function emptyErrors() {
                  const arr1 = !!formik.values.overrideErrors.length
                    ? formik.values.overrideErrors[0]
                    : [];
                  // eslint-disable-next-line array-callback-return
                  arr1.filter((value, index) => {
                    if (value.toLowerCase().includes('bitrate')) {
                      delete arr1[index];
                    }
                  });
                  formik.values.overrideErrors = arr1;
                  subformik.setFieldTouched('overrideBitrate', false);
                }

                return (
                  <React.Fragment>
                    <div className='page-title allcaps'>
                      <h5 className='text-uppercase'>
                        {t('PROFILESETTINGADVANCE')}
                        <Link
                          to='#'
                          className={
                            'btn btn-default plus-minus ' +
                            (menu ? '' : 'collapsed')
                          }
                          onClick={() => {
                            this.toggle();
                            subformik.setFieldTouched(
                              'overrideResolution',
                              true,
                            );
                            // subformik.setFieldTouched('overrideBitrate', true);
                            subformik.setFieldTouched(
                              'overrideFramerate',
                              true,
                            );
                          }}
                        >
                          <span></span>
                        </Link>
                      </h5>
                    </div>
                    <div
                      className={
                        'profile-settings ' + (menu ? 'in' : 'collapse')
                      }
                      id='con'
                    >
                      <div className='d-flex flex-column '>
                        <div className='form-group d-flex flex-row align-items-center row'>
                          <div className='col-lg-2 col-sm-3 col-10'>
                            {t('OVERRIDERESOLUTION')}{' '}
                          </div>
                          <div className='col-lg-1 col-sm-1 col-2'>
                            <span className='customCheckBox profileCheckBox'>
                              <Input
                                name='resolutionBox'
                                // component='input'
                                type='checkbox'
                                checked={subformik.values.resolutionBox}
                                // eslint-disable-next-line no-unused-vars
                                onClick={(value) => {
                                  if (subformik.values.resolutionBox) {
                                    subformik.setFieldValue(
                                      'resolutionBox',
                                      false,
                                    );
                                    formik.setFieldValue(
                                      'resolutionBox',
                                      false,
                                    );
                                    // formik.setFieldValue('overrideResolution', []);
                                  } else {
                                    formik.setFieldValue('resolutionBox', true);
                                    formik.setFieldValue('overrideResolution', {
                                      label: '360x240',
                                      value: '360x240',
                                    });
                                    subformik.setFieldValue(
                                      'resolutionBox',
                                      true,
                                    );
                                  }
                                }}
                                onBlur={() => {
                                  subformik.setFieldTouched(
                                    'resolutionBox',
                                    true,
                                  );
                                  subformik.setFieldTouched(
                                    'overrideResolution',
                                    true,
                                  );
                                }}
                              />
                              <ErrorMessage
                                component='span'
                                name='resolutionBox'
                                className='col-sm-8 col-sm-push-4 error'
                              />
                              <span></span>
                            </span>
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12'>
                            <Select
                              name='overrideResolution'
                              value={subformik.values.overrideResolution}
                              onChange={(value) => {
                                subformik.setFieldValue(
                                  'overrideResolution',
                                  value,
                                );
                                subformik.values.overrideResolution = value;
                                formik.setFieldValue(
                                  'overrideResolution',
                                  value,
                                );
                              }}
                              options={customResolution}
                              isDisabled={!subformik.values.resolutionBox}
                              onBlur={() => {
                                subformik.setFieldTouched(
                                  'overrideResolution',
                                  true,
                                );
                              }}
                            />
                            <ErrorMessage
                              component='span'
                              name='overrideResolution'
                              className='col-sm-8 col-sm-push-4 error'
                            />
                          </div>
                        </div>

                        <div className='form-group d-flex flex-row align-items-center row'>
                          <div className='col-lg-2 col-sm-3 col-10'>
                            {t('BITRATE')}
                          </div>
                          <div className='col-lg-1 col-sm-1 col-2'>
                            <span className='customCheckBox profileCheckBox'>
                              <Input
                                name='bitRateBox'
                                component='input'
                                type='checkbox'
                                checked={subformik.values.bitRateBox}
                                disabled={bitrateDisabled}
                                // eslint-disable-next-line no-unused-vars
                                onClick={(value) => {
                                  if (subformik.values.bitRateBox) {
                                    subformik.setFieldValue(
                                      'bitRateBox',
                                      false,
                                    );
                                    formik.setFieldValue('bitRateBox', false);
                                    // emptyErrors();
                                    // subformik.values.overrideBitrate = 400;
                                    // formik.values.overrideBitrate = 400;
                                  } else {
                                    formik.setFieldValue('bitRateBox', true);
                                    subformik.setFieldValue('bitRateBox', true);
                                  }
                                }}
                                onBlur={() => {
                                  subformik.setFieldTouched('bitRateBox', true);
                                  subformik.setFieldTouched(
                                    'overrideBitrate',
                                    true,
                                  );
                                }}
                              />
                              <ErrorMessage
                                component='span'
                                name='bitRateBox'
                                className='col-sm-8 col-sm-push-4 error'
                              />
                              <span></span>
                            </span>
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12'>
                            <div className='input-group spinner bitrate'>
                              <div className='bitrate-input'>
                                <input
                                  className='form-control'
                                  name='overrideBitrate'
                                  autoComplete='off'
                                  type='number'
                                  disabled={
                                    bitrateDisabled ||
                                    !subformik.values.bitRateBox
                                  }
                                  value={subformik.values.overrideBitrate}
                                  // defaultValue={400}
                                  {...subformik.getFieldProps(
                                    'overrideBitrate',
                                  )}
                                  onChange={(valueNumber) => {
                                    subformik.setFieldValue(
                                      'overrideBitrate',
                                      valueNumber?.target?.value,
                                    );
                                  }}
                                />
                                <div className='input-group-btn-vertical'>
                                  <button
                                    className='btn btn-default upBtn'
                                    type='button'
                                    onClick={() => {
                                      subformik.setFieldTouched(
                                        'overrideBitrate',
                                        true,
                                      );
                                      subformik.setFieldValue(
                                        'overrideBitrate',
                                        subformik.values.overrideBitrate + 1,
                                      );
                                      formik.setFieldValue(
                                        'overrideBitrate',
                                        subformik.values.overrideBitrate + 1,
                                      );
                                    }}
                                    disabled={!subformik.values.bitRateBox}
                                  >
                                    <i className='fa fa-caret-up'></i>
                                  </button>
                                  <button
                                    className='btn btn-default downBtn'
                                    type='button'
                                    onClick={() => {
                                      subformik.setFieldTouched(
                                        'overrideBitrate',
                                        true,
                                      );
                                      subformik.setFieldValue(
                                        'overrideBitrate',
                                        subformik.values.overrideBitrate - 1,
                                      );
                                      formik.setFieldValue(
                                        'overrideBitrate',
                                        formik.values.overrideBitrate - 1,
                                      );
                                    }}
                                    disabled={!subformik.values.bitRateBox}
                                  >
                                    <i className='fa fa-caret-down'></i>
                                  </button>
                                </div>
                              </div>
                              <ErrorMessage
                                component='span'
                                name='overrideBitrate'
                                className='col-sm-8 col-sm-push-4 error'
                              />
                              {/* {(!(is4kProfile(formik.values.profile.value) || is4kProfile(subformik.values.overrideResolution.value)) && formik.values.overrideBitrate > 6000) && <span style={{ color: '#ed4747', fontSize: '13px' }}>{t('INVALIDBITRATE')}</span>}
                          {(!(is4kProfile(formik.values.profile.value) || is4kProfile(subformik.values.overrideResolution.value)) && formik.values.overrideBitrate > 6000) ? (this.props.invalidBitRate(true) || null) : this.props.invalidBitRate(false) || null} */}

                              {unit.product !== 'LU_SOLO_PRO' &&
                                formik.values.overrideBitrate > 6000 && (
                                  <span
                                    style={{
                                      color: '#ed4747',
                                      fontSize: '13px',
                                    }}
                                  >
                                    {t('INVALIDBITRATE')}
                                  </span>
                                )}
                              {unit.product !== 'LU_SOLO_PRO' &&
                              formik.values.overrideBitrate > 6000
                                ? this.props.invalidBitRate(true) || null
                                : this.props.invalidBitRate(false) || null}
                            </div>
                          </div>
                        </div>

                        <div className='form-group d-flex flex-row align-items-center row'>
                          <div className='col-lg-2 col-sm-3 col-10'>
                            {t('FRAMERATE')}{' '}
                          </div>
                          <div className='col-lg-1 col-sm-1 col-2'>
                            <div className='customCheckBox profileCheckBox'>
                              <Input
                                name='frameRateBox'
                                // component='input'
                                type='checkbox'
                                checked={subformik.values.frameRateBox}
                                // eslint-disable-next-line no-unused-vars
                                onClick={(value) => {
                                  if (subformik.values.frameRateBox) {
                                    subformik.setFieldValue(
                                      'frameRateBox',
                                      false,
                                    );
                                    formik.setFieldValue('frameRateBox', false);
                                    subformik.setFieldValue(
                                      'overrideBitrate',
                                      subformik.values.overrideBitrate,
                                    );
                                    // formik.setFieldValue('overrideFrame', []);
                                  } else {
                                    formik.setFieldValue('frameRateBox', true);
                                    formik.setFieldValue('overrideFrame', {
                                      label: '12.48',
                                      value: '12.48',
                                    });
                                    subformik.setFieldValue(
                                      'frameRateBox',
                                      true,
                                    );
                                  }
                                }}
                                onBlur={() => {
                                  subformik.setFieldTouched(
                                    'frameRateBox',
                                    true,
                                  );
                                  subformik.setFieldTouched(
                                    'overrideFrame',
                                    true,
                                  );
                                }}
                              />
                              <ErrorMessage
                                component='span'
                                name='frameRateBox'
                                className='col-sm-8 col-sm-push-4 error'
                              />
                              <span></span>
                            </div>
                          </div>
                          <div className='col-lg-4 col-sm-6 col-12'>
                            <Select
                              name='overrideFrame'
                              value={subformik.values.overrideFrame}
                              onChange={(value) => {
                                subformik.setFieldValue('overrideFrame', value);
                                subformik.values.overrideFrame = value;
                                formik.setFieldValue('overrideFrame', value);
                              }}
                              defaultValue={customFrameRate[0]}
                              options={customFrameRate}
                              isDisabled={!subformik.values.frameRateBox}
                              onBlur={() => {
                                subformik.setFieldTouched(
                                  'overrideFrame',
                                  true,
                                );
                              }}
                            />
                            <ErrorMessage
                              component='span'
                              name='overrideFrame'
                              className='col-sm-8 col-sm-push-4 error'
                            />
                          </div>
                        </div>
                        {unit.product === 'LU_SOLO_PRO' && (
                          <div>
                            <div className='form-group d-flex flex-row align-items-center row'>
                              <div className='col-lg-2 col-sm-3 col-auto'>
                                {t('AUDIOBITRATE')}
                              </div>
                              <div className='col-lg-1 col-sm-1 col-2'>
                                <span className='customCheckBox'>
                                  <Input
                                    type={'checkbox'}
                                    name='audioBitRateBox'
                                    value={subformik.values.audioBitRateBox}
                                    checked={subformik.values.audioBitRateBox}
                                    onClick={() => {
                                      if (subformik.values.audioBitRateBox) {
                                        subformik.setFieldValue(
                                          'audioBitRateBox',
                                          false,
                                        );
                                        formik.setFieldValue(
                                          'audioBitRateBox',
                                          false,
                                        );
                                        emptyErrors();
                                        subformik.values.audioBitRateBox =
                                          aduioBitRateOptions.options[0];
                                        formik.values.audioBitRateBox =
                                          aduioBitRateOptions.options[0];
                                      } else {
                                        formik.setFieldValue(
                                          'audioBitRateBox',
                                          true,
                                        );
                                        subformik.setFieldValue(
                                          'audioBitRateBox',
                                          true,
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      subformik.setFieldTouched(
                                        'audioBitRateBox',
                                        true,
                                      );
                                      subformik.setFieldTouched(
                                        'audioBitRate',
                                        true,
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component='span'
                                    name='audioBitRate'
                                    className='col-sm-8 col-sm-push-4 error'
                                  />
                                  <span></span>
                                </span>
                              </div>
                              <div className='col-lg-4 col-sm-6 col-12'>
                                <div className='input '>
                                  <Select
                                    name='audioBitRate'
                                    defaultValue={
                                      aduioBitRateOptions.options[0]
                                    }
                                    value={subformik.values.audioBitRate}
                                    isDisabled={
                                      !subformik.values.audioBitRateBox
                                    }
                                    onChange={(value) => {
                                      formik.setFieldValue(
                                        'audioBitRate',
                                        value,
                                      );
                                      formik.values.audioBitRate = value;
                                      subformik.setFieldValue(
                                        'audioBitRate',
                                        value,
                                      );
                                      subformik.values.audioBitRate = value;
                                    }}
                                    options={aduioBitRateOptions.options}
                                    onBlur={() => {
                                      formik.setFieldTouched(
                                        'audioBitRate',
                                        true,
                                      );
                                    }}
                                  />
                                </div>
                                <ErrorMessage
                                  component='span'
                                  name='audioBitRate'
                                  className='col-sm-8 col-sm-push-4 error'
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            </Formik>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { unit } = state.unit;
  const streamToolsUnitsList = state.streamTools;
  return {
    unit,
    streamToolsUnitsList,
  };
};

const mapDispatchToProps = {};

const ConnectedProfileSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ProfileSettings));
export { ConnectedProfileSettings as ProfileSettingsForNormalMode };
