import { fbService } from './editunit/fb.service';
const { REACT_APP_fbAppID } = process.env;
export function initFBSdk() {
  return new Promise(() => {
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: REACT_APP_fbAppID,
        status: true,
        cookie: true,
        channelUrl: 'views/channel.html',
        version: 'v14.0',
      });
      fbService.fbEventSubscribe();

      // auto authenticate with the api if already logged in with facebook
      fbService.fbLoginStatus();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/all.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}
