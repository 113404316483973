import { restreamConstants } from '../constants';

const initialState = {
  restreamLoading: true,
  restream: {
    auth: {},
    platforms: {},
    availablePlatforms: [],
    ingests: {},
    availableIngests: [],
    channel: { enabled: {}, disabled: {} },
    streamKey: {},
  },
  authIsLoading: true,
};
const {
  RE_USER_DETAILS_FAIL,
  RE_USER_DETAILS,
  RE_USER_DETAILS_REQ,
  RE_GET_PLATFORMS,
  RE_GET_PLATFORMS_FAIL,
  RE_GET_USERPLATFORMS,
  RE_GET_USERPLATFORMS_FAIL,
  RE_GET_ALLINGEST,
  RE_GET_ALLINGEST_FAIL,
  RE_GET_INGEST,
  RE_GET_INGEST_FAIL,
  RE_CHANNELS,
  RE_GET_STREAM_KEY,
  RE_GET_TOKEN_FAIL,
  RE_GET_TOKEN_REQ,
  RE_GET_STREAM_KEY_FAIL,
  RE_GET_TOKEN,
} = restreamConstants;

export function restream(state = initialState, payload) {
  switch (payload.type) {
    case RE_GET_TOKEN_REQ:
      return { ...state, authIsLoading: true };
    case RE_GET_TOKEN_FAIL:
      return { ...state, restream: { ...state.restream, auth: payload.error } };
    case RE_GET_TOKEN:
      return {
        ...state,
        restream: { ...state.restream, auth: payload.AuthData },
      };
    case RE_USER_DETAILS_REQ:
      return { ...state, restreamLoading: true };
    case RE_USER_DETAILS:
      return {
        ...state,
        restream: { ...state.restream, user: payload.userData },
      };
    case RE_USER_DETAILS_FAIL:
      return {
        ...state,
        restream: { ...state.restream, user: payload.error },
        restreamLoading: false,
      };
    case RE_GET_PLATFORMS_FAIL:
      return {
        ...state,
        restream: { ...state.restream, platforms: payload.error },
        restreamLoading: false,
      };
    case RE_GET_PLATFORMS:
      return {
        ...state,
        restream: { ...state.restream, platforms: payload.Data },
      };
    case RE_GET_USERPLATFORMS_FAIL:
      return {
        ...state,
        restream: { ...state.restream, availablePlatforms: payload.error },
        restreamLoading: false,
      };
    case RE_GET_USERPLATFORMS:
      return {
        ...state,
        restream: { ...state.restream, availablePlatforms: payload.Data },
      };
    case RE_GET_ALLINGEST_FAIL:
      return {
        ...state,
        restream: { ...state.restream, ingests: payload.error },
        restreamLoading: false,
      };
    case RE_GET_ALLINGEST:
      return {
        ...state,
        restream: { ...state.restream, ingests: payload.Data },
      };
    case RE_GET_INGEST_FAIL:
      return {
        ...state,
        restream: { ...state.restream, availableIngests: payload.error },
        restreamLoading: false,
      };
    case RE_GET_INGEST:
      return {
        ...state,
        restream: { ...state.restream, availableIngests: payload.Data },
      };
    case RE_CHANNELS:
      return {
        ...state,
        restream: {
          ...state.restream,
          channel: {
            disabled: payload.Data.disabled,
            enabled: payload.Data.enabled,
          },
        },
      };
    case RE_GET_STREAM_KEY_FAIL:
      return {
        ...state,
        restream: { ...state.restream, streamKey: payload.error },
        restreamLoading: false,
      };
    case RE_GET_STREAM_KEY:
      return {
        ...state,
        restream: { ...state.restream, streamKey: payload.Data },
        restreamLoading: false,
      };
    default:
      return state;
  }
}
