import React from 'react';
import { connect } from 'react-redux';
import { LoginForm } from './LoginForm';
import { userActions } from '../../actions';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
//import i18n from '../../i18n';

class Login extends React.Component {
  constructor(props) {
    super(props);
    // reset login status
    //this.props.dispatch(userActions.logout());
    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidMount() {
    if (getLocalStorageItem('access_token')) {
      this.props.history.push('/dashboard/units');
    } else {
      this.props.initalState();
    }
  }

  onSubmitHandler = (values) => {
    if (values.email && values.password) {
      this.props.login(values.email.toLowerCase(), values.password);
    }
  };

  render() {
    const { submitted, t, history } = this.props;
    const { username, password } = this.state;
    return (
      <div>
        <LoginForm
          t={t}
          history={history}
          username={username}
          password={password}
          submitted={submitted}
          onSubmitHandler={this.onSubmitHandler}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { submitted } = state.authentication;
  return { submitted };
}

const mapDispatchToProps = {
  login: userActions.login,
  initalState: userActions.initialState,
};

const ConnectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Login));
export { ConnectedLoginPage as Login };
