import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const loadState = () => {
  try {
    const serializedState = cookies.get('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);

    cookies.set('state', serializedState, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      secure: true,
    });
  } catch (err) {
    // Ignore write errors.
  }
};
