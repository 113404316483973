/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  FBAction,
  linkedinAction,
  periscopetwitterActions,
  YTAction,
  destinationActions,
  twitchAction,
  editUnitAction,
  unitActions,
  liveStreamActions,
} from '../../actions';
// import { fbService } from '../../services';
import { StreamToolDestinationsView } from './StreamToolDestinationsView';
import './StreamToolDestinations.css';
import { errorBlock } from '../../api/errorBlock';
import { wowzaPostStatus } from '../../api/wowza.api';
import { notify } from '../CommonNotification/CommonNotification';
import i18n from 'i18next';
import { getUnitWithStatus } from '../../selectors/unit_selectors';
import utf8 from 'utf8';
import { Modalpopup } from '../Modalpopup';
import Cookies from 'universal-cookie';
import { savedDestinationsService } from '../../services';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

class StreamToolDestinations extends Component {
  static intervals = {};

  state = {
    showModal: false,
    modalDetails: null,
    transcoderState: {
      postState: '',
      postId: '',
      postDescription: '',
    },
    loginFlags: {
      fbLoggedIn: false,
      periscopeLoggedIn: false,
      twitchLoggedIn: false,
      vimeoLoggedIn: false,
      elLoggedIn: false,
      bcLoggedIn: false,
      reLoggedIn: false,
      ytLoggedIn: false,
      linkedinLoggedIn: true,
      sbLoggedIn: false,
    },
    selectedAwsDestination: {},
  };

  async componentDidMount() {
    const {
      selectedDes: {
        streaming_destination: { streaming_provider },
      },
      match,
      getAwsDest,
      selectedDes,
    } = this.props;
    try {
      this.setState({
        selectedAwsDestination: await savedDestinationsService.getAwsDest(
          selectedDes.streaming_destination.external_id,
        ),
      });
      if (streaming_provider === 'wowza') {
        this.getPostStatus(streaming_provider);
        this.getTranscoderState(streaming_provider);
      }
      if (streaming_provider === 'Periscope and Twitter') {
        if (cookies.get(`${cookies.get('username')}_twitterLogin`)) {
          this.isLoggedIn(streaming_provider, true);
          this.getPostStatus(streaming_provider);
          this.getTranscoderState(streaming_provider);
        } else {
          this.isLoggedIn(streaming_provider, false);
        }
      }
      if (streaming_provider === 'Facebook') {
        if (getLocalStorageItem('FB_access_token')) {
          this.isLoggedIn(streaming_provider, true);
          this.getPostStatus(streaming_provider);
          this.getTranscoderState(streaming_provider);
        } else {
          this.isLoggedIn(streaming_provider, false);
        }
      }
      if (streaming_provider === 'LinkedIn') {
        if (cookies.get('LI_access_token')) {
          this.isLoggedIn(streaming_provider, true);
          this.getPostStatus(streaming_provider);
        } else {
          this.isLoggedIn(streaming_provider, false);
        }
      }
      if (streaming_provider.toLowerCase() === 'youtube') {
        if (cookies.get('YT_access_token')) {
          this.isLoggedIn(streaming_provider, true);
          this.getPostStatus(streaming_provider);
          this.getTranscoderState(streaming_provider);
        } else {
          this.isLoggedIn(streaming_provider, false);
        }
      }
      if (streaming_provider === 'Twitch') {
        const v1 = !!cookies.get('twitch_access_token')
          ? this.isLoggedIn(streaming_provider, true)
          : this.isLoggedIn(streaming_provider, false);
        !!cookies.get('twitch_access_token') &&
          this.twitchChannelName(match.params.bossId);
      }
      if (streaming_provider === 'Boxcast') {
        const v1 = !!cookies.get('boxcast_access_token')
          ? this.isLoggedIn(streaming_provider, true)
          : this.isLoggedIn(streaming_provider, false);
      }
      if (streaming_provider === 'Easy Live') {
        const v1 = !!cookies.get('el_access_token')
          ? this.isLoggedIn(streaming_provider, true)
          : this.isLoggedIn(streaming_provider, false);
      }
      if (streaming_provider === 'Vimeo') {
        this.checkVimeoPostUrl();
        const v1 = !!cookies.get('vimeo_access_token')
          ? this.isLoggedIn(streaming_provider, true)
          : this.isLoggedIn(streaming_provider, false);
      }
      if (streaming_provider === 'Restream.io') {
        const v1 = !!cookies.get('restream_access_token')
          ? this.isLoggedIn(streaming_provider, true)
          : this.isLoggedIn(streaming_provider, false);
      }
      if (streaming_provider === 'Switchboard Live') {
        const v1 = !!cookies.get('SB_access_token')
          ? this.isLoggedIn(streaming_provider, true)
          : this.isLoggedIn(streaming_provider, false);
      }
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  }
  checkVimeoPostUrl = () => {
    const { unit } = this.props;
    if (
      !!cookies.get(`vimeoPostUrl_${unit.BOSSID}`) &&
      cookies.get(`vimeoPostUrl_${unit.BOSSID}`).length
    ) {
      this.setState({
        ...this.state,
        transcoderState: {
          ...this.state.transcoderState,
          postState: 'Live',
          postId: cookies.get(`vimeoPostUrl_${unit.BOSSID}`),
        },
      });
    } else if (!!!cookies.get(`vimeoPostUrl_${unit.BOSSID}`)) {
      this.setState({
        ...this.state,
        transcoderState: {
          ...this.state.transcoderState,
          postState: 'Not Started',
        },
      });
    }
  };
  twitchChannelName = (bossId) => {
    const { getTwitchUser, match } = this.props;
    try {
      getTwitchUser(bossId, false);
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  };
  utfDecode = (data) => {
    try {
      return utf8.decode(data);
    } catch (e) {
      return data;
    }
  };
  ensureWowzaToken = () => {
    const { ovpCredentials, match } = this.props;
    let parsed_ovp_cred = {};
    if (ovpCredentials !== '') {
      parsed_ovp_cred = JSON.parse(ovpCredentials);
      return parsed_ovp_cred[`${match.params.bossId}_wowza`];
    } else {
      return (parsed_ovp_cred[`${match.params.bossId}_wowza`] = '');
    }
  };

  handleClick = (data) => {
    let streamtoolsDetailsForThisUnit =
      this.props.streamToolsUnitList?.[data.bossId];
    if (
      this.props.unit.status === 'streaming' ||
      (streamtoolsDetailsForThisUnit.isStreamToolActive &&
        streamtoolsDetailsForThisUnit.isStreamtoolsStreaming)
    ) {
      notify('info', i18n.t('NODELETEDESTWHILESTREAMING'));
    } else {
      this.setState({
        showModal: true,
        modalDetails: {
          destID: data?.destId,
          isOpen: true,
          classModal: '',
          modalHeader: data.modalHeader,
          modalBody: data.modalBody,
          toggle: this.cancel,
          primaryBtnText: data.primaryBtnText,
          secondaryBtnText: data.secondaryBtnText,
          primaryBtnClick: this.delDest,
          secondaryBtnClick: this.cancel,
        },
      });
    }
  };

  delDest = () => {
    this.setState(
      {
        modalDetails: {
          ...this.state.modalDetails,
          isOpen: false,
        },
      },
      () => {
        this.delConfirm();
      },
    );
  };

  delConfirm = async () => {
    const {
      deleteStreamToolDestination,
      // getStreamToolDestination,
      // handleRemoveDestinationLoading,
      deleteDestination,
      selectedDestination,
      refreshStreamToolsDestination,
      setStreamToolsDestinations,
      streamToolsUnitList,
    } = this.props;
    const { destID } = this.state.modalDetails;
    const { bossId } = this.props.match.params;

    const selectDestId = selectedDestination?.id;
    try {
      // handleRemoveDestinationLoading(false);

      const streamtoolDestinations = [
        ...streamToolsUnitList?.[bossId]?.selectedDestinations,
      ];
      const findDestIndex = streamtoolDestinations?.findIndex(
        (e) => e.id === destID?.id,
      );

      if (findDestIndex !== -1) {
        streamtoolDestinations.splice(findDestIndex, 1);
      }
      setStreamToolsDestinations(bossId, streamtoolDestinations);

      await deleteStreamToolDestination(destID?.id);
      await refreshStreamToolsDestination();
      if (
        destID?.streaming_destination?.streaming_destination_outputs[0]
          ?.stream_name ===
        selectedDestination?.streaming_destination
          ?.streaming_destination_outputs[0]?.stream_name
      ) {
        await deleteDestination(selectDestId, bossId);
      }
      // handleRemoveDestinationLoading(false);
    } catch (e) {
      errorBlock(e);
    }
  };

  cancel = () => {
    this.setState({
      modalDetails: {
        isOpen: false,
      },
    });
  };

  async componentDidUpdate(prevProps) {
    const {
      selectedDes: {
        streaming_destination: { streaming_provider },
      },
      match,
      linkedin,
      units,
      selectedDes,
    } = this.props;
    const { selectedAwsDestination } = this.state;
    try {
      // if (
      //   streaming_provider.toLowerCase() === 'youtube' &&
      //   cookies.get('YT_access_token') &&
      //   selectedAwsDestination[0].startAsPreview !== true
      // ) {
      //   if (
      //     this.state.transcoderState.postState.toLowerCase() === 'teststarting'
      //   )
      //     await this.ytMakePostLive();
      // }
      if (
        prevProps.selectedDes.streaming_destination.streaming_provider !==
        this.props.selectedDes.streaming_destination.streaming_provider
      ) {
        this.killIntervals();

        if (streaming_provider === 'wowza') {
          this.getPostStatus(streaming_provider);
          this.getTranscoderState(streaming_provider);
        }
        if (streaming_provider === 'Periscope and Twitter') {
          if (cookies.get('periscope_access_token')) {
            this.isLoggedIn(streaming_provider, true);
            this.getPostStatus(streaming_provider);
            this.getTranscoderState(streaming_provider);
          } else {
            this.isLoggedIn(streaming_provider, false);
          }
        }
        if (streaming_provider === 'Facebook') {
          if (getLocalStorageItem('FB_access_token')) {
            this.isLoggedIn(streaming_provider, true);
            this.getPostStatus(streaming_provider);
            this.getTranscoderState(streaming_provider);
          } else {
            this.isLoggedIn(streaming_provider, false);
          }
        }
        if (streaming_provider === 'LinkedIn') {
          if (cookies.get('LI_access_token')) {
            this.isLoggedIn(streaming_provider, true);
            this.getPostStatus(streaming_provider);
          } else {
            this.isLoggedIn(streaming_provider, false);
          }
        }
        if (streaming_provider.toLowerCase() === 'youtube') {
          if (cookies.get('YT_access_token')) {
            this.isLoggedIn(streaming_provider, true);
            this.getPostStatus(streaming_provider);
            this.getTranscoderState(streaming_provider);
          } else {
            this.isLoggedIn(streaming_provider, false);
          }
        }
        if (streaming_provider === 'Twitch') {
          const v1 = !!cookies.get('twitch_access_token')
            ? this.isLoggedIn(streaming_provider, true)
            : this.isLoggedIn(streaming_provider, false);
          //!!cookies.get('twitch_access_token') && this.twitchChannelName(match.params.bossId);
        }
        if (streaming_provider === 'Boxcast') {
          const v1 = !!cookies.get('boxcast_access_token')
            ? this.isLoggedIn(streaming_provider, true)
            : this.isLoggedIn(streaming_provider, false);
        }
        if (streaming_provider === 'Easy Live') {
          const v1 = !!cookies.get('el_access_token')
            ? this.isLoggedIn(streaming_provider, true)
            : this.isLoggedIn(streaming_provider, false);
        }
        if (streaming_provider === 'Vimeo') {
          this.checkVimeoPostUrl();
          const v1 = !!cookies.get('vimeo_access_token')
            ? this.isLoggedIn(streaming_provider, true)
            : this.isLoggedIn(streaming_provider, false);
        }
        if (streaming_provider === 'Restream.io') {
          const v1 = !!cookies.get('restream_access_token')
            ? this.isLoggedIn(streaming_provider, true)
            : this.isLoggedIn(streaming_provider, false);
        }
      }
      if (
        JSON.stringify(prevProps.fbLoginData) !==
        JSON.stringify(this.props.fbLoginData)
      ) {
        if (streaming_provider === 'Facebook') {
          this.isLoggedIn(streaming_provider, true);
          setTimeout(() => {
            this.getTranscoderState(streaming_provider);
          }, 1500);
        }
      }
      if (
        JSON.stringify(prevProps.linkedin.create) !==
        JSON.stringify(linkedin.create)
      ) {
        const postUrl = linkedin.create[match.params.bossId];
        if (!!postUrl && streaming_provider === 'LinkedIn') {
          const selectedUnit = units.filter(
            (value) => value.BOSSID === match.params.bossId,
          );
          const unitStatus = selectedUnit[0].status;
          if (unitStatus === 'streaming') {
            this.setState({
              ...this.state,
              transcoderState: {
                ...this.state.transcoderState,
                postId: postUrl,
              },
            });
          }
        }
      }
      /*if (JSON.stringify(prevProps.vimeo.eventStatus) !== JSON.stringify(vimeo.eventStatus)) {
        if(!!vimeo.eventStatus){
          let postUrl = '';
          if(!!vimeo.eventStatus.link && vimeo.eventStatus.hasOwnProperty('link')){
            postUrl = vimeo.eventStatus.link;
          }
          if ((vimeo.eventStatus.duration === 0) && postUrl.length && streaming_provider === "Vimeo") {
            this.setState({
              ...this.state,
              transcoderState: {
                ...this.state.transcoderState,
                postId: postUrl,
                postState: "Live"
              },
            });

          }
        }

      }*/
      if (prevProps.linkedin.postStatus.status !== linkedin.postStatus.status) {
        const selectedUnit = units.filter(
          (value) => value.BOSSID === match.params.bossId,
        );
        const unitStatus = selectedUnit[0].status;
        const linkedinStatus = linkedin.postStatus.status;
        if (
          linkedinStatus.toLowerCase() === 'processing' &&
          unitStatus === 'connected'
        ) {
          this.setState({
            ...this.state,
            transcoderState: {
              ...this.state.transcoderState,
              postState: 'Not Started',
            },
          });
        } else if (linkedinStatus.toLowerCase() === 'completed') {
          this.setState({
            ...this.state,
            transcoderState: {
              ...this.state.transcoderState,
              postState: 'Completed',
            },
          });
        } else if (
          linkedinStatus.toLowerCase() === 'available' &&
          unitStatus === 'streaming'
        ) {
          this.setState({
            ...this.state,
            transcoderState: {
              ...this.state.transcoderState,
              postState: 'Live',
            },
          });
        } else if (linkedinStatus.toLowerCase() === 'deleted') {
          this.setState({
            ...this.state,
            transcoderState: {
              ...this.state.transcoderState,
              postState: linkedinStatus,
            },
          });
        } else if (linkedinStatus.toLowerCase() === 'incomplete') {
          this.setState({
            ...this.state,
            transcoderState: {
              ...this.state.transcoderState,
              postState: 'deleted',
            },
          });
        } else if (linkedinStatus.toLowerCase() === 'session_expired') {
          this.isLoggedIn('LinkedIn', false);
        }
      }
      if (
        JSON.stringify(prevProps.ytLoginData) !==
        JSON.stringify(this.props.ytLoginData)
      ) {
        if (streaming_provider.toLowerCase() === 'youtube') {
          this.isLoggedIn(streaming_provider, true);
          setTimeout(() => {
            this.getPostStatus(streaming_provider);
          }, 1500);
        }
      }
      if (
        JSON.stringify(prevProps.ptbroadcast) !==
        JSON.stringify(this.props.ptbroadcast)
      ) {
        if (
          streaming_provider.toLowerCase() === 'periscope and twitter' &&
          this.props.ptbroadcast &&
          this.props.ptbroadcast.id
        ) {
          this.isLoggedIn(streaming_provider, true);
          setTimeout(() => {
            this.getPostStatus(streaming_provider);
          }, 1500);
        } else {
          this.isLoggedIn(streaming_provider, false);
        }
      }
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  }

  getTranscoderState = (provider) => {
    const { match, currentDestinationCount } = this.props;
    try {
      if (provider === 'wowza') {
        clearInterval(
          StreamToolDestinations.intervals[currentDestinationCount],
        );
        StreamToolDestinations.intervals[currentDestinationCount] = setInterval(
          () => {
            this.getPostStatus(provider);
          },
          10000,
        );
        // storeIntervals(this.wowzaInterval)
      }
      if (provider === 'Periscope and Twitter') {
        clearInterval(
          StreamToolDestinations.intervals[currentDestinationCount],
        );
        StreamToolDestinations.intervals[currentDestinationCount] = setInterval(
          () => {
            this.getPostStatus(provider);
          },
          10000,
        );
        // storeIntervals(this.twitterInterval)
      }
      if (provider === 'Facebook') {
        clearInterval(
          StreamToolDestinations.intervals[currentDestinationCount],
        );
        StreamToolDestinations.intervals[currentDestinationCount] = setInterval(
          () => {
            this.getPostStatus(provider);
          },
          10000,
        );
        // storeIntervals(this.FBInterval)
      }
      if (provider.toLowerCase() === 'youtube') {
        clearInterval(
          StreamToolDestinations.intervals[currentDestinationCount],
        );
        StreamToolDestinations.intervals[currentDestinationCount] = setInterval(
          () => {
            this.getPostStatus(provider);
          },
          10000,
        );
        // storeIntervals(this.YTInterval)
      }
    } catch (e) {
      errorBlock(e, match.params.bossId);
    }
  };

  getPostStatus = async (provider) => {
    const {
      fbGetLiveVideoStatus,
      LIPostStatus,
      getPTBroadcastStatus,
      match,
      units,
      getYTEventDetails,
      selectedDes,
    } = this.props;
    //const userInfo = await fbService.userInfo();
    //const { ovpCredentials } = userInfo;
    //const ovpData = JSON.parse(ovpCredentials);

    try {
      switch (provider.toLowerCase()) {
        case 'facebook':
          // const selectedMode = ovpData[match.params.bossId].selected_mode;
          const data = await fbGetLiveVideoStatus(
            selectedDes.streaming_destination.external_id,
            match.params.bossId,
            this.state.selectedAwsDestination[0]?.fb_page_id,
          );
          data.status.toLowerCase() === 'unpublished' ||
          data.status.toLowerCase() === 'scheduled_unpublished'
            ? this.setState({
                ...this.state,
                transcoderState: {
                  postId: this.state.transcoderState.postId,
                  postState: 'preview',
                  postDescription: data.description,
                },
              })
            : this.setState({
                ...this.state,
                transcoderState: {
                  postId: this.state.transcoderState.postId,
                  postState: data.status,
                  postDescription: data.description,
                },
              });
          const t1 =
            data.status.toLowerCase() === 'live'
              ? this.setState({
                  ...this.state,
                  transcoderState: {
                    postState: this.state.transcoderState.postState,
                    postId: data.video.id,
                    postDescription: data.description,
                  },
                })
              : null;
          break;
        case 'linkedin':
          this.setState({
            ...this.state,
            transcoderState: {
              ...this.state.transcoderState,
              postState: 'Not Started',
            },
          });
          const l1 = await LIPostStatus(
            selectedDes.streaming_destination.external_id,
            match,
          );
          const selectedUnit = units.filter(
            (value) => value.BOSSID === match.params.bossId,
          );
          const unitStatus = selectedUnit[0].status;
          if (
            l1.status.toLowerCase() === 'processing' &&
            unitStatus === 'connected'
          ) {
            this.setState({
              ...this.state,
              transcoderState: {
                ...this.state.transcoderState,
                postState: 'Not Started',
              },
            });
          } else if (
            l1.status.toLowerCase() === 'available' &&
            unitStatus === 'connected'
          ) {
            this.setState({
              ...this.state,
              transcoderState: {
                ...this.state.transcoderState,
                postState: 'Completed',
              },
            });
          } else if (
            l1.status.toLowerCase() === 'available' &&
            unitStatus === 'streaming'
          ) {
            this.setState({
              ...this.state,
              transcoderState: {
                ...this.state.transcoderState,
                postState: 'Live',
              },
            });
          } else if (l1.status.toLowerCase() === 'incomplete') {
            this.setState({
              ...this.state,
              transcoderState: {
                ...this.state.transcoderState,
                postState: 'deleted',
              },
            });
          }
          break;
        case 'wowza':
          let wowzaToken = this.ensureWowzaToken();
          if (wowzaToken !== '') {
            const v1 = await wowzaPostStatus(wowzaToken);
            let d = new Date();
            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
            cookies.set('wowza_long_token', wowzaToken, {
              path: '/',
              expires: d,
              secure: true,
            });
            this.setState({
              ...this.state,
              transcoderState: {
                postId: this.state.transcoderState.postId,
                postState: v1.data.state,
              },
            });
          }
          break;
        case 'periscope and twitter':
          // const v2 = await getPTBroadcastStatus(
          //   selectedDes.streaming_destination.external_id,
          // );
          // this.setState({
          //   ...this.state,
          //   transcoderState: {
          //     postId: this.state.transcoderState.postId,
          //     postState: v2.state,
          //   },
          // });
          break;
        case 'youtube':
          const ytdata = await getYTEventDetails(
            selectedDes.streaming_destination.external_id,
            match,
          );
          (ytdata.status.lifeCycleStatus.toLowerCase() === 'testing' ||
            ytdata.status.lifeCycleStatus.toLowerCase() === 'ready') &&
          !ytdata.snippet.isDefaultBroadcast
            ? this.setState({
                ...this.state,
                transcoderState: {
                  postId: this.state.transcoderState.postId,
                  postState: 'preview',
                  postDescription: ytdata.snippet.description,
                },
              })
            : this.setState({
                ...this.state,
                transcoderState: {
                  postId: this.state.transcoderState.postId,
                  postState: ytdata.status.lifeCycleStatus,
                  postDescription: ytdata.snippet.description,
                },
              });
          const t2 =
            ytdata.status.lifeCycleStatus.toLowerCase() !== 'deleted'
              ? this.setState({
                  ...this.state,
                  transcoderState: {
                    postState: this.state.transcoderState.postState,
                    postId: ytdata.id,
                    postDescription: ytdata.snippet.description,
                  },
                })
              : null;
          break;
        default:
      }
    } catch (e) {
      this.killIntervals();
      if (provider.toLowerCase() === 'linkedin') {
        if (
          e &&
          !!e?.response &&
          (e?.response?.status === 403 || e?.response?.status === 401)
        ) {
          this.isLoggedIn(provider, false);
        }
      }
      //errorBlock(e,match.params.bossId); //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
      if (provider.toLowerCase() === 'facebook') {
        if (
          !!e.response &&
          !!e.response.data &&
          e.response.data.error.type === 'OAuthException' &&
          e.response.config.url
            .toLowerCase()
            .includes('https://graph.facebook.com')
        ) {
          this.isLoggedIn(provider, false);
        }
      }
      if (provider.toLowerCase() === 'facebook') {
        if (e.status === 'DELETED') {
          this.setState({
            ...this.state,
            transcoderState: {
              postState: e.status,
              postId: '',
              postDescription: '',
            },
          });
        }
      }
      if (provider.toLowerCase() === 'youtube') {
        if (
          !!e.response &&
          !!e.response.data &&
          e.response.data.error.status === 'UNAUTHENTICATED' &&
          e.response.config.url
            .toLowerCase()
            .includes('https://content.googleapis.com')
        ) {
          this.isLoggedIn(provider, false);
        }
      }
    }
  };

  fbMakePostLive = async () => {
    const {
      selectedDes: {
        streaming_destination: { streaming_provider },
      },
      fbGetLiveVideoStatus,
      fbUpdateLiveVideoStatus,
      match,
      units,
      selectedDes,
    } = this.props;
    try {
      if (streaming_provider.toLowerCase() === 'facebook') {
        const data = await fbGetLiveVideoStatus(
          selectedDes.streaming_destination.external_id,
          match.params.bossId,
          this.state.selectedAwsDestination[0]?.fb_page_id,
        );
        const selectedUnit = units.filter(
          (value) => value.BOSSID === match.params.bossId,
        );
        if (!!selectedUnit[0].status) {
          if (
            selectedUnit[0].status === 'streaming' &&
            (data.status === 'UNPUBLISHED' ||
              data.status === 'SCHEDULED_UNPUBLISHED')
          ) {
            const payload =
              data.status === 'UNPUBLISHED'
                ? { status: 'LIVE_NOW' }
                : { status: 'SCHEDULED_LIVE' };
            await fbUpdateLiveVideoStatus(
              selectedDes.streaming_destination.external_id,
              payload,
              match,
              this.state.selectedAwsDestination[0]?.fb_page_id,
            );
            notify('success', i18n.t('MODESUCCESSFULLYCHANGED'));
          } else {
            notify('info', i18n.t(`STARTUNITREQUEST`));
          }
        }
      }
    } catch (e) {
      // errorBlock(e, match.params.bossId); //errorBlock commented because toaster was showing multiple error. 1 from failed API call and other from here
      this.isLoggedIn(streaming_provider, false);
    }
  };

  ytMakePostLive = async () => {
    const {
      selectedDes: {
        streaming_destination: { streaming_provider },
      },
      getYTEventDetails,
      ytBroadcastTransition,
      match,
      units,
      selectedDes,
    } = this.props;
    try {
      if (streaming_provider.toLowerCase() === 'youtube') {
        const ytdata = await getYTEventDetails(
          selectedDes.streaming_destination.external_id,
          match,
        );
        if (
          ytdata.status.lifeCycleStatus === 'ready' &&
          this.props.unit.status !== 'streaming'
        ) {
          notify('info', <b>{i18n.t('STARTUNITREQUEST')}</b>);
        } else if (!ytdata.snippet.isDefaultBroadcast) {
          const selectedUnit = units.filter(
            (value) => value.BOSSID === match.params.bossId,
          );
          if (!!selectedUnit[0].status) {
            if (
              selectedUnit[0].status === 'streaming' &&
              (ytdata.status.lifeCycleStatus === 'ready' ||
                ytdata.status.lifeCycleStatus === 'testing')
            ) {
              await ytBroadcastTransition(
                selectedDes.streaming_destination.external_id,
                'live',
                match.params.bossId,
              );
              notify('success', i18n.t('MODESUCCESSFULLYCHANGED'));
            } else if (ytdata.status.lifeCycleStatus === 'complete') {
              notify('success', i18n.t(`STREAMCOMPLETE`));
            } else if (ytdata.status.lifeCycleStatus === 'deleted') {
              notify('success', i18n.t(`BROADCASTNOTEXIST`));
            }
            // else {
            //   notify('success', i18n.t(`MODECANTBECHANGED`));
            // }
          }
        }
      }
    } catch (e) {
      errorBlock(e, match.params.bossId);
      this.isLoggedIn(streaming_provider, false);
    }
  };
  isLoggedIn = (provider, isLoggedIn) => {
    let v1;
    switch (provider.toLowerCase()) {
      case 'facebook':
        v1 = isLoggedIn
          ? this.setState({
              loginFlags: {
                ...this.state.loginFlags,
                fbLoggedIn: isLoggedIn,
              },
            })
          : this.setState({
              loginFlags: { ...this.state.loginFlags, fbLoggedIn: isLoggedIn },
            });
        break;
      case 'linkedin':
        this.setState({
          loginFlags: {
            ...this.state.loginFlags,
            linkedinLoggedIn: isLoggedIn,
          },
        });
        if (!isLoggedIn) {
          cookies.remove('LI_access_token', { path: '/' });
          // delete cookies.get('LI_access_token');
          cookies.set('LI_post_profile', true, {
            path: '/',
            maxAge: 60 * 60 * 24 * 365,
            secure: true,
          });
        }
        break;
      case 'periscope and twitter':
        this.setState({
          loginFlags: {
            ...this.state.loginFlags,
            periscopeLoggedIn: isLoggedIn,
          },
        });
        if (!isLoggedIn) {
          cookies.remove(`${cookies.get('username')}_twitterLogin`, {
            path: '/',
          });
          cookies.remove('periscope_access_token', {
            path: '/',
          });
          cookies.remove('periscope_refresh_token', {
            path: '/',
          });
          // delete cookies.get(`${cookies.get('username')}_twitterLogin`);
          // delete cookies.get('periscope_access_token');
          // delete cookies.get('periscope_refresh_token');
        }
        break;
      case 'twitch':
        this.setState({
          loginFlags: { ...this.state.loginFlags, twitchLoggedIn: isLoggedIn },
        });
        break;
      case 'switchboard live':
        this.setState({
          loginFlags: { ...this.state.loginFlags, sbLoggedIn: isLoggedIn },
        });
        break;
      case 'restream.io':
        v1 = isLoggedIn
          ? this.setState({
              loginFlags: {
                ...this.state.loginFlags,
                reLoggedIn: isLoggedIn,
              },
            })
          : this.setState({
              loginFlags: { ...this.state.loginFlags, reLoggedIn: isLoggedIn },
            });
        break;
      case 'vimeo':
        v1 = isLoggedIn
          ? this.setState({
              loginFlags: {
                ...this.state.loginFlags,
                vimeoLoggedIn: isLoggedIn,
              },
            })
          : this.setState({
              loginFlags: {
                ...this.state.loginFlags,
                vimeoLoggedIn: isLoggedIn,
              },
            });
        break;
      case 'easy live':
        v1 = isLoggedIn
          ? this.setState({
              loginFlags: {
                ...this.state.loginFlags,
                elLoggedIn: isLoggedIn,
              },
            })
          : this.setState({
              loginFlags: { ...this.state.loginFlags, elLoggedIn: isLoggedIn },
            });
        break;
      case 'boxcast':
        v1 = isLoggedIn
          ? this.setState({
              loginFlags: {
                ...this.state.loginFlags,
                bcLoggedIn: isLoggedIn,
              },
            })
          : this.setState({
              loginFlags: { ...this.state.loginFlags, bcLoggedIn: isLoggedIn },
            });
        break;
      case 'youtube':
        this.setState({
          loginFlags: { ...this.state.loginFlags, ytLoggedIn: isLoggedIn },
        });
        if (!isLoggedIn) {
          cookies.remove('YT_access_token', {
            path: '/',
          });
          // delete cookies.get('YT_access_token');
        }
        break;
      default:
    }
  };

  componentWillUnmount() {
    this.killIntervals();
  }

  killIntervals() {
    Object.keys(StreamToolDestinations.intervals).forEach((key) => {
      clearInterval(StreamToolDestinations.intervals[key]);
    });
  }

  render() {
    const {
      t,
      history,
      selectedDes,
      match,
      destinationLoading,
      fbLogin,
      ytLogin,
      unit,
      channel,
      currentDestinationCount,
      status,
      selectProvider,
      totalDes,
    } = this.props;

    const { selectedAwsDestination } = this.state;
    return (
      <>
        {selectedDes && (
          <StreamToolDestinationsView
            t={t}
            currentDestinationCount={currentDestinationCount}
            utf={this.utfDecode}
            selectedDes={this.props.selectedDes}
            channel={channel}
            match={match}
            transcoderState={this.state.transcoderState}
            loginFlags={this.state.loginFlags}
            fbMakePostLive={this.fbMakePostLive}
            destinationLoading={destinationLoading}
            fbLogin={fbLogin}
            ytMakePostLive={this.ytMakePostLive}
            modalHandler={this.handleClick}
            ytLogin={ytLogin}
            unit={unit}
            twitterStreamState={cookies.get('twitter_stream_state')}
            selectProvider={selectProvider}
            selectedAwsDestination={selectedAwsDestination}
            totalDes={totalDes}
          />
        )}

        {this.state.showModal && this.state.modalDetails ? (
          <Modalpopup
            t={t}
            match={match}
            history={history}
            modalDetails={this.state.modalDetails}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { ovpCredentials } = state.authentication.user;
  const { selectedDestination, destinationLoading } = state.destination;
  const fbLoginData = state.fb.fb_data.login;
  const { liveVideo } = state.fb;
  const { linkedin } = state.linkedin;
  const { vimeo } = state.vimeo;
  const { units } = state.unit;
  const { login } = state.yt.YT_data;
  const { ptbroadcast, status } = state.periscope;
  const { channel } = state.twitch;
  const streamToolsUnitList = state.streamTools;
  // isStreamToolActive, isStreamtoolsStreaming
  return {
    selectedDestination,
    destinationLoading,
    ovpCredentials,
    fbLoginData,
    units,
    linkedin,
    liveVideo,
    unit: getUnitWithStatus(state),
    ytLoginData: login,
    ptbroadcast,
    vimeo,
    channel,
    status,
    streamToolsUnitList,
  };
};

const mapDispatchToProps = {
  fbLogin: FBAction.fbLogin,
  getTwitchUser: twitchAction.getChannel,
  getPTBroadcastStatus: periscopetwitterActions.getPTBroadcastStatus,
  fbGetLiveVideoStatus: FBAction.fbLiveVideoStatus,
  fbUpdateLiveVideoStatus: FBAction.fbUpdateLiveVideoStatus,
  getYTEventDetails: YTAction.getYTEventDetails,
  ytLogin: YTAction.YTLogin,
  ytBroadcastTransition: YTAction.ytBroadcastTransition,
  LIPostStatus: linkedinAction.LIPostStatus,
  deleteStreamToolDestination: destinationActions.removeStreamToolDestination,
  selectProvider: editUnitAction.selectProvider,
  getAwsDest: savedDestinationsService.getAwsDest,
  getStreamToolDestination: unitActions.getStreamToolDestination,
  deleteDestination: destinationActions.deleteSingleDestination,
  refreshStreamToolsDestination:
    liveStreamActions.refreshStreamToolsDestination,
  setStreamToolsDestinations: liveStreamActions.setStreamToolsDestinations,
};

const ConnectedDestinations = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(StreamToolDestinations));
export { ConnectedDestinations as StreamToolDestinations };
