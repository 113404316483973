import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './SoloTerms.css';
import { LangDropdown } from '../LangDropdown';
import GoSolo from '../../assets/images/go-solo.png';
import { Link } from 'react-router-dom';
import { AppVersion } from '../AppVersion/AppVersion';

class SoloTerms extends Component {
  state = {
    scnumber: '',
    errors: {
      scnumber: '',
    },
  };

  render() {
    const { t } = this.props;

    return (
      <div id='wrapper' className='liveu_page'>
        <div className='navbar d-flex flex-row align-items-center justify-content-between px-3'>
          <div className='logoMainCol'>
            <div className='navbar-header'>
              <img src={GoSolo} alt='GoSolo' className='logoMain' />
            </div>
          </div>
          <div className='navMainCol d-flex flex-column px-0'>
            <div className='d-flex flex-row align-items-md-end navsection'>
              <div className='ml-auto rightNav'>
                <LangDropdown align={true} />
              </div>
            </div>
          </div>
        </div>

        <div id='page-wrapper'>
          <div>
            <div className='wowzahelp soloTerms pagewrap'>
              <div className='container'>
                <div className='page-title'>
                  <h4>{t('TERMSANDCONDITIONS')}</h4>
                </div>
                <div className='card-body'>
                  <h3>{t('SAMPLETEXTHEADER')}</h3>
                  <p>{t('SAMPLETEXTTITLE')}</p>
                  <p>{t('SAMPLETEXT1')}</p>
                  <p>{t('SAMPLETEXT1.1')}</p>
                  <p>{t('SAMPLETEXT1.2')}</p>
                  <p>{t('SAMPLETEXT1.3')}</p>
                  <p>{t('SAMPLETEXT1.4')}</p>
                  <p>{t('SAMPLETEXT1.5')}</p>
                  <p>{t('SAMPLETEXT1.6')}</p>
                  <p>{t('SAMPLETEXT1.7')}</p>
                  <p>{t('SAMPLETEXT1.8')}</p>
                  <p>{t('SAMPLETEXT1.9')}</p>
                  <p>{t('SAMPLETEXT1.10')}</p>
                  <p>{t('SAMPLETEXT1.11')}</p>
                  <p>{t('SAMPLETEXT2')}</p>
                  <p>{t('SAMPLETEXT3')}</p>
                  <p>
                    {t('SAMPLETEXT4START')}
                    <Link to='mailto:info@liveu.tv'>info@liveu.tv</Link>
                    {t('SAMPLETEXT4END')}
                  </p>
                  <p>{t('SAMPLETEXT5')}</p>
                  <p>{t('SAMPLETEXT6')}</p>
                  <p>
                    {t('SAMPLETEXT6.1START')}
                    <Link
                      to='https://www.shopify.com/legal/terms'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Terms of Service{' '}
                    </Link>
                    {t('SAMPLETEXT6.1MID')}
                    <Link
                      to='https://www.shopify.com/legal/privacy'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Privacy Policy{' '}
                    </Link>
                    {t('SAMPLETEXT6.1END')}
                    <Link
                      to='https://shop.liveu.tv/pages/www.shopify.com'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      www.shopify.com.
                    </Link>
                  </p>
                  <p>{t('SAMPLETEXT6.2')}</p>
                  <p>{t('SAMPLETEXT6.3')}</p>
                  <p>{t('SAMPLETEXT6.4')}</p>
                  <p>{t('SAMPLETEXT7')}</p>
                  <p>{t('SAMPLETEXT8')}</p>
                  <p>{t('SAMPLETEXT9')}</p>
                  <p>{t('SAMPLETEXT9.1')}</p>
                  <p>{t('SAMPLETEXT9.2')}</p>
                  <p>{t('SAMPLETEXT9.3')}</p>
                  <p>{t('SAMPLETEXT9.4')}</p>
                  <p>{t('SAMPLETEXT10')}</p>
                  <p>{t('SAMPLETEXT10.1')}</p>
                  <p>{t('SAMPLETEXT10.2')}</p>
                  <p>{t('SAMPLETEXT10.3')}</p>
                  <p>{t('SAMPLETEXT10.4')}</p>
                  <p>{t('SAMPLETEXT11')}</p>
                  <p>{t('SAMPLETEXT11.1')}</p>
                  <p>{t('SAMPLETEXT11.2')}</p>
                  <p>{t('SAMPLETEXT11.3')}</p>
                  <p>{t('SAMPLETEXT12')}</p>
                  <p>{t('SAMPLETEXT13')}</p>
                  <p>{t('SAMPLETEXT14')}</p>
                  <p>{t('SAMPLETEXT14.1')}</p>
                  <p>{t('SAMPLETEXT14.2')}</p>
                  <p>{t('SAMPLETEXT14.3')}</p>
                  <p>{t('SAMPLETEXT14.4')}</p>
                  <p>{t('SAMPLETEXT14.5')}</p>
                  <p>{t('SAMPLETEXT14.6')}</p>
                  <p>{t('SAMPLETEXT15')}</p>
                  <p>{t('SAMPLETEXT16')}</p>
                  <p>{t('SAMPLETEXT16.1')}</p>
                  <p>{t('SAMPLETEXT16.2')}</p>
                  <p>{t('SAMPLETEXT16.3')}</p>
                  <p>{t('SAMPLETEXT16.4')}</p>
                  <p>{t('SAMPLETEXT17')}</p>
                  <p>{t('SAMPLETEXT17.1')}</p>
                  <p>{t('SAMPLETEXT17.2')}</p>
                  <p>{t('SAMPLETEXT17.3')}</p>
                  <p>{t('SAMPLETEXT17.4')}</p>
                  <p>{t('SAMPLETEXT17.5')}</p>
                  <p>{t('SAMPLETEXT18')}</p>
                  <p>{t('SAMPLETEXT18.1')}</p>
                  <p>{t('SAMPLETEXT18.2')}</p>
                  <p>
                    {t('SAMPLETEXT19START')}
                    <Link
                      to='https://www.liveu.tv/support'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      www.liveu.tv/supportterms
                    </Link>
                    {t('SAMPLETEXT19END')}
                  </p>
                  <p>
                    {t('SAMPLETEXT20START')}
                    <Link
                      to='https://www.liveu.tv/'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      www.liveu.tv/privacy
                    </Link>
                    {t('SAMPLETEXT20END')}
                    <Link
                      to='https://www.shopify.com/legal/privacy'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      https://www.shopify.com/legal/privacy.
                    </Link>
                  </p>
                  <p>{t('SAMPLETEXT21')}</p>
                  <p>{t('SAMPLETEXT22')}</p>
                  <p>{t('SAMPLETEXT23')}</p>
                  <p>{t('SAMPLETEXT24')}</p>
                  <p>{t('SAMPLETEXT24.1')}</p>
                  <p>{t('SAMPLETEXT24.2')}</p>
                  <p>{t('SAMPLETEXT24.3')}</p>
                  <p>{t('SAMPLETEXT25')}</p>
                  <p>{t('SAMPLETEXT26')}</p>
                  <p>{t('SAMPLETEXT27')}</p>
                  <p>{t('SAMPLETEXT28')}</p>
                  <p>{t('SAMPLETEXT29')}</p>
                  <h4>{t('YTTERMSHEADING')}</h4>
                  <p>
                    {t('YTTERMS1')}
                    <Link
                      to='https://www.youtube.com/t/terms'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      https://www.youtube.com/t/terms
                    </Link>
                    .{t('YTTERMS2')}
                    <Link
                      to='http://www.google.com/policies/privacy'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      http://www.google.com/policies/privacy
                    </Link>
                    .{t('YTTERMS3')}
                    <Link
                      to='https://security.google.com/settings/security/permissions'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      https://security.google.com/settings/security/permissions
                    </Link>
                    .{t('YTTERMS4')}
                    <Link to='mailto:solo.help@liveu.tv'>
                      solo.help@liveu.tv
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='btm-link'>
          <span>
            {t('VERSION')} <version-number></version-number>
          </span>
          <Link to='https://get.gosolo.tv/privacy-policy'>
            {t('PRIVACYPOLICY')}
          </Link>
        </div> */}

        <div className='loginAppVersion'>
          <AppVersion t={t} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { showLoader } = state.common;
  const { data } = state.channelServiceDestination;
  return {
    showLoader,
    data,
  };
};

const mapDispatchToProps = {};

const ConnectedSoloTerms = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SoloTerms));
export { ConnectedSoloTerms as SoloTerms };
