import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { REACT_APP_TwitchAppID } = process.env;

const twitchBaseUrl = 'https://api.twitch.tv/';
//const twitchIngestUrl = 'https://ingest.twitch.tv/';

// export const _getTwitchIngests = () =>
//   axios.get(
//     `${twitchIngestUrl}ingests`,
//     //{ headers: { Accept: 'application/vnd.twitchtv.v5+json' } },
//   );
export const _getTwitchUser = () =>
  axios.get(`${twitchBaseUrl}helix/users`, {
    headers: {
      Authorization: `Bearer ${cookies.get('twitch_access_token')}`,
      'Client-Id': REACT_APP_TwitchAppID,
    },
  });

export const _getTwitchStreamKey = (id) =>
  axios.get(`${twitchBaseUrl}helix/streams/key?broadcaster_id=${id}`, {
    headers: {
      Authorization: `Bearer ${cookies.get('twitch_access_token')}`,
      'Client-Id': REACT_APP_TwitchAppID,
    },
  });
export const _getTwitchGames = (query) =>
  axios.get(
    `${twitchBaseUrl}helix/search/categories?query=${query}&type=suggest`,
    {
      headers: {
        Authorization: `Bearer ${cookies.get('twitch_access_token')}`,
        'Client-Id': REACT_APP_TwitchAppID,
      },
    },
  );
export const _updateTwitchChannel = (payload, id) =>
  axios.patch(`${twitchBaseUrl}helix/channels?broadcaster_id=${id}`, payload, {
    headers: {
      Authorization: `Bearer ${cookies.get('twitch_access_token')}`,
      'Client-Id': REACT_APP_TwitchAppID,
    },
  });
// export const getTwitchToken = () => axios.get(`${twitchBaseUrl}/channel?oauth_token=${token}`)
// POST https://id.twitch.tv/oauth2/token
//     ?client_id=uo6dggojyb8d6soh92zknwmi5ej1q2
//     &client_secret=nyo51xcdrerl8z9m56w9w6wg
//     &code=394a8bc98028f39660e53025de824134fb46313
//     &grant_type=authorization_code
//     &redirect_uri=http://localhost
