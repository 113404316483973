import { Button, Checkbox, Col, Row, Slider, Tag, Icon } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import ScriptTag from 'react-script-tag';
import { liveStreamActions } from '../../actions';
import MuteLogo from '../../assets/svg/mute.svg';
import WhiteMuteLogo from '../../assets/svg/mutewhite.svg';
import VolumeLogo from '../../assets/svg/volume.svg';
import WhiteVolumeLogo from '../../assets/svg/volumeWhite.svg';
import { useDispatch, useSelector } from 'react-redux';
import streamToolsQueue from '../../helpers/queue';

const scriptUrl =
  'https://static-eu-irl.studio.liveu.tv/front/js/easylive/elwebrtc.5cd9ec38c1a43a4082f98831cb8b2cef4254ccaa.min.js';

const settings = {
  quality: 'hd',
};
const { scope } = liveStreamActions;
let listner;
let socket;
let streamSrc = null;
let audioContext;
let objects = {};
let isPlay = true;
const StreamToolsPreviewView = ({
  t,
  match,
  liveId,
  isParamsFetched,
  // fallbackUrl,
  showFallback,
  // handleShowfallback,
  audioVolume,
  updateAudioHandler,
  back,
  muteLiveFeed,
  // toggleMute,
}) => {
  const containerRef = useRef(null);
  const videoContainerRef = useRef(null);
  const skeletonRef = useRef(null);
  const dispatch = useDispatch();
  const {
    unit: { BOSSID: bossId },
  } = useSelector((state) => state.unit);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [videoName, setVideoName] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [mute, setMute] = useState(true);
  const [showOverLay, setShowOverlay] = useState(false);
  // const [isPlay, setIsPlay] = useState(false);
  // const [isInit, setIsInit] = useState(true);
  const leftmeter = useRef(null);
  const rightmeter = useRef(null);
  // const audioMeterRef = useRef(null);
  useEffect(() => {
    const video = videoContainerRef.current;
    if (video) {
      video.oncanplay = () => {
        setIsVideoReady(true);
        video.play();
        // video.muted = false
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoContainerRef.current]);

  useEffect(() => {
    const block = skeletonRef.current;
    const width = block.clientWidth;
    block.style.setProperty('--skeleton-block-height', `${(9 / 16) * width}px`);
  }, []);

  useEffect(() => {
    if (isParamsFetched) {
      const block = containerRef.current;
      const width = block.clientWidth;
      block.style.setProperty('--video-block-height', `${(9 / 16) * width}px`);
      var video = document.getElementById('liveVideo1');

      video.addEventListener('loadedmetadata', function () {
        video.style.setProperty('play-button', 'display: none');
      });
    }
  }, [isParamsFetched]);

  useEffect(() => {
    isScriptLoaded &&
      isParamsFetched &&
      liveId &&
      (async () => {
        dispatch(
          streamToolsQueue(() => {
            return async (dispatch) => {
              socket = await dispatch(liveStreamActions.getSocket(bossId));
              if (!(socket && socket.url)) {
                return;
              }

              socket.init = () => {
                socket.send(
                  JSON.stringify({
                    init: liveId,
                  }),
                );
              };

              socket.onmessage = (data) => {
                const msg = JSON.parse(data.data);

                if (
                  msg?.init?.customer?.current_live?.start !== 2 &&
                  !listner
                ) {
                  listner = setInterval(socket.init, 20000);
                } else {
                  if (msg?.init?.customer?.current_live?.start === 2) {
                    clearInterval(listner);
                    setIsStreaming(true);
                  }
                  if (msg?.init?.customer?.current_live?.start === 0) {
                    setIsStreaming(false);
                  }
                }
                if (msg?.init?.customer?.current_live?.link) {
                  const { link } = msg.init.customer.current_live;
                  setVideoName(link.split('/')[4]);
                }

                if (liveId && msg[liveId]) {
                  window.webRTCLib.updateSfu(msg[liveId]);
                }
                window.webRTCLib.updateSfu(msg);
              };
              socket.init();
            };
          }, []),
        );
      })();
    return () => {
      clearInterval(listner);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded, isParamsFetched, liveId]);

  useEffect(() => {
    if (
      socket?.url &&
      liveId &&
      videoName &&
      isStreaming &&
      isScriptLoaded &&
      isParamsFetched
    ) {
      dispatch(
        streamToolsQueue(() => {
          return () => {
            socket.originalSend = socket.send;
            socket.send = (data) => {
              socket.originalSend(
                JSON.stringify({
                  ...(data.data && {
                    website: {
                      scope: scope(),
                      ...(data.data && data.data),
                    },
                  }),
                  ...(data.data ?? JSON.parse(data)),
                }),
              );
            };
          };
        }, []),
      );

      isScriptLoaded &&
        window.webRTCLib.init({
          ws: socket,
          id: liveId,
          withData: true,
          isRunning: isRunning.bind(null, liveId),
          update: playerUpdate.bind(null, videoContainerRef),
        });

      isScriptLoaded &&
        window.webRTCLib.add({
          container: containerRef.current,
          videoContainer: videoContainerRef.current,
          videoName,
          settingsName: videoName,
          settings,
          boxId: liveId,
        });
    }
    return () => {
      if (socket?.originalSend) {
        dispatch(
          streamToolsQueue(() => {
            return () => {
              socket.send = socket.originalSend;
              socket.originalSend = null;
            };
          }, []),
        );

        isScriptLoaded &&
          window.webRTCLib
            .getOrCreate({
              id: liveId,
            })
            .close();
        objects['program']?.source?.disconnect();
        if (objects['program']?.meter?.port?.onmessage) {
          objects['program'].meter.port.onmessage = null;
        }
        objects = {};
        clearInterval(listner);
      }
      // isScriptLoaded &&
      //   window.webRTCLib.remove({
      //   ws: socket,
      //   id: liveId,
      //   withData: true,
      //   isRunning: isRunning.bind(null, liveId),
      //   update: playerUpdate.bind(null, videoContainerRef),
      // });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, videoName, liveId, isScriptLoaded, isParamsFetched, isStreaming]);

  useEffect(() => {
    if (isVideoReady) {
      videoContainerRef.current.muted = mute;
      // isPlay = !mute;
      !initialized && initAudioNode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mute]);

  useEffect(() => {
    if (isVideoReady) {
      if (!showOverLay) {
        videoContainerRef.current.play();
        // videoContainerRef.current.muted = false;
        isPlay = true;
      } else {
        videoContainerRef.current.pause();
        isPlay = false;
      }
      // !initialized && initAudioNode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOverLay]);

  const handleClickOnVideoElement = () => {
    if (isStreaming) {
      // setShowOverlay(true);
      setShowOverlay((pre) => !pre);
    }
  };
  // const handleVideoPlay = () => {
  //   // setIsPlay(true);
  //   isPlay = true;
  //   // initAudioNode();
  // };

  // const handleVideoPause = () => {
  //   // setIsPlay(false);
  //   isPlay = false;
  // };

  const handlePlayButtonClick = () => {
    setShowOverlay((pre) => !pre);
    // setIsInit(false);
  };

  const handleMute = () => {
    setMute((pre) => !pre);
  };

  const initAudioNode = async () => {
    let audio = getContext();
    await audio.context.audioWorklet.addModule(
      'https://static-eu-irl.easylive.io/front/js/vumeter_v13.js',
    );
    setInitialized(true);
    updateVUMeter('program', streamSrc, true);
  };

  const playerUpdate = function (videoContainerRef, stream) {
    if (stream.peer && stream.peer.pc) {
      if (stream.srcObject) {
        if (
          videoContainerRef.current &&
          (!videoContainerRef.current.srcObject ||
            videoContainerRef.current.getAttribute('data-stream-id') !==
              stream.srcObject.id)
        ) {
          videoContainerRef.current.srcObject = stream.srcObject;
          videoContainerRef.current.setAttribute(
            'data-stream-id',
            stream.srcObject.id,
          );
          streamSrc = stream.srcObject;
        }
      }
    }
  };

  const isRunning = function (box_id, streamName, settings) {
    if (streamName) {
      let { srcObject } = window.webRTCLib.getStreamAndPeer(
        box_id,
        streamName,
        settings,
      );
      if (!srcObject) {
        return false;
      }
      return true;
    }
    return false;
  };

  const updateVUMeter = function (name, srcObject, initialized) {
    if (window.AudioContext) {
      var audio = getContext();

      if (srcObject && srcObject.getAudioTracks().length) {
        if (!objects[name]) {
          let meter = createAudioMeter(initialized);

          if (meter) {
            objects[name] = {
              meter,
            };
          }
        } else {
          if (objects[name].source) {
            objects[name].source.disconnect();
          }
        }

        if (objects[name] && objects[name].meter) {
          // createAudioMeterUI(objects[name].meter, container, 1);

          // container.meter = objects[name].meter;

          objects[name].source =
            audio.context.createMediaStreamSource(srcObject);
          objects[name].source.connect(objects[name].meter);
        }
      }
    }
  };

  const createAudioMeter = function (initialized) {
    if (initialized) {
      let audio = getContext();
      let meter = new AudioWorkletNode(audio.context, 'vumeter');
      meter.port.onmessage = volumeAudioProcess.bind(null, meter);
      return meter;
    }
    return false;
  };

  function volumeAudioProcess(meter, event) {
    if (!event.data.volume) {
      return;
    }
    meter.volume = event.data.volume;
    meter.stereo = event.data.stereo;
    meter.clipping = event.data.clipping;
    meter.peakMeter = event.data.peakMeter;
    const left = leftmeter.current;
    const right = rightmeter.current;
    let l1 = meter.volume[0] * 100;
    let r1 = meter.volume[1] * 100;

    if (!isPlay) {
      left.style.setProperty('--strength', `${0}%`);
      right.style.setProperty('--strength', `${0}%`);
      return;
    }

    left.style.setProperty('--strength', `${l1}%`);
    right.style.setProperty('--strength', `${r1}%`);
    if (l1 > 80) {
      left.style.setProperty('--bgcolor', '#FF2400');
    } else {
      left.style.setProperty('--bgcolor', '#4caf50');
    }
    if (r1 > 80) {
      right.style.setProperty('--bgcolor', '#FF2400');
    } else {
      right.style.setProperty('--bgcolor', '#4caf50');
    }
  }

  const getContext = function () {
    let localAudioContext;
    if (!audioContext) {
      if (!window.chrome) {
        localAudioContext = new window.AudioContext();
      } else {
        localAudioContext = new window.AudioContext({
          sampleRate: 48000,
        });
      }
      audioContext = { context: localAudioContext };
    }
    return audioContext;
  };

  return (
    <>
      <ScriptTag
        type='text/javascript'
        src={scriptUrl}
        onLoad={() => {
          setIsScriptLoaded(true);
        }}
      />

      <div className='previewToolMain'>
        <Row className='mb-3'>
          <Col span={15}>
            <div className='sub-title'>
              <h4>
                {t('PROGRAMPREVIEW')}{' '}
                <sup>
                  <Tag
                    style={{
                      color: 'rgb(228 73 32 / 88%)',
                      border: 'dotted 1.5px',
                      fontSize: '10px',
                    }}
                  >
                    {t('BETA')}
                  </Tag>
                </sup>
              </h4>
            </div>
          </Col>
          <Col span={9}>
            <Button
              onClick={back}
              className='btn-primary'
              style={{ float: 'right' }}
            >
              Back
            </Button>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24} lg={19} xl={20} className='video-block mb-lg-0 mb-3'>
            {isParamsFetched ? (
              <div className='mb-4' ref={containerRef}>
                {
                  // showFallback && (
                  // <div>
                  //   <video
                  //     autoPlay='autoplay'
                  //     muted='muted'
                  //     poster={fallbackUrl}
                  //     playsInline={true}
                  //     className='live-video'
                  //     style={{ backgroundColor: 'black' }}
                  //   ></video>
                  // </div>
                  // )
                }
                <div>
                  <div className='video-overlay'>
                    <video
                      style={{ display: !showFallback ? 'block' : 'none' }}
                      ref={videoContainerRef}
                      autoPlay='autoplay'
                      muted='muted'
                      playsInline={true}
                      id='liveVideo1'
                      className={`live-video`}
                      onClick={handleClickOnVideoElement}
                      // poster={poster}
                      // controls={true}
                      // onPlay={handleVideoPlay}
                      // onPause={handleVideoPause}
                    ></video>
                    {!showFallback && !isVideoReady && (
                      <em className='overlay-text'>{t('WAITVIDEOFEED')}</em>
                    )}

                    {
                      // showOverLay &&
                      // isStreaming &&
                      // !showFallback &&
                      // isVideoReady && <div className='overlay-preview' />
                    }

                    {isVideoReady && (
                      <div onClick={handleMute} className='mute-link'>
                        <img
                          src={mute ? WhiteMuteLogo : WhiteVolumeLogo}
                          height={20}
                          width='auto'
                          alt=''
                        />
                      </div>
                    )}
                  </div>
                  {showOverLay &&
                    isStreaming &&
                    !showFallback &&
                    isVideoReady && (
                      <div>
                        <div className='play-button-preview'>
                          <Icon
                            type='play-circle'
                            theme='filled'
                            style={{ color: '#e44920', fontSize: '10vh' }}
                            onClick={handlePlayButtonClick}
                          />
                        </div>
                        {/* <em className='overlay-text play-text'>
                          {t('PLAYTXT')}
                        </em> */}
                      </div>
                    )}
                </div>
              </div>
            ) : (
              <div
                className='custom-skeleton video-skeleton mb-4'
                ref={skeletonRef}
              ></div>
            )}

            <Row align='middle'>
              <Col span={24} md={9}>
                {
                  // <Button
                  //   onClick={handleShowfallback}
                  //   className='btn-primary '
                  //   disabled={!fallbackUrl}
                  //   style={{ color: 'white' }}
                  // >
                  //   <b className={!fallbackUrl ? 'ml-2' : ''}>
                  //     {!showFallback ? t('SHOWFALLBACK') : t('SHOWVIDEOFEED')}
                  //   </b>
                  // </Button>
                }
                {
                  // isVideoReady && (
                  // <Link onClick={handleMute} className='mute-link'>
                  //   <b>{mute ? 'Unmute' : 'Mute'}</b>
                  // </Link>
                  // )
                }
              </Col>
              <Col span={24} md={15} className='text-right my-2'>
                {isParamsFetched ? (
                  <Link
                    to={`/dashboard/units/${match?.params?.bossId}/streamtoolssettings`}
                    className='streamtools-settings-link'
                  >
                    <b>{t('SETFALLBACKLOGO')}</b>
                  </Link>
                ) : (
                  <div className='custom-skeleton p-2'> &nbsp; </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={5} xl={4} span={24} className='mt-5'>
            <div className='row m-lg-0 justify-content-center justify-content-lg-between '>
              <div className='col-auto p-lg-0'>
                <div className='col-auto justify-content-center volume-slider-container'>
                  <Slider
                    vertical
                    defaultValue={audioVolume}
                    railStyle={{
                      backgroundColor: '#ccc',
                    }}
                    disabled={!isStreaming || muteLiveFeed}
                    onChange={(e) => {
                      updateAudioHandler(e);
                    }}
                  />
                  <div
                    className='text-center mt-3'
                    // onClick={toggleMute}
                    role='button'
                  >
                    {
                      <img
                        src={muteLiveFeed ? MuteLogo : VolumeLogo}
                        height={20}
                        width='auto'
                        alt=''
                      />
                    }
                  </div>
                </div>
                <p className='my-2 font-weight-bold text-center'>Volume</p>
              </div>
              <div className='col-auto p-lg-0'>
                <Row gutter={5}>
                  <Col span={12}>
                    <div className='meter-container'>
                      <div className='meter' ref={leftmeter} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className='meter-container'>
                      <div className='meter' ref={rightmeter} />
                    </div>
                  </Col>
                </Row>
                <div className='my-3 font-weight-bold text-center'>
                  {t('AUDIOVUMETER')}
                </div>
              </div>
            </div>
            <hr></hr>
            {false && (
              <Checkbox className='d-flex align-items-center justify-content-lg-center font-weight-bold'>
                <div>{t('MIRRORSTEREO')}</div>
              </Checkbox>
            )}
            <p className='audio-info-text'>{t('MONOTOSTEREO')}</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StreamToolsPreviewView;
