export const ytConstants = {
  YT_LOGIN_FAILURE: 'YT_LOGIN_FAILURE',
  YT_LOGIN: 'YT_LOGIN',
  SHOW_YT_SIGN_IN: 'SHOW_YT_SIGN_IN',
  SHOW_YT_SIGN_IN_FALSE: 'SHOW_YT_SIGN_IN_FALSE',
  YT_SESSION_EXPIRED_TRUE: 'YT_SESSION_EXPIRED_TRUE',
  YT_SESSION_EXPIRED_FALSE: 'YT_SESSION_EXPIRED_FALSE',
  GET_BROADCASTS: 'GET_BROADCASTS',
  GET_BROADCASTS_FAIL: 'GET_BROADCASTS_FAIL',
  GET_LIVE_STREAMS: 'GET_LIVE_STREAMS',
  GET_LIVE_STREAMS_FAIL: 'GET_LIVE_STREAMS_FAIL',
  YT_LOADING_TRUE: 'YT_LOADING_TRUE',
  YT_LOADING_FALSE: 'YT_LOADING_FALSE',
  YT_BROADCAST_EXIST: 'YT_BROADCAST_EXIST',
  YT_LIVE_STREAM_EXIST: 'YT_LIVE_STREAM_EXIST',
  YT_BROADCAST_LIST: 'YT_BROADCAST_LIST',
  YT_LIVE_STREAM_LIST: 'YT_LIVE_STREAM_LIST',
  YT_DELETE_UPCOMING: 'YT_DELETE_UPCOMING',
  YT_DELETE_UPCOMING_FAIL: 'YT_DELETE_UPCOMING_FAIL',
  YT_DELETE_DASHBOARD: 'YT_DELETE_DASHBOARD',
  YT_DELETE_DASHBOARD_FAIL: 'YT_DELETE_DASHBOARD_FAIL',
  YT_INITIAL_STATE: 'YT_INITIAL_STATE',
  GET_DEFAULT_BROADCAST: 'GET_DEFAULT_BROADCAST',
  GET_DEFAULT_BROADCAST_FAIL: 'GET_DEFAULT_BROADCAST_FAIL',
  GET_DEFAULT_LIVE_STREAM: 'GET_DEFAULT_LIVE_STREAM',
  GET_DEFAULT_LIVE_STREAM_FAIL: 'GET_DEFAULT_LIVE_STREAM_FAIL',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_USER_DETAILS_FAIL: 'GET_USER_DETAILS_FAIL',
  YT_LOADING: 'YT_LOADING',
  YT_LOADING_STOP: 'YT_LOADING_STOP',
  GET_BROADCAST_BY_ID: 'GET_BROADCAST_BY_ID',
  GET_BROADCAST_BY_ID_FAIL: 'GET_BROADCAST_BY_ID_FAIL',
  GET_LIVE_STREAM_BY_ID: 'GET_LIVE_STREAM_BY_ID',
  GET_LIVE_STREAM_BY_ID_FAIL: 'GET_LIVE_STREAM_BY_ID_FAIL',
  YT_BROADCAST_ANALYTICS: 'YT_BROADCAST_ANALYTICS',
  YT_ANALYTICS_LOADING: 'YT_ANALYTICS_LOADING',
  YT_ANALYTICS_STOP: 'YT_ANALYTICS_STOP',
  YT_VIDEO_ANALYTICS: 'YT_VIDEO_ANALYTICS',
};
