import {
  commonConstants,
  editunitConstants,
  fbConstants,
} from '../../constants';
import {
  editUnitsService,
  fbService,
  savedDestinationsService,
} from '../../services';
import { errorBlock } from '../../api/errorBlock';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import { store } from '../../store';
// import { Promise } from 'bluebird';
import i18n from 'i18next';
import { notify } from '../../components/CommonNotification/CommonNotification';
import { commonAction } from '../common.actions';
import { history } from '../../history';
import { destinationService } from '../../services';
import Cookies from 'universal-cookie';
// import { liveStreamActions } from '../livestream.action';
import { editUnitAction, liveStreamActions } from '../../actions';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const FBAction = {
  fbLogin,
  fbLoginCheck,
  delFBDest,
  getLocation,
  getFriends,
  getAdTags,
  fbGetPages,
  getAdLocation,
  fbPostPages,
  getPostSponsors,
  checkCrossPostPage,
  createLiveVideo,
  facebookLogout,
  fbLiveVideoStatus,
  fbUpdateLiveVideoStatus,
  fbInitialState,
  fbGetPostAnalytics,
  fbUpdateLiveVideo,
  fbGetUserDetails,
  fbGetGroupByPage,
  fbImageUpload,
};
let fbId,
  postId,
  eventsOptions,
  pagesOptions,
  groupOptions,
  personalOptions,
  defaultOption,
  currentUnitData;
/**
 * @name fbLoginCheck
 * @params match
 * @descriptiton function used for fb login check
 **/
function fbLoginCheck(match) {
  return async (dispatch) => {
    try {
      dispatch(fbInitialState(match));
      dispatch(fbGetPages(match));
    } catch (e) {
      errorBlock(e, match);
      dispatch(otherFailure());
      dispatch(showSignIn());
    }
  };

  function otherFailure() {
    return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
  }

  function showSignIn() {
    return { type: fbConstants.SHOW_FB_SIGN_IN };
  }
}
/**
 * @name fbLogin
 * @params match
 * @descriptiton function used for fb login
 **/
function fbLogin(match) {
  return async (dispatch) => {
    try {
      dispatch(fbInitialState(match));
      dispatch(request());

      const fbLogin = await fbService.fbLogin();

      let d = new Date();
      d.setTime(d.getTime() + 90 * 24 * 60 * 60 * 1000);
      dispatch(success(fbLogin));
      dispatch(otherSuccess());
      dispatch(otherSuccessRes());
      setLocalStorageItem('FB_access_token', fbLogin.authResponse.accessToken);
      setLocalStorageItem('FB_user_id', fbLogin.authResponse.userID);
      notify('success', i18n.t('FBLOGIN'));
      dispatch(fbGetPages(match));
      const fbRefereshToken = await fbService.fbrefreshroken(
        fbLogin.authResponse.accessToken,
      );
      setLocalStorageItem(
        'FB_access_token',
        fbRefereshToken.data.data.response.access_token,
      );
      return Promise.resolve(true);
    } catch (e) {
      dispatch(failure(e));
      notify('error', i18n.t('FBLOGINFAIL'));
      errorBlock(e, match);
      dispatch(otherFailure());
      dispatch(showSignIn());
      return Promise.reject(e);
    }

    function request() {
      return { type: fbConstants.SHOW_FB_SIGN_IN };
    }

    function success(fbLoginData) {
      return { type: fbConstants.FB_LOGIN, fbLoginData };
    }

    function otherSuccess() {
      return { type: fbConstants.SHOW_FB_SIGN_IN_FALSE };
    }

    function otherSuccessRes() {
      return { type: fbConstants.FB_SESSION_EXPIRED_FALSE };
    }

    function failure(error) {
      return { type: fbConstants.FB_LOGIN_FAILURE, error };
    }

    function otherFailure() {
      return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
    }

    function showSignIn() {
      return { type: fbConstants.SHOW_FB_SIGN_IN };
    }
  };
}
/**
 * @name fbGetPages
 * @params match
 * @descriptiton function used to get pages user follows
 **/
function fbGetPages(match) {
  return async (dispatch) => {
    try {
      dispatch(request());
      dispatch(request2());
      dispatch(otherSuccess());
      fbId = !!match ? match.params.fbId : null;
      postId = !!match ? match.params.postId : null;
      const fbGetPages = await fbService.fbgetPages();
      dispatch(otherSuccessRes());
      let fbData = {};
      fbData.pages = [];
      fbData.user = [];
      fbData.user.push({
        id: getLocalStorageItem('FB_user_id'),
        access_token: getLocalStorageItem('FB_access_token'),
      });
      dispatch(fbPerData(fbData.user));
      if (fbGetPages.data.length) {
        fbGetPages.data.forEach((item) => {
          fbData.pages.push({
            name: item.name,
            id: item.id,
            access_token: item.access_token,
          });
        });
      }

      dispatch(success(fbData.pages));
      dispatch(fbGetGroups(match));
    } catch (e) {
      dispatch(otherFailure());
      dispatch(failure(e));
      dispatch(other());
      errorBlock(e, match);
      dispatch(showSignIn());
    }

    function request() {
      return { type: fbConstants.FB_PAGES_REQ };
    }

    function request2() {
      return { type: fbConstants.FB_LOADING_TRUE };
    }

    function other() {
      return { type: fbConstants.FB_LOADING_FALSE };
    }

    function success(fbGetPagesData) {
      return { type: fbConstants.FB_PAGES, fbGetPagesData };
    }

    function fbPerData(fbData) {
      return { type: fbConstants.FB_PERSONAL, fbData };
    }

    function failure(error) {
      return { type: fbConstants.FB_PAGES_FAILURE, error };
    }

    function otherFailure() {
      return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
    }

    function showSignIn() {
      return { type: fbConstants.SHOW_FB_SIGN_IN };
    }

    function otherSuccessRes() {
      return { type: fbConstants.FB_SESSION_EXPIRED_FALSE };
    }

    function otherSuccess() {
      return { type: fbConstants.SHOW_FB_SIGN_IN_FALSE };
    }
  };
}
/**
 * @name fbPostPagess
 * @params match
 * @descriptiton post crosspost for user's fb pages
 **/
function fbPostPages(data) {
  return async (dispatch) => {
    try {
      const fbPostPages = await fbService.fbPostCrossPages(
        data[0].id1,
        data[0].id2,
        data[0].stat,
        data[0].token,
      );
      dispatch(FBData(fbPostPages));
      notify('success', i18n.t('SUCCESSFULLYVALIDATED'));
    } catch (e) {
      dispatch(failure(e));
      notify('error', i18n.t('EL500ERROR'));
      dispatch(crossError(e.toString()));
      if (
        e.response.data.error.message
          .toLowerCase()
          .includes('user does not have sufficient administrative')
      ) {
        notify('error', i18n.t('CROSSPOSTERROR'));
      } else {
        errorBlock(e);
      }
      otherFailure();
    }
  };
  function FBData(postData) {
    return { type: fbConstants.FB_POST_SUCCESS, postData };
  }

  function failure(error) {
    return { type: fbConstants.FB_POST_FAILURE, error };
  }

  function otherFailure() {
    return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
  }
  function crossError() {
    return { type: fbConstants.FB_CROSSPOST_PAGE_ERR };
  }
}

/**
 * @name fbGetGroups
 * @params match
 * @descriptiton get user's fb groups
 **/
function fbGetGroups(match) {
  return async (dispatch) => {
    try {
      let limitCheck = true;
      let nextPage = null;
      const data = [];

      while (limitCheck && data.length < 1000) {
        const fbgetGroups = await fbService.fbgetGroups(nextPage);
        data.push(...fbgetGroups?.data);

        if (fbgetGroups?.paging?.next) {
          limitCheck = true;
          nextPage = fbgetGroups?.paging?.cursors?.after;
        } else {
          limitCheck = false;
        }
      }

      let fbData = {};
      fbData.groups = [];
      if (data.length) {
        data.forEach((item) => {
          /*if (item.privacy) {*/ //Only groups in which user is administrator
          fbData.groups.push({
            name: item.name,
            id: item.id,
            access_token: getLocalStorageItem('FB_access_token'),
            privacy: item.privacy || '',
          });
          // }
        });
      }
      dispatch(FBData(fbData.groups));
      dispatch(fbGetEvents(match));
    } catch (e) {
      dispatch(otherFailure());
      dispatch(failure(e));
      errorBlock(e, match);
    }

    function FBData(fbGetGroupsData) {
      return { type: fbConstants.FB_GROUPS, fbGetGroupsData };
    }

    function failure(error) {
      return { type: fbConstants.FB_GROUPS_FAILURE, error };
    }

    function otherFailure() {
      return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
    }
  };
}
/**
 * @name fbGetEvents
 * @params match
 * @descriptiton function used to get user's events
 **/
function fbGetEvents(match) {
  return async (dispatch) => {
    try {
      const fbGetGroups = await fbService.fbgetEvents();
      let fbData = {};
      fbData.events = [];
      if (fbGetGroups.data.length) {
        fbGetGroups.data.forEach((item) => {
          let f_date = item.end_time ? item.end_time : item.start_time;
          if (new Date() < new Date(f_date)) {
            fbData.events.push({
              name: item.name,
              id: item.id,
              access_token: getLocalStorageItem('FB_access_token'),
            });
          }
        });
      }
      dispatch(FBData(fbData.events));
      dispatch(fbGetLiveVideos(match));
    } catch (e) {
      dispatch(otherFailure());
      dispatch(failure(e.toString()));
      errorBlock(e, match);
    }

    function FBData(fbGetEventsData) {
      return { type: fbConstants.FB_EVENTS, fbGetEventsData };
    }

    function failure(error) {
      return { type: fbConstants.FB_EVENTS_FAILURE, error };
    }

    function otherFailure() {
      return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
    }
  };
}
/**
 * @name fbGetLiveVideos
 * @params match
 * @descriptiton function used to get  live videos from personal,page
 **/
function fbGetLiveVideos(match) {
  return async (dispatch) => {
    try {
      let fbPersonal = store.getState().fb.fb_data.personal;
      let fbPages = store.getState().fb.fb_data.pages;
      let recentDest;
      if (
        !cookies.get('latestPosts') ||
        cookies.get('latestPosts') === 'undefined'
      ) {
        const userInfo = await fbService.userInfo();
        if (!!userInfo.ovpCredentials) {
          if (!!JSON.parse(userInfo.ovpCredentials).fb_pages) {
            recentDest = JSON.parse(userInfo.ovpCredentials).fb_pages;
          }
        }
        let d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        if (recentDest) {
          cookies.set('latestPosts', JSON.stringify(recentDest), {
            path: '/',
            expires: d,
            secure: true,
          });
        } else {
          cookies.set('latestPosts', [], {
            path: '/',
            expires: d,
            secure: true,
          });
        }
      }
      let latestPosts =
        cookies.get('latestPosts') !== 'undefined'
          ? cookies.get('latestPosts')
          : [];
      //let fbGroups = store.getState().fb.fb_data.groups;//commented because either it returns empty data or 500 or 403
      //let fbEvents =store.getState().fb.fb_data.events;////commented because either it returns empty data or 500 or 403
      let fbAllEvents = [...fbPages];
      const results =
        latestPosts &&
        fbAllEvents.filter(({ id: value1 }) =>
          latestPosts.some(({ id: value2 }) => value2 === value1),
        );
      fbAllEvents = results;
      await dispatch(editUnitAction.getAWSDestInven());
      const awsDest =
        store.getState().editUnit.editunit.aws_Destinations.destList;
      const matchedFbDest = awsDest.filter((value) => {
        return value.streaming_provider === 'Facebook';
      });
      const unpublished = [];
      if (matchedFbDest.length) {
        //if there's no AWS destination then there's no need to call FB graph API.
        const fbPerRes = map(fbPersonal, async (allValue) => {
          try {
            //add individual try catch
            const fbUserLiveVideo = await fbService.fbuserLiveVideos(
              allValue.access_token,
            );
            matchedFbDest.forEach((matchedValue) => {
              if (fbUserLiveVideo.data.length) {
                fbUserLiveVideo.data.forEach((resValue) => {
                  if (matchedValue.d_id === resValue.id) {
                    resValue.destination_id = matchedValue.d_id;
                    resValue.page_id = allValue.id;
                    unpublished.push(resValue);
                  }
                });
              }
            });
          } catch (e) {
            dispatch(FBLiveVideoExistsFailure());
            dispatch(failure(e.toString()));
            dispatch(other());
            errorBlock(e, match);
            dispatch(otherFailure());
            dispatch(showSignIn());
          }
        });
        await Promise.all(fbPerRes);
        const result = map(fbAllEvents, async (allValue) => {
          try {
            //add individual try catch
            if (allValue.access_token) {
              const fbgetLiveVideos = await fbService.fbgetLiveVideos(
                allValue.id,
                allValue.access_token,
              );
              matchedFbDest.forEach((matchedValue) => {
                if (fbgetLiveVideos.data.length) {
                  fbgetLiveVideos.data.forEach((resValue) => {
                    if (matchedValue.d_id === resValue.id) {
                      resValue.destination_id = matchedValue.d_id;
                      resValue.page_id = allValue.id;
                      unpublished.push(resValue);
                    }
                  });
                }
              });
            }
            // }else{//commented because of FB groups live videos returns empty data
            //     const fbgetLiveVideos =  await fbService.fbgetLiveVideos(allValue.id,getLocalStorageItem('FB_access_token'));
            //     matchedFbDest.forEach((matchedValue)=>{
            //         if(fbgetLiveVideos.data.length){
            //             fbgetLiveVideos.data.forEach((resValue)=>{
            //                 if(matchedValue.d_id === resValue.id){
            //                     resValue.destination_id = matchedValue.d_id;
            //                     resValue.page_id = allValue.id;
            //                     unpublished.push(resValue);
            //                 }
            //             })
            //         }
            //     });
            // }
          } catch (e) {
            dispatch(FBLiveVideoExistsFailure());
            dispatch(failure(e.toString()));
            dispatch(other());
            errorBlock(e, match);
            dispatch(otherFailure());
            dispatch(showSignIn());
          }
        });
        await Promise.all(result);
      }

      dispatch(FBData(unpublished));
      unpublished.length
        ? dispatch(FBLiveVideoExists())
        : dispatch(FBLiveVideoExistsFailure());
      dispatch(recentUsedDest(match));
    } catch (e) {
      dispatch(FBLiveVideoExistsFailure());
      dispatch(failure(e.toString()));
      dispatch(other());
      errorBlock(e, match);
      dispatch(otherFailure());
      dispatch(showSignIn());
    }

    function FBData(fbLiveVideoData) {
      return { type: fbConstants.FB_LIVE_VIDEOS, fbLiveVideoData };
    }

    function FBLiveVideoExists() {
      return { type: fbConstants.FB_LIVE_VIDEOS_EXISTS };
    }

    function FBLiveVideoExistsFailure() {
      return { type: fbConstants.FB_LIVE_VIDEOS_EXISTS_FAILURE };
    }

    function failure(error) {
      return { type: fbConstants.FB_LIVE_VIDEOS_FAILURE, error };
    }

    function other() {
      return { type: fbConstants.FB_LOADING_FALSE };
    }

    function otherFailure() {
      return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
    }

    function showSignIn() {
      return { type: fbConstants.SHOW_FB_SIGN_IN };
    }
  };
}
/**
 * @name recentUsedDest
 * @params match
 * @descriptiton function used to get recently used dest from Ovp
 **/
function recentUsedDest(match) {
  return async (dispatch) => {
    try {
      const userInfo = await fbService.userInfo();
      if (!!userInfo.ovpCredentials) {
        const ovpData = JSON.parse(userInfo.ovpCredentials);
        /* if (userInfo.ovpCredentials && ovpData[match.params.bossId]) {*/
        //problematic match.params
        if (!!JSON.parse(userInfo.ovpCredentials).fb_pages) {
          const recentDest = JSON.parse(userInfo.ovpCredentials).fb_pages;
          dispatch(success(recentDest));
          dispatch(otherSuccess());
        } else {
          dispatch(noRecentDest());
        }
        if (!!ovpData[match.params.bossId]) {
          const t1 = pickBy(ovpData[match.params.bossId], identity);
          delete ovpData[match.params.bossId];
          dispatch(ovpSuccess({ ...ovpData, currentUnit: { ...t1 } }));
        } else {
          dispatch(ovpFailure({}));
        }

        /*else {
                    dispatch(noRecentDest());
                    dispatch(otherOvp());
                    dispatch(ovpFailure({}))
                    if(userInfo.ovpCredentials && !(!!ovpData[match.params.bossId])){
                        dispatch(ovpSuccess({...ovpData}));
                    }else{
                        dispatch(ovpFailure({}))
                    }
                }*/
      } else {
        dispatch(noRecentDest());
        dispatch(otherOvp());
        dispatch(ovpFailure({}));
      }
      dispatch(streamLocationOptions(match));
    } catch (e) {
      dispatch(otherFailure());
      dispatch(failure(e));
      dispatch(other());
      errorBlock(e, match);
      dispatch(showSignIn());
    }

    function failure(error) {
      return { type: fbConstants.RECENT_DEST_FAILURE, error };
    }

    function success(destData) {
      return { type: fbConstants.RECENT_DEST, destData };
    }

    function otherSuccess() {
      return { type: fbConstants.RECENT_DEST_EXISTS };
    }

    function noRecentDest() {
      return { type: fbConstants.RECENT_DEST_EXISTS_FAILURE };
    }

    function otherFailure() {
      return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
    }

    function otherOvp() {
      return { type: fbConstants.SHOW_FB_SIGN_IN };
    }

    function showSignIn() {
      return { type: fbConstants.SHOW_FB_SIGN_IN };
    }

    function other() {
      return { type: fbConstants.FB_LOADING_FALSE };
    }

    function ovpSuccess(data) {
      return { type: commonConstants.OVP_DATA, data };
    }

    function ovpFailure(error) {
      return { type: commonConstants.OVP_DATA_FAILURE, error };
    }
  };
}
/**
 * @name streamLocationOptions
 * @params match
 * @descriptiton function used for making dropdown options
 **/
function streamLocationOptions(match) {
  return async (dispatch) => {
    try {
      const { fb_data } = store.getState().fb;
      personalOptions = fb_data.personal.filter((v) => {
        v.label = 'Personal profile';
        v.value = v.id;
        v.type = 'me';
        return v;
      });
      groupOptions = fb_data.groups.filter((v) => {
        v.label = v.name;
        v.value = v.id;
        v.type = 'group';
        return v;
      });

      groupOptions = orderBy(
        groupOptions,
        [(val) => val.name.toLowerCase()],
        ['asc'],
      ); //to sort option in ascending order
      pagesOptions = fb_data.pages.filter((v) => {
        v.label = v.name;
        v.value = v.id;
        v.type = 'page';
        return v;
      });
      pagesOptions = orderBy(
        pagesOptions,
        [(val) => val.name.toLowerCase()],
        ['asc'],
      ); //to sort option in ascending order
      eventsOptions = fb_data.events.filter((v) => {
        v.label = v.name;
        v.value = v.id;
        v.type = 'event';
        return v;
      });
      eventsOptions = orderBy(
        eventsOptions,
        [(val) => val.name.toLowerCase()],
        ['asc'],
      ); //to sort option in ascending order

      let v1 = [
        {
          label: '',
          options: personalOptions,
        },
        {
          label: 'Pages',
          options: pagesOptions,
        },
        {
          label: 'Groups',
          options: groupOptions,
        },
        {
          label: 'Events',
          options: eventsOptions,
        },
      ];
      if (!groupOptions.length) {
        v1.splice(2, 1);
      }
      if (!pagesOptions.length) {
        v1.splice(1, 1);
      }
      if (!eventsOptions.length) {
        v1.splice(3, 1);
      }
      dispatch(options(v1));
      dispatch(defaultData(match, v1));
    } catch (e) {
      dispatch(otherFailure());
      dispatch(other());
      errorBlock(e, match);
      dispatch(showSignIn());
    }
  };

  function otherFailure() {
    return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
  }

  function other() {
    return { type: fbConstants.FB_LOADING_FALSE };
  }

  function showSignIn() {
    return { type: fbConstants.SHOW_FB_SIGN_IN };
  }

  function options(Data) {
    return { type: fbConstants.STREAMLOCATION_OPTIONS, Data };
  }
}
/**
 * @name defaultData
 * @params match,optionsData
 * @descriptiton function used to get default data from Ovp
 **/
function defaultData(match, optionsData) {
  return async (dispatch) => {
    try {
      let selectedId;
      const { ovpData } = store.getState().common;
      currentUnitData = ovpData.currentUnit;
      const streamLocationOptions = optionsData;
      if (
        !!currentUnitData &&
        !!currentUnitData.selected_wheretostream &&
        !!currentUnitData.selected_location
      ) {
        selectedId =
          !!fbId && !!postId ? fbId : currentUnitData.selected_location;
        let v1;
        if (currentUnitData.selected_wheretostream === 'page') {
          v1 = await getCrossPage(selectedId, dispatch, match);
          if (!!!v1) {
            dispatch(removeOptionsAndSetDefault('pages', selectedId, match));
          } else {
            const whereToSTreamArr = !!store.getState().fb.otherData
              .whereToStream.length
              ? store.getState().fb.otherData.whereToStream
              : [];
            const pageIndex = findIndex(whereToSTreamArr, ['label', 'Pages']);
            const pagesValues =
              pageIndex > -1
                ? store.getState().fb.otherData.whereToStream[pageIndex].options
                : [];
            let p1 = pagesValues.filter((v) => v.id === selectedId);
            if (p1.length && p1[0].access_token) {
              defaultOption = {
                label: p1[0].label,
                access_token: p1[0].access_token,
                value: p1[0].value,
                type: 'page',
              };
            } else {
              dispatch(removeOptionsAndSetDefault('pages', selectedId, match));
            }
          }
        }
        if (currentUnitData.selected_wheretostream === 'group') {
          //remove respective option if there's no data in next line
          let groupIndex = findIndex(streamLocationOptions, [
            'label',
            'Groups',
          ]);
          if (groupIndex > -1) {
            v1 = streamLocationOptions[groupIndex].options.filter(
              (v) => v.id === selectedId,
            );
          } /*else{
                        dispatch(removeOptionsAndSetDefault("groups",selectedId,match))
                    }*/
          if (v1.length && v1[0]) {
            defaultOption = v1[0];
          } else {
            dispatch(removeOptionsAndSetDefault('groups', selectedId, match));
          }
        }
        if (currentUnitData.selected_wheretostream === 'event') {
          const eventIndex = findIndex(streamLocationOptions, [
            'label',
            'Events',
          ]);
          if (eventIndex > -1) {
            v1 = streamLocationOptions[eventIndex].options.filter(
              (v) => v.id === selectedId,
            );
          } /*else{
                        dispatch(removeOptionsAndSetDefault("groups",selectedId,match))
                    }*/
          if (v1.length && v1[0]) {
            defaultOption = v1[0];
          } else {
            dispatch(removeOptionsAndSetDefault('events', selectedId, match));
          }
          // v1 = streamLocationOptions[3].options.filter((v) => v.id === selectedId);
          // if(v1.length && v1[0]){
          //     defaultOption = v1[0];
          // }else{
          //     dispatch(removeOptionsAndSetDefault("events",selectedId,match))
          // }
        }
        if (currentUnitData.selected_wheretostream === 'me') {
          defaultOption = { type: 'me' };
        }
        dispatch(
          ovpDat({ defaultOption, currentUnit: { ...currentUnitData } }),
        );
      } else {
        defaultOption = { type: 'me' };
        dispatch(ovpDat({ defaultOption, currentUnit: {} }));
      }
      !!fbId && !!postId ? dispatch(postOrPage(match)) : dispatch(other());
    } catch (e) {
      dispatch(otherFailure());
      dispatch(other());
      errorBlock(e, match);
      dispatch(showSignIn());
    }
  };

  function otherFailure() {
    return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
  }

  function other() {
    return { type: fbConstants.FB_LOADING_FALSE };
  }

  function showSignIn() {
    return { type: fbConstants.SHOW_FB_SIGN_IN };
  }

  function ovpDat(Data) {
    return { type: fbConstants.FB_OVP_DATA, Data };
  }
}
/**
 * @name removeOptionsAndSetDefault
 * @params match
 * @descriptiton function used to determine type of fbId
 **/
function removeOptionsAndSetDefault(type, id) {
  return async (dispatch) => {
    try {
      const v1 = [
        {
          label: '',
          options: personalOptions,
        },
        {
          label: 'Groups',
          options: groupOptions,
        },
        {
          label: 'Pages',
          options: pagesOptions,
        },
        {
          label: 'Events',
          options: eventsOptions,
        },
      ];
      if (type === 'groups') {
        // eslint-disable-next-line array-callback-return
        groupOptions.filter((val, index) => {
          if (val.id === id) {
            groupOptions.splice(index, 1);
          }
        });
      } else if (type === 'pages') {
        // eslint-disable-next-line array-callback-return
        pagesOptions.filter((val, index) => {
          if (val.id === id) {
            pagesOptions.splice(index, 1);
          }
        });
      } else if (type === 'events') {
        // eslint-disable-next-line array-callback-return
        eventsOptions.filter((val, index) => {
          if (val.id === id) {
            eventsOptions.splice(index, 1);
          }
        });
      } else if (type === 'all') {
        v1.splice(1, 1);
        v1.splice(2, 1);
        v1.splice(3, 1);
      }
      if (!groupOptions.length) {
        v1.splice(1, 1);
      }
      if (!pagesOptions.length) {
        v1.splice(2, 1);
      }
      if (!eventsOptions.length) {
        v1.splice(3, 1);
      }
      dispatch(options(v1));
      defaultOption = { type: 'me' };
      dispatch(ovpDat({ defaultOption, currentUnit: { ...currentUnitData } }));
    } catch (e) {
      //errorBlock(e,match)silenced
      defaultOption = { type: 'me' };
      dispatch(ovpDat({ defaultOption, currentUnit: { ...currentUnitData } }));
    }
  };

  function ovpDat(Data) {
    return { type: fbConstants.FB_OVP_DATA, Data };
  }
  function options(Data) {
    return { type: fbConstants.STREAMLOCATION_OPTIONS, Data };
  }
}

/**
 * @name postOrPage
 * @params match
 * @descriptiton function used to determine type of fbId
 **/
function postOrPage(match) {
  let groups, pages, events, personal;
  const where = store.getState().fb.otherData.whereToStream;
  // eslint-disable-next-line no-unused-vars
  let v1, v3;
  let t1 = {};
  return async (dispatch) => {
    try {
      // eslint-disable-next-line array-callback-return
      where.filter((val) => {
        if (val.label.toLowerCase() === 'groups') {
          groups = val.options.filter((value) => value.id === fbId);
        } else if (val.label.toLowerCase() === 'pages') {
          pages = val.options.filter((value) => value.id === fbId);
        } else if (val.label.toLowerCase() === 'events') {
          events = val.options.filter((value) => value.id === fbId);
        } else if (val.label.toLowerCase() === '') {
          personal = val.options.filter((value) => value.id === fbId);
        }
      });
      const v2 =
        (!!groups ? groups[0] : null) ||
        (!!pages ? pages[0] : null) ||
        (!!events ? events[0] : null) ||
        (!!personal ? personal[0] : null);
      if (!!v2) {
        v3 = await destinationService.AWSLucAPIDestination(postId);
      } else {
        dispatch(removeOptionsAndSetDefault('all', match));
      }
      /*const v3 = !!v2 ? await destinationService.AWSLucAPIDestination(postId) :dispatch(removeOptionsAndSetDefault("all",match))*/
      //insert rollbar info here
      if (pages.length) {
        v1 = await getCrossPage(fbId, dispatch);
        if (!!!v1) {
          dispatch(removeOptionsAndSetDefault('pages', fbId, match));
        } else {
          //add a condition to remove pages from options if get cross page fails
          if (!!v1) {
            t1 = await crossPostShared(dispatch, postId, pages[0].access_token);
          } else {
            dispatch(removeOptionsAndSetDefault('pages', fbId, match));
          }
          /*t1 = v1.length ? await crossPostShared(dispatch,postId, pages[0].access_token) : dispatch(removeOptionsAndSetDefault("pages",fbId,match))*/
          if (!!!t1) {
            dispatch(removeOptionsAndSetDefault('pages', fbId, match));
          }
        }
      }
      !!v3.length ? dispatch(success(...v3)) : dispatch(success({})); //needs insight
      !!!t1 ? dispatch(other()) : dispatch(getVideoStatus(match));
    } catch (e) {
      dispatch(error(e.toString()));
      errorBlock(e, match);
    }
  };

  // eslint-disable-next-line no-unused-vars
  function success(Data) {
    return { type: fbConstants.GET_FB_AWS_DEST, Data };
  }

  function error(error) {
    return { type: fbConstants.GET_FB_AWS_DEST_FAIL, error };
  }
  // eslint-disable-next-line no-unused-vars
  function other() {
    return { type: fbConstants.FB_LOADING_FALSE };
  }
}
/**
 * @name crossPostShared
 * @params postId,token
 * @descriptiton function used to get cross post pages
 **/
function crossPostShared(dispatch, postId, token) {
  return new Promise(async (resolve) => {
    try {
      const v2 = await fbService.fbGetCrossPosts(postId, token);
      if (v2.data.length) {
        dispatch(crossPostSuc(v2.data));
        resolve(v2.data);
      } else {
        dispatch(crossPostErr());
        resolve(true);
      }
    } catch (e) {
      dispatch(crossPostErr());
      //errorBlock(e);silenced to continue execution
      resolve(false);
    }
  });

  function crossPostSuc(Data) {
    return { type: fbConstants.GET_CROSS_POST_SHARED, Data };
    //check reducer for this
  }

  function crossPostErr() {
    return { type: fbConstants.GET_CROSS_POST_SHARED_FAIL };
  }
}
/**
 * @name getVideoStatus
 * @params match
 * @descriptiton function used to determine video status
 **/
// eslint-disable-next-line no-unused-vars
function getVideoStatus(match) {
  let videoStat;
  return async (dispatch) => {
    try {
      const t1 = await dispatch(fbLiveVideoStatus(postId, match.params.bossId));
      if (!!t1.video.id) {
        const v1 = await fbService.fbGetVideoStatus(t1.video.id);
        let tags, reqs;
        if (!!v1.content_tags) {
          tags = map(v1.content_tags, async (value) => {
            const tag = await fbService.fbGetContentTag(value);
            tag.label = tag.name;
            tag.key = tag.id;
            return tag;
          });
          reqs = await Promise.all(tags);
        }
        // eslint-disable-next-line no-unused-vars
        const t2 = !!reqs ? reqs : (reqs = []);
        videoStat = {
          ...v1,
          content_tags: [...reqs],
        };
        dispatch(
          success({ videoStatus: { ...videoStat }, postStatus: { ...t1 } }),
        );
        dispatch(other());
      } else {
        dispatch(success({ videoStatus: {}, postStatus: {} }));
        dispatch(other());
      }
    } catch (e) {
      dispatch(success({ videoStatus: {}, postStatus: {} }));
      dispatch(other());
      //errorBlock(e,match);silence the errors
    }
  };

  function other() {
    return { type: fbConstants.FB_LOADING_FALSE };
  }

  function success(Data) {
    return { type: fbConstants.GET_FB_VIDEO_STATUS, Data };
  }
}
/**
 * @name delFBDest
 * @params destId,match
 * @descriptiton function used to delete fb destination(actually deleting AWS or LUC dest)
 **/
function delFBDest(destId, match) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      await savedDestinationsService.delAWSDest(destId);
      dispatch(liveStreamActions.refreshStreamToolsDestination());
      const lucDest = await savedDestinationsService.getLucDest();
      let lucDests = lucDest.filter((values) => {
        return values.streaming_destination.external_id === destId;
      });
      if (lucDests.length) {
        await savedDestinationsService.delLucDest(lucDests[0].id);
        const getAwsDestByInven = await editUnitsService.getAWSDestInven();
        if (getAwsDestByInven.dest.length) {
          dispatch(successOther(getAwsDestByInven));
        } else {
          dispatch(successOther(getAwsDestByInven));
        }
        dispatch(success(lucDests));
        notify('success', i18n.t('FBDESTDELETED'));
        dispatch(hideSpinner());
        dispatch(fbLoginCheck(match));
      } else {
        const getAwsDestByInven = await editUnitsService.getAWSDestInven();
        if (getAwsDestByInven.dest.length) {
          dispatch(successOther(getAwsDestByInven));
        } else {
          dispatch(successOther(getAwsDestByInven));
        }
        dispatch(success(lucDest));
        notify('success', i18n.t('FBDESTDELETED'));
        dispatch(hideSpinner());
        dispatch(fbLoginCheck(match));
      }
    } catch (e) {
      dispatch(otherFailureRes1());
      dispatch(failure(e.toString()));
      errorBlock(e, match);

      dispatch(showSignIn());
      dispatch(hideSpinner());
    }
  };

  function success(sucData) {
    return { type: fbConstants.FB_DELETE_DEST, sucData };
  }

  function failure(error) {
    return { type: fbConstants.FB_DELETE_DEST_FAIL, error };
  }

  function otherFailureRes1() {
    return { type: fbConstants.FB_SESSION_EXPIRED_TRUE };
  }

  function showSignIn() {
    return { type: fbConstants.SHOW_FB_SIGN_IN };
  }

  function successOther(awsDestListData) {
    return { type: editunitConstants.GET_AWS_DESTBYINVEN, awsDestListData };
  }
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name getLocation
 * @params value,match
 * @descriptiton fetch a location based on typed query
 **/
function getLocation(value, match) {
  return async () => {
    try {
      const getLocation = await fbService.fbGetLocations(value);
      const values = getLocation.data.filter((value) => {
        value.key = value.id;
        value.label = value.name;
        return value;
      });
      return values;
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name getFriends
 * @params match
 * @descriptiton function used for fetching user's friends
 **/
function getFriends(match) {
  return async () => {
    try {
      const friends = await fbService.fbGetFriends();
      const values = friends.data.filter((value) => {
        value.key = value.id;
        value.label = value.name;
        value.image = value.picture;
        return value;
      });
      return values;
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name getAdTags
 * @params value,match
 * @descriptiton function used to get ad tags based on typed query
 **/
function getAdTags(value, match) {
  return async () => {
    try {
      const friends = await fbService.fbAdinterest(value);
      const values = friends.data.filter((value) => {
        value.key = value.id;
        value.label = value.name;
        return value;
      });
      return values;
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name getCrossPage
 * @params pageId,dispatch,match
 * @descriptiton function used to fetch cross posted pages
 **/
function getCrossPage(pageId, dispatch, match) {
  return new Promise(async (resolve, reject) => {
    try {
      const fbPages = store.getState().fb.fb_data.pages;
      const selectedPage = fbPages.filter((v) => v.id === pageId);
      //what if user has no fb page
      if (selectedPage.length && selectedPage[0].access_token) {
        const fbgetLiveVideos = await fbService.fbGetCrossPage(
          pageId,
          selectedPage[0].access_token,
        );
        if (fbgetLiveVideos.length) {
          resolve(fbgetLiveVideos);
        } else {
          resolve(true);
        }
        dispatch(crossData(fbgetLiveVideos));
      } else {
        resolve(false);
      }
    } catch (e) {
      if (!!e.response && e.response.status === 403) {
        //notify('error', i18n.t('CROSSPOSTERROR'));
        resolve(true);
      }
      if (!!e.response && !!e.response.status) {
        resolve(false);
      } else {
        dispatch(crossError(e.toString()));
        reject(e);
        errorBlock(e, match);
      }
    }
  });

  function crossData(Data) {
    return { type: fbConstants.FB_CROSSPOST_PAGE, Data };
  }

  function crossError(error) {
    return { type: fbConstants.FB_CROSSPOST_PAGE_ERR, error };
  }
}

/**
 * @name fbGetGroupByPage
 * @params pageid,match
 * @descriptiton fb Get Geoup By Page
 **/
function fbGetGroupByPage(pageId, match) {
  return async () => {
    try {
      const fbPages = store.getState().fb.fb_data.pages;
      const selectedPage = fbPages.filter((v) => v.id === pageId);
      return await fbService.fbGetGeoupByPageId(
        pageId,
        selectedPage[0].access_token,
      );
    } catch (e) {
      errorBlock(e, match);
    }
  };
}

/**
 * @name checkCrossPostPage
 * @params pageid,match
 * @descriptiton check for cross posting
 **/
function checkCrossPostPage(pageId, match) {
  return async (dispatch) => {
    try {
      const fbPages = store.getState().fb.fb_data.pages;
      const selectedPage = fbPages.filter((v) => v.id === pageId);
      const fbgetLiveVideos = await fbService.fbGetCrossPage(
        pageId,
        selectedPage[0].access_token,
      );
      if (fbgetLiveVideos.length) {
        dispatch(crossData(fbgetLiveVideos));
      } else {
        dispatch(crossData([]));
      }
    } catch (e) {
      dispatch(crossError(e.toString()));
      if (
        e.response.data.error.message
          .toLowerCase()
          .includes('user does not have sufficient administrative')
      ) {
        notify('error', i18n.t('CROSSPOSTERROR'));
      } else {
        errorBlock(e, match);
      }
    }
  };

  function crossData(Data) {
    return { type: fbConstants.FB_CROSSPOST_PAGE, Data };
  }

  function crossError() {
    return { type: fbConstants.FB_CROSSPOST_PAGE_ERR };
  }
}
/**
 * @name getAdLocation
 * @params value,match
 * @descriptiton function used to get location for ads
 **/
function getAdLocation(value, match) {
  return async () => {
    try {
      const friends = await fbService.fbGetAdLocations(value);
      const values = friends.data.filter((value) => {
        value.label = value.name;
        return value;
      });
      return values;
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name getPostSponsors
 * @params id,match
 * @descriptiton function used to get post sponsor
 **/
function getPostSponsors(id, match) {
  return async () => {
    try {
      const friends = await fbService.fbPostSponsors(id);
      const v1 = [
        {
          key: Math.floor(Math.random() * 10),
          label: friends.name,
          verified: friends.verification_status === 'not_verified',
          id: friends.id,
          value: friends.name,
        },
      ];
      return v1;
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name createLiveVideo
 * @params updatepayload,formikdata,formdata,match
 * @descriptiton function used to create a Fb post
 **/
function createLiveVideo(updatepayload, formikData, facebookForm, match) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const data = await fbService.fbCreateLiveVideo(
        formikData.streamLocation.value,
        formikData.streamLocation.access_token,
        facebookForm,
      );
      const streamKey = data.stream_url.substring(
        data.stream_url.lastIndexOf('/') + 1,
      );
      const ingestUrl = data.secure_stream_url.substring(
        0,
        data.secure_stream_url.lastIndexOf('/') + 1,
      );
      const payload = {
        ...data,
        streamKey,
        ingestUrl,
      };
      dispatch(createVideo({ ...payload }));
      dispatch(updateLiveVideo(updatepayload, data.id, formikData, match));
    } catch (e) {
      dispatch(createVideoFail(e.toString()));
      if (
        !!e.response &&
        e.response.status === 403 &&
        !!e.response.data &&
        !!e.response.data.error
      ) {
        if (
          (!!e.response.data.error.message &&
            e.response.data.error.message
              .toLowerCase()
              .includes(
                'requires either admin permissions or publish_to_groups',
              )) ||
          e.response.data.error.code === 200
        ) {
          //specially added for 403 error for groups
          notify('error', e.response.data.error.message);
        }
      } else if (
        !!e.response &&
        e.response.data.error.code === 200 &&
        !!e.response.data &&
        !!e.response.data.error.error_user_msg
      ) {
        notify(
          'error',
          e.response.data.error.error_user_msg.replace(
            ' Learn more at https://www.facebook.com/business/help/216491699144904',
            '',
          ),
        );
      } else {
        errorBlock(e, match);
      }
      dispatch(hideSpinner());
    }
  };

  function createVideo(Data) {
    return { type: fbConstants.FB_CREATE_LIVE_VIDEO, Data };
  }

  function createVideoFail(error) {
    return { type: fbConstants.FB_CREATE_LIVE_VIDEO_FAIL, error };
  }

  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name updateLiveVideo
 * @params updatepayload,videoId,formikdata,match
 * @descriptiton function used to update other details to a live video post
 **/
function updateLiveVideo(updatePayload, videoId, formikData, match) {
  return async (dispatch) => {
    try {
      const data = await fbService.fbUpdateLiveVideo(
        videoId,
        {
          access_token: formikData.streamLocation.access_token,
          cross_share_to_group_ids:
            updatePayload.cross_share_to_group_ids || '',
        },
        updatePayload,
      );
      dispatch(updateVideo(data));
      const { streamKey } = store.getState().fb.liveVideo.create;
      const { ingestUrl } = store.getState().fb.liveVideo.create;
      const { id } = store.getState().fb.liveVideo.create;
      dispatch(intermediate(ingestUrl, streamKey, id, formikData, match));
    } catch (e) {
      dispatch(updateVideoFail(e.toString()));
      errorBlock(e, match);
      dispatch(hideSpinner());
    }
  };

  function updateVideo(Data) {
    return { type: fbConstants.FB_UPDATE_LIVE_VIDEO, Data };
  }

  function updateVideoFail(error) {
    return { type: fbConstants.FB_UPDATE_LIVE_VIDEO_FAIL, error };
  }

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name intermediate
 * @params ingestUrl, streamKey, id, formikData, match
 * @descriptiton an intermediate function
 **/
function intermediate(ingestUrl, streamKey, id, formikData, match) {
  return async (dispatch) => {
    try {
      const payload = {
        destinationName: formikData?.destination,
        selectedProfile: formikData?.profile?.name,
        primary_url: ingestUrl,
        secondary_url: '',
        stream_name: streamKey,
        username: '',
        password: '',
        id: id,
        streaming_provider: 'Facebook',
        overrideResolution: formikData?.resolutionBox
          ? formikData?.overrideResolution?.value
          : '',
        overrideFramerate: formikData?.frameRateBox
          ? formikData?.overrideFrame?.value
          : '',
        overrideBitrate: formikData?.bitRateBox
          ? formikData?.overrideBitrate
          : '',
        fb_page_id:
          formikData?.streamLocation?.type === 'page'
            ? formikData?.streamLocation?.value
            : '',
        audio_bitrate_override: formikData.audioBitRateBox
          ? formikData.audioBitRate.value
          : null,
        // cross_share_to_group_ids:
      };
      dispatch(
        commonAction.createAwsDestination(
          payload,
          match.params.bossId,
          formikData,
        ),
      );
    } catch (e) {
      errorBlock(e);
    }
  };
}
/**
 * @name facebookLogout
 * @params match
 * @descriptiton logs out of FB
 **/
function facebookLogout(match) {
  return async (dispatch) => {
    try {
      // delete getLocalStorageItem('FB_access_token');
      // delete cookies.get('FB_user_id');
      removeLocalStorageItem('FB_access_token');
      removeLocalStorageItem('FB_user_id');
      const logout = await fbService.fbLogout();
      dispatch(fbInitialState(match));
      if (logout) {
        notify('info', i18n.t('FBLOGOUT'));
        history.push('/dashboard/edit/' + match.params.bossId);
      }
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name fbInitialState
 * @params match
 * @descriptiton function used to reset fb redux state
 **/
function fbInitialState(match) {
  return async (dispatch) => {
    try {
      dispatch(initial());
    } catch (e) {
      errorBlock(e, match);
    }
  };

  function initial() {
    return { type: fbConstants.FB_INITIAL_STATE };
  }
}

let counter = 0;
/**
 * @name fbLiveVideoStatus
 * @params id,bossId
 * @descriptiton function used to get live video status
 **/
function fbLiveVideoStatus(id, bossId, pageId) {
  //const { units } = store.getState().unit;
  // const obj = cookies.get(`FB_${bossId}`);
  // const pageId = obj && (obj.type === 'page' ? obj.value : null);
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const accessToken = await dispatch(getAccessToken(pageId));
        const data = await fbService.fbGetLiveVideoStatus(id, accessToken);
        dispatch(statusSuccess(data));
        resolve(data);
      } catch (e) {
        // const selectedUnit = units.filter((value) => value.id === bossId);
        // if (!!selectedUnit[0]) {
        //     if (selectedUnit[0].status === 'streaming') {
        //         await unitService.stopUnit(bossId);
        //     }
        // }
        dispatch(statusFail(e.toString()));
        if (
          !!e.response &&
          !!e.response.data &&
          !!e.response.data.error &&
          e.response.data.error.type === 'OAuthException' &&
          e.response.config.url
            .toLowerCase()
            .includes('https://graph.facebook.com')
        ) {
          // dispatch(fbInitialState(bossId));
        }
        errorBlock(e, bossId);
        if (
          !!e.response &&
          !!e.response.data &&
          e.response.data.error.code === 100 &&
          e.response.data.error.error_subcode === 33
        ) {
          try {
            const pageToken =
              pageId && (await fbService.fbGetPageTokenByPageId(pageId));
            let d = new Date();
            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
            if (pageToken?.access_token) {
              cookies.set(`${pageId}_FB_page_token`, pageToken.access_token, {
                path: '/',
                expires: d,
                secure: true,
              });
              // to stop infinite api calling when post actually gets deleted
              if (counter++ > 3) {
                dispatch(statusSuccess({ status: 'DELETED' }));
                reject({ status: 'DELETED' });
                counter = 0;
              }
            } else {
              dispatch(statusSuccess({ status: 'DELETED' }));
              reject({ status: 'DELETED' });
            }
          } catch (e) {
            dispatch(statusSuccess({ status: 'DELETED' }));
            reject({ status: 'DELETED' });
            // eslint-disable-next-line no-console
            console.log(
              'error while getting page token',
              e?.response?.data?.error,
            );
            errorBlock(e, bossId);
          }
        } else {
          reject(e);
        }
      }
    });
  };

  function statusSuccess(Data) {
    return { type: fbConstants.FB_LIVE_VIDEO_STATUS, Data };
  }

  function statusFail(error) {
    return { type: fbConstants.FB_LIVE_VIDEO_STATUS_ERROR, error };
  }
}

function getAccessToken(pageId) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      let pageAccessTokenMap = store.getState().fb?.fb_data?.pages;
      if (
        pageAccessTokenMap.length === 0 &&
        !!getLocalStorageItem('FB_access_token')
      ) {
        await dispatch(fbGetPages());
        pageAccessTokenMap = store.getState().fb?.fb_data?.pages;
      }
      let accessToken;
      if (!!pageId) {
        for (let item of pageAccessTokenMap) {
          if (pageId === item.id) {
            accessToken = item.access_token;
            break;
          }
        }
      } else {
        accessToken = getLocalStorageItem('FB_access_token');
      }
      resolve(accessToken);
    });
  };
}
/**
 * @name fbGetPostAnalytics
 * @params postId,bossId,token
 * @descriptiton function used to fetch reactions for a post
 **/
function fbGetPostAnalytics(id, bossId, token) {
  // const { units } = store.getState().unit;
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await fbService.fbGetPostReactions(id, token);
        const data2 = await fbService.fbGetPostComments(id, token);
        const data3 = await fbService.fbGetPostViews(id, token, 'live_views');
        dispatch(
          statusSuccess({
            comments: [...data2],
            views: data3,
            reactions: [...data],
          }),
        );
        resolve({ comments: [...data2], views: data3, reactions: [...data] });
      } catch (e) {
        // const selectedUnit = units.filter((value) => value.id === bossId);
        // if (!!selectedUnit[0]) {
        //     if (selectedUnit[0].status === 'streaming') {
        //         await unitService.stopUnit(bossId);
        //     }
        // }
        dispatch(statusFail(e.toString()));
        errorBlock(e, bossId);
        reject(e);
      }
    });
  };

  function statusSuccess(Data) {
    return { type: fbConstants.FB_POST_ANALYTICS, Data };
  }

  function statusFail(error) {
    return { type: fbConstants.FB_POST_ANALYTICS_FAIL, error };
  }
}
/**
 * @name fbUpdateLiveVideo
 * @params id,payload,formikData,match
 * @descriptiton intermediate function used to update live video
 **/
function fbUpdateLiveVideo(id, payload, formikData, match) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const pageId =
        formikData?.streamLocation?.type === 'me'
          ? ''
          : formikData?.streamLocation?.id;
      await dispatch(fbUpdateLiveVideoStatus(id, payload, match, pageId));
      const t2 = await dispatch(fbLiveVideoStatus(id, null, pageId));
      const streamKey = t2.stream_url.substring(
        t2.stream_url.lastIndexOf('/') + 1,
      );
      const ingestUrl = t2.secure_stream_url.substring(
        0,
        t2.secure_stream_url.lastIndexOf('/') + 1,
      );
      dispatch(intermediate(ingestUrl, streamKey, id, formikData, match));
    } catch (e) {
      errorBlock(e);
      dispatch(hideSpinner());
    }
  };
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name fbUpdateLiveVideoStatus
 * @params id, payload, match
 * @descriptiton function used to update live video status
 **/
function fbUpdateLiveVideoStatus(id, payload, match, pageId) {
  return async (dispatch) => {
    try {
      const accessToken = await dispatch(getAccessToken(pageId));
      const data = await fbService.fbUpdateLiveVideoStatus(
        id,
        payload,
        accessToken,
      );
      dispatch(statusSuccess(data));
      return Promise.resolve(data);
    } catch (e) {
      dispatch(statusFail(e));
      errorBlock(e, match);
      return Promise.reject(e);
    }
  };

  function statusSuccess(Data) {
    return { type: fbConstants.FB_UPDATE_LIVE_VIDEO_STATUS, Data };
  }

  function statusFail(error) {
    return { type: fbConstants.FB_UPDATE_LIVE_VIDEO_STATUS_ERR, error };
  }
}

function fbGetUserDetails(match) {
  return async (dispatch) => {
    try {
      const token = getLocalStorageItem('FB_access_token');
      const data = await fbService.fbGetUserDetails(token);
      dispatch(statusSuccess(data));
      return Promise.resolve(data);
    } catch (e) {
      dispatch(statusFail(e));
      errorBlock(e, match);
      return Promise.reject(e);
    }
  };

  function statusSuccess(Data) {
    return { type: fbConstants.GET_FB_USER_DETAILS, Data };
  }

  function statusFail(error) {
    return { type: fbConstants.GET_FB_USER_DETAILS_FAIL, error };
  }
}

function fbImageUpload(payload) {
  return async () => {
    try {
      const res = await fbService.fbImageUpload(payload);
      return res;
    } catch (e) {
      // eslint-disable-next-line
      console.log('error', e);
    }
  };
}
