import { fbConstants } from '../constants';

const initialState = {
  fbLoading: true,
  authIsLoading: true,
  showfbSignIn: true,
  showfbLoading: true,
  fb_data: {
    personal: [],
    login: [],
    pages: [],
    groups: [],
    events: [],
    live_videos: [],
  },
  otherData: {
    whereToStream: [],
    ovpData: { defaultOption: {}, currentUnit: {} },
    crossPostPages: [],
    crosspostStatus: [],
    crossPostError: false,
  },
  liveVideo: {
    create: {},
    update: {},
    status: { status: '' },
    updateStatus: {},
  },
  analytics: { views: 0, reactions: [], comments: [], error: {} },
  edit: {
    videoStatus: {},
    dest: {},
    postStatus: {},
    crossPostPages: [
      {
        name: 'Saywhat Organisation',
        id: '419693971424201',
      },
      {
        name: 'Manica Post Zimpapers',
        id: '152535288239280',
      },
      {
        name: 'National Movement of Catholic Students Zimbabwe',
        id: '1502543403346398',
      },
      {
        name: 'Chronicle Zimbabwe',
        id: '138418633000347',
      },
      {
        name: 'Centre For Innovation and Technology',
        id: '1758802921017821',
      },
      {
        name: 'Pamtengo Radio',
        id: '303281073135557',
      },
      {
        name: 'The Herald-Zimbabwe',
        id: '380838785328009',
      },
      {
        name: 'Zimbabwe Coalition on Debt and Development',
        id: '515876198475634',
      },
      {
        name: 'Agribusiness Media',
        id: '1462048987254768',
      },
      {
        name: 'Smtv News & Sports Network',
        id: '102077232136301',
      },
      {
        name: 'Zimbo Live TV',
        id: '1404213366520536',
      },
      {
        name: 'earGROUND',
        id: '1582253971858723',
      },
      {
        name: 'National AIDS Council of Zimbabwe',
        id: '214320811915235',
      },
      {
        name: 'ZimEye',
        id: '150154425045764',
      },
      {
        name: 'Zimbabwe Election Support Network(ZESN)',
        id: '183256165057631',
      },
      {
        name: 'YAFM',
        id: '1633816456841458',
      },
      {
        name: 'Zim Soccernet',
        id: '107895790928035',
      },
      {
        name: 'Doves Holdings',
        id: '551763248178408',
      },
      {
        name: 'Youth Advocates',
        id: '133115330067709',
      },
      {
        name: 'Jasen Mphepo Little Theatre -JMLT',
        id: '112019083666149',
      },
      {
        name: '263Chat',
        id: '304646782971438',
      },
      {
        name: 'Africa Network Television',
        id: '2247834111901472',
      },
      {
        name: 'The Zimbabwenewslive',
        id: '1497226467210057',
      },
      {
        name: 'Bhanditi TV',
        id: '213833035687985',
      },
    ],
    crossPostShared: [
      {
        name: 'Saywhat Organisation',
        id: '419693971424201',
      },
      {
        name: 'Manica Post Zimpapers',
        id: '152535288239280',
      },
      {
        name: 'National Movement of Catholic Students Zimbabwe',
        id: '1502543403346398',
      },
      {
        name: 'Chronicle Zimbabwe',
        id: '138418633000347',
      },
      {
        name: 'Centre For Innovation and Technology',
        id: '1758802921017821',
      },
      {
        name: 'Pamtengo Radio',
        id: '303281073135557',
      },
      {
        name: 'The Herald-Zimbabwe',
        id: '380838785328009',
      },
      {
        name: 'Zimbabwe Coalition on Debt and Development',
        id: '515876198475634',
      },
      {
        name: 'Agribusiness Media',
        id: '1462048987254768',
      },
      {
        name: 'Smtv News & Sports Network',
        id: '102077232136301',
      },
      {
        name: 'Zimbo Live TV',
        id: '1404213366520536',
      },
      {
        name: 'earGROUND',
        id: '1582253971858723',
      },
      {
        name: 'National AIDS Council of Zimbabwe',
        id: '214320811915235',
      },
      {
        name: 'ZimEye',
        id: '150154425045764',
      },
      {
        name: 'Zimbabwe Election Support Network(ZESN)',
        id: '183256165057631',
      },
      {
        name: 'YAFM',
        id: '1633816456841458',
      },
      {
        name: 'Zim Soccernet',
        id: '107895790928035',
      },
      {
        name: 'Doves Holdings',
        id: '551763248178408',
      },
      {
        name: 'Youth Advocates',
        id: '133115330067709',
      },
      {
        name: 'Jasen Mphepo Little Theatre -JMLT',
        id: '112019083666149',
      },
      {
        name: '263Chat',
        id: '304646782971438',
      },
      {
        name: 'Africa Network Television',
        id: '2247834111901472',
      },
      {
        name: 'The Zimbabwenewslive',
        id: '1497226467210057',
      },
      {
        name: 'Bhanditi TV',
        id: '213833035687985',
      },
    ],
  },
  fb_live_videos_exist: false,
  recent_Destinations: [],
  deleteEvent: [],
  fb_Session_Expired: true,
  recent_Destinations_Exists: true,
  user_details: {},
};
const {
  FB_LOADING_FALSE,
  FB_LOADING_TRUE,
  FB_PAGES,
  FB_POST_SUCCESS,
  FB_POST_FAILURE,
  FB_PAGES_FAILURE,
  FB_PAGES_REQ,
  FB_GROUPS,
  FB_GROUPS_FAILURE,
  FB_EVENTS,
  FB_EVENTS_FAILURE,
  FB_LIVE_VIDEOS,
  FB_LIVE_VIDEOS_FAILURE,
  FB_LOGIN_FAILURE,
  FB_LOGIN,
  FB_PERSONAL,
  FB_LIVE_VIDEOS_EXISTS_FAILURE,
  FB_LIVE_VIDEOS_EXISTS,
  RECENT_DEST_EXISTS_FAILURE,
  RECENT_DEST_EXISTS,
  RECENT_DEST_FAILURE,
  RECENT_DEST,
  SHOW_FB_SIGN_IN,
  SHOW_FB_SIGN_IN_FALSE,
  FB_SESSION_EXPIRED_FALSE,
  FB_SESSION_EXPIRED_TRUE,
  FB_DELETE_DEST_FAIL,
  FB_DELETE_DEST,
  STREAMLOCATION_OPTIONS,
  FB_OVP_DATA,
  FB_CROSSPOST_PAGE,
  FB_CROSSPOST_PAGE_ERR,
  FB_CREATE_LIVE_VIDEO_FAIL,
  FB_CREATE_LIVE_VIDEO,
  FB_UPDATE_LIVE_VIDEO,
  FB_UPDATE_LIVE_VIDEO_FAIL,
  FB_INITIAL_STATE,
  FB_LIVE_VIDEO_STATUS,
  FB_LIVE_VIDEO_STATUS_ERROR,
  FB_UPDATE_LIVE_VIDEO_STATUS,
  FB_UPDATE_LIVE_VIDEO_STATUS_ERR,
  FB_POST_ANALYTICS_FAIL,
  FB_POST_ANALYTICS,
  GET_FB_VIDEO_STATUS_FAIL,
  GET_FB_VIDEO_STATUS,
  GET_FB_AWS_DEST_FAIL,
  GET_FB_AWS_DEST,
  GET_CROSS_POST_SHARED,
  GET_CROSS_POST_SHARED_FAIL,
  GET_FB_USER_DETAILS,
  GET_FB_USER_DETAILS_FAIL,
} = fbConstants;

export function fb(state = initialState, payload) {
  switch (payload.type) {
    case FB_LOADING_TRUE:
      return { ...state, fbLoading: true };
    case FB_LOADING_FALSE:
      return { ...state, fbLoading: false };
    case FB_LOGIN:
      return { ...state, fb_data: { login: payload.fbLoginData } };
    case FB_LOGIN_FAILURE:
      return { ...state, fb_data: { ...payload.error } };
    case FB_PAGES_REQ:
      return { ...state, showfbLoading: true };
    case FB_POST_SUCCESS:
      return {
        ...state,
        otherData: {
          ...state.otherData,
          crosspostStatus: payload.postData,
          
        },
        showfbLoading: false,
      };
    case FB_POST_FAILURE:
      return { ...state, fb_data: { ...payload.error } };
    case FB_PERSONAL:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: payload.fbData,
          pages: [],
          groups: [],
          events: [],
          live_videos: [],
        },
        showfbLoading: true,
      };
    case FB_PAGES:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: payload.fbGetPagesData,
          groups: [],
          events: [],
          live_videos: [],
        },
      };
    case FB_PAGES_FAILURE:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: payload.error,
          groups: [],
          events: [],
          live_videos: [],
        },
        showfbLoading: false,
      };
    case FB_GROUPS:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: state.fb_data.pages,
          groups: payload.fbGetGroupsData,
          events: [],
          live_videos: [],
        },
      };
    case FB_GROUPS_FAILURE:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: state.fb_data.pages,
          groups: payload.error,
          events: [],
          live_videos: [],
        },
        showfbLoading: false,
      };
    case FB_EVENTS_FAILURE:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: state.fb_data.pages,
          groups: state.fb_data.groups,
          events: payload.error,
          live_videos: [],
        },
        showfbLoading: false,
      };
    case FB_EVENTS:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: state.fb_data.pages,
          groups: state.fb_data.groups,
          events: payload.fbGetEventsData,
          live_videos: [],
        },
      };
    case FB_LIVE_VIDEOS_FAILURE:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: state.fb_data.pages,
          groups: state.fb_data.groups,
          events: state.fb_data.events,
          live_videos: payload.error,
        },
        showfbLoading: false,
      };
    case FB_LIVE_VIDEOS:
      return {
        ...state,
        fb_data: {
          login: state.fb_data.login,
          personal: state.fb_data.personal,
          pages: state.fb_data.pages,
          groups: state.fb_data.groups,
          events: state.fb_data.events,
          live_videos: payload.fbLiveVideoData,
        },
      };
    case FB_LIVE_VIDEOS_EXISTS:
      return { ...state, fb_live_videos_exist: true, showfbLoading: true };
    case FB_LIVE_VIDEOS_EXISTS_FAILURE:
      return { ...state, fb_live_videos_exist: false, showfbLoading: false };
    case RECENT_DEST_FAILURE:
      return {
        ...state,
        recent_Destinations: payload.error,
        showfbLoading: false,
      };
    case RECENT_DEST:
      return {
        ...state,
        recent_Destinations: payload.destData,
        showfbLoading: false,
      };
    case RECENT_DEST_EXISTS:
      return { ...state, recent_Destinations_Exists: true };
    case RECENT_DEST_EXISTS_FAILURE:
      return {
        ...state,
        recent_Destinations_Exists: false,
        showfbLoading: false,
      };
    case SHOW_FB_SIGN_IN_FALSE:
      return { ...state, showfbSignIn: false };
    case SHOW_FB_SIGN_IN:
      return { ...state, showfbSignIn: true };
    case FB_SESSION_EXPIRED_FALSE:
      return { ...state, fb_Session_Expired: false };
    case FB_SESSION_EXPIRED_TRUE:
      return { ...state, fb_Session_Expired: true };
    case FB_DELETE_DEST_FAIL:
      return { ...state, deleteEvent: [payload.error] };
    case FB_DELETE_DEST:
      return { ...state, deleteEvent: [payload.sucData] };
    case STREAMLOCATION_OPTIONS:
      return {
        ...state,
        otherData: { ...state.otherData, whereToStream: payload.Data },
      };
    case FB_OVP_DATA:
      return {
        ...state,
        otherData: { ...state.otherData, ovpData: payload.Data },
      };
    case FB_CROSSPOST_PAGE:
      return {
        ...state,
        otherData: {
          ...state.otherData,
          crossPostPages: payload.Data,
          crossPostError: false,
        },
      };
    case FB_CROSSPOST_PAGE_ERR:
      return {
        ...state,
        otherData: {
          ...state.otherData,
          crossPostPages: [],
          crossPostError: true,
        },
      };
    case FB_CREATE_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, create: payload.Data },
      };
    case FB_CREATE_LIVE_VIDEO_FAIL:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, create: payload.error },
      };
    case FB_UPDATE_LIVE_VIDEO:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, update: payload.Data },
      };
    case FB_UPDATE_LIVE_VIDEO_FAIL:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, update: payload.error },
      };
    case FB_LIVE_VIDEO_STATUS:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, status: payload.Data },
      };
    case FB_LIVE_VIDEO_STATUS_ERROR:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, status: payload.error },
      };
    case FB_UPDATE_LIVE_VIDEO_STATUS:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, updateStatus: payload.Data },
      };
    case FB_UPDATE_LIVE_VIDEO_STATUS_ERR:
      return {
        ...state,
        liveVideo: { ...state.liveVideo, updateStatus: payload.error },
      };
    case FB_INITIAL_STATE:
      return { ...state, ...initialState };
    case FB_POST_ANALYTICS_FAIL:
      return {
        ...state,
        analytics: { ...state.analytics, error: payload.error },
      };
    case FB_POST_ANALYTICS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          views: payload.Data.views,
          reactions: payload.Data.reactions,
          comments: payload.Data.comments,
        },
      };
    case GET_FB_VIDEO_STATUS:
      return {
        ...state,
        edit: {
          ...state.edit,
          videoStatus: payload.Data.videoStatus,
          postStatus: payload.Data.postStatus,
        },
      };
    case GET_FB_VIDEO_STATUS_FAIL:
      return { ...state, edit: { ...state.edit, videoStatus: payload.error } };
    case GET_FB_AWS_DEST_FAIL:
      return { ...state, edit: { ...state.edit, dest: payload.error } };
    case GET_FB_AWS_DEST:
      return { ...state, edit: { ...state.edit, dest: payload.Data } };

    case GET_CROSS_POST_SHARED:
      return {
        ...state,
        edit: { ...state.edit, crossPostShared: payload.Data },
      };
    case GET_CROSS_POST_SHARED_FAIL:
      return { ...state, edit: { ...state.edit, crossPostShared: [] } };

    case GET_FB_USER_DETAILS: {
      return { ...state, user_details: payload.Data };
    }

    case GET_FB_USER_DETAILS_FAIL:
      return { ...state, user_details: payload.error };

    default:
      return state;
  }
}
