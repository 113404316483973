export const fbAnalyticsConstants = {
  FBDATA_REQ: 'FBDATA_REQ',
  FBDATA_SUCCESS: 'FBDATA_SUCCESS',
  FBDATA_ERROR: 'FBDATA_ERROR',

  FBDATA_LOADED: 'FBDATA_LOADED',

  ANALYTICS_DATA: 'ANALYTICS_DATA',
  ANALYTICS_DATA_ERROR: 'ANALYTICS_DATA_ERROR',
};
