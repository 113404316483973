import React, { useEffect, useState } from 'react';
import { liveStreamActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../CommonNotification/CommonNotification';
import StreamToolsFallBackSlateView from './streamToolsFallBackSlateView';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
import streamToolsQueue from '../../helpers/queue';

const { storeImageInS3, setFallbackSlate } = liveStreamActions;

const StreamToolsFallBackSlate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const streamToolUnitsList = useSelector((state) => state.streamTools);
  const bossId = useSelector((state) => state.unit.unit.BOSSID);

  const [streamToolData, setStreamToolData] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [url, setUrl] = useState(null);
  const [dimenstions, setDimenations] = useState([]);

  useEffect(() => {
    if (bossId && streamToolUnitsList) {
      setStreamToolData(streamToolUnitsList[bossId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bossId]);

  useEffect(() => {
    if (streamToolData?.fallBackSlateName && streamToolData?.fallBackSlateUrl) {
      const { fallBackSlateName, fallBackSlateUrl } = streamToolData;
      setFileName(fallBackSlateName);
      (async () => {
        await fetchImageFromUrlAndSetDimensions(fallBackSlateUrl);
        setUrl(fallBackSlateUrl);
      })();
      setisLoading(false);
    }
    if (
      streamToolData &&
      !(streamToolData?.fallBackSlateName || streamToolData?.fallBackSlateUrl)
    ) {
      setisLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamToolData]);

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleFallbackSlateUpload = async (info) => {
    setIsUploading(true);
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
    const { file } = info;
    if (file.status === 'done') {
      const allowedFiletype =
        'image/jpeg,image/jpg,image/png,image/gif,image/webp,image/avif,video/mp4,.jpg,.png,.jpeg,.gif,.webp,.avif,.mp4';
      if (!allowedFiletype.includes(file.type)) {
        notify('error', t('UPLOADFILEVALIDATION'));
        handleUploadFail();
        return;
      }

      let [width, height] = await calculateImageDimenstions(
        file.originFileObj,
        file.type,
      );

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      let imageFile = file.originFileObj;

      if (width > 1920 || height > 1080) {
        imageFile = await imageCompression(file.originFileObj, options);
        width = 1920;
        height = 1080;
      }
      try {
        dispatch(
          streamToolsQueue(() => {
            return async (dispatch) => {
              let [t, width00, height00] = await dispatch(
                storeImageInS3(imageFile, width, height, bossId),
              );
              setUrl(t);
              setFileName(file.name);
              setDimenations([width00, height00]);
              await dispatch(
                setFallbackSlate(t, width00, height00, file.name, bossId),
              );
              setIsUploading(false);
            };
          }, []),
        );
      } catch (err) {
        handleUploadFail();
        notify('error', t('Failed to upload logo'));
      }
    }
    if (file.status === 'removed') {
      handleUploadFail();
    }
  };

  const fetchImageFromUrlAndSetDimensions = async (url) => {
    try {
      await fetch(url)
        .then((res) => res.blob())
        .then(async (blob) => {
          setDimenations(await calculateImageDimenstions(blob));
        });
    } catch (err) {
      handleUploadFail();
    }
  };

  const calculateImageDimenstions = (image, type) => {
    return new Promise((resolve) => {
      if (type === 'video/mp4' || type === '.mp4') {
        if (image) {
          const video = document.createElement('video');
          video.preload = 'metadata';
          video.onloadedmetadata = () => {
            // Get the video dimensions
            const width = video.videoWidth;
            const height = video.videoHeight;

            // Print the dimensions to the console
            resolve([width, height]);
          };

          video.src = URL.createObjectURL(image);
        }
      } else {
        const img = new Image();
        img.src = URL.createObjectURL(image);
        img.onload = function () {
          resolve([img.naturalWidth, img.naturalHeight]);
        };
      }
    });
  };

  const handleUploadFail = () => {
    setUrl(null);
    setFileList([]);
    setDimenations([]);
    setFileName(null);
    setIsUploading(false);
    setisLoading(false);
  };

  const handleRemoveFallbackSlate = async () => {
    setIsClearing(true);
    setUrl(null);
    setFileList([]);
    setDimenations([]);
    setFileName(null);
    await dispatch(streamToolsQueue(setFallbackSlate, ['', 0, 0, '', bossId]));
    // await dispatch(liveStreamActions.setFallbackSlate('', 0, 0, '', bossId));
    setIsClearing(false);
  };

  return (
    <>
      <StreamToolsFallBackSlateView
        {...{
          t,
          isLoading,
          isUploading,
          isClearing,
          dimenstions,
          url,
          handleFallbackSlateUpload,
          handleRemoveFallbackSlate,
          dummyRequest,
          fileList,
          fileName,
        }}
      />
    </>
  );
};

export default StreamToolsFallBackSlate;
