import { fbAnalyticsConstants } from '../../constants';
const initialState = {
  isLoading: true,
  postAnalytics: { data: [], error: false },
};
const { ANALYTICS_DATA, ANALYTICS_DATA_ERROR, FBDATA_REQ, FBDATA_LOADED } =
  fbAnalyticsConstants;

export function fbAnalytics(state = initialState, payload) {
  switch (payload.type) {
    case ANALYTICS_DATA:
      return {
        ...state,
        postAnalytics: { ...state.postAnalytics, data: payload.Data },
      };
    case ANALYTICS_DATA_ERROR:
      return {
        ...state,
        postAnalytics: { ...state.postAnalytics, error: true },
      };
    case FBDATA_REQ:
      return { ...state, isLoading: true };
    case FBDATA_LOADED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
