import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Collapse, Row, Skeleton } from 'antd';
import './streamtools.css';
import { useDispatch, useSelector } from 'react-redux';
import audioBitRateOptions from '../../data/aduioBitRateOptions.json';
import { liveStreamActions } from '../../actions';
import { Field, Formik } from 'formik';
import { ProfileSettings } from '../ProfileSettings';
import { versionCheck } from '../../selectors/destinations_selectors';
import '../Providers/CustomRtmp/CustomRtmp.css';
import { notify } from '../CommonNotification/CommonNotification';
import { Spin, Icon } from 'antd';

const { Panel } = Collapse;
const antIcon = (
  <Icon
    type='loading'
    style={{
      fontSize: '14px',
      marginRight: '5px',
      color: 'white',
      position: 'relative',
      bottom: '4px',
    }}
    spin
  />
);

const frameRateOptions = [
  { label: '12.48', value: '12.48' },
  { label: '12.5', value: '12.5' },
  { label: '14.98', value: '14.98' },
  { label: '15', value: '15' },
  { label: '23.97', value: '23.97' },
  { label: '24', value: '24' },
  { label: '24.97', value: '24.97' },
  { label: '25', value: '25' },
  { label: '29.97', value: '29.97' },
  { label: '30', value: '30' },
  { label: '50', value: '50' },
  { label: '59.94', value: '59.94' },
  { label: '60', value: '60' },
];
const { setStreamToolsAdvanceProfile, retriveStreamToolsInfo } =
  liveStreamActions;
const initialState = {
  fps: null,
  videoBitrate: null,
  audioBitrate: null,
};
const profileReducer = (state, action) => {
  switch (action.type) {
    case 'SETFPS':
      return {
        ...state,
        fps:
          frameRateOptions.find((item) => item.value === action.fps) ??
          frameRateOptions[1],
      };

    case 'SETAUDIOBITRATE':
      return {
        ...state,
        audioBitate:
          audioBitRateOptions.options.find(
            (item) => item.value === action.audioBitate,
          ) ?? audioBitRateOptions.options[0],
      };

    case 'SETVIDEOBITRATE':
      return {
        ...state,
        videoBitrate: action.videoBitrate,
      };
    default:
      return state;
  }
};
function StreamToolsProfileConfiguration() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [highBitRate, setHighBitRate] = useState(false)
  const [invalidBitrate, setInvalidBitrate] = useState(false);
  const [advanceProfile, setAdvanceProfile] = useReducer(
    profileReducer,
    initialState,
  );
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit.unit);
  const isStreaming = useSelector(
    (state) => state.streamTools?.[unit.BOSSID]?.isStreamtoolsStreaming,
  );
  const bitrateDisabled = useSelector((state) => versionCheck(state));

  useEffect(() => {
    (async () => {
      let data = await dispatch(retriveStreamToolsInfo(unit.BOSSID, false));
      if (!!data.fps) {
        setAdvanceProfile({
          type: 'SETFPS',
          fps: data.fps,
        });
      }
      if (!!data.audio_bitrate) {
        setAdvanceProfile({
          type: 'SETAUDIOBITRATE',
          audioBitate: data.audio_bitrate,
        });
      }
      if (!!data.video_bitrate) {
        setAdvanceProfile({
          type: 'SETAUDIOBITRATE',
          videoBitrate: data.video_bitrate,
        });
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const advanceProfile = {
        fps: null,
        audio_bitrate: null,
        video_bitrate: null,
      };
      if (!!values.frameRateBox) {
        advanceProfile.fps = values.overrideFrame.value;
      }
      if (!!values.audioBitRateBox) {
        advanceProfile.audio_bitrate = values.audioBitRate.value;
      }
      if (!!values.bitRateBox) {
        advanceProfile.video_bitrate = values.overrideBitrate;
      }
      await dispatch(setStreamToolsAdvanceProfile(advanceProfile, unit.BOSSID));
      notify('success', t('STREAMTOOLSADVANCEPROFILESET'));
    } catch (err) {
      notify('ERROR', t('STREAMTOOLSADVANCEPROFILESETERROR'));
    } finally {
      setIsSubmitting(false);
    }
  };

  function isBitRateHigher() {
    // setHighBitRate(value);
  }

  return (
    <>
      <Row className='mb-3'>
        <Col span={24}>
          <Collapse
            bordered={false}
            className='antd-collapse'
            expandIconPosition='right'
          >
            <Panel
              header={
                <div className='sub-title'>
                  <h4>{t('STPROFILECONFIG')}</h4>
                </div>
              }
              key='1'
            >
              <Row>
                <div className='customrtmp pagewrap'>
                  <Formik
                    initialValues={{
                      overrideFrame: !!advanceProfile.fps
                        ? advanceProfile.fps
                        : {
                            label: '12.48',
                            value: '12.48',
                          },

                      overrideBitrate: !!advanceProfile.videoBitrate
                        ? advanceProfile.videoBitrate
                        : 400,
                      bitRateBox: !!advanceProfile.videoBitrate,
                      frameRateBox: !!advanceProfile.fps,
                      audioBitRateBox: !!advanceProfile.audioBitRate,
                      overrideErrors: !!advanceProfile.videoBitrate
                        ? Number(advanceProfile.videoBitrate) < 250
                          ? { overrideBitrate: 'invalid bitrate' }
                          : ''
                        : '',
                      audioBitRate: !!advanceProfile.audioBitRate
                        ? advanceProfile.audioBitRate
                        : { label: '128kbps', value: '128000' },
                    }}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {(formik) => (
                      <form
                        name='streamtoolsadvanceprofile'
                        onSubmit={formik.handleSubmit}
                        noValidate
                      >
                        {isLoading ? (
                          <>
                            <Skeleton />
                          </>
                        ) : (
                          <>
                            <Field
                              component={ProfileSettings}
                              t={t}
                              formik={formik}
                              bitrateDisabled={bitrateDisabled}
                              isBitRateHigher={isBitRateHigher}
                              profile={formik.values.profile}
                              invalidBitRate={setInvalidBitrate}
                              isNotInTheDestinationFormPage={true}
                            />

                            <div className='row'>
                              <div className='col offset-lg-6'>
                                <button
                                  type='submit'
                                  disabled={
                                    !formik.isValid ||
                                    formik.values.overrideErrors.length ||
                                    invalidBitrate ||
                                    isSubmitting ||
                                    isStreaming
                                  }
                                  className='btn btn-primary'
                                >
                                  {isSubmitting && <Spin indicator={antIcon} />}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('SUBMIT')}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </Row>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}

export default StreamToolsProfileConfiguration;
