import React, { useState, useEffect } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { Link, Route, Switch } from 'react-router-dom';
import { Units } from '../Units';
import { UnitHeader } from '../UnitHeader';
import { UnitDetailsContainer } from '../UnitDetailsContainer';
import { Payment } from '../Payment';
import { ConfigureSoloconnect } from '../ConfigureSoloconnect';
import { ErrorBoundary } from '../ErrorBoundary';
import mobUnit from '../../assets/images/solo-device.png';
import Cookies from 'universal-cookie';
import { StreamtoolProtected } from '../StreamtoolProtected';
import StreamToolsSettings from '../StreamtoolsSettings/streamToolsSettings';
import StreamToolsPreview from '../StreamToolsPreview/StreamToolsPreview';
const cookies = new Cookies();
export const DashboardView = ({
  t,
  match,
  history,
  units,
  unit,
  onSelectedUnit,
  onUnitSettings,
  handleClick,
  openMenu,
  lrtMessage,
  modalHandler,
}) => {
  const [adminMessage, setAdminMessage] = useState(true);
  const [adminMessageValue, setAdminMessageValue] = useState(
    cookies.get('admin_message'),
  );

  function saveCookie() {
    cookies.set('admin_message_visible', false, {
      path: '/',
      secure: true,
    });
  }

  useEffect(() => {
    setAdminMessageValue(cookies.get('admin_message'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.get('admin_message')]);
  return (
    <>
      <div className='solounit-main'>
        {lrtMessage && (
          <UncontrolledAlert color='info'>
            <i className='fa fa-exclamation-triangle fa' aria-hidden='true' />
            {t('LRTSUBSCRIBED')}
          </UncontrolledAlert>
        )}
        {/* haven't created util function for dangerouslySetInnerHTML bacause of cross site scripting
         * may be anyone can insert mislenious javascript or html code
         */}
        {cookies.get('admin_message') &&
          (cookies.get('admin_message_visible')
            ? JSON.parse(cookies.get('admin_message_visible')) !== false
            : true) && (
            <UncontrolledAlert
              color='warning'
              isOpen={adminMessage}
              toggle={() => {
                setAdminMessage(!adminMessage);
                saveCookie();
              }}
            >
              <i className='fa fa-exclamation-triangle fa' aria-hidden='true' />
              {adminMessageValue && (
                <span
                  dangerouslySetInnerHTML={{ __html: adminMessageValue }}
                ></span>
              )}
            </UncontrolledAlert>
          )}
        <div className='solounit-contain'>
          {units.length > 0 ? (
            <React.Fragment>
              <div
                className={
                  openMenu
                    ? 'd-flex flex-row position-relative row mx-0 navOpen'
                    : 'd-flex flex-row position-relative row mx-0'
                }
              >
                {/* add ACTIVE class */}
                <ErrorBoundary>
                  <div className='mobileview'>
                    <Units
                      units={units}
                      history={history}
                      match={match}
                      onSelectedUnit={onSelectedUnit}
                      onUnitSettings={onUnitSettings}
                      modalHandler={modalHandler}
                    />
                  </div>
                </ErrorBoundary>
                <div className='d-flex flex-column col px-0 unit-contain'>
                  {unit !== undefined && (
                    <ErrorBoundary>
                      <Route
                        exact
                        path={`${match.url}/(|payment/lrt/|soloconnect/|payment/soloconnect/|payment/streamtool/):bossId`}
                      >
                        <UnitHeader {...{ unit }} />
                      </Route>
                    </ErrorBoundary>
                  )}
                  <div className='unit-nav mobUnitNav'>
                    <Link to='#' onClick={() => handleClick()}>
                      <span className='icon-bar'></span>
                      <span className='icon-bar'></span>
                      <span className='icon-bar'></span>
                    </Link>
                    <span className='selectUnitName d-flex align-items-center'>
                      <div className='selectUnitNameImg'>
                        <img src={mobUnit} alt={''} />
                      </div>
                      {!!unit && !!unit.name && (
                        <div className='selectUnitNameTxt'>{unit.name}</div>
                      )}
                    </span>
                    <ErrorBoundary>
                      <Units
                        units={units}
                        history={history}
                        match={match}
                        onSelectedUnit={onSelectedUnit}
                        onUnitSettings={onUnitSettings}
                        modalHandler={modalHandler}
                      />
                    </ErrorBoundary>
                  </div>
                  <Switch>
                    <ErrorBoundary>
                      <Route
                        exact
                        path={`${match.url}/:bossId`}
                        component={UnitDetailsContainer}
                      />
                      <Route
                        exact
                        path={`${match.url}/payment/lrt/:bossId`}
                        component={Payment}
                      />
                      <Route
                        exact
                        path={`${match.url}/soloconnect/:bossId`}
                        component={ConfigureSoloconnect}
                      />
                      <Route
                        exact
                        path={`${match.url}/payment/soloconnect/:bossId`}
                        component={Payment}
                      />
                      <Route
                        exact
                        path={`${match.url}/payment/streamtool/:bossId`}
                        component={Payment}
                      />

                      <StreamtoolProtected
                        exact
                        path={`${match.url}/:bossId/streamtoolssettings`}
                        component={StreamToolsSettings}
                      />

                      <StreamtoolProtected
                        exact
                        path={`${match.url}/:bossId/streamtoolspreview`}
                        component={StreamToolsPreview}
                      />
                    </ErrorBoundary>
                  </Switch>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className='addNewSolo'>
              <Link to='/dashboard/add'>{t('CLICKHERE')}</Link>
              {t('ADDNEWSOLOUNIT')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
