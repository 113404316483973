import { REHYDRATE } from 'redux-persist';
import { unitConstants } from '../constants';
import mapKeys from 'lodash/mapKeys';
const initialState = {
  units: [],
  units_status: {},
  units_details: {},
  unit: {},
  isEdit: false,
  loadingUnits: true,
  videoDetails: {},
  unitstart: false,
  interfaces: [],
  selectedInterface: {},
  units_lrt_details: [],
  units_sla_details: [],
  sfLoading: true,
  isInterfaceLoading: true,
  isInterfaceDetailsLoading: true,
  unitsettings: [],
  unitInterface: [],
  rebootStatus: {},
  unitLoaderState: null,
  unitOrder: 1,
};
const {
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  GET_SFLRT_DETAILS_SUCCESS,
  GET_SFSLA_DETAILS_SUCCESS,
  SELECTED_UNIT,
  EDIT_SELECTED_UNIT,
  CANCEL_EDIT_SELECTED_UNIT,
  UPDATE_UNIT,
  VIDEO_DETAILS_REQUEST,
  GET_VIDEO_DETAILS,
  GET_STATUS_SUCCESS,
  GET_INTERFACE_REQUEST,
  GET_INTERFACE_SUCCESS,
  GET_INTERFACE_FAILURE,
  GET_INTERFACE_DETAILS_REQUEST,
  GET_INTERFACE_DETAILS_SUCCESS,
  GET_INTERFACE_DETAILS_FAILURE,
  SF_LOADING_START,
  SF_LOADING_STOP,
  GET_UNIT_SETTINGS_SUCCESS,
  GET_UNIT_INTERFACE,
  REBOOT_UNIT,
  SET_UNIT_LOADER_STATE,
  UPDATE_UNIT_FROM_UNITS,
  SET_SELECTOR_VALUE,
} = unitConstants;

export function unit(state = initialState, payload) {
  switch (payload.type) {
    case GET_UNITS_REQUEST:
      return {
        ...state,
      };

    case GET_UNITS_SUCCESS:
      return {
        ...state,
        units: [...payload.units],
        loadingUnits: false,
      };

    case GET_STATUS_SUCCESS:
      return {
        ...state,
        units_status: mapKeys(payload.status, 'deviceId'),
      };
    case GET_SFLRT_DETAILS_SUCCESS:
      return {
        ...state,
        units_lrt_details: payload.lrtDetails,
      };
    case GET_SFSLA_DETAILS_SUCCESS:
      return {
        ...state,
        units_sla_details: payload.slaDetails,
      };
    case SELECTED_UNIT:
      return {
        ...state,
        isEdit: false,
        unit: payload.unit,
      };
    case EDIT_SELECTED_UNIT:
      return {
        ...state,
        unit: payload.unit,
        isEdit: true,
        loadingUnits: false,
      };

    case CANCEL_EDIT_SELECTED_UNIT:
      return {
        ...state,
        unit: payload.unit,
        isEdit: false,
        loadingUnits: false,
      };

    case UPDATE_UNIT:
      const updatedUnits = state.units.map((unit) =>
        unit.BOSSID === payload.unit.BOSSID ? payload.unit : unit,
      );
      return {
        ...state,
        units: updatedUnits,
        unit: payload.unit,
        isEdit: false,
        loadingUnits: false,
      };
    case VIDEO_DETAILS_REQUEST:
      return {
        ...state,
        sourceLoading: true,
      };
    case GET_VIDEO_DETAILS:
      return {
        ...state,
        videoDetails: payload.res,
        sourceLoading: false,
      };
    case GET_UNIT_INTERFACE:
      return {
        ...state,
        unitInterface: payload.Data,
      };
    case 'UNIT_START_REQUEST':
      return {
        ...state,
        unitstart: payload.arg,
      };
    case 'UNIT_STOP_REQUEST':
      return {
        ...state,
        unitstart: payload.arg,
      };
    case 'REGISTER_UNIT_REQUEST':
      return {
        ...state,
        isRegisterLoading: true,
      };
    case 'REGISTER_UNIT_SUCCESS':
      return {
        ...state,
        isRegisterLoading: false,
      };
    case 'REGISTER_UNIT_FAILURE':
      return {
        ...state,
        isRegisterLoading: false,
      };
    case GET_INTERFACE_REQUEST:
      return {
        ...state,
        isInterfaceLoading: true,
      };
    case GET_INTERFACE_SUCCESS:
      return {
        ...state,
        interfaces: payload.interfaces,
        isInterfaceLoading: false,
      };
    case GET_INTERFACE_FAILURE:
      return {
        ...state,
        isInterfaceLoading: false,
      };
    case GET_INTERFACE_DETAILS_REQUEST:
      return {
        ...state,
        isInterfaceDetailsLoading: true,
      };
    case GET_INTERFACE_DETAILS_SUCCESS:
      return {
        ...state,
        selectedInterface: payload.details,
        isInterfaceDetailsLoading: false,
      };
    case GET_INTERFACE_DETAILS_FAILURE:
      return {
        ...state,
        isInterfaceDetailsLoading: false,
      };
    case SF_LOADING_START:
      return {
        ...state,
        sfLoading: true,
      };
    case SF_LOADING_STOP:
      return {
        ...state,
        sfLoading: false,
      };
    case GET_UNIT_SETTINGS_SUCCESS:
      return {
        ...state,
        unitsettings: payload.res,
      };
    case REBOOT_UNIT:
      return {
        ...state,
        rebootStatus: payload.rebootStatus,
      };
    case SET_UNIT_LOADER_STATE:
      return {
        ...state,
        unitLoaderState: {
          ...(state?.unitLoaderState ? state?.unitLoaderState : {}),
          [payload?.payload?.bossId]: payload.payload.loaderState,
        },
      };
    case UPDATE_UNIT_FROM_UNITS: {
      const updatedUnit = payload.units.find(
        (unit) => unit.BOSSID === state.unit?.BOSSID,
      );
      return {
        ...state,
        unit: updatedUnit,
      };
    }
    case SET_SELECTOR_VALUE: {
      return {
        ...state,
        unitOrder: payload.value,
      };
    }
    case REHYDRATE: {
      if (payload.payload?.unit) {
        return {
          ...payload.payload?.unit,
          unitLoaderState: null,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
