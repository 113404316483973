import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { liveStreamActions, lrtActions } from '../../actions';
import { LrtView } from './LrtView';
import {
  getChannels,
  soloConnectExists,
  lrtSubscribed,
  // getUnitWithStatus,
} from '../../selectors/unit_selectors';
import './Lrt.css';
import { ErrorBoundary } from '../ErrorBoundary';
import i18n from 'i18next';
import { notify } from '../CommonNotification/CommonNotification';
import Cookies from 'universal-cookie';
import streamtoolZones from '../../data/streamToolZones.json';
import streamtoolZonesStage from '../../data/streamToolZonesStage.json';

const cookies = new Cookies();

class Lrt extends Component {
  state = {
    lrtToggle: false,
    modalIsOpen: false,
  };

  changeZoneHandler = (unitId, selected_channel) => {
    if (selected_channel) {
      this.setState({ lrtToggle: false });
      selected_channel = '';
      // cookies.set(`selected_channel_${unitId}`, '', {
      //   path: '/',
      //   maxAge: 60 * 60 * 24 * 365,
      //   secure: true,
      // });
      this.props.changeZone(unitId, selected_channel);
    } else {
      if (!cookies.get(`selected_channel_${this.props.unit.BOSSID}`)) {
        if (!this.state.lrtToggle) {
          notify('info', i18n.t('PLEASESELECTCHANNELFORLRT'));
        }
        this.setState({ lrtToggle: !this.state.lrtToggle });
      } else {
        this.setState({ lrtToggle: true });
        selected_channel = cookies.get(
          `selected_channel_${this.props.unit.BOSSID}`,
        );
        this.props.changeZone(unitId, selected_channel);
      }
    }
  };

  componentDidMount() {
    this.props.getAllSubscription('trialing');
    if (this.props.channelServiceDestination.selected_channel) {
      this.setState({ lrtToggle: true });
    } else {
      this.setState({ lrtToggle: false });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const { channelServiceDestination } = this.props;
    if (prevProps.channelServiceDestination !== channelServiceDestination) {
      if (channelServiceDestination.selected_channel) {
        this.setState({ lrtToggle: true });
      } else {
        this.setState({ lrtToggle: false });
      }
    }
  }

  lrtModal = () => {
    this.setState({ modalIsOpen: true });
  };

  toggle = () => {
    this.setState({ modalIsOpen: false });
  };
  onStreamToolsZoneUpdate = async (_, { value }) => {
    const { unit, setStreamToolsZone } = this.props;
    let STZone =
      process.env.REACT_APP_environment === 'production'
        ? streamtoolZones.find((zone) => zone?.['LUC Group Name'] === value)
            ?.value ?? 'eu-irl-4'
        : streamtoolZonesStage.find(
            (zone) => zone?.['LUC Group Name'] === value,
          )?.value ?? 'eu-irl-1';
    await setStreamToolsZone(unit?.BOSSID, STZone);
  };
  render() {
    const {
      t,
      unit,
      service,
      channels,
      channelServiceDestination,
      soloConnect,
      lrtSubscribed,
      lrtLoading,
      streamTools,
    } = this.props;
    const { lrtToggle, modalIsOpen } = this.state;
    let selectedChannel = cookies.get(
      `selected_channel_${this.props.unit.BOSSID}`,
    );

    const {
      streamtoolSubscribed,
      isStreamToolActive,
      dnd,
      isStreamtoolsStreaming,
      selectedZone,
      isUpdatingZone,
    } = streamTools[unit?.BOSSID];
    return (
      <ErrorBoundary>
        <LrtView
          t={t}
          service={service}
          channels={channels}
          unit={unit}
          selectedChannel={selectedChannel}
          channelServiceDestination={channelServiceDestination}
          changeZoneHandler={this.changeZoneHandler}
          soloConnect={soloConnect}
          lrtSubscribed={lrtSubscribed}
          lrtLoading={lrtLoading}
          lrtToggle={lrtToggle}
          modalIsOpen={modalIsOpen}
          modalToggle={this.toggle}
          modalHandler={this.lrtModal}
          {...{
            streamtoolSubscribed,
            isStreamToolActive,
            dnd,
            isStreamtoolsStreaming,
            selectedZone,
            isUpdatingZone,
            zonesOptions:
              process.env.REACT_APP_environment === 'production'
                ? streamtoolZones
                : streamtoolZonesStage,
            onStreamToolsZoneUpdate: this.onStreamToolsZoneUpdate,
          }}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  const { lrtLoading } = state.payment;
  const { streamTools } = state;
  return {
    channels: getChannels(state),
    soloConnect: soloConnectExists(state),
    lrtSubscribed: lrtSubscribed(state),
    lrtLoading,
    streamTools,
  };
};

const mapDispatchToProps = {
  getAllSubscription: lrtActions.getAllSubscription,
  changeZone: lrtActions.changeZone,
  setStreamToolsZone: liveStreamActions.setStreamToolsZone,
};

const ConnectedLrt = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Lrt));
export { ConnectedLrt as Lrt };
