import { getRestreamToken } from '../api/aws.api';
import {
  getUserDetails,
  getPlatforms,
  getUserPlatforms,
  getAllIngests,
  getIngests,
  getStreamKey,
  enableChannels,
  disableChannels,
} from '../api/restream.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();
export const restreamService = {
  getUserDet,
  getPlat,
  getUserPlat,
  getIngest,
  getUserIngest,
  getStrKey,
  enableChan,
  disableChan,
  getVimToken,
};
async function getVimToken(code, redirect) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const getTok = await getRestreamToken(code, redirect, awsHeaders);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getUserDet() {
  try {
    const getChan = await getUserDetails();
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getPlat() {
  try {
    const getChan = await getPlatforms();
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getUserPlat() {
  try {
    const getChan = await getUserPlatforms();
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getUserIngest() {
  try {
    const getChan = await getIngests();
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getIngest() {
  try {
    const getChan = await getAllIngests();
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getStrKey() {
  try {
    const getChan = await getStreamKey();
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function enableChan(payload) {
  try {
    const getChan = await enableChannels(payload);
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function disableChan(payload) {
  try {
    const getChan = await disableChannels(payload);
    return getChan.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
