import i18n from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { userActions } from '../../actions';
import GoSoloBlack from '../../assets/images/go-solo-black.png';
import { history } from '../../history';
import { AppVersion } from '../AppVersion/AppVersion';
import { LangDropdown } from '../LangDropdown';
import { Liveuloading } from '../Liveuloading';
import './Verify.css';
class Verify extends Component {
  state = {
    currentStatus: '',
    loading: true,
  };

  async componentDidMount() {
    if (this.props.match && this.props.match.params) {
      var user = {
        token: this.props.match.params.token,
      };
      const result = await this.props.verifyUser(user);
      if (result) {
        this.setState({
          currentStatus: i18n.t('ACCOUNTVALIDATEDSUCCESSFULLY'),
        });
        this.setState({ loading: false });
      } else {
        this.setState({ currentStatus: i18n.t('SORRY') });
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { t } = this.props;
    const { currentStatus, loading } = this.state;

    return loading ? (
      <Liveuloading />
    ) : (
      <div className='entrySection d-flex align-items-center justify-content-center'>
        <div className='entryContainer'>
          <div className='loginLang'>
            <LangDropdown align={false} />
          </div>
          <Row className='rowflex d-flex flex-row align-items-stretch'>
            <Col
              md='7'
              className='d-none d-md-flex whitebgTransparent rightSide align-items-center '
            >
              <span className='titleTxt'>
                {t('MAKING')} <br />
                {t('LIVE')} <br />
                {t('SIMPLER')}
              </span>
            </Col>
            <Col
              md='5'
              className='leftSide d-flex flex-column justify-content-center'
            >
              <div className='welcome'>
                <div className='text-center my-5'>
                  <img src={GoSoloBlack} alt='description' />
                </div>
                <h4 className='mb-3'>{currentStatus}</h4>
              </div>
              <div className='errormsg'>
                <span id='errormsg' className='text-center'></span>
              </div>
              <fieldset>
                <div className='form-group'>
                  <input
                    type='button'
                    value={t('BACKTOLOGIN')}
                    className='btn btn-lg btn-primary btn-block'
                    onClick={() => history.push('/login')}
                  />
                </div>
              </fieldset>
            </Col>
          </Row>
          <div className='loginAppVersion'>
            <AppVersion t={t} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  verifyUser: userActions.verifyUser,
};

const ConnectedVerify = connect(
  null,
  mapDispatchToProps,
)(withTranslation()(Verify));
export { ConnectedVerify as Verify };
