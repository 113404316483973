import { getEasyLiveToken, getEasyLiveProfile } from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const easyLiveService = {
  getELToken,
  getEasyLiveProf,
};
async function getELToken(redirect_uri, code) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await getEasyLiveToken(redirect_uri, code, awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
async function getEasyLiveProf(fType, elZone, streamTitle) {
  try {
    const res = await getEasyLiveProfile(fType, elZone, streamTitle);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
