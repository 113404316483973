import { restreamConstants, commonConstants } from '../constants';
import { restreamService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { history } from '../history';
import { commonAction } from './common.actions';
import { store } from '../store';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const restreamAction = {
  getUserDetails,
  createStream,
  restreamToken,
};
/**
 * @name restreamToken
 * @params code,redirect,bossID
 * @descriptiton gets a restream token
 **/
function restreamToken(code, redirect, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getReTok = await restreamService.getVimToken(code, redirect);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('restream_access_token', getReTok.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(success(getReTok));
      history.push('/dashboard/restream/' + bossId);
      dispatch(getUserDetails(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };
  function request() {
    return { type: restreamConstants.RE_GET_TOKEN_REQ };
  }

  function success(AuthData) {
    return { type: restreamConstants.RE_GET_TOKEN, AuthData };
  }

  function failure(error) {
    return { type: restreamConstants.RE_GET_TOKEN_FAIL, error };
  }
}
/**
 * @name getUserDetails
 * @params bossId
 * @descriptiton get restream user profile
 **/
function getUserDetails(bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getUser = await restreamService.getUserDet();
      dispatch(success(getUser));
      dispatch(getPlatforms(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function request() {
    return { type: restreamConstants.RE_USER_DETAILS_REQ };
  }

  function success(userData) {
    return { type: restreamConstants.RE_USER_DETAILS, userData };
  }

  function failure(error) {
    return { type: restreamConstants.RE_USER_DETAILS_FAIL, error };
  }
}
/**
 * @name getPlatforms
 * @params bossId
 * @descriptiton get restream channels
 **/
function getPlatforms(bossId) {
  return async (dispatch) => {
    try {
      const getPlat = await restreamService.getPlat();
      dispatch(success(getPlat));
      dispatch(getUserPlatforms(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function success(Data) {
    return { type: restreamConstants.RE_GET_PLATFORMS, Data };
  }

  function failure(error) {
    return { type: restreamConstants.RE_GET_PLATFORMS_FAIL, error };
  }
}
/**
 * @name getUserPlatforms
 * @params bossId
 * @descriptiton get restream user channels
 **/
function getUserPlatforms(bossId) {
  return async (dispatch) => {
    try {
      const getUserPlat = await restreamService.getUserPlat();
      dispatch(intermediate(bossId, getUserPlat));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function failure(error) {
    return { type: restreamConstants.RE_GET_USERPLATFORMS_FAIL, error };
  }
}
/**
 * @name intermediate
 * @params bossId,platforms
 * @descriptiton function used to process user channels
 **/

function intermediate(bossId, userPlatforms) {
  return async (dispatch) => {
    try {
      const { platforms } = store.getState().restream.restream;
      let userPlat = [];
      let channels = { enabled: [], disabled: [] };
      // eslint-disable-next-line array-callback-return
      platforms.filter((pValue) => {
        // eslint-disable-next-line array-callback-return
        userPlatforms.filter((aValue) => {
          if (aValue.streamingPlatformId === pValue.id) {
            if (aValue.enabled) {
              userPlat.push({
                label: `${aValue.displayName} - ${pValue.name}`,
                value: aValue.id,
                ticked: true,
              });
              channels.enabled.push({
                label: `${aValue.displayName} - ${pValue.name}`,
                value: aValue.id,
                ticked: true,
              });
            } else {
              userPlat.push({
                label: `${aValue.displayName} - ${pValue.name}`,
                value: aValue.id,
              });
              channels.disabled.push({
                label: `${aValue.displayName} - ${pValue.name}`,
                value: aValue.id,
              });
            }
          }
        });
      });
      if (!platforms.length) {
        notify('warning', i18n.t('NOPLATFORMS'));
      }
      if (!channels.enabled.length && !channels.disabled.length) {
        notify('warning', i18n.t('NOCHANNELS'));
      }
      dispatch(success(userPlat));
      dispatch(other(channels));
      dispatch(allIngests(bossId));
    } catch (e) {
      errorBlock(e, bossId);
    }
  };
  function other(Data) {
    return { type: restreamConstants.RE_CHANNELS, Data };
  }
  function success(Data) {
    return { type: restreamConstants.RE_GET_USERPLATFORMS, Data };
  }
}
/**
 * @name allIngests
 * @params bossId
 * @descriptiton get restream ingests URls
 **/
function allIngests(bossId) {
  return async (dispatch) => {
    try {
      const getAllIngest = await restreamService.getIngest();
      dispatch(success(getAllIngest));
      dispatch(userIngests(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function success(Data) {
    return { type: restreamConstants.RE_GET_ALLINGEST, Data };
  }

  function failure(error) {
    return { type: restreamConstants.RE_GET_ALLINGEST_FAIL, error };
  }
}
/**
 * @name userIngests
 * @params bossId
 * @descriptiton get restream user ingest urls
 **/
function userIngests(bossId) {
  return async (dispatch) => {
    try {
      const getIngest = await restreamService.getUserIngest();
      dispatch(intermediate2(bossId, getIngest));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function failure(error) {
    return { type: restreamConstants.RE_GET_INGEST_FAIL, error };
  }
}
/**
 * @name intermediate2
 * @params bossId,ingestData
 * @descriptiton function used to process ingests
 **/
function intermediate2(bossId, ingestData) {
  return async (dispatch) => {
    try {
      const allIngests = store.getState().restream.restream.ingests;
      let availableIngest = [];
      // eslint-disable-next-line array-callback-return
      allIngests.filter((allValue) => {
        if (allValue.id === ingestData.ingestId) {
          availableIngest.push({
            label: allValue.name,
            value: allValue.rtmpUrl,
            ticked: true,
          });
        } else {
          availableIngest.push({
            label: allValue.name,
            value: allValue.rtmpUrl,
          });
        }
      });
      dispatch(success(availableIngest));
      dispatch(getStreamKey(bossId));
    } catch (e) {
      errorBlock(e, bossId);
    }
  };
  function success(Data) {
    return { type: restreamConstants.RE_GET_INGEST, Data };
  }
}
/**
 * @name getStreamKey
 * @params bossId
 * @descriptiton get user stream key
 **/
function getStreamKey(bossId) {
  return async (dispatch) => {
    try {
      const str = await restreamService.getStrKey();
      dispatch(success(str.streamKey));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function success(Data) {
    return { type: restreamConstants.RE_GET_STREAM_KEY, Data };
  }
  function failure(error) {
    return { type: restreamConstants.RE_GET_STREAM_KEY_FAIL, error };
  }
}
/**
 * @name createStream
 * @params formValues,bossId
 * @descriptiton enables/disables user's channel based on selection
 **/
function createStream(values, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const enabledChannels =
        store.getState().restream.restream.channel.enabled;
      let channelsToBeEnabled = differenceWith(
        values.selectedChannels,
        enabledChannels,
        isEqual,
      );
      let channelsToBeDisabled = differenceWith(
        enabledChannels,
        values.selectedChannels,
        isEqual,
      );
      const enableArr = channelsToBeEnabled.map(async (x) => {
        const payload = { channelId: x.value };
        return await restreamService.enableChan(payload);
      });
      await Promise.all(enableArr);
      const disableArr = channelsToBeDisabled.map(async (x) => {
        const payload = { channelId: x.value };
        return await restreamService.disableChan(payload);
      });
      await Promise.all(disableArr);
      dispatch(makePayload(values, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
      // eslint-disable-next-line no-unused-vars
      const res =
        e.message ||
        e.response.status !== 404 ||
        e.response.status !== 403 ||
        e.response.status !== 401
          ? dispatch(hideSpinner())
          : null;
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name makePayload
 * @params formvalues,bossID
 * @descriptiton creates AWS Destination
 **/
function makePayload(values, bossId) {
  return async (dispatch) => {
    try {
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile,
        primary_url: values.ingestUrl,
        secondary_url: '',
        stream_name: values.streamKey,
        username: '',
        password: '',
        id: guid(),
        streaming_provider: 'Restream.io',
        overrideResolution: values.overrideResolution,
        overrideFramerate: values.overrideFramerate,
        overrideBitrate: values.overrideBitrate,
        audio_bitrate_override: values.audio_bitrate_override
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
      // eslint-disable-next-line no-unused-vars
      const res =
        e.message ||
        e.response.status !== 404 ||
        e.response.status !== 403 ||
        e.response.status !== 401
          ? dispatch(hideSpinner())
          : null;
    }
  };
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name guid
 * @params none
 * @descriptiton creates 16 char unique string
 **/
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
