import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import { history } from '../history';
import Cookies from 'universal-cookie';
import { store } from '../store';
import { userActions } from '../actions';
import { removeLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';

const cookies = new Cookies();
export const errorBlock = (e, bossId) => {
  const rollbarObj = !!window.rollbarObj ? window.rollbarObj : null;

  store.dispatch(userActions.isLoadingFalse());

  if (e?.code === 'ERR_NETWORK') {
    notify('error', i18n.t('502'));
  }
  if (!!bossId) {
    if (!!bossId.url) {
      if (bossId.url.includes('/dashboard/facebookanalytics')) {
        notify('error', i18n.t('ERROROCCURED') + ' - 1!');
        history.push('/dashboard/units');
        return;
      }
      if (bossId.url.includes('/dashboard/facebook')) {
        // notify('error', i18n.t('ERROROCCURED') + ' - 2!');
        history.push(`/dashboard/edit/${bossId.params.bossId}`);
        return;
      }
      if (bossId.url.includes('/dashboard/editfacebook')) {
        notify('error', i18n.t('ERROROCCURED') + ' - 3!');
        history.push(`/dashboard/edit/${bossId.params.bossId}`);
        return;
      }
      //cookies.remove('FB_access_token', { path: '/' });
      //cookies.remove('FB_user_id', { path: '/' });
    }
  }

  if (!!e.message && !e.response) {
    if (!e.message.toLowerCase().includes('network error')) {
      //don't log network errors
      !!rollbarObj && rollbarObj.error(e);
      // notify('error', i18n.t('ERROROCCURED') + ' - 4!');
      return;
    }
  }
  if (e.error && e.error.message) {
    notify('error', e.error.message);
    return;
  }
  if (!e.message && !e.response) {
    !!rollbarObj && rollbarObj.error(e);
    // notify('error', i18n.t('ERROROCCURED') + ' - 5!');
    return;
  }
  if (!!e.response) {
    return errorWithXhrResponse(e, bossId, rollbarObj);
  }
};

const errorWithXhrResponse = (e, bossId, rollbarObj) => {
  let rollbar = rollbarObj;
  let flagF1 = false;
  try {
    if (!!e.response.config) {
      if (
        !e.response.config.url
          .toLowerCase()
          .includes('https://graph.facebook.com') &&
        !e.response.config.url.toLowerCase().includes('fbcdn.net') &&
        e.response.status !== 401
      ) {
        !!rollbar && rollbar.error(e);
      }
      if (
        e.response.config.url
          .toLowerCase()
          .includes('https://graph.facebook.com')
      ) {
        flagF1 = true;
        if (
          e.response.data.error.code === 190 ||
          e.response.data.error.code === 2500
        ) {
          notify('error', i18n.t('FBSESSIONEXPIRED'));
          if (e.response.data.error.code === 190) {
            removeLocalStorageItem('FB_access_token');
            removeLocalStorageItem('FB_user_id');
          }
          if (!!bossId) {
            if (!!bossId.url) {
              if (bossId.url.includes('/dashboard/facebookanalytics')) {
                history.push('/dashboard/units');
                return;
              }
              if (bossId.url.includes('/dashboard/facebook')) {
                history.push(`/dashboard/edit/${bossId.params.bossId}`);
                return;
              }
              if (bossId.url.includes('/dashboard/editfacebook')) {
                history.push(`/dashboard/edit/${bossId.params.bossId}`);
                return;
              }
            }
          }
        }
        if (!!bossId) {
          if (!!bossId.url) {
            //cookies.remove('FB_access_token', { path: '/' });
            //cookies.remove('FB_user_id', { path: '/' });
            //notify('error', i18n.t('Error occured - 6!'));
            notify(
              'error',
              e.response.data.error.message || i18n.t('ERROROCCURED') + ' - 6!',
            );
            if (bossId.url.includes('/dashboard/facebookanalytics')) {
              history.push('/dashboard/units');
              return;
            }
            if (bossId.url.includes('/dashboard/facebook')) {
              history.push(`/dashboard/edit/${bossId.params.bossId}`);
              return;
            }
            if (bossId.url.includes('/dashboard/editfacebook')) {
              history.push(`/dashboard/edit/${bossId.params.bossId}`);
              return;
            }
          }
        }
        //notify('error', i18n.t('Error occured!'));
      } else if (
        e.response.config.url
          .toLowerCase()
          .includes('https://content.googleapis.com')
      ) {
        flagF1 = true;
        if (e.response.status === 403) {
          notify('error', e.response.data.error.errors[0].message);
          if (
            e.response.data.error.errors[0].reason ===
              'liveStreamingNotEnabled' ||
            e.response.data.error.errors[0].reason === 'quotaExceeded' ||
            e.response.data.error.errors[0].reason === 'dailyLimitExceeded'
          ) {
            //yt quota error includes tag and react antd notify not able to handle
          }
        } else if (e.response.status === 500) {
          notify('error', e.response.data.error.message);
        } else {
          notify('error', i18n.t('YTSESSIONEXPIRED'));
          cookies.remove('YT_access_token', { path: '/' });
          // delete cookies.get('YT_access_token');
          if (!!bossId) {
            if (!!bossId.url) {
              if (bossId.url.includes('/dashboard/youtubeanalytics')) {
                history.push('/dashboard/units');
              } else if (bossId.url.includes('/dashboard/units')) {
                history.push(`/dashboard/units/${bossId.params.bossId}`);
              } else {
                history.push('/dashboard/edit/' + bossId.params.bossId);
              }
            }
          }
        }
      } else if (e.response.config.url.toLowerCase().includes('linkedin')) {
        flagF1 = true;
        if (
          e.response.status === 404 ||
          e.response.status === 403 ||
          e.response.status === 401
        ) {
          if (
            e.response.data.errors[0].message
              .toLowerCase()
              .includes('revoked') ||
            e.response.data.errors[0].message
              .toLowerCase()
              .includes('invalid') ||
            !!e.response.data.errors[0].message
          ) {
            notify('error', i18n.t('LINKEDINACCOUNTPERMISSION'));
            notify('error', i18n.t('LINKEDINSESSIONEXPIRED'));
            cookies.remove('LI_access_token', { path: '/' });
            // delete cookies.get('LI_access_token');
            if (!!bossId.url && bossId.url.includes('/dashboard/linkedin')) {
              history.push('/dashboard/linkedinlogin/' + bossId.params.bossId);
            }
          } else {
            notify('error', i18n.t('LINKEDINLIVESTREAMINGNOTENABLED'));
          }
        } else {
          notify('error', i18n.t('UNAUTHORIZEDCLIENT'));
        }
      } else if (e.response.config.url.toLowerCase().includes('easylive')) {
        flagF1 = true;
        if (
          e.response.status === 404 ||
          e.response.status === 403 ||
          e.response.status === 401
        ) {
          notify('error', i18n.t('ELSESSIONEXPIRED'));
          cookies.remove('el_access_token', { path: '/' });
          // delete cookies.get('el_access_token');
        } else {
          notify('error', i18n.t('UNAUTHORIZEDCLIENT'));
        }
        history.push('/dashboard/easylivelogin/' + bossId);
      } else if (e.response.config.url.toLowerCase().includes('switchboard')) {
        flagF1 = true;
        if (e.response.status === 403) {
          notify(
            'error',
            `${i18n.t('SWITCHBOARDSESSIONEXPIRED')} ${i18n.t('REFRESHING')}`,
          );
          return 403;
        } else if (e.response.status === 404 || e.response.status === 401) {
          notify('error', i18n.t('SWITCHBOARDSESSIONEXPIRED'));
          cookies.remove('SB_access_token', { path: '/' });
          // delete cookies.get('SB_access_token');
          history.push('/dashboard/switchboardlogin/' + bossId);
        } else {
          notify('error', i18n.t('UNAUTHORIZEDCLIENT'));
          history.push('/dashboard/switchboardlogin/' + bossId);
        }
      } else if (e.response.config.url.toLowerCase().includes('restream')) {
        flagF1 = true;
        if (
          e.response.status === 404 ||
          e.response.status === 403 ||
          e.response.status === 401
        ) {
          notify('error', i18n.t('RESTREAMSESSIONEXPIRED'));
          // delete cookies.get('restream_access_token');
          cookies.remove('restream_access_token', { path: '/' });
        } else {
          notify('error', i18n.t('UNAUTHORIZEDCLIENT'));
        }
        history.push('/dashboard/restreamlogin/' + bossId);
      } else if (e.response.config.url.toLowerCase().includes('periscope')) {
        flagF1 = true;
        if (e.response.config.url.toLowerCase().includes('token')) {
          if (e.response.status === 403 || e.response.status === 404) {
            notify('error', i18n.t('LOGINLIMITREACHED'));
          } else {
            notify('error', i18n.t('UNAUTHORIZEDCLIENT'));
          }
        } else if (
          e.response.status === 401 ||
          e.response.status === 403 ||
          e.response.status === 404
        ) {
          // delete cookies.get('periscope_access_token');
          // delete cookies.get('periscope_refresh_token');
          notify('error', i18n.t('PERISCOPESESSIONEXPIRED'));
        } else {
          if (e.response.data.errors) {
            notify('error', e.response.data.errors[0].message);
          }
        }
      } else if (e.response.config.url.toLowerCase().includes('wowza')) {
        flagF1 = true;
        if (e.response.config.url.toLowerCase().includes('validate')) {
          notify('error', i18n.t('INVALIDCONNECTION'));
        } else if (e.response.status === 401) {
          cookies.remove('wowza_long_token', { path: '/' });
          notify('error', i18n.t('WOWZASESSIONEXPIRED'));
          history.push('/dashboard/wowza/' + bossId);
        } else {
          if (e.response.status === 404) {
            notify('error', i18n.t('TRANSCODERNOTFOUND'));
          } else if (e.response.status === 500) {
            notify('error', e.response.data.error);
          } else {
            notify('error', i18n.t('TRANSCODERNOTFOUND'));
          }
          history.push('/dashboard/wowzartmp/' + bossId);
        }
      } else if (e.response.config.url.toLowerCase().includes('boxcast')) {
        flagF1 = true;
        cookies.remove('boxcast_access_token', { path: '/' });
        // delete cookies.get('boxcast_access_token');
        notify('error', i18n.t('UNAUTHORIZEDCLIENT'));
        history.push('/dashboard/boxcastlogin/' + bossId);
      } else if (e.response.config.url.toLowerCase().includes('vimeo')) {
        flagF1 = true;
        if (e.response.status === 400) {
          notify('error', i18n.t('EL500ERROR'));
          return;
        } else if (e.response.status !== 404) {
          cookies.remove('vimeo_access_token', { path: '/' });
          notify('error', i18n.t('VIMEOSESSIONEXPIRED'));
        } else if (e.response.data.error_code === 5000) {
          notify('error', i18n.t('BROADCASTNOTEXIST'));
        } else {
          notify('error', i18n.t('UNAUTHORIZEDCLIENT') + 1);
        }
        if (!!bossId) {
          if (!!bossId.url) {
            if (bossId.url.includes('/dashboard/vimeo')) {
              history.push('/dashboard/vimeologin/' + bossId.params.bossId);
            }
          }
        }
      } else if (e.response.config.url.toLowerCase().includes('stripe')) {
        flagF1 = true;
        if (
          e.response.status === 404 ||
          e.response.status === 402 ||
          e.response.status === 400
        ) {
          if (
            e.response.config.url
              .toLowerCase()
              .includes('getupcominginvoices') &&
            e.response.status === 404
          ) {
          } else if (e.response.status === 400) {
            notify('error', i18n.t('ERROR'));
          } else {
            notify('error', e.response.data.errors[0].message);
          }
        }
      } else if (e.response.config.url.toLowerCase().includes('twitch')) {
        flagF1 = true;
        if (
          // eslint-disable-next-line eqeqeq
          e.response.status == 500 &&
          e.response.config.url === 'https://api.twitch.tv/helix/'
        ) {
          notify('error', i18n.t('ENABLETWOFACTORAUTHTWITCH'));
        }
        cookies.remove('twitch_access_token', { path: '/' });
        // delete cookies.get('twitch_access_token');
        notify('error', i18n.t('TWITCHSESSIONEXPIRED'));
        history.push('/dashboard/twitchlogin/' + bossId);
      } else if (e.response.config.url.toLowerCase().includes('liveu')) {
        flagF1 = true;
        if (e.response.config.url.toLowerCase().includes('password')) {
          if (e.response.status === 404) {
            notify('error', e.response.data.messages[0].description);
          }
        }
        if (e.response.config.url.toLowerCase().includes('inventory/user')) {
          if (e.response.config.url.toLowerCase().includes('verify')) {
            if (e.response.status === 404) {
              notify('error', e.response.data.messages[0].description);
            }
          } else if (e.response.config.data) {
            if (e.response.data.messages) {
              switch (e.response.data.messages[0].code) {
                case 6312:
                  notify(
                    'error',
                    i18n.t('PASSWORD') +
                      ' ' +
                      e.response.data.messages[0].description,
                  );
                  break;
                default:
                  notify('error', e.response.data.messages[0].description);
                  break;
              }
            } else {
              notify('error', i18n.t('REQUESTFAIL'));
            }
          }
        }
        if (e.response.config.url.toLowerCase().includes('account/password')) {
          if (e.response.status === 404) {
            notify('error', e.response.data.messages[0].description);
          } else if (e.response.status === 400) {
            if (e.response.data.code === 1001) {
              notify('error', e.response.data.messages[0].description);
            } else {
              notify('error', i18n.t('REQUESTFAIL'));
            }
          } else {
            notify('error', i18n.t('REQUESTFAIL'));
          }
        }
        if (e.response.config.url.toLowerCase().includes('units?reg_code')) {
          if (e.response.data.messages && e.response.data.messages[0].code) {
            notify('error', e.response.data.messages[0].description);
          } else {
            notify('error', i18n.t('ERROR'));
          }
        }
        if (e.response.config.url.toLowerCase().includes('details/request')) {
          if (
            e.response.data.messages &&
            e.response.data.messages[0].code === 8006
          ) {
            notify('error', e.response.data.messages[0].description);
          } else {
            notify('error', i18n.t('ERROR'));
          }
        }
      } else if (!flagF1) {
        !!rollbar && rollbar.error(e);
        notify('error', i18n.t('ERROROCCURED') + ' - 7!');
      }
    }
  } catch (e) {
    !!rollbar && rollbar.error(e);
    notify('error', i18n.t('ERROROCCURED') + ' - 8!');
  }
};
