import { destinationConstant, editunitConstants } from '../../constants';
import {
  editUnitsService,
  unitService,
  periscopeTwitterService,
} from '../../services';
import { errorBlock } from '../../api/errorBlock';
import { history } from '../../history';
import { FBAction } from './fb.actions';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const editUnitAction = {
  getChannelServices,
  getAWSDestInven,
  getStreamingProviders,
  selectProvider,
  getStreamingProvidersFromApi,
};
const {
  // REACT_APP_isMainLandChina,
  REACT_APP_EasyliveScope,
  REACT_APP_EasyLiveClientID,
  REACT_APP_BoxcastClientID,
  REACT_APP_BoxcastScope,
  REACT_APP_VimeoScopes,
  REACT_APP_VimeoClientID,
  REACT_APP_environment,
  REACT_APP_restreamClientID,
  REACT_APP_TwitchAppID,
  REACT_APP_switchboardClientID,
  REACT_APP_switchboardScopes,
  REACT_APP_LinkedinScope,
  REACT_APP_LinkedinClientID,
} = process.env;
/**
 * @name getChannelServices
 * @params match
 * @descriptiton get unit and its services
 **/

/**
 * @name periscopeTwitterService
 * @descriptiton get unit and its services
 **/
function getTwitterOAuth() {
  const { getTwitterOAuthToken } = periscopeTwitterService;

  // function request() {
  //   return { type: editunitConstants.GET_TWITTER_AUTH_REQ };
  // }

  function failure(error) {
    return { type: editunitConstants.GET_CHANNEL_SERVICE_FAIL, error };
  }

  return async (dispatch) => {
    try {
      // dispatch(request());
      const oAuth = await getTwitterOAuthToken();
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('oauth_token_secret', oAuth.oauth_token_secret, {
        path: '/',
        expires: d,
        secure: true,
      });
      window.location = `https://api.twitter.com/oauth/authorize?oauth_token=${oAuth.oauth_token}`;
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };
}

function getChannelServices(match) {
  let { bossId } = match.params;

  return async (dispatch) => {
    try {
      dispatch(getStreamingProviders());
      dispatch(request());
      const getChann = await unitService.getChannelServicesAndDestination(
        bossId,
      );
      dispatch(success(getChann));
      dispatch(getStreamingProvidersFromApi());
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };

  function request() {
    return { type: editunitConstants.GET_STREAMING_PROVIDERS_REQ };
  }

  function success(getChannData) {
    return { type: editunitConstants.GET_CHANNEL_SERVICE, getChannData };
  }

  function failure(error) {
    return { type: editunitConstants.GET_CHANNEL_SERVICE_FAIL, error };
  }
}
/**
 * @name getStreamingProviders
 * @params none
 * @descriptiton get all providers
 **/
function getStreamingProviders() {
  return async (dispatch) => {
    try {
      const getProviders = await editUnitsService.getStreamingProviders();
      dispatch(success(getProviders));
      dispatch(getAWSDestInven());
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };

  function success(streamProvidersData) {
    return {
      type: editunitConstants.GET_STREAMING_PROVIDERS,
      streamProvidersData,
    };
  }

  function failure(error) {
    return { type: editunitConstants.GET_STREAMING_PROVIDERS_FAILURE, error };
  }
}

/**
 * @name getStreamingProvidersFromApi
 * @params none
 * @descriptiton get all providers from Api
 **/
function getStreamingProvidersFromApi() {
  return async (dispatch) => {
    try {
      const { status, data } =
        await editUnitsService.getStreamingProvidersFromApi();
      dispatch(success(data));
      dispatch({
        type: destinationConstant.STREAMING_PROVIDERS_SUCCESS,
        providers: data,
      });

      !status && getAWSDestInven();
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };

  function success(streamProvidersData) {
    return {
      type: editunitConstants.GET_STREAMING_PROVIDERS,
      streamProvidersData,
    };
  }

  function failure(error) {
    return { type: editunitConstants.GET_STREAMING_PROVIDERS_FAILURE, error };
  }
}

/**
 * @name getAWSDestInven
 * @params none
 * @descriptiton get all aws destination by inventory ID
 **/
function getAWSDestInven() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getAwsDestByInven = await editUnitsService.getAWSDestInven();
      if (getAwsDestByInven.dest.length) {
        dispatch(success(getAwsDestByInven));
        return getAwsDestByInven;
      } else {
        dispatch(success(getAwsDestByInven));
        return getAwsDestByInven.dest;
      }
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
    }
  };

  function request() {
    return { type: editunitConstants.GET_AWS_DESTBYINVEN_REQ };
  }

  function success(awsDestListData) {
    return { type: editunitConstants.GET_AWS_DESTBYINVEN, awsDestListData };
  }

  function failure() {
    return { type: editunitConstants.GET_AWS_DESTBYINVEN_FAILURE };
  }
}
/**
 * @name selectProvider
 * @params provider,match
 * @descriptiton perform redirect based on provider
 **/
function selectProvider(provider, match) {
  return async (dispatch) => {
    const DomainURL =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
    // if (!REACT_APP_isMainLandChina) {
    //     ga('send', 'event', {
    //         eventCategory: 'Destination - ' + provider,
    //         eventAction: 'select_destination',
    //         eventLabel: provider
    //     });
    // }
    // eslint-disable-next-line default-case
    switch (provider.toLowerCase()) {
      case 'youtube':
        if (cookies.get('YT_access_token')) {
          history.push('/dashboard/youtube/' + match.params.bossId);
        } else {
          history.push('/dashboard/configureyoutube/' + match.params.bossId);
        }

        break;
      case 'wowza':
        history.push('/dashboard/wowza/' + match.params.bossId);
        break;
      case 'castr':
        history.push('/dashboard/castr/' + match.params.bossId);
        break;
      case 'facebook':
        if (getLocalStorageItem('FB_access_token')) {
          history.push('/dashboard/facebook/' + match.params.bossId);
        } else {
          dispatch(FBAction.fbLogin(match));
        }
        break;
      case 'periscope and twitter':
        if (cookies.get(`${cookies.get('username')}_twitterLogin`)) {
          history.push('/dashboard/twitter/' + match.params.bossId);
        } else {
          dispatch(getTwitterOAuth());
        }
        break;
      case 'twitch':
        const TwitchRedirectURL =
          REACT_APP_environment === 'development'
            ? DomainURL + '/liveu/dashboard/twitchauth'
            : DomainURL + '/dashboard/twitchauth';
        const TwitchOAuthURL = `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=${REACT_APP_TwitchAppID}&redirect_uri=${encodeURIComponent(
          TwitchRedirectURL,
        )}&scope=user:read:email channel:read:editors channel:read:stream_key user:read:email channel:manage:broadcast channel:manage:videos`;
        if (cookies.get('twitch_access_token')) {
          history.push('/dashboard/twitch/' + match.params.bossId);
        } else {
          window.location = TwitchOAuthURL + '&state=' + match.params.bossId;
        }
        break;
      case 'restream.io':
        const RestreamRedirectURL =
          REACT_APP_environment === 'development'
            ? DomainURL + '/liveu/#/dashboard/restreamauth'
            : DomainURL + '/dashboard/restreamauth';
        const RestreamOAuthURL = `https://api.restream.io/login?response_type=code&client_id=${REACT_APP_restreamClientID}&redirect_uri=${encodeURIComponent(
          encodeURIComponent(RestreamRedirectURL),
        )}`;
        if (cookies.get('restream_access_token')) {
          history.push('/dashboard/restream/' + match.params.bossId);
        } else {
          window.location = window.location =
            RestreamOAuthURL + '&state=' + match.params.bossId;
        }
        break;
      case 'vimeo':
        const VimeoRedirectURL =
          REACT_APP_environment === 'development'
            ? DomainURL + '/liveu/dashboard/vimeoauth'
            : DomainURL + '/dashboard/vimeoauth';
        const VimeoOAuthURL = `https://api.vimeo.com/oauth/authorize?client_id=${REACT_APP_VimeoClientID}&scope=${REACT_APP_VimeoScopes}&response_type=code&redirect_uri=${VimeoRedirectURL}`;
        if (cookies.get('vimeo_access_token')) {
          history.push('/dashboard/vimeo/' + match.params.bossId);
        } else {
          window.location = VimeoOAuthURL + '&state=' + match.params.bossId;
        }
        break;

      case 'easy live':
        const EasyLiveRedirectURL = DomainURL + '/dashboard/easyliveauth';
        // const EasyLiveOAuthURL = `https://www.goeasy.live/auth/dialog/?client_id=${REACT_APP_EasyLiveClientID}&redirect_uri=${EasyLiveRedirectURL}${REACT_APP_EasyliveScope}`;
        const EasyLiveOAuthURL = `https://studio.liveu.tv/?client_id=${REACT_APP_EasyLiveClientID}&redirect_uri=${EasyLiveRedirectURL}${REACT_APP_EasyliveScope}`;
        if (cookies.get('el_access_token')) {
          history.push('/dashboard/easylive/' + match.params.bossId);
        } else {
          window.location = EasyLiveOAuthURL + '&state=' + match.params.bossId;
        }
        break;
      case 'linkedin':
        const LinkedinRedirectURL =
          REACT_APP_environment === 'development'
            ? DomainURL + '/liveu/dashboard/linkedinauth'
            : DomainURL + '/dashboard/linkedinauth';
        const LinkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?client_id=${REACT_APP_LinkedinClientID}&scope=${REACT_APP_LinkedinScope}&response_type=code&redirect_uri=${LinkedinRedirectURL}`;

        if (cookies.get('LI_access_token')) {
          history.push('/dashboard/linkedin/' + match.params.bossId);
        } else {
          window.location = LinkedinOAuthURL + '&state=' + match.params.bossId;
        }
        break;
      case 'boxcast':
        const BoxCastRedirectURL =
          REACT_APP_environment === 'development'
            ? DomainURL + '/liveu/dashboard/boxcastauth'
            : DomainURL + '/dashboard/boxcastauth';
        const BoxCastOAuthURL = `https://auth.boxcast.com/oauth2/auth?client_id=${REACT_APP_BoxcastClientID}&scope=${REACT_APP_BoxcastScope}&response_type=code&redirect_uri=${BoxCastRedirectURL}`;

        if (cookies.get('boxcast_access_token')) {
          history.push('/dashboard/boxcast/' + match.params.bossId);
        } else {
          window.location = BoxCastOAuthURL + '&state=' + match.params.bossId;
        }
        break;
      case 'switchboard live':
        const SBRedirectURL =
          REACT_APP_environment === 'development'
            ? DomainURL + '/liveu/dashboard/switchboardauth'
            : DomainURL + '/dashboard/switchboardauth';
        const SBOAuthURL = `https://accounts.switchboard.live/connect/authorize?response_type=code&client_id=${REACT_APP_switchboardClientID}&realm=switchboard.live&scope=${encodeURIComponent(
          REACT_APP_switchboardScopes,
        )}&redirect_uri=${encodeURIComponent(
          SBRedirectURL,
        )}&resource=cloud.switchboard.live`;
        if (cookies.get('SB_access_token')) {
          history.push('/dashboard/switchboard/' + match.params.bossId);
        } else {
          window.location = SBOAuthURL + '&state=' + match.params.bossId;
        }
        break;
    }
  };
}
