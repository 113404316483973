import { paymentConstants, commonConstants, unitConstants } from '../constants';
import { paymentService, unitService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { store } from '../store';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import _ from 'lodash';
const cookies = new Cookies();
const {
  PAYMENT_LOADING_START,
  PAYMENT_LOADING_STOP,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  GET_PLANS_SUCCESS,
  GET_PLANS_ERROR,
  GET_CURRENCY_SUCCESS,
  GET_CUSTOMER_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  //GET_SUBSCRIPTION_ERROR,
  PAYMENT_METHOD_LOADING_START,
  PAYMENT_METHOD_LOADING_STOP,
  PAYMENT_HISTORY_LOADING_START,
  PAYMENT_HISTORY_LOADING_STOP,
  INVOICE_LOADING_START,
  INVOICE_LOADING_STOP,
  PAYMENT_INFO_LOADING_START,
  PAYMENT_INFO_LOADING_STOP,
  GET_INVOICES_SUCCESS,
  GET_CHARGES_SUCCESS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_CHARGE_DETAILS_SUCCESS,
  GET_COUPON_SUCCESS,
  GET_TAX_SUCCESS,
  //GET_UPCOMING_INVOICES_REQUEST,
  GET_UPCOMING_INVOICES_SUCCESS,
  ADD_UNIT_LOADING,
  ADD_UNIT_LOADING_STOP,
  GET_UPCOMING_INVOICES_ERROR,
  GET_CREDIT_BALANCE_SUCCESS,
  GET_LOYALTY_CREDIT_SUCCESS,
  GET_LOYALTY_CREDIT_ERROR,
  GET_USERS_LOYALTY_CREDIT_SUCCESS,
  GET_USERS_LOYALTY_CREDIT_ERROR,
  GET_CREDIT_BALANCE_FAILURE,
  REMOVE_INVOICE,
} = paymentConstants;
const { OTHER_LOADING, OTHER_LOADING_FALSE } = commonConstants;
const {
  SF_LOADING_START,
  SF_LOADING_STOP,
  GET_UNITS_SUCCESS,
  GET_SFLRT_DETAILS_SUCCESS,
  GET_SFSLA_DETAILS_SUCCESS,
} = unitConstants;
export const paymentActions = {
  getCurrency,
  getCustomerDetails,
  verifyCoupon,
  calculateTax,
  makeCouponTaxToDefault,
  removeCoupon,
  proceed,
  addCards,
  getCustomerCards,
  editCreditCard,
  setDefaultCC,
  deleteCC,
  getPaymentHistory,
  getInvoiceChargeDetails,
  getPaymentInfo,
  getUnitsDetailsInfo,
  getCreditBalance,
  deleteSubscription,
  changeSubscription,
  updateCustomerDetails,
  retriveSubscription,
  getCustomerDetailsAddUnit,
  paymentButton,
  createtaxIds,
  startSub,
  getAllSubscription,
  provideCredit,
  retriveLoyaltyCredit,
  getLoyaltyCredit,
  removeInvoice,
};

// Get currency details
function getCurrency(currency) {
  return async (dispatch) => {
    try {
      const res = await paymentService.getCurrency(currency);
      dispatch(success(res));
      cookies.set('currency_amount', res.amount, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        secure: true,
      });
    } catch (err) {}
  };
  function success(res) {
    return { type: GET_CURRENCY_SUCCESS, res };
  }
}

// Get all the plans from APi
function getPlans() {
  return async (dispatch) => {
    try {
      const data = await paymentService.getPlans();
      dispatch(success(data));
    } catch (err) {
      if (err && err.response) {
        dispatch(planfailure(err));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      errorBlock(err);
    }
  };
  function success(res) {
    return { type: GET_PLANS_SUCCESS, res };
  }
  function planfailure(res) {
    return { type: GET_PLANS_ERROR, res };
  }
  function stoploading() {
    return { type: PAYMENT_LOADING_STOP };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}

function getCustomerDetailsAddUnit() {
  return async (dispatch) => {
    dispatch(requestloading());
    try {
      const res = await paymentService.getCustomerDetails();
      dispatch(success(res));
      dispatch(stoploading());
    } catch (err) {
      if (err && err.response) {
        dispatch(success({}));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      // errorBlock(err)
    }
  };
  function success(res) {
    return { type: GET_CUSTOMER_DETAILS_SUCCESS, res };
  }
  function requestloading() {
    return { type: ADD_UNIT_LOADING };
  }
  function stoploading() {
    return { type: ADD_UNIT_LOADING_STOP };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}
// get customer card details
function getCustomerDetails(subscription = false) {
  return async (dispatch) => {
    dispatch(requestloading());
    try {
      dispatch(getPlans());
      // const { customer_id } = store.getState().payment.customer;
      // if (customer_id) {
      const res = await paymentService.getCustomerDetails();
      if (subscription && res && res.sources && res.sources.data[0]) {
        if (
          res.sources.data[0].address_country === 'United States' ||
          res.sources.data[0].address_country === 'US' ||
          res.sources.data[0].address_country === 'Canada' ||
          res.sources.data[0].address_country === 'CA'
        ) {
          const country =
            res.sources.data[0].address_country === 'United States' ||
            res.sources.data[0].address_country === 'US'
              ? 'US'
              : 'CA';
          dispatch(calculateTax(res.sources.data[0].address_zip, country));
        }
      }
      dispatch(success(res));
      subscription && dispatch(getPlans());
      // }else{
      //     dispatch(getPlans())
      // }
    } catch (err) {
      if (err && err.response) {
        dispatch(success({}));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      // errorBlock(err)
    }
  };
  function success(res) {
    return { type: GET_CUSTOMER_DETAILS_SUCCESS, res };
  }
  function requestloading() {
    return { type: PAYMENT_LOADING_START };
  }
  function stoploading() {
    return { type: PAYMENT_LOADING_STOP };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}

// Verify coupon
function verifyCoupon(coupon, cb) {
  return async (dispatch) => {
    try {
      const res = await paymentService.verifyCoupon(coupon);
      if (res.valid) {
        if (
          res.metadata.user &&
          res.metadata.user !== cookies.get('username')
        ) {
          cb(res, null);
        } else {
          const { amount_off, percent_off } = res;
          if (amount_off || percent_off) {
            dispatch(success({ amount_off, percent_off }));
            cb(null, res);
          }
        }
      } else {
        cb(res, null);
      }
    } catch (err) {
      cb(err, null);
    }
  };
  function success(coupon) {
    return { type: GET_COUPON_SUCCESS, coupon };
  }
}

// Calculating tax based on zip
function calculateTax(zip, country) {
  return async (dispatch) => {
    //dispatch(request())
    try {
      if (zip !== undefined && zip.match(/^\w{1,}(?:[-\s]\w{1,})?$/)) {
        dispatch(
          success({ combined_rate: 0, isZipValid: false, taxLoading: true }),
        );
        const res = await paymentService.calculateTax(zip, country);

        const { combined_rate } = res.rate;
        if (combined_rate) {
          dispatch(
            success({
              combined_rate: Number(combined_rate),
              isZipValid: true,
              taxLoading: false,
            }),
          );
        }
        return res;
      } else {
        dispatch(
          success({ combined_rate: 0, isZipValid: true, taxLoading: false }),
        );
      }
    } catch (err) {
      if (!!err.response && err.response.status === 404) {
        notify('warning', i18n.t('NOTUSZIP'));
        dispatch(
          success({ combined_rate: 0, isZipValid: false, taxLoading: false }),
        );
      } else if (
        !!err.response &&
        (err.response.status === 400 ||
          err.response.status === 403 ||
          err.response.status === 500 ||
          err.response.status === 504)
      ) {
        notify('error', err.data.errors[0].message);
        dispatch(
          success({ combined_rate: 0, isZipValid: true, taxLoading: false }),
        );
      } else {
        notify('error', i18n.t('ERROR'));
        dispatch(
          success({ combined_rate: 0, isZipValid: true, taxLoading: false }),
        );
      }
    }
  };
  function success(tax) {
    return { type: GET_TAX_SUCCESS, tax };
  }
}

function makeCouponTaxToDefault() {
  return async (dispatch) => {
    try {
      dispatch(successCoupon({ amount_off: null, percent_off: null }));
      dispatch(
        successTax({ combined_rate: 0, isZipValid: true, taxLoading: false }),
      );
      dispatch(requestloading());
    } catch (err) {}
  };
  function successCoupon(coupon) {
    return { type: GET_COUPON_SUCCESS, coupon };
  }
  function successTax(tax) {
    return { type: GET_TAX_SUCCESS, tax };
  }
  function requestloading() {
    return { type: PAYMENT_LOADING_START };
  }
}

//remove applied coupon
function removeCoupon(cb) {
  return async (dispatch) => {
    try {
      dispatch(successCoupon({ amount_off: null, percent_off: null }));
      cb(null, 'success');
    } catch (err) {
      cb(err, null);
    }
  };
  function successCoupon(coupon) {
    return { type: GET_COUPON_SUCCESS, coupon };
  }
}

//proceed for payment and subscription
function proceed(token, data, stateValue, customerAddress) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      let result = {};
      if (_.isEmpty(customerAddress)) {
        result = await addCustomer(data);
      } else {
        if (data.bname !== customerAddress.bname && data.bname.trim() !== '') {
          const cusObj = {
            business_name: data.bname,
          };
          await updateCus(cusObj);
        }
        if (customerAddress.country === '') {
          const cusObj = {
            business_name: '',
            line1: data && data.address1,
            line2: data && data.address2,
            city: data && data.city,
            state: data && data.state,
            country: data && data.countryCode,
            postal_code: data && data.zipcode,
          };
          await updateCus(cusObj);
        }
      }
      if (token) {
        result = await addCC(token.id, data, stateValue, 'sub');
      } else {
        result = await startSub(data, stateValue);
      }
      dispatch(hideSpinner());
      return result;
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//payment method add cards
function addCards(token, data, stateValue, attach = false) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      let result = {};
      if (!attach) {
        if (data.isCustomerNotExist) {
          result = await addCustomer(data);
        } else if (data.isAddressEmpty) {
          const cusObj = {
            business_name: '',
            line1: data && data.address1,
            line2: data && data.address2,
            city: data && data.city,
            state: data && data.state,
            country: data && data.countryCode,
            postal_code: data && data.zipcode,
          };
          await updateCus(cusObj);
        }
        result = await addCC(token.id, data, stateValue, 'card');
      } else {
        result = await addCC('', data, '', 'attach');
      }

      dispatch(hideSpinner());
      return result;
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//update customer details
async function updateCus(payload) {
  try {
    const res = await paymentService.updateCustomer(payload);
    if (res) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

//add credit card
async function addCC(token, data, stateValue, type) {
  try {
    if (type !== 'attach') {
      var payload = {
        token: token,
        cardholdername: data.fullname,
      };
      const res = await paymentService.addCard(payload);
      if (res) {
        if (type === 'sub') {
          return await startSub(data, stateValue);
        } else {
          return res;
        }
      } else {
        return null;
      }
    } else {
      let payload = {
        paymentId: data.paymentId,
        type: 'attach',
      };

      await paymentService.addCard(payload);
      return await startSub(
        {
          bossId: data.unit_id,
          service_type: data.service_type,
          interval: data.interval,
          tax: data.tax_percent,
          regionDropDown: data.region,
          planChecked: data.plan_id,
          paymentButton: true,
          scnumber: data.scnumber,
          kc: data.kc,
        },
        { ...stateValue, coupon: data.coupon },
      ).then((res) => {
        if (res) notify('success', i18n.t('PLANSUBSCRIBED'));
      });
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

//create stripe customer
async function addCustomer(data) {
  try {
    let payload = {
      city: data.city,
      country: data.country.value,
      add_line1: data.address1,
      add_line2: data.address2,
      zipcode: data.zipcode,
      state: data.state,
    };
    return await paymentService.createCustomer(payload);
    // if (res) {
    //     if(data.bname && res.id){
    //         const nameresult = await updateCus(data, res.id);
    //     }
    //     if (type === 'sub') {
    //         return await startSub(data, stateValue, res.id);
    //     }
    //     else {
    //         return res
    //     }
    // } else {
    //     return null;
    // }
  } catch (err) {
    return Promise.reject(err);
  }
}

//start subscription
async function startSub(data, stateValue) {
  try {
    const { unit } = store.getState().unit;
    var temppayload = {
      unit_id: data.bossId,
      plan_id: stateValue.planChecked || data.planChecked,
      coupon: !stateValue.coupon ? undefined : stateValue.coupon.trim(),
      service_type:
        stateValue.planSelected?.metadata?.service_type || data.service_type,
      package_type: undefined,
      modem:
        stateValue.planSelected?.metadata?.service_type === 'soloconnect' ||
        data?.service_type === 'soloconnect'
          ? data.kc
          : undefined,
      lrt_sn:
        stateValue.planSelected?.metadata?.service_type === 'soloconnect' ||
        data?.service_type === 'soloconnect'
          ? data.scnumber
          : undefined,
      unit_sn:
        stateValue.planSelected?.metadata?.service_type === 'soloconnect' ||
        stateValue.planSelected?.metadata?.service_type === 'lrt' ||
        data?.service_type === 'soloconnect' ||
        data?.service_type === 'lrt' ||
        stateValue.planSelected?.metadata?.service_type === 'streamtool' ||
        stateValue.planSelected?.metadata?.service_type === 'streamtool'
          ? unit.SN
          : undefined,
      interval: stateValue.planSelected?.interval || data.interval,
      tax_percent: !data.tax ? undefined : data.tax,
      region: data.regionDropDown,
      paymentButton: data.paymentButton || undefined,
      unit_type: unit.product,
      streamtool_service: stateValue?.planSelected?.metadata?.service || null,
      // country: data.regionCountry,
    };

    const res = await paymentService.startSubscription(temppayload);
    if (res) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

// get customer card details
function getCustomerCards() {
  return async (dispatch) => {
    dispatch(requestloading());
    try {
      // const { customer_id } = store.getState().payment.customer;
      // if (customer_id) {
      const res = await paymentService.getCustomerDetails();
      if (res) {
        dispatch(cardsuccess(res));
        dispatch(stoploading());
      } else {
        dispatch(cardsuccess({}));
        dispatch(stoploading());
      }
      // } else {
      //     const customer = await paymentService.getCustomer();
      //     if (customer.length && customer[0].customer_id !== undefined) {
      //         dispatch(success(customer[0]));
      //         const res = await paymentService.getCustomerDetails(customer[0].customer_id);
      //         if (res) {
      //             dispatch(cardsuccess(res));
      //             dispatch(stoploading());
      //         } else {
      //             dispatch(cardsuccess({}));
      //             dispatch(stoploading());
      //         }
      //     } else {
      //         dispatch(success({}));
      //         dispatch(cardsuccess({}));
      //         dispatch(stoploading());
      //     }
      // }
    } catch (err) {
      if (err && err.response) {
        dispatch(success({}));
        dispatch(cardsuccess({}));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      errorBlock(err);
    }
  };
  function requestloading() {
    return { type: PAYMENT_METHOD_LOADING_START };
  }
  function success(customer) {
    return { type: GET_CUSTOMER_SUCCESS, customer };
  }
  function stoploading() {
    return { type: PAYMENT_METHOD_LOADING_STOP };
  }
  function cardsuccess(res) {
    return { type: GET_CUSTOMER_DETAILS_SUCCESS, res };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}

//edit credit card
function editCreditCard(value, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const exp = value.expiry.split('/');
      var payload = {
        card_id: value.card_id,
        name: value.fullname,
        exp_month: exp[0],
        exp_year: exp[1],
        address_city: value.city,
        address_country: value.country.label,
        address_line1: value.address1,
        address_line2: value.address2,
        address_zip: value.zipcode,
        address_state: value.state,
      };
      const res = await paymentService.editCard(payload);
      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//set primary/default card
function setDefaultCC(card_id, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const { customer_id } = store.getState().payment.customer;
      var payload = {
        customer_id: customer_id,
        card_id: card_id,
      };
      const res = await paymentService.setDefaultCard(payload);
      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//delete credit card
function deleteCC(card_id, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const { customer_id } = store.getState().payment.customer;
      var payload = {
        customer_id: customer_id,
        card_id: card_id,
      };
      const res = await paymentService.deleteCard(payload);
      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

// get customer payment history
function getPaymentHistory() {
  return async (dispatch) => {
    dispatch(requestloading());
    dispatch(invoicesuccess([]));
    dispatch(chargesuccess([]));
    try {
      // const { customer_id } = store.getState().payment.customer;
      // if (customer_id) {
      dispatch(getInvoicesCharges());
      // } else {
      //     const customer = await paymentService.getCustomer();
      //     if (customer.length && customer[0].customer_id !== undefined) {
      //         dispatch(success(customer[0]));
      //         dispatch(getInvoicesCharges(customer[0].customer_id))
      //     } else {
      //         dispatch(success({}));
      //         dispatch(stoploading());
      //     }
      // }
    } catch (err) {
      if (err && err.response) {
        dispatch(success());
      } else {
        dispatch(failure({}));
      }
      dispatch(stoploading());
      errorBlock(err);
    }
  };
  function requestloading() {
    return { type: PAYMENT_HISTORY_LOADING_START };
  }
  function success(customer) {
    return { type: GET_CUSTOMER_SUCCESS, customer };
  }
  function stoploading() {
    return { type: PAYMENT_HISTORY_LOADING_STOP };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
  function invoicesuccess(resInvoices) {
    return { type: GET_INVOICES_SUCCESS, resInvoices };
  }
  function chargesuccess(resCharges) {
    return { type: GET_CHARGES_SUCCESS, resCharges };
  }
}

//get invoice and charge list
function getInvoicesCharges() {
  return async (dispatch) => {
    try {
      const resInvoices = await paymentService.getInvoices();
      if (resInvoices) {
        dispatch(invoicesuccess(resInvoices));
        const resCharges = await paymentService.getCharges();
        if (resCharges) {
          dispatch(chargesuccess(resCharges));
          dispatch(stoploading());
        } else {
          dispatch(invoicesuccess([]));
          dispatch(chargesuccess([]));
          dispatch(stoploading());
        }
      } else {
        dispatch(invoicesuccess([]));
        dispatch(chargesuccess([]));
        dispatch(stoploading());
      }
    } catch (err) {
      if (err && err.response) {
        dispatch(invoicesuccess([]));
        dispatch(chargesuccess([]));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      errorBlock(err);
    }
  };
  function invoicesuccess(resInvoices) {
    return { type: GET_INVOICES_SUCCESS, resInvoices };
  }
  function chargesuccess(resCharges) {
    return { type: GET_CHARGES_SUCCESS, resCharges };
  }
  function stoploading() {
    return { type: PAYMENT_HISTORY_LOADING_STOP };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}

//get invoice and charge details
function getInvoiceChargeDetails(invoiceId) {
  return async (dispatch) => {
    dispatch(requestloading());
    try {
      const resInvoice = await paymentService.getInvoiceDetails(invoiceId);
      if (resInvoice) {
        dispatch(invoicesuccess(resInvoice));
        const res = await paymentService.getCustomerDetails();
        if (res) {
          dispatch(cardsuccess(res));
        }
        if (resInvoice.charge) {
          const resCharge = await paymentService.getChargeDetails(
            resInvoice.charge,
          );
          if (resCharge) {
            dispatch(chargesuccess(resCharge));
            dispatch(stoploading());
          } else {
            dispatch(invoicesuccess({}));
            dispatch(chargesuccess({}));
            dispatch(stoploading());
          }
        } else {
          dispatch(chargesuccess({}));
          dispatch(stoploading());
        }
      } else {
        dispatch(invoicesuccess({}));
        dispatch(chargesuccess({}));
        dispatch(stoploading());
      }
    } catch (err) {
      if (err && err.response) {
        dispatch(invoicesuccess({}));
        dispatch(chargesuccess({}));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      errorBlock(err);
    }
  };
  function requestloading() {
    return { type: INVOICE_LOADING_START };
  }
  function invoicesuccess(resInvoice) {
    return { type: GET_INVOICE_DETAILS_SUCCESS, resInvoice };
  }
  function chargesuccess(resCharge) {
    return { type: GET_CHARGE_DETAILS_SUCCESS, resCharge };
  }
  function cardsuccess(res) {
    return { type: GET_CUSTOMER_DETAILS_SUCCESS, res };
  }
  function stoploading() {
    return { type: INVOICE_LOADING_STOP };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}

// get customer card details
function getPaymentInfo() {
  return async (dispatch) => {
    dispatch(requestloading());
    try {
      // dispatching Raw Plans from json:
      dispatch(getPlans());
      dispatch(getUnitsDetailsInfo());
      // if (customer_id) {
      dispatch(getAllSubscription('trialing'));
      dispatch(getUpcomingInvoices());
      dispatch(getCreditBalance());

      const res = await paymentService.getCustomerDetails();
      if (res) {
        dispatch(cardsuccess(res));
        dispatch(stoploading());
      } else {
        dispatch(cardsuccess({}));
        dispatch(stoploading());
      }
      // } else {
      //     const customer = await paymentService.getCustomer();
      //     if (customer.length && customer[0].customer_id !== undefined) {
      //         dispatch(getAllSubscription(customer[0].customer_id))
      //         dispatch(getUpcomingInvoices(customer[0].customer_id))
      //         dispatch(success(customer[0]));
      //         const res = await paymentService.getCustomerDetails(customer[0].customer_id);
      //         if (res) {
      //             dispatch(cardsuccess(res));
      //             dispatch(stoploading());
      //         } else {
      //             dispatch(cardsuccess({}));
      //             dispatch(stoploading());
      //         }
      //     } else {
      //         dispatch(success({}));
      //         dispatch(cardsuccess({}));
      //         dispatch(stoploading());
      //     }
      // }
    } catch (err) {
      if (err && err.response) {
        dispatch(success({}));
        dispatch(cardsuccess({}));
      } else {
        dispatch(failure());
      }
      dispatch(stoploading());
      errorBlock(err);
    }
  };
  function requestloading() {
    return { type: PAYMENT_INFO_LOADING_START };
  }
  function success(customer) {
    return { type: GET_CUSTOMER_SUCCESS, customer };
  }
  function stoploading() {
    return { type: PAYMENT_INFO_LOADING_STOP };
  }
  function cardsuccess(res) {
    return { type: GET_CUSTOMER_DETAILS_SUCCESS, res };
  }
  function failure() {
    return { type: GET_CUSTOMER_FAILURE };
  }
}

// All studio subscription
function getCreditBalance() {
  return async (dispatch) => {
    // dispatch(request());
    try {
      // const { username } = cookies.get('');
      const res = await paymentService.getCreditBalance(
        cookies.get('username'),
      );
      dispatch(success(res));
    } catch (err) {
      errorBlock(err);
      failure();
    }
  };
  function success(res) {
    return { type: GET_CREDIT_BALANCE_SUCCESS, res };
  }
  function failure() {
    return { type: GET_CREDIT_BALANCE_FAILURE };
  }
}
function getAllSubscription(type) {
  return async (dispatch) => {
    dispatch(request());
    try {
      let res2 = [];
      if (type === 'trialing') {
        res2 = await paymentService.getAllSubscription('trialing');
      }
      const res = await paymentService.getAllSubscription(
        type === 'trialing' ? 'active' : type,
      );
      dispatch(success([...res, ...res2]));
    } catch (err) {
      errorBlock(err);
    }
  };
  function request() {
    return { type: GET_SUBSCRIPTION_REQUEST };
  }
  function success(res) {
    return { type: GET_SUBSCRIPTION_SUCCESS, res };
  }
  //function failure(res) { return { type: GET_SUBSCRIPTION_ERROR, res } }
}

function getUnitsDetailsInfo() {
  return async (dispatch) => {
    dispatch(startrequest());
    try {
      let sflrt_promises = {};
      let sfsla_promises = {};
      const units = await paymentService.getUnitsDetails();
      //const sfAuth = await salesforceService.salesforceAuth();
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      // cookies.set('salesforce_access_token', sfAuth.access_token, { path: '/', expires:d, secure:true});
      const _units = await Promise.all(
        units.map(async (unit) => {
          if (unit.status !== 'offline') {
            const result = await unitService.getChannelServicesAndDestination(
              unit.BOSSID,
            );
            if (
              result.services.indexOf('lrt') !== -1 &&
              result.services.indexOf('cloud') !== -1
            ) {
              sflrt_promises[unit.SN] = paymentService.sfLrtDetails(
                unit.BOSSID,
              );
            }
          }
          sfsla_promises[unit.SN] = paymentService.sfSlaDetails(unit.BOSSID);
          return unit;
        }),
      );
      dispatch(success(_units));
      const sflrt_key = Object.keys(sflrt_promises);
      const sfsla_key = Object.keys(sfsla_promises);
      const zipObject = function (keys, values) {
        const result = [];
        keys.forEach((key, i) => {
          if (values[i].result === 'service exists') {
            values[i].unitname = key;
            result.push(values[i]);
          } else {
            const rollbarObj = !!window.rollbarObj ? window.rollbarObj : null;
            !!rollbarObj &&
              rollbarObj.warning(values[i].result, {
                description: values[i].result,
                user: cookies.get('username'),
                unitId: key,
              });
          }
        });
        return result;
      };
      //const lrtDetails = await Promise.all(sflrt_promises);
      const temp_lrtDetails = await Promise.all(
        sflrt_key.map((key) => {
          const value = sflrt_promises[key];
          return value;
        }),
      );
      let lrtDetails = zipObject(sflrt_key, temp_lrtDetails);
      //const slaDetails = await Promise.all(sfsla_promises);
      const temp_slaDetails = await Promise.all(
        sfsla_key.map((key) => {
          const value = sfsla_promises[key];
          return value;
        }),
      );
      let slaDetails = zipObject(sfsla_key, temp_slaDetails);
      dispatch(success_lrt_details(lrtDetails));
      dispatch(success_sla_details(slaDetails));
      // dispatch(success_lrt_details(lrtDetails.map(s => {if(s.result === 'service exists') return s})));
      // dispatch(success_sla_details(slaDetails.map(s => {if(s.result === 'service exists') return s})));
      dispatch(stoprequest());
    } catch (err) {
      dispatch(stoprequest());
    }
  };

  function success(units) {
    return { type: GET_UNITS_SUCCESS, units };
  }
  function success_lrt_details(lrtDetails) {
    return { type: GET_SFLRT_DETAILS_SUCCESS, lrtDetails };
  }
  function success_sla_details(slaDetails) {
    return { type: GET_SFSLA_DETAILS_SUCCESS, slaDetails };
  }
  function startrequest() {
    return { type: SF_LOADING_START };
  }
  function stoprequest() {
    return { type: SF_LOADING_STOP };
  }
}

// Get upcoming invoice of customer
function getUpcomingInvoices() {
  return async (dispatch) => {
    //dispatch(request());
    try {
      const resUpInvoices = await paymentService.getUpcomingInvoices();
      dispatch(success(resUpInvoices));
    } catch (err) {
      dispatch(failure());
      errorBlock(err);
    }
  };
  //function request(resUpInvoices) { return { type: GET_UPCOMING_INVOICES_REQUEST } }
  function success(resUpInvoices) {
    return { type: GET_UPCOMING_INVOICES_SUCCESS, resUpInvoices };
  }
  function failure() {
    return { type: GET_UPCOMING_INVOICES_ERROR };
  }
}

//Delete subscritpion
function deleteSubscription(payload, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const res = await paymentService.cancelSubscription(payload);
      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}
//Retrive subscritpion
function retriveSubscription(payload, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const res = await paymentService.cancelSubscription(payload);
      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//Update subscritpion
function changeSubscription(payload, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      const res = await paymentService.updateSubscription(payload);
      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//update customer details
function updateCustomerDetails(value, cb) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      //const { customer_id } = store.getState().payment.customer;
      var payload = {
        ...value,
        business_name: value.bname,
      };
      const res = await paymentService.updateCustomer(payload);

      dispatch(hideSpinner());
      if (res) {
        cb(null, res);
      }
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

//Payment Buttons
function paymentButton(payload) {
  return async (dispatch) => {
    try {
      const res = await paymentService.paymentButton(payload);
      dispatch(success(res));
    } catch (err) {}
  };
  function success(res) {
    return res;
  }
}

function createtaxIds(value, taxId) {
  return async () => {
    // dispatch(showSpinner());
    try {
      //const { customer_id } = store.getState().payment.customer;
      const res = await paymentService.createTaxId(value, taxId);
      if (res) {
        // dispatch(hideSpinner());
        if (!res.deleted) notify('success', i18n.t('TAXIDSUCCESS'));
        if (res.deleted) notify('success', i18n.t('TAXIDDELETESUCCESS'));
        return res;
      }
    } catch (err) {
      // notify('error',err.message)
      // dispatch(hideSpinner());
      errorBlock(err.response.data);
    }
    // function showSpinner() {
    //   return { type: OTHER_LOADING };
    // }
    // function hideSpinner() {
    //   return { type: OTHER_LOADING_FALSE };
    // }
  };
}

function provideCredit(payload) {
  return async (dispatch) => {
    dispatch(showSpinner());
    try {
      await paymentService.addCreditBalance({
        email: cookies.get('username'),
        proSerialNumber: payload.pro_unit.SN,
        regSerialNumber: payload.simple_units.map((item) => item.SN),
        description: 'Credited on subscription of New pro Unit',
      });
      dispatch(hideSpinner());
    } catch (err) {
      dispatch(hideSpinner());
      errorBlock(err);
    }
  };
  function showSpinner() {
    return { type: OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: OTHER_LOADING_FALSE };
  }
}

function retriveLoyaltyCredit(payload) {
  return async (dispatch) => {
    try {
      let res = await unitService.canAvailLoyaltyCredit(payload);
      dispatch(success(res?.data?.data?.response));
    } catch (err) {
      dispatch(failure());
      errorBlock(err.response.data);
    }
  };

  function success(data) {
    return { type: GET_LOYALTY_CREDIT_SUCCESS, data };
  }

  function failure() {
    return { type: GET_LOYALTY_CREDIT_ERROR };
  }
}

function getLoyaltyCredit() {
  // const email = cookies.get('username');
  return async (dispatch) => {
    try {
      let res = await paymentService.getLoyaltyCredit();
      dispatch(success(res?.data?.data?.response));
    } catch (err) {
      dispatch(failure());
      errorBlock(err.response.data);
    }
  };

  function success(data) {
    return { type: GET_USERS_LOYALTY_CREDIT_SUCCESS, data };
  }

  function failure() {
    return { type: GET_USERS_LOYALTY_CREDIT_ERROR };
  }
}

function removeInvoice() {
  return { type: REMOVE_INVOICE };
}
