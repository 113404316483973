import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { HeaderView } from './HeaderView';
import { userActions, unitActions } from '../../actions';

import './Header.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const shouldAdminMsgCall =
  cookies.get('admin_message_visible') &&
  JSON.parse(cookies.get('admin_message_visible'));

class Header extends Component {
  constructor(props) {
    super(props);
    // this.getAdminMessage();
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
    shouldAdminMsgCall && this.getAdminMessage();
  }

  componentDidMount() {
    const { getUserInfo } = this.props;
    // setStreamToolsConfig(units);
    getUserInfo();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const condition =
      prevProps.location.pathname !== location.pathname &&
      location.pathname.includes('/dashboard/units/');
    if (condition && shouldAdminMsgCall) {
      this.getAdminMessage();
    }
  }
  getAdminMessage() {
    this.props.adminMessage();
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  logout = async () => {
    const { unitSettings } = this.props;
    await unitSettings();
  };

  render() {
    const { t, history, match, unit, first_name, last_name, customer } =
      this.props;

    return (
      <HeaderView
        t={t}
        match={match}
        history={history}
        unit={unit}
        logout={this.logout}
        toggle={this.toggle}
        isOpen={this.state.dropdownOpen}
        firstname={first_name}
        customer={customer}
        lastname={last_name}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    unit: state.unit.unit,
    first_name: state.authentication.user.firstName,
    last_name: state.authentication.user.lastName,
    customer: state.payment.customer,
    units: state.unit.units,
  };
};

const mapDispatchToProps = {
  getUserInfo: userActions.getUserInfo,
  unitSettings: unitActions.unitSettings,
  adminMessage: userActions.adminMessage,
  setStreamToolsConfig: userActions.setStreamToolsConfig,
};

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(Header)));

export { ConnectedHeader as Header };
