import React from 'react';
import { StreamToolDestinations } from '../StreamToolDestinations';
import { Destinations } from '../Destinations';

const HandleDestinations = React.memo((props) => {
  const {
    selectedDes,
    match,
    unit,
    t,
    streamToolsUnitsList,
    handleRemoveDestinationLoading,
  } = props;
  // const { selectedDestinations, isStreamToolActive } =
  //   streamToolsUnitsList?.[unit?.BOSSID];

  const unitDetails = streamToolsUnitsList?.[unit?.BOSSID];
  // const isSelectedDestYoutubeExists = () => {
  //   return (
  //     selectedDestinations?.length === 3 &&
  //     isStreamToolActive &&
  //     selectedDestinations.some(
  //       (e) => e.streaming_destination.streaming_provider === 'youtube',
  //     )
  //   );
  // };

  if (
    unitDetails?.isStreamToolActive &&
    unitDetails?.selectedDestinations &&
    unitDetails?.selectedDestinations.length > 0 &&
    unit?.status !== 'offline'
  ) {
    return (
      <div className={`col-md-4 unitBlk_cont d-flex flex-column`}>
        <div className='sub-title'>
          <h4>{t('DESTINATION').toUpperCase()}</h4>
        </div>
        <div className='block-wrap unitDestination'>
          {unitDetails?.selectedDestinations.map((e, index, arr) => (
            <StreamToolDestinations
              currentDestinationCount={index + 1}
              selectedDes={e}
              match={match}
              key={e?.id}
              handleRemoveDestinationLoading={handleRemoveDestinationLoading}
              totalDes={arr.length}
            />
          ))}
        </div>
      </div>
    );
  }

  if (
    !unitDetails?.isStreamToolActive &&
    selectedDes &&
    selectedDes.streaming_destination !== 'undefined'
  ) {
    return (
      <Destinations
        selectedDes={selectedDes}
        match={match}
        handleRemoveDestinationLoading={handleRemoveDestinationLoading}
      />
    );
  }

  return null;
});
export default HandleDestinations;
