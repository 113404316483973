import { REHYDRATE } from 'redux-persist';
import { commonConstants } from '../constants';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const v1 = !!cookies.get('currentSelectedUnit')
  ? { [cookies.get('currentSelectedUnit')]: false }
  : {};
const initialState = {
  providersLoading: true,
  create_Aws_Destination: null,
  create_LUC_Destination: null,
  set_LUC_Destination: null,
  showLoader: false,
  progressBarInfo: { items: [], current  : 0},
  showProgressBar:false,
  disableSubmit: false,
  ovpData: {},
  unitStreamLoader: { ...v1 },
};
const {
  STREAMING_PROVIDERS,
  STREAMING_PROVIDERS_FAILURE,
  CM_CREATE_AWS_DEST,
  CM_CREATE_AWS_DEST_FAILURE,
  CREATE_LUC_DEST,
  CREATE_LUC_DEST_FAILURE,
  SET_LUC_DEST,
  SET_LUC_DEST_FAILURE,
  OTHER_LOADING,
  OTHER_LOADING_FALSE,
  DISABLE_SUBMIT_FALSE,
  DISABLE_SUBMIT_TRUE,
  OVP_DATA_FAILURE,
  OVP_DATA,
  UNIT_STREAM_LOADING,
  SET_PROGRESSBAR_DATA,
  SHOW_PROGRESSBAR,
  HIDE_PROGRESSBAR
} = commonConstants;

export function common(state = initialState, payload) {
  switch (payload.type) {
    case STREAMING_PROVIDERS_FAILURE:
      return { ...state, providersLoading: false };
    case STREAMING_PROVIDERS:
      return { ...state, providersLoading: false };
    case CM_CREATE_AWS_DEST_FAILURE:
      return { ...state, create_Aws_Destination: payload.error };
    case CM_CREATE_AWS_DEST:
      return { ...state, create_Aws_Destination: payload.createAwsDestData };
    case CREATE_LUC_DEST:
      return { ...state, create_LUC_Destination: payload.createLucDesData };
    case CREATE_LUC_DEST_FAILURE:
      return { ...state, create_LUC_Destination: payload.error };
    case SET_LUC_DEST_FAILURE:
      return { ...state, set_LUC_Destination: payload.error };
    case SET_LUC_DEST:
      return { ...state, set_LUC_Destination: payload.setLucDestData };
    case OTHER_LOADING_FALSE:
      return { ...state, showLoader: false };
    case OTHER_LOADING:
      return { ...state, showLoader: true };
    case DISABLE_SUBMIT_TRUE:
      return { ...state, disableSubmit: true };
    case DISABLE_SUBMIT_FALSE:
      return { ...state, disableSubmit: false };
    case OVP_DATA:
      return { ...state, ovpData: payload.data };
    case OVP_DATA_FAILURE:
      return { ...state, ovpData: payload.error };
    case SET_PROGRESSBAR_DATA:
      return { ...state, progressBarInfo: { ...payload.data } }
    case SHOW_PROGRESSBAR:
      return { ...state, showProgressBar: true }
    case HIDE_PROGRESSBAR: 
      return {...state, showProgressBar:false}
    case UNIT_STREAM_LOADING:
      return {
        ...state,
        unitStreamLoader: { ...state.unitStreamLoader, ...payload.Data },
      };
    case REHYDRATE:
      return {
        ...state
      }
    default:
      return state;
  }
}
