export const destinationConstant = {
  GET_CHANNEL_SERVICE_DES_REQUEST: 'GET_CHANNEL_SERVICE_DES_REQUEST',
  GET_CHANNEL_SERVICE_DES_SUCCESS: 'GET_CHANNEL_SERVICE_DES_SUCCESS',
  GET_CHANNEL_SERVICE_DES_ERROR: 'GET_CHANNEL_SERVICE_DES_ERROR',
  AWS_DESTINATIONS_REQ: 'AWS_DESTINATIONS_REQ',
  AWS_DESTINATIONS_SUCCESS: 'AWS_DESTINATIONS_SUCCESS',
  AWS_DESTINATIONS_ERROR: 'AWS_DESTINATIONS_ERROR',
  GET_SELECTED_DES_SUCCESS: 'GET_SELECTED_DES_SUCCESS',
  AWS_DESTINATION_REQ: 'AWS_DESTINATION_REQ',
  AWS_DESTINATION_SUCCESS: 'AWS_DESTINATION_SUCCESS',
  AWS_DESTINATION_ERROR: 'AWS_DESTINATION_ERROR',
  LUC_DESTINATIONS_REQ: 'LUC_DESTINATIONS_REQ',
  LUC_DESTINATIONS_SUCCESS: 'LUC_DESTINATIONS_SUCCESS',
  LUC_DESTINATIONS_ERROR: 'LUC_DESTINATIONS_ERROR',
  GET_SELECTED_DES_REQ: 'GET_SELECTED_DES_REQ',
  GET_SELECTED_DES_ERROR: 'GET_SELECTED_DES_ERROR',
  STREAMING_PROVIDERS_REQ: 'STREAMING_PROVIDERS_REQ',
  STREAMING_PROVIDERS_SUCCESS: 'STREAMING_PROVIDERS_SUCCESS',
  STREAMING_PROVIDERS_FAILURE: 'STREAMING_PROVIDERS_FAILURE',
  GET_UNIT_DETAILS_REQUEST: 'GET_UNIT_DETAILS_REQUEST',
  GET_UNIT_DETAILS_SUCCESS: 'GET_UNIT_DETAILS_SUCCESS',
  GET_UNIT_DETAILS_ERROR: 'GET_UNIT_DETAILS_ERROR',
  INITIAL_MANAGE_DESTINATION: 'INITIAL_MANAGE_DESTINATION',
  OTHER_LOADING: 'OTHER_LOADING',
  OTHER_LOADING_FALSE: 'OTHER_LOADING_FALSE',
  SET_FB_PAGE_ID_SUCCESS: 'SET_FB_PAGE_ID_SUCCESS',
};
