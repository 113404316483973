// import { Promise } from 'bluebird';
import devPlans from '../data/devPlans';
import prodPlans from '../data/prodPlans';
import {
  _addCard,
  _calculateTax,
  _cancelSubscription,
  _createCustomer,
  _editCard,
  _deleteCard,
  _getAllSubscription,
  _getChargeDetails,
  _getCharges,
  _getCurrency,
  _getCustomerDetails,
  _getCustomerId,
  _getInvoiceDetails,
  _getInvoices,
  _getUpcomingInvoices,
  _getCreditBalance,
  _paymentButton,
  _salesforceVerifyUnit,
  _setDefaultCard,
  _startSubscription,
  _updateCustomer,
  _createStripeTaxId,
  _verifyCoupon,
  _updateSubscription,
  _addCreditBalance,
  _getLoyaltyCredit,
} from '../api/aws.api';
import { getLUCUnitByUser } from '../api/luc.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const paymentService = {
  getCustomer,
  getAllSubscription,
  getPlans,
  getCurrency,
  getCustomerDetails,
  verifyCoupon,
  calculateTax,
  createCustomer,
  addCard,
  startSubscription,
  editCard,
  setDefaultCard,
  deleteCard,
  getInvoices,
  getCharges,
  getInvoiceDetails,
  getChargeDetails,
  getUnitsDetails,
  getUpcomingInvoices,
  getCreditBalance,
  sfLrtDetails,
  sfSlaDetails,
  cancelSubscription,
  updateSubscription,
  updateCustomer,
  paymentButton,
  createTaxId,
  addCreditBalance,
  getLoyaltyCredit,
};

async function getCustomer() {
  const awsHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'x-user-uuid': `${cookies.get('user_session')}`,
  };

  try {
    const customer = await _getCustomerId(awsHeaders);
    return customer.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getAllSubscription(type) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getAllSubscription(awsHeaders, type);
    return res.data.data.response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getCreditBalance() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getCreditBalance(awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get all the plans from json
function getPlans() {
  const appMode = process.env.REACT_APP_environment;
  return appMode?.toLowerCase() === 'production' ? prodPlans : devPlans;
}

// Get currency details
async function getCurrency(currency) {
  try {
    const res = await _getCurrency(currency);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
// Get customer card details
async function getCustomerDetails() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getCustomerDetails(awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Verify coupon
async function verifyCoupon(coupon) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _verifyCoupon(awsHeaders, coupon);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Calculate Tax
async function calculateTax(zip, country) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _calculateTax(awsHeaders, zip, country);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Create stripe customer
async function createCustomer(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _createCustomer(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Add card
async function addCard(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _addCard(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Start subscription
async function startSubscription(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _startSubscription(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Edit card
async function editCard(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _editCard(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Change default/primary card
async function setDefaultCard(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _setDefaultCard(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Delete card
async function deleteCard(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _deleteCard(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get all the invoices of customer
async function getInvoices() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getInvoices(awsHeaders);
    return res.data.data.response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get all the charges of customer
async function getCharges() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getCharges(awsHeaders);
    return res.data.data.response.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get invoice details by id
async function getInvoiceDetails(id) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getInvoiceDetails(awsHeaders, id);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get invoice details by id
async function getChargeDetails(id) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getChargeDetails(awsHeaders, id);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function sfLrtDetails(id) {
  try {
    const sfHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let lrtpayload = { number: id, service: 'LRT' };
    const res = await _salesforceVerifyUnit(sfHeaders, lrtpayload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function sfSlaDetails(id) {
  try {
    const sfHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let slapayload = { number: id, service: 'SLA' };
    const res = await _salesforceVerifyUnit(sfHeaders, slapayload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getUnitsDetails() {
  const { REACT_APP_ApplicationId } = process.env;
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await getLUCUnitByUser(lucHeaders);
    return res.data.data.units;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Get upcoming invoice of customer
async function getUpcomingInvoices() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getUpcomingInvoices(awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Cancel subscription
async function cancelSubscription(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _cancelSubscription(awsHeaders, payload);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Update subscription
async function updateSubscription(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _updateSubscription(awsHeaders, payload);
    return res.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

//Update stripe customer
async function updateCustomer(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _updateCustomer(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

// Payment Button
async function paymentButton(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await _paymentButton(awsHeaders, payload);
  } catch (e) {
    return Promise.reject(e);
  }
}

//Create  Delete stripe Tax ID
async function createTaxId(payload, taxId) {
  const { vat, vatId } = payload;
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _createStripeTaxId(
      awsHeaders,
      { type: vatId, value: vat },
      taxId,
    );
    return res.data;
  } catch (err) {
    throw err;
    // console.log('err', JSON.parse(err));
    // // notify('error',err.message)
    // return Promise.reject(err);
  }
}

//Add Credit Balance

async function addCreditBalance(payload) {
  const awsHeaders = {
    authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'x-user-uuid': `${cookies.get('user_session')}`,
  };

  try {
    const response = await _addCreditBalance(awsHeaders, payload);
    if (!response.data.errors) return response.data;
    else throw response;
  } catch (err) {
    throw err;
  }
}

async function getLoyaltyCredit() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await _getLoyaltyCredit(awsHeaders);
  } catch (err) {
    return Promise.reject(err);
  }
}
