import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { withTranslation } from 'react-i18next';
import { paymentActions, lrtActions, liveStreamActions } from '../../actions';
import {
  getSelectedPlans,
  getCustomerCardDetails,
  getCustomerAddress,
  getCouponDiscount,
  getTaxRate,
  subscriptionsList,
} from '../../selectors/payment_selectors';
import { getCustomer } from '../../selectors/unit_selectors';
import { PaymentView } from './PaymentView';
import devPlans from '../../data/devPlans';
import prodPlans from '../../data/prodPlans';
//import { Liveuloading } from '../Liveuloading';
import { OtherSpinner } from '../Otherspinner';
import { getPlans } from '../../helpers/getPlans';
import _ from 'lodash';
// import { Skeleton } from 'antd';
const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

class Payment extends Component {
  state = {
    plans: {
      plan1: [],
    },
  };

  async componentDidMount() {
    const {
      getCustomerDetails,
      history: {
        location: { state },
      },
      match,
      // lrtSubscribed,
      // soloConnect,
      getAllSubscription,
      unit,
      retriveLoyaltyCredit,
      getUserLoyaltyCredit,
    } = this.props;

    const appMode = process.env.REACT_APP_environment;
    if (
      match.url.includes('soloconnect') &&
      (state == null || (state && state.scnumber == null))
    ) {
      window.location.href =
        '/dashboard/units/soloconnect/' + match.params.bossId;
    }

    getCustomerDetails(true);
    getAllSubscription('all');

    let listPlans =
      appMode?.toLowerCase() === 'production' ? prodPlans : devPlans;

    if (match.url.includes('streamtool')) {
      listPlans = listPlans.filter((e) => {
        // if (lrtSubscribed) {
        //   return e.metadata.service === 'lrt';
        // }
        // if (soloConnect) {
        //   return e.metadata.service === 'soloconnect';
        // }
        return e.metadata.service === 'lrt-no-service';
      });
    }

    getUserLoyaltyCredit();
    if (unit?.unit?.product === 'LU_SOLO_PRO') {
      retriveLoyaltyCredit(unit?.unit?.SN);
    }
    const plans = getPlans(listPlans);
    this.setState({ plans: plans });
  }

  componentWillUnmount() {
    const { makeCouponTaxToDefault } = this.props;
    makeCouponTaxToDefault();
  }

  render() {
    const {
      t,
      match,
      history,
      customerAddress,
      isZipValid,
      taxLoading,
      currency,
      showLoader,
      customerCardDetails,
      verifyCoupon,
      coupon,
      calculateTax,
      tax,
      removeCoupon,
      proceed,
      isIndianCustomer,
      addCard,
      soloConnect,
      subscriptions,
      unit,
      loyaltyCredit,
      userLoyaltyCredit,
      setInitialDataLoadedFlag,
    } = this.props;

    return (
      <React.Fragment>
        {this.state.plans.plan1.length > 0 && (
          <Elements stripe={stripePromise}>
            <PaymentView
              t={t}
              match={match}
              history={history}
              plans={this.state.plans}
              currency={currency}
              customerCardDetails={customerCardDetails}
              customerAddress={customerAddress}
              verifyCoupon={verifyCoupon}
              amount_off={coupon.amount_off}
              percent_off={coupon.percent_off}
              calculateTax={calculateTax}
              tax={tax}
              removeCoupon={removeCoupon}
              proceed={proceed}
              isZipValid={isZipValid}
              taxLoading={taxLoading}
              customerDetails={this.props.customerDetails}
              isIndianCustomer={isIndianCustomer}
              addCard={addCard}
              isSoloConnect={soloConnect}
              subscriptions={subscriptions}
              unit={unit}
              provideCredit={this.props.provideCredit}
              loyaltyCredit={loyaltyCredit}
              userLoyaltyCredit={userLoyaltyCredit}
              setInitialDataLoadedFlag={setInitialDataLoadedFlag}
            />
          </Elements>
        )}
        {showLoader && (
          <React.Fragment>
            <OtherSpinner showSpinner={showLoader} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    isLoadingPayment,
    tax,
    customerDetails,
    isIndianCustomer,
    loyaltyCredit,
    userLoyaltyCredit,
  } = state.payment;
  const { currency } = state.currency;
  const { showLoader } = state.common;
  const { unit } = state.unit;

  const subs = state.payment.subscriptions.filter((e) => e.status === 'active');
  let soloConnectExists = null;
  let lrtExists = null;

  if (subs.length > 0) {
    soloConnectExists = _.find(subs, (o) => {
      return (
        o?.metadata?.service_type === 'soloconnect' &&
        o?.metadata?.unit_id === unit?.BOSSID
      );
    });

    lrtExists = _.find(subs, (o) => {
      return (
        o?.metadata?.service_type === 'lrt' &&
        o?.metadata?.unit_id === unit?.BOSSID
      );
    });
  }

  return {
    plans: getSelectedPlans(state),
    isLoadingPayment,
    currency,
    showLoader,
    customer: getCustomer(state),
    customerAddress: getCustomerAddress(state),
    customerCardDetails: getCustomerCardDetails(state),
    coupon: getCouponDiscount(state),
    tax: getTaxRate(state),
    isZipValid: tax.isZipValid,
    taxLoading: tax.taxLoading,
    customerDetails: customerDetails,
    isIndianCustomer: isIndianCustomer,
    soloConnect: soloConnectExists,
    lrtSubscribed: lrtExists,
    subscriptions: subscriptionsList(state),
    unit: state.unit,
    loyaltyCredit,
    userLoyaltyCredit,
  };
};

const mapDispatchToProps = {
  getCustomerDetails: paymentActions.getCustomerDetails,
  verifyCoupon: paymentActions.verifyCoupon,
  calculateTax: paymentActions.calculateTax,
  makeCouponTaxToDefault: paymentActions.makeCouponTaxToDefault,
  removeCoupon: paymentActions.removeCoupon,
  getAllSubscription: paymentActions.getAllSubscription,
  proceed: paymentActions.proceed,
  getCustomerId: lrtActions.getCustomerId,
  startSub: paymentActions.startSub,
  addCard: paymentActions.addCards,
  provideCredit: paymentActions.provideCredit,
  retriveLoyaltyCredit: paymentActions.retriveLoyaltyCredit,
  getUserLoyaltyCredit: paymentActions.getLoyaltyCredit,
  setInitialDataLoadedFlag: liveStreamActions.setInitialDataLoadedFlag,
};

const ConnectedPayment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Payment));
export { ConnectedPayment as Payment };
