import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Entry from './Entry';
import { history } from './history';
import App from './App';
import { Protected } from './components/Protected';
import { lngActions } from './actions';
import { SoloTerms } from './components/SoloTerms/SoloTerms';
import Rollbar from 'rollbar';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { lngChange } = lngActions;

class MainApp extends Component {
  componentDidMount() {
    const language = cookies.get('language');
    const currency = cookies.get('currency');
    const currency_symbol = cookies.get('currency_symbol');
    if (language) {
      this.props.lngChange(language, currency, currency_symbol);
    } else {
      this.props.lngChange('en', 'USD', '$');
    }
    // if (
    //   localStorage.frontEndReset !== undefined &&
    //   JSON.parse(localStorage.frontEndReset)
    // ) {
    //   appReset();
    // } else {
    //   const rollbar = !!window.rollbarObj
    //     ? window.rollbarObj
    //     : new Rollbar(window.rollbarConfig);
    //   rollbar.info(`didn't enter for ${localStorage.lastUsername}`);
    // }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path='/login' component={Entry} />
          <Route path='/register' component={Entry} />
          <Route path='/forgotpassword' component={Entry} />
          <Route path={`/verify_email/token/:token`} component={Entry} />
          <Route path={`/reset/token/:token`} component={Entry} />
          <Route path={`/update/email/:token`} component={Entry} />
          <Route path='/soloterms' component={SoloTerms} />
          <Protected component={App} />
        </Switch>
      </Router>
    );
  }
}
export const appReset = async () => {
  const rollbar = !!window.rollbarObj
    ? window.rollbarObj
    : new Rollbar(window.rollbarConfig);
  try {
    if (
      localStorage.frontEndReset !== undefined &&
      JSON.parse(localStorage.frontEndReset)
    ) {
      const names = await caches.keys();
      await Promise.all(names.map((name) => caches.delete(name)));
      delete localStorage.frontEndReset;
      if (!!rollbar) {
        if (!!localStorage.lastUsername) {
          rollbar.info(`User ${localStorage.lastUsername} migrated to react`);
        } else {
          rollbar.info(`An anonymous user migrated to react`);
        }
      }
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  } catch (e) {
    !!rollbar &&
      rollbar.error(`Failed to migrate ${localStorage.lastUsername}`);
  }
};
export default connect(
  (state) => ({
    loggedIn: state.loggedIn,
  }),
  { lngChange },
)(MainApp);
