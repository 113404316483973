export default function lazycomponentLoader(lazyComponent) {
  let attemptsLeft = 3;
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 0) {
            reject(error);
            return;
          }
          attemptsLeft--;
          lazycomponentLoader(lazyComponent).then(resolve, reject);
        }, 1000);
      });
  });
}
