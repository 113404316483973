import React from 'react';

import './ProfileSettings.css';

import { ProfileSettingsForNormalMode } from './ProfileSettingsForNormalMode';
import { ProfileSettingsForStreamTools } from './PofileSettingsForStreamTools';
import { useSelector } from 'react-redux';

export const ProfileSettings = (props) => {
  const { BOSSID } = useSelector((state) => state.unit?.unit);
  const isStreamToolActive = useSelector(
    (state) => state.streamTools?.[BOSSID]?.isStreamToolActive,
  );

  return (
    <>
      {isStreamToolActive ? (
        <ProfileSettingsForStreamTools {...props} />
      ) : (
        <ProfileSettingsForNormalMode {...props} />
      )}
    </>
  );
};
