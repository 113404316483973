const streamToolsQueue = (() => {
  let pending = Promise.resolve();

  const run = async (cb, params, dispatch, isTerminated) => {
    if (isTerminated) {
      pending = Promise.resolve();
    }
    try {
      await pending;
    } catch (err) {
    } finally {
      const action = cb(...params);
      if (typeof action === 'function') {
        return await action(dispatch); // If action is a thunk
      } else {
        return await action; // If action is a plain object
      }
    }
  };

  return (cb, params, isTerminated = false) => {
    return async (dispatch) =>
      (pending = run(cb, params, dispatch, isTerminated));
  };
})();

export default streamToolsQueue;
