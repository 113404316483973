import { getVimeoToken } from '../api/aws.api';
import {
  updateVimeoStream,
  addFollowertoStream,
  addDomaintoStream,
  getVimeoFollowers,
  getUserDetails,
  getAllEvents,
  createLiveEvent,
  deleteEvents,
  getSingleEvents,
  updateSingleEvents,
} from '../api/vimeo.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const vimeoService = {
  createEvent,
  updStream,
  addFollowers,
  addDomain,
  getFollowers,
  updateStreamStatus,
  userDet,
  getVimToken,
  getAllVimeoEvents,
  delEvents,
  getSpecificVimeoEvents,
  setSpecificVimeoEvents,
};
async function getAllVimeoEvents(userId) {
  try {
    const getTok = await getAllEvents(userId);
    return getTok.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getVimToken(code, redirect) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    accept: 'application/vnd.vimeo.*+json;version=3.4',
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const getTok = await getVimeoToken(code, redirect, awsHeaders);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function userDet() {
  try {
    const str = await getUserDetails();
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function createEvent(payload) {
  try {
    const str = await createLiveEvent(payload);
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
/*async function createStream() {
    try {
        const str = await createVimeoStream({ 'upload': { 'approach': 'live' } });
        return str.data;
    }catch (e) {
        return Promise.reject(e);
    }
}*/
async function updateStreamStatus(id, uri) {
  try {
    const str = await updateVimeoStream(id, uri);
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getFollowers() {
  try {
    const str = await getVimeoFollowers();
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function updStream(id, payload) {
  try {
    const str = await updateVimeoStream(id, payload);
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function addFollowers(id, payload) {
  try {
    const str = await addFollowertoStream(id, payload);
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function addDomain(id, payload) {
  try {
    const str = await addDomaintoStream(id, payload);
    return str.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function delEvents(userId, destId) {
  try {
    const getTok = await deleteEvents(userId, destId);
    return getTok.data.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getSpecificVimeoEvents(userId, destId) {
  try {
    const getTok = await getSingleEvents(userId, destId);
    return getTok.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function setSpecificVimeoEvents(userId, destId, event) {
  try {
    const getTok = await updateSingleEvents(userId, destId, event);
    return getTok.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
