import { getValueForEnvironment } from '../utils/setZoneValues';

export const migrations = {
  0: (state) => {
    const intialState = {
      isStreamToolActive: false,
      selectedDestinations: [],
      progress: 0,
      progressInfo: '',
      dnd: false,
      helperDestination: {},
      streamtoolSubscribed: false,
    };
    if (state?.unit?.units?.length > 0) {
      state.streamTools = {};
      state.unit.units.forEach((unit) => {
        state.streamTools[unit.BOSSID] = intialState;
      });
    } else {
      state.streamTools = {};
    }

    return state;
  },
  1: (state) => {
    const intialState = {
      isStreamToolActive: false,
      selectedDestinations: [],
      progress: 0,
      progressInfo: '',
      dnd: false,
      helperDestination: {},
      streamtoolSubscribed: false,
      fps: 12.48,
      videoBitrate: 400,
      audioBitrate: 128000,
      profile: '1920 x 1080 Widescreen (16:9) 60 fps',
      selectedZone: getValueForEnvironment('eu-irl-1', 'eu-irl-4'),
    };
    if (state?.unit?.units?.length > 0) {
      state.streamTools = {};
      state.unit.units.forEach((unit) => {
        state.streamTools[unit.BOSSID] = intialState;
      });
    } else {
      state.streamTools = {};
    }

    return state;
  },
  2: (state) => {
    const intialState = {
      isStreamToolActive: false,
      selectedDestinations: [],
      progress: 0,
      progressInfo: '',
      dnd: false,
      helperDestination: {},
      streamtoolSubscribed: false,
      fps: 12.48,
      videoBitrate: 400,
      audioBitrate: 128000,
      profile: '1920 x 1080 Widescreen (16:9) 60 fps',
      selectedZone: getValueForEnvironment('eu-irl-1', 'eu-irl-4'),
      initialDataLoaded: false,
    };
    if (state?.unit?.units?.length > 0) {
      state.streamTools = {};
      state.unit.units.forEach((unit) => {
        state.streamTools[unit.BOSSID] = intialState;
      });
    } else {
      state.streamTools = {};
    }

    return state;
  },
};
