import { notification } from 'antd';
// import { store } from '../../store';


export const notify = (type, message) => {
  // const { streamTools: { dnd }, unit: { unit: { BOSSID }} } = store.getState();
  // if (dnd?.[BOSSID]) {
  //   return
  // }
  const config = {
    message: message,
    duration: 3,
    key: message,
  };
  switch (type) {
    case 'warning':
      notification.warning(config);
      break;
    case 'error':
      notification.error(config);
      break;
    case 'info':
      notification.info(config);
      break;
    case 'success':
      notification.success(config);
      break;
    default:
      notification.info(config);
      break;
  }
};
