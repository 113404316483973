import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login } from './components/Login';
import { ForgotPassword } from './components/ForgotPassword';
import { Register } from './components/Register/Register';
import { Verify } from './components/Verify/Verify';
import { Reset } from './components/Reset/Reset';
import { UpdateEmail } from './components/UpdateEmail';
import './Entry.css';
import { ErrorBoundary } from './components/ErrorBoundary';

const Entry = () => {
  return (
    <ErrorBoundary>
      <div className='Entry'>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/register' component={Register} />
          <Route path='/forgotpassword' component={ForgotPassword} />
          <Route path={`/reset/token/:token`} component={Reset} />
          <Route path={`/update/email/token/:token`} component={UpdateEmail} />
          <Route path={`/verify_email/token/:token`} component={Verify} />
          {/* <Route path="/submitted" component={ForgotPassword} /> */}
        </Switch>
      </div>
    </ErrorBoundary>
  );
};

export default Entry;
