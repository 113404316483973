import { referralService } from '../services';
//import { commonConstants } from '../constants';

export const getReferralAction = {
  getReferral,
  getReferralList,
  refInsertToDb,
};
/**
 * @name getReferral
 * @descriptiton gets a getReferral code
 **/
function getReferral() {
  return async () => {
    try {
      var firstPart = (Math.random() * 46656) | 0;
      var secondPart = (Math.random() * 46656) | 0;
      firstPart = ('000' + firstPart.toString(36)).slice(-3);
      secondPart = ('000' + secondPart.toString(36)).slice(-3);
      return firstPart + secondPart;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
    }
  };
}
/**
 * @name insert to db referal code
 * @descriptiton gets a getReferral code
 **/
function refInsertToDb(payload) {
  return async () => {
    try {
      const getRef = await referralService.getreferralCode(payload);
      return getRef.data.data.response;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
    }
  };
}
/**
 * @name getReferral lisr
 * @descriptiton gets a getReferral code
 **/
function getReferralList() {
  return async () => {
    try {
      //dispatch(showLoader());
      const getRefList = await referralService.getreferralCodeList();
      // dispatch(hideSpinner());
      return getRefList.data.data.response.message
        ? null
        : getRefList.data.data.response;
    } catch (e) {
      // dispatch(hideSpinner());
      // eslint-disable-next-line no-console
      console.log('error', e);
    }
  };
}

// function showLoader() {
//   return { type: commonConstants.OTHER_LOADING };
// }
// function hideSpinner() {
//   return { type: commonConstants.OTHER_LOADING_FALSE };
// }
