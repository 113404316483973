import { vimeoConstants, commonConstants } from '../constants';
import {
  destinationService,
  savedDestinationsService,
  vimeoService,
} from '../services';
import { errorBlock } from '../api/errorBlock';
import { history } from '../history';
import { commonAction } from './common.actions';
import { store } from '../store';
import Cookies from 'universal-cookie';
import { savedDestinationsAction } from './editunit/saveddestinations.actions';
import moment from 'moment';
import { notify } from '../components/CommonNotification/CommonNotification';
import { liveStreamActions } from '../actions';
const cookies = new Cookies();

export const vimeoAction = {
  createLiveEvent,
  getFollowers,
  updateStatus,
  userdetails,
  vimeoToken,
  getVimeoEvent,
  setEvent,
  deleteVimeoEvent,
  getSpecificVimeoEvent,
  updateSpecificVimeoEvent,
};
/**
 * @name vimeoToken
 * @params code,redirect,bossID
 * @descriptiton gets vimeo access token.
 **/
function vimeoToken(code, redirect, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getVimTok = await vimeoService.getVimToken(code, redirect);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('vimeo_access_token', getVimTok.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(success(getVimTok));
      history.push('/dashboard/vimeo/' + bossId);
      dispatch(userdetails(bossId));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e);
      history.push('/dashboard/vimeologin/' + bossId);
    }
  };

  function request() {
    return { type: vimeoConstants.GET_VIM_TOKEN_REQ };
  }

  function success(AuthData) {
    return { type: vimeoConstants.GET_VIM_TOKEN, AuthData };
  }

  function failure(error) {
    return { type: vimeoConstants.GET_VIM_TOKEN_FAIL, error };
  }
}
/**
 * @name getVimeoEvent
 * @params none
 * @descriptiton gets vimeo events
 **/
function getVimeoEvent() {
  return async (dispatch) => {
    try {
      dispatch(request(true));
      const userId = cookies.get('vimeo_user_id');
      let user = await vimeoService.getAllVimeoEvents(userId);
      user = user.filter((singleEvent) =>
        singleEvent?.uri?.includes('live_events'),
      );
      if (user && user.length) {
        dispatch(exists(true));
        dispatch(success(user));
      } else {
        dispatch(exists(false));
        dispatch(fail());
      }
      dispatch(request(false));
    } catch (e) {
      dispatch(fail(e.toString()));
      errorBlock(e);
    }
  };

  function request(Data) {
    return { type: vimeoConstants.GET_VIMEO_EVENTS_REQ, Data };
  }
  function exists(Data) {
    return { type: vimeoConstants.VIMEO_EVENTS_EXISTS, Data };
  }
  function success(Data) {
    return { type: vimeoConstants.GET_VIMEO_EVENTS, Data };
  }

  function fail() {
    return { type: vimeoConstants.GET_VIMEO_EVENTS_FAIL };
  }
}
/**
 * @name userdetails
 * @params none
 * @descriptiton gets vimeo user details
 **/
function userdetails() {
  return async (dispatch) => {
    try {
      dispatch(request());
      const user = await vimeoService.userDet();
      const userId = user.uri.substring(
        user.uri.lastIndexOf('/') + 1,
        user.uri.length,
      );

      cookies.set('vimeo_user_id', userId, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        secure: true,
      });
      if (
        !!user &&
        user.hasOwnProperty('live_quota') &&
        !!user.live_quota &&
        user.live_quota.status === 'private_mode'
      ) {
        /*         notify('warning',i18n.t('VIMEOINFO'))*/
        cookies.set('vimeoPrivateMode', true, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
        dispatch(privateMode());
      } else if (
        !!user &&
        user.hasOwnProperty('live_quota') &&
        !!user.live_quota &&
        user.live_quota.status === 'available'
      ) {
        cookies.set('vimeoPrivateMode', false, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else {
        cookies.set('vimeoPrivateMode', user.live_quota.status, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      }
      dispatch(success(user));
    } catch (e) {
      dispatch(fail(e.toString()));
      errorBlock(e);
    }
  };
  function privateMode() {
    return { type: vimeoConstants.PRIVATE_ENABLED };
  }

  function request() {
    return { type: vimeoConstants.VIM_USER_DETAILS_REQ };
  }

  function success(createData) {
    return { type: vimeoConstants.VIM_USER_DETAILS, createData };
  }

  function fail(error) {
    return { type: vimeoConstants.VIM_USER_DETAILS_FAIL, error };
  }
}
/**
 * @name updateStatus
 * @params post id
 * @descriptiton changes post status to completed
 **/
function updateStatus(id, payload) {
  return async (dispatch) => {
    try {
      const uri =
        payload === 'activate'
          ? `/live_events/${id}/activate`
          : `/live_events/${id}/end`;
      const update = await vimeoService.updateStreamStatus(id, uri);
      dispatch(success(update));
      return Promise.resolve(update);
    } catch (e) {
      dispatch(fail(e.toString()));
      dispatch(hideLoader());
      errorBlock(e);
      return Promise.reject(e);
    }
  };
  function success(Data) {
    return { type: vimeoConstants.UPDATE_STREAM_STATUS, Data };
  }

  function fail(error) {
    return { type: vimeoConstants.UPDATE_STREAM_STATUS_FAIL, error };
  }
  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name getFollowers
 * @params bossID
 * @descriptiton get user's followers
 **/
// eslint-disable-next-line no-unused-vars
function getFollowers(bossId) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const fol = await vimeoService.getFollowers();
      dispatch(success(fol));
      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
      dispatch(fail(e.toString()));
      errorBlock(e);
    }
  };

  function success(createData) {
    return { type: vimeoConstants.GET_VIM_FOLLOWERS, createData };
  }

  function fail(error) {
    return { type: vimeoConstants.GET_VIM_FOLLOWERS_FAIL, error };
  }

  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name createStream
 * @params formvalues,bossID
 * @descriptiton creates vimeo post
 **/
/*function createStream(values, bossId) {
    return async dispatch => {
        try {
            dispatch(showLoader());
            const str = await vimeoService.createStream();
            dispatch(success(str));
            dispatch(updateStream(values, bossId));
        } catch (e) {
            dispatch(hideLoader())
            dispatch(fail(e.toString()));
            errorBlock(e,bossId);
        }
    };

    function success(createData) {
        return {type: vimeoConstants.CREATE_VIM_STREAM, createData}
    }

    function showLoader() {
        return {type: commonConstants.OTHER_LOADING}
    }

    function fail(error) {
        return {type: vimeoConstants.CREATE_VIM_STREAM_FAIL, error}
    }
    function hideLoader() {
        return {type: commonConstants.OTHER_LOADING_FALSE}
    }
}*/

/**
 * @name setEvent
 * @params formvalues,bossID
 * @descriptiton sets a vimeo vimeo
 **/
function setEvent(values, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const streamId = values.uri.substring(
        values.uri.lastIndexOf('/') + 1,
        values.uri.length,
      );
      if (values.uri.indexOf('live_event') > 0) {
        cookies.set(`isVimeoLiveEvent_${bossId}`, true, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else {
        cookies.set(`isVimeoLiveEvent_${bossId}`, false, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      }
      if (values.link === undefined) {
        cookies.set(`vimeoPostUrl_${bossId}`, values.uri, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else if (!!values.link) {
        cookies.set(`vimeoPostUrl_${bossId}`, values.link, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else {
        cookies.remove(`vimeoPostUrl_${bossId}`, { path: '/' });
        // delete cookies.get(`vimeoPostUrl_${bossId}`);
      }
      const payload = {
        destinationName: values.title,
        selectedProfile: '1280 x 720 Widescreen (16:9)',
        primary_url: values.rtmps_link,
        secondary_url: values.rtmp_link,
        stream_name: values.stream_key,
        username: '',
        password: '',
        id: streamId,
        streaming_provider: 'Vimeo',
        overrideResolution: '',
        overrideFramerate: '',
        overrideBitrate: '',
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(fail(e.toString()));
      dispatch(hideLoader());
      errorBlock(e, bossId);
    }
  };
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }

  function fail() {
    return { type: vimeoConstants.GET_VIMEO_EVENTS_FAIL };
  }
}
/**
 * @name createLiveEvent
 * @params formvalues,bossID
 * @descriptiton creates vimeo event
 **/
function createLiveEvent(values, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      let payload = {};
      if (values.scheduleLive) {
        if (!JSON.parse(cookies.get('vimeoPrivateMode'))) {
          //check for vimeo private mode, payload will change if private mode enabled from vimeo
          payload = {
            automatically_title_stream: false,
            title: values.destination,
            stream_title: values.destination,
            stream_description: values.description.length
              ? values.description
              : null,
            schedule:
              values.scheduleLive === true
                ? {
                    start_time:
                      values.scheduleType === 'single' &&
                      values.scheduleLive === true
                        ? values.scheduledTime
                        : null,
                    type: values.scheduleType,
                    weekdays:
                      values.scheduleType === 'weekly' ? values.weekdays : {},
                    daily_time:
                      values.scheduleType === 'weekly' &&
                      values.scheduleLive === true
                        ? moment
                            .utc(values.scheduledWeekTime)
                            .format('HH:mm:ss') + 'Z'
                        : null,
                  }
                : {},
            stream_privacy: {
              view: values.privacy.value,
            },
            stream_embed: {
              embed: values.embed.value,
            },
            chat_enabled: values.chat_enabled,
            stream_password: values.password,
          };
        } else {
          payload = {
            automatically_title_stream: false,
            title: values.destination,
            stream_title: values.destination,
            stream_description: values.description,
            schedule:
              values.scheduleLive === true
                ? {
                    start_time:
                      values.scheduleType === 'single' &&
                      values.scheduleLive === true
                        ? values.scheduledTime
                        : null,
                    type: values.scheduleType,
                    weekdays:
                      values.scheduleType === 'weekly' ? values.weekdays : {},
                    daily_time:
                      values.scheduleType === 'weekly' &&
                      values.scheduleLive === true
                        ? moment
                            .utc(values.scheduledWeekTime)
                            .format('HH:mm:ss') + 'Z'
                        : null,
                  }
                : {},
          };
        }
      } else {
        if (!JSON.parse(cookies.get('vimeoPrivateMode'))) {
          //check for vimeo private mode, payload will change if private mode enabled from vimeo
          payload = {
            automatically_title_stream: false,
            title: values.destination,
            stream_title: values.destination,
            stream_description: values.description.length
              ? values.description
              : null,
            stream_privacy: {
              view: values.privacy.value,
            },
            stream_embed: {
              embed: values.embed.value,
            },
            chat_enabled: values.chat_enabled,
            stream_password: values.password,
          };
        } else {
          payload = {
            automatically_title_stream: false,
            title: values.destination,
            stream_title: values.destination,
            stream_description: values.description,
          };
        }
      }
      const str = await vimeoService.createEvent(payload);
      dispatch(success(str));

      if (str.uri.indexOf('live_event') > 0) {
        cookies.set(`isVimeoLiveEvent_${bossId}`, true, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else {
        cookies.set(`isVimeoLiveEvent_${bossId}`, false, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      }
      if (!!str.link) {
        cookies.set(`vimeoPostUrl_${bossId}`, str.link, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else {
        cookies.remove(`vimeoPostUrl_${bossId}`, { path: '/' });
        // delete cookies.get(`vimeoPostUrl_${bossId}`);
      }
      const streamId = str.uri.substring(
        str.uri.lastIndexOf('/') + 1,
        str.uri.length,
      );
      if (values.embed.value === 'whitelist' && values.domain.length) {
        dispatch(addDomain(streamId, values, bossId));
      }
      dispatch(makePayload(streamId, values, bossId));
    } catch (e) {
      dispatch(fail(e.toString()));
      dispatch(hideLoader());
      errorBlock(e, bossId);
    }
  };
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
  function success(createData) {
    return { type: vimeoConstants.UPDATE_VIM_STREAM, createData };
  }

  function fail(error) {
    return { type: vimeoConstants.UPDATE_VIM_STREAM_FAIL, error };
  }
}

/**
 * @name setEvent
 * @params formvalues,bossID
 * @descriptiton sets a vimeo vimeo
 **/
// function setEvent(values,bossId) {
//     return async dispatch => {
//         try {
//             dispatch(showLoader());
//             const streamId = values.uri.substring(values.uri.lastIndexOf('/') + 1, values.uri.length);
//             const payload = {
//                 destinationName: values.title,
//                 selectedProfile: "1280 x 720 Widescreen (16:9)",
//                 primary_url: values.rtmps_link,
//                 secondary_url: values.rtmp_link,
//                 stream_name: values.stream_key,
//                 username: '',
//                 password: '',
//                 id: streamId,
//                 streaming_provider: 'Vimeo',
//                 overrideResolution: '',
//                 overrideFramerate: '',
//                 overrideBitrate: ''
//             };
//             dispatch(commonAction.createAwsDestination(payload, bossId));

//         } catch (e) {
//             dispatch(fail(e.toString()));
//             dispatch(hideLoader())
//             errorBlock(e,bossId);

//         }
//     };
//     function showLoader() {
//         return {type: commonConstants.OTHER_LOADING}
//     }
//     function hideLoader() {
//         return {type: commonConstants.OTHER_LOADING_FALSE}
//     }

//     function fail() {
//         return {type: vimeoConstants.GET_VIMEO_EVENTS_FAIL}
//     }
// }
/**
 * @name updateStream
 * @params formvalues,bossID
 * @descriptiton updates vimeo post
 **/
/*function updateStream(values, bossId) {
    return async dispatch => {
        try {
            const stream = store.getState().vimeo.vimeo.create;
            const streamId = stream.uri.substring(stream.uri.lastIndexOf('/') + 1, stream.uri.length);
            let payload = {};
            if(!JSON.parse(cookies.get('vimeoPrivateMode'))){//check for vimeo private mode, payload will change if private mode enabled from vimeo
                payload = {
                    'name': values.destination,
                    'description': values.description,
                    'live': {'status': 'ready'},
                    'privacy': {
                        'view': values.privacy.value,
                        'embed': values.embed.value,
                        'comments': values.comment.value,
                        'download': values.download
                    },
                    'password': values.password
                };
            }else{
                payload = {
                    'name': values.destination,
                    'description': values.description,
                    'live': {'status': 'ready'}
                };
            }
            const str = await vimeoService.updStream(streamId, payload);
            dispatch(success(str));
            if (values.privacy.value === 'users' && values.people.length) {
                dispatch(addFollower(streamId, values, bossId));
            }
            if (values.embed.value === 'whitelist' && values.domain.length) {
                dispatch(addDomain(streamId, values, bossId));
            }
            dispatch(makePayload(streamId, values, bossId));
        } catch (e) {
            dispatch(fail(e.toString()));
            dispatch(hideLoader())
            errorBlock(e,bossId);

        }
    };
    function hideLoader() {
        return {type: commonConstants.OTHER_LOADING_FALSE}
    }
    function success(createData) {
        return {type: vimeoConstants.UPDATE_VIM_STREAM, createData}
    }

    function fail(error) {
        return {type: vimeoConstants.UPDATE_VIM_STREAM_FAIL, error}
    }
}*/
/**
 * @name addFollower
 * @params postId,formValues,bossID
 * @descriptiton adds follower to a vimeo post
 **/
// function addFollower(streamId, values, bossId) {
//     return async dispatch => {
//         try {
//             const payload = values.people.map((value) => {
//                 return {'uri': value.uri};
//             });
//             const add = await vimeoService.addFollowers(streamId, payload);
//             dispatch(success(add));
//         } catch (e) {
//             dispatch(fail(e.toString()));
//             errorBlock(e,bossId);
//             dispatch(hideLoader())
//         }
//     };
//     function hideLoader() {
//         return {type: commonConstants.OTHER_LOADING_FALSE}
//     }

//     function success(createData) {
//         return {type: vimeoConstants.ADD_VIM_FOLLOWER, createData}
//     }

//     function fail(error) {
//         return {type: vimeoConstants.ADD_VIM_DOMAIN_FAIL, error}
//     }
// }
/**
 * @name addDomain
 * @params postId,formvalues,bossID
 * @descriptiton adds domain to a vimeo post
 **/
function addDomain(streamId, values, bossId) {
  return async (dispatch) => {
    try {
      /* const addDomainArr = values.domain.map(async (x) => {
                const payload = {id: streamId, domain: x.value};
                return await vimeoService.addDomain(streamId, x.value, payload)
            });
            const resAll = await Promise.all(addDomainArr);*/
      let allowedDom = values.domain.map((val) => val.value);
      let payload = { allowed_domains: allowedDom };
      const addDomain = await vimeoService.addDomain(streamId, payload);
      dispatch(success(addDomain));
    } catch (e) {
      dispatch(fail(e.toString()));
      errorBlock(e, bossId);
      dispatch(hideLoader());
    }
  };
  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
  function success(createData) {
    return { type: vimeoConstants.ADD_VIM_DOMAIN, createData };
  }

  function fail(error) {
    return { type: vimeoConstants.ADD_VIM_DOMAIN_FAIL, error };
  }
}
/**
 * @name makePayload
 * @params postId,formvalues,bossID
 * @descriptiton creates AWS Destination
 **/
function makePayload(streamId, values, bossId) {
  return async (dispatch) => {
    try {
      const vimKey = store.getState().vimeo.vimeo.update.stream_key;
      const priUrl = store.getState().vimeo.vimeo.update.rtmp_link;
      const secUrl = store.getState().vimeo.vimeo.update.rtmps_link;
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile.name,
        primary_url: priUrl,
        secondary_url: secUrl,
        stream_name: vimKey,
        username: '',
        password: '',
        id: streamId,
        streaming_provider: 'Vimeo',
        overrideResolution: values.resolutionBox
          ? values.overrideResolution.value
          : '',
        overrideFramerate: values.frameRateBox
          ? values.overrideFrame.value
          : '',
        overrideBitrate: values.bitRateBox ? values.overrideBitrate : '',
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
    }
  };

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name deleteVimeoEvent
 * @params none
 * @descriptiton gets vimeo events
 **/
function deleteVimeoEvent(destinationId, awsDest = {}, t) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const userId = cookies.get('vimeo_user_id');
      await vimeoService.delEvents(userId, destinationId);
      let awsDestination = awsDest.filter((value) => {
        return value.d_id === destinationId;
      });
      if (awsDestination.length) {
        await savedDestinationsService.delAWSDest(destinationId);
        dispatch(liveStreamActions.refreshStreamToolsDestination());
      }
      const lucDest = await savedDestinationsService.getLucDest();
      let lucDests = lucDest.filter((values) => {
        return values.streaming_destination.external_id === destinationId;
      });
      if (lucDests.length) {
        await savedDestinationsService.delLucDest(lucDests[0].id);
      }
      dispatch(hideSpinner());
      dispatch(success());
      notify('success', t('DESTINATIONDELETED'));
      return true;
    } catch (e) {
      dispatch(fail(e.toString()));
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
  function success() {
    return { type: vimeoConstants.DELETE_EVENTS_SUCCESS, destinationId };
  }
  function fail() {
    return { type: vimeoConstants.GET_VIMEO_EVENTS_FAIL };
  }
}
/**
 * @name getSingleVimeoEvent
 * @params userId, detId
 * @descriptiton gets Single vimeo events
 **/
function getSpecificVimeoEvent(detId) {
  return async () => {
    try {
      // dispatch(showLoader());
      const userId = cookies.get('vimeo_user_id');
      const event = await vimeoService.getSpecificVimeoEvents(userId, detId);
      const eventAdvanceProfile = await destinationService.AWSLucAPIDestination(
        detId,
      );

      // dispatch(hideSpinner());
      return { ...event, ...eventAdvanceProfile };
    } catch (e) {
      // dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  // function showLoader() {
  //   return { type: commonConstants.OTHER_LOADING };
  // }
  // function hideSpinner() {
  //   return { type: commonConstants.OTHER_LOADING_FALSE };
  // }
}
/**
 * @name getVimeoEvent
 * @params none
 * @descriptiton gets vimeo events
 **/
function updateSpecificVimeoEvent(event, destId, match) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const userId = cookies.get('vimeo_user_id');
      let createData = await vimeoService.setSpecificVimeoEvents(
        userId,
        destId,
        event,
      );
      await savedDestinationsAction.getAwsDest(destId, match, {});
      dispatch(success(createData));
      dispatch(hideSpinner());
      // history.push('/dashboard/vimeo/' + bossId);
      // return user;
    } catch (e) {
      // console.log(e)
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function success(createData) {
    return { type: vimeoConstants.UPDATE_VIM_STREAM, createData };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
