import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { history } from '../../history';
import {
  unitActions,
  lrtActions,
  destinationActions,
  paymentActions,
} from '../../actions';
import { DashboardView } from './DashboardView';
// import { Liveuloading } from '../Liveuloading';
import gosololoading from '../../assets/images/gosololoading.png';
import {
  getUnitsWithStatus,
  getUnitWithStatus,
  lrtSubscribed,
} from '../../selectors/unit_selectors';
import './Dashboard.css';
import { Modalpopup } from '../Modalpopup';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import { notify } from '../CommonNotification/CommonNotification';
import { Skeleton } from 'antd';
const cookies = new Cookies();

class Dashboard extends Component {
  static intervalT1 = 0;
  static unitStatusInterval = 0;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalDetails: null,
      openMenu: false,
      showLRTMessage: false,
      // intervalT1: 0,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };
  componentWillUnmount() {
    clearInterval(Dashboard.intervalT1);
    clearInterval(Dashboard.unitStatusInterval);
  }

  componentDidMount() {
    const { getUnitsInfo, getCustomerDetails } = this.props;
    if (cookies.get('username_update_path')) {
      history.push(cookies.get('username_update_path'));
    }
    getUnitsInfo();
    this.updateUnits();
    this.updateUnitStatus();
    getCustomerDetails();

    setTimeout(async () => {
      this.checkRestoreUnit();
    }, 10000);
  }

  streamToolDestinations = () => {
    const { getStreamToolDestination } = this.props;
    setTimeout(() => {
      getStreamToolDestination();
    }, 0);
  };
  updateUnitStatus = () => {
    const { updateUnitBatteryStatus } = this.props;
    clearInterval(Dashboard.unitStatusInterval);
    Dashboard.unitStatusInterval = setInterval(() => {
      updateUnitBatteryStatus();
    }, 120000);
  };

  updateUnits = () => {
    const { updateUnits, unit, getChannelServicesAndDestination } = this.props;
    clearInterval(Dashboard.intervalT1);
    Dashboard.intervalT1 = setInterval(() => {
      updateUnits();
      if (unit?.status === 'offline') {
        getChannelServicesAndDestination(unit.BOSSID);
      }
    }, 10000);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.units.length) {
      const { unit } = this.props;
      return (
        JSON.stringify(nextProps.units) !== JSON.stringify(this.props.units) ||
        JSON.stringify(nextState.modalDetails) !==
          JSON.stringify(this.state.modalDetails) ||
        JSON.stringify(nextState.openMenu) !==
          JSON.stringify(this.state.openMenu) ||
        JSON.stringify(nextProps.lrtSubscribed) !==
          JSON.stringify(lrtSubscribed) ||
        nextState.showLRTMessage !== this.state.showLRTMessage ||
        nextProps.location.pathname !== this.props.location.pathname ||
        nextProps.streamToolsUnitsList?.[unit?.BOSSID]?.isStreamToolActive !==
          this.props.streamToolsUnitsList?.[unit?.BOSSID]?.isStreamToolActive
        // JSON.stringify(nextProps.streamToolDestinations) !== JSON.stringify(this.props.streamToolDestinations)
      );
    } else {
      return true;
    }
  }

  checkRestoreUnit = () => {
    const { units, unitsettings, lrtSubscribed, unitChannelService } =
      this.props;
    if (
      !_.isEmpty(lrtSubscribed) &&
      !unitChannelService?.services?.includes('lrt')
    ) {
      this.setState({
        showLRTMessage: true,
      });
    } else {
      this.setState({
        showLRTMessage: false,
      });
    }
    let awsunits = [];
    let lucunits = [];
    if (!!unitsettings && unitsettings.length) {
      lucunits = units.filter(function (x) {
        // eslint-disable-next-line array-callback-return
        return unitsettings.some(function (item) {
          if (
            x.id === item.unit_id &&
            x.sw_version !== item.sw_version &&
            x.status !== 'offline' &&
            !!x.reg_code &&
            x.reg_code.substr(0, 2) !== 'sa'
          ) {
            awsunits.push(item);
            return x;
          }
        });
      });
      if (lucunits.length) {
        //restore confirmation modal pop up
        this.setState({
          showModal: true,
          modalDetails: {
            awsunits: awsunits,
            lucunits: lucunits,
            isOpen: true,
            classModal: '',
            modalHeader: i18n.t('RESETUNIT'),
            modalBody: i18n.t('UNITRESETMESSAGE'),
            toggle: this.cancel,
            primaryBtnText: i18n.t('YES'),
            secondaryBtnText: i18n.t('CANCEL'),
            primaryBtnClick: this.reset,
            secondaryBtnClick: this.cancelreset,
          },
        });
      }
    }
  };

  autoDeleteLUCDestination = async () => {
    const { units } = this.props;
    await this.props.autoDeleteDestination(units);
  };

  cancel = () => {
    this.setState({ showModal: false, modalDetails: { isOpen: false } });
  };
  reset = () => {
    this.setState(
      {
        showModal: false,
        modalDetails: {
          ...this.state.modalDetails,
          isOpen: false,
        },
      },
      async () => {
        const { restoreUnitSettings } = this.props;
        try {
          await restoreUnitSettings(
            this.state.modalDetails.awsunits,
            this.state.modalDetails.lucunits,
          );
        } catch (e) {}
      },
    );
  };

  cancelreset = () => {
    this.setState(
      {
        showModal: false,
        modalDetails: {
          ...this.state.modalDetails,
          isOpen: false,
        },
      },
      async () => {
        const { cancelUnitSettings } = this.props;
        try {
          await cancelUnitSettings(
            this.state.modalDetails.awsunits,
            this.state.modalDetails.lucunits,
          );
        } catch (e) {}
      },
    );
  };
  handleRebootClick = (data) => {
    this.setState({
      showModal: true,
      modalDetails: {
        bossId: data.destId,
        isOpen: true,
        classModal: '',
        modalHeader: data.modalHeader,
        modalBody: data.modalBody,
        toggle: this.cancel,
        primaryBtnText: data.primaryBtnText,
        secondaryBtnText: data.secondaryBtnText,
        primaryBtnClick: this.rebootLRTUnit,
        secondaryBtnClick: this.cancel,
      },
    });
  };
  rebootLRTUnit = async () => {
    const { rebootUnit, t } = this.props;
    this.cancel();
    notify('success', t('REBOOTINPROGRESS'));
    await rebootUnit(this.state.modalDetails.bossId);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const {
      unit,
      units_status,
      lrtSubscribed,
      unitChannelService,
      channelsLoading,
    } = this.props;

    if (prevProps.unit !== unit) {
      if (!!unit && !!unit.BOSSID) {
        cookies.set('currentSelectedUnit', unit.BOSSID, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      }

      if (!channelsLoading) {
        if (
          !_.isEmpty(lrtSubscribed) &&
          !unitChannelService?.services?.includes('lrt')
        ) {
          this.setState({ showLRTMessage: true });
        } else {
          this.setState({
            showLRTMessage: false,
          });
        }
      }
    }

    // if (prevProps?.unit?.BOSSID !== unit?.BOSSID || prevProps.isStreamToolActive !== isStreamToolActive) {
    //   if (isStreamToolActive) {
    //     this.streamToolDestinations();
    //   }
    // }

    if (prevProps.units_status !== units_status) {
      // this.autoDeleteLUCDestination();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      clearInterval(Dashboard.intervalT1);
      this.updateUnits();
    }
  }

  onSelectedUnit = (e, unit) => {
    const { selectedUnit, getChannelServicesAndDestination, getUnitDetails } =
      this.props;
    if ((unit && unit.BOSSID) !== (this.props.unit && this.props.unit.BOSSID)) {
      selectedUnit(unit);
      getChannelServicesAndDestination(unit.BOSSID);
      getUnitDetails(unit.BOSSID);
    }
  };

  onUnitSettings = (e, unit) => {
    //e.preventDefault();
    this.props.editSelectedUnit(unit);
  };

  twitterRedirectLoader = () => {
    const { t } = this.props;
    return (
      <div className='loaderWithText d-flex align-items-center justify-content-center'>
        <div className=' text-center'>
          <img src={gosololoading} alt='liveu' />
          <div className='loadingText pt-3'>
            <h3 className='font-weight-bold'>{t('PLEASEWAIT')}</h3>
            <p>{t('TWITTERLOADINGTEXT')}</p>
          </div>
        </div>
      </div>
    );
  };

  /*async componentDidUpdate() {
    const { getUnitsInfo } = this.props;		
    getUnitsInfo();
  }*/

  render() {
    const { t, loadingUnits, match, history, units, unit, editunit, location } =
      this.props;
    return (
      <React.Fragment>
        {loadingUnits ? (
          <div className='card-body'>
            <div className='sketch'>
              <div className='sketch-top'>
                <Skeleton active className='imageSkeleton' paragraph={false} />
                <Skeleton active className='imageSkeleton' paragraph={false} />
                <Skeleton active className='imageSkeleton' paragraph={false} />
                <Skeleton active className='imageSkeleton' paragraph={false} />
              </div>
              <div className='sketch-body'>
                <Skeleton
                  active
                  title={{
                    rows: 1,
                  }}
                  paragraph={{
                    rows: 2,
                  }}
                />
                <div className='row'>
                  <div className='col-md-4 unitBlk_cont'>
                    <Skeleton
                      active
                      className='imageSkeleton skeleton_card'
                      paragraph={false}
                    />
                  </div>
                  <div className='col-md-4 unitBlk_cont'>
                    <Skeleton
                      active
                      className='imageSkeleton skeleton_card'
                      paragraph={false}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4 unitBlk_cont'>
                    <Skeleton
                      active
                      className='imageSkeleton skeleton_card'
                      paragraph={false}
                    />
                  </div>
                  <div className='col-md-4 unitBlk_cont'>
                    <Skeleton
                      active
                      className='imageSkeleton skeleton_card'
                      paragraph={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : editunit.twitterGetAuthLoading ? (
          this.twitterRedirectLoader()
        ) : (
          <DashboardView
            t={t}
            match={match}
            history={history}
            units={units}
            unit={unit}
            onSelectedUnit={this.onSelectedUnit}
            onUnitSettings={this.onUnitSettings}
            handleClick={this.handleClick}
            openMenu={this.state.openMenu}
            lrtMessage={this.state.showLRTMessage}
            modalHandler={this.handleRebootClick}
            location={location}
          />
        )}
        {this.state.showModal && this.state.modalDetails ? (
          <Modalpopup
            t={t}
            match={match}
            history={history}
            modalDetails={this.state.modalDetails}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { loadingUnits, unitsettings, units_status } = state.unit;
  const { editunit } = state.editUnit;
  const { data, channelsLoading } = state.channelServiceDestination;
  const streamToolsUnitsList = state.streamTools;

  return {
    units: getUnitsWithStatus(state),
    unit: getUnitWithStatus(state),
    units_status,
    loadingUnits,
    unitsettings,
    lrtSubscribed: lrtSubscribed(state),
    unitChannelService: data,
    editunit,
    channelsLoading,
    streamToolsUnitsList,
  };
};

const mapDispatchToProps = {
  getUnitsInfo: unitActions.getUnitsInfo,
  updateUnitBatteryStatus: unitActions.updateUnitBatteryStatus,
  getUnitDetails: destinationActions.getUnitDetails,
  updateUnits: unitActions.updateUnits,
  selectedUnit: unitActions.selectedUnit,
  editSelectedUnit: unitActions.editSelectedUnit,
  getCustomerId: lrtActions.getCustomerId,
  getChannelServicesAndDestination:
    destinationActions.getChannelServicesAndDestination,
  cancelUnitSettings: unitActions.cancelUnitSettings,
  restoreUnitSettings: unitActions.restoreUnitSettings,
  autoDeleteDestination: destinationActions.autoDeleteDestination,
  getCustomerDetails: paymentActions.getCustomerDetails,
  rebootUnit: unitActions.rebootUnit,
  getStreamToolDestination: unitActions.getStreamToolDestination,
};

const ConnectedDashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Dashboard));
export { ConnectedDashboard as Dashboard };
