import { destinationConstant } from '../constants';
const {
  AWS_DESTINATIONS_REQ,
  AWS_DESTINATIONS_SUCCESS,
  AWS_DESTINATIONS_ERROR,
  GET_SELECTED_DES_SUCCESS,
  AWS_DESTINATION_REQ,
  AWS_DESTINATION_SUCCESS,
  AWS_DESTINATION_ERROR,
  LUC_DESTINATIONS_REQ,
  LUC_DESTINATIONS_SUCCESS,
  LUC_DESTINATIONS_ERROR,
  GET_SELECTED_DES_REQ,
  GET_SELECTED_DES_ERROR,
  STREAMING_PROVIDERS_REQ,
  STREAMING_PROVIDERS_SUCCESS,
  STREAMING_PROVIDERS_FAILURE,
  GET_UNIT_DETAILS_REQUEST,
  GET_UNIT_DETAILS_SUCCESS,
  GET_UNIT_DETAILS_ERROR,
  INITIAL_MANAGE_DESTINATION,
  OTHER_LOADING,
  OTHER_LOADING_FALSE,
  SET_FB_PAGE_ID_SUCCESS,
} = destinationConstant;
const initialState = {
  awsDestinations: [],
  lucDestinations: [],
  selectedDestination: undefined,
  selectedAwsDestination: {},
  providers: [],
  unitDetails: {},
  isLoading: true,
  destinationLoading: true,
  showLoader: false,
};

export function destination(state = initialState, payload) {
  switch (payload.type) {
    case AWS_DESTINATIONS_REQ:
      return {
        ...state,
        isLoading: true,
      };
    case AWS_DESTINATIONS_SUCCESS:
      return {
        ...state,
        awsDestinations: payload.awsDestinations,
        isLoading: true,
      };
    case AWS_DESTINATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case LUC_DESTINATIONS_REQ:
      return {
        ...state,
      };
    case LUC_DESTINATIONS_SUCCESS:
      return {
        ...state,
        lucDestinations: payload.lucDestinations,
        isLoading: false,
      };
    case LUC_DESTINATIONS_ERROR:
      return {
        isLoading: false,
      };
    case INITIAL_MANAGE_DESTINATION:
      return {
        ...state,
        awsDestinations: [],
        lucDestinations: [],
        isLoading: false,
      };
    case GET_SELECTED_DES_REQ:
      return {
        ...state,
        selectedDestination: undefined,
        destinationLoading: true,
      };
    case GET_SELECTED_DES_SUCCESS:
      return {
        ...state,
        selectedDestination: payload?.res,
        destinationLoading: false,
      };
    case SET_FB_PAGE_ID_SUCCESS:
      return {
        ...state,
        selectedDestination: { ...state?.selectedDestination, ...payload?.res },
      };
    case GET_SELECTED_DES_ERROR:
      return {
        ...state,
      };
    case AWS_DESTINATION_REQ:
      return {
        ...state,
        isLoading: true,
      };
    case AWS_DESTINATION_SUCCESS:
      return {
        ...state,
        selectedAwsDestination: payload.selectedAwsDestination,
        isLoading: false,
      };
    case AWS_DESTINATION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case STREAMING_PROVIDERS_REQ:
      return {
        ...state,
      };
    case STREAMING_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: payload.providers,
      };
    case STREAMING_PROVIDERS_FAILURE:
      return {
        ...state,
        providers: [],
      };
    case GET_UNIT_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case GET_UNIT_DETAILS_SUCCESS:
      return { ...state, unitDetails: payload.unitDetails, isLoading: false };
    case GET_UNIT_DETAILS_ERROR:
      return { ...state, isLoading: false };
    case OTHER_LOADING_FALSE:
      return { ...state, showLoader: false };
    case OTHER_LOADING:
      return { ...state, showLoader: true };
    default:
      return state;
  }
}
