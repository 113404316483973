import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { lrtActions } from '../../actions';
import './ConfigureSoloconnect.css';
import { OtherSpinner } from '../Otherspinner';
import i18n from 'i18next';
import { notify } from '../CommonNotification/CommonNotification';
import { history } from '../../history';

class ConfigureSoloconnect extends Component {
  state = {
    scnumber: '',
    errors: {
      scnumber: '',
    },
  };
  testPattern = (e) => {
    const { errors } = this.state;
    const { value } = e.target;
    if (value === '' || value === undefined) {
      errors.scnumber = i18n.t('SOLOCONNECTNOREQUIRED');
    } else {
      errors.scnumber = this.testPattern1(value.toLowerCase())
        ? ''
        : i18n.t('SOLOCONNECTNOINVALID');
    }
    this.setState({ scnumber: value });
  };

  testPattern1 = (value) => {
    return (
      /^(\bsc[\w]{0,}-[\w]{1,})+$/.test(value) ||
      /^(\b3s[\w]{0,}-[\w]{1,})+$/.test(value) ||
      /LUSOLO-\d{5}/i.test(value)
    );
  };

  getserialnumber = (e) => {
    e.preventDefault();
    const { salesforceValidateUnit } = this.props;
    const { scnumber } = this.state;
    if (scnumber) {
      salesforceValidateUnit(scnumber);
    }
  };

  goToLRT = (e) => {
    e.preventDefault();
    if (
      this.props.data.services.indexOf('lrt') !== -1 &&
      this.props.data.services.indexOf('cloud') !== -1
    ) {
      notify('info', i18n.t('LRTALREADYEXISTS'));
    } else {
      history.push(
        '/dashboard/units/payment/lrt/' + this.props.match.params.bossId,
      );
    }
  };

  render() {
    const { t, showLoader } = this.props;

    return (
      <React.Fragment>
        <div className='csConnect clearfix'>
          <div className='container-min'>
            <div className='sub-title'>
              <h4>{t('SOLOCONNECTHEADER')}</h4>
            </div>
            <div className='container'>
              {
                // eslint-disable-next-line jsx-a11y/no-redundant-roles
                <form role='form' name='soloconnect' className='tour-tooltip'>
                  <div className='confSoloConnect_blk'>
                    <div className='row'>
                      <div className='col-md-12 mb-3'>
                        {t('SOLOCONNECTUSAONLY')}{' '}
                        <a
                          href='https://get.gosolo.tv/soloconnect'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          {t('SOLOCONNECTMOREINFO')}
                        </a>
                      </div>
                    </div>
                    <div className='card card-default mb-3'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-2'>
                            <p>{t('SOLOCONNECTACTIVATEMODEM')}</p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-12 form-group'>
                            <div className='row'>
                              <div className='col-lg-4 col-md-6 col-12 pr-md-0 mb-2 mb-md-0'>
                                <input
                                  type='text'
                                  className={`form-control ${
                                    this.state.errors.scnumber.length > 0 ||
                                    this.state.scnumber.length === 0
                                      ? ''
                                      : 'success'
                                  }`}
                                  id='txtSoloconnectnumber'
                                  name='scnumber'
                                  value={this.state.scnumber}
                                  maxLength='20'
                                  onChange={this.testPattern}
                                />
                                <div className='error'>
                                  {this.state.errors.scnumber.length > 0 && (
                                    <span>{this.state.errors.scnumber}</span>
                                  )}
                                </div>
                              </div>
                              <div className='col-lg-4 col-md-6 col-12 cscFindBtn_blk'>
                                <span
                                  className='fa fa-info-circle connect-tooltip'
                                  data-toggle='tooltip'
                                  id='activeconnect'
                                >
                                  {/* <span className="tooltiptext"></span> */}
                                </span>
                                <UncontrolledTooltip
                                  placement='top'
                                  target='activeconnect'
                                >
                                  {t('SOLOCONNECTNUMBERINFO')}
                                </UncontrolledTooltip>

                                <button
                                  onClick={this.getserialnumber}
                                  disabled={
                                    this.state.errors.scnumber.length > 0 ||
                                    this.state.scnumber.length === 0
                                  }
                                  className='btn btn-default ml-3'
                                  id='basic-addon2'
                                >
                                  <span className='fa fa-search'></span>{' '}
                                  {t('FINDSERIALTOSTART')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Trans>{t('SOLOCONNECTINFO')}</Trans>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <button
                          className='atag link-text'
                          onClick={this.goToLRT}
                        >
                          {t('NEEDLRTNOTCONNECT')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
        {showLoader && (
          <React.Fragment>
            <OtherSpinner showSpinner={showLoader} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { showLoader } = state.common;
  const { data } = state.channelServiceDestination;

  return {
    showLoader,
    data,
  };
};

const mapDispatchToProps = {
  salesforceValidateUnit: lrtActions.salesforceValidateUnit,
};

const ConnectedConfigureSoloconnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ConfigureSoloconnect));
export { ConnectedConfigureSoloconnect as ConfigureSoloconnect };
