import {
  getBroadcastDetails,
  getLiveStreams,
  deleteEvent,
  _getChannels,
  _getDefaultBroadcast,
  _getLiveStream,
  _createDefaultBroadcast,
  _createBroadcastEvent,
  _createLiveStream,
  _createSingleLiveStream,
  _bindLiveStream,
  _editLiveStream,
  _deleteLiveStream,
  _getBroadcastEventById,
  _getLiveStreamById,
  _broadcastTransition,
  _ytAnalytics,
  _getAllBroadcasts,
  _getVideoAnalytics,
  _getVideoComments,
  _updateVideo,
} from '../../api/yt.api';

export const ytService = {
  handleAuthClick,
  listenYTSignInChange,
  getYTSignInStatus,
  getBroadcastDet,
  getLiveSt,
  deleteYTEvent,
  gapiSignIn,
  getChannels,
  getDefaultBroadcast,
  getLiveStream,
  createDefaultBroadcast,
  createBroadcastEvent,
  createLiveStream,
  createSingleLiveStream,
  bindLiveStream,
  editLiveStream,
  deleteLiveStream,
  getBroadcastEventById,
  getLiveStreamById,
  broadcastTransition,
  ytAnalytics,
  getAllBroadcasts,
  getVideoAnalytics,
  getVideoComments,
  updateVideo,
};
async function gapiSignIn() {
  try {
    // eslint-disable-next-line no-undef
    const f1 = await gapi?.auth2
      .getAuthInstance()
      .currentUser.get()
      .getAuthResponse(true);
    return f1;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function handleAuthClick() {
  try {
    // eslint-disable-next-line no-undef
    const f1 = await gapi?.auth2.getAuthInstance().signIn();
    return f1.Zi;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function listenYTSignInChange() {
  try {
    // eslint-disable-next-line no-undef
    const f1 = await gapi?.auth2.getAuthInstance()?.isSignedIn.listen();
    return f1;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getYTSignInStatus() {
  try {
    // eslint-disable-next-line no-undef
    const f2 = await gapi?.auth2.getAuthInstance()?.isSignedIn.get();
    return f2;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getBroadcastDet() {
  try {
    const getBroad = await getBroadcastDetails();
    return getBroad.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getLiveSt() {
  try {
    const getLiveStr = await getLiveStreams();
    return getLiveStr.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function deleteYTEvent(eventID) {
  try {
    if (!eventID) {
      return;
    }
    const delEvent = await deleteEvent(eventID);
    if (delEvent.status === 204) {
      return delEvent;
    }
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getChannels() {
  try {
    const channels = await _getChannels();
    return channels.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getDefaultBroadcast() {
  try {
    const broadcast = await _getDefaultBroadcast();
    return broadcast.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getLiveStream(livestream_id) {
  try {
    const livestream = await _getLiveStream(livestream_id);
    return livestream.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function createDefaultBroadcast(payload) {
  try {
    const broadcast = await _createDefaultBroadcast(payload);
    return broadcast.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function createBroadcastEvent(payload) {
  try {
    const broadcast = await _createBroadcastEvent(payload);
    return broadcast.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function createLiveStream(payload) {
  try {
    const livestream = await _createLiveStream(payload);
    return livestream.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function createSingleLiveStream(payload) {
  try {
    const livestream = await _createSingleLiveStream(payload);
    return livestream.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function bindLiveStream(id, streamId, payload) {
  try {
    const event = await _bindLiveStream(id, streamId, payload);
    return event.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function editLiveStream(payload) {
  try {
    const livestream = await _editLiveStream(payload);
    return livestream;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function deleteLiveStream(id) {
  try {
    const livestream = await _deleteLiveStream(id);
    return livestream;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getBroadcastEventById(broadcast_id) {
  try {
    const broadcast = await _getBroadcastEventById(broadcast_id);
    return broadcast.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getLiveStreamById(livestream_id) {
  try {
    const livestream = await _getLiveStreamById(livestream_id);
    return livestream.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function broadcastTransition(id, status) {
  try {
    const broadcast = await _broadcastTransition(id, status);
    return broadcast.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function ytAnalytics(id) {
  try {
    const analytics = await _ytAnalytics(id);
    return analytics.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getAllBroadcasts() {
  try {
    const broadcast = await _getAllBroadcasts();
    return broadcast.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getVideoAnalytics(ids) {
  try {
    const analytics = await _getVideoAnalytics(ids);
    return analytics.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getVideoComments(id) {
  try {
    const comments = await _getVideoComments(id);
    return comments.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function updateVideo(payload) {
  try {
    const video = await _updateVideo(payload);
    return video;
  } catch (e) {
    return Promise.reject(e);
  }
}
