import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const restreamBaseUrl = 'https://api.restream.io/v2';
export const getUserDetails = () =>
  axios.get(`${restreamBaseUrl}/user/profile`, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const getPlatforms = () =>
  axios.get(`${restreamBaseUrl}/platform/all`, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const getUserPlatforms = () =>
  axios.get(`${restreamBaseUrl}/user/channel/all`, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const getAllIngests = () =>
  axios.get(`${restreamBaseUrl}/server/all`, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const getIngests = () =>
  axios.get(`${restreamBaseUrl}/user/ingest`, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const getStreamKey = () =>
  axios.get(`${restreamBaseUrl}/user/streamKey`, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const enableChannels = (payload) =>
  axios.post(`${restreamBaseUrl}/user/channel/enable`, payload, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
export const disableChannels = (payload) =>
  axios.post(`${restreamBaseUrl}/user/channel/disable`, payload, {
    headers: {
      Authorization: `Bearer ${cookies.get('restream_access_token')}`,
    },
  });
