import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import { getPlans } from '../helpers/getPlans';

const plansSelector = (state) => state.payment.plans;
const customerSelector = (state) => state.payment.customerDetails;
const couponSelector = (state) => state.payment.coupon;
const taxSelector = (state) => state.payment.tax;
const invoiceSelector = (state) => state.payment.customerInvoices;
const chargeSelector = (state) => state.payment.customerCharges;
const subscriptionsSelector = (state) => state.payment.subscriptions;
const unitSelector = (state) => state.unit.units;

export const getSelectedPlans = createSelector(plansSelector, (plans) => {
  return getPlans(plans);
});
// Get customer card details
export const getCustomerCardDetails = createSelector(
  customerSelector,
  (customer) => {
    if (customer.sources) {
      const { data } = customer.sources;
      return data[0];
    }
  },
);
// Get customer address details
export const getCustomerAddress = createSelector(
  customerSelector,
  (customer) => {
    if (!_.isEmpty(customer)) {
      return {
        //state: customer.metadata.state ? customer.metadata.state : (customer.address.state ? customer.address.state : ''),
        state:
          customer.address && customer.address.state
            ? customer.address.state
            : customer.metadata && customer.metadata.state
            ? customer.metadata.state
            : '',
        city:
          customer.address && customer.address.city
            ? customer.address.city
            : customer.metadata && customer.metadata.city
            ? customer.metadata.city
            : '',
        zipcode:
          customer.address && customer.address.postal_code
            ? customer.address.postal_code
            : customer.metadata && customer.metadata.zipcode
            ? customer.metadata.zipcode
            : '',
        bname:
          customer.metadata && customer.metadata.business_name
            ? customer.metadata.business_name
            : '',
        address1:
          customer.address && customer.address.line1
            ? customer.address.line1
            : customer.metadata && customer.metadata.add_line1
            ? customer.metadata.add_line1
            : '',
        address2:
          customer.address && customer.address.line2
            ? customer.address.line2
            : customer.metadata && customer.metadata.add_line2
            ? customer.metadata.add_line2
            : '',
        country:
          customer.address && customer.address.country
            ? customer.address.country
            : customer.metadata && customer.metadata.country
            ? customer.metadata.country
            : '',
        countryCode:
          customer.address && customer.address.country
            ? customer.address.country
            : customer.metadata && customer.metadata.country
            ? customer.metadata.country
            : '',
      };
    } else {
      return {};
    }
  },
);
// Get customer card details
export const getCouponDiscount = createSelector(couponSelector, (coupon) => {
  return coupon;
});
// Get tax rate
export const getTaxRate = createSelector(taxSelector, (tax) => {
  return Number(tax.combined_rate * 100).toFixed(4);
});

//filter date format
function dateFilter(date) {
  var t = moment.unix(date).format('LL');
  return t;
}

//filter credit card type
function setFundType(fundtype) {
  switch (fundtype) {
    case 'credit':
      return 'Credit Card';
    case 'debit':
      return 'Debit Card';
    case 'prepaid':
      return 'Prepaid';
    case 'unknown':
      return 'Unknown';
    default:
      return 'Unknown';
  }
}

//get payment details
export const getPaymentDetails = createSelector(
  invoiceSelector,
  chargeSelector,
  (invoices, charges) => {
    let tempCharges = charges;
    let invoicelist = [];
    // eslint-disable-next-line array-callback-return
    invoices.map((invoice) => {
      // eslint-disable-next-line array-callback-return
      charges.map((charge) => {
        if (invoice.charge === charge.id) {
          invoice.chargedetails = charge;
          _.remove(tempCharges, (temp) => {
            return temp.id === charge.id;
          });
        }
      });
    });
    let tempArray = [...invoices, ...tempCharges];
    // eslint-disable-next-line array-callback-return
    tempArray.map((temp, index) => {
      let invoice = {};
      invoice.key = index.toString();
      if (temp.object === 'invoice') {
        invoice.id = temp.id;
        invoice.date = temp?.status_transitions?.paid_at
          ? dateFilter(temp.status_transitions.paid_at)
          : temp?.status_transitions?.finalized_at
          ? dateFilter(temp.status_transitions.finalized_at)
          : dateFilter(temp.created);
        invoice.subtotal = temp.total / 100;
        invoice.fundtype = temp.chargedetails
          ? temp.chargedetails.payment_method_details &&
            setFundType(temp.chargedetails.payment_method_details.card.funding)
          : '-';
        invoice.total = temp.tax
          ? temp.total / 100 - temp.tax / 100
          : temp.total / 100;
        invoice.tax =
          temp.tax && temp.tax !== 0 ? temp.tax / 100 : i18n.t('NA');
        invoice.number = temp.number;
        invoice.brand = temp.chargedetails
          ? temp.chargedetails?.payment_method_details?.card?.brand +
            ' ' +
            i18n.t('ENDINGIN') +
            ' ' +
            temp.chargedetails?.payment_method_details?.card?.last4
          : '-';
        invoice.imgtype = temp.chargedetails
          ? temp.chargedetails?.payment_method_details?.card?.brand
          : '-';
        if (
          temp.lines.data[temp.lines.total_count - 1].plan.metadata
            .service_type === 'soloconnect'
        ) {
          if (
            temp.lines.data[temp.lines.total_count - 1].plan.metadata.modem ===
            '2'
          ) {
            invoice.servicetype = i18n.t('SOLOCONNECT') + '' + -2;
          }
          if (
            temp.lines.data[temp.lines.total_count - 1].plan.metadata.modem ===
            '3'
          ) {
            invoice.servicetype = i18n.t('SOLOCONNECT') + '' + -3;
          }
        }
        if (
          temp.lines.data[temp.lines.total_count - 1].plan.metadata
            .service_type === 'lrt'
        ) {
          invoice.servicetype = i18n.t('LRT');
        }
        invoice.exists = temp.charge || temp.auto_advance ? true : false;
      }
      if (temp.object === 'charge') {
        invoice.date = dateFilter(temp.created);
        invoice.subtotal = temp.amount / 100;
        invoice.fundtype = temp.payment_method_details
          ? setFundType(temp.payment_method_details.card.funding)
          : '-';
        invoice.total = temp.metadata.tax_amount
          ? temp.amount / 100 - temp.metadata.tax_amount
          : temp.amount / 100;
        invoice.tax =
          temp.metadata.tax_amount && temp.metadata.tax_amount !== 0
            ? temp.metadata.tax_amount
            : i18n.t('NA');
        invoice.number = 'Charge';
        invoice.brand =
          temp.payment_method_details.card.brand +
          ' ' +
          i18n.t('ENDINGIN') +
          ' ' +
          temp.payment_method_details.card.last4;
        invoice.imgtype = temp.payment_method_details.card.brand;
        if (!temp.paid) {
          invoice.status = i18n.t('FAILED');
        }
      }
      if (temp.paid) {
        invoice.status = i18n.t('PAID');
      } else {
        invoice.status = invoice.closed ? i18n.t('CLOSED') : i18n.t('UNPAID');
      }
      invoicelist.push(invoice);
    });
    return invoicelist;
  },
);

export const subscriptionsList = createSelector(
  subscriptionsSelector,
  unitSelector,
  (subscriptions, units) => {
    let subList = {
      lrt: [],
      soloconnect: [],
      streamtool: [],
      chargemonth: 0,
      chargeyear: 0,
    };
    if (subscriptions.length && units.length) {
      // eslint-disable-next-line array-callback-return
      subscriptions.map((sub) => {
        // eslint-disable-next-line array-callback-return
        units.map((unit) => {
          if (unit.BOSSID === sub.metadata.unit_id) {
            sub.unitname = unit.SN;
          }
        });
        if (sub.plan.metadata.service_type === 'lrt') {
          return subList.lrt.push(sub);
        }
        if (sub.plan.metadata.service_type === 'soloconnect') {
          return subList.soloconnect.push(sub);
        }
        if (sub.plan.metadata.service_type === 'streamtool') {
          return subList.streamtool.push(sub);
        }
      });
      // eslint-disable-next-line array-callback-return
      subscriptions.map((sub) => {
        if (!sub.cancel_at_period_end) {
          if (sub.items.data[0].plan.interval === 'month') {
            if (sub.discount !== null) {
              if (sub.discount.duration !== 'once') {
                if (sub.discount.coupon.amount_off !== null) {
                  return (subList.chargemonth =
                    subList.chargemonth +
                    (sub.plan.amount - sub.discount.coupon.amount_off));
                } else {
                  return (subList.chargemonth =
                    subList.chargemonth +
                    (sub.plan.amount -
                      (sub.plan.amount * sub.discount.coupon.percent_off) /
                        100));
                }
              } else {
                return (subList.chargemonth =
                  subList.chargemonth + sub.plan.amount);
              }
            } else {
              return (subList.chargemonth =
                subList.chargemonth + sub.plan.amount);
            }
          }
          if (sub.items.data[0].plan.interval === 'year') {
            if (sub.discount !== null) {
              if (sub.discount.duration !== 'once') {
                if (sub.discount.coupon.amount_off !== null) {
                  return (subList.chargeyear =
                    subList.chargeyear +
                    (sub.plan.amount - sub.discount.coupon.amount_off));
                } else {
                  return (subList.chargeyear =
                    subList.chargeyear +
                    (sub.plan.amount -
                      (sub.plan.amount * sub.discount.coupon.percent_off) /
                        100));
                }
              } else {
                return (subList.chargeyear =
                  subList.chargeyear + sub.plan.amount);
              }
            } else {
              return (subList.chargeyear =
                subList.chargeyear + sub.plan.amount);
            }
          }
        }
      });
      return subList;
    }
  },
);
