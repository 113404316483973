import axios from 'axios';
const { REACT_APP_wowzaAPIToken } = process.env;
const headers = { 'wsc-api-key': REACT_APP_wowzaAPIToken };
export const validate = (token, payload) =>
  axios.post(
    `https://api.cloud.wowza.com/api/v1/token/transcoders/${token}/validate`,
    payload,
    { headers },
  );
export const getTranscoder = (access_token) =>
  axios.get(
    `https://api.cloud.wowza.com/api/v1/token/transcoders/${access_token}`,
    { headers },
  );
export const wowzaStart = (token, payload) =>
  axios.post(
    `https://api.cloud.wowza.com/api/v1/token/transcoders/${token}/start`,
    payload,
    { headers },
  );
export const wowzaStop = (token, payload) =>
  axios.post(
    `https://api.cloud.wowza.com/api/v1/token/transcoders/${token}/stop`,
    payload,
    { headers },
  );
export const wowzaPostStatus = (token) =>
  axios.get(
    `https://api.cloud.wowza.com/api/v1/token/transcoders/${token}/state`,
    { headers },
  );
