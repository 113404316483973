import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RegisterForm } from './RegisterForm';
import { userActions } from '../../actions';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      preference: false,
      registeredSuccess: false,
    };
  }

  onSubmitHandler = async (values) => {
    if (values.captchaValue !== null) {
      this.setState({ submitted: true });
    }
    var userpayload = {
      inventory: {
        parent_inventory_reg_code: 'fsdklvjxcoihwernmasdfnkqwo',
      },
      user: {
        password: values.password,
        email: values.email.toLowerCase(),
        first_name:
          values.fname.substring(0, 1).toUpperCase() +
          values.fname.substring(1),
        last_name:
          values.lname.substring(0, 1).toUpperCase() +
          values.lname.substring(1),
      },
      'application ID': 'lusDcCdW5MGE1ZMAnPjibsTMNx5OgdIC',
    };

    const result = await this.props.register(
      userpayload,
      values.email.toLowerCase(),
    );
    if (result) {
      this.setState({ registeredSuccess: true });
      this.setPreference(values.email.toLowerCase());
    } else {
      this.setState({ submitted: false });
    }
  };
  changeCommunicationPreference = () => {
    this.setState({
      preference: !this.state.preference,
    });
  };
  setPreference(email) {
    const { setCommunicationPreference } = this.props;
    const isLoggedIn = false;
    setCommunicationPreference({
      isLoggedIn,
      preference: this.state.preference,
      email,
    });
  }

  render() {
    const { t, history, isRegisterSuccess } = this.props;
    const { submitted, preference, registeredSuccess } = this.state;

    return (
      <RegisterForm
        t={t}
        history={history}
        submitted={submitted}
        onSubmitHandler={this.onSubmitHandler}
        isRegisterSuccess={isRegisterSuccess}
        changeCommunicationPreference={this.changeCommunicationPreference}
        preference={preference}
        registeredSuccess={registeredSuccess}
      />
    );
  }
}

function mapStateToProps(state) {
  const { isRegisterSuccess } = state.registration;
  return {
    isRegisterSuccess,
  };
}

const mapDispatchToProps = {
  register: userActions.register,
  setCommunicationPreference: userActions.setCommunicationPreference,
};

const ConnectedRegisterPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Register));
export { ConnectedRegisterPage as Register };
