import { boxCastConstants } from '../constants';

const initialState = {
  boxCastLoading: true,
  boxCast: {
    auth: {},
    ingests: [],
    user: {},
    create: {},
    stopStream: {},
    error: false,
  },
  authIsLoading: true,
};
const {
  GET_TOKEN,
  GET_TOKEN_FAIL,
  GET_TOKEN_REQ,
  GET_RTMP,
  GET_RTMP_FAIL,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_REQ,
  CREATE_STREAM,
  CREATE_STREAM_FAIL,
  STOP_STREAM_FAIL,
  STOP_STREAM,
} = boxCastConstants;

export function boxcast(state = initialState, payload) {
  switch (payload.type) {
    case GET_TOKEN_REQ:
      return { ...state, authIsLoading: true };
    case GET_TOKEN:
      return {
        ...state,
        boxCast: { ...state.boxCast, auth: payload.authData },
      };
    case GET_TOKEN_FAIL:
      return { ...state, authIsLoading: false };
    case GET_RTMP_FAIL:
      return {
        ...state,
        boxCast: { ...state.boxCast, ingests: [], error: true },
        boxCastLoading: false,
      };
    case GET_RTMP:
      return {
        ...state,
        boxCast: { ...state.boxCast, ingests: [...payload.ingData] },
        boxCastLoading: false,
      };
    case GET_USER_REQ:
      return { ...state, boxCastLoading: true };
    case GET_USER:
      return {
        ...state,
        boxCast: { ...state.boxCast, user: payload.userData },
      };
    case GET_USER_FAIL:
      return {
        ...state,
        boxCast: { ...state.boxCast, user: payload.error },
        boxCastLoading: false,
      };
    case CREATE_STREAM_FAIL:
      return { ...state, boxCast: { ...state.boxCast, create: payload.error } };
    case CREATE_STREAM:
      return {
        ...state,
        boxCast: { ...state.boxCast, create: payload.strData },
      };
    case STOP_STREAM:
      return {
        ...state,
        boxCast: { ...state.boxCast, stopStream: payload.Data },
      };
    case STOP_STREAM_FAIL:
      return {
        ...state,
        boxCast: { ...state.boxCast, stopStream: payload.error },
      };

    default:
      return state;
  }
}
