import { easyliveConstants } from '../constants';
const initialState = {
  authIsLoading: true,
  elIsLoading: true,
  el: { auth: {}, profile: {}, existing: [], create: {} },
  streamExist: false,
  elLimit: false,
  existingLoading: true,
};
const {
  EL_GET_TOKEN,
  EL_GET_TOKEN_FAIL,
  EL_GET_TOKEN_REQ,
  EL_GET_PROFILE,
  EL_GET_PROFILE_FAIL,
  EL_GET_PROFILE_REQ,
  EL_GET_EXISTING_STREAMS,
  EL_GET_EXISTING_STREAMS_FAIL,
  EL_GET_EXISTING_STREAMS_REQ,
  EL_CREATE_STREAM,
  EL_CREATE_STREAM_FAIL,
  EL_STREAM_EXISTS_FALSE,
  EL_STREAM_EXISTS_TRUE,
  EL_STREAM_LIMIT,

} = easyliveConstants;

export function easyLive(state = initialState, payload) {
  switch (payload.type) {
    case EL_GET_TOKEN_REQ:
      return { ...state, authIsLoading: true };
    case EL_GET_TOKEN_FAIL:
      return { ...state, authIsLoading: false };
    case EL_GET_TOKEN:
      return { ...state, el: { ...state.el, auth: payload.elAuthData } };
    case EL_GET_PROFILE_FAIL:
      return {
        ...state,
        el: { ...state.el, profile: payload.error },
        elIsLoading: false,
      };
    case EL_GET_PROFILE:
      return {
        ...state,
        el: { ...state.el, profile: payload.elProfData },
        elIsLoading: false,
      };
    case EL_GET_PROFILE_REQ:
      return { ...state, elIsLoading: true };
    case EL_GET_EXISTING_STREAMS_REQ:
      return { ...state, existingLoading: true };
    case EL_GET_EXISTING_STREAMS:
      return {
        ...state,
        el: { ...state.el, existing: [...payload.elExistData] },
        existingLoading: false,
      };
    case EL_GET_EXISTING_STREAMS_FAIL:
      return {
        ...state,
        el: { ...state.el, existing: [payload.error] },
        existingLoading: false,
      };
    case EL_CREATE_STREAM_FAIL:
      return { ...state, el: { ...state.el, create: payload.error } };
    case EL_CREATE_STREAM:
      return { ...state, el: { ...state.el, create: payload.createData } };
    case EL_STREAM_EXISTS_FALSE:
      return { ...state, streamExist: false };
    case EL_STREAM_EXISTS_TRUE:
      return { ...state, streamExist: true };
    case EL_STREAM_LIMIT:
      return { ...state, elLimit: true };

    default:
      return state;
  }
}