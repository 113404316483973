import { saveddestinationsConstants, commonConstants } from '../../constants';
import { liveStreamServices, savedDestinationsService } from '../../services';
import { editUnitAction } from './editunit.actions';
import { notify } from '../../components/CommonNotification/CommonNotification';
import { errorBlock } from '../../api/errorBlock';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { history } from '../../history';
import { destinationActions } from '../destination.actions';
import utf8 from 'utf8';
import i18n from 'i18next';
import { _getChannelServicesAndDestination } from '../../api/luc.api';
import Cookies from 'universal-cookie';
import { store } from '../../store';
import { destinationAllowed } from '../../utils';
import { liveStreamActions } from '../../actions';
import { getLocalStorageItem } from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();
let countMatched = 4;

export const savedDestinationsAction = {
  getAwsDest,
  getStreamToolAwsDest,
  delAWSDest,
  createAwsDest,
};
let getAwsDes;
let propsMatch;

function getStreamToolAwsDest(destID, match, destData = {}) {
  propsMatch = match;
  return async (dispatch) => {
    dispatch(request());
    try {
      const {
        isStreamToolActive,
        isStreamtoolsStreaming,
        selectedDestinations: streamtoolDest,
      } = store.getState()?.streamTools[match.params.bossId];

      const {
        // destination: { selectedDestination: lucDest },
        unit: { unit },
      } = store.getState();

      if (
        isStreamToolActive &&
        (isStreamtoolsStreaming || unit.status === 'streaming')
      ) {
        history.push(`/dashboard/units/${match.params.bossId}`);
        dispatch(hideSpinner());
        return;
      }

      getAwsDes = await savedDestinationsService.getAwsDest(destID);

      if (getAwsDes?.length === 0) {
        dispatch(createAwsDest(getAwsDes, match, destData));
      } else {
        const dest = getAwsDes[0];
        let bossId = dest?.boss_id;

        if (
          dest?.boss_id &&
          !dest?.boss_id.split(',').includes(match.params.bossId)
        ) {
          bossId = `${bossId},${match.params.bossId}`;
        } else {
          // eslint-disable-next-line
          bossId = match.params.bossId;
        }

        let existingDests = streamtoolDest.map((destination) => destination.id);

        if (!existingDests.includes(destID)) {
          if (streamtoolDest.length < destinationAllowed) {
            await savedDestinationsService.setStreamtoolDest({
              bossId,
              destId: dest?.d_id,
            });
          } else if (streamtoolDest.length === destinationAllowed) {
            await dispatch(
              destinationActions.removeStreamToolDestination(
                existingDests[streamtoolDest.length - 1],
              ),
            );

            await savedDestinationsService.setStreamtoolDest({
              bossId,
              destId: dest?.d_id,
            });
          }
        }

        notify('success', i18n.t('SOLOUNITCONFIGURED'));
        dispatch(hideSpinner());
        history.push('/dashboard/units/' + propsMatch.params.bossId);
      }
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function request() {
      return { type: commonConstants.OTHER_LOADING };
    }

    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}

/**
 * @name getAwsDes
 * @params destID,match
 * @descriptiton get a aws destination
 **/
function getAwsDest(destID, match, destData = {}) {
  propsMatch = match;
  return async (dispatch) => {
    dispatch(request());
    try {
      const {
        isStreamToolActive,
        isStreamtoolsStreaming,
        selectedDestinations: streamtoolDest,
      } = store.getState()?.streamTools[match.params.bossId];

      const {
        // destination: { selectedDestination: lucDest },
        unit: { unit },
      } = store.getState();

      if (
        isStreamToolActive &&
        (isStreamtoolsStreaming || unit.status === 'streaming')
      ) {
        history.push(`/dashboard/units/${match.params.bossId}`);
        dispatch(hideSpinner());
        return;
      }

      getAwsDes = await savedDestinationsService.getAwsDest(destID);
      dispatch(success(getAwsDes));

      if (getAwsDes?.length === 0) {
        dispatch(createAwsDest(getAwsDes, match, destData));
      } else {
        const dest = getAwsDes[0];
        let bossId = dest?.boss_id;

        if (
          dest?.boss_id &&
          !dest?.boss_id.split(',').includes(match.params.bossId)
        ) {
          bossId = `${bossId},${match.params.bossId}`;
        } else {
          // eslint-disable-next-line
          bossId = match.params.bossId;
        }

        let existingDests = streamtoolDest.map((destination) => destination.id);

        if (!existingDests.includes(destID)) {
          if (streamtoolDest.length < destinationAllowed) {
            await savedDestinationsService.setStreamtoolDest({
              bossId,
              destId: dest?.d_id,
            });
          } else if (streamtoolDest.length === destinationAllowed) {
            await dispatch(
              destinationActions.removeStreamToolDestination(
                existingDests[streamtoolDest.length - 1],
              ),
            );
            await savedDestinationsService.setStreamtoolDest({
              bossId,
              destId: dest?.d_id,
            });
          }
        }

        dispatch(getLucDest());
      }
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function request() {
      return { type: commonConstants.OTHER_LOADING };
    }
    function success(getAwsDest) {
      return { type: saveddestinationsConstants.GET_AWS_DEST, getAwsDest };
    }
    function failure(error) {
      return {
        type: saveddestinationsConstants.GET_AWS_DEST_REQ_FAILURE,
        error,
      };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name createAwsDest
 * @params formdata,match
 * @descriptiton create aws destination
 **/
function createAwsDest(getAwsDestData, match, destData = {}) {
  const {
    isStreamToolActive,
    isStreamtoolsStreaming,
    selectedDestinations: streamtoolDest,
  } = store.getState().streamTools?.[match.params.bossId];

  const {
    destination: { selectedDestination: lucDest },
    unit: { unit },
  } = store.getState();

  const makePayload = (data) => {
    let tempObj = {
      destination_id: data.d_id || match.params.destId,
      provider: data.streaming_provider,
      title: data.title,
      type: 'stream',
      username: data.username || '',
      password: data.password || '',
      pri_url: data.primary_url,
      sec_url: data.secondary_url,
      profileSelected: data.streaming_profile,
      streamname: data.stream_name,
      max_overrideResolution: data.max_res_override || '',
      min_overrideResolution: data.min_res_override || '',
      max_overrideFramerate: data.max_fps_override || '',
      min_overrideFramerate: data.min_fps_override || '',
      max_overrideBitrate: data.max_bitrate_override || '',
      min_overrideBitrate: data.min_bitrate_override || '',
      unit_id: match.params.bossId,
      // is_stream_tool: isStreamToolActive,
    };
    let obj1 = pickBy(tempObj, identity);
    return obj1;
  };

  const makeStreamtoolPayload = (data) => {
    let tempObj = {
      destination_id: data.d_id || match.params.destId,
      provider: data.streaming_provider,
      title: data.title,
      type: 'stream',
      username: data.username || '',
      password: data.password || '',
      pri_url: data.primary_url,
      sec_url: data.secondary_url,
      profileSelected: data.streaming_profile,
      streamname: data.stream_name,
      // max_overrideResolution: data.max_res_override || '',
      // min_overrideResolution: data.min_res_override || '',
      // max_overrideFramerate: data.max_fps_override || '',
      // min_overrideFramerate: data.min_fps_override || '',
      // max_overrideBitrate: data.max_bitrate_override || '',
      // min_overrideBitrate: data.min_bitrate_override || '',
      unit_id: match.params.bossId,
      // is_stream_tool: isStreamToolActive,
    };
    let obj1 = pickBy(tempObj, identity);
    return obj1;
  };
  return async (dispatch) => {
    dispatch(request());
    try {
      if (
        isStreamToolActive &&
        (isStreamtoolsStreaming || unit.status === 'streaming')
      ) {
        history.push(`/dashboard/units/${match.params.bossId}`);
        dispatch(hideSpinner());
        return;
      }

      const createAwsDest = await savedDestinationsService.createAwsDest(
        isStreamToolActive
          ? makeStreamtoolPayload(getAwsDestData[0] || destData)
          : makePayload(getAwsDestData[0] || destData),
      );

      if (isStreamToolActive) {
        const { storeStreamToolsData } = liveStreamServices;
        await storeStreamToolsData({
          fps: !!(
            getAwsDestData[0]?.overrideFramerate || destData?.overrideFramerate
          )
            ? getAwsDestData[0]?.overrideFramerate ||
              destData?.overrideFramerate
            : null,
          video_bitrate: !!(
            getAwsDestData[0]?.overrideBitrate || destData?.overrideBitrate
          )
            ? getAwsDestData[0]?.overrideBitrate || destData?.overrideBitrate
            : null,
          audio_bitrate:
            getAwsDestData[0]?.audio_bitrate_override ||
            destData?.audio_bitrate_override
              ? getAwsDestData[0]?.audio_bitrate_override ||
                destData?.audio_bitrate_override
              : null,
          bossId: match.params.bossId,
        });
      }

      getAwsDes = getAwsDestData[0] || destData || getAwsDestData;

      let destId = streamtoolDest.find(
        (e) =>
          e?.streaming_destination?.streaming_destination_outputs[0]
            ?.stream_name ===
          lucDest?.streaming_destination?.streaming_destination_outputs[0]
            ?.stream_name,
      )?.id;

      if (streamtoolDest.length < destinationAllowed && isStreamToolActive) {
        destId = null;
      }

      if (streamtoolDest.length === destinationAllowed && isStreamToolActive) {
        destId = streamtoolDest[streamtoolDest.length - 1]?.id;
      }

      if (destId) {
        await dispatch(destinationActions.removeStreamToolDestination(destId));
      }

      if (isStreamToolActive && createAwsDest) {
        history.push('/dashboard/units/' + propsMatch.params.bossId);
      } else {
        dispatch(success(createAwsDest));
        dispatch(getLucDest());
      }
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function success(createAwsDestData) {
      return {
        type: saveddestinationsConstants.CREATE_AWS_DEST,
        createAwsDestData,
      };
    }

    function request() {
      return { type: commonConstants.OTHER_LOADING };
    }

    function failure(error) {
      return {
        type: saveddestinationsConstants.CREATE_AWS_DEST_FAILURE,
        error,
      };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name getLucDest
 * @params none
 * @descriptiton get luc destination
 **/
function getLucDest() {
  return async (dispatch) => {
    try {
      // const lucDest = await savedDestinationsService.getLucDest();
      // dispatch(success(lucDest));
      // let lucDests = lucDest.filter((values) => {
      //   return values.streaming_destination.external_id === destId;
      // });
      // if (!lucDests.length) {
      dispatch(createLucDest());
      // } else {
      //   dispatch(getDestinationDetail(lucDests[0].id));
      // }
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function failure(error) {
      return { type: saveddestinationsConstants.GET_LUC_DEST_FAILURE, error };
    }
    // function success(lucDestData) {
    //   return { type: saveddestinationsConstants.GET_LUC_DEST, lucDestData };
    // }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name setLucDest
 * @params destId
 * @descriptiton set LUC destination on unit
 **/
function setLucDest(destID) {
  var payload1 = {
    unit: {
      selected_destination: destID,
    },
  };
  return async (dispatch) => {
    try {
      const { REACT_APP_ApplicationId } = process.env;
      const setLucDestPayload = await savedDestinationsService.setLucDest(
        payload1,
        propsMatch.params.bossId,
      );
      dispatch(success(setLucDestPayload));
      dispatch(otherSuccess());
      notify('success', i18n.t('SOLOUNITCONFIGURED'));
      const headers = {
        Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
        'Application-Id': REACT_APP_ApplicationId,
      };
      getMatchedDestId(headers, propsMatch.params.bossId);
      async function getMatchedDestId(headers, Id) {
        const res = await _getChannelServicesAndDestination(headers, Id);
        if (countMatched > 0) {
          if (
            payload1?.unit?.selected_destination ===
            res?.data?.data?.unit?.selected_destination
          ) {
            history.push('/dashboard/units/' + propsMatch.params.bossId);
          } else {
            setTimeout(function () {
              countMatched--;
              if (countMatched === 2) {
                dispatch(setLucDest(destID));
              } else {
                getMatchedDestId(headers, Id);
              }
            }, 1000);
          }
        } else {
          history.push('/dashboard/units/' + propsMatch.params.bossId);
        }
      }
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }
    function otherSuccess() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }

    function success(setLucDestData) {
      return { type: saveddestinationsConstants.SET_LUC_DEST, setLucDestData };
    }
    function failure(error) {
      return { type: saveddestinationsConstants.SET_LUC_DEST_FAILURE, error };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name getDestinationDetail
 * @params postId
 * @descriptiton get destination detail of particular Destination
 **/
function getDestinationDetail(postId) {
  return async (dispatch) => {
    try {
      await dispatch(destinationActions.getDestinationDetails(postId));
      dispatch(setLucDest(postId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name createLucDest
 * @params none
 * @descriptiton create a LUC destination
 **/
function createLucDest() {
  let title;
  const makePayload2 = (data) => {
    title =
      data.streaming_provider.toLowerCase() === 'facebook' ||
      data.streaming_provider.toLowerCase() === 'youtube'
        ? utfEncode(data.title)
        : data.title;
    let obj1 = {
      destination: {
        name: title,
        type: 'stream',
        streaming_destination: {
          external_id: data.d_id,
          streaming_provider: data.streaming_provider,
          streaming_destination_outputs: [
            {
              streaming_profile: data.streaming_profile,
              stream_name: data.stream_name,
              min_res_override: data.min_res_override,
              max_res_override: data.max_res_override,
              min_fps_override: data.min_fps_override,
              max_fps_override: data.max_fps_override,
              min_bitrate_override: data.min_bitrate_override,
              max_bitrate_override: data.max_bitrate_override,
            },
          ],
          streaming_ingest: {
            username: data.username,
            password: data.password,
            primary_url: data.primary_url,
            secondary_url: data.secondary_url,
          },
        },
      },
    };
    return obj1;
  };
  return async (dispatch) => {
    try {
      const createLucDes = await savedDestinationsService.createLucDest(
        makePayload2(getAwsDes[0] || getAwsDes),
      );
      dispatch(success(createLucDes));
      dispatch(getDestinationDetail(createLucDes.id));
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function failure(error) {
      return {
        type: saveddestinationsConstants.CREATE_LUC_DEST_FAILURE,
        error,
      };
    }
    function success(createLucDesData) {
      return {
        type: saveddestinationsConstants.CREATE_LUC_DEST,
        createLucDesData,
      };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
  function utfEncode(data) {
    try {
      return utf8.encode(data);
    } catch (e) {
      return data;
    }
  }
}
/**
 * @name delAWSDest
 * @params destId,params
 * @descriptiton delete a aws destination
 **/
function delAWSDest(destId, params) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const delAwsDest = await savedDestinationsService.delAWSDest(destId);
      dispatch(liveStreamActions.refreshStreamToolsDestination());
      dispatch(success(delAwsDest));
      dispatch(getLucDest2(destId, params));
    } catch (e) {
      dispatch(failure(e.toString()));
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function success(delAwsDestData) {
    return { type: saveddestinationsConstants.DEL_AWS_DEST, delAwsDestData };
  }
  function failure(error) {
    return { type: saveddestinationsConstants.DEL_AWS_DEST_FAILURE, error };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name getLucDest2
 * @params destId
 * @descriptiton get a particular luc destination
 **/
function getLucDest2(destId, params) {
  return async (dispatch) => {
    try {
      const lucDest = await savedDestinationsService.getLucDest();
      dispatch(success(lucDest));
      let lucDests = lucDest.filter((values) => {
        return values.streaming_destination.external_id === destId;
      });
      if (lucDests.length) {
        dispatch(delLUCDest(lucDests[0].id, params));
      } else {
        dispatch(await editUnitAction.getAWSDestInven());
        dispatch(hideSpinner());
        notify('success', i18n.t('DESTINATIONDELETED'));
      }
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function failure(error) {
      return { type: saveddestinationsConstants.GET_LUC_DEST_FAILURE, error };
    }
    function success(lucDestData) {
      return { type: saveddestinationsConstants.GET_LUC_DEST, lucDestData };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name delLUCDest
 * @params destId
 * @descriptiton delete a LUC destination
 **/
function delLUCDest(destId, params) {
  return async (dispatch) => {
    try {
      const lucDest = await savedDestinationsService.delLucDest(destId);
      dispatch(success(lucDest));
      dispatch(await editUnitAction.getAWSDestInven());
      if (!!params && params.emptyUnitDest) {
        const payload1 = {
          unit: {
            selected_destination: '',
          },
        };
        let bossId = cookies.get('currentSelectedUnit');
        await savedDestinationsService.setLucDest(payload1, bossId);
      }

      dispatch(hideSpinner());
      notify('success', i18n.t('DESTINATIONDELETED'));
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function failure(error) {
      return { type: saveddestinationsConstants.GET_LUC_DEST_FAILURE, error };
    }
    function success(delLucDestData) {
      return { type: saveddestinationsConstants.GET_LUC_DEST, delLucDestData };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
