import {
  _getTwitchUser,
  // _getTwitchIngests,
  _getTwitchGames,
  _updateTwitchChannel,
  _getTwitchStreamKey,
} from '../api/twitch.api';
import { _getTwitchIngests } from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
export const twitchService = {
  getTwitchUser,
  getTwitchIngests,
  getTwitchGames,
  updateTwitchChannel,
  getTwitchStreamKey,
};
const cookies = new Cookies();
async function getTwitchUser() {
  try {
    const channel = await _getTwitchUser();
    return channel.data.data[0];
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getTwitchIngests() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const ingests = await _getTwitchIngests(awsHeaders);
    return ingests.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getTwitchStreamKey(id) {
  try {
    const response = await _getTwitchStreamKey(id);
    return response.data.data[0];
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getTwitchGames(value) {
  try {
    const games = await _getTwitchGames(value);
    if (!!games.data.data) {
      return games.data.data;
    } else {
      return [];
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

async function updateTwitchChannel(payload, id) {
  try {
    const post = await _updateTwitchChannel(payload, id);
    return post.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
