import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { userLngPreferences } from './userLngPreferences.reducer';
import { unit } from './unit.reducer';
import { editUnit } from './editunit.reducer';
import { destination } from './destination.reducer';
import { payment } from './payment.reducer';
import { currency } from './currency.reducer';
import { channelServiceDestination } from './channelServiceDestination.reducer';
import { easyLive } from './easylive.reducer';
import { common } from './common.reducer';
import { periscope } from './periscopetwitter.reducer';
import { boxcast } from './boxcast.reducer';
import { vimeo } from './vimeo.reducer';
import { restream } from './restream.reducer';
import { fb } from './fb.reducer';
import { yt } from './yt.reducer';
import { twitch } from './twitch.reducer';
import { switchboard } from './switchboard.reducer';
import { linkedin } from './linkedin.reducer';
import { fbAnalytics } from './HeaderAnalytics/fbAnalytics.reducer';
import {streamTools} from './streamtools.reducer'
const appReducer = combineReducers({
  authentication,
  userLngPreferences,
  registration,
  unit,
  destination,
  channelServiceDestination,
  editUnit,
  payment,
  currency,
  easyLive,
  common,
  periscope,
  boxcast,
  fbAnalytics,
  vimeo,
  fb,
  linkedin,
  switchboard,
  yt,
  restream,
  twitch,
  streamTools
});

// Reset the redux store state
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
