import { Divider } from 'antd';
import React from 'react';
import StreamToolsFallBackSlate from './streamToolsFallBackSlate';
import StreamToolsLogoUpload from './streamToolsLogoUpload';
import StreamToolsProfileConfiguration from './StreamToolsProfileConfiguration';

const StreamToolsSettings = ({ match }) => {
  return (
    <>
      <div className='previewToolMain'>
        <StreamToolsLogoUpload {...{ match }} />
        <Divider />
        <StreamToolsFallBackSlate {...{ match }} />
        <Divider />
        <StreamToolsProfileConfiguration {...{ match }} />
      </div>
    </>
  );
};

export default StreamToolsSettings;
