import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from '../Dropdown';
import { Modal, ModalFooter, ModalBody, Button } from 'reactstrap';
import StreamToolsZones from '../StreamToolsZones/streamToolsZones';
export const LrtView = ({
  t,
  service,
  channels,
  unit,
  selectedChannel,
  channelServiceDestination,
  changeZoneHandler,
  soloConnect,
  lrtSubscribed,
  lrtLoading,
  lrtToggle,
  modalIsOpen,
  modalToggle,
  modalHandler,
  streamtoolSubscribed,
  isStreamToolActive,
  dnd,
  isStreamtoolsStreaming,
  selectedZone,
  zonesOptions,
  onStreamToolsZoneUpdate,
  isUpdatingZone,
}) => {
  const { selected_channel } = channelServiceDestination;

  const getServiceStatus = () => {
    // if (streamtoolSubscribed && soloConnect) {
    //   return `${t('LRT')}, ${t('SOLOCONNECT')} ${t('AND')} ${t('STREAMTOOLS')}`;
    // }

    if (soloConnect) {
      return `${t('LRT')} ${t('AND')} ${t('SOLOCONNECT')}`;
    }

    // if (streamtoolSubscribed && !soloConnect) {
    //   return `${t('LRT')} ${t('AND')} ${t('STREAMTOOLS')}`;
    // }

    if (service && !soloConnect) {
      return t('LRT');
    }

    return t('NOSERVICE');
  };

  return (
    <div className='col-md-4 unitBlk_cont flex-column svunit-blocks'>
      <div className='sub-title'>
        <h4>{t('LRT')}</h4>
      </div>

      <div className='block-wrap'>
        {lrtLoading ? (
          <div className='timeline-wrapper'>
            <div className='timeline-item'>
              <div className='animated-background facebook'>
                <div className='background-masker header-top'></div>
                <div className='background-masker header-left'></div>
                <div className='background-masker header-right'></div>
                <div className='background-masker header-bottom'></div>
                <div className='background-masker subheader-left'></div>
                <div className='background-masker subheader-right'></div>
                <div className='background-masker subheader-bottom'></div>
                <div className='background-masker content-top'></div>
                <div className='background-masker content-first-end'></div>
                <div className='background-masker content-second-line'></div>
                <div className='background-masker content-second-end'></div>
                <div className='background-masker content-third-line'></div>
                <div className='background-masker content-third-end'></div>
              </div>
            </div>
          </div>
        ) : (
          <div className='container-min'>
            <div className='lrtStatus_blk'>
              <div className='profile-select d-flex flex-row justify-content-between 	'>
                <div className='lbltxt lrttxt'>{t('SERVICESTATUS')}:</div>
                <div className='valuetxt'>{getServiceStatus()}</div>
              </div>
              {service && (
                <div className='profile-select d-flex flex-row justify-content-between'>
                  <div className='lbltxt lrttxt'>{t('LRT')}:</div>
                  {
                    <div className='valuetxt ml-2'>
                      <input
                        type='checkbox'
                        className='toggle'
                        id='cloudGraphicstoggle'
                        checked={lrtToggle}
                        disabled={
                          channels.length === 0 ||
                          unit.status === 'streaming' ||
                          unit.status === 'offline' ||
                          dnd
                        }
                        onChange={() =>
                          changeZoneHandler(unit.BOSSID, selected_channel)
                        }
                      />
                      <label
                        htmlFor='cloudGraphicstoggle'
                        className='mb-0'
                        disabled={
                          channels.length === 0 ||
                          unit.status === 'streaming' ||
                          unit.status === 'offline' ||
                          dnd
                        }
                      >
                        <span className='on'>On</span>
                        <span className='off'>Off</span>
                      </label>
                    </div>
                  }
                </div>
              )}
              <>
                {service && channels.length > 0 && (
                  <div className='zone-block d-flex flex-row justify-content-between align-items-center'>
                    <div className='lbltxt '>{t('ZONE')}: </div>
                    <div className='valuetxt d-flex flex-row align-items-center'>
                      {!false ? (
                        <Dropdown
                          channels={channels}
                          unit={unit}
                          selectedChannel={selectedChannel}
                          channelServiceDestination={channelServiceDestination}
                          selected_channel={selected_channel}
                          isDisabled={
                            channels.length === 0 ||
                            unit.status === 'streaming' ||
                            !lrtToggle
                          }
                          {...{
                            streamtoolSubscribed,
                            isStreamToolActive,
                            dnd,
                            isStreamtoolsStreaming,
                            selectedZone,
                            zonesOptions,
                            onStreamToolsZoneUpdate,
                            isUpdatingZone,
                          }}
                        />
                      ) : (
                        <StreamToolsZones
                          {...{
                            streamtoolSubscribed,
                            isStreamToolActive,
                            dnd,
                            isStreamtoolsStreaming,
                            selectedZone,
                            zonesOptions,
                            onStreamToolsZoneUpdate,
                            isUpdatingZone,
                          }}
                        />
                      )}
                      <span className='ml-2' title={t('LRTSERVICE')}>
                        <Link
                          to='#'
                          className='help-btn fa fa-question-circle fa-lg'
                          onClick={() => {
                            modalHandler();
                          }}
                          disabled={
                            channels.length === 0 ||
                            unit.status === 'streaming' ||
                            !lrtToggle
                          }
                        ></Link>
                      </span>
                    </div>
                  </div>
                )}
              </>
              {(!service || !soloConnect) && (
                <div className='border-top mt-2'></div>
              )}

              <div className='text-center info-text '>
                {!service && !soloConnect && (
                  <span className='f-size-12'>{t('NOLRTCONNECTINFO')}</span>
                )}
                {!soloConnect && service && (
                  <span className='f-size-10'>{t('NOSOLOCONNECTINFO')}</span>
                )}
                {soloConnect && !service && (
                  <span className='f-size-12'>
                    {t('NOLRTBUTSOLOCONNECTPAID')}
                  </span>
                )}
              </div>
              <div className='ltrAction d-flex flex-row justify-content-center w-100'>
                {(process.env.REACT_APP_environment === 'production'
                  ? !service
                  : true) && (
                  <div className='mr-2'>
                    <div className='nolrtinfo'>
                      <div className='lrtservices'>
                        <Link
                          to={`/dashboard/units/payment/lrt/${unit.BOSSID}`}
                          disabled={
                            (process.env.REACT_APP_environment === 'production'
                              ? !service
                              : true) &&
                            (lrtSubscribed ||
                              soloConnect ||
                              streamtoolSubscribed)
                          }
                          className='add-lrt btn btn-primary'
                          title={`${t('ADD')} ${t('LRT')}`}
                        >
                          <b>{t('ADDLRTBTN')}</b>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {(process.env.REACT_APP_environment === 'production'
                  ? !service || !soloConnect
                  : true) && (
                  <div className='mr0'>
                    <div className='lrtservices '>
                      <Link
                        to={`/dashboard/units/soloconnect/${unit.BOSSID}`}
                        disabled={
                          (process.env.REACT_APP_environment === 'production'
                            ? !service
                            : true) && soloConnect
                        }
                        className='add-lrt btn btn-primary'
                        title={`${t('ADD')} ${t('SOLOCONNECT')}`}
                      >
                        <b>{t('ADDSOLOCONNECTBTN')}</b>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              {!(unit.status === 'offline') && (
                <div className='row'>
                  <div className='col-md-12 text-center pt-1 f-size-11'>
                    <span>
                      <Link
                        to='#'
                        className='fa fa-info-circle fa-lg info-icon mr-1'
                      ></Link>
                      {t('SOLOCONNECTUSAONLY')}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div
          id='addlrtinfo'
          className='modal paymentinfo-popup'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-hidden='true'
                >
                  <i className='fa fa-times' aria-hidden='true'></i>
                </button>
                <h1>{t('INFO')}</h1>
              </div>
              <div className='modal-body'>{t('LRTMODEINFO')}</div>
              <div className='modal-footer'>
                <div className='col-md-12'>
                  <button
                    className='btn btn-primary btn-sm'
                    data-dismiss='modal'
                    aria-hidden='true'
                  >
                    {t('OK')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalIsOpen ? (
        <Modal isOpen={modalIsOpen} toggle={modalToggle}>
          <i className='fa fa-times' onClick={modalToggle}></i>
          <div className='modal-footer border-bottom'>
            <h3 className='w-100 text-left'>{t('INFO')}</h3>
          </div>
          {/* <div className="page-title mb-0"><h1></h1></div> */}
          <ModalBody>
            <p>{t('REGIONINFO')}</p>
            <p className='red'>{t('STREAMINGLRTINFO')}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              className='btn btn-primary btn-sm'
              data-dismiss='modal'
              aria-hidden='true'
              onClick={modalToggle}
            >
              {t('OK')}
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </div>
  );
};
