import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import GoSoloBlack from '../../assets/images/go-solo-black.png';
import { AppVersion } from '../AppVersion/AppVersion';
import { LangDropdown } from '../LangDropdown';
import './ForgotPassword.css';
export const ForgotPasswordView = (props) => {
  const { t, history, submitted, onSubmitHandler } = props;

  return (
    <div className='entrySection d-flex align-items-center justify-content-center'>
      <div className='entryContainer'>
        <div className='loginLang'>
          <LangDropdown align={false} />
        </div>
        <Row className='rowflex d-flex flex-row align-items-stretch'>
          <Col
            lg='7'
            md='6'
            className='d-none d-md-flex whitebgTransparent rightSide align-items-center '
          >
            <span className='titleTxt'>
              {t('MAKING')} <br />
              {t('LIVE')} <br />
              {t('SIMPLER')}
            </span>
          </Col>
          <Col
            lg='5'
            md='6'
            className='leftSide d-flex flex-column justify-content-center'
          >
            <div className='welcome'>
              <div className='text-center my-5'>
                <img src={GoSoloBlack} alt='description' />
              </div>
              <h4 className='mb-3'>{t('PASSWORDRESET')}</h4>
            </div>
            <div className='errormsg'>
              <span id='errormsg' className='text-center'></span>
            </div>
            <Formik
              initialValues={{
                email: '',
                captchaValue: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .trim()
                  .required(t('EMAILREQUIRED'))
                  .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    t('INVALIDEMAIL'),
                  ),
                captchaValue: Yup.string().required(),
              })}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, { setSubmitting }) => {
                values.email = values.email.trim();
                onSubmitHandler(values);
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form
                  name='register'
                  className='w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  {
                    <React.Fragment>
                      <FormGroup className='regInput'>
                        <Input
                          className={`form-control ${
                            formik.errors.email ? '' : 'success'
                          }`}
                          placeholder={t('EMAILADDRESS')}
                          name='email'
                          id='txtEmail'
                          type='email'
                          maxLength='75'
                          {...formik.getFieldProps('email')}
                        />
                        <span
                          className={
                            !formik.errors.email && formik.values.email
                              ? 'successtick'
                              : 'd-none'
                          }
                        >
                          <i className='fa fa-check' />
                        </span>
                        <ErrorMessage
                          component='span'
                          name='email'
                          className='col-sm-8 col-sm-push-4 error'
                        />
                      </FormGroup>
                      {formik.values.email && !formik.errors.email && (
                        <FormGroup className='regInput iamrobot'>
                          <ReCAPTCHA
                            sitekey='6LfvgBITAAAAAKZZSXVrhPvFceB3mARvJLtOFZB9'
                            onChange={(value) => {
                              formik.setFieldValue('captchaValue', value);
                              formik.values.captchaValue = value;
                            }}
                          />
                        </FormGroup>
                      )}
                      <FormGroup className='loginInputBtn'>
                        {submitted ? (
                          <input
                            type='button'
                            id='btnLoading'
                            value={t('LOADING')}
                            className='btn btn-lg btn-danger btn-block'
                          />
                        ) : (
                          <input
                            className='btn btn-lg btn-primary btn-block'
                            value={t('SENDRESETLINK')}
                            type='submit'
                            disabled={!formik.isValid}
                          />
                        )}
                      </FormGroup>
                      <FormGroup className='regInput text-right mb-2 w-100 login-links-register'>
                        <button
                          type='button'
                          className='atag'
                          onClick={() => history.push('/login')}
                        >
                          {t('GOBACK')}
                        </button>
                      </FormGroup>
                    </React.Fragment>
                  }
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <div className='loginAppVersion'>
          <AppVersion t={t} />
        </div>
      </div>
    </div>
  );
};
