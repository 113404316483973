import { commonConstants, switchboardConstants } from '../constants';
import { switchboardService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { history } from '../history';
import { commonAction } from './common.actions';
import i18n from 'i18next';
import { notify } from '../components/CommonNotification/CommonNotification';
import { store } from '../store';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const switchboardAction = {
  getData,
  refreshToken,
  streamDetails,
  makePayload,
  SBToken,
};
/**
 * @name SBToken
 * @params code,redirect,bossID
 * @descriptiton gets a switchboard token
 **/
function SBToken(code, redirect, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getSBTok = await switchboardService.getSwitchboardToken(
        'token',
        code,
        redirect,
      );
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('SB_access_token', getSBTok.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      cookies.set('SB_refresh_token', getSBTok.refresh_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(success(getSBTok));
      history.push('/dashboard/switchboard/' + bossId);
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };
  function request() {
    return { type: switchboardConstants.SB_TOKEN_REQ };
  }

  function success(Data) {
    return { type: switchboardConstants.SB_TOKEN, Data };
  }

  function failure(error) {
    return { type: switchboardConstants.SB_TOKEN_FAIL, error };
  }
}
/**
 * @name getData
 * @params match
 * @descriptiton get switchboard user profile
 **/
function getData(match) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const t1 = await switchboardService.getSwitchboardUser();
      const t2 = await switchboardService.getSwitchboardProfile(t1[0].Id);
      dispatch(profile(t2));
      const isActive = t2.ActiveSubscription.Status;
      if (isActive === 'Active' || isActive === 'InTrial') {
        const t3 = await switchboardService.getSwitchboardAllStreams(t1[0].Id);
        if (t3.no_streams) {
          dispatch(noworkflows());
        } else {
          t3.filter((val) => {
            val.label = val.Label;
            val.value = val.Id;
            return t3;
          });
          dispatch(workflows(t3));
        }
        !t3.no_streams && dispatch(streamDetails(t3[0], match, false));
      } else {
        notify('error', i18n.t('SWITCHBOARDACCOUNTSTAT'));
        history.push('/dashboard/switchboardlogin/' + match.params.bossId);
      }
    } catch (e) {
      const t1 = errorBlock(e, match);
      dispatch(fail());
      // eslint-disable-next-line no-unused-vars
      const t2 = t1 === 403 ? dispatch(refreshToken(match)) : null;
    }
  };
  function request() {
    return { type: switchboardConstants.SB_DATA_REQ };
  }
  function fail() {
    return { type: switchboardConstants.SB_DATA_FAIL };
  }
  function profile(Data) {
    return { type: switchboardConstants.SB_PROFILE, Data };
  }
  function workflows(Data) {
    return { type: switchboardConstants.SB_WORKFLOWS, Data };
  }
  function noworkflows() {
    return { type: switchboardConstants.SB_NO_WORKFLOWS };
  }
}
/**
 * @name refreshToken
 * @params match
 * @descriptiton gets new token based on refresh token
 **/
function refreshToken(match) {
  return async (dispatch) => {
    try {
      const t1 = await switchboardService.getSwitchboardToken('refresh');
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('SB_access_token', t1.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(getData(match));
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
/**
 * @name streamDetails
 * @params formvalues,match,loader
 * @descriptiton gets a particular stream with details
 **/
function streamDetails(data, match, loader) {
  let showloader = loader;
  return async (dispatch) => {
    try {
      showloader && dispatch(showLoader());
      const t1 = await switchboardService.getSwitchboardStreamDetail(
        data.CustomerId,
        data.Id,
      );
      dispatch(streamname(t1.StreamKey));
      t1.Locations.filter((val) => {
        val.label = val.Label;
        val.value = val.RtmpAddress;
        return t1;
      });
      dispatch(ingests(t1.Locations));
      dispatch(hideLoader());
    } catch (e) {
      const t1 = errorBlock(e, match);
      dispatch(fail());
      // eslint-disable-next-line no-unused-vars
      const t2 = t1 === 403 ? dispatch(refreshToken(match)) : null;
      dispatch(hideLoader());
    }
  };
  function fail() {
    return { type: switchboardConstants.SB_DATA_FAIL };
  }
  function streamname(Data) {
    return { type: switchboardConstants.SB_STREAM_NAME, Data };
  }
  function ingests(Data) {
    return { type: switchboardConstants.SB_INGESTS, Data };
  }
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name makePayload
 * @params formvalues,bossID
 * @descriptiton creates AWS Destination
 **/
function makePayload(values, bossId) {
  const { streamKey } = store.getState().switchboard.SB;
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile,
        primary_url: values.ingestUrl,
        secondary_url: '',
        stream_name: streamKey,
        username: '',
        password: '',
        id: guid(),
        streaming_provider: 'Switchboard Live',
        overrideResolution: values.overrideResolution,
        overrideFramerate: values.overrideFramerate,
        overrideBitrate: values.overrideBitrate,
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
      // eslint-disable-next-line no-unused-vars
      const res =
        e.message ||
        e.response.status !== 404 ||
        e.response.status !== 403 ||
        e.response.status !== 401
          ? dispatch(hideSpinner())
          : null;
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name guid
 * @params none
 * @descriptiton creates 16 char unique string
 **/
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
