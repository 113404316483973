export const editunitConstants = {
  //edit unit constants
  GET_STREAMING_PROVIDERS_REQ: 'GET_STREAMING_PROVIDERS_REQ',
  GET_STREAMING_PROVIDERS: 'GET_STREAMING_PROVIDERS',
  GET_STREAMING_PROVIDERS_FAILURE: 'GET_STREAMING_PROVIDERS_FAILURE',
  GET_AWS_DESTBYINVEN_REQ: 'GET_AWS_DESTBYINVEN_REQ',
  GET_AWS_DESTBYINVEN: 'GET_AWS_DESTBYINVEN',
  GET_AWS_DESTBYINVEN_FAILURE: 'GET_AWS_DESTBYINVEN_FAILURE',
  GET_CHANNEL_SERVICE: 'GET_CHANNEL_SERVICE',
  GET_CHANNEL_SERVICE_FAIL: 'GET_CHANNEL_SERVICE_FAIL',
  //fb constants
  FB_PAGES_REQ: 'FB_PAGES_REQ',
  FB_PAGES_FAILURE: 'FB_PAGES_FAILURE',
  FB_PAGES: 'FB_PAGES',
  FB_GROUPS: 'FB_GROUPS',
  FB_GROUPS_FAILURE: 'FB_GROUPS_FAILURE',
  FB_EVENTS: 'FB_EVENTS',
  FB_EVENTS_FAILURE: 'FB_EVENTS_FAILURE',
  FB_LIVE_VIDEOS: 'FB_LIVE_VIDEOS',
  FB_LIVE_VIDEOS_FAILURE: 'FB_LIVE_VIDEOS_FAILURE',
  FB_LOGIN_FAILURE: 'FB_LOGIN_FAILURE',
  FB_LOGIN: 'FB_LOGIN',
  FB_PERSONAL: 'FB_PERSONAL',
  FB_LIVE_VIDEOS_EXISTS: 'FB_LIVE_VIDEOS_EXISTS',
  FB_LIVE_VIDEOS_EXISTS_FAILURE: 'FB_LIVE_VIDEOS_EXISTS_FAILURE',
  RECENT_DEST_EXISTS: 'RECENT_DEST_EXISTS',
  RECENT_DEST_EXISTS_FAILURE: 'RECENT_DEST_EXISTS_FAILURE',
  RECENT_DEST_FAILURE: 'RECENT_DEST_FAILURE',
  RECENT_DEST: 'RECENT_DEST',
  SHOW_FB_SIGN_IN: 'SHOW_FB_SIGN_IN',
  SHOW_FB_SIGN_IN_FALSE: 'SHOW_FB_SIGN_IN_FALSE',
  FB_SESSION_EXPIRED_TRUE: 'FB_SESSION_EXPIRED_TRUE',
  FB_SESSION_EXPIRED_FALSE: 'FB_SESSION_EXPIRED_FALSE',
  FB_DELETE_DEST: 'FB_DELETE_DEST',
  FB_DELETE_DEST_FAIL: 'FB_DELETE_DEST_FAIL',
  //saved destinations constants
  GET_AWS_DEST_REQ_FAILURE: 'GET_AWS_DEST_REQ_FAILURE',
  GET_AWS_DEST: 'GET_AWS_DEST',
  CREATE_AWS_DEST: 'CREATE_AWS_DEST',
  CREATE_AWS_DEST_FAILURE: 'CREATE_AWS_DEST_FAILURE',
  GET_LUC_DEST: 'GET_LUC_DEST',
  GET_LUC_DEST_FAILURE: 'GET_LUC_DEST_FAILURE',
  CREATE_LUC_DEST: 'CREATE_LUC_DEST',
  CREATE_LUC_DEST_FAILURE: 'CREATE_LUC_DEST_FAILURE',
  SET_LUC_DEST: 'SET_LUC_DEST',
  SET_LUC_DEST_FAILURE: 'SET_LUC_DEST_FAILURE',
  DEL_AWS_DEST_FAILURE: 'DEL_AWS_DEST_FAILURE',
  DEL_AWS_DEST: 'DEL_AWS_DEST',
  DEL_LUC_DEST: 'DEL_LUC_DEST',
  DEL_LUC_DEST_FAILURE: 'DEL_LUC_DEST_FAILURE',
  //yt constants
  YT_LOGIN_FAILURE: 'YT_LOGIN_FAILURE',
  YT_LOGIN: 'YT_LOGIN',
  SHOW_YT_SIGN_IN: 'SHOW_YT_SIGN_IN',
  SHOW_YT_SIGN_IN_FALSE: 'SHOW_YT_SIGN_IN_FALSE',
  YT_SESSION_EXPIRED_TRUE: 'YT_SESSION_EXPIRED_TRUE',
  YT_SESSION_EXPIRED_FALSE: 'YT_SESSION_EXPIRED_FALSE',
  GET_BROADCASTS: 'GET_BROADCASTS',
  GET_BROADCASTS_FAIL: 'GET_BROADCASTS_FAIL',
  GET_LIVE_STREAMS: 'GET_LIVE_STREAMS',
  GET_LIVE_STREAMS_FAIL: 'GET_LIVE_STREAMS_FAIL',
  YT_LOADING_TRUE: 'YT_LOADING_TRUE',
  YT_LOADING_FALSE: 'YT_LOADING_FALSE',
  YT_BROADCAST_EXIST: 'YT_BROADCAST_EXIST',
  YT_LIVE_STREAM_EXIST: 'YT_LIVE_STREAM_EXIST',
  YT_BROADCAST_LIST: 'YT_BROADCAST_LIST',
  YT_LIVE_STREAM_LIST: 'YT_LIVE_STREAM_LIST',
  YT_DELETE_UPCOMING: 'YT_DELETE_UPCOMING',
  YT_DELETE_UPCOMING_FAIL: 'YT_DELETE_UPCOMING_FAIL',
  YT_DELETE_DASHBOARD: 'YT_DELETE_DASHBOARD',
  YT_DELETE_DASHBOARD_FAIL: 'YT_DELETE_DASHBOARD_FAIL',
  GET_TWITTER_AUTH_REQ: 'GET_TWITTER_AUTH_REQ',
};
