import Cookies from 'universal-cookie';
const cookies = new Cookies();

// Function to set an item in localStorage
export const setLocalStorageItem = (name, value) => {
  localStorage.setItem(name, value);
};

// Function to get an item from localStorage
export const getLocalStorageItem = (name) => {
  return localStorage.getItem(name);
};

// Function to remove an item from localStorage
export const removeLocalStorageItem = (name) => {
  localStorage.removeItem(name);
};

// Function to set a cookie
export const setCookie = (name, value, options = {}) => {
  cookies.set(name, value, options);
};

// Function to get a cookie
export const getCookie = (name) => {
  return cookies.get(name);
};

// Function to remove a cookie
export const removeCookie = (name) => {
  cookies.remove(name);
};
