import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ForgotPasswordView } from './ForgotPasswordView';
import { userActions } from '../../actions';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaValue: null,
      submitted: false,
    };
  }

  onSubmitHandler = async (values) => {
    if (values.captchaValue !== null) {
      this.setState({ submitted: true });
    }
    const result = await this.props.forgetpassword(values.email.toLowerCase());
    if (result) {
      this.setState({ submitted: true });
    } else {
      this.setState({ submitted: false });
    }
  };

  render() {
    const { t, history } = this.props;
    const { submitted } = this.state;

    return (
      <ForgotPasswordView
        t={t}
        history={history}
        submitted={submitted}
        onSubmitHandler={this.onSubmitHandler}
      />
    );
  }
}

const mapDispatchToProps = {
  forgetpassword: userActions.forgetpassword,
};

const connectedForgotPassword = connect(
  null,
  mapDispatchToProps,
)(withTranslation()(ForgotPassword));
export { connectedForgotPassword as ForgotPassword };
