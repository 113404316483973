import i18n from 'i18next';
import React, { Component } from 'react';
import { notify } from '../CommonNotification/CommonNotification';

export class EditUnitName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.unit.name,
    };
  }
  renameUnitHandler = (unit) => {
    const payload = {
      unit: { name: this.state.name },
    };
    unit.name = this.state.name;
    this.props.renameUnit(unit.BOSSID, payload, unit);
    notify('success', 'Succesfully Name Changed');
  };

  unitNameChangeHandler = (e) => {
    this.setState({ name: e.target.value });
  };

  cancelRenameHandler = () => {
    this.props.cancelRenameUnit(this.props.unit);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    // Only safe with condition
    if (prevProps.unit.BOSSID !== this.props.unit.BOSSID) {
      this.setState({ name: this.props.unit.name });
    }
  }

  render() {
    return (
      <span className='editUnitName d-flex flex-row'>
        <input
          onChange={(e) => this.unitNameChangeHandler(e)}
          type='text'
          value={this.state.name}
          ng-required='true'
          //ng-pattern="/^\S+[a-zA-Z0-9_-]*?$/"
        />

        <button
          type='button'
          className='btn btn-primary mx-1 btn-sm btn-yes'
          onClick={() => this.renameUnitHandler(this.props.unit)}
          disabled={!/^(\b[\wa-zA-Z0-9_-]{5,})+$/.test(this.state.name)}
        >
          <i className='fa fa-check'></i>
        </button>
        <button
          type='button'
          className='btn btn-danger btn-sm btn-no'
          onClick={() => this.cancelRenameHandler(this.props.unit)}
        >
          <i className='fa fa-times'></i>
        </button>
        <button
          type='button'
          className='btn btn-primary btn-sm btn-no ml-3'
          onClick={() => this.props.removeUnit(this.props.unit)}
        >
          {i18n.t('REMOVEUNIT')}
        </button>
      </span>
    );
  }
}
