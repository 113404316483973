import {
  editunitConstants,
  ytConstants,
  commonConstants,
} from '../../constants';
import {
  editUnitsService,
  savedDestinationsService,
  ytService,
  unitService,
} from '../../services';
import { notify } from '../../components/CommonNotification/CommonNotification';
import { commonAction } from './../common.actions';
import { destinationActions } from './../destination.actions';
import { errorBlock } from '../../api/errorBlock';
import { store } from '../../store';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { history } from '../../history';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
// import { liveStreamActions } from './../livestream.action';
import { liveStreamActions } from '../../actions';

const cookies = new Cookies();

export const YTAction = {
  YTLogin,
  ytLoginCheck,
  delYTUnpublished,
  delYTDashboard,
  ytInitialState,
  getBroadcastDetails,
  //getDefaultBroadcast,
  checkStream,
  checkEditStream,
  getYTChannels,
  editSelectedLiveStream,
  deleteSelectedLiveStream,
  getYTEventDetails,
  getYTStreamToolEventDetails,
  ytBroadcastTransition,
  ytBroadcastAnalytics,
  ytBroadcastStreamToolAnalytics,
  getAnalytics,
  getAnalyticsComments,
  youtubeLogout,
};

function ytLoginCheck(match) {
  return async (dispatch) => {
    try {
      dispatch(otherSuccess());
      dispatch(otherSuccessRes());
      dispatch(getBroadcastDetails(match));
    } catch (e) {
      errorBlock(e, match);
      dispatch(otherFailureRes1());
      dispatch(showSignIn());
    }
  };

  function showSignIn() {
    return { type: ytConstants.SHOW_YT_SIGN_IN };
  }
  function otherFailureRes1() {
    return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
  }
  function otherSuccess() {
    return { type: ytConstants.SHOW_YT_SIGN_IN_FALSE };
  }
  function otherSuccessRes() {
    return { type: ytConstants.YT_LOADING_TRUE };
  }
}

function YTLogin(event, match) {
  return async (dispatch) => {
    try {
      dispatch(request());
      await ytService.handleAuthClick(event);
      const ytLogin = await ytService.gapiSignIn();
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('YT_access_token', ytLogin.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      notify('success', i18n.t('YTLOGINSUCCESS'));
      dispatch(success(ytLogin));
      dispatch(otherSuccess());
      dispatch(otherSuccessRes());
      if (match.url.includes('youtubeanalytics')) {
        dispatch(getAnalytics(match));
      } else if (match.url.includes('configureyoutube')) {
        history.push('/dashboard/youtube/' + match.params.bossId);
      } else {
        dispatch(getBroadcastDetails(match));
      }
      dispatch(otherSuccessRes1());
    } catch (e) {
      if (match.url.includes('youtubeanalytics')) {
        history.push('/dashboard/unit/');
      }
      dispatch(failure(e));
      notify('error', i18n.t('YTLOGINFAIL'));
      errorBlock(e, match);
      dispatch(otherFailure());
      dispatch(otherFailureRes());
      dispatch(otherFailureRes1());
      dispatch(showSignIn());
    }

    function request() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function success(ytLoginData) {
      return { type: ytConstants.YT_LOGIN, ytLoginData };
    }
    function otherSuccess() {
      return { type: ytConstants.SHOW_YT_SIGN_IN_FALSE };
    }
    function otherSuccessRes() {
      return { type: ytConstants.YT_LOADING_TRUE };
    }
    function failure(error) {
      return { type: ytConstants.YT_LOGIN_FAILURE, error };
    }
    function otherFailure() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function otherFailureRes() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function otherFailureRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function otherSuccessRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function getBroadcastDetails(match) {
  return async (dispatch) => {
    try {
      const getBroadcast = await ytService.getBroadcastDet();
      getBroadcast.items.length !== 0
        ? dispatch(success(getBroadcast.items))
        : dispatch(success([]));
      dispatch(otherSuccessRes1());
      dispatch(getLiveStream(match));
    } catch (e) {
      dispatch(failure(e.response.data.error));
      errorBlock(e, match);
      dispatch(otherFailure());
      dispatch(otherFailureRes());
      dispatch(otherFailureRes1());
      dispatch(showSignIn());
      if (match.url.includes('youtube')) {
        dispatch(loadingStop());
      }
    }

    function success(ytBroadcastData) {
      return { type: ytConstants.GET_BROADCASTS, ytBroadcastData };
    }
    function failure(error) {
      return { type: ytConstants.GET_BROADCASTS_FAIL, error };
    }
    function otherFailure() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function otherFailureRes() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function otherFailureRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function otherSuccessRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
    function loadingStop() {
      return { type: ytConstants.YT_LOADING_STOP };
    }
  };
}

function getLiveStream(match) {
  return async (dispatch) => {
    try {
      const getLiveStr = await ytService.getLiveSt();
      let broadcastList = store.getState().yt.YT_data.broadcasts;
      function liveStreamFilter() {
        let arr1 = [];
        // eslint-disable-next-line array-callback-return
        getLiveStr.items.map((firstValue) => {
          let flagF1 = false;
          // eslint-disable-next-line array-callback-return
          broadcastList.map((secondValue) => {
            if (firstValue.id === secondValue.contentDetails.boundStreamId) {
              flagF1 = true;
              firstValue.isDisable = true;
              arr1.push(firstValue);
            }
          });
          if (!flagF1) {
            firstValue.isDisable = false;
            arr1.push(firstValue);
          }
        });
        return uniqBy(arr1, 'id');
      }
      getLiveStr.items.length !== 0
        ? dispatch(success(liveStreamFilter()))
        : dispatch(success([]));
      dispatch(otherSuccessRes1());
      if (match.url.includes('edit')) {
        dispatch(ytAllEvents(match));
      }
      if (match.url.includes('youtube')) {
        dispatch(loadingStop());
      }
    } catch (e) {
      dispatch(failure(e));
      errorBlock(e, match);
      dispatch(otherFailure());
      dispatch(otherRes());
      dispatch(otherFailureRes1());
      dispatch(showSignIn());
      if (match.url.includes('youtube')) {
        dispatch(loadingStop());
      }
    }

    function success(ytLiveStreamData) {
      return { type: ytConstants.GET_LIVE_STREAMS, ytLiveStreamData };
    }
    function failure(error) {
      return { type: ytConstants.GET_LIVE_STREAMS_FAIL, error };
    }
    function otherFailure() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function otherRes() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function otherFailureRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function otherSuccessRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
    function loadingStop() {
      return { type: ytConstants.YT_LOADING_STOP };
    }
  };
}

function ytAllEvents(match) {
  return async (dispatch) => {
    try {
      let intermediate = [];
      let unpublishedYTEvents = [];
      let dashboardYTEvents = [];
      let intermediate2 = [];
      let broadcastList = store.getState().yt.YT_data.broadcasts;
      let { liveStreams } = store.getState().yt.YT_data;
      const awsDest =
        store.getState().editUnit.editunit.aws_Destinations.destList;
      const matchedYtDest = awsDest.filter((value) => {
        return value.streaming_provider.toLowerCase() === 'youtube';
      });
      if (broadcastList.length && matchedYtDest.length) {
        broadcastList.forEach((broadcastValue) => {
          matchedYtDest.forEach((matchedValue) => {
            if (broadcastValue.id === matchedValue.d_id) {
              broadcastValue.destination_id = matchedValue.d_id;
              intermediate.push(broadcastValue);
            }
          });
        });
        intermediate2 = broadcastList.filter((bvalue) => {
          return !matchedYtDest.some((mvalue) => {
            return bvalue.id === mvalue.d_id;
          });
        });
      } else {
        dispatch(ytBroadcastExists(false));
      }
      if (intermediate.length && liveStreams.length) {
        const v1 = eventFilter(intermediate, liveStreams);
        unpublishedYTEvents = v1;
        dispatch(broadcastSuccess(unpublishedYTEvents));
        dispatch(ytBroadcastExists(true));
      } else {
        dispatch(ytBroadcastExists(false));
      }
      if (intermediate2.length && liveStreams.length) {
        const v2 = eventFilter(intermediate2, liveStreams);
        dashboardYTEvents = v2;
        dispatch(liveStreamSuccess(dashboardYTEvents));
        dashboardYTEvents.length && dispatch(ytLiveStreamExists(true));
        dispatch(otherRes());
      } else {
        dispatch(ytLiveStreamExists(false));
        dispatch(otherRes());
      }
      dispatch(otherSuccessRes1());
      function filterStreams(interValue, liveValue) {
        if (
          liveValue.cdn.resolution !== '1440p' &&
          liveValue.cdn.resolution !== 'variable' &&
          liveValue.cdn.resolution !== '2160p'
        ) {
          return interValue;
        }
      }
      function eventFilter(value1, value2) {
        let arr1 = [];
        value1.forEach((firstValue) => {
          if (
            !!firstValue.snippet.scheduledStartTime &&
            firstValue.snippet.scheduledStartTime !== '1970-01-01T00:00:00Z'
          ) {
            let flagF1 = false;
            value2.forEach((secondValue) => {
              if (secondValue.id === firstValue.contentDetails.boundStreamId) {
                flagF1 = true;
                const v1 = filterStreams(firstValue, secondValue);
                if (v1) {
                  arr1.push(v1);
                }
              }
            });
            if (!flagF1) {
              arr1.push(firstValue);
            }
          }
        });
        return arr1;
      }
    } catch (e) {
      dispatch(otherFailureRes1());
      errorBlock(e, match);
      dispatch(showSignIn());
    }
    function ytBroadcastExists(broadcastExists) {
      return { type: ytConstants.YT_BROADCAST_EXIST, broadcastExists };
    }
    function broadcastSuccess(broadcastData) {
      return { type: ytConstants.YT_BROADCAST_LIST, broadcastData };
    }
    function liveStreamSuccess(liveStreamData) {
      return { type: ytConstants.YT_LIVE_STREAM_LIST, liveStreamData };
    }
    function ytLiveStreamExists(liveStreamExists) {
      return { type: ytConstants.YT_LIVE_STREAM_EXIST, liveStreamExists };
    }
    function otherRes() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function otherFailureRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function otherSuccessRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
  };
}

function getYTChannels(match, type, dId, ytType) {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const userDetails = await ytService.getChannels();
      userDetails.items && userDetails.items.length !== 0
        ? dispatch(success(userDetails.items[0]))
        : dispatch(success([]));
      dispatch(ytSessionNotExpired());
      if (type === 'create') {
        dispatch(ytLoadingStop());
      }
      if (type === 'edit') {
        if (ytType === 'unpublished') {
          dispatch(awsDestination(dId, match));
        }
        if (ytType === 'dashboard') {
          dispatch(broadcastById(dId, match));
        }
      }
    } catch (e) {
      dispatch(failure(e));
      errorBlock(e, match);
      dispatch(ytLoadingStop());
      dispatch(ytSessionExpired());
      dispatch(showSignIn());
      dispatch(loadingStop());
    }
    function loading() {
      return { type: ytConstants.YT_LOADING };
    }
    function loadingStop() {
      return { type: ytConstants.YT_LOADING_STOP };
    }
    function success(userDetails) {
      return { type: ytConstants.GET_USER_DETAILS, userDetails };
    }
    function failure(error) {
      return { type: ytConstants.GET_USER_DETAILS_FAIL, error };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytLoadingStop() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

// function getDefaultBroadcast(match) {
//     return async dispatch => {
//         try {
//             const defaultBroadcast = await ytService.getDefaultBroadcast();
//             (defaultBroadcast.items.length !== 0) ? dispatch(success(defaultBroadcast.items[0])) : dispatch(success([]));
//             dispatch(ytSessionNotExpired());
//             if((defaultBroadcast.items.length !== 0)){
//                 dispatch(defaultLiveStream(defaultBroadcast.items[0].contentDetails.boundStreamId, match));
//             }else{
//                 dispatch(ytLoadingStop());
//                 dispatch(ytSessionExpired());
//                 dispatch(showSignIn());
//                 dispatch(loadingStop())
//             }
//         } catch (e) {
//             dispatch(failure(e));
//             errorBlock(e, match);
//             dispatch(ytLoadingStop());
//             dispatch(ytSessionExpired());
//             dispatch(showSignIn());
//             dispatch(loadingStop())
//         }
//         function loadingStop() { return { type: ytConstants.YT_LOADING_STOP } }
//         function success(defaultBroadcast) { return { type: ytConstants.GET_DEFAULT_BROADCAST, defaultBroadcast } }
//         function failure(error) { return { type: ytConstants.GET_DEFAULT_BROADCAST_FAIL, error } }
//         function showSignIn() { return { type: ytConstants.SHOW_YT_SIGN_IN } }
//         function ytLoadingStop() { return { type: ytConstants.YT_LOADING_FALSE } }
//         function ytSessionExpired() { return { type: ytConstants.YT_SESSION_EXPIRED_TRUE } }
//         function ytSessionNotExpired() { return { type: ytConstants.YT_SESSION_EXPIRED_FALSE } }
//     }
// }

// function defaultLiveStream(stream_id, match) {
//     return async dispatch => {
//         try {
//             const defaultLiveStream = await ytService.getLiveStream(stream_id);
//             (defaultLiveStream.items.length !== 0) ? dispatch(success(defaultLiveStream.items[0])) : dispatch(success([]));
//             dispatch(ytSessionNotExpired());
//             dispatch(loadingStop());
//         } catch (e) {
//             dispatch(failure(e));
//             errorBlock(e, match);
//             dispatch(ytLoadingStop());
//             dispatch(ytSessionExpired());
//             dispatch(showSignIn());
//             dispatch(loadingStop())
//         }
//         function loadingStop() { return { type: ytConstants.YT_LOADING_STOP } }
//         function success(defaultLiveStream) { return { type: ytConstants.GET_DEFAULT_LIVE_STREAM, defaultLiveStream } }
//         function failure(error) { return { type: ytConstants.GET_DEFAULT_LIVE_STREAM_FAIL, error } }
//         function showSignIn() { return { type: ytConstants.SHOW_YT_SIGN_IN } }
//         function ytLoadingStop() { return { type: ytConstants.YT_LOADING_FALSE } }
//         function ytSessionExpired() { return { type: ytConstants.YT_SESSION_EXPIRED_TRUE } }
//         function ytSessionNotExpired() { return { type: ytConstants.YT_SESSION_EXPIRED_FALSE } }
//     }
// }

function awsDestination(dId, match) {
  return async (dispatch) => {
    try {
      dispatch(destinationActions.awsDestinationsById(dId));
      dispatch(ytSessionNotExpired());
      dispatch(broadcastById(dId, match));
    } catch (e) {
      errorBlock(e, match);
      dispatch(ytLoadingStop());
      //dispatch(ytSessionExpired());
      //dispatch(showSignIn());
      dispatch(loadingStop());
    }
    function loadingStop() {
      return { type: ytConstants.YT_LOADING_STOP };
    }
    // eslint-disable-next-line no-unused-vars
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytLoadingStop() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    // eslint-disable-next-line no-unused-vars
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function broadcastById(broadcast_id, match) {
  return async (dispatch) => {
    try {
      const broadcast = await ytService.getBroadcastEventById(broadcast_id);
      broadcast.items.length !== 0
        ? dispatch(success(broadcast.items[0]))
        : dispatch(success([]));
      dispatch(ytSessionNotExpired());
      if (
        broadcast.items.length !== 0 &&
        broadcast.items[0].contentDetails.boundStreamId
      ) {
        dispatch(
          liveStreamById(
            broadcast.items[0].contentDetails.boundStreamId,
            match,
          ),
        );
      } else {
        dispatch(loadingStop());
      }
    } catch (e) {
      dispatch(failure(e));
      errorBlock(e, match);
      dispatch(ytLoadingStop());
      dispatch(ytSessionExpired());
      dispatch(showSignIn());
      dispatch(loadingStop());
    }
    function loadingStop() {
      return { type: ytConstants.YT_LOADING_STOP };
    }
    function success(broadcast) {
      return { type: ytConstants.GET_BROADCAST_BY_ID, broadcast };
    }
    function failure(error) {
      return { type: ytConstants.GET_BROADCAST_BY_ID_FAIL, error };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytLoadingStop() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function liveStreamById(stream_id, match) {
  return async (dispatch) => {
    try {
      const liveStream = await ytService.getLiveStreamById(stream_id);
      liveStream.items.length !== 0
        ? dispatch(success(liveStream.items[0]))
        : dispatch(success([]));
      dispatch(ytSessionNotExpired());
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failure(e));
      errorBlock(e, match);
      dispatch(ytLoadingStop());
      dispatch(ytSessionExpired());
      dispatch(showSignIn());
      dispatch(loadingStop());
    }
    function loadingStop() {
      return { type: ytConstants.YT_LOADING_STOP };
    }
    function success(liveStream) {
      return { type: ytConstants.GET_LIVE_STREAM_BY_ID, liveStream };
    }
    function failure(error) {
      return { type: ytConstants.GET_LIVE_STREAM_BY_ID_FAIL, error };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytLoadingStop() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function delYTUnpublished(destId, match) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());

      await savedDestinationsService.delAWSDest(destId);
      await ytService.deleteYTEvent(destId);
      const lucDest = await savedDestinationsService.getLucDest();
      dispatch(liveStreamActions.refreshStreamToolsDestination());

      let lucDests = lucDest.filter((values) => {
        return values.streaming_destination.external_id === destId;
      });

      if (lucDests.length) {
        await savedDestinationsService.delLucDest(lucDests[0].id);
        const getAwsDestByInven = await editUnitsService.getAWSDestInven();
        if (getAwsDestByInven.dest.length) {
          dispatch(successOther(getAwsDestByInven));
        } else {
          dispatch(successOther(getAwsDestByInven));
        }
        dispatch(success(lucDests));
        notify('success', i18n.t('YTDESTINATIONDELETED'));
        dispatch(hideSpinner());
        dispatch(ytLoginCheck(match));
      } else {
        const getAwsDestByInven = await editUnitsService.getAWSDestInven();
        if (getAwsDestByInven.dest.length) {
          dispatch(successOther(getAwsDestByInven));
        } else {
          dispatch(successOther(getAwsDestByInven));
        }
        dispatch(success(lucDest));
        notify('success', i18n.t('YTDESTINATIONDELETED'));
        dispatch(hideSpinner());
        dispatch(ytLoginCheck(match));
      }
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, match);
      dispatch(otherFailureRes1());
      dispatch(showSignIn());
      dispatch(hideSpinner());
    }
  };
  function success(sucData) {
    return { type: ytConstants.YT_DELETE_UPCOMING, sucData };
  }
  function failure(error) {
    return { type: ytConstants.YT_DELETE_UPCOMING_FAIL, error };
  }
  function otherFailureRes1() {
    return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
  }
  function showSignIn() {
    return { type: ytConstants.SHOW_YT_SIGN_IN };
  }
  function successOther(awsDestListData) {
    return { type: editunitConstants.GET_AWS_DESTBYINVEN, awsDestListData };
  }
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function delYTDashboard(eventID, match) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const delYTEve = await ytService.deleteYTEvent(eventID);
      dispatch(success(delYTEve));
      notify('success', i18n.t('YTDESTINATIONDELETED'));
      dispatch(hideSpinner());
      dispatch(ytLoginCheck(match));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, match);
      dispatch(otherFailureRes1());
      dispatch(showSignIn());
      dispatch(hideSpinner());
    }
  };
  function success(sucData) {
    return { type: ytConstants.YT_DELETE_DASHBOARD, sucData };
  }
  function failure(error) {
    return { type: ytConstants.YT_DELETE_DASHBOARD_FAIL, error };
  }
  function otherFailureRes1() {
    return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
  }
  function showSignIn() {
    return { type: ytConstants.SHOW_YT_SIGN_IN };
  }
  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function ytInitialState(match) {
  return async (dispatch) => {
    try {
      dispatch(initial());
    } catch (e) {
      errorBlock(e, match);
    }
  };

  function initial() {
    return { type: ytConstants.YT_INITIAL_STATE };
  }
}

function editSelectedLiveStream(liveStream, match) {
  return async (dispatch) => {
    try {
      await ytService.editLiveStream(liveStream);
      dispatch(ytSessionNotExpired());
      dispatch(getLiveStream(match));
    } catch (e) {
      if (!!e.response && e.response.status === 401) {
        dispatch(ytSessionExpired());
      } else {
        dispatch(ytSessionNotExpired());
      }
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function deleteSelectedLiveStream(liveStream, match) {
  return async (dispatch) => {
    try {
      const stream = await ytService.deleteLiveStream(liveStream.id);
      if (stream.status === 204) {
        notify('success', 'Deleted'); //needed here
      }
      dispatch(ytSessionNotExpired());
      dispatch(getLiveStream(match));
    } catch (e) {
      errorBlock(e, match);
      if (!!e.response) {
        if (e.response.status === 401) {
          dispatch(ytSessionExpired());
        } else {
          dispatch(ytSessionNotExpired());
        }
      }
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function checkStream(values, defBroadcast, liveStream, match) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      let startDate = '';
      if (values.scheduleLive) {
        startDate = new Date(values.scheduledTime).toISOString();
      } else {
        var currentDate = new Date().getTime();
        startDate = new Date(currentDate + 60000).toISOString();
      }
      let payload = {
        snippet: {
          title: values.destinationEvent,
          scheduledStartTime: startDate,
          description: values.descriptionEvent,
        },
        status: {
          privacyStatus: values.privacyEvent.value,
          selfDeclaredMadeForKids: values.forKids,
        },
        contentDetails: {
          enableAutoStart: !values.startAsPreview,
          enableAutoStop: !values.startAsPreview,
        },
      };
      const broadcast = await ytService.createBroadcastEvent(payload);
      let frameRate = '';
      if (values.profile.name.includes('p60')) {
        frameRate = '60fps';
      } else {
        frameRate = '30fps';
      }

      if (values.profile.name === '2160p50/60') {
        frameRate = '60fps';
      } else if (values.profile.name === '2160p25/30') {
        frameRate = '30fps';
      }

      let resolution = '';
      switch (values.profile.name) {
        case '720p60':
          resolution = '720p';
          break;
        case '1080p60':
          resolution = '1080p';
          break;
        case '2160p50/60':
        case '2160p25/30':
          resolution = '2160p';
          break;
        default:
          resolution = values.profile.name;
          break;
      }

      if (values.streamContent === 'existing') {
        await ytService.bindLiveStream(broadcast.id, values.liveStream.id, {});
        // if (values.forKids) {
        let videoObj = {
          id: broadcast.id,
          status: {
            selfDeclaredMadeForKids: values.forKids,
          },
        };
        await ytService.updateVideo(videoObj);
        //}
        dispatch(
          makePayload(
            values,
            broadcast,
            values.liveStream,
            match.params.bossId,
          ),
        );
      } else {
        let streamObj = {};
        if (values.streamContent === 'single') {
          streamObj = {
            snippet: {
              title: 'Basic',
            },
            contentDetails: {
              isReusable: false,
            },
            cdn: {
              resolution: resolution,
              ingestionType: 'rtmp',
              frameRate: frameRate,
            },
          };
          const livestream = await ytService.createSingleLiveStream(streamObj);
          await ytService.bindLiveStream(broadcast.id, livestream.id, {});
          let videoObj = {
            id: broadcast.id,
            status: {
              selfDeclaredMadeForKids: values.forKids,
            },
          };
          await ytService.updateVideo(videoObj);
          dispatch(
            makePayload(values, broadcast, livestream, match.params.bossId),
          );
        }
        if (values.streamContent === 'create') {
          streamObj = {
            snippet: {
              title: values.streamKeyEvent,
            },
            cdn: {
              resolution: resolution,
              ingestionType: 'rtmp',
              frameRate: frameRate,
            },
          };

          const livestream = await ytService.createLiveStream(streamObj);
          await ytService.bindLiveStream(broadcast.id, livestream.id, {});
          let videoObj = {
            id: broadcast.id,
            status: {
              selfDeclaredMadeForKids: values.forKids,
            },
          };
          await ytService.updateVideo(videoObj);
          dispatch(
            makePayload(values, broadcast, livestream, match.params.bossId),
          );
        }
      }
    } catch (e) {
      if (!!e.response && e.response.status === 401) {
        dispatch(ytInitialState(match));
      }
      dispatch(hideSpinner());
      errorBlock(e, match);
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function checkEditStream(values, editBroadcast, match) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      let startDate = '';
      if (values.scheduledTime) {
        startDate = new Date(values.scheduledTime).toISOString();
      }
      let payload = {
        id: editBroadcast.id,
        snippet: {
          title: values.destinationEvent,
          scheduledStartTime: startDate,
          description: values.descriptionEvent,
        },
        status: {
          privacyStatus: values.privacyEvent.value,
          selfDeclaredMadeForKids: editBroadcast.status.selfDeclaredMadeForKids,
        },

        contentDetails: {
          ...editBroadcast.contentDetails,
          enableAutoStart: !values.startAsPreview,
          enableAutoStop: !values.startAsPreview,
        },
        // contentDetails: editBroadcast.contentDetails,
      };
      const broadcast = await ytService.createDefaultBroadcast(payload);

      let frameRate = '';
      if (values.profile.name.includes('p60')) {
        frameRate = '60fps';
      } else {
        frameRate = '30fps';
      }

      if (values.profile.name === '2160p50/60') {
        frameRate = '60fps';
      } else if (values.profile.name === '2160p25/30') {
        frameRate = '30fps';
      }

      let resolution = '';
      switch (values.profile.name) {
        case '720p60':
          resolution = '720p';
          break;
        case '1080p60':
          resolution = '1080p';
          break;
        case '2160p50/60':
        case '2160p25/30':
          resolution = '2160p';
          break;
        default:
          resolution = values.profile.name;
          break;
      }
      if (values.streamContent === 'existing') {
        await ytService.bindLiveStream(broadcast.id, values.liveStream.id, {});
        dispatch(
          makePayload(
            values,
            broadcast,
            values.liveStream,
            match.params.bossId,
          ),
        );
      } else {
        let streamObj = {};
        if (values.streamContent === 'single') {
          streamObj = {
            snippet: {
              title: 'Basic',
            },
            contentDetails: {
              isReusable: false,
            },
            cdn: {
              resolution: resolution,
              ingestionType: 'rtmp',
              frameRate: frameRate,
            },
          };
          const livestream = await ytService.createSingleLiveStream(streamObj);
          await ytService.bindLiveStream(broadcast.id, livestream.id, {});
          dispatch(
            makePayload(values, broadcast, livestream, match.params.bossId),
          );
        }
        if (values.streamContent === 'create') {
          streamObj = {
            snippet: {
              title: values.streamKeyEvent,
            },
            cdn: {
              resolution: resolution,
              ingestionType: 'rtmp',
              frameRate: frameRate,
            },
          };
          const livestream = await ytService.createLiveStream(streamObj);
          await ytService.bindLiveStream(broadcast.id, livestream.id, {});
          dispatch(
            makePayload(values, broadcast, livestream, match.params.bossId),
          );
        }
      }
    } catch (e) {
      if (!!e.response && e.response.status === 401) {
        dispatch(ytInitialState(match));
      }
      dispatch(hideSpinner());
      errorBlock(e, match);
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function makePayload(values, broadcast, liveStream, bossId) {
  return async (dispatch) => {
    try {
      const payload = {
        destinationName: values.destinationEvent,
        selectedProfile:
          values.streamContent === 'existing'
            ? values.profileExisting.name
            : values.profile.name,
        primary_url: liveStream.cdn.ingestionInfo.ingestionAddress,
        secondary_url: liveStream.cdn.ingestionInfo.backupIngestionAddress,
        stream_name: liveStream.cdn.ingestionInfo.streamName,
        username: '',
        password: '',
        id: broadcast.id,
        streaming_provider: 'youtube',
        overrideResolution: values.resolutionBox
          ? values.overrideResolution.value
          : '',
        overrideFramerate: values.frameRateBox
          ? values.overrideFrame.value
          : '',
        overrideBitrate: values.bitRateBox ? values.overrideBitrate : '',
        startAsPreview: values.startAsPreview || '',
        audio_bitrate_override: values.audioBitRateBox
          ? values.audioBitRate?.value
          : null,
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function getYTStreamToolEventDetails(broadcast_id, match) {
  return async (dispatch) => {
    try {
      const broadcast = await ytService.getBroadcastEventById(broadcast_id);
      dispatch(ytSessionNotExpired());
      return Promise.resolve(
        broadcast.items.length !== 0 ? broadcast.items[0] : [],
      );
    } catch (e) {
      if (!!e.response && e.response.status === 401) {
        dispatch(ytInitialState(match));
      }
      dispatch(failure(e));
      errorBlock(e, match);
      dispatch(ytSessionExpired());
      dispatch(showSignIn());
      return Promise.reject(e);
    }
    function failure(error) {
      return { type: ytConstants.GET_BROADCAST_BY_ID_FAIL, error };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function getYTEventDetails(broadcast_id, match) {
  return async (dispatch) => {
    try {
      const broadcast = await ytService.getBroadcastEventById(broadcast_id);
      broadcast.items.length !== 0
        ? dispatch(success(broadcast.items[0]))
        : dispatch(success([]));
      dispatch(ytSessionNotExpired());
      return Promise.resolve(
        broadcast.items.length !== 0 ? broadcast.items[0] : [],
      );
    } catch (e) {
      if (!!e.response && e.response.status === 401) {
        dispatch(ytInitialState(match));
      }
      dispatch(failure(e));
      errorBlock(e, match);
      dispatch(ytSessionExpired());
      dispatch(showSignIn());
      return Promise.reject(e);
    }
    function success(broadcast) {
      return { type: ytConstants.GET_BROADCAST_BY_ID, broadcast };
    }
    function failure(error) {
      return { type: ytConstants.GET_BROADCAST_BY_ID_FAIL, error };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function ytBroadcastTransition(id, status, match) {
  const { units } = store.getState().unit;
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await ytService.broadcastTransition(id, status);
        resolve(data);
      } catch (e) {
        if (e.response.data.error.errors[0].reason !== 'redundantTransition') {
          setTimeout(async () => {
            const data = await ytService.broadcastTransition(id, status);
            resolve(data);
          }, 5000);
        } else {
          if (!!e.response && e.response.status === 401) {
            const selectedUnit = units.filter((value) => value.id === match);
            if (!!selectedUnit[0]) {
              if (selectedUnit[0].status === 'streaming') {
                await unitService.stopUnit(match);
              }
            }
            dispatch(ytInitialState(match));
          }
          errorBlock(e, match);
          reject(e);
        }
      }
    });
  };
}

function ytBroadcastAnalytics(id, match) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const analytics = await ytService.ytAnalytics(id);
        analytics.items.length !== 0
          ? dispatch(success(analytics.items[0]))
          : dispatch(success([]));
        resolve(analytics);
      } catch (e) {
        if (!!e.response && e.response.status === 401) {
          dispatch(ytInitialState(match));
        }
        errorBlock(e, match);
        reject(e);
      }
    });
  };
  function success(analytics) {
    return { type: ytConstants.YT_BROADCAST_ANALYTICS, analytics };
  }
}

function ytBroadcastStreamToolAnalytics(id, match) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const analytics = await ytService.ytAnalytics(id);
        analytics.items.length !== 0
          ? resolve(analytics.items[0])
          : resolve([]);
      } catch (e) {
        if (!!e.response && e.response.status === 401) {
          dispatch(ytInitialState(match));
        }
        errorBlock(e, match);
        reject(e);
      }
    });
  };
}

function getAnalytics(match) {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const broadcast = await ytService.getAllBroadcasts();
      const videos = broadcast.items;
      if (videos.length !== 0) {
        let ids = map(videos, 'id');
        let broadcastids = ids.join(',');
        const result = await ytService.getVideoAnalytics(broadcastids);
        videos.forEach(function (video) {
          video.play = false;
          if (video.contentDetails.monitorStream.embedHtml) {
            video.contentDetails.monitorStream.embedHtml =
              video.contentDetails.monitorStream.embedHtml.replace(
                'autoplay; ',
                '',
              );
            video.contentDetails.monitorStream.embedHtml =
              video.contentDetails.monitorStream.embedHtml.replace(
                'autoplay=1&; ',
                '',
              );
          }
          result.items.forEach(function (obj) {
            if (video.id === obj.id) {
              video.statistics = obj.statistics;
            }
          });
        });
        dispatch(success(videos));
      } else {
        dispatch(success([]));
      }
      dispatch(otherSuccessRes1());
      dispatch(stoploading());
    } catch (e) {
      if (!!e.response) {
        if (e.response.status === 401) {
          dispatch(ytInitialState(match));
        } else {
          dispatch(otherFailureRes());
          dispatch(otherFailureRes1());
        }
      }
      errorBlock(e, match);
      dispatch(showSignIn());
    }
    function loading() {
      return { type: ytConstants.YT_ANALYTICS_LOADING };
    }
    function stoploading() {
      return { type: ytConstants.YT_ANALYTICS_STOP };
    }
    function success(videoanalytics) {
      return { type: ytConstants.YT_VIDEO_ANALYTICS, videoanalytics };
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function otherFailureRes() {
      return { type: ytConstants.YT_LOADING_FALSE };
    }
    function otherFailureRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function otherSuccessRes1() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function getAnalyticsComments(video_id, match) {
  return async (dispatch) => {
    try {
      const comments = await ytService.getVideoComments(video_id);
      dispatch(ytSessionNotExpired());
      return Promise.resolve(comments.items);
    } catch (e) {
      if (!!e.response && e.response.status === 401) {
        dispatch(ytInitialState(match));
      }
      errorBlock(e, match);
      dispatch(ytSessionExpired());
      dispatch(showSignIn());
      return Promise.reject(e);
    }
    function showSignIn() {
      return { type: ytConstants.SHOW_YT_SIGN_IN };
    }
    function ytSessionExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_TRUE };
    }
    function ytSessionNotExpired() {
      return { type: ytConstants.YT_SESSION_EXPIRED_FALSE };
    }
  };
}

function youtubeLogout(match) {
  return async (dispatch) => {
    try {
      // delete cookies.get('YT_access_token');
      cookies.remove('YT_access_token', { path: '/' });
      dispatch(ytInitialState(match));
      //state:{dId: dId, ytType: type}
      history.push('/dashboard/configureyoutube/' + match.params.bossId);
    } catch (e) {
      errorBlock(e, match);
    }
  };
}
