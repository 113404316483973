import axios from 'axios';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

const { REACT_APP_AWSServerURL } = process.env;
const URL = `${REACT_APP_AWSServerURL}/castr`;

export const _deleteStreamById = async (id) => {
  try {
    const castrHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-api-key': cookies.get('castr_api_key'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let result = await axios.delete(`${URL}/stream?streamId=${id}`, {
      headers: castrHeaders,
    });
    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('errors', error);
  }
};
export const _getCastrStreams = async () => {
  try {
    const castrHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-api-key': cookies.get('castr_api_key'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let result = await axios.get(`${URL}/streams`, {
      headers: castrHeaders,
    });
    return result.data.data.response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('errors', error);
  }
};

export const _getUserProfile = async (token) => {
  try {
    const castrHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-api-key': token,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let result = await axios.get(`${URL}/userprofile`, {
      headers: castrHeaders,
    });
    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
};

export const _getRegionList = async (streamType) => {
  try {
    const castrHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-api-key': cookies.get('castr_api_key'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let result = await axios.get(`${URL}/regions?streamType=${streamType}`, {
      headers: castrHeaders,
    });
    return result.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
};

export const _createNewStream = async (
  streamName,
  streamTypeValue,
  selectedRegion,
) => {
  try {
    const payload = {
      type: streamTypeValue,
      name: streamName,
      region: selectedRegion,
    };
    const castrHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-api-key': cookies.get('castr_api_key'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let result = await axios.post(`${URL}/stream`, payload, {
      headers: castrHeaders,
    });
    return result.data.data.response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('castr api error', error);
  }
};

export const _getStreamRtmpConfig = async (streamId) => {
  try {
    const castrHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-api-key': cookies.get('castr_api_key'),
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    let result = await axios.get(`${URL}/stream?streamId=${streamId}`, {
      headers: castrHeaders,
    });

    return result;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('errors', error);
  }
};
