import { editunitConstants, saveddestinationsConstants } from '../constants';
const initialState = {
  //editunit initial state
  editunit: {
    providers: [],
    aws_Destinations: { destList: [] },
    unitServices: {},
    isLoading: true,
    saveDestinationLoading: true,
    twitterGetAuthLoading: false,
  },
  //saved destination initial state
  saveddest: {
    stripe: { id: null },
    aws_Destination: { dest: null },
    create_Aws_Destination: null,
    luc_Destinations: [],
    create_LUC_Destination: null,
    set_LUC_Destination: null,
    del_Aws_Destination: { dest: null },
    del_LUC_destination: { dest: null },
    showLoader: false,
  },
};

const {
  GET_STREAMING_PROVIDERS_REQ,
  GET_STREAMING_PROVIDERS,
  GET_STREAMING_PROVIDERS_FAILURE,
  GET_AWS_DESTBYINVEN_REQ,
  GET_AWS_DESTBYINVEN,
  GET_AWS_DESTBYINVEN_FAILURE,
  GET_CHANNEL_SERVICE_FAIL,
  GET_CHANNEL_SERVICE,
} = editunitConstants;
const {
  GET_AWS_DEST,
  GET_AWS_DEST_REQ_FAILURE,
  CREATE_AWS_DEST_FAILURE,
  CREATE_AWS_DEST,
  GET_LUC_DEST,
  GET_LUC_DEST_FAILURE,
  CREATE_LUC_DEST,
  CREATE_LUC_DEST_FAILURE,
  SET_LUC_DEST,
  SET_LUC_DEST_FAILURE,
  DEL_AWS_DEST_FAILURE,
  DEL_AWS_DEST,
  DEL_LUC_DEST,
  DEL_LUC_DEST_FAILURE,
  GET_TWITTER_AUTH_REQ,
} = saveddestinationsConstants;
export function editUnit(state = initialState, payload) {
  switch (payload.type) {
    //editunit
    case GET_STREAMING_PROVIDERS_REQ:
      return { ...state, editunit: { ...state.editunit, isLoading: false } };
    case GET_TWITTER_AUTH_REQ:
      return {
        ...state,
        editunit: { ...state.editunit, twitterGetAuthLoading: true },
      };
    case GET_STREAMING_PROVIDERS:
      return {
        ...state,
        editunit: {
          ...state.editunit,
          providers: payload.streamProvidersData,
        },
      };
    case GET_STREAMING_PROVIDERS_FAILURE:
      return {
        ...state,
        editunit: {
          ...state.editunit,
          providers: [payload.error],
          isLoading: false,
        },
      };
    case GET_AWS_DESTBYINVEN_REQ:
      return {
        ...state,
        editunit: {
          ...state.editunit,
          isLoading: false,
          saveDestinationLoading: true,
        },
      };
    case GET_AWS_DESTBYINVEN:
      return {
        ...state,
        editunit: {
          ...state.editunit,
          aws_Destinations: { destList: payload.awsDestListData.dest },
          isLoading: false,
          saveDestinationLoading: false,
        },
      };
    case GET_AWS_DESTBYINVEN_FAILURE:
      return {
        ...state,
        editunit: {
          ...state.editunit,
          aws_Destinations: { destList: [] },
          isLoading: false,
        },
      };
    case GET_CHANNEL_SERVICE:
      return {
        ...state,
        editunit: { ...state.editunit, unitServices: payload.getChannData },
      };
    case GET_CHANNEL_SERVICE_FAIL:
      return {
        ...state,
        editunit: {
          ...state.editunit,
          unitServices: payload.error,
          isLoading: false,
        },
      };
    //saved destinations
    case GET_AWS_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          aws_Destination: { dest: payload.getAwsDest },
        },
      };
    case GET_AWS_DEST_REQ_FAILURE:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          aws_Destination: { dest: payload.error },
        },
      };
    case CREATE_AWS_DEST_FAILURE:
      return {
        ...state,
        saveddest: { ...state.saveddest, create_Aws_Destination: null },
      };
    case CREATE_AWS_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          create_Aws_Destination: payload.createAwsDestData,
        },
      };
    case GET_LUC_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          luc_Destinations: [payload.lucDestData],
        },
      };
    case GET_LUC_DEST_FAILURE:
      return {
        ...state,
        saveddest: { ...state.saveddest, luc_Destinations: [payload.error] },
      };
    case CREATE_LUC_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          create_LUC_Destination: payload.createLucDesData,
        },
      };
    case CREATE_LUC_DEST_FAILURE:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          create_LUC_Destination: payload.error,
        },
      };
    case SET_LUC_DEST_FAILURE:
      return {
        ...state,
        saveddest: { ...state.saveddest, set_LUC_Destination: payload.error },
      };
    case SET_LUC_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          set_LUC_Destination: payload.setLucDestData,
        },
      };
    case DEL_AWS_DEST_FAILURE:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          del_Aws_Destination: { dest: payload.error },
        },
      };
    case DEL_AWS_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          del_Aws_Destination: { dest: payload.delAwsDestData },
        },
      };
    case DEL_LUC_DEST_FAILURE:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          del_LUC_Destination: { dest: payload.error },
        },
      };
    case DEL_LUC_DEST:
      return {
        ...state,
        saveddest: {
          ...state.saveddest,
          del_LUC_Destination: { dest: payload.delLucDestData },
        },
      };

    default:
      return state;
  }
}
