import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { TemplateView } from './TemplateView';
import './Template.css';
import { OtherSpinner } from '../Otherspinner';
import { ProgressBar } from '../Otherspinner/ProgressBar';

class Template extends Component {
  state = { componentError: false };

  render() {
    const { t, match, showLoader, showProgressBar } = this.props;
    return (
      <React.Fragment>
        <TemplateView t={t} match={match} state={this.state.componentError} />
        {showLoader && (
          <React.Fragment>
            <OtherSpinner showSpinner={showLoader} />
          </React.Fragment>
        )}
        {
          showProgressBar && (
            <React.Fragment>
              <ProgressBar/>
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const { showLoader, showProgressBar } = state.common;
  return {
    showLoader,
    showProgressBar
  };
};

const ConnectedTemplate = connect(
  mapStateToProps,
  null,
)(withTranslation()(Template));

export { ConnectedTemplate as Template };
