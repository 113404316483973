import React, { Component } from 'react';
import { ModalpopupView } from './ModalpopupView';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './Modalpopup.css';

class Modalpopup extends Component {
  render() {
    const { t, match, history, modalDetails } = this.props;
    return (
      <ModalpopupView
        t={t}
        match={match}
        history={history}
        modalDetails={modalDetails}
      />
    );
  }
}
const ConnectedModalpopup = connect(null, null)(withTranslation()(Modalpopup));
export { ConnectedModalpopup as Modalpopup };
