import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const ytBaseUrl = 'https://content.googleapis.com/youtube/v3';
export const getBroadcastDetails = () =>
  axios.get(`${ytBaseUrl}/liveBroadcasts`, {
    params: {
      part: 'id,snippet,status,contentDetails',
      broadcastStatus: 'upcoming',
      broadcastType: 'event',
      maxResults: 50,
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
    },
  });
export const getLiveStreams = () =>
  axios.get(`${ytBaseUrl}/liveStreams`, {
    params: {
      part: 'id,snippet,cdn',
      mine: true,
      maxResults: 50,
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
      'If-None-Match': guid(),
    },
  });
export const deleteEvent = (eventID) =>
  axios.delete(`${ytBaseUrl}/liveBroadcasts`, {
    params: { id: eventID },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _getChannels = () =>
  axios.get(`${ytBaseUrl}/channels`, {
    params: {
      part: 'id,snippet',
      mine: true,
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
      'If-None-Match': guid(),
    },
  });
export const _getDefaultBroadcast = () =>
  axios.get(`${ytBaseUrl}/liveBroadcasts`, {
    params: {
      broadcastType: 'persistent',
      mine: true,
      part: 'id,snippet,status,contentDetails',
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _getLiveStream = (broadcast_id) =>
  axios.get(`${ytBaseUrl}/liveStreams`, {
    params: {
      id: broadcast_id,
      part: 'id,snippet,status,cdn',
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
      'If-None-Match': guid(),
    },
  });
export const _createDefaultBroadcast = (payload) =>
  axios.put(`${ytBaseUrl}/liveBroadcasts`, payload, {
    params: {
      part: 'id,snippet,status,contentDetails',
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _createBroadcastEvent = (payload) =>
  axios.post(`${ytBaseUrl}/liveBroadcasts`, payload, {
    params: {
      part: 'id,snippet,status,contentDetails',
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _createLiveStream = (payload) =>
  axios.post(`${ytBaseUrl}/liveStreams`, payload, {
    params: {
      part: 'id,snippet,status,cdn',
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
      'If-None-Match': guid(),
    },
  });
export const _createSingleLiveStream = (payload) =>
  axios.post(`${ytBaseUrl}/liveStreams`, payload, {
    params: {
      part: 'id,snippet,status,cdn,contentDetails',
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
      'If-None-Match': guid(),
    },
  });
export const _bindLiveStream = (id, streamId, payload) =>
  axios.post(`${ytBaseUrl}/liveBroadcasts/bind`, payload, {
    params: {
      id: id,
      part: 'id,snippet,status,contentDetails',
      streamId: streamId,
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
      'If-None-Match': guid(),
    },
  });
export const _editLiveStream = (payload) =>
  axios.put(`${ytBaseUrl}/liveStreams`, payload, {
    params: {
      part: 'id,snippet,status,cdn',
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _deleteLiveStream = (id) =>
  axios.delete(`${ytBaseUrl}/liveStreams`, {
    params: {
      id: id,
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _getBroadcastEventById = (id) =>
  axios.get(`${ytBaseUrl}/liveBroadcasts`, {
    params: {
      part: 'id,snippet,status,contentDetails',
      id: id,
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _getLiveStreamById = (id) =>
  axios.get(`${ytBaseUrl}/liveStreams`, {
    params: {
      part: 'id,snippet,status,cdn,contentDetails',
      id: id,
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _broadcastTransition = (id, status) =>
  axios.post(
    `${ytBaseUrl}/liveBroadcasts/transition`,
    {},
    {
      params: {
        part: 'id,snippet,status,contentDetails',
        id: id,
        broadcastStatus: status,
      },
      headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
    },
  );
export const _ytAnalytics = (id) =>
  axios.get(`${ytBaseUrl}/videos`, {
    params: {
      part: 'snippet,statistics',
      id: id,
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _getAllBroadcasts = () =>
  axios.get(`${ytBaseUrl}/liveBroadcasts`, {
    params: {
      part: 'id,snippet,status,contentDetails',
      broadcastStatus: 'completed',
      broadcastType: 'all',
      maxResults: 50,
    },
    headers: {
      Authorization: 'Bearer ' + cookies.get('YT_access_token'),
    },
  });
export const _getVideoAnalytics = (ids) =>
  axios.get(`${ytBaseUrl}/videos`, {
    params: {
      part: 'statistics',
      id: ids,
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _getVideoComments = (id) =>
  axios.get(`${ytBaseUrl}/commentThreads`, {
    params: {
      part: 'snippet,replies',
      videoId: id,
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
export const _updateVideo = (payload) =>
  axios.put(`${ytBaseUrl}/videos`, payload, {
    params: {
      part: 'id,status',
    },
    headers: { Authorization: 'Bearer ' + cookies.get('YT_access_token') },
  });
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
