export const easyliveConstants = {
  EL_GET_TOKEN_REQ: 'EL_GET_TOKEN_REQ',
  EL_GET_TOKEN: 'EL_GET_TOKEN',
  EL_GET_TOKEN_FAIL: 'EL_GET_TOKEN_FAIL',
  EL_GET_PROFILE_REQ: 'EL_GET_PROFILE_REQ',
  EL_GET_PROFILE: 'EL_GET_PROFILE',
  EL_GET_PROFILE_FAIL: 'EL_GET_PROFILE_FAIL',
  EL_GET_EXISTING_STREAMS_REQ: 'EL_GET_EXISTING_STREAMS_REQ',
  EL_GET_EXISTING_STREAMS: 'EL_GET_EXISTING_STREAMS',
  EL_GET_EXISTING_STREAMS_FAIL: 'EL_GET_EXISTING_STREAMS_FAIL',
  EL_CREATE_STREAM: 'EL_CREATE_STREAM',
  EL_CREATE_STREAM_FAIL: 'EL_CREATE_STREAM_FAIL',
  EL_SESSION_EXPIRED: 'EL_SESSION_EXPIRED',
  EL_SESSION_EXPIRED_FALSE: 'EL_SESSION_EXPIRED_FALSE',
  EL_STREAM_EXISTS_TRUE: 'EL_STREAM_EXISTS_TRUE',
  EL_STREAM_EXISTS_FALSE: 'EL_STREAM_EXISTS_FALSE',
  EL_STREAM_LIMIT: 'EL_STREAM_LIMIT',

};
