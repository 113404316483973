import React from "react";
import { ClipLoader } from "react-spinners";
import { Button } from "antd";

const NormalFlowGoLiveButton = ({
  showLoader,
  unit,
  t,
  stopStream,
  goLiveDisabled,
  handleStartStream,
}) => (
  <>
    {showLoader[unit.BOSSID] && (
      <Button type="button" className="btn btn-danger btn-primary" size="large">
        <span>
          <ClipLoader
            size={22}
            title="loading"
            color={"#ffffff"}
            loading={showLoader[unit.BOSSID]}
          />
        </span>
      </Button>
    )}
    {unit.status === "streaming" && !showLoader[unit.BOSSID] && (
      <Button
        title={t("STOPUNIT")}
        type="button"
        className="btn btn-danger"
        // disabled={!unit.selected_destination}
        onClick={() => stopStream(unit)}
        style={{
          color: "#fff",
          backgroundColor: "#dc3545",
        }}
        size="large"
      >
        {t("STOP")}
      </Button>
    )}
    {unit.status !== "streaming" && !showLoader[unit.BOSSID] && (
      <Button
        type="button"
        title={t("STARTUNIT")}
        disabled={goLiveDisabled}
        className="btn btn-primary"
        style={{
          color: "#fff",
        }}
        onClick={handleStartStream}
        size="large"
      >
        {t("GOLIVE")}
      </Button>
    )}
  </>
);

export default NormalFlowGoLiveButton;
