import axios from 'axios';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();
const {
  REACT_APP_AWSServerURL,
  REACT_APP_switchboardClientID,
  REACT_APP_appMode,
} = process.env;
const easyLiveBaseUrl = `${REACT_APP_AWSServerURL}/easylive/`;
const twitterBaseUrl = `${REACT_APP_AWSServerURL}/twitter/`;
const periscopeBaseUrl = `${REACT_APP_AWSServerURL}/periscope/`;
const SBBaseUrl = `${REACT_APP_AWSServerURL}/switchboard/`;
const LIBaseUrl = `${REACT_APP_AWSServerURL}/linkedin/`;

export const _getTwitchIngests = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/getingests`, {
    headers: awsHeaders,
  });
export const liveuLogin = (headers) =>
  axios.post(`${REACT_APP_AWSServerURL}/awsluc/login`, null, {
    headers: headers,
    // withCredentials:true
  });
export const zendeskLiveuLogin = (headers, return_to) =>
  axios.post(
    `${REACT_APP_AWSServerURL}/zendesk/userlogin`,
    { return_to },
    { headers: headers },
  );
export const _addLUCTerms = (payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/awsluc/lucterms`, payload);
export const _getCustomerId = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getcustomerid`, {
    headers: awsHeaders,
    params: {},
  });
export const deleteAWSDestination = (awsHeaders, destId) =>
  axios.delete(`${REACT_APP_AWSServerURL}/awsluc/destination`, {
    headers: awsHeaders,
    // params: destId,
    params: { d_id: destId },
  });
export const createAWSDestination = (awsHeaders, data) =>
  axios.post(`${REACT_APP_AWSServerURL}/awsluc/destination`, data, {
    headers: awsHeaders,
  });
export const getAWSDestinationById = (awsHeaders, id) =>
  axios.get(`${REACT_APP_AWSServerURL}/awsluc/destination`, {
    headers: awsHeaders,
    params: { d_id: id },
  });
export const getAWSDestinationList = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/awsluc/destinationlist`, {
    headers: awsHeaders,
    params: {},
  });
export const _getAllSubscription = (awsHeaders, type = 'active') =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getallsubscriptions`, {
    headers: awsHeaders,
    params: { type: type },
  });
export const _getCreditBalance = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/addbalance`, {
    headers: awsHeaders,
  });
export const _getPlans = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getplans`, {
    headers: awsHeaders,
  });
export const _getCurrency = (currency) =>
  axios.get(`${REACT_APP_AWSServerURL}/currency?currency=${currency}`);
export const _salesforceAuth = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/salesforce/auth`, {
    headers: awsHeaders,
  });
export const _salesforceValidateUnit = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/salesforce/validateunit`, payload, {
    headers: awsHeaders,
  });
export const _canAvailLoyaltyCredit = (awsHeaders, unitId) =>
  axios.get(`${REACT_APP_AWSServerURL}/getloyaltycredit`, {
    params: {
      type: 'is_unit_exists',
      unit_no: unitId,
    },
    headers: awsHeaders,
  });
export const _getLoyaltyCredit = (awsHeaders) =>
  axios.get(
    `${REACT_APP_AWSServerURL}/getloyaltycredit?type=users_loyalty_credit`,
    {
      headers: awsHeaders,
    },
  );
export const _salesforceRegisterUnit = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/salesforce/register`, payload, {
    headers: awsHeaders,
  });
export const _salesforceVerifyUnit = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/salesforce/verifyunit`, payload, {
    headers: awsHeaders,
  });
export const _getCustomerDetails = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getcustomerdetails`, {
    headers: awsHeaders,
  });
export const _verifyCoupon = (awsHeaders, coupon) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/verifycoupon?coupon=${coupon}`, {
    headers: awsHeaders,
  });
export const _createCoupon = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/referralcode`, payload, {
    headers: awsHeaders,
  });
export const _listof_referralcode = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/referralcode`, {
    headers: awsHeaders,
  });
export const _calculateTax = (awsHeaders, zip, country) =>
  axios.get(
    `${REACT_APP_AWSServerURL}/calculatetax?zip=${zip}&country=${country}`,
    {
      headers: awsHeaders,
    },
  );
export const _createCustomer = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripe/addcustomers`, payload, {
    headers: awsHeaders,
  });
export const getEasyLiveToken = (redirect_uri, code, awsheaders) =>
  axios.get(`${easyLiveBaseUrl}token`, {
    params: { redirect_uri: redirect_uri, code: code },
    headers: awsheaders,
  });
export const getEasyLiveProfile = (fType, elZone, streamTitle) => {
  let params;
  if (fType === 'about_me') {
    params = { ftype: fType, access_token: cookies.get('el_access_token') };
  } else if (fType === 'zone_id') {
    params = {
      ftype: fType,
      access_token: cookies.get('el_access_token'),
      zone_id: elZone,
      type_id: 'liveu',
    };
  } else if (fType === 'create_stream') {
    params = {
      ftype: fType,
      access_token: cookies.get('el_access_token'),
      zone_id: elZone,
      type_id: 'liveu',
      stream_title: streamTitle,
    };
  }
  return axios.get(`${easyLiveBaseUrl}create`, {
    params: params,
    headers: {
      Authorization: 'Bearer ' + cookies.get('el_access_token'),
    },
  });
};

// Twitter starts here...
export const _twitterAccessToken = (awsheaders, payload) =>
  axios.post(`${twitterBaseUrl}accesstoken`, payload, {
    headers: awsheaders,
  });
export const _twitterCreateSource = (awsheaders, payload) =>
  axios.post(`${twitterBaseUrl}sources`, payload, {
    headers: awsheaders,
  });
export const _twitterDeleteSource = (awsheaders, payload) =>
  axios.delete(`${twitterBaseUrl}sources?ids=${payload}`, {
    headers: awsheaders,
  });
export const _getTwitterRegion = (awsheaders) =>
  axios.get(`${twitterBaseUrl}regions`, { headers: awsheaders });
export const _getTwitterSources = (awsheaders) =>
  axios.get(`${twitterBaseUrl}sources`, { headers: awsheaders });
export const _getTwitterOAuthToken = (awsheaders) =>
  axios.get(`${twitterBaseUrl}getoauthtoken`, { headers: awsheaders });
export const _createTwitterBroadcast = (awsheaders, payload) =>
  axios.post(
    `${twitterBaseUrl}broadcast
  `,
    payload,
    {
      headers: awsheaders,
    },
  );
export const _publishTwitterBroadcast = (awsheaders, payload) =>
  axios.put(
    `${twitterBaseUrl}broadcast
  `,
    payload,
    {
      headers: awsheaders,
    },
  );
export const _stopTwitterBroadcast = (awsheaders, payload) =>
  axios.post(
    `${twitterBaseUrl}broadcast
  `,
    payload,
    {
      headers: awsheaders,
    },
  );
export const _getTwitterBroadcastStatus = (awsheaders, broadcast_id) =>
  axios.get(
    `${twitterBaseUrl}broadcast
  ?id=${broadcast_id}`,
    {
      headers: awsheaders,
    },
  );
export const _getPeriscopeProfile = (periscopeHeaders) =>
  axios.get(`${periscopeBaseUrl}me`, { headers: periscopeHeaders });
export const _getPeriscopeRegion = (periscopeHeaders) =>
  axios.get(`${periscopeBaseUrl}region`, { headers: periscopeHeaders });

export const getBoxcastToken = (code, redirect, grantType, awsHeaders) =>
  axios.post(
    `${REACT_APP_AWSServerURL}/boxcast/token`,
    {
      code: code,
      redirect_uri: redirect,
      grant_type: grantType,
    },
    { headers: awsHeaders },
  );

export const getVimeoToken = (code, redirect, awsHeaders) =>
  axios.post(
    `${REACT_APP_AWSServerURL}/vimeo/token`,
    {
      code: code,
      redirect_uri: redirect,
      grant_type: 'authorization_code',
    },
    { headers: awsHeaders },
  );

export const getRestreamToken = (code, redirect, awsHeaders) =>
  axios.post(
    `${REACT_APP_AWSServerURL}/restream/token`,
    {
      code: code,
      redirect_uri: redirect,
      grant_type: 'authorization_code',
    },
    { headers: awsHeaders },
  );

export const _addCard = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripe/addcard`, payload, {
    headers: awsHeaders,
  });
export const _startSubscription = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripe/startsubscription`, payload, {
    headers: awsHeaders,
  });
export const _editCard = (awsHeaders, payload) =>
  axios.put(`${REACT_APP_AWSServerURL}/stripe/editcard`, payload, {
    headers: awsHeaders,
  });
export const _setDefaultCard = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripe/setdefaultcard`, payload, {
    headers: awsHeaders,
  });
export const _deleteCard = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripe/deletecard`, payload, {
    headers: awsHeaders,
  });
export const _getInvoices = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/paymenthistory`, {
    headers: awsHeaders,
  });
export const _getCharges = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getallcharges`, {
    headers: awsHeaders,
  });
export const _getInvoiceDetails = (awsHeaders, id) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getinvoicedetails?id=${id}`, {
    headers: awsHeaders,
  });
export const _getChargeDetails = (awsHeaders, id) =>
  axios.get(
    `${REACT_APP_AWSServerURL}/stripe/getchargedetails?charge_id=${id}`,
    { headers: awsHeaders },
  );
export const _getUpcomingInvoices = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/stripe/getupcominginvoices`, {
    headers: awsHeaders,
  });
export const _cancelSubscription = (awsHeaders, payload) =>
  axios.post(
    `${REACT_APP_AWSServerURL}/stripe/cancelsubscription?reactivate=${
      payload.reactivate && payload.reactivate
    }`,
    payload,
    {
      headers: awsHeaders,
    },
  );
export const _updateSubscription = (awsHeaders, payload) =>
  axios.put(`${REACT_APP_AWSServerURL}/stripe/updatesubscription`, payload, {
    headers: awsHeaders,
  });
export const _addUnitSettings = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/awsluc/unitsettings`, payload, {
    headers: awsHeaders,
  });
export const _getUnitSettings = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/awsluc/unitsettings`, {
    headers: awsHeaders,
  });
export const _createAWSDestinationList = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/awsluc/destinationlist`, payload, {
    headers: awsHeaders,
  });

export const getSBProfile = (c_Id) =>
  axios.get(`${SBBaseUrl}profile`, {
    headers: { Authorization: 'Bearer ' + cookies.get('SB_access_token') },
    params: { operation: 'profile', customer_id: c_Id },
  });
export const getSBUser = () =>
  axios.get(`${SBBaseUrl}user`, {
    headers: { Authorization: 'Bearer ' + cookies.get('SB_access_token') },
    params: { operation: 'user_id' },
  });

export const getSBStreamDetails = (c_Id, stream_Id) =>
  axios.get(`${SBBaseUrl}stream`, {
    headers: { Authorization: 'Bearer ' + cookies.get('SB_access_token') },
    params: {
      stream_Id,
      operation: 'stream_details',
      customer_id: c_Id,
      stream_id: stream_Id,
    },
  });

export const getSBAllStreams = (c_Id) =>
  axios.get(`${SBBaseUrl}stream`, {
    headers: { Authorization: 'Bearer ' + cookies.get('SB_access_token') },
    params: { operation: 'all_streams', customer_id: c_Id },
  });

export const getSBToken = (query, code, redirect) => {
  if (query === 'refresh') {
    return axios.post(
      `${SBBaseUrl}token/${query}`,
      {
        refresh_token: cookies.get('SB_refresh_token'),
        client_id: REACT_APP_switchboardClientID,
        grant_type: 'refresh_token',
        appMode: REACT_APP_appMode,
      },
      {
        headers: {
          Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
          'x-user-uuid': `${cookies.get('user_session')}`,
        },
      },
    );
  } else {
    return axios.post(
      `${SBBaseUrl}token`,
      {
        code: code,
        redirect_uri: redirect,
        appMode: REACT_APP_appMode,
        grant_type: 'authorization_code',
      },
      {
        headers: {
          Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
          'x-user-uuid': `${cookies.get('user_session')}`,
        },
      },
    );
  }
};
export const LI_Token = (code, redirect) =>
  axios.post(
    `${LIBaseUrl}token`,
    {
      code: code,
      redirect_uri: redirect,
      grant_type: 'authorization_code',
    },
    {
      headers: {
        Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
        'x-user-uuid': `${cookies.get('user_session')}`,
      },
    },
  );

export const LI_Assests = (linkedinId, regionId) =>
  axios.post(
    `${LIBaseUrl}assets`,
    { id: linkedinId, region: regionId },
    { headers: { Authorization: 'Bearer ' + cookies.get('LI_access_token') } },
  );
export const LI_Status = (payload) =>
  axios.get(`${LIBaseUrl}assets`, {
    headers: { Authorization: 'Bearer ' + cookies.get('LI_access_token') },
    params: { ...payload },
  });
export const LI_Profile = () =>
  axios.get(`${LIBaseUrl}profile`, {
    headers: { Authorization: 'Bearer ' + cookies.get('LI_access_token') },
  });
export const LI_CreatePost = (payload) =>
  axios.post(
    `${LIBaseUrl}post`,
    { ...payload },
    { headers: { Authorization: 'Bearer ' + cookies.get('LI_access_token') } },
  );
export const LI_Organisation = () =>
  axios.get(`${LIBaseUrl}organization`, {
    headers: { Authorization: 'Bearer ' + cookies.get('LI_access_token') },
  });
export const _updateCustomer = (awsHeaders, payload) =>
  axios.put(`${REACT_APP_AWSServerURL}/stripe/addcustomers`, payload, {
    headers: awsHeaders,
  });

export const _referralCodeVerify = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/referralverify`, payload, {
    headers: awsHeaders,
  });
export const _paymentButton = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripepaymentbutton`, payload, {
    headers: awsHeaders,
  });
export const _getAdminMessage = (awsHeaders) => {
  return axios.get(`${REACT_APP_AWSServerURL}/getadminmessage`, {
    headers: awsHeaders,
  });
};
export const _createStripeTaxId = (awsHeaders, payload, taxId) =>
  axios.post(`${REACT_APP_AWSServerURL}/stripetaxidcreate`, payload, {
    headers: awsHeaders,
    params: { taxId: taxId },
  });
export const _getCommunicationPreference = (awsHeaders) =>
  axios.get(`${REACT_APP_AWSServerURL}/getcommunicationpreference`, {
    headers: awsHeaders,
  });

export const _setCommunicationPreference = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/setcommunicationpreference`, payload, {
    headers: awsHeaders,
  });

export const _updateEmail = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/updatecustomeremail`, payload, {
    headers: awsHeaders,
  });
export const ImageUploadS3 = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/uploadfiletocloud`, payload, {
    headers: awsHeaders,
  });

export const nStreamToolDestincations = (awsHeaders, boosID) =>
  axios.get(`${REACT_APP_AWSServerURL}/streamtooldestinations`, {
    params: {
      unit_id: boosID,
    },
    headers: awsHeaders,
    // withCredentials:true
  });

export const nDeleteStreamToolDestination = (awsHeaders, id) =>
  axios.delete(`${REACT_APP_AWSServerURL}/streamtooldestinations`, {
    params: {
      dest_id: id,
    },
    headers: awsHeaders,
  });

export const callStoreStreamToolsData = (payload, headers) => {
  return axios.post(`${REACT_APP_AWSServerURL}/streamtools`, payload, {
    headers,
  });
};

export const callGetStreamToolsData = ({ bossId, STZone }, headers) => {
  return axios.get(`${REACT_APP_AWSServerURL}/streamtools`, {
    params: {
      bossId,
      STZone,
    },
    headers,
  });
};

export const nSetStreamtoolDest = (payload, awsHeaders) => {
  return axios.put(
    `${REACT_APP_AWSServerURL}/streamtooldestinations`,
    payload,
    {
      headers: awsHeaders,
    },
  );
};
export const _addCreditBalance = (awsHeaders, payload) =>
  axios.post(`${REACT_APP_AWSServerURL}/addloyaltycredit`, payload, {
    headers: awsHeaders,
  });
