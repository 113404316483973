import { linkedinConstants, commonConstants } from '../constants';
import { linkedinService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { history } from '../history';
import { commonAction } from './common.actions';
import { store } from '../store';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const linkedinAction = {
  getProfile,
  makePayload,
  LIPostStatus,
  LIToken,
  LiGetPages,
  getAssets,
};
/**
 * @name LIToken
 * @params code,redirect,bossID
 * @descriptiton gets a Linkedin token
 **/
function LIToken(code, redirect, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getTok = await linkedinService.getLIToken(code, redirect);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('LI_access_token', getTok.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(success(getTok));
      history.push('/dashboard/linkedin/' + bossId);
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
      history.push('/dashboard/linkedinlogin/' + bossId);
    }
  };

  function request() {
    return { type: linkedinConstants.LI_TOKEN_REQ };
  }

  function success(Data) {
    return { type: linkedinConstants.LI_TOKEN, Data };
  }

  function failure(error) {
    return { type: linkedinConstants.LI_TOKEN_FAIL, error };
  }
}
/**
 * @name getProfile
 * @params match
 * @descriptiton get user's LinkedIn profile
 **/
function getProfile(match) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const det = await linkedinService.LIProfile();
      const v1 = {
        userName: `${det.firstName.localized.en_US} ${det.lastName.localized.en_US}`,
        userId: det.id,
      };
      dispatch(success(v1));
      dispatch(getAssets('personal', v1.userId, 'WEST_US', null, match, false));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, match);
      history.push('/dashboard/linkedinlogin/' + match.params.bossId);
    }
  };

  function request() {
    return { type: linkedinConstants.LI_DATA_REQ };
  }

  function success(Data) {
    return { type: linkedinConstants.LI_PROFILE, Data };
  }

  function failure() {
    return { type: linkedinConstants.LI_DATA_FAIL };
  }
}
/**
 * @name getAssets
 * @params profile type,userId, regionId, option selected , match , display loader (boolean)
 * @descriptiton get Linkedin assests based on type of profile (personal or page)
 **/
function getAssets(type, userId, regionId, selectedId, match, loader) {
  const linkedInId =
    type === 'personal'
      ? `urn:li:person:${userId}`
      : `urn:li:organization:${selectedId}`;

  return async (dispatch) => {
    try {
      loader && dispatch(showLoader());
      const details = await linkedinService.LIAssests(linkedInId, regionId);
      const v1 = {
        assetId: details.value.asset.split('urn:li:digitalmediaAsset:').pop(),
        ingests: details.value.ingestUrls,
      };
      dispatch(success(v1));
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set(
        `LI_post_data_${match.params.bossId}`,
        JSON.stringify({
          lAssetId: details.value.asset
            .split('urn:li:digitalmediaAsset:')
            .pop(),
        }),
        { path: '/', expires: d, secure: true },
      );
      loader && dispatch(hideLoader());
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, match);
      // history.push('/dashboard/linkedinlogin/' + match.params.bossId)
      history.push('/dashboard/linkedinlogin/' + match.params.bossId);
    }
  };

  function success(Data) {
    return { type: linkedinConstants.LI_ASSETS, Data };
  }

  function failure() {
    return { type: linkedinConstants.LI_DATA_FAIL };
  }

  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideLoader() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name LiGetPages
 * @params match
 * @descriptiton get all pages
 **/
function LiGetPages(match) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const v1 = await linkedinService.LIOrganisation();
      const v2 = v1.filter((val) => {
        val.label = val['organization~'].localizedName;
        val.value = val.organization.split('urn:li:organization:').pop();
        return val;
      });
      if (v2.length) {
        dispatch(org(v2));
        dispatch(getAssets('page', null, 'WEST_US', v2[0].value, match, false));
      } else {
        dispatch(orgfail());
      }
    } catch (e) {
      dispatch(failure());
      errorBlock(e, match);
    }
  };

  function request() {
    return { type: linkedinConstants.LI_DATA_REQ };
  }

  function org(Data) {
    return { type: linkedinConstants.LI_PAGE_DATA, Data };
  }

  function failure() {
    return { type: linkedinConstants.LI_DATA_FAIL };
  }

  function orgfail() {
    return { type: linkedinConstants.LI_PAGE_FAIL };
  }
}
/**
 * @name makePayload
 * @params values,bossID
 * @descriptiton creates a aws destination
 **/
function makePayload(values, bossId) {
  const { userId } = store.getState().linkedin.linkedin.user;
  let t1 = cookies.get(`LI_post_data_${bossId}`);
  let d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  cookies.set(
    `LI_post_data_${bossId}`,
    JSON.stringify({
      ...t1,
      linkedinId: JSON.parse(cookies.get('LI_post_profile'))
        ? userId
        : values.pages,
      postPrivacy: values.privacy,
    }),
    { path: '/', expires: d, secure: true },
  );
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile,
        primary_url: values.ingestUrl,
        secondary_url: values.secondary_url,
        stream_name: values.assetId,
        username: '',
        password: '',
        id: values.assetId,
        streaming_provider: 'LinkedIn',
        overrideResolution: values.overrideResolution,
        overrideFramerate: values.overrideFramerate,
        overrideBitrate: values.overrideBitrate,
        audio_bitrate_override: values.audio_bitrate_override
      };
      dispatch(commonAction.createAwsDestination(payload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }

  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
}
/**
 * @name LIPostStatus
 * @params postId,match
 * @descriptiton check post status
 **/
function LIPostStatus(postId, match) {
  let v2;
  let t2 = store.getState().linkedin.linkedin.create;
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const t1 = !!postId.id ? postId : { id: postId };
        const v1 = await linkedinService.LIStatus(t1);
        if (!!v1) {
          v2 = { id: v1.id, status: v1.recipes[0].status };
        } else {
          v2 = { id: postId.id, status: 'COMPLETED' };
        }
        if (!!cookies.get('linkedin_post_url')) {
          if (
            !!t2[match.params.bossId] ||
            !!cookies.get('linkedin_post_url')[match.params.bossId]
          ) {
            const d1 = {
              [match.params.bossId]:
                cookies.get('linkedin_post_url')[match.params.bossId],
            };
            dispatch(create(d1));
          }
        }

        dispatch(status(v2));
        resolve(v2);
      } catch (e) {
        errorBlock(e, match);
        if (
          e &&
          !!e?.response &&
          (e?.response?.status === 404 ||
            e?.response?.status === 403 ||
            e?.response?.status === 401)
        ) {
          dispatch(status({ id: postId.id, status: 'SESSION_EXPIRED' }));
        }
        reject(e);
      }
    });
  };

  function status(Data) {
    return { type: linkedinConstants.LI_POST_STATUS, Data };
  }

  function create(Data) {
    return { type: linkedinConstants.LI_CREATE_POST, Data };
  }
}
