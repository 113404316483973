import { createStore, applyMiddleware } from 'redux';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import throttle from 'lodash/throttle';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { saveState } from '../helpers/localStorage';
import * as actionCreators from '../actions/index';
import { migrations } from './migration';
const { REACT_APP_environment } = process.env;
const loggerMiddleware = createLogger();
// const persistedState = loadState();
let tStore;
const storeVersion = 0;
const persistConfig = {
  key: 'root',
  storage,
  timeout: null,
  version: storeVersion,
  migrate: createMigrate(migrations, { debug: true }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({
  actionCreators,
  trace: true,
  traceLimit: 25,
});
if (
  /*REACT_APP_environment === 'staging' ||*/ REACT_APP_environment ===
  'development'
) {
  tStore = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        thunkMiddleware,
        createStateSyncMiddleware({
          blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
        }),
        loggerMiddleware,
      ),
    ),
  );
} else {
  tStore = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
}

initMessageListener(tStore);
export const persistor = persistStore(tStore);
export const store = tStore;
store.subscribe(
  throttle(() => {
    saveState({
      //userLngPreferences: store.getState().userLngPreferences,
      //authentication: store.getState().authentication,
    });
  }, 1000),
);
