import { createSelector } from 'reselect';
// import orderBy from 'lodash/orderBy';
// import filter from 'lodash/filter';
// import find from 'lodash/find';
// import remove from 'lodash/remove';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import zoneException from '../data/zoneExceptionForNormalUnits.json';
import { checkUnitVersion } from '../utils/checkUnitVersion';
const cookies = new Cookies();

// All the selectors that needed
const customerSelector = (state) => state.payment.customer;
const servicesSelector = (state) =>
  state.channelServiceDestination.data.services;
const channelsSelector = (state) =>
  state.channelServiceDestination.data.channels;
const channelServiceDestinationSelector = (state) =>
  state.channelServiceDestination.data;
const unitSelector = (state) => state.unit.unit;
const unitsSelector = (state) => state.unit.units;
const unitOrder = (state) => state.unit.unitOrder;
const unit_statusSelector = (state) => state.unit.units_status;
const lrtSelector = (state) => state.payment.subscriptions;
const solounitSelector = (state) => state.destination.unitDetails;
const interfaceSelector = (state) => state.unit.interfaces;

export const getUnitWithStatus = createSelector(
  unitsSelector,
  unit_statusSelector,
  unitSelector,
  (units, status, unit) => {
    const selectedUnit = units.filter((u) => u?.BOSSID === unit?.BOSSID);
    const unitWithStatus =
      selectedUnit &&
      selectedUnit.map((su) => ({
        ...su,
        ...status[su.BOSSID],
      }));
    return addBatteryStatus(unitWithStatus)[0];
  },
);

export const getUnitsWithStatus = createSelector(
  unitsSelector,
  unit_statusSelector,
  unitSelector,
  unitOrder,
  (units, units_status, unit, orderValue) => {
    const _units = units.map((u) => {
      let statusOfSelectedUnit = {};
      if ((u && u.BOSSID) === (unit && unit.BOSSID)) {
        u.isActive = 'active';
      } else {
        u.isActive = '';
      }
      statusOfSelectedUnit = units_status[u.BOSSID];
      return { ...u, ...statusOfSelectedUnit };
    });
    const sortedUnits =
      orderValue === 1
        ? _.orderBy(
            addBatteryStatus(_units),
            ['priority', 'name'],
            ['asc', 'asc'],
          )
        : _units.slice().sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            const isAlphabetic = (char) => /[a-zA-Z]/.test(char);

            for (let i = 0; i < Math.min(aName.length, bName.length); i++) {
              const aChar = aName[i];
              const bChar = bName[i];

              const aIsAlphabetic = isAlphabetic(aChar);
              const bIsAlphabetic = isAlphabetic(bChar);

              if (aIsAlphabetic && !bIsAlphabetic) {
                return -1;
              } else if (!aIsAlphabetic && bIsAlphabetic) {
                return 1;
              }

              if (aChar !== bChar) {
                return aChar.localeCompare(bChar);
              }
            }

            return aName.length - bName.length;
          });

    return sortedUnits;
  },
);

function addBatteryStatus(units) {
  return units.map((unit) => {
    if (unit.sw_version) {
      if (
        (unit.sw_version.substr(0, 1) === 'v' &&
          parseFloat(unit.sw_version.substr(1, 3)) >= 6.0) ||
        parseFloat(unit.sw_version.substr(0, 3)) >= 6.0
      ) {
        unit.mode = true;
      }
    }
    if (unit.status === 'idle') {
      unit.status = 'connected';
      cookies.set(`status_${unit.BOSSID}`, 0, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        secure: true,
      });
    } else if (unit.status === 'streaming') {
    } else {
      cookies.set(`status_${unit.BOSSID}`, 0, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        secure: true,
      });
    }

    switch (unit.status.toLowerCase()) {
      case 'streaming':
        unit.priority = 0;
        break;
      case 'transcoding':
        unit.priority = 1;
        break;
      case 'starting transcoder':
        unit.priority = 2;
        break;
      case 'connected':
        unit.priority = 3;
        break;
      case 'offline':
        unit.priority = 4;
        break;
      default:
        unit.priority = 5;
        break;
    }

    if (unit.battery) {
      unit.showbattery = true;
      unit.batterystatusvalue = unit.battery.percentage;
      unit.batterystatus = unit.battery.percentage + '%';
      unit.batterystatuswidth = unit.battery.percentage + '%';
      unit.batterystatuscolor = '#666';
      if (unit.battery.charging) {
        unit.batterystatuscolor = '#66CD00';
        unit.batterystatuscharging = 'progress-striped active';
      } else if (unit.battery.percentage > 50) {
        unit.batterystatuscolor = '#66CD00';
      } else if (unit.battery.percentage >= 25) {
        unit.batterystatuscolor = '#FCD116';
      } else {
        unit.batterystatuscolor = '#FF3333';
      }
      if (unit.battery.percentage === 0) {
      }
    }
    if (unit.modems && unit.modems.length) {
      unit.modems.forEach(function (modem) {
        if (modem.connected) {
          unit.signalQuality = 'wifi wifi-signal-' + modem.upSignalQuality;
          unit.modemname = modem.name;
        }
      }, this);
    }
    return unit;
  });
}

//const videoDetailsSelector = state => state.unit.videoDetails;
const bitrateSelector = (state) => state.unit.videoDetails.bitrate;
let chartSourceData = [0];
export const getBitrate = createSelector(
  bitrateSelector,
  unitsSelector,
  unitSelector,
  (bitrate, units, unit) => {
    // select the unit and checks if streaming
    const unitSelected = units.find((u) => u.BOSSID === unit.BOSSID);
    // if the unit is streaming
    // if (unitSelected !== undefined && unitSelected.status === 'streaming') {
    if (unitSelected !== undefined) {
      if (bitrate !== undefined) {
        chartSourceData.push(bitrate);
      }

      if (chartSourceData.length > 5) {
        chartSourceData.shift();
      }

      cookies.set(
        `currentBitrate_${unitSelected.BOSSID}`,
        JSON.stringify(chartSourceData),
        { path: '/', maxAge: 60 * 60 * 24 * 365, secure: true },
      );
      // if the unit not streaming
    } else if (
      unitSelected !== undefined &&
      cookies.get(`currentBitrate_${unitSelected.BOSSID}`)
    ) {
      chartSourceData = cookies.get(`currentBitrate_${unitSelected.BOSSID}`);
    } else {
      chartSourceData = [];
    }
    // append x values as index
    return chartSourceData.map((a, index) => ({ x: index, y: a }));
  },
);

// Get the LRT service
export const getLrtServices = createSelector(servicesSelector, (services) => {
  if (services && services.indexOf('lrt') === -1) {
    return '';
  } else {
    return 'lrt';
  }
});
// Get the customer details
export const getCustomer = createSelector(customerSelector, (customer) => {
  return customer;
});
// Get the Zone list
export const getChannels = createSelector(
  channelsSelector,
  unitSelector,
  (channels, unit) => {
    if (channels) {
      const result = channels
        .map((item) => {
          if (unit.product !== 'LU_SOLO_PRO' && checkUnitVersion(unit)) {
            return { value: item.name, text: item.name };
          } else if (
            unit.product !== 'LU_SOLO_PRO' &&
            zoneException.includes(item.name.trim())
          ) {
            return undefined;
          }
          switch (item.name.trim()) {
            case 'Cloud_Service_EU':
              return { value: item.name, text: 'EU_Ireland' };
            case 'Cloud_Service_APAC':
              return { value: item.name, text: 'AP_Singapore' };
            case 'Cloud_Service_Austra':
              return { value: item.name, text: 'AU_Sydney' };
            case 'Cloud_Service_EMEA':
              return { value: item.name, text: 'EU_Ireland' };
            case 'Cloud_Service_US_SJ':
              return { value: item.name, text: 'US_West_SanJose' };
            case 'Cloud-Service':
              return { value: item.name, text: 'US_East_NorthVirginia' };
            case 'Galmo_Instance2':
              return { value: item.name, text: item.name };
            case 'Auto_mahendra@intellimedianetworks.net':
              return { value: item.name, text: item.name };
            default:
              return { value: item.name, text: item.name };
          }
        })
        .filter((item) => item !== undefined);
      // converting to ascending order
      let asscResult = _.orderBy(result, ['text'], ['asc']);
      return asscResult;
    }
  },
);
// Get selected channel
export const getSelectedChannel = createSelector(
  channelServiceDestinationSelector,
  (channelServiceDestination) => {
    if (channelServiceDestination.id) {
      return channelServiceDestination;
    }
  },
);

// Check if soloconnect service exits
export const soloConnectExists = createSelector(
  lrtSelector,
  unitSelector,
  (lrt, unit) => {
    if (lrt.length > 0) {
      return _.find(lrt, (o) => {
        return (
          o?.metadata?.service_type === 'soloconnect' &&
          o?.metadata?.unit_id === unit?.BOSSID
        );
      });
    }
  },
);

// Check if lrt service exits
export const lrtSubscribed = createSelector(
  lrtSelector,
  unitSelector,
  (lrt, unit) => {
    if (lrt.length > 0) {
      return _.find(lrt, (o) => {
        return (
          o.metadata.service_type === 'lrt' &&
          o.metadata.unit_id === unit?.BOSSID
        );
      });
    }
  },
);

// Unit connections filter
export const interfacesList = createSelector(
  interfaceSelector,
  solounitSelector,
  (interfaceValue, solounit) => {
    if (solounit.product) {
      if (solounit.product === 'LU-Solo') {
        if (interfaceValue.length > 0) {
          _.remove(interfaceValue, (inter) => {
            return inter.port <= 1;
          });
          return _.filter(interfaceValue, (inter) => {
            return (inter.interfaceName =
              inter.port.indexOf('wlan') !== -1
                ? 'Wi-Fi'
                : inter.name
                ? inter.name + ' ' + inter.technology
                : 'USB Device');
          });
        }
      } else {
        return _.filter(interfaceValue, (inter) => {
          return (inter.interfaceName =
            inter.port.indexOf('wlan') !== -1
              ? 'Wi-Fi'
              : inter.name
              ? inter.name + ' ' + inter.technology
              : 'USB Device');
        });
      }
    }
  },
);
