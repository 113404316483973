import {
  _createNewStream,
  _getStreamRtmpConfig,
  _getCastrStreams,
} from '../api/castr.api';

export const castrService = {
  createNewStream,
  getStreamRtmpConfig,
  getCastrStreams,
};
async function getCastrStreams() {
  try {
    const data = await _getCastrStreams();
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function createNewStream(streamName, streamTypeValue, selectedRegion) {
  try {
    const data = await _createNewStream(
      streamName,
      streamTypeValue,
      selectedRegion,
    );
    return data;
  } catch (e) {
    return Promise.reject(e.error.message);
  }
}
async function getStreamRtmpConfig(streamId) {
  try {
    const data = await _getStreamRtmpConfig(streamId);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}
