import {
  LI_Assests,
  LI_CreatePost,
  LI_Organisation,
  LI_Profile,
  LI_Status,
  LI_Token,
} from '../api/aws.api';

export const linkedinService = {
  LIAssests,
  LICreatePost,
  LIProfile,
  LIOrganisation,
  LIStatus,
  getLIToken,
};

async function getLIToken(code, redirect) {
  try {
    const getTok = await LI_Token(code, redirect);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function LIAssests(linkedinId, regionId) {
  try {
    const data = await LI_Assests(linkedinId, regionId);
    return data.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function LICreatePost(payload) {
  try {
    const details = await LI_CreatePost(payload);
    return details.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function LIOrganisation() {
  try {
    const details = await LI_Organisation();
    return details.data.data.response.elements;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function LIProfile() {
  try {
    const details = await LI_Profile();
    return details.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function LIStatus(payload) {
  try {
    const details = await LI_Status(payload);
    if (!!details.data.data.response) {
      return details.data.data.response;
    } else {
      return details.data.data.response;
    }
  } catch (e) {
    return Promise.reject(e);
  }
}
