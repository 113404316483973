import {
  getSBAllStreams,
  getSBProfile,
  getSBStreamDetails,
  getSBToken,
  getSBUser,
} from '../api/aws.api';

export const switchboardService = {
  getSwitchboardToken,
  getSwitchboardProfile,
  getSwitchboardAllStreams,
  getSwitchboardStreamDetail,
  getSwitchboardUser,
};

async function getSwitchboardToken(query, code, redirect) {
  try {
    const getTok = await getSBToken(query, code, redirect);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getSwitchboardProfile(c_Id) {
  try {
    const getTok = await getSBProfile(c_Id);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getSwitchboardAllStreams(c_Id) {
  try {
    const getTok = await getSBAllStreams(c_Id);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function getSwitchboardStreamDetail(c_Id, stream_Id) {
  try {
    const getTok = await getSBStreamDetails(c_Id, stream_Id);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getSwitchboardUser() {
  try {
    const getTok = await getSBUser();
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}
