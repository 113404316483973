import axios from 'axios';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const fbBaseUrl = 'https://graph.facebook.com/';
const fbAPIVersion = 'v13.0';
const { REACT_APP_AWSServerURL } = process.env;
export const fbGetPages = () =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/me/accounts`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: 1000,
    },
  });
export const fbGetGroups = (nextPage) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/me/groups`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: 200,
      after: nextPage,
    },
  });
export const fbGetEvents = () =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/me/events`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: 1000,
    },
  });
export const fbUserLiveVideos = (token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/me/live_videos`, {
    params: {
      access_token: token,
      limit: 100,
      broadcast_status: "['UNPUBLISHED','SCHEDULED_UNPUBLISHED']",
      source: 'owner',
      fields:
        'id, page_id, secure_stream_url, stream_url, destination_id, embed_html, planned_start_time,status,title',
    },
  });
export const fbGetLiveVideos = (id, token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}/live_videos`, {
    params: {
      access_token: token,
      limit: 100,
      broadcast_status: "['UNPUBLISHED','SCHEDULED_UNPUBLISHED']",
      source: 'owner',
      fields:
        'id, page_id, secure_stream_url, stream_url, destination_id, embed_html, planned_start_time,status,title',
    },
  });
export const fbGetLocation = (value) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/search`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: 10,
      fields: 'name',
      q: value,
      type: 'place',
    },
  });
export const fbGetFriendList = () =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/me/friends`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: '5000',
      fields: 'name,picture',
    },
  });
export const fbAdTags = (value) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/search`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: 10,
      fields: 'name',
      q: value,
      type: 'adinterest',
    },
  });
export const fbCrossPages = (id, token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}/crosspost_whitelisted_pages`, {
    params: { fields: 'id,name,allows_live_crossposts', access_token: token },
  });
export const initCrossPages = (id1, id2, stat, token) =>
  axios.post(`${fbBaseUrl + fbAPIVersion}/${id1}`, {
    // params: {
    begin_crossposting_handshake: [{ partner_page_id: id2, allow_live: stat }],
    access_token: token,
    //   },
  });

export const fbAdGeolocation = (value) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/search`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      limit: 10,
      q: value,
      type: 'adgeolocation',
      location_types: ['country', 'region', 'city'],
    },
  });
export const fbPostSponsor = (id) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      fields: 'verification_status,name',
    },
  });

export const createLiveVideo = (id, token, payload) =>
  axios.post(`${fbBaseUrl + fbAPIVersion}/${id}/live_videos`, payload, {
    params: { access_token: token },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
export const updateLiveVideo = (id, token, payload) =>
  axios.post(
    `${fbBaseUrl + fbAPIVersion}/${id}`,
    { ...payload },
    {
      params: {
        access_token: token.access_token || token,
        cross_share_to_group_ids: token.cross_share_to_group_ids || '',
      },
      headers: { 'Content-Type': 'application/json' },
    },
  );

export const GetLiveVideoStatus = (id, accessToken) => {
  return axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: {
      access_token: accessToken,
      fields:
        'video,status,description,secure_stream_url,title,stream_url,dash_preview_url,embed_html,planned_start_time',
    },
  });
};

export const UpdateLiveVideoStatus = (id, payload, accessToken) =>
  axios.post(
    `${fbBaseUrl + fbAPIVersion}/${id}`,
    { ...payload },
    { params: { access_token: accessToken } },
  );

export const GetVideoStatus = (id) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      fields:
        'title,description,content_tags,tags,embeddable,place,scheduled_publish_time,privacy',
    },
  });

export const GetContentTag = (id) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: { access_token: getLocalStorageItem('FB_access_token') },
  });

export const GetFriendFromId = (id) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: { access_token: getLocalStorageItem('FB_access_token') },
  });

export const GetPost = (id, token, field) => {
  if (field === 'live_views') {
    return axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
      params: { access_token: token, fields: field },
    });
  } else {
    return axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
      params: {
        access_token: token,
        fields: field,
        order: 'reverse_chronological',
      },
    });
  }
};

export const GetPostReactions = (id, token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}/reactions`, {
    params: { access_token: token },
  });

export const GetPostComments = (id, token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}/comments`, {
    params: { access_token: token, order: 'reverse_chronological' },
  });

export const fbGetCrossPostPages = (videoId, token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${videoId}/crosspost_shared_pages`, {
    params: { access_token: token },
  });

export const GetFbToken = (id) => {
  return axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      fields: 'access_token',
    },
  });
};

export const fbRefreshToken = (headers, token) =>
  axios.get(`${REACT_APP_AWSServerURL}/getfacebookrefreshtoken`, {
    params: {
      access_token: token,
    },
    headers: headers,
  });

export const getPagesLiveVideos = (id, token, endpoint) => {
  let params, apiEndpoint;

  if (endpoint === 'live_videos') {
    apiEndpoint = `${id}/${endpoint}`;
    params = {
      access_token: token,
      limit: 100,
      fields:
        'live_views,comments,reactions,title,status,embed_html,created_time',
    };
  } else {
    apiEndpoint = `${endpoint}`;
    params = {
      access_token: token,
      limit: 100,
      broadcast_status: "['VOD']",
      fields:
        'live_views,comments,reactions,title,status,embed_html,created_time',
    };
  }
  return axios.get(`${fbBaseUrl + fbAPIVersion}/${apiEndpoint}`, {
    params: params,
  });
};

export const getFacebookUserDetails = (token) => {
  let params, apiEndpoint, endpoint;
  endpoint = 'me';
  apiEndpoint = `/${endpoint}`;
  params = {
    access_token: token,
    limit: 100,
    fields: 'name,id,picture',
  };

  return axios.get(`${fbBaseUrl + fbAPIVersion}/${apiEndpoint}`, {
    params: params,
  });
};

export const fbGetGroupByPage = (id, token) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${id}`, {
    params: { access_token: token },
  });

export const fbGetPageToken = (pageId) =>
  axios.get(`${fbBaseUrl + fbAPIVersion}/${pageId}`, {
    params: {
      access_token: getLocalStorageItem('FB_access_token'),
      fields: 'access_token',
    },
  });
