import { Button, Skeleton, Tag, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { history } from '../../history';
import StreamToolsProfile from '../StreamtoolsProfile/StreamToolsProfile';

const StreamToolsDashBoardCard = ({
  streamtoolSubscribed,
  unit,
  isStreamToolActive,
  isLoadingData,
  dnd,
  handleStreamToolsToggleChange,
  isStreamtoolsStreaming,
  t,
  unitLoaderState,
  selectedZone,
  zonesOptions,
  onZoneUpdate,
  isUpdatingZone,
}) => {
  const handleStreamtoolCard = () => {
    if (isLoadingData) {
      return (
        <>
          <div className='d-flex'>
            <div className='single-line-skeleton-70 mr-5'>
              <Skeleton active title={false} />
            </div>
            <div className='single-line-skeleton-30'>
              <Skeleton active title={false} />
            </div>
          </div>
          <div className='single-line-skeleton-small mt-1'>
            <Skeleton active title={false} />
          </div>
          <div className='single-line-skeleton mt-3 ml-3 mr-3'>
            <Skeleton active title={false} />
          </div>
          <div className='single-line-skeleton mt-2 ml-5 mr-5'>
            <Skeleton active title={false} />
          </div>
          <div className='single-line-skeleton mt-2'>
            <Skeleton active title={false} />
          </div>
          <div className='single-line-skeleton mt-2'>
            <Skeleton active title={false} />
          </div>
        </>
      );
    }
    if (streamtoolSubscribed) {
      return (
        <div className='streamtoolstoggle'>
          <div className='d-flex justify-content-between mb-2'>
            <div className='d-flex align-items-center'>
              <b>{t('USESTREAMTOOLS')}</b>
            </div>
            <div className='valuetxt'>
              <input
                type='checkbox'
                className='toggle'
                id='streamTool'
                disabled={
                  unit?.status === 'streaming' ||
                  unit?.status === 'offline' ||
                  dnd === true ||
                  (isStreamToolActive && isStreamtoolsStreaming) ||
                  unitLoaderState?.[unit.BOSSID]
                }
                checked={isStreamToolActive}
                onChange={handleStreamToolsToggleChange}
              />
              <label
                htmlFor='streamTool'
                className='mb-0'
                disabled={
                  unit?.status === 'streaming' ||
                  unit?.status === 'offline' ||
                  dnd === true ||
                  (isStreamToolActive && isStreamtoolsStreaming)
                }
              >
                <span className='on'>{t('ON')}</span>
                <span className='off'>{t('OFF')}</span>
              </label>
            </div>
          </div>

          {false && (
            <div className='zone-block d-flex flex-row justify-content-between align-items-center'>
              <div className='lbltxt '>{t('ZONE')}: </div>
              <div style={{ width: '200px' }}>
                <Dropdown
                  name='selectedStreamToolZone'
                  placeholder='None Selected'
                  fluid
                  selection
                  onChange={onZoneUpdate}
                  options={zonesOptions}
                  value={selectedZone}
                  loading={isUpdatingZone}
                  disabled={
                    !isStreamToolActive ||
                    unit?.status === 'streaming' ||
                    unit?.status === 'offline' ||
                    isUpdatingZone ||
                    dnd === true ||
                    (isStreamToolActive && isStreamtoolsStreaming)
                  } // disable when unit is streaming
                />
              </div>
            </div>
          )}
          <div className='border-top mt-2'></div>
          <div className='mt-3'>
            <StreamToolsProfile
              label='col-lg-3'
              filed='col-lg-9'
              isRenderingInCard={true}
            />
          </div>
          <div>
            <div className='mt-2 text-center'>
              <p>
                {t('STREAMTOOLSENGINE')}:
                <b>
                  {' '}
                  {isStreamToolActive && isStreamtoolsStreaming
                    ? t('RUNNING')
                    : t('NOTRUNNING')}
                </b>
              </p>
            </div>
            <div className='d-flex justify-content-center'>
              <Tooltip
                title={
                  dnd === true && 'Will be available after streaming is started'
                }
              >
                <div className='lrtservices mt-2'>
                  <Link
                    to={`/dashboard/units/${unit.BOSSID}/streamtoolspreview`}
                    className='add-lrt btn btn-primary'
                    title={t('STREAMTOOLSCONTROLPANEL')}
                    disabled={
                      dnd === true ||
                      !(isStreamToolActive && streamtoolSubscribed)
                    }
                  >
                    <b>{t('STREAMTOOLSCONTROLPANEL')}</b>
                  </Link>
                </div>
              </Tooltip>
            </div>
          </div>
          <p className='f-size-12 mt-2 text-center'>{t('STREAMTOOLSTART')}</p>
        </div>
      );
    }

    return (
      <>
        <div className=' d-flex justify-content-around align-items-center flex-column'>
          <div className='text-center mt-4'>
            <span>{t('STREAMTOOLINFO')}</span>
            <span>&nbsp;</span>

            <span>
              <a
                href='https://www.liveu.tv/products/create/liveu-solo/solo-stream-tools'
                target='_blank'
                rel='noreferrer'
              >
                {t('CLKHERE')}
              </a>
            </span>
            <span>&nbsp;</span>
            <span>{t('STREAMTOOLINFO-2')}</span>
          </div>
          <Button
            onClick={() => {
              history.push(
                `/dashboard/units/payment/streamtool/${unit.BOSSID}`,
              );
            }}
            className='btn btn-primary mt-4 '
            // disabled={unit.status === 'streaming'}
            style={{ color: 'white' }}
          >
            <b>{t('ADDSTREAMTOOLS')}</b>
          </Button>
        </div>
        {/* <Link
          to={`/dashboard/units/payment/streamtool/${unit.BOSSID}`}
          className='btn btn-primary'
          title={t('ADDSTREAMTOOLS')}
        >
          <b>{t('ADDSTREAMTOOLS')}</b>
        </Link> */}
      </>
    );
  };

  return (
    <div className='col-md-4 unitBlk_cont flex-column svunit-blocks'>
      <div className='sub-title'>
        <h4>
          {t('STREAMTOOL')}{' '}
          <sup>
            <Tag
              style={{
                color: 'rgb(228 73 32 / 88%)',
                border: 'dotted 1.5px',
                fontSize: '10px',
              }}
            >
              {t('BETA')}
            </Tag>
          </sup>
        </h4>
      </div>
      <div className='block-wrap'>{handleStreamtoolCard()}</div>
    </div>
  );
};

export default StreamToolsDashBoardCard;
