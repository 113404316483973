import { vimeoConstants } from '../constants';

const initialState = {
  vimeoLoading: true,
  isPrivate: false,
  vimeo: {
    auth: {},
    create: {},
    domain: [],
    update: {},
    user: {},
    eventStatus: {},
    events: { isLoading: false, exists: false, list: [] },
  },
  authIsLoading: true,
};
const {
  CREATE_VIM_STREAM,
  CREATE_VIM_STREAM_FAIL,
  ADD_VIM_DOMAIN_FAIL,
  ADD_VIM_DOMAIN,
  UPDATE_VIM_STREAM_FAIL,
  UPDATE_VIM_STREAM,
  VIM_USER_DETAILS_REQ,
  VIM_USER_DETAILS_FAIL,
  VIM_USER_DETAILS,
  GET_VIM_TOKEN_FAIL,
  GET_VIM_TOKEN,
  GET_VIM_TOKEN_REQ,
  GET_VIMEO_EVENTS,
  GET_VIMEO_EVENTS_FAIL,
  GET_VIMEO_EVENTS_REQ,
  VIMEO_EVENTS_EXISTS,
  UPDATE_STREAM_STATUS,
  UPDATE_STREAM_STATUS_FAIL,
  PRIVATE_ENABLED,
  DELETE_EVENTS_SUCCESS,
} = vimeoConstants;

export function vimeo(state = initialState, payload) {
  switch (payload.type) {
    case PRIVATE_ENABLED:
      return { ...state, isPrivate: true };
    case GET_VIM_TOKEN_REQ:
      return { ...state, authIsLoading: true };
    case GET_VIM_TOKEN_FAIL:
      return { ...state, authIsLoading: false };
    case GET_VIM_TOKEN:
      return { ...state, vimeo: { ...state.vimeo, auth: payload.AuthData } };
    case VIM_USER_DETAILS_FAIL:
      return { ...state, vimeo: { ...state.vimeo, user: payload.error } };
    case VIM_USER_DETAILS:
      return {
        ...state,
        vimeo: { ...state.vimeo, user: payload.createData },
        vimeoLoading: false,
      };
    case VIM_USER_DETAILS_REQ:
      return { ...state, vimeoLoading: true };
    case CREATE_VIM_STREAM_FAIL:
      return { ...state, vimeo: { ...state.vimeo, create: payload.error } };
    case CREATE_VIM_STREAM:
      return {
        ...state,
        vimeo: { ...state.vimeo, create: payload.createData },
      };
    case UPDATE_VIM_STREAM:
      return {
        ...state,
        vimeo: { ...state.vimeo, update: payload.createData },
      };
    case UPDATE_VIM_STREAM_FAIL:
      return { ...state, vimeo: { ...state.vimeo, update: payload.error } };
    case ADD_VIM_DOMAIN:
      return {
        ...state,
        vimeo: { ...state.vimeo, domain: payload.createData },
      };
    case ADD_VIM_DOMAIN_FAIL:
      return { ...state, vimeo: { ...state.vimeo, domain: payload.error } };
    case VIMEO_EVENTS_EXISTS:
      return {
        ...state,
        vimeo: {
          ...state.vimeo,
          events: {
            ...state.vimeo.events,
            exists: payload.Data,
            isLoading: false,
          },
        },
      };
    case GET_VIMEO_EVENTS_REQ:
      return {
        ...state,
        vimeo: {
          ...state.vimeo,
          events: { ...state.vimeo.events, isLoading: payload.Data },
        },
      };
    case GET_VIMEO_EVENTS_FAIL:
      return {
        ...state,
        vimeo: {
          ...state.vimeo,
          events: { ...state.vimeo.events, isLoading: false, list: [] },
        },
      };
    case GET_VIMEO_EVENTS:
      return {
        ...state,
        vimeo: {
          ...state.vimeo,
          events: { ...state.vimeo.events, list: [...payload.Data] },
        },
      };
    case UPDATE_STREAM_STATUS_FAIL:
      return {
        ...state,
        vimeo: { ...state.vimeo, eventStatus: payload.error },
      };
    case UPDATE_STREAM_STATUS:
      return { ...state, vimeo: { ...state.vimeo, eventStatus: payload.Data } };
    case DELETE_EVENTS_SUCCESS:
      let newList = state.vimeo.events.list.filter(
        (val) => val.uri.split('/')[2] !== payload.destinationId,
      );
      return {
        ...state,
        vimeo: {
          ...state.vimeo,
          events: {
            ...state.vimeo.events,
            list: [...newList],
            exists: newList.length > 0 ? true : false,
          },
        },
      };
    default:
      return state;
  }
}
