import {
  _getPeriscopeRegion,
  _createTwitterBroadcast,
  _publishTwitterBroadcast,
  _stopTwitterBroadcast,
  _getTwitterOAuthToken,
  _getTwitterSources,
  _getTwitterRegion,
  _twitterAccessToken,
  _twitterCreateSource,
  _twitterDeleteSource,
} from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();
export const periscopeTwitterService = {
  twitterAccessToken,
  getTwitterOAuthToken,
  getPeriscopeRegion,
  createTwitterBroadcast,
  publishTwitterBroadcast,
  stopTwitterBroadcast,
  getTwitterRegion,
  getTwitterSources,
  twitterCreateSource,
  deleteTwitterSource,
};

async function twitterCreateSource(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _twitterCreateSource(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
async function twitterAccessToken(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _twitterAccessToken(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
async function getTwitterOAuthToken() {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _getTwitterOAuthToken(awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getTwitterRegion() {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _getTwitterRegion(awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getTwitterSources() {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _getTwitterSources(awsHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function deleteTwitterSource(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _twitterDeleteSource(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getPeriscopeRegion() {
  const periscopeHeaders = {
    Authorization: 'Bearer ' + cookies.get('periscope_access_token'),
  };
  try {
    const res = await _getPeriscopeRegion(periscopeHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function createTwitterBroadcast(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _createTwitterBroadcast(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function publishTwitterBroadcast(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const res = await _publishTwitterBroadcast(awsHeaders, payload);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function stopTwitterBroadcast(payload) {
  const periscopeHeaders = {
    Authorization: 'Bearer ' + cookies.get('periscope_access_token'),
  };
  try {
    const res = await _stopTwitterBroadcast(payload, periscopeHeaders);
    return res.data.data.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
