import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Button, Result } from 'antd';
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, reloadKey: 0 };
  }
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log('error', error, errorInfo);
    //report error boundary errors to rollbar
    const rollbarObj = !!window.rollbarObj ? window.rollbarObj : null;
    !!rollbarObj &&
      rollbarObj.debug(`Captured in Error boundary: ${error}`, errorInfo);
  }
  retry = () => {
    const { resetState } = this.props;
    const { reloadKey } = this.state;
    this.setState({ reloadKey: reloadKey + 1 });
    if (resetState !== undefined) {
      this.setState({ resetState: !resetState });
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: false });
    }
  };
  forceRetry = () => {
    window.location.reload(true);
    this.setState({ reloadKey: 0 });
  };

  render() {
    const { reloadKey, hasError } = this.state;
    if (hasError) {
      return (
        <Result
          status='warning'
          title='Something went wrong!'
          subTitle='Please retry.'
          extra={[
            <Button type='primary' key='console' onClick={this.retry}>
              Retry
            </Button>,
            reloadKey === 4 && (
              <Button type='primary' key='reload' onClick={this.forceRetry}>
                Reload
              </Button>
            ),
          ]}
        ></Result>
      );
    }

    return this.props.children;
  }
}
const ConnectedErrorBoundary = connect(
  null,
  null,
)(withTranslation()(ErrorBoundary));
export { ConnectedErrorBoundary as ErrorBoundary };
