import {
  _createCoupon,
  _referralCodeVerify,
  _listof_referralcode,
} from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const referralService = {
  getreferralCode,
  referralCodeVerify,
  getreferralCodeList,
};
async function getreferralCode(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const getRef = await _createCoupon(awsHeaders, payload);
    return getRef;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function referralCodeVerify(payload) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const isValidReferral = await _referralCodeVerify(awsHeaders, payload);
    return isValidReferral.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getreferralCodeList() {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const getRef = await _listof_referralcode(awsHeaders);
    return getRef;
  } catch (e) {
    return Promise.reject(e);
  }
}
