import orderBy from 'lodash/orderBy';
import {
  getLUCVideoStatus,
  updateLUCUnitById,
  getLUCUnitByUser,
  getLUCUnitStatus,
  getUnitDelay,
  _startUnit,
  _stopUnit,
  _getChannelServicesAndDestination,
  _changeZone,
  _registerLUCUnit,
  _getInterfaces,
  _getInterfaceDetails,
  _editInterfaceDetails,
  _editWifiDetails,
  _rebootLUCUnit,
  _removeUnit,
  _sendUnitLogs,
  _clearAccessToken,
} from '../api/luc.api';
import {
  _addUnitSettings,
  _getUnitSettings,
  nStreamToolDestincations,
  _canAvailLoyaltyCredit,
} from '../api/aws.api';
import Cookies from 'universal-cookie';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import { history } from '../history';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

const { REACT_APP_ApplicationId } = process.env;

export const unitService = {
  getUnits,
  updateUnits,
  updateUnitName,
  getVideoDetails,
  LiveUSoloAPIUnitDelay,
  startUnit,
  stopUnit,
  getChannelServicesAndDestination,
  changeZone,
  registerUnit,
  getInterfaces,
  getInterfaceDetails,
  editInterfaceDetails,
  editWifiDetails,
  getUnitsName,
  addUnitSettings,
  getUnitSettings,
  rebootLUCUnit,
  removeUnit,
  streamToolDestinations,
  canAvailLoyaltyCredit,
  getUserUnits,
  sendUnitLogs,
  clearAccessToken,
};
async function rebootLUCUnit(bossID) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    return await _rebootLUCUnit(lucHeaders, bossID);
  } catch (err) {
    return Promise.reject(err);
  }
}
async function status(id) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    return await getLUCUnitStatus(lucHeaders, id);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function canAvailLoyaltyCredit(unitId) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await _canAvailLoyaltyCredit(awsHeaders, unitId);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getUserUnits() {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };

    const res = await getLUCUnitByUser(lucHeaders);
    return { _units: res.data.data.units ? res.data.data.units : [] };
  } catch {}
}

async function getUnits() {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  const status_promises = [];
  const details_promise = [];
  try {
    const res = await getLUCUnitByUser(lucHeaders);
    const units = res.data.data.units ? res.data.data.units : [];
    const _units = units.map((unit) => {
      if (unit.status !== 'offline') {
        status_promises.push(status(unit.BOSSID));
        details_promise.push(getChannelServicesAndDestination(unit.BOSSID));
      }

      if (unit.sw_version) {
        if (
          (unit.sw_version.substr(0, 1) === 'v' &&
            parseFloat(unit.sw_version.substr(1, 3)) >= 6.0) ||
          parseFloat(unit.sw_version.substr(0, 3)) >= 6.0
        ) {
          unit.mode = true;
        }
      }
      if (unit.status === 'idle') {
        unit.status = 'connected';
      }

      switch (unit.status.toLowerCase()) {
        case 'streaming':
          unit.priority = 0;
          break;
        case 'transcoding':
          unit.priority = 1;
          break;
        case 'starting transcoder':
          unit.priority = 2;
          break;
        case 'connected':
          unit.priority = 3;
          break;
        case 'offline':
          unit.priority = 4;
          break;
        default:
          unit.priority = 5;
          break;
      }

      return unit;
    });

    const sortedUnits = orderBy(_units, ['priority', 'name'], ['asc', 'asc']);
    return { _units: sortedUnits, status_promises, details_promise };
  } catch (err) {
    return Promise.reject(err);
  }
}

async function updateUnits() {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await getLUCUnitByUser(lucHeaders);
    const { units } = res.data.data;
    const _units = units.map((unit) => {
      if (unit.status === 'idle') {
        unit.status = 'connected';

        cookies.set(`status_${unit.BOSSID}`, 0, {
          path: '/',
          maxAge: 60 * 60 * 24 * 365,
          secure: true,
        });
      } else if (unit.status === 'streaming') {
        unit.status = 'streaming';
      }
      return unit;
    });
    return _units;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function updateUnitName(id, payload) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await updateLUCUnitById(lucHeaders, id, payload);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getVideoDetails(id, path) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await getLUCVideoStatus(lucHeaders, id, path);
    return res.data;
  } catch (err) {
    if (err?.response?.status === 403) {
      history.push('/dashboard');
    }
    return Promise.reject(err);
  }
}

async function LiveUSoloAPIUnitDelay(unit_id, payload) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    return await getUnitDelay(payload, unit_id, lucHeaders);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function startUnit(id, payload) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    return await _startUnit(lucHeaders, id, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function stopUnit(id) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    return await _stopUnit(lucHeaders, id);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getChannelServicesAndDestination(id) {
  // const headers = {
  //     'Authorization': `Bearer ${cookies.get('access_token')}`,
  //     'Application-Id': REACT_APP_ApplicationId
  // }
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await _getChannelServicesAndDestination(lucHeaders, id);
    return res.data.data.unit;
  } catch (err) {
    if (err?.response?.status === 403) {
      history.push(`/dashboard`);
    }
    return Promise.reject(err);
  }
}

async function changeZone(id, payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    return await _changeZone(awsHeaders, id, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function registerUnit(sn) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const payload = {
      reg_code: sn,
    };
    const res = await _registerLUCUnit(
      lucHeaders,
      payload,
      cookies.get('inventory_id'),
    );
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getInterfaces(id) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _getInterfaces(lucHeaders, id);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getInterfaceDetails(id, path, port, subpath) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _getInterfaceDetails(lucHeaders, id, path, port, subpath);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function editInterfaceDetails(payload, id, path, port, subpath) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _editInterfaceDetails(
      lucHeaders,
      payload,
      id,
      path,
      port,
      subpath,
    );
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function editWifiDetails(payload, id, path, port, subpath, ssid) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _editWifiDetails(
      lucHeaders,
      payload,
      id,
      path,
      port,
      subpath,
      ssid,
    );
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getUnitsName() {
  const lucHeaders = {
    Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    'Application-Id': REACT_APP_ApplicationId,
  };
  try {
    const res = await getLUCUnitByUser(lucHeaders);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function addUnitSettings(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _addUnitSettings(awsHeaders, payload);
    return res?.data?.data?.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getUnitSettings() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await _getUnitSettings(awsHeaders);
    return res?.data?.data?.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function removeUnit(id) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const payload = {
      applicationId: 'lusDcCdW5MGE1ZMAnPjibsTMNx5OgdIC',
    };
    notify('success', i18n.t('REMOVESUCCESS'));
    return await _removeUnit(lucHeaders, id, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function streamToolDestinations(bossID) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    const res = await nStreamToolDestincations(awsHeaders, bossID);
    return res?.data?.data?.response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function sendUnitLogs(id, payload) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _sendUnitLogs(lucHeaders, id, payload);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function clearAccessToken() {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
    };
    const res = await _clearAccessToken(lucHeaders);
    return res?.data?.data?.response;
  } catch (err) {
    return Promise.reject(err);
  }
}
