import { createSelector } from 'reselect';
import { history } from '../history';

const awsDestinationSelector = (state) => state.destination.awsDestinations;
const lucDestinationSelector = (state) => state.destination.lucDestinations;
const selectedDestinationSelector = (state) =>
  state.destination.selectedDestination;
const ovp_credentialsSelector = (state) =>
  state.authentication.user.ovpCredentials;
const unitSelector = (state) => state.unit.unit;
const providerSelector = (state) => state.destination.providers;
const unitDetailSelector = (state) => state.destination.unitDetails;

export const getActiveDestinations = createSelector(
  awsDestinationSelector,
  lucDestinationSelector,
  (awsdests, lucdests) => {
    return awsdests.filter((awsdest) => {
      let flag = false;
      if (lucdests.length) {
        // eslint-disable-next-line array-callback-return
        lucdests.map((lucdest) => {
          if (
            lucdest.streaming_destination.external_id === awsdest.d_id ||
            awsdest.unit_id
          ) {
            //check if destination exists in LUC list
            if (lucdest.streaming_destination.external_id === awsdest.d_id) {
              awsdest.id = lucdest.id;
            }
            flag = true;
          }
        });
      } else {
        flag = awsdest.unit_id ? true : false;
      }
      awsdest.isChecked = false;
      return flag;
    });
  },
);

export const getSavedDestinations = createSelector(
  awsDestinationSelector,
  lucDestinationSelector,
  (awsdests, lucdests) => {
    return awsdests.filter((awsdest) => {
      let flag = false;
      if (lucdests.length) {
        // eslint-disable-next-line array-callback-return
        lucdests.map((lucdest) => {
          if (
            lucdest.streaming_destination.external_id !== awsdest.d_id ||
            !awsdest.unit_id
          ) {
            flag = true;
          }
        });
      } else {
        flag = awsdest.unit_id ? false : true;
      }
      awsdest.isChecked = false;
      return flag;
    });
  },
);

export const getselectedDestination = createSelector(
  selectedDestinationSelector,
  (selectedDestination) => {
    return selectedDestination;
  },
);

export const ensureWowzaToken = createSelector(
  ovp_credentialsSelector,
  unitSelector,
  (ovpCredentials, unit) => {
    let parsed_ovp_cred = {};
    if (ovpCredentials !== '') {
      parsed_ovp_cred = JSON.parse(ovpCredentials);
      return parsed_ovp_cred[`${unit.BOSSID}_wowza`];
    } else {
      return (parsed_ovp_cred[`${unit.BOSSID}_wowza`] = {});
    }
  },
);

export const getProfilesAndAuthUser = createSelector(
  providerSelector,
  (providers) => {
    let streamingProv;
    const {
      location: { pathname },
    } = history;
    if (
      pathname.split('/')[2] === 'rtmp' ||
      pathname.split('/')[2] === 'editrtmp'
    ) {
      streamingProv = pathname.split('/')[3];
    } else {
      let prov = pathname.split('/')[2];
      if (prov === 'periscope') {
        streamingProv = 'Periscope and Twitter';
      }
    }

    const provider = providers.filter((prov) => prov.name === streamingProv);
    if (provider.length) {
      provider[0].streaming_profiles.filter((profile) => {
        profile.label = profile.name;
        profile.value = profile.name;
        return profile;
      });
      provider[0].authUser = /(username|password)/g.test(provider[0].template)
        ? true
        : false;
      return provider[0];
    }
  },
);

export const versionCheck = createSelector(unitDetailSelector, (unit) => {
  if (unit.sw_version) {
    if (
      (unit.sw_version.substr(0, 1) === 'v' &&
        parseFloat(unit.sw_version.substr(1, 3)) >= 6.0) ||
      parseFloat(unit.sw_version.substr(0, 3)) >= 6.0
    ) {
      return (unit.bitrateDisabled = false);
    } else {
      return (unit.bitrateDisabled = true);
    }
  }
});
