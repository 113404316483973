import React, { Component } from 'react';
import Select from 'react-select';
import filter from 'lodash/filter';
import countryList from '../../data/Country.json';

export class CountryDropdown extends Component {
  state = {
    countries: [],
  };
  componentDidMount() {
    this.setState({
      countries: countryList,
    });
  }
  render() {
    const { countries } = this.state;
    const { handleCountryChange, onblur, defaultSelectedValue, value } =
      this.props;
    function getCountry(item) {
      const result = filter(countries, function (o) {
        return item.label === o.label;
      });
      return result[0];
    }
    return (
      <Select
        className={
          value.label ? 'select-form-control success' : 'select-form-control'
        }
        options={countries}
        onChange={handleCountryChange}
        onBlur={onblur}
        value={defaultSelectedValue ? getCountry(defaultSelectedValue) : value}
      />
    );
  }
}
