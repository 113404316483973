import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component {
  render() {
    const { videoUrl } = this.props;
    return (
      <div title='sample'>
        <ReactPlayer

          url={videoUrl}
          controls={true}
          width={'300'}
          height={'170'}
          config={{
            youtube: {
              playerVars: {
                livemonitor: 1
              }
            }
          }}
        />
      </div>
      
    );
  }
}
const ConnectedVideoPlayer = connect(
  null,
  null,
)(withTranslation()(VideoPlayer));
export { ConnectedVideoPlayer as VideoPlayer };
