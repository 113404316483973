import axios from 'axios';
import { userActions } from './actions';
import i18n from 'i18next';
import { notify } from './components/CommonNotification/CommonNotification';
import Cookies from 'universal-cookie';
import { removeLocalStorageItem } from './utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export default {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          const { status } = error.response;
          const { url } = error.response.config;

          if (status === 401) {
            if (url.toLowerCase().indexOf('liveu.tv') !== -1) {
              notify('error', i18n.t('SESSIONEXPIRED'));
              removeLocalStorageItem('access_token');
              cookies.remove('username', { path: '/' });
              cookies.remove('user_session', { path: '/' });
              store.dispatch(userActions.logout());
            }
          }
        }

        return Promise.reject(error);
      },
    );
  },
};
