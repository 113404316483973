import { easyliveConstants, commonConstants } from '../constants';
import { easyLiveService } from '../services';
import { errorBlock } from '../api/errorBlock';
import { history } from '../history';
import { commonAction } from './common.actions';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// const MASTER_ACCESS_TOKEN = "VNWnAxqyiP9FnMCUFskdfHLkAKpFPiRUKTKAmvbULjb9hegNwmn47dkkLM9Arkhc";
export const easyLiveAction = {
  getELToken,
  getELProfile,
  getELExisitng,
  checkStream,
  
  showSpinner,
  hideSpinner
};
/**
 * @name getELToken
 * @params redirect,code,bossID
 * @descriptiton gets a EasyLive token
 **/
function getELToken(redirect, code, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const getELTok = await easyLiveService.getELToken(redirect, code);
      let d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      cookies.set('el_access_token', getELTok.access_token, {
        path: '/',
        expires: d,
        secure: true,
      });
      dispatch(success(getELTok));
      history.push('/dashboard/easylive/' + getELTok.state);
      dispatch(getELProfile('about_me'));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function request() {
    return { type: easyliveConstants.EL_GET_TOKEN_REQ };
  }

  function success(elAuthData) {
    return { type: easyliveConstants.EL_GET_TOKEN, elAuthData };
  }

  function failure(error) {
    return { type: easyliveConstants.EL_GET_TOKEN_FAIL, error };
  }
}
/**
 * @name getELToken
 * @params query parameter,bossID
 * @descriptiton gets EasyLive Profile
 **/
function getELProfile(fType, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const profile = await easyLiveService.getEasyLiveProf(fType);
      dispatch(success(profile));
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function request() {
    return { type: easyliveConstants.EL_GET_TOKEN_REQ };
  }

  function success(elProfData) {
    return { type: easyliveConstants.EL_GET_PROFILE, elProfData };
  }

  function failure(error) {
    return { type: easyliveConstants.EL_GET_PROFILE_FAIL, error };
  }
}
/**
 * @name getELExisitng
 * @params query parameter,ZoneId,bossID
 * @descriptiton get existing streams
 **/
function getELExisitng(fType, elZone, bossId) {
  return async (dispatch) => {
    try {
      dispatch(request());
      const profile = await easyLiveService.getEasyLiveProf(fType, elZone);
      if (profile.list.length) {
        dispatch(streamExists());
        dispatch(success(profile.list));
      } else {
        dispatch(streamExistsFalse());
        dispatch(success([]));
        notify('warning', i18n.t('ELNOEXISTR'));
      }
    } catch (e) {
      dispatch(failure(e.toString()));
      errorBlock(e, bossId);
    }
  };

  function request() {
    return { type: easyliveConstants.EL_GET_EXISTING_STREAMS_REQ };
  }

  function success(elExistData) {
    return { type: easyliveConstants.EL_GET_EXISTING_STREAMS, elExistData };
  }

  function failure(error) {
    return { type: easyliveConstants.EL_GET_EXISTING_STREAMS_FAIL, error };
  }
  function streamExists() {
    return { type: easyliveConstants.EL_STREAM_EXISTS_TRUE };
  }
  function streamExistsFalse() {
    return { type: easyliveConstants.EL_STREAM_EXISTS_FALSE };
  }
}
/**
 * @name checkStream
 * @params values,bossID
 * @descriptiton checks if option selected is "create" or "existing"
 **/
function checkStream(values, bossId) {
  return async (dispatch) => {
    try {
      dispatch(showSpinner());
      const payload = {
        destinationName: values.destination,
        selectedProfile: values.profile.name,
        primary_url:
          values.streamGroup === 'existing'
            ? values.existingStream.streamIngest
            : '',
        secondary_url: '',
        stream_name:
          values.streamGroup === 'existing'
            ? values.existingStream.streamName
            : '',
        username: '',
        password: '',
        id: guid(),
        streaming_provider: 'Easy Live',
        overrideResolution: values.resolutionBox
          ? values.overrideResolution.value
          : '',
        overrideFramerate: values.frameRateBox
          ? values.overrideFrame.value
          : '',
        overrideBitrate: values.bitRateBox ? values.overrideBitrate : '',
        audio_bitrate_override: values.audioBitRateBox ? values.audioBitRate?.value : null
      };
      if (values.streamGroup === 'existing') {
        dispatch(commonAction.createAwsDestination(payload, bossId));
      } else {
        dispatch(
          elCreateStream(values.zone.id, values.streamName, payload, bossId),
        );
      }
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
    }
  };
  function showSpinner() {
    return { type: commonConstants.OTHER_LOADING };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name guid
 * @params none
 * @descriptiton creates 16 char unique string
 **/
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
/**
 * @name elCreateStream
 * @params zoneid, streamname, form values , bossID
 * @descriptiton create easylive stream
 **/
function elCreateStream(zoneId, streamName, elPayload, bossId) {
  return async (dispatch) => {
    try {
      const createStream = await easyLiveService.getEasyLiveProf(
        'create_stream',
        zoneId,
        streamName,
      );
      elPayload.stream_name = createStream.rtmp.stream_name;
      elPayload.primary_url = createStream.rtmp.url.replace(
        createStream.rtmp.stream_name,
        '',
      );
      dispatch(createSuccess(createStream));
      dispatch(commonAction.createAwsDestination(elPayload, bossId));
    } catch (e) {
      dispatch(hideSpinner());
      if (
        !!e.response &&
        !!e.response.data &&
        !!e.response.data.errors[0] &&
        e.response.data.errors[0].message.match(/limit_assets/g)
      ) {
        notify('error', i18n.t('ELLIMITASSESTS'));
        dispatch(elLimit());
      } else {
        dispatch(failure(e.toString()));
        errorBlock(e, bossId);
      }
    }
  };

  function createSuccess(createData) {
    return { type: easyliveConstants.EL_CREATE_STREAM, createData };
  }

  function failure(error) {
    return { type: easyliveConstants.EL_CREATE_STREAM_FAIL, error };
  }
  function elLimit() {
    return { type: easyliveConstants.EL_STREAM_LIMIT };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}


function showSpinner() {
  return { type: commonConstants.OTHER_LOADING };
}

function hideSpinner() {
  return { type: commonConstants.OTHER_LOADING_FALSE };
}