export const vimeoConstants = {
  VIM_USER_DETAILS_REQ: 'VIM_USER_DETAILS_REQ',
  VIM_USER_DETAILS: 'VIM_USER_DETAILS',
  VIM_USER_DETAILS_FAIL: 'VIM_USER_DETAILS_FAIL',
  CREATE_VIM_STREAM: 'CREATE_VIM_STREAM',
  CREATE_VIM_STREAM_FAIL: 'CREATE_VIM_STREAM_FAIL',
  UPDATE_VIM_STREAM: 'UPDATE_VIM_STREAM',
  UPDATE_VIM_STREAM_FAIL: 'UPDATE_VIM_STREAM_FAIL',
  ADD_VIM_FOLLOWER: 'ADD_VIM_FOLLOWER',
  ADD_VIM_FOLLOWER_FAIL: 'ADD_VIM_FOLLOWER_FAIL',
  ADD_VIM_DOMAIN: 'ADD_VIM_DOMAIN',
  ADD_VIM_DOMAIN_FAIL: 'ADD_VIM_DOMAIN_FAIL',
  GET_VIM_FOLLOWERS: 'GET_VIM_FOLLOWERS',
  GET_VIM_FOLLOWERS_FAIL: 'GET_VIM_FOLLOWERS_FAIL',
  UPDATE_STREAM_STATUS: 'UPDATE_STREAM_STATUS',
  UPDATE_STREAM_STATUS_FAIL: 'UPDATE_STREAM_STATUS_FAIL',
  GET_VIM_TOKEN_REQ: 'GET_VIM_TOKEN_REQ',
  GET_VIM_TOKEN: 'GET_VIM_TOKEN',
  GET_VIM_TOKEN_FAIL: 'GET_VIM_TOKEN_FAIL',
  GET_VIMEO_EVENTS_REQ: 'GET_VIMEO_EVENTS_REQ',
  GET_VIMEO_EVENTS: 'GET_VIMEO_EVENTS',
  VIMEO_EVENTS_EXISTS: 'VIMEO_EVENTS_EXISTS',
  GET_VIMEO_EVENTS_FAIL: 'GET_VIMEO_EVENTS_FAIL',
  PRIVATE_ENABLED: 'PRIVATE_ENABLED',
  DELETE_EVENTS_SUCCESS: 'DELETE_EVENTS_SUCCESS',
};
