export const restreamConstants = {
  RE_USER_DETAILS_REQ: 'RE_USER_DETAILS_REQ',
  RE_USER_DETAILS: 'RE_USER_DETAILS',
  RE_USER_DETAILS_FAIL: 'RE_USER_DETAILS_FAIL',
  RE_GET_PLATFORMS: 'RE_GET_PLATFORMS',
  RE_GET_PLATFORMS_FAIL: 'RE_GET_PLATFORMS_FAIL',
  RE_GET_USERPLATFORMS_FAIL: 'RE_GET_USERPLATFORMS_FAIL',
  RE_GET_USERPLATFORMS: 'RE_GET_USERPLATFORMS',
  RE_GET_ALLINGEST_FAIL: 'RE_GET_ALLINGEST_FAIL',
  RE_GET_ALLINGEST: 'RE_GET_ALLINGEST',
  RE_GET_INGEST_FAIL: 'RE_GET_INGEST_FAIL',
  RE_GET_INGEST: 'RE_GET_INGEST',
  RE_CHANNELS: 'RE_CHANNELS',
  RE_GET_STREAM_KEY: 'RE_GET_STREAM_KEY',
  RE_GET_STREAM_KEY_FAIL: 'RE_GET_STREAM_KEY_FAIL',
  RE_GET_TOKEN_REQ: 'RE_GET_TOKEN_REQ',
  RE_GET_TOKEN: 'RE_GET_TOKEN',
  RE_GET_TOKEN_FAIL: 'RE_GET_TOKEN_FAIL',
};
