import {
  _addLUCTerms,
  _getAdminMessage,
  _getCommunicationPreference,
  _setCommunicationPreference,
  liveuLogin,
  // zendeskLiveuLogin,
  _updateEmail,
} from '../api/aws.api';
import {
  _changeLUCPassword,
  _resetLUCPassword,
  _updateLUCPassword,
  _emailChangeRequest,
  _emailChangeVerification,
  _verifyLUCUser,
  getLUCUserDetails,
  getLUCUserInventories,
  _registerLUCUser,
} from '../api/luc.api';
import Cookies from 'universal-cookie';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

const { REACT_APP_ApplicationId } = process.env;

export const userService = {
  login,
  registerLUCUser,
  userInfo,
  resetLUCPassword,
  verifyLUCUser,
  updateLUCPassword,
  addLUCTerms,
  getAdminMessage,
  changeLUCPassword,
  changeLUCEmail,
  setCommunicationPreference,
  getCommunicationPreference,
  emailChangeVerification,
  updateEmail,
};

async function storeData(liveu_token) {
  try {
    let d = new Date();
    d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
    setLocalStorageItem('access_token', liveu_token);
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const inventory_res = await getLUCUserInventories(lucHeaders);
    cookies.set('inventory_id', inventory_res.data[0].erpId, {
      path: '/',
      expires: d,
      secure: true,
    });
    cookies.set('lucUserId', inventory_res.data[0].dbId, {
      path: '/',
      expires: d,
      secure: true,
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
async function getAdminMessage() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await _getAdminMessage(awsHeaders);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function login(username, password) {
  // const { REACT_APP_environment } = process.env;
  let d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);

  cookies.set('user_session', guid(), {
    path: '/',
    expires: d,
    secure: true,
  });
  const headers = {
    Authorization: 'Basic ' + window.btoa(username + ':' + password),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };

  cookies.set('username', username.toLowerCase(), {
    path: '/',
    expires: d,
    secure: true,
  });
  try {
    // if (REACT_APP_environment === 'production') {
    //   const return_to = `${window.location.protocol}//${
    //     window.location.hostname
    //   }${
    //     window.location.port ? `:${window.location.port}` : ``
    //   }/dashboard/units`;
    //   const login_res = await zendeskLiveuLogin(headers, return_to);
    //   await storeData(login_res.data.data.response.access_token);
    //   cookies.set('sso_access_token', login_res.data.data.response.sso_token, {
    //     path: '/',
    //     expires: d,
    //     secure: true,
    //   });
    //   var redirect_url = `${login_res.data.data.response.redirect_url}`;
    // } else {
    const login_res = await liveuLogin(headers);
    await storeData(login_res.data.data.credentials.access_token);
    // }
    return Promise.resolve(undefined);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function userInfo() {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await getLUCUserDetails(lucHeaders);
    const user = res.data;
    return user;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function resetLUCPassword(email) {
  try {
    const lucHeaders = {
      'Application-Id': REACT_APP_ApplicationId,
    };
    const payload = {
      email: email,
      'application ID': 'lusDcCdW5MGE1ZMAnPjibsTMNx5OgdIC',
    };
    const res = await _resetLUCPassword(lucHeaders, payload);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function registerLUCUser(user) {
  try {
    const lucHeaders = {
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _registerLUCUser(lucHeaders, user);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function verifyLUCUser(user) {
  try {
    const lucHeaders = {
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _verifyLUCUser(lucHeaders, user);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function updateLUCPassword(payload) {
  try {
    const lucHeaders = {
      'Application-Id': REACT_APP_ApplicationId,
    };
    const res = await _updateLUCPassword(lucHeaders, payload);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function addLUCTerms(payload) {
  try {
    const res = await _addLUCTerms(payload);
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function getCommunicationPreference() {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await _getCommunicationPreference(awsHeaders);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function setCommunicationPreference(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    if (payload.isLoggedIn)
      return await _setCommunicationPreference(awsHeaders, payload);
    else return await _setCommunicationPreference({}, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function updateEmail(payload) {
  try {
    const awsHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'x-user-uuid': `${cookies.get('user_session')}`,
    };
    return await _updateEmail(awsHeaders, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function changeLUCPassword(values) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const payload = {
      ...values,
      confirmNewPassword: values.newPassword,
      userName: cookies.get('username'),
      // 'application ID': 'lusDcCdW5MGE1ZMAnPjibsTMNx5OgdIC',
    };
    return await _changeLUCPassword(lucHeaders, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function changeLUCEmail(values) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const payload = {
      email: values.email,
    };
    return await _emailChangeRequest(lucHeaders, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function emailChangeVerification(token) {
  try {
    const lucHeaders = {
      Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
      'Application-Id': REACT_APP_ApplicationId,
    };
    const payload = {
      token: token,
    };
    const res = await _emailChangeVerification(lucHeaders, payload);
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

// async function removeUnit(id) {
//   try {
//     const lucHeaders = {
//       Authorization: `Bearer ${cookies.get('access_token')}`,
//       'Application-Id': REACT_APP_ApplicationId,
//     };
//     const payload = {
//       applicationId: 'lusDcCdW5MGE1ZMAnPjibsTMNx5OgdIC',
//     };
//     notify('success', i18n.t('REMOVESUCCESS'));
//     return await _removeUnit(lucHeaders, id, payload);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// }

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}
