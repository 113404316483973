import React from 'react';
import { Row, Col, Button, Upload, Icon, Skeleton, Tooltip } from 'antd';
import {
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import './streamtools.css';
import { Rnd } from 'react-rnd';
// import PropagateLoader from 'react-spinners/PropagateLoader';
// import { css } from '@emotion/core';

// const override = css`
//   top: -5px;
//   left: -5px;
// `;

const StreamToolsLogoUploadView = ({
  t,
  isLoading,
  isUploading,
  isClearing,
  imageUrl,
  dimenstions,
  change,
  clicked,
  addingLogo,
  url,
  handleLogoUpload,
  handleRemoveLogo,
  addWaterMark,
  dummyRequest,
  fileList,
  back,
  RND,
  outerBoxRef,
  innerBoxRef,
  changePosition,
  changeSize,
  // resizeStop,
}) => {
  const uploadButton = (
    <div>
      {isUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Row className='mb-3'>
          <Col span={15}>
            <div className='sub-title'>
              <h4>{t('LOGOGRAPHIC')}</h4>
            </div>
          </Col>

          <Col span={9}>
            <Button
              onClick={back}
              type='button'
              style={{ float: 'right' }}
              className='btn btn-primary'
            >
              Back
            </Button>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24} lg={16} className='mb-lg-0 mb-3'>
            <div id='outerBox' className='outerBox mb-3' ref={outerBoxRef}>
              {
                <>
                  <Rnd
                    size={{ width: RND.width, height: RND.height }}
                    style={{
                      border: !!url ? `1px solid black` : 'none',
                    }}
                    position={{ x: RND.x, y: RND.y }}
                    onDragStop={changePosition}
                    onResizeStop={changeSize}
                    // onResizeStop={resizeStop}
                    minWidth={50}
                    ref={innerBoxRef}
                    bounds={'parent'}
                    lockAspectRatio={true}
                    enableResizing={{
                      top: false,
                      right: false,
                      bottom: false,
                      left: false,
                      topRight: true,
                      bottomRight: true,
                      bottomLeft: true,
                      topLeft: true,
                    }}
                  >
                    {!!(url || imageUrl) && (
                      <img
                        src={imageUrl || url}
                        alt='Logo'
                        className='logo'
                        id='logoImage'
                      />
                    )}
                  </Rnd>

                  {!url && (
                    <div className='ml-3 mt-3'>
                      <Upload
                        disabled={isUploading}
                        listType='picture-card'
                        showUploadList={false}
                        customRequest={dummyRequest}
                        fileList={fileList}
                        onRemove={handleRemoveLogo}
                        accept='image/jpeg, image/jpg, image/png, image/gif'
                        onChange={handleLogoUpload}
                      >
                        {uploadButton}
                      </Upload>
                    </div>
                  )}
                </>
              }
              {isLoading && <Skeleton />}
            </div>
            <em>{t('DARGLOGO')}</em>
          </Col>
          <Col lg={8} span={24}>
            <div className='d-flex '>
              <Upload
                onChange={handleLogoUpload}
                customRequest={dummyRequest}
                disabled={isUploading}
                fileList={fileList}
                onRemove={handleRemoveLogo}
                accept='image/jpeg, image/jpg, image/png, image/gif'
                showUploadList={false}
              >
                <Button
                  icon={isUploading ? <CheckOutlined /> : null}
                  loading={isUploading}
                  type='primary'
                  className='btn-primary'
                >
                  {!isUploading && (
                    <span>
                      <Icon type='upload' />
                      <b className='p-1'>{t('UPLOADNEWLOGO')}</b>
                    </span>
                  )}
                  {isUploading && <b> {t('UPLOADING')} </b>}
                </Button>
              </Upload>
              <Tooltip
                title={'You can upload a *.png, *.jpg, *.gif, or *.apng file.'}
              >
                <i
                  type='info-circle'
                  className={`d-flex justify-content-center align-items-center profile-help-btn fa fa-question-circle fa-lg ml-2`}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
            {isLoading ? (
              <Skeleton />
            ) : (
              dimenstions.length > 0 && (
                <div className='lstcontainer'>
                  <div className='information-row'>
                    <span className='information'> {t('FILEINFO')} </span>
                    <div className='box topborder'>
                      <span id='width'>{dimenstions[0]}</span>
                    </div>
                    <div className='box-saparator topborder'>
                      <span> x </span>
                    </div>
                    <div className='box topborder'>
                      <span id='height'>{dimenstions[1]}</span>
                    </div>
                  </div>
                </div>
              )
            )}
            <hr></hr>
            <div className='d-flex align-items-center flex-wrap'>
              <Button
                onClick={handleRemoveLogo}
                disabled={!url}
                icon={isClearing ? <CheckOutlined /> : null}
                loading={isClearing}
                className='mb-2 mr-2'
              >
                {isClearing ? t('CLEARING') : t('CLEARLOGO')}
              </Button>
              <Button
                onClick={addWaterMark}
                disabled={!(url && change && dimenstions.length > 0)}
                icon={clicked ? <CheckOutlined /> : null}
                loading={addingLogo}
                className={`mb-2 ${
                  !(url && change && dimenstions.length > 0)
                    ? ''
                    : 'btn-primary'
                }`}
                type='primary'
              >
                {clicked
                  ? change
                    ? t('UPDATELOGO')
                    : t('LOGOADDED')
                  : t('ADDLOGO')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StreamToolsLogoUploadView;
