import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { notify } from '../CommonNotification/CommonNotification';
import profiles from '../../data/Profile.json';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { liveStreamServices } from '../../services';
import './StreamToolsProfile.css';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { destinationActions, liveStreamActions } from '../../actions';

const StreamToolsProfile = (props) => {
  const [streamtoolOptions, setOptions] = useState([]);
  const { t, i18n } = useTranslation();
  const { unit } = useSelector((state) => state.unit);
  const { providers } = useSelector((state) => state.destination);
  // const streamToolDestinations = useSelector(
  //   (state) => state.streamTools?.[unit?.BOSSID].selectedDestinations,
  // );
  // const profile = useSelector(
  //   (state) => state.streamTools?.[unit.BOSSID].profile,
  // );
  const {
    dnd,
    isStreamToolActive,
    isStreamtoolsStreaming,
    profile,
    selectedDestinations: streamToolDestinations,
  } = useSelector((state) => state.streamTools?.[unit?.BOSSID]);
  const profileRef = useRef();
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const dispatch = useDispatch();
  const profileOptions = () => {
    if (providers[0]) {
      let options = providers.filter(
        (prov) => prov.name.toLowerCase() === 'generic',
      );
      if (options.length !== 0) {
        let oldOptions = options;
        try {
          //to sort profiles in ascending order
          options[0].streaming_profiles = orderBy(
            options[0].streaming_profiles,
            [
              (val) => {
                const l1 = val.name.toLowerCase().indexOf('x');
                const newVal = val.name.slice(0, l1);
                return Number(newVal);
              },
            ],
            ['asc'],
          );
        } catch (e) {
          options = oldOptions;
        }
        let profileResult = null;
        // let result = null;
        try {
          profileResult = profiles.filter((obj1) =>
            options[0].streaming_profiles.some(
              (obj2) => obj1.value === obj2.name,
            ),
          );
          return profileResult
            .filter((profile) => profile.label === 'Standard Profiles')
            .map((profile) => {
              let t = { ...profile };
              t.label = t.value;
              return t;
            });
        } catch (e) {
          options = oldOptions;
        }
      } else {
        this.props.history.push({
          pathname: `/dashboard/edit/${unit.BOSSID}`,
        });
        notify('warning', i18n.t('STREAMINGPROVIDERNOTRETRIEVED'));
      }
    }
  };
  useEffect(() => {
    (async function () {
      if (!!!providers?.length) {
        await dispatch(destinationActions.getStreamingProviders());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    (async function () {
      const profileResult = profileOptions();
      setOptions(profileResult);
      if (profile && streamToolDestinations.length > 0) {
        profileRef?.current?.select?.setValue({
          label: profile,
          value: profile,
        });
      } else if (profileResult?.length > 0) {
        profileRef.current.select.setValue(profileResult[0]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);
  const modalToggle = () => {
    setIsProfileModalVisible((pre) => !pre);
  };
  const { label, filed, isRenderingInCard } = props;
  return (
    <>
      {isProfileModalVisible && !isRenderingInCard ? (
        <Modal isOpen={isProfileModalVisible} toggle={modalToggle}>
          <i className='fa fa-times' onClick={modalToggle}></i>
          <div className='modal-footer border-bottom'>
            <h3 className='w-100 text-left'>{t('STREAMTOOLPROFILE')}</h3>
          </div>
          {/* <div className="page-title mb-0"><h1></h1></div> */}
          <ModalBody>
            <p>{t('PROFILESETTINGSSTREAMTOOLENGINE')}</p>
            <p>{t('PROFILESETTINGSSELECTEDDESTINATIONS')}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              className='btn btn-primary btn-sm'
              data-dismiss='modal'
              aria-hidden='true'
              onClick={modalToggle}
            >
              {t('OK')}
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}

      <div
        className={`form-group row ${
          isRenderingInCard ? 'd-flex align-items-center' : ''
        }`}
      >
        <span className={label + ' pr-0'}>{`${t('PROFILE')} ${
          isRenderingInCard ? ':' : ''
        }`}</span>
        <div
          className={`${filed} d-flex ${isRenderingInCard ? 'pl-lg-0' : ''}`}
        >
          <Select
            className={`select-form-control success w-100`}
            name='streamToolsProfiles'
            options={streamtoolOptions}
            isDisabled={
              isRenderingInCard &&
              (unit?.status === 'streaming' ||
                unit?.status === 'offline' ||
                dnd ||
                !isStreamToolActive ||
                isStreamtoolsStreaming ||
                !(streamToolDestinations.length > 0))
            }
            onChange={(value) => {
              const { storeStreamToolsData } = liveStreamServices;
              storeStreamToolsData({
                profile: value.value,
                bossId: unit.BOSSID,
              });
              dispatch(
                liveStreamActions.setStreamToolsProfile(
                  value.value,
                  unit.BOSSID,
                ),
              );
            }}
            ref={profileRef}
            // isDisabled={!isThisDestinationIsStreamToolsPrimaryDestination()}
          />
          {!isRenderingInCard && (
            <i
              type='info-circle'
              className={`d-flex justify-content-center align-items-center profile-help-btn fa fa-question-circle fa-lg`}
              onClick={modalToggle}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StreamToolsProfile;
