//import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './i18n';
import { Provider } from 'react-redux';
import MainApp from './MainApp';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';

import NetworkService from './NetworkService';
import PiwikPro from '@piwikpro/react-piwik-pro';

NetworkService.setupInterceptors(store);
require('dotenv').config();

PiwikPro.initialize(
  'bc3aa383-d16a-4e02-810f-402aa80488e4',
  'https://liveu.containers.piwik.pro',
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
