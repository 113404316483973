import i18n from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { errorBlock } from '../../api/errorBlock';
import { userActions } from '../../actions';
import GoSoloBlack from '../../assets/images/go-solo-black.png';
// we can use this for redirect page
import { history } from '../../history';
import { AppVersion } from '../AppVersion/AppVersion';
import { LangDropdown } from '../LangDropdown';
import Cookies from 'universal-cookie';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

class UpdateEmail extends Component {
  state = {
    currentStatusMessage: i18n.t('VERIFYINGEMAIL'),
    success: false,
  };

  removeCookies = () => {
    cookies.remove('username_update_path', { path: '/' });
    removeLocalStorageItem('access_token');
  };

  async componentDidMount() {
    const { verifyEmail, updateEmail, match, location } = this.props;
    try {
      if (!getLocalStorageItem('access_token')) {
        let d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        cookies.set('username_update_path', location.pathname, {
          path: '/',
          expires: d,
          secure: true,
        });
        history.push('/login');
      } else {
        cookies.remove('username_update_path', { path: '/' });
        const token = match?.params?.token;
        if (token) {
          const verifiedEmail = await verifyEmail(token);
          if (verifiedEmail?.email) {
            const result = await updateEmail(verifiedEmail?.email);
            if (result) {
              this.removeCookies();
              this.setState({
                currentStatusMessage: i18n.t('VERIFYINGEMAILSUCCESS'),
                success: true,
              });
            } else {
              this.removeCookies();
              this.setState({
                currentStatusMessage: i18n.t('SOMETHINGWENTWRONG'),
                success: false,
              });
            }
          } else {
            this.removeCookies();
            this.setState({
              currentStatusMessage: i18n.t('VERIFICATIONFAILED'),
              success: false,
            });
          }
        } else {
          this.removeCookies();
          history.push('/login');
        }
      }
    } catch (err) {
      this.removeCookies();
      errorBlock(err);
      //throw err;
    }
  }

  render() {
    const { t } = this.props;
    const { currentStatusMessage, success } = this.state;

    return (
      <div className='entrySection d-flex align-items-center justify-content-center'>
        <div className='entryContainer'>
          <div className='loginLang'>
            <LangDropdown align={false} />
          </div>
          <Row className='rowflex d-flex flex-row align-items-stretch'>
            <Col
              md='7'
              className='d-none d-md-flex whitebgTransparent rightSide align-items-center '
            >
              <span className='titleTxt'>
                {t('MAKING')} <br />
                {t('LIVE')} <br />
                {t('SIMPLER')}
              </span>
            </Col>
            <Col
              md='5'
              className='leftSide d-flex flex-column justify-content-center'
            >
              <div className='welcome'>
                <div className='text-center my-5'>
                  <img src={GoSoloBlack} alt='description' />
                </div>
                <p className='mb-3'>{currentStatusMessage}</p>
                {success && <a href='/login'>{i18n.t('BACKTOLOGIN')}</a>}
              </div>
              <div className='errormsg'>
                <span id='errormsg' className='text-center'></span>
              </div>
            </Col>
          </Row>
          <div className='loginAppVersion'>
            <AppVersion t={t} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  verifyEmail: userActions.verifyEmail,
  updateEmail: userActions.updateEmail,
};

const ConnectedReset = connect(
  null,
  mapDispatchToProps,
)(withTranslation()(UpdateEmail));
export { ConnectedReset as UpdateEmail };
