import { ErrorMessage, Formik } from 'formik';
import i18n from 'i18next';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import { userActions } from '../../actions';
import GoSoloBlack from '../../assets/images/go-solo-black.png';
import { history } from '../../history';
import { AppVersion } from '../AppVersion/AppVersion';
import { LangDropdown } from '../LangDropdown';
//import './Reset.css';
class Reset extends Component {
  state = {
    currentStatus: i18n.t('UPDATEPASSWORD'),
    submitted: false,
    isSuccess: false,
  };

  onSubmitHandler = async (values) => {
    this.setState({ submitted: true });
    var details = {
      password: values.password,
      token: this.props.match.params.token,
    };

    const result = await this.props.updatePassword(details);
    if (result) {
      this.setState({ currentStatus: i18n.t('PASSWORDCHANGEDSUCCESSFULLY') });
      this.setState({ submitted: false, isSuccess: true });
    } else {
      this.setState({ submitted: false });
    }
  };

  render() {
    const { t } = this.props;
    const { currentStatus, submitted, isSuccess } = this.state;

    return (
      <div className='entrySection d-flex align-items-center justify-content-center'>
        <div className='entryContainer'>
          <div className='loginLang'>
            <LangDropdown align={false} />
          </div>
          <Row className='rowflex d-flex flex-row align-items-stretch'>
            <Col
              md='7'
              className='d-none d-md-flex whitebgTransparent rightSide align-items-center '
            >
              <span className='titleTxt'>
                {t('MAKING')} <br />
                {t('LIVE')} <br />
                {t('SIMPLER')}
              </span>
            </Col>
            <Col
              md='5'
              className='leftSide d-flex flex-column justify-content-center'
            >
              <div className='welcome'>
                <div className='text-center my-5'>
                  <img src={GoSoloBlack} alt='description' />
                </div>
                <h4 className='mb-3'>{currentStatus}</h4>
              </div>
              <div className='errormsg'>
                <span id='errormsg' className='text-center'></span>
              </div>
              {isSuccess ? (
                <div className='panel-body reset-success'>
                  <div className='form-group  col-sm-11 col-sm-push-1'>
                    <input
                      type='button'
                      value={t('LOGIN')}
                      className='btn btn-lg btn-primary btn-block'
                      onClick={() => history.push('/login')}
                    />
                  </div>
                </div>
              ) : (
                <fieldset>
                  <Formik
                    initialValues={{
                      password: '',
                      confirmpassword: '',
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string()
                        .trim()
                        .required(t('PASSWORDREQUIRED'))
                        .matches(
                          /^(?=.*[_$@#!&%.])(?=.*[^_$@#!&%.])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\w$@#!&%.]{8,128}$/,
                          t('PASSWORDCHARACTERS'),
                        ),
                      confirmpassword: Yup.string()
                        .trim()
                        .oneOf(
                          [Yup.ref('password'), null],
                          t('PASSSWORDONOTMATCH'),
                        )
                        .required(t('PASSWORDREQUIRED')),
                    })}
                    // eslint-disable-next-line no-unused-vars
                    onSubmit={(values, { setSubmitting }) => {
                      values.password = values.password.trim();
                      values.confirmpassword = values.confirmpassword.trim();
                      this.onSubmitHandler(values);
                    }}
                    enableReinitialize={true}
                  >
                    {(formik) => (
                      <Form
                        name='register'
                        className='w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                      >
                        {
                          <React.Fragment>
                            <FormGroup className='regInput'>
                              <Input
                                className={`form-control ${
                                  formik.errors.password ? '' : 'success'
                                }`}
                                placeholder={t('PASSWORD')}
                                id='txtPassword'
                                name='password'
                                type='password'
                                maxLength='128'
                                {...formik.getFieldProps('password')}
                              />
                              <span
                                className={
                                  !formik.errors.password &&
                                  formik.values.password
                                    ? 'successtick'
                                    : 'd-none'
                                }
                              >
                                <i className='fa fa-check' />
                              </span>
                              <ErrorMessage
                                component='span'
                                name='password'
                                className='col-sm-8 col-sm-push-4 error'
                              />
                            </FormGroup>
                            <FormGroup className='regInput'>
                              <Input
                                className={`form-control ${
                                  formik.errors.confirmpassword ? '' : 'success'
                                }`}
                                placeholder={t('REENTERPASSWORD')}
                                id='txtConfirmPassword'
                                name='confirmpassword'
                                type='password'
                                maxLength='128'
                                {...formik.getFieldProps('confirmpassword')}
                              />
                              <span
                                className={
                                  !formik.errors.confirmpassword &&
                                  formik.values.confirmpassword
                                    ? 'successtick'
                                    : 'd-none'
                                }
                              >
                                <i className='fa fa-check' />
                              </span>
                              <ErrorMessage
                                component='span'
                                name='confirmpassword'
                                className='col-sm-8 col-sm-push-4 error'
                              />
                            </FormGroup>
                            <FormGroup className='loginInputBtn'>
                              {submitted ? (
                                <input
                                  type='button'
                                  id='btnLoading'
                                  value={t('LOADING')}
                                  className='btn btn-lg btn-danger btn-block'
                                />
                              ) : (
                                <input
                                  className='btn btn-lg btn-primary btn-block'
                                  value={t('UPDATE')}
                                  type='submit'
                                  disabled={!formik.isValid}
                                />
                              )}
                            </FormGroup>
                            <FormGroup className='regInput text-center mb-2 w-100 login-links-register'>
                              <button
                                type='button'
                                className='atag'
                                onClick={() => history.push('/login')}
                              >
                                {t('GOBACK')}
                              </button>
                            </FormGroup>
                          </React.Fragment>
                        }
                      </Form>
                    )}
                  </Formik>
                </fieldset>
              )}
            </Col>
          </Row>

          <div className='loginAppVersion'>
            <AppVersion t={t} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updatePassword: userActions.updatePassword,
};

const ConnectedReset = connect(
  null,
  mapDispatchToProps,
)(withTranslation()(Reset));
export { ConnectedReset as Reset };
