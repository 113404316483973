export const linkedinConstants = {
  LI_PROFILE: 'LI_PROFILE',
  LI_DATA_REQ: 'LI_DATA_REQ',
  LI_ASSET_REQ: 'LI_ASSET_REQ',
  LI_ASSETS: 'LI_ASSETS',
  LI_DATA_FAIL: 'LI_DATA_FAIL',
  LI_POST_STATUS: 'LI_POST_STATUS',
  LI_TOKEN_REQ: 'LI_TOKEN_REQ',
  LI_TOKEN: 'LI_TOKEN',
  LI_TOKEN_FAIL: 'LI_TOKEN_FAIL',
  LI_CREATE_POST: 'LI_CREATE_POST',
  LI_PAGE_DATA: 'LI_PAGE_DATA',
  LI_PAGE_FAIL: 'LI_PAGE_FAIL',
};
