import React, { useEffect, useRef, useState } from 'react';
import StreamToolsLogoUploadView from './streamToolsLogoUploadView';
import { liveStreamActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';
import { notify } from '../CommonNotification/CommonNotification';
import { history } from '../../history';
import streamToolsQueue from '../../helpers/queue';

const { setWaterMark, removeWaterMark, storeImageInS3 } = liveStreamActions;

const StreamToolsLogoUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const streamToolUnitsList = useSelector((state) => state.streamTools);
  const bossId = useSelector((state) => state.unit.unit.BOSSID);
  const [streamToolData, setStreamtoolData] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [addingLogo, setAddingLogo] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [change, setChange] = useState(false);
  const [url, setUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [dimenstions, setDimenations] = useState([]);
  // const [position, setPosition] = useState([0, 0]);
  const [logoZoom, setLogoZoom] = useState(0);
  const [RND, SetRND] = useState({ width: 50, height: 50, x: 10, y: 10 });

  const outerBoxRef = useRef(null);
  const innerBoxRef = useRef(null);
  useEffect(() => {
    if (bossId && streamToolUnitsList) {
      setStreamtoolData(streamToolUnitsList?.[bossId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bossId]);

  useEffect(() => {
    if (streamToolData?.watermarkUrl) {
      const { watermarkUrl, x, y } = streamToolData;
      // logoImage = document.getElementById("logoImage");
      if (watermarkUrl) {
        setUrl(watermarkUrl);
        setClicked(true);
        (async () => {
          await fetchImageFromUrl(watermarkUrl);
        })();
      }
      setisLoading(false);
      if (x !== undefined && y !== undefined) {
        // setPosition([x, y]);
        moveInnerBoxToPosition([x, y]);
      }
      if (streamToolData.logoZoom) {
        setLogoZoom(streamToolData.logoZoom);
      }
    }
    if (streamToolData && !streamToolData?.watermarkUrl) {
      setisLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamToolData]);

  useEffect(() => {
    const block = outerBoxRef.current;
    const width = block.clientWidth;
    block.style.setProperty('--outer-box-height', `${(9 / 16) * width}px`);
    if (logoZoom) {
      const innerWidth = (width * logoZoom) / 100;
      SetRND((pre) => {
        return {
          ...pre,
          width: innerWidth,
          height: 'auto',
        };
      });
    } else {
      SetRND((pre) => {
        return {
          ...pre,
          width: width * 0.1,
          height: 'auto',
        };
      });
    }
  }, [logoZoom]);

  const fetchImageFromUrl = async (url) => {
    try {
      await fetch(url)
        .then((res) => res.blob())
        .then(async (blob) => {
          // setImageUrl(await readImage(blob));
          setDimenations(await calculateImageDimenstions(blob));
        });
    } catch (err) {
      handleUploadFail();
    }
  };

  // const readImage = (blob) => {
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       resolve(reader.result);
  //     };
  //     reader.readAsDataURL(blob);
  //   });
  // };

  const moveInnerBoxToPosition = (position) => {
    let x00 = Math.ceil(outerBoxRef.current.clientWidth * (position[0] / 100));
    let y00 = Math.ceil(outerBoxRef.current.clientHeight * (position[1] / 100));
    SetRND((pre) => {
      return {
        ...pre,
        x: x00,
        y: y00,
      };
    });
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleLogoUpload = async (info) => {
    setIsUploading(true);
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
    const { file } = info;
    if (file.status === 'done') {
      const allowedFiletype =
        'image/jpeg,image/jpg,image/png,image/gif,.jpg,.png,.jpeg,.gif';
      if (!allowedFiletype.includes(file.type)) {
        notify('error', t('UPLOADWATERMARKVALIDATION'));
        handleUploadFail();
        return;
      }

      let [width, height] = await calculateImageDimenstions(file.originFileObj);

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: 'png',
      };

      let imageFile = file.originFileObj;

      if (width > 1920 || height > 1080) {
        imageFile = await imageCompression(file.originFileObj, options);
        width = 1920;
        height = 1080;
      }

      try {
        let [t, width00, height00] = await dispatch(
          streamToolsQueue(storeImageInS3, [imageFile, width, height, bossId]),
        );
        setUrl(t);
        const outerWidth = outerBoxRef.current.clientWidth;
        SetRND({
          width: outerWidth * 0.1,
          height: 'auto',
          x: 10,
          y: 10,
        });
        setDimenations([width00, height00]);
        setChange(true);

        const reader = new FileReader();
        reader.onload = () => {
          setIsUploading(false);
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(file.originFileObj);
      } catch (err) {
        handleUploadFail();
        notify('error', t('FAILEDUPLOADLOGO'));
      }
      // }
    }
    if (file.status === 'removed') {
      handleUploadFail();
    }
  };

  const calculateImageDimenstions = (image) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = function () {
        resolve([img.naturalWidth, img.naturalHeight]);
      };
    });
  };

  const handleUploadFail = () => {
    setUrl(null);
    setImageUrl(null);
    setFileList([]);
    setDimenations([]);
    setIsUploading(false);
  };

  const addWaterMark = async () => {
    if (url) {
      setAddingLogo(true);
      const outerWidth = outerBoxRef.current.clientWidth;
      const innerWidth =
        typeof RND.width === 'number'
          ? RND.width
          : +RND.width.replace('px', '');
      // const zoom = (innerWidth / dimenstions[0]) * 100;
      const zoom = (innerWidth / outerWidth) * 100;
      let x = (RND.x / outerBoxRef.current.clientWidth) * 100;
      let y = (RND.y / outerBoxRef.current.clientHeight) * 100;
      await dispatch(
        streamToolsQueue(setWaterMark, [
          url,
          ...dimenstions,
          x,
          y,
          bossId,
          zoom,
        ]),
      );
      setTimeout(() => {
        setAddingLogo(false);
        setClicked(true);
        setChange(false);
      }, 0);
      notify('success', t('LOGOADDVIDEOFEED'));
    } else {
      notify('error', t('UPLOADLOGO'));
    }
  };

  const handleRemoveLogo = async () => {
    const width = outerBoxRef.current.clientWidth;
    setIsClearing(true);
    setUrl(null);
    setImageUrl(null);
    setFileList([]);
    setDimenations([]);
    SetRND(() => {
      return {
        x: 10,
        y: 10,
        width: width * 0.1,
        height: 'auto',
      };
    });
    await dispatch(streamToolsQueue(removeWaterMark, [bossId]));
    setIsClearing(false);
    setClicked(false);
  };

  function back() {
    history.goBack();
  }

  const changePosition = (e, d) => {
    e.stopImmediatePropagation();
    setChange(true);
    SetRND((pre) => {
      return {
        ...pre,
        x: d.x,
        y: d.y,
      };
    });
  };

  const changeSize = (e, direction, ref, delta, position) => {
    setChange(true);
    SetRND({
      width: ref.style.width,
      height: ref.style.height,
      ...position,
    });
  };

  return (
    <>
      <StreamToolsLogoUploadView
        {...{
          t,
          isLoading,
          isUploading,
          isClearing,
          imageUrl,
          dimenstions,
          change,
          clicked,
          addingLogo,
          url,
          handleLogoUpload,
          handleRemoveLogo,
          addWaterMark,
          dummyRequest,
          fileList,
          back,
          RND,
          outerBoxRef,
          innerBoxRef,
          changePosition,
          changeSize,
          logoZoom,
          // resizeStop,
        }}
      />
    </>
  );
};

export default StreamToolsLogoUpload;
