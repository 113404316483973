import { _createDestination, setLucDes, _editUsers } from '../api/luc.api';
import { createAWSDestination } from '../api/aws.api';
import { history } from '../history';
import { store } from '../store';
import { errorBlock } from '../api/errorBlock';
import { commonConstants } from '../constants';
import { notify } from '../components/CommonNotification/CommonNotification';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
// import { destinationActions } from './destination.actions';
// import { destinationAllowed } from '../utils';
import { liveStreamServices } from '../services';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const customRtmpActions = {
  setStream,
};

function setStream(bossId, destination, cb) {
  const { isStreamToolActive } = store.getState().streamTools?.[bossId];
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      let streampayload = {
        destination: {
          name: destination.destinationname,
          type: 'stream',
          streaming_destination: {
            external_id: destination.id,
            streaming_provider: destination.streaming_provider,
            streaming_destination_outputs: [
              {
                streaming_profile: destination.selectedProfile,
                stream_name: destination.stream_name,
                min_res_override: destination.overrideResolution,
                max_res_override: destination.overrideResolution,
                min_fps_override: destination.overrideFramerate,
                max_fps_override: destination.overrideFramerate,
                min_bitrate_override: destination.overrideBitrate,
                max_bitrate_override: destination.overrideBitrate,
                audio_bitrate_override: destination?.audio_bitrate_override,
              },
            ],
            streaming_ingest: {
              username: destination.username,
              password: destination.password,
              primary_url: destination.primary_url,
              secondary_url: destination.secondary_url,
            },
          },
        },
      };
      if (destination.streaming_provider === 'SRT-OUT-Caller-Solo') {
        streampayload = {
          ...streampayload,
          destination: {
            ...streampayload.destination,
            streaming_destination: {
              ...streampayload.destination.streaming_destination,
              streaming_ingest: {
                ...streampayload.destination.streaming_destination
                  .streaming_ingest,
                streamingSrt: {
                  mode: 'caller',
                  latency: destination.latency,
                  passphrase: destination.passphrase,
                },
                // audio_bitrate_override:destination.audio_bitrate_override
              },
            },
          },
        };
      }
      const tempstreampayload = {
        //'inventory_id': cookies.get('inventory_id'),
        destination_id: destination.id.toString(),
        provider: destination.streaming_provider,
        title: destination.destinationname,
        type: 'stream',
        username: destination.username,
        password: destination.password,
        pri_url: destination.primary_url,
        sec_url: destination.secondary_url,
        profileSelected: destination.selectedProfile,
        streamname: destination.stream_name,
        max_overrideResolution: destination.overrideResolution,
        min_overrideResolution: destination.overrideResolution,
        max_overrideFramerate: destination.overrideFramerate,
        min_overrideFramerate: destination.overrideFramerate,
        max_overrideBitrate: destination.overrideBitrate,
        min_overrideBitrate: destination.overrideBitrate,
        audio_bitrate_override: destination.audio_bitrate_override,
        unit_id: bossId,
        latency: destination.latency,
        passphrase: destination.passphrase,
        // is_stream_tool: isStreamToolActive,
      };

      const makeStreamtoolPayload = {
        //'inventory_id': cookies.get('inventory_id'),
        destination_id: destination.id.toString(),
        provider: destination.streaming_provider,
        title: destination.destinationname,
        type: 'stream',
        username: destination.username,
        password: destination.password,
        pri_url: destination.primary_url,
        sec_url: destination.secondary_url,
        profileSelected: destination.selectedProfile,
        streamname: destination.stream_name,
        // max_overrideResolution: destination.overrideResolution,
        // min_overrideResolution: destination.overrideResolution,
        // max_overrideFramerate: destination.overrideFramerate,
        // min_overrideFramerate: destination.overrideFramerate,
        // max_overrideBitrate: destination.overrideBitrate,
        // min_overrideBitrate: destination.overrideBitrate,
        // audio_bitrate_override: destination.audio_bitrate_override,
        unit_id: bossId,
        latency: destination.latency,
        passphrase: destination.passphrase,
        // is_stream_tool: isStreamToolActive,
      };

      const payload = isStreamToolActive
        ? makeStreamtoolPayload
        : tempstreampayload;

      const awsstreampayload = {};

      Object.keys(payload).forEach((key) => {
        if (payload[key] !== undefined && payload[key] !== '') {
          awsstreampayload[key] = payload[key];
        }
      });
      dispatch(
        createDestination(
          streampayload,
          awsstreampayload,
          bossId,
          cb,
          destination,
        ),
      );
    } catch (error) {
      errorBlock(error);
      dispatch(hideSpinner());
    }
  };

  function showLoader() {
    return { type: commonConstants.OTHER_LOADING };
  }

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function createDestination(
  streampayload,
  awsstreampayload,
  bossId,
  cb,
  advanceProfile,
) {
  const {
    // destination: {
    //   selectedDestination: lucDest
    // },
    unit: { unit },
  } = store.getState();

  const {
    // selectedDestinations: streamtoolDest,
    isStreamToolActive,
    isStreamtoolsStreaming,
  } = store.getState()?.streamTools[bossId];

  return async (dispatch) => {
    try {
      const { REACT_APP_ApplicationId } = process.env;
      const headers = {
        Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
        'x-user-uuid': `${cookies.get('user_session')}`,
      };
      const lucHeaders = {
        Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
        'Application-Id': REACT_APP_ApplicationId,
      };

      if (
        isStreamToolActive &&
        (isStreamtoolsStreaming || unit.status === 'streaming')
      ) {
        history.push(`/dashboard/units/${bossId}`);
        dispatch(hideSpinner());
        return;
      }

      // let destId = streamtoolDest.find(
      //   (e) =>
      //     e?.streaming_destination?.streaming_destination_outputs[0]
      //       ?.stream_name ===
      //     lucDest?.streaming_destination?.streaming_destination_outputs[0]
      //       ?.stream_name,
      // )?.id;

      // if (streamtoolDest.length < destinationAllowed && isStreamToolActive) {
      //   destId = null;
      // }

      // if (streamtoolDest.length === destinationAllowed && isStreamToolActive) {
      //   destId = streamtoolDest[streamtoolDest.length - 1]?.id;
      // }

      // if (destId) {
      //   await dispatch(destinationActions.removeStreamToolDestination(destId));
      // }

      const aws_res = await createAWSDestination(headers, awsstreampayload);

      if (isStreamToolActive) {
        const { storeStreamToolsData } = liveStreamServices;
        await storeStreamToolsData({
          fps: !!advanceProfile?.overrideFramerate
            ? advanceProfile?.overrideFramerate
            : null,
          video_bitrate: !!advanceProfile?.overrideBitrate
            ? advanceProfile?.overrideBitrate
            : null,
          audio_bitrate: !!advanceProfile?.audio_bitrate_override
            ? advanceProfile?.audio_bitrate_override
            : null,
          bossId,
        });
      }

      if (aws_res.data.data) {
        dispatch(createLUCDestination(lucHeaders, streampayload, bossId, cb));
      }
    } catch (error) {
      cb('ERRORWHILEADDINGDESTINATION', null);
      errorBlock(error);
      dispatch(hideSpinner());
    }
  };

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function createLUCDestination(lucHeaders, streampayload, bossId, cb) {
  const { isStreamToolActive } = store.getState().streamTools?.[bossId];
  return async (dispatch) => {
    try {
      if (
        streampayload.destination.streaming_destination.streaming_provider ===
        'wowza'
      ) {
        dispatch(setWowzaTokenToOvpCredentials(lucHeaders, bossId));
      }
      if (isStreamToolActive) {
        dispatch(hideSpinner());
        history.push(`/dashboard/units/${bossId}`);
        notify('success', i18n.t('SOLOUNITCONFIGURED'));
        return;
      }
      const luc_res = await _createDestination(lucHeaders, streampayload);
      const unitpayload = {
        unit: {
          selected_destination: luc_res.data.data.destination.id,
        },
      };
      dispatch(setLUCDestination(unitpayload, bossId, lucHeaders, cb));
    } catch (error) {
      cb('REQUESTFAIL', null);
      errorBlock(error);
      dispatch(hideSpinner());
    }
  };

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function setLUCDestination(unitpayload, bossId, lucHeaders, cb) {
  return async (dispatch) => {
    try {
      await setLucDes(unitpayload, bossId, lucHeaders);
      dispatch(hideSpinner());
      history.push(`/dashboard/units/${bossId}`);
      notify('success', i18n.t('SOLOUNITCONFIGURED'));
    } catch (error) {
      if (error?.response?.status === 400) {
        notify('error', error?.response?.data?.description);
      }
      cb('UNABLETOSETDESTINATION', null);
      errorBlock(error);
      dispatch(hideSpinner());
    }
  };

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

// eslint-disable-next-line no-unused-vars
function setWowzaTokenToOvpCredentials(lucHeaders, bossId, unitpayload) {
  return async (dispatch) => {
    const { ovpCredentials } = store.getState().authentication.user;
    try {
      if (ovpCredentials) {
        const old_ovp_credentials = JSON.parse(ovpCredentials);
        old_ovp_credentials[`${bossId}_wowza`] =
          cookies.get('wowza_long_token');

        // if (old_ovp_credentials.recent_destinations) {
        //     old_ovp_credentials.recent_destinations.push(unitpayload.unit.selected_destination);
        // } else {
        //     old_ovp_credentials.recent_destinations = [unitpayload.unit.selected_destination];
        // }

        const credentialsPayload = {
          ovpCredentials: JSON.stringify(old_ovp_credentials),
        };
        await _editUsers(lucHeaders, credentialsPayload);
        dispatch(update_ovp_credentials(JSON.stringify(old_ovp_credentials)));
      }
    } catch (error) {
      errorBlock(error);
      dispatch(hideSpinner());
    }
  };

  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }

  function update_ovp_credentials(ovp_credentials) {
    return { type: 'UPDATE_OVP_CRED', ovp_credentials };
  }
}
