import { getRtmp, getUserDetails } from '../api/boxcast.api';
import { getBoxcastToken } from '../api/aws.api';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();

export const boxCastService = {
  getRtmpUrl,
  userDetails,
  getBoxCastToken,
};

async function getBoxCastToken(code, redirect, grantType) {
  const awsHeaders = {
    Authorization: 'Bearer ' + getLocalStorageItem('access_token'),
    'x-user-uuid': `${cookies.get('user_session')}`,
  };
  try {
    const getTok = await getBoxcastToken(code, redirect, grantType, awsHeaders);
    return getTok.data.data.response;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getRtmpUrl() {
  try {
    const rtmp = await getRtmp();
    return rtmp.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
async function userDetails() {
  try {
    const details = await getUserDetails();
    return details.data;
  } catch (e) {
    return Promise.reject(e);
  }
}
