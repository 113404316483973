import React from 'react';
import liveuloading from '../../assets/images/liveuloading.png';
import './liveuloading.css';

export const Liveuloading = () => {
  return (
    <div className='loading_blk w-100 d-flex flex-row align-items-center justify-content-center h-100'>
      <div className='loading'>
        <img src={liveuloading} alt='loading' />
      </div>
    </div>
  );
};
