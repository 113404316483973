import _ from 'lodash';
import { history } from '../history';

export const getPlans = (plans) => {
    const {
        location: { pathname, state },
      } = history;
      
      let serviceType = pathname.split('/')[4];
      
      let selplans;
      if (state && state.kc && state.scnumber) {
        if (state.region === 'ROW') {
          selplans = plans.filter(
            (plan) =>
              plan.metadata.service_type === serviceType &&
              plan.metadata.modem === state.kc &&
              plan.id.split('-')[2] === state.region,
          );
        } else {
          selplans = plans.filter(
            (plan) =>
              plan.metadata.service_type === serviceType &&
              plan.metadata.modem === state.kc &&
              plan.id.split('-')[2] !== 'ROW',
          );
        }
        return { plan1: _.orderBy(selplans, ['interval'], ['asc']) };
      }
      
      selplans = plans.filter((plan) => plan.metadata.service_type === serviceType);
    
      return { plan1: _.orderBy(selplans, ['interval'], ['asc']) };
  };