import React from 'react';
import { Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { LangDropdown } from '../LangDropdown';
import GoSoloBlack from '../../assets/images/go-solo-black.png';
import DeviceImage from '../../assets/images/device-img.png';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Login.css';
import { AppVersion } from '../AppVersion/AppVersion';

export const LoginForm = (props) => {
  const { submitted, onSubmitHandler, t, history } = props;

  function showpass() {
    var x = document.getElementById('txtPassword');
    var y = document.getElementById('fontIcon');

    if (x.type === 'password') {
      x.type = 'text';
      y.className = 'fa fa-eye';
    } else {
      x.type = 'password';
      y.className = 'fa fa-eye-slash';
    }
  }

  return (
    <div className='entrySection d-flex align-items-center justify-content-center'>
      <div className='entryContainer'>
        <div className='loginLang'>
          <LangDropdown align={false} />
        </div>
        <Row className='rowflex d-flex flex-row align-items-stretch'>
          <Col
            md='7'
            className='d-none d-md-flex whitebgTransparent rightSide align-items-center '
          >
            <span className='titleTxt'>
              {t('MAKING')} <br />
              {t('LIVE')} <br />
              {t('SIMPLER')}
            </span>
          </Col>
          <Col
            md='5'
            className='leftSide d-flex flex-column justify-content-center'
          >
            <div className='welcome'>
              <div className='text-center my-5'>
                <img src={GoSoloBlack} alt='description' />
              </div>
              <h4>{t('WELCOME')}</h4>
            </div>
            <div className='errormsg'>
              <span id='errormsg' className='text-center'></span>
            </div>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string().trim().required(t('EMAILREQUIRED')),
                //.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, t('INVALIDEMAIL')),
                password: Yup.string().trim().required(t('PASSWORDREQUIRED')),
                // .matches(/^(?=.*[_$@#!&%.])(?=.*[^_$@#!&%.])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[\w$@#!&%.]{8,128}$/, t('PASSWORDCHARACTERS'))
              })}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, { setSubmitting }) => {
                values.email = values.email.trim();
                values.password = values.password.trim();
                onSubmitHandler(values);
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form
                  name='register'
                  className='w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <FormGroup className='loginInput'>
                    <Input
                      className={`form-control ${
                        formik.errors.email ? '' : 'success'
                      }`}
                      placeholder={t('EMAIL')}
                      name='email'
                      id='txtEmail'
                      type='email'
                      maxLength='75'
                      {...formik.getFieldProps('email')}
                    />
                    <ErrorMessage
                      component='span'
                      name='email'
                      className='col-sm-8 col-sm-push-4 error'
                    />
                  </FormGroup>
                  <FormGroup className='loginInput'>
                    <div className='input-group'>
                      <Input
                        className={`form-control ${
                          formik.errors.password ? '' : 'success'
                        }`}
                        placeholder={t('PASSWORD')}
                        id='txtPassword'
                        name='password'
                        type='password'
                        maxLength='128'
                        {...formik.getFieldProps('password')}
                      />
                      <div className='input-group-addon'>
                        <i
                          className='fa fa-eye-slash'
                          id='fontIcon'
                          onClick={() => showpass()}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      component='span'
                      name='password'
                      className='col-sm-8 col-sm-push-4 error'
                    />
                  </FormGroup>
                  <FormGroup className='login-links d-flex flex-row justify-content-between'>
                    <div className='help_link_blk'>
                      {/*<a ui-sref="query">translation</a>*/}
                      {/* <Link to="mailto:solo.help@liveu.tv">{t("HELP")}</Link> */}
                      <a href='mailto:solo.help@liveu.tv'>{t('HELP')}</a>
                    </div>
                    <div className='forgotpass_link_blk'>
                      <button
                        type='button'
                        className='atag'
                        onClick={() => history.push('/forgotpassword')}
                      >
                        {t('FORGOTPASSWORD')}
                      </button>
                    </div>
                  </FormGroup>
                  <FormGroup className='loginInputBtn'>
                    {submitted ? (
                      <button
                        type='button'
                        id='btnLoading'
                        value={t('LOADING')}
                        className='btn btn-lg btn-danger btn-block'
                      >
                        {t('LOADING')}
                      </button>
                    ) : (
                      <button
                        type='submit'
                        id='btnLogin'
                        value={t('LOGIN')}
                        className='btn btn-lg btn-primary btn-block'
                        disabled={!formik.isValid}
                      >
                        {t('LOGIN')}
                      </button>
                    )}
                    {/* {
                                            !submitted
                                                ? <button type="submit" id="btnLogin" value={t('LOGIN')} className="btn btn-lg btn-primary btn-block" >{t('LOGIN')}</button>
                                                : <button type="button" id="btnLoading" value={t('LOADING')} className=" btn btn-lg btn-danger btn-block">{t('LOADING')}</button>
                                        } */}
                  </FormGroup>
                </Form>
              )}
            </Formik>
            <div className='form-group new-user text-center'>
              <button
                type='button'
                className='atag'
                onClick={() => history.push('/register')}
              >
                {t('NEWUSER')}
              </button>
            </div>
            <div className='form-group text-center needsolo-link'>
              <img src={DeviceImage} alt='Device' />{' '}
              <span> {t('NEEDSOLO')}</span>{' '}
              <a
                href='https://gosolo.tv/'
                rel='noopener noreferrer'
                target='_blank'
              >
                {t('GETITHERE')}
              </a>
            </div>
          </Col>
        </Row>
        <div className='loginAppVersion'>
          <AppVersion t={t} />
        </div>
      </div>
    </div>
  );
};
