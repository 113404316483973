import {
  commonConstants,
  saveddestinationsConstants,
  streamToolConstants,
} from '../constants';
import {
  destinationService,
  editUnitsService,
  liveStreamServices,
  savedDestinationsService,
} from '../services';
import { errorBlock } from '../api/errorBlock';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import findIndex from 'lodash/findIndex';
import { notify } from '../components/CommonNotification/CommonNotification';
import { history } from '../history';
import { store } from '../store';
import { _editUsers } from '../api/luc.api';
import { destinationActions } from '../actions';
import utf8 from 'utf8';
import i18n from 'i18next';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
// import { destinationAllowed } from '../utils';
const cookies = new Cookies();
export const commonAction = {
  getStreamingProviders,
  createAwsDestination,
  createLucDest,
  stopAllIntervals,
  showProgressBar,
  hideProgressBar,
  setProgressBarInfo,
};

/**
 * @name stopAllIntervals
 * @params none
 * @descriptiton clears all running intervals
 **/
function stopAllIntervals() {
  return async () => {
    try {
      for (let i = 1; i <= 99999; i++) {
        clearInterval(i);
      }
    } catch (e) {}
  };
}
/**
 * @name getStreamingProviders
 * @params none
 * @descriptiton get all providers
 **/
function getStreamingProviders() {
  return async (dispatch) => {
    try {
      await editUnitsService.getStreamingProviders();
      //dispatch(success(getProviders));
      dispatch(successOther());
    } catch (e) {
      dispatch(failure());
      errorBlock(e);
    }
  };
  function successOther() {
    return { type: commonConstants.STREAMING_PROVIDERS };
  }
  // function success(streamProvidersData) {
  //   return {
  //     type: editunitConstants.GET_STREAMING_PROVIDERS,
  //     streamProvidersData,
  //   };
  // }
  function failure() {
    return { type: commonConstants.STREAMING_PROVIDERS_FAILURE };
  }
}
/**
 * @name createAwsDestination
 * @params processed data, bossId, form data
 * @descriptiton common function for all providers to create aws destination
 **/
function createAwsDestination(
  getAwsDestData,
  bossId,
  formikData,
  isDirectCreateLucDest = false,
) {
  // const { isStreamToolActive } = store.getState().streamTools?.[bossId];
  let tempObj;
  const makePayload = (data) => {
    tempObj = {
      //inventory_id: cookies.get('inventory_id'),
      destination_id: data.id,
      provider: data.streaming_provider,
      title: data.destinationName,
      type: 'stream',
      username: data.username,
      password: data.password,
      pri_url: data.primary_url,
      sec_url: data.secondary_url,
      profileSelected: data.selectedProfile,
      streamname: data.stream_name,
      max_overrideResolution: data.overrideResolution,
      min_overrideResolution: data.overrideResolution,
      max_overrideFramerate: data.overrideFramerate,
      min_overrideFramerate: data.overrideFramerate,
      max_overrideBitrate: data.overrideBitrate,
      min_overrideBitrate: '',
      unit_id: bossId,
      fb_page_id:
        data.streaming_provider.toLowerCase() === 'facebook'
          ? data.fb_page_id
          : '',
      // fb_page_id: formikData.streamLocation.xvalue,
      startAsPreview: data.startAsPreview,
      audio_bitrate_override: data.audio_bitrate_override,
      // is_stream_tool: isStreamToolActive,
    };

    let obj1 = pickBy(tempObj, identity);
    return obj1;
  };

  const makeStreamtoolPayload = (data) => {
    tempObj = {
      //inventory_id: cookies.get('inventory_id'),
      destination_id: data.id,
      provider: data.streaming_provider,
      title: data.destinationName,
      type: 'stream',
      username: data.username,
      password: data.password,
      pri_url: data.primary_url,
      sec_url: data.secondary_url,
      profileSelected: data.selectedProfile,
      streamname: data.stream_name,
      // max_overrideResolution: data.overrideResolution,
      // min_overrideResolution: data.overrideResolution,
      // max_overrideFramerate: data.overrideFramerate,
      // min_overrideFramerate: data.overrideFramerate,
      // max_overrideBitrate: data.overrideBitrate,
      // min_overrideBitrate: '',
      unit_id: bossId,
      fb_page_id:
        data.streaming_provider.toLowerCase() === 'facebook'
          ? data.fb_page_id
          : '',
      // fb_page_id: formikData.streamLocation.xvalue,
      startAsPreview: data.startAsPreview,
      // audio_bitrate_override: data.audio_bitrate_override,
      // is_stream_tool: isStreamToolActive,
    };

    let obj1 = pickBy(tempObj, identity);
    return obj1;
  };

  return async (dispatch) => {
    try {
      const {
        dnd: IsThisWebSocketDestination,
        isStreamToolActive,
        isStreamtoolsStreaming,
        // selectedDestinations: streamtoolDest,
      } = store.getState()?.streamTools[bossId];

      const {
        // destination: {
        //   selectedDestination: lucDest
        // },
        unit: { unit },
      } = store.getState();

      const awsPayload = isStreamToolActive
        ? makeStreamtoolPayload(getAwsDestData)
        : makePayload(getAwsDestData);

      if (
        isStreamToolActive &&
        (isStreamtoolsStreaming || unit.status === 'streaming')
      ) {
        history.push(`/dashboard/units/${bossId}`);
        dispatch(hideSpinner());
        return;
      }

      // let destId = streamtoolDest.find(
      //   (e) =>
      //     e?.streaming_destination?.streaming_destination_outputs[0]
      //       ?.stream_name ===
      //     lucDest?.streaming_destination?.streaming_destination_outputs[0]
      //       ?.stream_name,
      // )?.id;

      // if (streamtoolDest.length < destinationAllowed && isStreamToolActive) {
      //   destId = null;
      // }

      // if (streamtoolDest.length === destinationAllowed && isStreamToolActive) {
      //   destId = streamtoolDest[streamtoolDest.length - 1]?.id;
      // }

      if (!isDirectCreateLucDest) {
        if (
          !isStreamToolActive ||
          (isStreamToolActive && !IsThisWebSocketDestination)
        ) {
          // if (destId) {
          //   await dispatch(
          //     destinationActions.removeStreamToolDestination(destId),
          //   );
          // }

          const createAwsDest = await savedDestinationsService.createAwsDest(
            awsPayload,
          );

          if (isStreamToolActive) {
            const { storeStreamToolsData } = liveStreamServices;
            await storeStreamToolsData({
              fps: !!getAwsDestData?.overrideFramerate
                ? getAwsDestData?.overrideFramerate
                : null,
              video_bitrate: !!getAwsDestData?.overrideBitrate
                ? getAwsDestData?.overrideBitrate
                : null,
              audio_bitrate: !!getAwsDestData?.audio_bitrate_override
                ? getAwsDestData?.audio_bitrate_override
                : null,
              bossId,
            });
          }

          dispatch(success(createAwsDest));
        }
      }
      await dispatch(
        createLucDest(tempObj, bossId, formikData, isDirectCreateLucDest),
      );
    } catch (e) {
      dispatch(failure(e));
      dispatch(hideSpinner());
      errorBlock(e);
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }

    function success(createAwsDestData) {
      return { type: commonConstants.CM_CREATE_AWS_DEST, createAwsDestData };
    }

    function failure(error) {
      return { type: commonConstants.CM_CREATE_AWS_DEST_FAILURE, error };
    }
  };
}
/**
 * @name createLucDest
 * @params processed data, bossId, form data
 * @descriptiton common function for all providers to create LUC destination
 **/
function createLucDest(
  data,
  bossId,
  formikData,
  isDirectCreateLucDest = false,
) {
  const title =
    data.provider.toLowerCase() === 'facebook' ||
    data.provider.toLowerCase() === 'youtube'
      ? utfEncode(data.title)
      : data.title;
  const makePayload2 = (data) => {
    let obj1 = {
      destination: {
        name: title,
        type: 'stream',
        streaming_destination: {
          external_id: data.destination_id,
          streaming_provider: data.provider,
          streaming_destination_outputs: [
            {
              streaming_profile: data.profileSelected,
              stream_name: data.streamname,
              min_res_override: data.max_overrideResolution,
              max_res_override: data.max_overrideResolution,
              min_fps_override: data.max_overrideFramerate,
              max_fps_override: data.max_overrideFramerate,
              min_bitrate_override: '',
              max_bitrate_override: data.max_overrideBitrate,
            },
          ],
          streaming_ingest: {
            username: data.username,
            password: data.password,
            primary_url: data.pri_url,
            secondary_url: data.sec_url,
          },
        },
      },
    };
    return obj1;
  };
  return async (dispatch) => {
    const { dnd: IsThisWebSocketDestination, isStreamToolActive } =
      store.getState().streamTools?.[bossId];

    if (isStreamToolActive && !IsThisWebSocketDestination) {
      dispatch(hideSpinner());
      notify('success', i18n.t('SOLOUNITCONFIGURED'));
      history.push('/dashboard/units/' + bossId);
      return;
    }
    let createLucDes;

    try {
      if (
        data.provider === 'Facebook' ||
        data.provider === 'Periscope and Twitter' ||
        data.provider.toLowerCase() === 'youtube'
      ) {
        createLucDes = await destinationService.createDestination(
          makePayload2(data),
        );
      } else {
        createLucDes = await savedDestinationsService.createLucDest(
          makePayload2(data),
        );
      }

      dispatch(success(createLucDes));
      if (isStreamToolActive) {
        await dispatch(storeHelperDestination(bossId, createLucDes));
      }
      await dispatch(
        getDestinationDetail(
          createLucDes.id,
          data,
          formikData,
          bossId,
          isDirectCreateLucDest,
        ),
      );
    } catch (e) {
      dispatch(failure(e.toString()));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function failure(error) {
      return {
        type: saveddestinationsConstants.CREATE_LUC_DEST_FAILURE,
        error,
      };
    }
    function storeHelperDestination(bossId, helperDestination) {
      return {
        type: streamToolConstants.UPDATE_ST_HELPER_DESTINATION,
        payload: {
          bossId,
          helperDestination,
        },
      };
    }
    function success(createLucDesData) {
      return {
        type: saveddestinationsConstants.CREATE_LUC_DEST,
        createLucDesData,
      };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
  function utfEncode(data) {
    try {
      return utf8.encode(data);
    } catch (e) {
      return data;
    }
  }
}
/**
 * @name getDestinationDetail
 * @params postId, data, form data,bossId
 * @descriptiton fetch a destination
 **/
function getDestinationDetail(
  postId,
  data,
  formikData,
  bossId,
  isDirectCreateLucDest = false,
) {
  return async (dispatch) => {
    try {
      await dispatch(destinationActions.getDestinationDetails(postId));
      await dispatch(
        setLucDest(data, postId, bossId, formikData, isDirectCreateLucDest),
      );
    } catch (e) {
      dispatch(hideSpinner());
      errorBlock(e, bossId);
    }
  };
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}
/**
 * @name setLucDest
 * @params data, destId,bossId, form data
 * @descriptiton set a destination
 **/
function setLucDest(
  lucData,
  destID,
  bossId,
  formikData,
  isDirectCreateLucDest = false,
) {
  const payload1 = {
    unit: {
      selected_destination: destID,
    },
  };
  return async (dispatch) => {
    try {
      const setLucDest = await savedDestinationsService.setLucDest(
        payload1,
        bossId,
      );
      dispatch(success(setLucDest));
      if (lucData.provider === 'Facebook') {
        dispatch(fbOvpOps(formikData, bossId));
      } else {
        const { dnd } = store.getState().streamTools?.[bossId];
        if (dnd) {
          return;
        }
        dispatch(hideSpinner());
        if (isDirectCreateLucDest) {
          return;
        }
        notify('success', i18n.t('SOLOUNITCONFIGURED'));
        history.push('/dashboard/units/' + bossId);
      }
    } catch (e) {
      dispatch(failure(e.toString()));
      dispatch(hideSpinner());
      errorBlock(e);
    }

    function success(setLucDestData) {
      return { type: saveddestinationsConstants.SET_LUC_DEST, setLucDestData };
    }
    function failure(error) {
      return { type: saveddestinationsConstants.SET_LUC_DEST_FAILURE, error };
    }
    function hideSpinner() {
      return { type: commonConstants.OTHER_LOADING_FALSE };
    }
  };
}
/**
 * @name fbOvpOps
 * @params  form data,bossId
 * @descriptiton set OVP for fb destionations
 **/
function fbOvpOps(formikData, bossId) {
  return async (dispatch) => {
    try {
      const { REACT_APP_ApplicationId } = process.env;
      const lucHeaders = {
        Authorization: `Bearer ${getLocalStorageItem('access_token')}`,
        'Application-Id': REACT_APP_ApplicationId,
      };
      let latestPost = [];
      latestPost = cookies.get('latestPosts') ? cookies.get('latestPosts') : [];
      const { ovpData } = store.getState().common;
      let currentUnitData = ovpData.currentUnit;
      const tempOvp = {
        selected_location: formikData.streamLocation.value,
        selected_wheretostream:
          formikData.streamLocation.label === 'Personal profile'
            ? 'me'
            : formikData.streamLocation.type,
        selected_profile: formikData.profile.name,
        selected_overrideprofile: formikData.resolutionBox
          ? formikData.overrideResolution.value
          : '',
        selected_bitrate: formikData.bitRateBox
          ? formikData.overrideBitrate
          : '',
        selected_framerate: formikData.frameRateBox
          ? formikData.overrideFrame.value
          : '',
        selected_mode: formikData.publishMode.label,
      };
      const newOvp = pickBy(tempOvp, identity);
      currentUnitData = newOvp;
      const fbPagesObj = {
        accessToken: formikData.streamLocation.access_token,
        id:
          formikData.streamLocation.label === 'Personal profile'
            ? formikData.streamLocation.value
            : formikData.streamLocation.id,
        postPage: formikData.streamLocation.name,
      };
      if (fbPagesObj.id && fbPagesObj.postPage) {
        latestPost.length > 4 && latestPost.shift();
        latestPost.push(fbPagesObj);
        const latestPostString = JSON.stringify(latestPost);
        let d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        cookies.set('latestPosts', latestPostString, {
          path: '/',
          expires: d,
          secure: true,
        });
      }
      if (formikData.streamLocation.label !== 'Personal profile') {
        if (ovpData.fb_pages && ovpData.fb_pages.length) {
          const index = findIndex(ovpData.fb_pages, (o) => {
            return o.postPage === fbPagesObj.postPage;
          });
          if (index === -1) {
            ovpData.fb_pages.push(fbPagesObj);
          }
          if (ovpData.fb_pages.length > 2) {
            ovpData.fb_pages.shift();
          }
        } else {
          ovpData.fb_pages = [fbPagesObj];
        }
      }
      if (!!ovpData.currentUnit) {
        delete ovpData.currentUnit;
      }
      ovpData[bossId] = { ...currentUnitData };
      const credentialsPayload = {
        ovpCredentials: JSON.stringify({ ...ovpData }),
      };
      const data = await _editUsers(lucHeaders, credentialsPayload);
      dispatch(success(data.data));
      dispatch(hideSpinner());
      notify('success', i18n.t('SOLOUNITCONFIGURED'));
      history.push('/dashboard/units/' + bossId);
    } catch (e) {
      dispatch(failure(e.toString()));
      dispatch(hideSpinner());
      errorBlock(e);
    }
  };
  function success(setLucDestData) {
    return { type: commonConstants.UPDATE_OVP, setLucDestData };
  }
  function failure(error) {
    return { type: commonConstants.UPDATE_OVP_FAIL, error };
  }
  function hideSpinner() {
    return { type: commonConstants.OTHER_LOADING_FALSE };
  }
}

function setProgressBarInfo(data) {
  return { type: commonConstants.SET_PROGRESSBAR_DATA, data };
}

function showProgressBar() {
  return { type: commonConstants.SHOW_PROGRESSBAR };
}

function hideProgressBar() {
  return { type: commonConstants.HIDE_PROGRESSBAR };
}
