import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const boxCastBaseUrl = 'https://api.boxcast.com/';

export const getRtmp = () =>
  axios.get(`${boxCastBaseUrl}account/static_rtmps`, {
    headers: { Authorization: `Bearer ${cookies.get('boxcast_access_token')}` },
  });
export const getUserDetails = () =>
  axios.get(`${boxCastBaseUrl}user`, {
    headers: { Authorization: `Bearer ${cookies.get('boxcast_access_token')}` },
  });
